import React from "react";
import { DropDownMenu } from "components";

const Select = (props) => {
  const bool1 =
    props.id === "selectedMode" ||
    props.id === "audioFx" ||
    props.id === "selectedFilter" ||
    props.id === "mainFilters" ||
    props.id === "subsubFilters" ||
    props.id === "subFilters";
  const bool2 = props.id === "themePicker";
  const bool3 = !bool1 && !bool2;

  const handleDropDown = (text) => {
    if (props.id === "selectedMode") {
      if (text === "Chromatic") props.onSetFilterOption(1);
      if (text === "Kinematic") props.onSetFilterOption(2);
      if (text === "Chiaroscuro") props.onSetFilterOption(3);
      if (text === "Edge Detect") props.onSetFilterOption(4);
    } else if (props.id === "audioFx") {
      props.onSetFilterOption(text);
    } else if (props.id === "selectedFilter") {
      const filter = props.types.options.find((f) => f && f.value === text);
      props.onSetFilterOption(text, filter);
    } else if (props.id === "mainFilters") {
      props.onSetFilterOption(text);
    } else if (props.id === "blendFilters") {
      const found = props.types.options.find((f) => f && f.name === text);
      props.onSetFilterOption({ name: text, value: found.value });
    } else if (props.id === "subsubFilters") {
      const found = props.types.options.find((f) => f && f.value === text);
      props.onSetFilterOption({ name: text, filters: found.filters });
    } else if (props.id === "subFilters") {
      props.onSetFilterOption(text);
      const found = props.types.options.find((f) => f && f.value === text);
      props.onSetFilter(text, found);
    } else if (props.id === "themePicker") {
      props.onSetFilterOption("colors", props.types.options[text]);
    } else {
      const found = props.types.options.find((f) => f && f.name === text);
      props.onSetFilterOption(props.name, found.value);
    }
  };

  return (
    <>
      {bool1 && (
        <DropDownMenu
          handleDropDown={handleDropDown}
          optionList={props.types.options.map((p, i) => p.value)}
          menuName={props.name}
          scrollParent=".remixer_sidebar"
          disabled={props.disabled}
        />
      )}
      {bool2 && (
        <DropDownMenu
          handleDropDown={handleDropDown}
          optionList={Object.entries(props.types.options).map((e) => e[0])}
          menuName={props.name}
          scrollParent=".remixer_sidebar"
          disabled={props.disabled}
        />
      )}
      {bool3 && (
        <DropDownMenu
          handleDropDown={handleDropDown}
          optionList={props.types.options.map((p, i) => p.name)}
          menuName={props.name
              .replace(/([A-Z])/g, " $1")
              .charAt(0)
              .toUpperCase() + props.name.replace(/([A-Z])/g, " $1").slice(1)}
          scrollParent=".remixer_sidebar"
          disabled={props.disabled}
        />
      )}
    </>
  );
};

export default Select;
