export const images = [
  require("assets/god/error/error0.png"),
  require("assets/god/error/error1.png"),
  require("assets/god/error/error2.png"),
  require("assets/god/error/error3.png"),
  require("assets/god/error/error4.png"),
  require("assets/god/error/error5.png"),
  require("assets/god/error/error6.png"),
  require("assets/god/error/error7.png"),
  require("assets/god/error/error8.png"),
  require("assets/god/error/error9.png"),
  require("assets/god/error/error10.png"),
];


export const messages = [
  "Oops! We are experiencing bad space weather.",
  "Oops! Our telescope collided with an asteroid.",
  "Oops! Our telescope collided with some space debris.",
  "Oops! Our telescope collided with a comet.",
  "Oops! Our telescope crashed with an unmanned spaceship.",
  "Oops! Our telescope has lost its star map.",
  "Oops! Our telescope got hijacked by aliens.",
  "Oops! Our telescope crashed with a satellite.",
  "Oops! Our telescope got too close to the Sun.",
  "Oops! Our telescope got sucked into a black hole.",
  "Oops! Our telescope crashed with a space station.",
];
