import React, {
  setGlobal,
  useGlobal,
  useState,
  useEffect,
  useCallback,
  useRef,
  useId,
} from "reactn";
import styled from "styled-components";
import { OptionsMenu } from "components";
import optionsMenuData from "./optionsMenuData";
import forward from "assets/icons/forward.ico";
import back from "assets/icons/back.ico";
import sky from "./skyIcons.js";
import Lobby from "./Lobby";
import AllTokens from "./AllTokens";
import ViewToken from "./ViewToken";
import SkyResidence from "./SkyResidence";

const SkyLuxury = ({
  onHelp,
  onClose,
  id,
  injectedProps,
  onToggleComponent,
  standAlone,
  windowView,
}) => {
  const appId = useId().replaceAll(":", "");
  const backPages = useRef([]);
  const nextPages = useRef([]);
  const collectionId = "2";
  const minToken = 1000000 * Number(collectionId);
  const [contract] = useGlobal("contract");
  const [account] = useGlobal("account");
  const [web3] = useGlobal("web3");
  const [token, setToken] = useState(null);
  const [allTokens, setAllTokens] = useState(null);
  const [myTokens, setMyTokens] = useState(null);
  const [userTokens, setUserTokens] = useState(null);
  const [tokenOwner, setTokenOwner] = useState(null);
  const [view, setView] = useState(standAlone ? windowView : "Lobby");
  const selection = window.getSelection().anchorNode;
  const baseURI = "https://api.heaven.computer";

  const getUserTokens = useCallback(async (user) => {
    setUserTokens(null);
    let tokenIds = [];
    let balance = await contract["balanceOf(address)"](user).catch((e) => {
      setView("Error");
      return;
    });
    for (let i = 0; i < parseInt(balance); i++) {
      await contract["tokenOfOwnerByIndex(address,uint256)"](user, i).then(
        (id) => {
          if (id < minToken) return;
          tokenIds.push(id.toString());
        }
      );
    }
    setUserTokens(tokenIds.sort());
  },
    [contract, minToken]
  );

  const getTokenOwner = useCallback(async (token) => {
    setTokenOwner(null);
    if (token) {
      let owner = await contract["ownerOf(uint256)"](token);
      const name = await web3.lookupAddress(owner);
      name ? setTokenOwner(name) : setTokenOwner(owner);
    }
  },
    [contract, web3]
  );

  const scene = document.querySelector('.scene.animate');
  const sign = document.querySelectorAll('.neonSign');
  useEffect(() => {
    if (scene && sign) {
      sign.forEach((e) => e.style.setProperty('--fontMultiplier', scene.offsetWidth/100));
      const resizeObserver = new ResizeObserver((entries) => {
        sign.forEach((e) => e.style.setProperty('--fontMultiplier', entries[0].contentRect.width/100));
      });
      resizeObserver.observe(scene);
    }
  }, [scene, sign]);

  useEffect(() => {
    return () => {
      backPages.current = [];
      nextPages.current = [];
    };
  }, []);

  useEffect(() => {
    const nextSky = document.getElementById("nextSky" + appId);
    const backSky = document.getElementById("backSky" + appId);
    if (nextPages.current.length > 0) {
      optionsMenuData["View"][3].disable = false;
      nextSky.classList.remove("disabled");
    } else {
      optionsMenuData["View"][3].disable = true;
      nextSky.classList.add("disabled");
    }
    if (backPages.current.length > 0) {
      optionsMenuData["View"][2].disable = false;
      backSky.classList.remove("disabled");
    } else {
      optionsMenuData["View"][2].disable = true;
      backSky.classList.add("disabled");
    }
    if ((nextPages.current.length || backPages.current.length) > 0) {
      optionsMenuData["Edit"][3].disable = false;
    } else {
      optionsMenuData["Edit"][3].disable = true;
    }
    if (
      view === "Lobby" ||
      view === "View Token" ||
      view === "Minting" ||
      view === "Token Residence"
    ) {
      if (selection) {
        optionsMenuData["Edit"][0].disable = true;
        optionsMenuData["Edit"][1].disable = false;
      } else {
        optionsMenuData["Edit"][0].disable = false;
        optionsMenuData["Edit"][1].disable = true;
      }
    } else {
      optionsMenuData["Edit"][0].disable = true;
      optionsMenuData["Edit"][1].disable = true;
    }
  }, [view, selection, appId]);

  const onRefresh = () => {
    if (selection) onSelectNone();
    backPages.current = [];
    nextPages.current = [];
    setMyTokens(null);
    setToken(null);
    setView("Lobby");
  };

  const getAllCollectionTokens = useCallback(async () => {
    setAllTokens(null);
    let tokens = [];
    await contract["viewAllCollectionTokens(uint256)"](collectionId).then(
      (r) => {
        for (let i = 0; i < r.length; i++) {
          tokens.push(r[i].toString());
        }
      }
    );
    setAllTokens(tokens);
  }, [contract]);

  const getMyTokens = useCallback(async () => {
    setMyTokens(null);
    let tokenIds = [];
    let balance = await contract["balanceOf(address)"](account);
    for (let i = 0; i < parseInt(balance); i++) {
      await contract["tokenOfOwnerByIndex(address,uint256)"](
        account,
        i
      ).then((id) => {
        if (id < minToken) return;
        tokenIds.push(id.toString());
      });
    }
    setMyTokens(tokenIds.sort());
  }, [contract, account, minToken]);

  useEffect(() => {
    if (contract) getAllCollectionTokens();
  }, [contract, getAllCollectionTokens]);

  useEffect(() => {
    if (contract && account) getMyTokens();
  }, [contract, account, getMyTokens]);

  useEffect(() => {
    if (injectedProps) {
      if (injectedProps === "documents") {
        setView("My Tokens");
      } else if (injectedProps === "alltokens") {
        setView("All Tokens");
      }
    }
  }, [injectedProps]);

  const createViewList = useCallback(
    (curr, prev) => {
      if (curr === prev) return;
      if (selection) onSelectNone();
      backPages.current.push(prev);
      setView(curr);
    },
    [selection]
  );

  function onClickOptionItem(item) {
    switch (item.text) {
      case "Lobby":
        if (standAlone) window.history.pushState("", "", path(item.text));
        createViewList("Lobby", view);
        break;
      case "Sky Residence":
        setGlobal({ appLoading: { name: "Sky Luxury", id: id } });
        if (allTokens) {
          let token = allTokens[Math.floor(Math.random() * allTokens.length)];
          handleViewSky(token);
        } else {
          getAllCollectionTokens().then((tokens) => {
            let token = tokens[Math.floor(Math.random() * tokens.length)];
            handleViewSky(token);
          });
        }
        break;
      case "Token Residence":
        if (allTokens) {
          setGlobal({ appLoading: { name: "Sky Luxury", id: id } });
          let token = allTokens[Math.floor(Math.random() * allTokens.length)];
          setGlobal({ appLoading: null });
          handleViewToken(token);
        } else {
          setGlobal({ appLoading: { name: "Sky Luxury", id: id } });
          getAllCollectionTokens().then((tokens) => {
            let token = tokens[Math.floor(Math.random() * tokens.length)];
            setGlobal({ appLoading: null });
            handleViewToken(token);
          });
        }
        break;
      case "All Tokens":
        if (standAlone) window.history.pushState("", "", path(item.text));
        createViewList("All Tokens", view);
        break;
      case "My Tokens":
        if (standAlone) return;
        createViewList("My Tokens", view);
        break;
      case "Back":
        handleNavigation("back");
        break;
      case "Forward":
        handleNavigation("forward");
        break;
      case "Refresh":
        onRefresh();
        break;
      case "Select All":
        onSelectAll();
        break;
      case "Select None":
        onSelectNone();
        break;
      case "Close":
        onClose();
        break;
      case "About...":
        standAlone ? onHelp("SL") : onToggleComponent("Help", "open", id, "SL");
        break;
      case "Help Center":
        standAlone ? onHelp("Home") : onToggleComponent("Help", "open", id);
        break;
      default:
    }
  }

  function onSelectNone() {
    window.getSelection().removeAllRanges();
  }

  function onSelectAll() {
    const el = document.getElementById("sl_inner");
    if (document.body.createTextRange) {
      let range = document.body.createTextRange();
      range.moveToElementText(el);
      range.select();
    } else if (window.getSelection) {
      let selection = window.getSelection();
      let range = document.createRange();
      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  function handleViewToken(token) {
    setToken(token);
    getTokenOwner(token);
    createViewList("View Token", view);
    if (standAlone) window.history.pushState("", "", "/token/" + token);
  }

  function handleViewUser(user) {
    getUserTokens(user);
    createViewList("User Tokens", view);
    if (standAlone) window.history.pushState("", "", "/user/" + user);
  }

  function handleViewSky(token) {
    setToken(token);
    createViewList("Sky Residence", view);
    if (standAlone) window.history.pushState("", "", "/sky/" + token);
  }
  
  const handleNavigation = (flow) => {
    if (flow === "back") {
      if (
        backPages.current.length > 0 &&
        backPages.current[backPages.current.length - 1] !== view
      ) {
        nextPages.current.unshift(view);
        if (standAlone)
          window.history.pushState(
            "",
            "",
            path(backPages.current[backPages.current.length - 1])
          );
        setView(backPages.current[backPages.current.length - 1]);
        backPages.current[0] === backPages.current[backPages.current.length - 1]
          ? (backPages.current = [])
          : backPages.current.pop();
      } else {
        return;
      }
    } else if (flow === "forward") {
      if (nextPages.current.length > 0 && nextPages.current[0] !== view) {
        backPages.current.push(view);
        if (standAlone)
          window.history.pushState("", "", path(nextPages.current[0]));
        setView(nextPages.current[0]);
        nextPages.current[0] === nextPages.current[nextPages.current.length - 1]
          ? (nextPages.current = [])
          : nextPages.current.shift();
      } else {
        return;
      }
    }
  };

  const path = (view) => {
    if (view === "Lobby") {
      return "/";
    } else if (view === "View Token" && token) {
      return "/token/" + token.toString();
    } else if (view === "Sky Residence" && token) {
      return "/sky/" + token.toString();
    } else if (view === "User Tokens" && tokenOwner) {
      return "/user/" + tokenOwner.toString();
    } else {
      return view ? "/" + view.replace(/\s+/g, "").toLowerCase() : "/";
    }
  };

  useEffect(() => {
    if (standAlone) {
      optionsMenuData["Residence"][1].disable = true;
    }
    if (standAlone && windowView) {
      const query = windowView.substring(windowView.indexOf("?") + 1).trim();
      if (windowView === "All Tokens" || windowView === "Lobby") {
        setView(windowView);
      } else if (windowView.startsWith("token?") || windowView.startsWith("sky?")) {
        if (allTokens && (isNaN(query) || query < minToken || query >= minToken + allTokens.length)) {
          setView("Error");
          return;
        }
        getTokenOwner(query);
        setToken(query);
        windowView.startsWith("token?") ? setView("View Token") : setView("Sky Residence");
      } else if (windowView.startsWith("user?")) {
        getUserTokens(query);
        setTokenOwner(query);
        setView("User Tokens");
      } else {
        setView("Error");
      }
    }
  }, [standAlone, windowView, allTokens, getUserTokens, getTokenOwner, minToken]);

  return (
    <Div>
      <section className="window_toolbar">
        <OptionsMenu items={optionsMenuData} onClickItem={onClickOptionItem} />
      </section>
      <div className="divider"></div>
      <section className="address_bar">
        <div className="div_bar"></div>
        <div className="address_bar_title">
          A<u>d</u>dress
        </div>
        <div className="address_bar_content">
          <div className="address_bar_content_text">
            <img
              draggable={false}
              src={sky}
              alt="favicon"
              className="address_bar_content_img"
            />
            <span>https://sky.luxury{path(view)}</span>
            <div className="address_bar_buttons">
              <div className="address_bar_go">
                <div />
              </div>
              <div
                id={"backSky" + appId}
                title="back"
                className="address_bar_back"
                onClick={() => handleNavigation("back")}
              >
                <img draggable={false} alt="back" src={back} />
              </div>
              <div
                id={"nextSky" + appId}
                title="forward"
                className="address_bar_forward"
                onClick={() => handleNavigation("forward")}
              >
                <img draggable={false} alt="forward" src={forward} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="window_content">
        <div id="sl_inner" className="window_content_inner">
          {view === "Error" && (<p style={{ padding: "10px" }}>Not found.</p>)}
          {view === "Lobby" && (
            <Lobby
              onToggleComponent={onToggleComponent}
              id={id}
              handleViewToken={handleViewToken}
              standAlone={standAlone}
              createViewList={createViewList}
            />
          )}
          {view === "All Tokens" && (
            <AllTokens
              minToken={minToken}
              id={id}
              allTokens={allTokens}
              baseURI={baseURI}
              handleViewToken={handleViewToken}
              type="all"
            />
          )}
          {view === "My Tokens" && (
            <AllTokens
              minToken={minToken}
              id={id}
              allTokens={myTokens}
              baseURI={baseURI}
              handleViewToken={handleViewToken}
              type="mine"
            />
          )}
          {view === "User Tokens" && (
            <AllTokens
              minToken={minToken}
              id={id}
              allTokens={userTokens}
              baseURI={baseURI}
              handleViewToken={handleViewToken}
              type="user"
            />
          )}
          {view === "View Token" && (
            <ViewToken
              minToken={minToken}
              handleViewSky={handleViewSky}
              handleViewUser={handleViewUser}
              handleViewToken={handleViewToken}
              baseURI={baseURI}
              token={token}
              allTokens={allTokens}
              tokenOwner={tokenOwner}
              onToggleComponent={onToggleComponent}
              id={id}
              standAlone={standAlone}
            />
          )}
          {view === "Sky Residence" && <SkyResidence id={id} token={token} />}
        </div>
      </section>
    </Div>
  );
};

const Div = styled.div`
width: 100%;
height: 100%;
background: var(--colorgrad3);
@container skytop style(--sectionIndex: 1) or style(--sectionIndex: 2) or style(--sectionIndex: 3) {
  .sphere {
    transition-delay: calc(20ms - var(--sphereSize)*20ms) !important;
    --sphereDuration: calc(30s - var(--sphereSize)*20s) !important;
    --sphereTranslateZ: calc(-10em + 10em * var(--sphereSize) - 1em) !important;
    transition-timing-function: cubic-bezier(0, 0.7, 1, 0.7) !important;
  }
}

@property --sectionIndex {
  syntax: "<integer>";
  inherits: true;
  initial-value: 0;
}

@property --angleOpening {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0deg;
}

@property --translatePoly {
  syntax: "<length>";
  inherits: true;
  initial-value: 110vh;
}

@property --wallShade0 {
  syntax: "<color>";
  inherits: true;
  initial-value: rgba(0, 0, 0, 0.05);
}

@property --wallShade1 {
  syntax: "<color>";
  inherits: true;
  initial-value: rgba(0, 0, 0, 0.7);
}

@property --stop1 {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 0%;
}

@property --stop2 {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 0%;
}

@property --stop3 {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 0%;
}

@property --alpha {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

@property --angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 170deg;
}
@property --groundColor0 {
  syntax: "<color>";
  inherits: true;
  initial-value: rgba(0, 0, 0, 1);
}

@property --groundColor1 {
  syntax: "<color>";
  inherits: true;
  initial-value: rgba(255, 255, 255, 0.2);
}

@property --ceilColor {
  syntax: "<color>";
  inherits: true;
  initial-value: rgba(66, 66, 66, 1);
}
.sl_inner {
}

.skytop {
  background-color: black;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  perspective: 100vmax;
  display: flex;
  --display: flex;
}

.skytop * {
  transform-style: preserve-3d;
}
svg {
  display: none;
}
.window_content_inner {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  overflow-x: hidden;
  margin: 0;
  --sections: 25;
  --sectionIndex: 0;
  scroll-timeline: --scrollTimeline y;
  scroll-timeline: --scrollTimeline vertical;
  background-image: radial-gradient(var(--wallShade0), color-mix(in srgb, var(--wallShade0) 65%, #000) 70%), linear-gradient(to bottom right, black 50%, var(--ceilColor) 50%);
  background-size: 100% 100%, 3px 3px;
}
.token_caption, table td, p:not(.pointer), h1, h2, h3, h4, li ul, b, .selectable span:not(.pointer):not(.skytop span), .sl_table, strong {
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}
.skytop {
  position: absolute;
}
.sticky {
  display: none;
}
@supports (animation-timeline: scroll()) {
  .skytop {
    position: sticky;
  }
  .sticky {
    display: block;
  }
}
.sticky {
  pointer-events: none;
  z-index: -1;
  position: sticky;
}
.sticky, .skytop {
  height: 100%;
  width: 100%;
  top: 0;
}
.window_content {
  overflow: hidden;
}
  b {
    font-weight: 700;
  }
  .selectable {
    & *:not(img):not(.see_all p):not(.see_all p):not(th):not(.sl_btn span):not(.sl_btn):not(.sl_mint span):not(.sl_mint) {
      user-select: text;
    }
  }
  .address_bar_content_text {
    user-select: text;
  }
  .buttons_row {
    .sl_btn {
      width: 32px;
      height: 27px;
    }
    .sl_btn + .sl_btn {
      margin-left: 10px;
    }
  }
  .sl_input {
    outline: 0;
    min-width: 38px;
    margin: 0 10px;
    input {
      height: 25px;
      border: none;
      display: flex;
      align-items: center;
      padding: 6px;
      outline: 0;
      white-space: nowrap;
      letter-spacing: 2px;
      font-size: 11px;
      width: 100%;
    }
  }

  .sl_btn {
    display: flex;
    align-items: center;
    padding: 6px;
    text-overflow: hidden;
    text-align: center;
  }
  .sl_btn.see_all {
    word-break: break-word;
    text-overflow: hidden;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    & p {
      clip-path: inset(-10px -10px -1px -10px);
    }
  }

  #sl_top {
    display: none;
    opacity: 0;
    transition: opacity 1s;
  }

  ::selection {
    background-color: hsla(201, 100%, 91%, 0.8);
    color: var(--black);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .token_item {
    max-width: 100%;
    max-height: 100%;
  }
  .sl_token_box {
    padding: 12px;
  }
  .sl_token_image {
    width: 426px;
    max-width: 100%;
    aspect-ratio: 16 / 9;
  }

  .skyerror {
    font-size: 10px;
    --top: 8.5em;
    bottom: -1.5em;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
    letter-spacing: 2px;
  }

  .glass.sl_mint {
    --top: 4.6em;
    scale: 70%;
    text-transform: uppercase;
    letter-spacing: 5px;
    bottom: 0;
    font-weight: 900;
    line-height: 1.1;
    font-size: 1.2em;
  }
  
  .skyerror, .glass.sl_mint {
    mix-blend-mode: luminosity;
    animation-name: fadeIn, mint;
    --n: calc(var(--sections) - 1);
    animation-range: calc(var(--range) * 0.7) calc(var(--range) * 1.3), var(--animRange) !important;
    position: absolute;
    z-index: 11;
    opacity: 0;
    height: fit-content;
    margin: 0.5em;
    padding: 1.2em 1.7em;
  }
  
  .glass {
    text-align: center;
    --alpha: 0.58;
    --stop1: 10%;
    --stop2: 50%;
    --stop3: 51%;
    --angle: 170deg;
  }
  
  .frostedglass {
    --alpha: 0.4;
    --stop1: 25%;
    --stop2: 60%;
    --stop3: 80%;
    --angle: 179deg;
  }
  
  .glass, .frostedglass {
    background-image: repeating-linear-gradient(var(--angle), rgba(255, 255, 255, calc(var(--alpha) - .2)) 0%, rgba(255, 255, 255, calc(var(--alpha) - 0.3)) var(--stop1), rgba(255, 255, 255, calc(var(--alpha) - 0.45)) var(--stop2), rgba(255, 255, 255, calc(var(--alpha) - 0.6)) var(--stop3), rgba(255, 255, 255, calc(var(--alpha) - 0.45)) 100%);
    border-radius: 5px;
    backdrop-filter: blur(4px);
    border-right: 1.2px solid rgba(31, 31, 31, 0.35);
    border-bottom: 1.2px solid rgba(31, 31, 31, 0.35);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
    outline: 0;
    text-decoration: none;
    color: white;
    text-shadow: 1.5px 1.5px 0px rgba(55, 55, 55, 0.4);
  }
  
  .glass:not(.disabled) {
    transition-property: --angle, --alpha, --stop1, --stop2, --stop3, text-shadow, border-color, box-shadow, transform, scale, translate;
    transition-duration: 1s;
  }
  
  td.frostedglass {
    background-image: repeating-linear-gradient(var(--angle), rgba(255, 255, 255, calc(var(--alpha) - .2)) 0%, rgba(255, 255, 255, calc(var(--alpha) - 0.3)) var(--stop1), rgba(255, 255, 255, calc(var(--alpha) - 0.45)) var(--stop2), rgba(255, 255, 255, calc(var(--alpha) - 0.6)) var(--stop3), rgba(255, 255, 255, calc(var(--alpha) - 0.45)) 100%);
  }
  
  .glass *, .glass:not(.sl_mint).disabled {
    background: inherit;
  }
  
  .glass *, td.frostedglass, .glass.disabled {
    background-clip: text;
    -webkit-background-clip: text;
  }
  
  .glass.disabled {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.18);
  }
  
  .glass.sl_mint {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.15);
  }
  
  .glass:not(.glass.disabled):not(.glass.sl_mint) *, td.frostedglass {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.4);
    color: rgba(255, 255, 255, 0.4);
  }
  
  .glass:not(.disabled):hover {
    --alpha: 0.5;
    --stop1: 25%;
    --stop2: 60%;
    --stop3: 80%;
    transform: scale(0.95);
    border-right-color: rgba(31, 31, 31, 0.3);
    border-bottom-color: rgba(31, 31, 31, 0.3);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.13);
  }
  
  .glass:not(.disabled):active {
    --stop1: 30%;
    --stop2: 65%;
    --stop3: 85%;
    transition-duration: 0.3s;
    transform: scale(0.88);
    border-right-color: rgba(31, 31, 31, 0.15);
    border-bottom-color:rgba(31, 31, 31, 0.15);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  }

  .animate {
    --extraRange: 0;
    --rangeMultiplier: 1;
    --range: calc(100% / var(--sections) * var(--rangeMultiplier));
    --animRange: calc(var(--range) * var(--n)) calc((var(--range) * (var(--n) + 1)) + (var(--range) * var(--extraRange)));
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-duration: auto;
    animation-timeline: --scrollTimeline;
    animation-range: var(--animRange);
  }
  
  .content {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .content:not(.skysection1) {
    visibility: hidden;
    z-index: -1;
  }
  
  .skytop {
    .scene {
      --n: 0;
      animation-name: enter, fadeWall, hide;
      animation-range: var(--animRange), calc(var(--range) * 0.6) var(--range), calc(var(--range) * 2) calc(var(--range) * 2.1);
    }
  
    .roof {
      --n: 0.1;
      animation-name: fadeCeil, fadeOut;
      animation-range: 0 calc(var(--range) * 0.2), calc(var(--range) * 0.6) calc(var(--range) * 0.9);
    }
  
    .ground {
      --n: 0.1;
      animation-name: fadeOut;
    }
  
  }
  
  .skysection1 {
    animation-name: sky1;
    --n: 1;
    --extraRange: 3.5;
  
    p {
      --n: 3.3;
      animation-name: fadeOut;
      --extraRange: 0;
    }
    h2.animate {
      --n: 3;
      animation-name: fadeOut;
      --extraRange: 0;
    }
    .line {
      --n: 1.9;
      --extraRange: 4;
      --rangeMultiplier: 0.6;
      animation-name: fadeInOut;
    }
  
    .sphere-wrap {
      --n: 3;
      --extraRange: 3.7;
      --rangeMultiplier: 0.6;
      animation-name: moveSphere;
      animation-range: calc(var(--range)*1.9) calc(var(--range)*2) !important;
    }
  }
  
  .skysection2 {
    --n: 3.7;
    --extraRange: 3;
    animation-name: sky2;
  
    h2.animate {
      opacity: 0;
      --n: 3.5;
      --extraRange: 6;
      animation-name: sky2h2;
    }
  
    .suby {
      --n: 3.5;
      --extraRange: 3;
      animation-name: suby;
      transform: translateY(150vh);
    }
  }
  
  .skysection3 {
    --n: 6;
    --extraRange: 3.5;
    animation-name: sky3, exitP;
    width: 100%;
    height: 100%;
  
    .stagger.animate:first-child {
      --exit: -1;
      --n: 10.1;
      --extraRange: 0.65;
      animation-name: exitP;
      position: relative;
  
      p:not(.animate) {
        position: absolute;
        opacity: 1;
        z-index: 200;
        user-select: none;
        visibility: hidden;
  
        .animate {
          visibility: visible;
          user-select: text;
          opacity: 0;
          --n: 6;
          --extraRange: 1;
          animation-name: fadeIn;
        }
      }
  
      p.animate {
        position: relative;
        z-index: 1;
        --n: 6.5;
        --extraRange: 1;
        animation-name: sky3p;
        top: 105vh;
        transform: translateY(105vh);
  
        strong {
          visibility: hidden;
        }
      }
    }
  
    .stagger.animate:nth-child(2),
    .stagger.animate:nth-child(3) {
      --exitN: 10.1;
      position: relative;
      animation-name: sky3p, exitP;
      animation-range: var(--animRange), calc(var(--range) * var(--exitN)) calc((var(--range) * (var(--exitN) + 1)) + (var(--range) * 0.65));
      top: 105vh;
      transform: translateY(105vh);
    }
  
    .stagger.animate:nth-child(2) {
      --exit: 1;
      --n: 7;
      --extraRange: 1;
    }
  
    .stagger.animate:nth-child(3) {
      --exit: -1;
      --n: 7.5;
      --extraRange: 1;
    }
  }
  
  .skysection4 {
    --n: 9.5;
    animation-name: sky4, enterPoly;
    --extraRange: 5;
    --polyN: calc(var(--n) + 0.5);
    animation-range: var(--animRange), calc(var(--range) * var(--polyN)) calc((var(--range) * 1.1 * (var(--polyN) + 1)) + (var(--range) * 1.1 * 4));
  
    .poly-column:first-of-type {
      --n: 10;
      --extraRange: 0.5;
      animation-name: enterLeft;
    }
  
    .poly-column:nth-of-type(2) {
      --n: 10.5;
      --extraRange: 0.5;
      animation-name: enterRight;
    }
  }
  
  .skysection5 {
    --n: 12.2;
    --extraRange: 5.7;
    animation-name: sky5;
    transform: translateY(150vh);
  
    p {
      --n: 14;
      --extraRange: 0.5;
    }
  
    p.animate:first-of-type,
    p.animate:nth-of-type(3) {
      animation-name: enterLeft;
    }
  
    p.animate:nth-of-type(2) {
      animation-name: enterRight;
    }
  }
  
  .skysection6 {
    --n: 15.8;
    --extraRange: 3.5;
    animation-name: sky6;
    transform: translateY(-150vh);
  }
  
  .skysection7 {
    --n: 17.4;
    --extraRange: 4.7;
    animation-name: sky7;
  
    div.animate {
      --n: 20.7;
      --rangeMultiplier: 0.9;
      animation-name: fadeIn;
    }
  }
  
  .skysection8 {
    --n: 20.5;
    --extraRange: 3;
    animation-name: sky8;
  
    .inner-circle {
      --n: 21.4;
      --extraRange: 1;
      animation-name: fadeOut;
    }
  }
  
  .content.animate:last-of-type {
    --n: calc(var(--sections) - 2);
    animation-name: skylast;
  
    .neonCircle {
      animation-name: growNeonCircle;
    }
  
    .wavy {
      opacity: 0;
      --n: calc(var(--sections) - 1);
      animation-name: fadeIn;
    }
  }
`;

export default SkyLuxury;