import React, { useEffect, useRef, useState, useGlobal } from "reactn";
import styled from "styled-components";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import loadImg from "assets/ghost/loading.gif";
// import $ from "jquery";
// import "jquery-ui/ui/widgets/draggable";

const GhostClippy = () => {
  const [contract] = useGlobal("contract");
  // enter ghost id to start
  // const clippyId = 5232;
  const [clippyId] = useGlobal("clippyId");
  const [started, setStarted] = useState(false);
  const [hash, setHash] = useState(null);
  const canvasReference = useRef();
  const clock = useRef();
  const manager = useRef();
  const renderer = useRef();
  const camera = useRef();
  const scene = useRef();
  const animation = useRef();
  const controls = useRef();
  const mixer = useRef();

  const rnd = (decPairs, n) => decPairs[n] / 255;
  const isEven = (n) => !(n & 1);
  const getIndex = (string, subString, index) =>
    string.split(subString, index).join(subString).length;
  const replacement = (decPairs, replaced, n) => {
    let val = null;
    let next = decPairs.find(
      (e) => Math.round((e / 255) * 10) !== Math.round((decPairs[n] / 255) * 10)
    );
    while (val === null || val === replaced) {
      val = Math.round((next / 255) * 10);
    }
    return val;
  };
  const pairs = (hash) => {
    let hashPairs = [];
    for (let j = 0; j < 32; j++)
      hashPairs.push(hash.slice(2 + j * 2, 4 + j * 2));
    return hashPairs.map((x) => parseInt(x, 16));
  };

  useEffect(() => {
    // $(`#clippy_window`).draggable({
    //   revert: "valid",
    //   scroll: false
    // });
    const clippy_window = document.getElementById("clippy_window");
    const resizeObserver = new ResizeObserver((entries) =>
      onWindowResize(
        entries[0].target.clientWidth,
        entries[0].target.clientHeight
      )
    );
    if (clippy_window) resizeObserver.observe(clippy_window);
  }, []);

  const onWindowResize = (width, height) => {
    if (camera.current) {
      camera.current.aspect = width / height;
      camera.current.updateProjectionMatrix();
      renderer.current.setSize(width, height);
    }
  };

  useEffect(() => {
    const getTokenHash = async (clippyId) => {
      if (contract && clippyId) {
        const hash = await contract["tokenIdToHashes(uint256,uint256)"](
          clippyId,
          0
        );
        if (hash) setHash(hash);
      }
    };
    getTokenHash(clippyId);
  }, [contract, clippyId]);

  useEffect(() => {
    if (!hash) return;
    setStarted(true);
    let ghost;

    function init() {
      // LOADING
      manager.current = new THREE.LoadingManager();
      manager.current.onStart = function () {
        const loading = document.getElementById("clippy_loading");
        if (loading) loading.style.display = "flex";
      };
      manager.current.onLoad = function () {
        const loading = document.getElementById("clippy_loading");
        if (loading) loading.style.display = "none";
        const canvas = document.getElementById("clippy_canvas");
        if (canvas) canvas.style.display = "flex";
      };
      manager.current.onError = function (url) {
        const loading = document.getElementById("clippy_loading");
        if (loading)
          loading.innerHTML = "An error occurred. Please refresh the page.";
      };
      const height = document.getElementById("clippy_window").offsetHeight;
      const width = document.getElementById("clippy_window").offsetWidth;

      // LIGHTS CAMERA ACTION

      scene.current = new THREE.Scene();
      camera.current = new THREE.PerspectiveCamera(45, width / height, 1, 2000);
      camera.current.position.set(0, 0, 200);
      renderer.current = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      clock.current = new THREE.Clock();
      controls.current = new OrbitControls(
        camera.current,
        renderer.current.domElement
      );
      controls.current.enableDamping = true;
      controls.current.enableKeys = false;
      controls.current.screenSpacePanning = false;
      controls.current.enablePan = false;
      controls.current.enableZoom = true;
      controls.current.minDistance = 100;
      controls.current.maxDistance = 1800;

      // RENDERER SETTINGS
      renderer.current.setSize(width, height);
      renderer.current.setPixelRatio(window.devicePixelRatio);
      renderer.current.physicallyCorrectLights = true;
      renderer.current.outputEncoding = THREE.sRGBEncoding;
      renderer.current.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.current.shadowMap.enabled = true;
      renderer.current.autoClear = true;

      // INIT OBJECTS
      initGhost(ghost.texture, ghost.model, ghost.decPairs);
      canvasReference.current.appendChild(renderer.current.domElement);
    }
    function animate() {
      animation.current = requestAnimationFrame(animate);
      renderer.current.render(scene.current, camera.current);
      const delta = clock.current.getDelta();
      if (mixer.current) mixer.current.update(delta);
      controls.current.update();
    }
    const mood = (decPairs) => Math.round(rnd(decPairs, 31) * 27);
    const gen = (decPairs) => (rnd(decPairs, 30) < 0.5 ? "f" : "m");
    const decPairs = pairs(hash);
    async function loadData() {
      const [model, texture] = await Promise.all([
        import(`assets/mall/models/${gen(decPairs)}/${mood(decPairs)}.fbx`),
        import(`assets/mall/matcap/m${Math.round(rnd(decPairs, 5) * 52)}.png`),
      ]);
      ghost = { model: model, texture: texture, decPairs: decPairs };
    }
    loadData().then(() => {
      if (ghost && started) {
        console.log(ghost);
        init();
        animate();
      }
    });
    function initGhost(paramTex, paramModel, decPairs) {
      const limbGlitch = isEven(rnd(decPairs, 6) * 10);
      const animGlitch = isEven(rnd(decPairs, 7) * 10)
        ? isEven(rnd(decPairs, 8) * 10)
        : isEven(rnd(decPairs, 9) * 10);
      const polyGlitch = isEven(rnd(decPairs, 10) * 10)
        ? isEven(rnd(decPairs, 11) * 10)
        : isEven(rnd(decPairs, 12) * 10);
      const vertGlitch = isEven(rnd(decPairs, 13) * 10);

      const texture = paramTex.default;
      const model = paramModel.default;
      const matCap = new THREE.TextureLoader(manager.current).load(texture);
      new THREE.FileLoader(manager.current).load(model, function (data) {
        let glitch = data;
        if (limbGlitch) {
          const limbs = data
            .split("\n")
            .filter((n) => n.startsWith("	;Model::mixamorig:"));
          const limb =
            limbs[Math.round(rnd(decPairs, 14) * (limbs.length - 1))];
          let re = new RegExp(`^([\\s\\S]*?)\\n` + limb + `*(?:\\n.*){1}`, "g");
          glitch = glitch.replace(re, "$1");
        }
        if (animGlitch) {
          const replaced = Math.round(rnd(decPairs, 0) * 10);
          const anims = glitch
            .split("\n")
            .filter((n) => n.startsWith("	Deformer: "));
          const anim =
            anims[Math.round(rnd(decPairs, 15) * (anims.length - 1))];
          let anim0 = glitch.substring(glitch.indexOf(anim));
          let anim1 = anim0.slice(
            getIndex(anim0, "{", 2),
            getIndex(anim0, "}", 5)
          );
          let anim2 = anim1.replace(
            new RegExp(replaced, "g"),
            replacement(decPairs, replaced, 0)
          );
          glitch = glitch.replace(anim1, anim2);
        }
        if (polyGlitch) {
          const replaced = Math.round(rnd(decPairs, 1) * 10);
          const poly0 = glitch.substring(
            glitch.indexOf(
              `PolygonVertexIndex: *${
                gen(decPairs) === "f" ? "19936" : "19768"
              }`
            )
          );
          const poly1 = poly0.slice(poly0.indexOf("-"), poly0.indexOf("}"));
          let poly = poly1.replace(
            new RegExp(replaced, "g"),
            replacement(decPairs, replaced, 1)
          );
          glitch = glitch.replace(poly1, poly);
        }
        if (vertGlitch) {
          const replaced = Math.round(rnd(decPairs, 2) * 10);
          let vert0 = glitch.substring(
            glitch.indexOf(
              `Vertices: *${gen(decPairs) === "f" ? "14958" : "14832"}`
            )
          );
          let vert1 = vert0.slice(vert0.indexOf("-"), vert0.indexOf("}"));
          let vert = vert1.replace(
            new RegExp(replaced, "g"),
            replacement(decPairs, replaced, 2)
          );
          glitch = glitch.replace(vert1, vert);
        }

        let buffer = new TextEncoder(manager.current).encode(glitch);
        let object = new FBXLoader(manager.current).parse(buffer);

        mixer.current = new THREE.AnimationMixer(object);
        const action = mixer.current.clipAction(object.animations[0]);
        action.play();

        object.traverse(function (child) {
          if (child.isMesh) {
            child.material = new THREE.MeshMatcapMaterial({
              matcap: matCap,
              flatShading:
                isEven(rnd(decPairs, 16) * 10) &&
                isEven(rnd(decPairs, 17) * 10) &&
                isEven(rnd(decPairs, 18) * 10),
              transparent:
                isEven(rnd(decPairs, 19) * 10) &&
                isEven(rnd(decPairs, 20) * 10) &&
                isEven(rnd(decPairs, 21) * 10),
              opacity:
                isEven(rnd(decPairs, 19) * 10) &&
                isEven(rnd(decPairs, 20) * 10) &&
                isEven(rnd(decPairs, 21) * 10)
                  ? rnd(decPairs, 22)
                  : 1,
            });
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });
        object.position.y = -37;
        object.position.z = 20;
        if (mood === 14 || mood === 3) object.position.z = 5;
        if (mood === 4) object.position.y = -35;
        if (mood === 6) object.position.z = 30;
        scene.current.add(object);
      });
    }
  }, [hash, started]);

  return (
    clippyId && (
      <Div id="clippy_window">
        <div id="clippy_loading">
          <img draggable={false} src={loadImg} alt="loading" />
        </div>
        <div id="clippy_canvas" ref={canvasReference}></div>
      </Div>
    )
  );
};

const Div = styled.div`
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  #clippy_loading {
    display: flex;
  }
  #clippy_canvas {
    height: 100%;
    width: 100%;
    display: none;
  }
`;

export default GhostClippy;
