const basses = [
  require("assets/remixer/bass/0.wav"),
  require("assets/remixer/bass/1.wav"),
  require("assets/remixer/bass/2.wav"),
  require("assets/remixer/bass/3.wav"),
  require("assets/remixer/bass/4.wav"),
  require("assets/remixer/bass/5.wav"),
  require("assets/remixer/bass/6.wav"),
  require("assets/remixer/bass/7.wav"),
  require("assets/remixer/bass/8.wav"),
  require("assets/remixer/bass/9.wav"),
  require("assets/remixer/bass/10.wav"),
  require("assets/remixer/bass/11.wav"),
  require("assets/remixer/bass/12.wav"),
  require("assets/remixer/bass/13.wav"),
  require("assets/remixer/bass/14.wav"),
  require("assets/remixer/bass/15.wav"),
  require("assets/remixer/bass/16.wav"),
  require("assets/remixer/bass/17.wav"),
  require("assets/remixer/bass/18.wav"),
  require("assets/remixer/bass/19.wav"),
  require("assets/remixer/bass/20.wav"),
  require("assets/remixer/bass/21.wav"),
  require("assets/remixer/bass/22.wav"),
  require("assets/remixer/bass/23.wav"),
  require("assets/remixer/bass/24.wav"),
];

export default basses;
