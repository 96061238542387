

// import * as palettes from "../palettes";
// import { THEMES } from "../palettes/user";
import huesaturation from "./huesaturation";
import sepia from "./sepia";
import binarize from "./binarize";
import channelSeparation from "./channelSeparation";
import jitter from "./jitter";
import vhs from "./vhs";
import lut from "./lut";
import exposure from "./exposure";
import convolve, { LAPLACIAN_3X3, UNSHARP_5X5, SHARPEN_3X3, SOBEL_HORIZONTAL, EMBOSS_3X3, OUTLINE_3X3, GAUSSIAN_3X3, BRIGHTEN_0_5X } from "./convolve";
import grayscale from "./grayscale";
import pixelate from "./pixelate";
import pixelsort from "./pixelsort";
import glitchblob from "./glitchblob";
import halftone from "./halftone";
import invert from "./invert";
import ordered from "./ordered";
import quantize from "./quantize";
import noise from "./noise";
import scanline from "./scanline";
import rgbStripe from "./rgbstripe";
import {
  atkinson,
  burkes,
  floydSteinberg,
  falseFloydSteinberg,
  sierra,
  sierra2,
  sierraLite,
  stucki,
  jarvis,
  horizontalStripe,
  verticalStripe,
} from "./errorDiffusing";
export { default as huesaturation } from "./huesaturation";
export { default as channelSeparation } from "./channelSeparation";
export { default as jitter } from "./jitter";
export { default as vhs } from "./vhs";
export { default as binarize } from "./binarize";
export { default as exposure } from "./exposure";
export { default as convolve } from "./convolve";
export { default as grayscale } from "./grayscale";
export { default as pixelate } from "./pixelate";
export { default as pixelsort } from "./pixelsort";
export { default as glitchblob } from "./glitchblob";
export { default as halftone } from "./halftone";
export { default as invert } from "./invert";
export { default as ordered } from "./ordered";
export { default as quantize } from "./quantize";
export { default as scanline } from "./scanline";
export { default as rgbStripe } from "./rgbstripe";
export {
  atkinson,
  burkes,
  floydSteinberg,
  falseFloydSteinberg,
  sierra,
  sierra2,
  sierraLite,
  stucki,
  jarvis,
  horizontalStripe,
  verticalStripe,
} from "./errorDiffusing";

export const filterIndex = [
  lut,
  sepia,
  huesaturation,
  binarize,
  channelSeparation,
  exposure,
  convolve,
  grayscale,
  pixelsort,
  glitchblob,
  halftone,
  invert,
  jitter,
  ordered,
  quantize,
  scanline,
  rgbStripe,
  atkinson,
  burkes,
  floydSteinberg,
  falseFloydSteinberg,
  sierra,
  sierra2,
  sierraLite,
  stucki,
  jarvis,
  horizontalStripe,
  verticalStripe,
  vhs,
].reduce((acc, cur) => {
  acc[cur.name] = cur;
  return acc;
}, {});

export const mainFilterLister = [
  {
    value: "Enhance",
    filters: [
      { value: "Desaturate", filter: grayscale },
      { value: "Exposure", filter: exposure },
      { value: "Hue/Saturation", filter: huesaturation },
      { value: "Invert Colors", filter: invert },
      { value: "Sepia", filter: sepia },
      {
        value: "Gaussian Blur",
        filter: { ...convolve, options: { ...convolve.options, select: {name: "3x3", value: GAUSSIAN_3X3} } },
      },
      {
        value: "Bevel/Emboss",
        filter: { ...convolve, options: { ...convolve.options, select: {name: "3x3", value: EMBOSS_3X3 }} },
      },
      {
        value: "Brightness",
        filter: { ...convolve, options: { ...convolve.options, select: {name: "0.5x", value: BRIGHTEN_0_5X } }},
      },
      {
        value: "Sharpness",
        filter: { ...convolve, options: { ...convolve.options, select: {name: "3x3", value: SHARPEN_3X3} } },
      },
      {
        value: "Unsharp Mask",
        filter: { ...convolve, options: { ...convolve.options, select: {name: "5x5", value: UNSHARP_5X5} } },
      },
      {
        value: "Edge Detection",
        filter: { ...convolve, options: { ...convolve.options, select: {name: "3x3", value: LAPLACIAN_3X3 } }},
      },
      {
        value: "Edge Outline",
        filter: { ...convolve, options: { ...convolve.options, select: {name: "3x3", value: OUTLINE_3X3} } },
      },
      {
        value: "Edge Sobel",
        filter: { ...convolve, options: { ...convolve.options, select: {name: "horizontal", value: SOBEL_HORIZONTAL} } },
      },
    ],
  },
  {
    value: "Glitch",
    filters: [
      {
        value: "Analog",
        filters: [
          { value: "VHS Tape", filter: vhs },
          { value: "CRT TV", filter: rgbStripe },
          { value: "Scanline", filter: scanline },
          { value: "RGB Shift", filter: channelSeparation },
          { value: "Noise", filter: noise },
          { value: "Jitter", filter: jitter },
          { value: "Pixelate", filter: pixelate },
        ],
      },
      {
        value: "Digital",
        filters: [
          { value: "Pixelsort", filter: pixelsort },
          { value: "LUT", filter: lut },
          { value: "JPEG", filter: {...glitchblob, options: { ...glitchblob.options, format: "IMAGE_JPEG"} } },
          { value: "PNG", filter: {...glitchblob, options: { ...glitchblob.options, format: "IMAGE_PNG"} } },
          { value: "BMP", filter: {...glitchblob, options: { ...glitchblob.options, format: "IMAGE_BMP"} } },
          { value: "WEBP", filter: {...glitchblob, options: { ...glitchblob.options, format: "IMAGE_WEBP"} } },
          { value: "ICO", filter: {...glitchblob, options: { ...glitchblob.options, format: "IMAGE_ICO"} } },
        ],
      },
    ],
  },
  {
    value: "Dither",
    filters: [
      {
        value: "Halftone",
        filter: {
          ...halftone,
          options: {
            ...halftone.options,
            palette: {
              ...halftone.options.palette,
              options: {
                ...halftone.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Binarize",
        filter: {
          ...binarize,
          options: {
            ...binarize.options,
            palette: {
              ...binarize.options.palette,
              options: {
                ...binarize.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Quantize",
        filter: {
          ...quantize,
          options: {
            ...quantize.options,
            palette: {
              ...quantize.options.palette,
              options: {
                ...quantize.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Ordered",
        filter: {
          ...ordered,
          options: {
            ...ordered.options,
            palette: {
              ...ordered.options.palette,
              options: {
                ...ordered.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Floyd-Steinberg",
        filter: {
          ...floydSteinberg,
          options: {
            ...floydSteinberg.options,
            palette: {
              ...floydSteinberg.options.palette,
              options: {
                ...floydSteinberg.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "False Floyd-Steinberg",
        filter: {
          ...falseFloydSteinberg,
          options: {
            ...falseFloydSteinberg.options,
            palette: {
              ...falseFloydSteinberg.options.palette,
              options: {
                ...falseFloydSteinberg.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Jarvis",
        filter: {
          ...jarvis,
          options: {
            ...jarvis.options,
            palette: {
              ...jarvis.options.palette,
              options: {
                ...jarvis.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Stucki",
        filter: {
          ...stucki,
          options: {
            ...stucki.options,
            palette: {
              ...stucki.options.palette,
              options: {
                ...stucki.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Burkes",
        filter: {
          ...burkes,
          options: {
            ...burkes.options,
            palette: {
              ...burkes.options.palette,
              options: {
                ...burkes.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Atkinson",
        filter: {
          ...atkinson,
          options: {
            ...atkinson.options,
            palette: {
              ...atkinson.options.palette,
              options: {
                ...atkinson.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Sierra (full)",
        filter: {
          ...sierra,
          options: {
            ...sierra.options,
            palette: {
              ...sierra.options.palette,
              options: {
                ...sierra.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Sierra (two-row)",
        filter: {
          ...sierra2,
          options: {
            ...sierra2.options,
            palette: {
              ...sierra2.options.palette,
              options: {
                ...sierra2.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Sierra (lite)",
        filter: {
          ...sierraLite,
          options: {
            ...sierraLite.options,
            palette: {
              ...sierraLite.options.palette,
              options: {
                ...sierraLite.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Stripe (horizontal)",
        filter: {
          ...horizontalStripe,
          options: {
            ...horizontalStripe.options,
            palette: {
              ...horizontalStripe.options.palette,
              options: {
                ...horizontalStripe.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
      {
        value: "Stripe (vertical)",
        filter: {
          ...verticalStripe,
          options: {
            ...verticalStripe.options,
            palette: {
              ...verticalStripe.options.palette,
              options: {
                ...verticalStripe.options.palette.options,
                levels: 256,
              },
            },
          },
        },
      },
    ],
  },
];
