import React, { setGlobal, useEffect } from "reactn";
import styled from "styled-components";

const GhostTemple = ({ token, id }) => {
  useEffect(() => {
    setGlobal({ appLoading: { name: "Ghost Software", id: id } });
  }, [id]);

  return (
    <Div>
      {token && (
        <object
          onLoad={() => {
            setGlobal({ appLoading: null });
          }}
          data={`https://api.heaven.computer/ghost/${token}`}
          aria-label={token}
        />
      )}
      {!token && <p>Loading...</p>}
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  object {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

export default GhostTemple;
