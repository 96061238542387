import React, { setGlobal } from "reactn";
import styled from "styled-components";
import { POWER_STATE } from "store";
import off from "assets/icons/shutdown.ico";

const LogOff = ({ mode, onClose }) => {
  const onSubmit = () => {
    let radios = document.getElementsByName("logoff");
    for (let i = 0, length = radios.length; i < length; i++) {
      if (radios[i].checked) {
        if (radios[i].value === "shutdown") {
          if (window.localStorage.removeItem("loggedIntoHeaven"))
            window.localStorage.removeItem("loggedIntoHeaven");
          setTimeout(() => {
            setGlobal({ turnedOff: true });
            onClose();
          }, 100);
        } else if (radios[i].value === "restart") {
          if (window.localStorage.removeItem("loggedIntoHeaven"))
            window.localStorage.removeItem("loggedIntoHeaven");
          setTimeout(() => {
            setGlobal({ turnedOff: true });
            setTimeout(() => {
              onClose();
              window.location.reload(false);
            }, 3000);
          }, 100);
        } else {
          return;
        }
      }
    }
  };
  if (mode === POWER_STATE.TURN_OFF) {
    return (
      <Div>
        <div className="crt"></div>
        <div className="container filter">
          <div className="window">
            <header className="window_header">
              <div className="window_header_title">Shut Down</div>
              <button
                key="close"
                onPointerUp={onClose}
                className="header_button header_button--close"
              />
            </header>
            <div className="content">
              <img
                draggable={false}
                src={off}
                alt="shut down"
                className="error_img"
              />
              <div className="content_title">
                What would you like HeavenComputer to do?
              </div>
              <form
                onChange={() =>
                  document.querySelector("#okBtn").classList.remove("disabled")
                }
              >
                <div className="toggle_radio">
                  <input
                    type="radio"
                    id="shutdown"
                    name="logoff"
                    value="shutdown"
                  />
                  <label htmlFor="shutdown">
                    <span>Shut Down</span>
                  </label>
                </div>
                <div className="toggle_radio">
                  <input
                    type="radio"
                    id="restart"
                    name="logoff"
                    value="restart"
                  />
                  <label htmlFor="restart">
                    <span>Restart</span>
                  </label>
                </div>
              </form>
              <div className="buttons">
                <div
                  id="okBtn"
                  className="button disabled"
                  type="submit"
                  onClick={() => onSubmit()}
                >
                  OK
                </div>
                <div className="button" onClick={() => onClose()}>
                  Close
                </div>
              </div>
            </div>
          </div>
        </div>
      </Div>
    );
  } else {
    setTimeout(() => {
      setGlobal({ turnedOff: true });
      window.localStorage.removeItem("loggedIntoHeaven");
    }, 4000);
    return (
      <Div>
        <div className="crt"></div>
        <div className="container filter"></div>
      </Div>
    );
  }
};

const Div = styled.div`
  touch-action: none;
  overscroll-behavior: none;
  font-size: 11px;
  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
  }
  .button + .button {
    margin-left: 10px;
  }
  .filter {
    animation: grayen 4s forwards;
  }
  .crt {
    z-index: 10;
    animation: grow 3s forwards;
    animation-timing-function: cubic-bezier(0.32, 0.48, 0.55, 0.75);
    pointer-events: none;
    width: 100vw;
    height: 100vh;

    left: 0;
    top: 0;
    display: block;
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAEAQMAAACTPww9AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAAA1JREFUCNdjOACEQAAACQgBgeEHEZ0AAAAASUVORK5CYII=);
    background-size: 2px 2px;
  }
  .content_title {
    margin-bottom: 12px;
  }
  .container {
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
  }
  .error_img {
    position: absolute;
    align-self: flex-start;
  }
  .header_button {
    height: 18px;
    width: 18px;
    outline: none;
    color: var(--colorgrad7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 3px;
    padding: 0;
    border: 0;
    background: var(--colorgrad3);
    box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad0),
      inset -2px -2px 0 0 var(--colorgrad5), inset 2px 2px 0 0 var(--colorgrad2);
  }
  .header_button:active {
    background-color: var(--colorgrad3);
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }

  .header_button--close:before,
  .header_button--close:after {
    transform-origin: center center;
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 12px;
    background: var(--colorgrad7);
  }
  .header_button--close:before {
    transform: rotateZ(-45deg);
  }
  .header_button--close:after {
    transform: rotateZ(45deg);
  }
  .window {
    z-index: 20;
    width: 300px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad5), inset 1px 1px 0 0 var(--colorgrad0);
    background: var(--colorgrad3);
    padding: 4px;
    align-self: center;
    display: flex;
    flex-direction: column;
  }
  @keyframes type {
    from {
      width: 0;
    }
  }
  .window_header {
    padding: 1px;
    background: linear-gradient(90deg, #f5c8bf, #fcd7d1, #f9e4e0, #f5c8bf);
    height: 22px;
    display: flex;
    align-items: center;
    align-content: center;
    color: white;
    font-weight: 700;
  }
  .window_header_title {
    flex: 1;
    pointer-events: none;
    padding: 5px;
    letter-spacing: 1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .content {
    background: var(--colorgrad3);
    padding: 1px;
    flex-direction: column;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    margin: 12px 24px;
    position: relative;
    img {
      width: 32px;
      height: 32px;
    }
  }
`;

export default LogOff;
