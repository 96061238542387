import React from "react";
import styled from "styled-components";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { thunk } from "redux-thunk";
import App from "./containers/App";
import reducers from "./reducers";
const reducer = combineReducers(reducers);
const store = createStore(reducer, applyMiddleware(thunk));

const GodRemixerContainer = ({
  onClose,
  onToggleComponent,
  id,
  injectedProps
}) => {
  return (
    <Div id="god_remixer_wrap">
      <Provider store={store}>
        <App
          onClose={onClose}
          onToggleComponent={onToggleComponent}
          injectedProps={injectedProps}
          id={id}
        />
      </Provider>
    </Div>
  );
};

const Div = styled.div`
  height: calc(100% - 22px);
  width: 100%;
  padding: 0;
  .margin_top {
    margin-top: 6px;
  }
  .no_margin {
    margin-bottom: 2px !important;
  }
  .single_range {
    padding: 1px 0 0px 0;
    margin-top: -11px;
  }
  .menu_entries + .menu_entries {
    margin-top: 10px;
  }
  .short_slider {
    padding-bottom: 8px;
  }
  .menu_entries:first-child:not(.single_range):not(.margin_top) {
    margin-top: 2px;
  }
  .menu_entries:last-child {
    margin-bottom: 10px;
  }
  .drop_wrap ~ .filter_slider {
    margin-bottom: 8px;
  }
  // #fil_cnv {
  //   border: 1px solid red;
  // }
  canvas, img, video {
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -o-crisp-edges;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
  }

  .number-input {
    max-width: 42px;
  }

  .tab_select {
    display: flex;
    justify-content: center;
    position: relative;
    top: 6px;
    background: var(--colorgrad3);
    left: -10px;
    width: 82px;
    padding: 4px;
    z-index: 1;
    clip-path: inset(-3px -3px 5.5px -3px);
    border-bottom: 1px solid white;
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: var(--colorgrad0) 0px 1px 0px 0px inset, var(--colorgrad4) -2px 1px 0px -1px,
      var(--colorgrad3) -0.5px 3px 0px 0.5px, var(--colorgrad0) -2px 2px 0px 0px,
      var(--colorgrad0) -2px 0px 0px -1px, var(--colorgrad6) 1px 2px 0px 0px,
      var(--colorgrad8) 2px 0px 0px -1px, #000000 2px 2px 0px 0px;
    &.selected {
      clip-path: inset(-3px -3px 0.5px -3px);
      box-sizing: border-box;
      border-bottom: 1px solid var(--colorgrad3);
      z-index: 2;
      top: 2px;
      & p {
        top: 1px;
      }
    }
    &:first-child {
      left: 2px;
      margin-right: 10px;
    }
    & p {
      position: relative;
      top: -1px;
      height: 14px;
      & span:active {
        outline: 1px dotted #000000;
        outline-offset: 0px;
      }
    }
  }
  .master_controls {
    & div {
      display: flex;
      flex-direction: row;
    }
  }
  .play_icon {
    position: relative;
    top: 2px;
  }
  .remixer_toolbar {
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & div:not(.tab_switch):not(.slider_container):not(.slider_switch) {
      overflow: hidden;
    }
    div:not(.tab_switch) {
      display: flex;
    }
  }
  i {
    font-style: italic !important;
  }
  .tab_switch {
    display: flex;
  }

  .react-p5 {
    display: none;
  }

  #remixer_panel {
    height: 100%;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
    background: var(--colorgrad3);
    padding: 4px;
    display: flex;
    justify-content: space-between;
    box-shadow: var(--colorgrad0) 0.5px 0.5px 0px 0.5px inset,
      var(--colorgrad6) 0px 0px 0px 1px inset, var(--colorgrad7) 1px 0px 0px 0px,
      var(--colorgrad7) 0px 1px 0px 0px, var(--colorgrad7) 1px 1px 0px 0px;
  }
  .greyInset {
      padding: 2px !important;
    box-shadow: inset -1px -1px 0px var(--colorgrad0), inset 1px 1px 0px 0px var(--colorgrad6),
      inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8);
  }

  #remixer_window {
    padding: 2px;
    overflow: hidden;
    height: calc(100% - 57px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .remixer_screen {
    overflow: hidden;
    width: auto;
    flex: 20 1 400px;
    min-width: 100px;
    min-height: 100px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    & ::-webkit-resizer {
      border: none;
      box-shadow: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: right 2px bottom 2px;
    }
  }
  .rm_boxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 26px;
  }
  .box_section {
    width: auto;
    margin: 6px;
    .longerSlider {
      margin: 10px 0;
    }
  }
  .box_section + .box_section {
    margin-top: 12px;
  }
  .remixer_sidebar {
    max-height: 100%;
    width: 20%;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 160px;
    padding: 4px;
  }
  .drop_wrap {
    margin: 2px 0;
    width: 100%;
  }
  .colorArray {
    display: grid;
    grid-template-columns: repeat(auto-fill, 16px);
    justify-content: space-between;
    grid-gap: 1px;
    margin: 8px 0;
  }
  .colorArray div {
    height: 16px;
    width: 16px;
    align-self: center;
    box-shadow: inset -1px -1px 0px var(--colorgrad0), inset 1px 1px 0px 0px var(--colorgrad6),
      inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8);
  }
  .parts_menu {
    position: relative;
    width: 100%;
    top: 10px;
  }
  .parts_menu + .parts_menu {
    margin: 10px 0 8px 0;
  }
  h1 {
    padding: 10px 0;
  }

  #edit_color_btn {
    border: 0;
    outline: 0;
    width: 14px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    background: var(--colorgrad3);
    box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad0),
      inset -2px -2px 0 0 var(--colorgrad5), inset 2px 2px 0 0 var(--colorgrad2);
    &:active:not(.disabled) {
      outline: 1px dotted #000000;
      outline-offset: -5px;
      background: linear-gradient(
          45deg,
          var(--colorgrad1) 25%,
          transparent 25%,
          transparent 75%,
          var(--colorgrad1) 75%,
          var(--colorgrad1)
        ),
        linear-gradient(
          45deg,
          var(--colorgrad1) 25%,
          transparent 25%,
          transparent 75%,
          var(--colorgrad1) 75%,
          var(--colorgrad1)
        );
      background-size: 2px 2px;
      background-position: 0 0, 1px 1px;
      box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
        inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
    }
  }
  #remixerInput,
  #remixerOuput {
    display: none;
  }
  input[type="checkbox"] + .boollabel {
    padding: 0 20px;
  }
  input[type="checkbox"] + label {
    padding: 2px 4px;
  }
  .canvases {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .address_bar_title {
    overflow: hidden;
  }
  .address_bar_buttons {
    z-index: 1;
  }
  .address_bar_content {
    background: var(--colorgrad3);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    flex-direction: row;
    width: calc(100% - 4px);
    overflow: hidden;
    position: relative;
  }
  .address_bar_play {
    z-index: 20;
    float: right;
  }
  .address_bar_time {
    z-index: 0;
    position: absolute;
    background: white;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .address_bar_track {
    z-index: 1;
    left: 2px;
    position: absolute;
    display: flex;
    height: calc(100% - 4px);
    width: calc(100% - 44px);
  }
  .playerbtns {
    display: flex;
    margin: 2px 4px;
  }
  #mutebtn {
    font-size: 16px;
  }
  #rmxr_div {
    max-width: 100%;
    min-width: 20%;
    overflow: hidden;
    min-height: 10%;
    height: 100%;
    resize: both;
  }
  #wvfrm_div {
    resize: horizontal;
    width: 100%;
    min-height: 10%;
    min-width: 10%;
    max-height: 100%;
    max-width: 100%;
  }
  .envelope {
    height: 80px;
  }
  #rmxr_cnv {
    transition: opacity 0.5s;
    opacity: 0;
    width: 100% !important;
    height: 100% !important;
    position: relative;
    display: flex;
  }
  #wvfrm_cnv {
    margin-bottom: 2px;
    background-color: var(--colorgrad3);
    height: 100% !important;
    width: 100% !important;
  }
  #vis_screen {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    flex: 1 1 fit-content;
    max-height: 90%;
    min-height: 10%;
    margin-top: 4px;
  }
  #stats_div {
    display: none;
    align-items: flex-end;
    justify-contet: space-between;
    flex-direction: column;
    overflow: hidden;
    min-height: 10%;
    min-width: 18%;
    max-height: 100%;
    max-width: 100%;
    margin-left: 4px;
      position: relative;
  }
  #stats_cont {
    position: relative;
    overflow: hidden !important;
    width: 100%;
    height: 100%;
  }
  #stats_text {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    z-index: 2;
    top: 0;
    padding: 2px 2px 0 10px;
    font-size: 10px;
  }
  #stats_cnv {
    position: absolute;
    overflow: hidden;
    bottom: 0px;
    left: -3px;
  }
  #stats_cnv,
  #stats_div,
  #wvfrm_cnv,
  #wvfrm_div {
    overflow: hidden;
  }

  .button.filterbtn + .button.filterbtn {
    margin-left: 0px;
  }
  .button.playerbtn + .button.playerbtn {
    margin-left: 4px;
  }

  .button.filterbtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button.playerbtn, .button.filterbtn {
    padding: 0;
    height: 22px;
    min-width: 22px !important;
  }
  .playerbtn.disabled {
    &:active {
      box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad0),
        inset -2px -2px 0 0 var(--colorgrad5), inset 2px 2px 0 0 var(--colorgrad2);
      outline: none;
      background: var(--colorgrad3);
    }
  }
  .playerico.disabled {
    opacity: 1;
    filter: drop-shadow(1px 1px 0 white);
  }
  img.playerico.disabled {
    opacity: 0.6;
  }
  .playerico {
    opacity: 0.8;
  }
  .address_bar_play {
    position: relative;
  }
  .address_bar_play {
    z-index: 1;
    box-shadow: inset -1px -1px 0px var(--colorgrad7), inset 1px 1px 0px var(--colorgrad4),
      inset -2px -2px 0px var(--colorgrad6), inset 2px 2px 0px var(--colorgrad0);
    height: 18px;
    width: 20px;
    background-color: var(--colorgrad3);
    svg {
      margin: 3px 4px;
    }
    &:active {
      outline: 1px dotted #000000;
      outline-offset: -4px;
      box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
        inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
    }
  }

  .address_bar_rew,
  .address_bar_ff {
    z-index: 1;
    svg {
      margin: 3px 4px;
    }
    box-shadow: inset -1px -1px 0px var(--colorgrad7), inset 1px 1px 0px var(--colorgrad4),
      inset -2px -2px 0px var(--colorgrad6), inset 2px 2px 0px var(--colorgrad0);
    height: 18px;
    width: 20px;
    background-color: var(--colorgrad3);
  }

  .fps_switch span {
    line-height: 12px;
    overflow: hidden;
    display: block;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 2px;
  }
  .fps_switch {
    min-width: calc(3ch + 4px);
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 2px 1px 0 1px;
  }

  .fps_switch + input {
    margin-left: 3px;
  }

  .slider_container + .slider_container {
    margin-left: 8px;
  }
  .slider_switch {
    margin: 0 4px;
    .slider_container {
      overflow: visible;
      display: flex;
      min-width: 20px;
      margin-bottom: 2px;
    }
    div {
      display: flex;
      align-items: center;
      span {
        overflow: visible;
        display: flex;
        align-items: center;
        margin-top: -2px;
      }
    }
  }
  .slider_switch {
    min-width: 20px;
  }

  .longerSlider {
    width: 100%;
  }
  .thick_slider {
    padding: 6px 0;
    outline-offset: 4px;
    width: 100%;
  }
  .filter_menu.boolcheck {
    justify-content: flex-end;
  }
`;

export default GodRemixerContainer;
