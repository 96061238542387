import React from "react";
import Sketch from "react-p5";

let spectrum, wave;

const Waveform = (props) => {

  const setup = (p5, canvasParentRef) => {
    p5.pixelDensity(10);
    let parent = document.body.querySelector("#wvfrm_div");
    let cnv = p5.createCanvas(parent.offsetWidth - 4, parent.offsetHeight - 4).parent(parent);
    cnv.id("wvfrm_cnv");
    const resizeObserver = new ResizeObserver(entries => {
      p5.resizeCanvas(parent.offsetWidth - 4, parent.offsetHeight - 4);
    });
    resizeObserver.observe(parent);
    p5.colorMode(p5.RGB);
    p5.background("var(--colorgrad3)");
  };

  function draw(p5) {
    if (!props.toneFFT || !props.toneWaveform || !props.playing) return;
    p5.background("var(--colorgrad3)");
    spectrum = props.toneFFT.getValue();
    wave = props.toneWaveform.getValue();
    p5.noFill();
    p5.strokeWeight(1);
    for (let i = 0; i < spectrum.length; i++) {
      p5.stroke('#ffffff');
      const value = spectrum[i];
      const x = p5.map(i, 0, spectrum.length, 0, p5.width+4)
      const h = p5.map(Math.abs(value), 0, 200, p5.height+10, 0);
      p5.rect(x, h, (p5.width / spectrum.length)-2, p5.height);
    }
    p5.beginShape();
    p5.strokeWeight(1);
    p5.stroke(0);
    for (let i = 0; i < wave.length; i++) {
      const x = p5.map(i, 0, wave.length, 0, p5.width);
      const y = p5.map(wave[i]*4, -1, 1, 0, p5.height);
      p5.vertex(x, y);
    }
    p5.endShape();
  }

  return <Sketch setup={setup} draw={draw} />;
};

export default Waveform;
