const lutImages = [
  require("assets/luts/lut0.png"),
  require("assets/luts/lut1.png"),
  require("assets/luts/lut2.png"),
  require("assets/luts/lut3.png"),
  require("assets/luts/lut4.png"),
  require("assets/luts/lut5.png"),
  require("assets/luts/lut6.png"),
  require("assets/luts/lut7.png"),
  require("assets/luts/lut8.png"),
  require("assets/luts/lut9.png"),
  require("assets/luts/lut10.png"),
  require("assets/luts/lut11.png"),
  require("assets/luts/lut12.png"),
  require("assets/luts/lut13.png"),
  require("assets/luts/lut14.png"),
  require("assets/luts/lut15.png"),
  require("assets/luts/lut16.png"),
  require("assets/luts/lut17.png"),
  require("assets/luts/lut18.png"),
  require("assets/luts/lut19.png"),
  require("assets/luts/lut20.png"),
  require("assets/luts/lut21.png"),
  require("assets/luts/lut22.png"),
  require("assets/luts/lut23.png"),
  require("assets/luts/lut24.png"),
  require("assets/luts/lut25.png"),
  require("assets/luts/lut26.png"),
  require("assets/luts/lut27.png"),
  require("assets/luts/lut28.png"),
  require("assets/luts/lut29.png"),
  require("assets/luts/lut30.png"),
  require("assets/luts/lut31.png"),
  require("assets/luts/lut32.png"),
  require("assets/luts/lut33.png"),
  require("assets/luts/lut34.png"),
  require("assets/luts/lut35.png"),
  require("assets/luts/lut36.png"),
  require("assets/luts/lut37.png"),
  require("assets/luts/lut38.png"),
  require("assets/luts/lut39.png"),
  require("assets/luts/lut40.png"),
  require("assets/luts/lut41.png"),
  require("assets/luts/lut42.png"),
  require("assets/luts/lut43.png"),
  require("assets/luts/lut44.png"),
  require("assets/luts/lut45.png"),
  require("assets/luts/lut46.png"),
  require("assets/luts/lut47.png"),
  require("assets/luts/lut48.png"),
];

export default lutImages;
