import React from "react";
import styled from "styled-components";

const Sky5 = () => {
	return (
		<Div className="skysection5 content animate selectable">
			<h1> </h1>
			<p className="animate">
				SkyLuxury showcases an <strong>eclectic mix of floorings</strong>, with each home being a masterpiece featuring multicultural tiling artwork inspired by all manner of luxurious motifs:
			</p>
			<p className="animate">
				Ostentatious quests for the seven treasures. The timelessness of aristocratic fashion. Carpets that echo forgotten iconic hotel designs. The tropical vibes of seaside escapades. Exotic hardwoods sourced from the depths of the Amazon rainforest.
			</p>
			<p className="animate">
				Ethical sourcing? We insit you worry not, noble patron, for we know... 
			</p>
			<h3>
				There is no greater luxury than the luxury of not giving a damn.
			</h3>
		</Div>
	);
};

const Div = styled.div`
p.animate:first-of-type, p.animate:nth-of-type(3) {
  transform: translateX(-150vh);
}

p.animate:nth-of-type(2) {
  transform: translateX(150vh);
}`;

export default Sky5;