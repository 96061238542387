import React, { useState, useEffect, setGlobal, useGlobal } from "reactn";
import styled from "styled-components";
import SkyImage from "./SkyImage";
import axios from "axios";
import { displayMetadata } from "./utils/displaymetadata.js";

const ViewToken = ({
  tokenOwner,
  allTokens,
  baseURI,
  handleViewToken,
  handleViewUser,
  handleViewSky,
  id,
  minToken,
  token,
}) => {
  const [appLoading] = useGlobal("appLoading");
  const [tokenInput, setTokenInput] = useState(null);
  const initMetadata = {
    name: "",
    colorscheme: "loading...",
    flooring: "loading...",
    illumination: "loading...",
    filter: "loading...",
    pool: "loading...",
    phase: "loading...",
    numgrad: "loading...",
    gradsmooth: "loading...",
    numfurnis: "loading...",
    furnitypes: "loading...",
    description: "loading...",
    ipfsHash: "loading...",
    htmlHash: "loading...",
  };
  const [metadata, setMetadata] = useState(initMetadata);

  const handleDownloadHTML = (tokenId) => {
    if (appLoading && appLoading.name === "Sky Luxury" && appLoading.id === id) return;
    setGlobal({ appLoading: { name: "Sky Luxury", id: id } });
    axios
      .get("https://api.heaven.computer/skyexporter/" + tokenId, { responseType: "blob" })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "skyluxury_" + tokenId + ".html");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setGlobal({ appLoading: null });
      })
      .catch(() => {
        setGlobal({ appLoading: null });
      });
  }
  const joinWithAnd = (items) => {
    const len = items.length;
    return len === 0 ? '' : 
           len === 1 ? items[0] : 
           len === 2 ? items.join(' and ') : 
           items.slice(0, -1).join(', ') + ' and ' + items[len - 1];
  }

  useEffect(() => {
    function fetchMetadata(tokenId) {
      const foundMetadata = displayMetadata.find(
        (item) => parseInt(item.tokenId) === parseInt(tokenId)
      );
      if (foundMetadata) {
        setMetadata(foundMetadata);
      } else {
        axios
          .get(baseURI + "/token/" + tokenId, {
            responseType: "json",
          })
          .then((response) => {
            const furnitypes = [];

            for (let i = 0; i < response.data.attributes[8].value; i++) {
              furnitypes.push(response.data.attributes[9 + i].value)
            }
            setMetadata({
              name: response.data.name.split("#200")[0].trim(),
              colorscheme: response.data.attributes[0].value,
              flooring: response.data.attributes[1].value,
              illumination: response.data.attributes[2].value,
              filter: response.data.attributes[3].value,
              pool: response.data.attributes[4].value,
              phase: response.data.attributes[5].value,
              numgrad: response.data.attributes[6].value,
              ipfsHash: response.data["image"].substring(7),
              gradsmooth: response.data.attributes[7].value,
              numfurnis: response.data.attributes[8].value,
              furnitypes: joinWithAnd(furnitypes),
              description: response.data.description.split('!\n\n')[1]?.split('\n\nAt last')[0] || '',
              htmlHash: response.data["interactive_nft"]["code_uri"].substring(7),
            });
          });
      }
    }
    if (allTokens && token) {
      fetchMetadata(token);
      const last = document.getElementById(id + "v_sl_last").classList;
      const first = document.getElementById(id + "v_sl_first").classList;
      const next = document.getElementById(id + "v_sl_next").classList;
      const prev = document.getElementById(id + "v_sl_previous").classList;
      if (
        parseInt(token) === minToken &&
        parseInt(token) !== minToken + (allTokens.length - 1)
      ) {
        last.remove("disabled");
        prev.add("disabled");
        first.add("disabled");
        next.remove("disabled");
      } else if (
        parseInt(token) !== minToken &&
        parseInt(token) === minToken + (allTokens.length - 1)
      ) {
        last.add("disabled");
        next.add("disabled");
        prev.remove("disabled");
        first.remove("disabled");
      } else if (parseInt(token) === minToken && allTokens.length - 1 === 0) {
        first.add("disabled");
        next.add("disabled");
        prev.add("disabled");
        last.add("disabled");
      } else {
        first.remove("disabled");
        next.remove("disabled");
        prev.remove("disabled");
        last.remove("disabled");
      }
    }
  }, [allTokens, token, baseURI, id, minToken]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tokenInput >= minToken && tokenInput < minToken + allTokens.length) {
      handleViewToken(tokenInput);
    } else {
      e.target.classList.add("shake_err");
      setTimeout(() => {
        e.target.classList.remove("shake_err");
      }, 700);
      return;
    }
  };

  function handleTokenChange(number) {
    if (number === parseInt(token) || number < minToken || number >= minToken + allTokens.length) {
      return;
    } else {
      setMetadata(initMetadata);
      handleViewToken(number);
    }
  }

  function toggleImageSize() {
    const images = document.querySelectorAll(".slti" + id);
    if (images) {
      images.forEach((image) => {
        if (image.classList.contains("large")) {
          image.style.width = "426px";
          image.classList.remove("large");
        } else {
          image.style.width = "640px";
          image.classList.add("large");
        }
      });
    }
  }
  return (
    <Div>
      {(!token || !allTokens) && <p style={{ padding: "10px" }}>Loading...</p>}
      {allTokens && token && (<>
        <div className="token_item selectable">
          <div className="buttons_row">
            <div
              className="sl_btn glass pointer"
              id={id + "v_sl_first"}
              onClick={() => handleTokenChange(minToken)}
            >
              <span>&#60;&#60;</span>
            </div>
            <div
              className="sl_btn glass pointer"
              id={id + "v_sl_previous"}
              onClick={() => handleTokenChange(parseInt(token) - 1)}
            >
              <span>&#60;</span>
            </div>
            <form
              className="sl_input glass"
              autoComplete="off"
              onSubmit={(e) => handleSubmit(e)}
              noValidate="novalidate"
            >
              <input
                className="texter"
                minLength="1"
                maxLength="3"
                size="3"
                min="0"
                max={allTokens.length - 1}
                id="token_page"
                type="number"
                name="token page"
                placeholder="000"
                onChange={(e) =>
                  setTokenInput(parseInt(e.target.value) + minToken)
                }
              ></input>
            </form>
            <div
              className="sl_btn glass pointer"
              id={id + "v_sl_next"}
              onClick={() => handleTokenChange(parseInt(token) + 1)}
            >
              <span>&#62;</span>
            </div>
            <div
              className="sl_btn glass pointer"
              id={id + "v_sl_last"}
              onClick={() => handleTokenChange(minToken + (allTokens.length - 1))}
            >
              <span>&#62;&#62;</span>
            </div>
          </div>
          <div className="sl_token_upper">
            <div className="sl_token_box_wrap">
              <div
                className="sl_token_box glass pointer"
                onClick={(e) => toggleImageSize(e)}
              >
                <SkyImage
                  id={id}
                  baseURI={baseURI}
                  token={token}
                  ipfsHash={metadata.ipfsHash}
                />
              </div>
            </div>

            <div className="token_caption">
              <div>
                <h2>sky #{token - minToken}</h2>{" "}
                <span className="codeglyph">{metadata.name}</span>
              </div>
              <div>
                <b>owned by</b>
                <span className="tokenowner">
                  {tokenOwner ? tokenOwner : "loading..."}
                </span>
              </div>
              <div className="see_all_row">
                <div
                  className={`sl_btn see_all glass pointer${metadata && metadata.ipfsHash !== "loading..."
                    ? ""
                    : " disabled"
                    }`}
                  onClick={() => {
                    handleViewSky(token);
                  }}
                >
                  <p>visit sky residence</p>
                </div>
                <div
                  className={`sl_btn see_all glass pointer${tokenOwner ? "" : " disabled"
                    }`}
                  onClick={() => {
                    if (!tokenOwner) return;
                    handleViewUser(tokenOwner);
                  }}
                >
                  <p>see all user skies</p>
                </div>
                <div
                  className={`sl_btn see_all glass pointer${metadata.illumination !== "loading..."
                    ? ""
                    : " disabled"
                    }`}
                  onClick={() =>
                    handleDownloadHTML(token)
                  }
                >
                  <p>download sky html file</p>
                </div>
              </div>
              <div>
                <p>{metadata.description}</p>
              </div>
            </div>
          </div>
          <table className="sl_table">
            <thead>
              <tr>
                <th scope="col" colSpan="2">
                  element
                </th>
                <th scope="col" colSpan="1">
                  amount
                </th>
                <th scope="col" colSpan="3">
                  value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="frostedglass" colSpan="3">scene color scheme</td>
                <td className="frostedglass" colSpan="3">{metadata.colorscheme}</td>
              </tr>
              <tr>
                <td className="frostedglass" colSpan="3">flooring style</td>
                <td className="frostedglass" colSpan="3">{metadata.flooring}</td>
              </tr>
              <tr>
                <td className="frostedglass" colSpan="3">ambient illumination</td>
                <td className="frostedglass" colSpan="3">{metadata.illumination}</td>
              </tr>
              <tr>
                <td className="frostedglass" colSpan="3">scroll filter</td>
                <td className="frostedglass" colSpan="3">{metadata.filter}</td>
              </tr>
              <tr>
                <td className="frostedglass" colSpan="3">baptismal pool</td>
                <td className="frostedglass" colSpan="3">{metadata.pool}</td>
              </tr>
              <tr>
                <td className="frostedglass" colSpan="3">circadian phase</td>
                <td className="frostedglass" colSpan="3">{metadata.phase}</td>
              </tr>
              <tr>
                <td className="frostedglass" colSpan="2">gradients</td>
                <td className="frostedglass" colSpan="1">{metadata.numgrad}</td>
                <td className="frostedglass" colSpan="3">{metadata.gradsmooth}</td>
              </tr>
              <tr>
                <td className="frostedglass" colSpan="2">furnis</td>
                <td className="frostedglass" colSpan="1">{metadata.numfurnis}</td>
                <td className="frostedglass" colSpan="3">{metadata.furnitypes}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row" colSpan="2">
                  HTML IPFS hash
                </th>
                <td className="frostedglass" colSpan="4">{metadata.htmlHash}</td>
              </tr>
              <tr>
                <th scope="row" colSpan="2">
                  MP4 IPFS hash
                </th>
                <td className="frostedglass" colSpan="4">{metadata.ipfsHash}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </>
      )}
    </Div>
  );
};

const Div = styled.div`
th, h2, b {
  padding-bottom: 4px;
  font-weight: 900;
  height: 0.8em;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.2);
}
th, h2 {
  font-size: 1rem;
}
  video {
    background-image: url(loadImg);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: center;
  }
  .codeglyph {
    font-size: 14px;
  }

  .sl_token_upper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
`;

export default ViewToken;
