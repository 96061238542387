import React, { useGlobal, useCallback } from "reactn";
import styled from "styled-components";
import seven from "assets/popup/7.gif";
import wiggle from "assets/popup/wiggle.gif";
import cd1 from "assets/popup/cd1.gif";
import cd2 from "assets/popup/cd2.gif";
import worm from "assets/popup/worm.gif";
import claim from "assets/popup/claim.gif";
import confetti from "assets/popup/confetti.gif";
import freemoney from "assets/popup/freemoney.gif";
import loser from "assets/popup/loser.gif";
import prize from "assets/popup/prize.gif";
import prize2 from "assets/popup/prize2.gif";
import visitor from "assets/popup/visitor.gif";
import winner1 from "assets/popup/winner1.gif";
import winner2 from "assets/popup/winner2.gif";
import chat from "assets/popup/chat.gif";
import tiles from "assets/popup/bg.jpeg";
import userClaim1 from "assets/json/claim1.json";
import userClaim2 from "assets/json/claim2.json";
import userClaim3 from "assets/json/claim3.json";
import userClaim4 from "assets/json/claim4.json";
import userClaim5 from "assets/json/claim5.json";
import userClaim6 from "assets/json/claim6.json";
import Astrodrop from "artifacts/Astrodrop.json";
import { ethers } from 'ethers';

const PopUp = ({ onToggleComponent }) => {
  const [adContract] = useGlobal("adContract");
  const [provider] = useGlobal("provider");
  const [account] = useGlobal("account");

  const claimCD = useCallback(
    async (user) => {
      let claim;
      const claim1 = userClaim1;
      const claim2 = userClaim2;
      const claim3 = userClaim3;
      const claim4 = userClaim4;
      const claim5 = userClaim5;
      const claim6 = userClaim6;
      if (claim1.hasOwnProperty(account)) {
        claim = userClaim1[account]
      } else if (claim2.hasOwnProperty(account)) {
        claim = userClaim2[account]
      } else if (claim3.hasOwnProperty(account)) {
        claim = userClaim3[account]
      } else if (claim4.hasOwnProperty(account)) {
        claim = userClaim4[account]
      } else if (claim5.hasOwnProperty(account)) {
        claim = userClaim5[account]
      } else if (claim6.hasOwnProperty(account)) {
        claim = userClaim6[account]
      }
      const abi = Astrodrop;
      const web3 = new ethers.providers.Web3Provider(provider);
      const signer = web3.getSigner(account);
      const writeContract = new ethers.Contract(adContract.address, abi, signer);
      const tx = await writeContract.claim(claim.index, account, claim.amount, claim.proof);
      await tx
        .wait()
        .then((receipt) => {
          onToggleComponent('CD Collection', 'open')
          console.log(receipt);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [adContract, account, provider, onToggleComponent]
  );

  return (
    <Div>
      <section className="window_toolbar_empty"></section>
      <section className="window_content">
        <div className="window_content_inner">
          <div className="pop_column">
            <img draggable={false} src={confetti} alt="gif" />
            <img draggable={false} src={confetti} alt="gif" />
            <img draggable={false} src={confetti} alt="gif" />
            <img draggable={false} src={confetti} alt="gif" />
          </div>
          <div className="pop_center">
            <img draggable={false} src={winner2} alt="gif" />
            <h1>CONGRATULATIONS!!!</h1>
            <p>
              You've been randomly selected to win <br />
              <u className="pop_pointer" onClick={() => claimCD(account)}>
                an amazing P R I Z E !!!
              </u>
            </p>
            <span>because</span>
            <div className="pop_column">
              <div className="pop_row" id="pop_vis">
                <img draggable={false} src={visitor} alt="gif" />
                <img draggable={false} src={seven} alt="gif" />
                <img draggable={false} src={seven} alt="gif" />
                <img draggable={false} src={seven} alt="gif" />
                <img draggable={false} src={seven} alt="gif" />
                <img draggable={false} src={seven} alt="gif" />
                <img draggable={false} src={seven} alt="gif" />
                <img draggable={false} src={seven} alt="gif" />
              </div>
              <div className="pop_row">
                <img draggable={false} src={winner1} alt="gif" />
              </div>
              <div className="button" onClick={() => claimCD(account)}>
                CLICK TO CLAIM YOUR PRIZE
              </div>
              <div className="pop_row">
                <img draggable={false} src={cd1} alt="gif" />
                <div className="pop_column">
                  <h2>
                    This pop up is not sketchy at all.
                    <br />
                    °·..·°¯°·._.· Please trust us. ·._.·°¯°·.·°
                  </h2>
                  <h3>This is a playful and harmless font.</h3>
                </div>
                <img draggable={false} src={cd1} alt="gif" />
              </div>
            </div>
            <div>
              <img draggable={false} src={worm} alt="gif" />
              <img draggable={false} src={loser} alt="gif" />
              <img draggable={false} src={worm} alt="gif" />
            </div>
            <p>
              <img draggable={false}
                className="pop_pointer"
                onClick={() => claimCD(account)}
                src={cd2}
                alt="gif"
              />
            </p>
            <div className="pop_column" onClick={() => claimCD(account)}>
              <img draggable={false} className="pop_pointer" src={claim} alt="gif" />
              <img draggable={false} className="pop_pointer" src={prize} alt="gif" />
              <img draggable={false} className="pop_pointer" src={prize2} alt="gif" />
              <img draggable={false} className="pop_pointer" src={chat} alt="gif" />
            </div>
            <img draggable={false} src={wiggle} alt="gif" />
            <img draggable={false} src={freemoney} alt="gif" />
            <img draggable={false} src={wiggle} alt="gif" />
          </div>
          <div className="pop_column">
            <img draggable={false} src={confetti} alt="gif" />
            <img draggable={false} src={confetti} alt="gif" />
            <img draggable={false} src={confetti} alt="gif" />
            <img draggable={false} src={confetti} alt="gif" />
          </div>
        </div>
      </section>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  background: var(--colorgrad3);
  .pop_row {
    display: flex;
    flex-direction: row;
    align-self: center;
  }
  .pop_column {
    display: flex;
    flex-direction: column;
  }
  #pop_vis {
    margin:0px 0 30px 0;
  }
  .pop_pointer {
    cursor:pointer;
  }
  h1 {
    margin:10px 0 30px 0;
    text-decoration: underline;
    font-style: italic;
    font-size: 32px;
    text-align: center;
    color: #ff0000;
    font-family: "Times New Roman", Times, serif;
  }
  h2 {
    margin:0 20px;
    line-height: 18px;
    font-size: 16px;
    text-align: center;
    color: #00ff00;

    font-family: "Times New Roman", Times, serif;
  }
  h3 {
    margin:0 20px;
    line-height: 20px;
    font-size: 18px;
    color: #0000ff;
    font-family: 'Comic Sans MS','Comic Neue',sans-serif;
  }
  p {
    margin: 20px 0 20px 0;
    line-height: 24px;
    font-size: 22px;
    color: #00ffff;
    font-family: "Arial", sans-serif;
  }
  span {
    font-weight: bold;
    margin: 20px 0 20px 0;
    font-size: 20px;
    font-style: italic;
    color: #ffff00;
    font-family: "Times New Roman", Times, serif;
  }
  .button {
    display: inline;
    margin-bottom: 30px;
  }
  p,
  span,
  a,
  td {
    user-select: text;
  }
  span {
    display: block;
  }
  .window_content_inner {
    display: flex;
    padding: 10px;
    overflow: auto;
    text-align: center;
    height: calc(100% - 3px);
    justify-content: space-between;
    align-items: flex-start;
    background: rgba(234,99,176, 0.5);
    background: linear-gradient(0deg, rgba(234,99,176,0.8) 0%, rgba(122,5,205,0.8) 78%);
    /* background-image: url(${tiles});
    background-size: contain; */
  }
  .window_content {
    height: calc(100% - 2px);
  }
  .window_toolbar_empty {
    height: 2px;
  }
`;

export default PopUp;
