import React from "react";
import styled from "styled-components";
import column from "assets/ghost/column.gif";
import anti from "assets/ghost/88x31_0.gif";
import chump from "assets/ghost/88x31_2.gif";
import powered from "assets/ghost/88x31_1.gif";
import corpo0 from "assets/ghost/corp_0.gif";
import corpo1 from "assets/ghost/corp_1.gif";
import corpo2 from "assets/ghost/corp_2.gif";
import corpo3 from "assets/ghost/corp_3.gif";
import divider from "assets/ghost/divider.gif";

const Main = ({
  standAlone,
  onMintWait,
  onMintDone,
  setTokenOwner,
  onToggleComponent,
  id,
  createViewList,
}) => {
  const handleReadMore = () => {
    const dots = document.getElementById("dots");
    const moreText = document.getElementById("more_text");
    const readMore = document.getElementById("read_more");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      readMore.innerHTML = "read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      readMore.innerHTML = "read less";
      moreText.style.display = "inline";
    }
  };

  return (
    <Div>
      <header className="gs_header">
        <img
          draggable={false}
          src={column}
          alt="favicon"
          className="gs_column"
        />
        <div className="gs_title">
          <div className="italic-outline">
            <span className="text">Ghost Software</span>
          </div>
          <div>on-chain errormancy</div>
        </div>
        <img
          draggable={false}
          src={column}
          alt="favicon"
          className="gs_column"
        />
      </header>
      <div className="gs_main">
        <div className="gs_top">
          <p>
            <span>
              “What a mad chance in this wild domain of flux, that apparitions
              fade and fuse through chromatic deviations and dissociations, and
              result in these divergent and perverted reflections of oneself.”
            </span>
          </p>
          <span id="mint_disabled">
            <div className="button disabled">Mint Your Ghost</div>
          </span>
          <p>
            GhostSoftware mint is closed.
            <br />
            Explore the{" "}
            <u
              className="classicLink pointer"
              onClick={() => {
                createViewList("All Tokens", "Main Hall");
              }}
            >
              temples
            </u>
            , search{" "}
            <a
              className="classicLink pointer"
              href="https://opensea.io/collection/heavencomputer"
              target="_blank"
              rel="noopener noreferrer"
            >
              OpenSea
            </a>{" "}
            for secondary sales, or...
            <br />
            experience the {" "}
            <u
              className="classicLink pointer"
              onClick={() => onToggleComponent("God Observer", "open", id)}
            >
              GodObserver
            </u> and <u
              className="classicLink pointer"
              onClick={() => onToggleComponent("Sky Luxury", "open", id)}
            >
              SkyLuxury
            </u> mints!
          </p>
        </div>
        <div className="gs_desc">
          <img draggable={false} src={divider} alt="favicon" />
          <p>
            GhostSoftware™ brings you the latest technology in divination,{" "}
            <span className="bold_span">On-Chain Errormancy</span>. Channel your
            intuition and let the algorithmic coincidences reveal anything you'd
            like to know about your past, present and future. Close your eyes,
            focus on a question and click{" "}
            <span className="bold_span">MINT YOUR GHOST</span> to access your
            ghosts' stone tape recordings.
            <span id="dots">...</span>
          </p>

          <span id="more_text">
            <p>
              For the modern spiritualists, soulsearchers and spectrophiliacs
              that seek spirits in obsolete technology,{" "}
              <span className="bold_span">
                the ghost in the machine has gone radio silent
              </span>
              . The floor doesn't creak and the lights don't flicker. Music
              streaming has taken the place of airwaves and silenced the eerie
              whispers of{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/Electronic_voice_phenomenon"
                target="_blank"
                rel="noopener noreferrer"
              >
                electronic voice phenomena
              </a>{" "}
              caused by{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/Electromagnetic_interference"
                target="_blank"
                rel="noopener noreferrer"
              >
                radio-frequency interference
              </a>
              . The once terribly feared{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/Phantasmagoria"
                target="_blank"
                rel="noopener noreferrer"
              >
                phantasmagoric camera obscuras
              </a>{" "}
              is today only a gimmick at child's play magic shows.{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/Pepper%27s_ghost"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pepper's ghost
              </a>
              , a shiver-inducing staple of 19th century séances, is the 21st
              century's{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/Pepper%27s_ghost#Concerts"
                target="_blank"
                rel="noopener noreferrer"
              >
                faux-hologram of Tupac
              </a>
              . The long misunderstood and awe-inducing ghostly outlines of{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/William_H._Mumler#Ghost_of_Abraham_Lincoln"
                target="_blank"
                rel="noopener noreferrer"
              >
                double exposure
              </a>
              , hovering orbs of{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/Backscatter_(photography)"
                target="_blank"
                rel="noopener noreferrer"
              >
                backscatter
              </a>
              , darkened vignette of{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/Daguerreotype"
                target="_blank"
                rel="noopener noreferrer"
              >
                daguerreotypes
              </a>{" "}
              are now innocuous and mundane filters on social media pictures.{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/EMF_measurement"
                target="_blank"
                rel="noopener noreferrer"
              >
                EMF meters
              </a>
              , so easily triggered by cellphones and bluetooth devices, lose
              their paranormal flair in an increasingly wireless world.
              Communication errors once caused by inexplicable morse code and{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/Telegraph_code"
                target="_blank"
                rel="noopener noreferrer"
              >
                cryptic messages
              </a>{" "}
              transmitted by phantom telegraph operators are nowadays justified
              by typos, hacks and autocorrect.{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/Scrying"
                target="_blank"
                rel="noopener noreferrer"
              >
                Scrying
              </a>{" "}
              is unwittingly performed everyday through the black mirrors of
              phone screens, however it reveals not a real spiritual world, but
              an unreal material world.
              <br />
              <br />
              Victorian scientists and scholars discovered the dwelling of
              ghosts: in static, blurs, errata, shadows, distortion, noise and
              other random glitches in which we’re hardwired to find false
              positives. Their paranormal research was born out of an
              appreciation for and misinterpretation of malfunctioning
              technology, and ghost technology was born out of actively
              engineering glitches.{" "}
              <span className="bold_span">
                A glitch, much like a ghost, parasitically uses a system as a
                conduit for the delivery of unexpected wisdom, therefore it
                makes for the perfect divination device.
              </span>{" "}
              But as poltergeists adjust to the zeitgeist, ghost technology must
              also upgrade to latest means of manifestation.
              <br />
              <br />
              The relatively new, experimental and haphazardly-growing web 3.0
              ecosytem is full of instabilities that are currently being
              exploited by relentless ghost data attempting to send encrypted
              transmissions into the world of living. Our team of Technomancy
              Researchers and Paranormal Engineers at GhostSoftware™ have
              developed the latest technology capable of reliably tracking
              ghosts that are haunting blocks and rattling chains on the
              blockchain, and decipher their encoded messages. Aptly named{" "}
              <span className="bold_span">On-chain Errormancy</span>, the
              algorithm detects supernatural activity within the chain and
              decrypts the transmission hash into a seed that morphs the visual
              representations of the ghost's energy based on their{" "}
              <a
                className="classicLink pointer"
                href="https://en.wikipedia.org/wiki/Stone_Tape"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stone Tape
              </a>{" "}
              recordings, and stores them in a video file by the means of
              divination.
              <br />
              <br />
              The divination process produces a motley of colourful
              phantasmagorical appearances, numberless airy figures, amorphous
              androgynous androids, absolutely bodiless ideas and forms of
              unsubstantial beauty that appear to dance in and out of time
              before the eye of the mind. Almost supernatural and uncanny in its
              nature, this fictitious splendour of a theatric spectacle is born
              from a sole spectator: you.{" "}
              <span className="bold_span">
                These are the ghosts that come from within you, follow you,
                speak to you and through you
              </span>
              . Their hollow eyes, faint grins and glassy countenance, all
              momentarily unknown yet eerily familiar, evoke nostalgia for a
              future that has already happened and invoke longing for an
              unforeseen past. They recount the untold memories of long-lost
              souls, as their words, spoken too late, remained hitherto unheard.
              As in an optical illusion, ghosts confront you with your own
              unbroken image in a fractal spectral mirror. What a mad chance in
              this wild domain of flux, that apparitions fade and fuse through
              chromatic deviations and dissociations, and result in these
              divergent and perverted reflections of oneself.
              <br />
              <br />
              Close your eyes, light a candle or incense, tune in to some
              binaural beats, do whatever helps you begin to ground and center
              yourself. Concentrate on the matter you desire guidance with. Form
              a question in your mind, precise and comprehensively. Rephrase and
              repeat until it is clear. When you feel you're ready, ask the
              question out loud and click the button that says{" "}
              <span className="bold_span">MINT YOUR GHOST</span>. Once your
              ghost has been minted, you'll be able to access the recording and
              read your answer. Whether you believe it or not, for every one of
              these momentary convergences, programmed serendipity and
              meaningful coincidences,{" "}
              <span className="bold_span">
                there is a ghost in the machine that is no longer silent
              </span>
              .
            </p>
          </span>
          <span
            onClick={() => handleReadMore()}
            id="read_more"
            className="bold_span pointer"
          >
            read more
          </span>
          <img
            draggable={false}
            src={divider}
            alt="favicon"
            className="gs_gear"
          />
        </div>
        <div className="gs_top">
          <p>
            <span>
              "Errare humanum est, perseverare autem <del>diabolicum</del>{" "}
              divinum"
            </span>
          </p>
        </div>
        <div className="gs_header">
          <img draggable={false} src={anti} alt="favicon" />
          <img draggable={false} src={powered} alt="favicon" />
          <img draggable={false} src={chump} alt="favicon" />
        </div>
        <div className="gs_header">
          <img draggable={false} src={corpo0} alt="favicon" />
          <img draggable={false} src={corpo1} alt="favicon" />
          <img draggable={false} src={corpo2} alt="favicon" />
          <img draggable={false} src={corpo3} alt="favicon" />
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 10px;
  letter-spacing: 1.5px;
  line-height: 13px;
  text-align: center;
  padding: 10px;
  p {
    color: black !important;
  }
  .input {
    box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad0),
      inset -2px -2px 0 0 var(--colorgrad5), inset 2px 2px 0 0 var(--colorgrad2);
    background: var(--colorgrad3);
    padding: 4px;
    height: 30px;
    margin-right: 4px;
    input {
      text-decoration: none;
      font-size: 11px;
      letter-spacing: 2px;
      border: 0;
      outline: 0;
      box-shadow: inset -1px -1px 0px var(--colorgrad0), inset 1px 1px 0px 0px var(--colorgrad6),
        inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8);
      background: #ffffff;
      height: 22px;
      padding: 4px;
      white-space: nowrap;
    }
  }
  img {
    max-width: 100%;
  }
  span.error {
    color: #dc143c;
    font-size: 11px;
  }
  #read_more {
    user-select: none;
  }
  #more_text,
  #someone_else {
    display: none;
  }
  #someone_else,
  #mint_disabled {
    flex-direction: column;
  }
  #someone_else,
  #your_own,
  form,
  #mint_disabled {
    align-items: center;
    justify-content: center;
    height: 32px;
  }
  #your_own,
  form,
  #mint_disabled {
    display: flex;
  }

  .gs_top {
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px;
    flex-direction: column;
  }
  .gs_top span:not(.error) {
    color: var(--colorgrad7);
    font-size: 11px;
    max-width: 400px;
  }
  .gs_top p {
    margin: 20px;
  }
  .gs_main div + div {
    margin: 10px 0;
  }
  .gs_main div img {
    margin: 0 5px;
  }
  .gs_desc span.bold_span {
    display: inline;
    font-weight: bold;
    color: #551a8b;
  }
  .gs_desc {
    max-width: 425px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
  .gs_desc p {
    text-align-last: center;
    padding: 25px;
  }
  .gs_gear {
    -moz-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
  .gs_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  p {
    font-size: 12px;
    line-height: 13px;
  }
  .gs_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .gs_column {
    width: 8em;
    height: 8em;
    margin-top: 10px;
  }
  .gs_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .gs_title div {
    margin-top: 10px;
    &:nth-child(2) {
      letter-spacing: 3px;
      color: var(--colorgrad5);
    }
  }
  .italic-outline {
    font-family: Arial, sans-serif;
    font-size: 4vh;
    font-weight: bold;
    z-index: 1;
    display: inline-block;
    transform: scale(1, 1.3);
    -webkit-transform: scale(1, 1.3);
    -moz-transform: scale(1, 1.3);
    -o-transform: scale(1, 1.3);
    -ms-transform: scale(1, 1.3);
  }
  .italic-outline .text {
    user-select: none;
    letter-spacing: -0.01em;
    line-height: 30px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    -webkit-text-stroke: 0.01em #000;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=#000000, Strength=1);
    text-shadow: 0.03em 0.03em 0 #6d6d6d;
  }
  .button {
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default Main;
