const loDrums = [
  require("assets/remixer/drum/lo/0.wav"),
  require("assets/remixer/drum/lo/1.wav"),
  require("assets/remixer/drum/lo/2.wav"),
  require("assets/remixer/drum/lo/3.wav"),
  require("assets/remixer/drum/lo/4.wav"),
  require("assets/remixer/drum/lo/5.wav"),
  require("assets/remixer/drum/lo/6.wav"),
  require("assets/remixer/drum/lo/7.wav"),
  require("assets/remixer/drum/lo/8.wav"),
  require("assets/remixer/drum/lo/9.wav"),
  require("assets/remixer/drum/lo/10.wav"),
  require("assets/remixer/drum/lo/11.wav"),
  require("assets/remixer/drum/lo/12.wav"),
  require("assets/remixer/drum/lo/13.wav"),
  require("assets/remixer/drum/lo/14.wav"),
  require("assets/remixer/drum/lo/15.wav"),
  require("assets/remixer/drum/lo/16.wav"),
];

export default loDrums;
