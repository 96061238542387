import React, { useGlobal, setGlobal, useEffect, useState } from "reactn";
import styled from "styled-components";
import HeavenComputer from "artifacts/HeavenComputer.json";
import { ethers } from "ethers";

const Intro = ({
  onMintWait,
  onMintDone,
  standAlone,
  createViewList,
  id,
  onToggleComponent,
}) => {
  const collectionId = 1;
  const [provider] = useGlobal("provider");
  const [connected] = useGlobal("connected");
  const [account] = useGlobal("account");
  const [contract] = useGlobal("contract");
  const [details, setDetails] = useState("");
  const [receiver, setReceiver] = useState("");
  const [error, setError] = useState(null);
  const notConnectedError = "oops! looks like you're not connected."

  useEffect(() => {
    if (error === notConnectedError && connected) setError(null);
    else if (error) document.querySelector(".godmint.error").innerHTML = error;
  }, [error, connected]);

  useEffect(() => {
    if (contract)
      contract["viewCollectionDetails(uint256)"](collectionId).then((r) =>
        setDetails(Object.values(r))
      );
  }, [contract]);

  async function purchase() {
    if (!connected) return;
    const abi = HeavenComputer.abi;
    const web3 = new ethers.providers.Web3Provider(provider);
    const signer = web3.getSigner(account);
    const writeContract = new ethers.Contract(contract.address, abi, signer);
    const price = details[1];
    const tx = await writeContract
      .purchase(collectionId, {
        value: price,
      })
      .catch((err) => {
        if (err.code === "ACTION_REJECTED") {
          setError("why would you reject god?");
        } else if (err.code === "INSUFFICIENT_FUNDS") {
          setError("looks like there is not enough ETH in this wallet.");
        } else {
          setError("oops! something went wrong.");
        }
      });
    await tx
      .wait(
        setError("success! please don't close this window. your god is coming..."),
        setGlobal({ appLoading: { name: "God Observer", id: id } }),
        setGlobal({ loading: true }),
        onMintWait()
      )
      .then((receipt) => {
        const mintedToken = receipt.events[0].args["tokenId"].toNumber();
        onMintDone(mintedToken);
        setError(null);
        console.log("minted token: " + mintedToken);
      })
      .catch((err) => {
        setError("oops! something went wrong.");
        setGlobal({ appLoading: null });
        setGlobal({ loading: false });
        console.log(err);
      });
  }

  async function otherPurchase(receiver) {
    if (ethers.utils.isAddress(receiver)) {
      try {
        const address = ethers.utils.getAddress(receiver);
        const abi = HeavenComputer.abi;
        const web3 = new ethers.providers.Web3Provider(provider);
        const signer = web3.getSigner(account);
        const writeContract = new ethers.Contract(
          contract.address,
          abi,
          signer
        );
        const price = details[1];
        const tx = await writeContract.purchaseTo(address, collectionId, {
          value: price,
        }).catch((err) => {
          if (err.code === "ACTION_REJECTED") {
            setError("why would you reject god?");
          } else if (err.code === "INSUFFICIENT_FUNDS") {
            setError("looks like there is not enough ETH in this wallet...");
          } else {
            setError("oops! something went wrong.");
          }
        });
        await tx
          .wait(
            setError("success! please wait for your god..."),
            setGlobal({ appLoading: { name: "God Observer", id: id } }),
            setGlobal({ loading: true }),
            onMintWait()
          )
          .then((receipt) => {
            const mintedToken = receipt.events[0].args["tokenId"].toNumber();
            onMintDone(mintedToken);
            setError(null);
            console.log("minted token: " + mintedToken);
          })
          .catch((err) => {
            setError("oops! something went wrong.");
            setGlobal({ appLoading: null });
            setGlobal({ loading: false });
            console.log(err);
          });
      } catch {
        setError("oops! something went wrong.");
        return;
      }
    } else {
      setError("oops! invalid eth address. try again.");
      return;
    }
  }

  const handleOtherMint = () => {
    const yourOwn = document.getElementById("your_own");
    const moreText = document.getElementById("someone_else");
    const switchMint = document.getElementById("switch_mint");
    if (yourOwn.style.display === "none") {
      yourOwn.style.display = "flex";
      switchMint.innerHTML = "or mint a god for someone else...";
      moreText.style.display = "none";
    } else {
      yourOwn.style.display = "none";
      switchMint.innerHTML = "or mint a god for yourself...";
      moreText.style.display = "flex";
    }
  };
  
  return (
    <Div className="selectable">
      <p>Welcome to the GodObserver™ webpage!</p>
      {standAlone && (
        <>
          <div id="go_mint">
            <span id="your_own">
              <div
                onClick={() => {
                  window.location.href = "https://heaven.computer";
                }}
                className="go_mint_btn pointer"
              >
                MINT YOUR GOD
              </div>
            </span>
          </div>
        </>
      )}
      {!standAlone && (
        <>
          <div id="go_mint">
            {error && <span className="godmint error"></span>}
            <span id="your_own">
              <div
                className={
                  "go_mint_btn " +
                  (!connected && error === notConnectedError ? "disabled" : "pointer")
                }
                onClick={() => {
                  if (connected) {
                    setError(null);
                    purchase();
                  } else {
                    if (error === notConnectedError) return;
                    onToggleComponent("Connection Wizard", "open", id);
                    setError(notConnectedError);
                  }
                }}
              >
                MINT YOUR GOD
              </div>
            </span>
            <span id="someone_else">
              <form autoComplete="off" noValidate="novalidate">
                <div
                  className="go_input"
                  onChange={(e) => setReceiver(e.target.value)}
                >
                  <input
                    className="go_mint_btn texter"
                    required
                    minLength="10"
                    maxLength="52"
                    size="42"
                    id="eth_address"
                    type="text"
                    name="address"
                    placeholder="enter ETH address"
                  ></input>
                </div>
                <div
                  className={
                    "go_mint_btn " +
                    (!connected && error === notConnectedError
                      ? "disabled"
                      : "pointer")
                  }
                  onClick={() => {
                    if (connected) {
                      setError(null);
                      otherPurchase(receiver);
                    } else {
                      if (error === notConnectedError) return;
                      setError(notConnectedError);
                      onToggleComponent("Connection Wizard", "open", id);
                    }
                  }}
                >
                  MINT
                </div>
              </form>
            </span>
            <span
              className="go_links pointer"
              onClick={() => handleOtherMint()}
              id="switch_mint"
            >
              or mint a god for someone else...
            </span>
          </div>
        </>
      )}
      <p style={{ paddingBottom: "12px" }}>
        Mint tokens that will grant you access to go on
        board the space shuttle in a secret mission to deploy and operate the
        revolutionary GodObserver™ Telescope!<br />
        Feel free to explore the <span
          className="go_links pointer"
          onClick={() => {
            createViewList("All Tokens", "Intro");
            if (standAlone) window.history.pushState("", "", "/catalogue/alltokens");
          }}
        >
          catalogue
        </span> or learn more about our <span
          className="go_links pointer"
          onClick={() => {
            createViewList("Research", "Intro");
            if (standAlone) window.history.pushState("", "", "/research");
          }}
        >
          research
        </span>.
      </p>
      <p>
        <b>PHOTOSENSITIVITY WARNING:</b> this experiment may contain flashing
        lights.
      </p>
      <p>Viewer discretion is advised.</p>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  #switch_mint:not(.disabled) {
    line-height: 13px;
    user-select: none;
    text-align: center;
  }
  .godmint.error {
    color: #b19cd9 !important;
    font-weight: bold;
    text-align: center;
    padding-bottom: 12px;
    font-size: 12px;
    letter-spacing: 2px;
  }
  #go_mint {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .go_input {
    margin-right: 4px;
  }
  p {
    font-size: 11px;
    text-align: center;
    line-height: 16px;
    text-align-last: center;
    letter-spacing: 1.5px;
    flex-direction: column;
  }
  #switch_mint.disabled {
    color: #a099a1;
    user-select: none;
  }
  #more_text,
  #someone_else {
    display: none;
  }
  #someone_else {
    flex-direction: column;
  }
  #someone_else,
  #your_own,
  form {
    align-items: center;
    justify-content: center;
    height: 32px;
  }
  #your_own,
  form {
    display: flex;
  }
`;

export default Intro;