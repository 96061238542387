import React, { setGlobal, useState, useEffect } from "reactn";
import styled from "styled-components";
import GodImage from "./GodImage.js";
import axios from "axios";
import { mathUnicode, lengths } from "./utils/mathUnicode.js";
import { displayMetadata } from "./utils/displaymetadata.js";
import { objects } from "./utils/objects.js";

const ViewToken = ({
  standAlone,
  tokenOwner,
  allTokens,
  baseURI,
  id,
  onToggleComponent,
  handleViewToken,
  handleViewUser,
  handleViewObjObserv,
  token = 0,
}) => {
  const [tokenInput, setTokenInput] = useState(null);
  const initMetadata = {
    name: "",
    godarchetypecode: "loading...",
    godconstellationname: "loading...",
    celestialobjectname: "loading...",
    celestialobjecttype: "loading...",
    datavisualizationcode: "loading...",
    datasonificationname: "loading...",
    musicauniversaliscode: "loading...",
    description: "loading...",
    ipfsHash: "loading...",
  };
  const [metadata, setMetadata] = useState(initMetadata);

  useEffect(() => {
    function fetchMetadata(tokenId) {
      const foundMetadata = displayMetadata.find(
        (item) => parseInt(item.tokenId) === parseInt(tokenId)
      );
      if (foundMetadata) {
        setMetadata(foundMetadata);
      } else {
        axios
          .get(baseURI + "/token/" + tokenId, {
            responseType: "json",
          })
          .then((response) => {
            setMetadata({
              name: response.data.name.split("#100")[0],
              godarchetypecode: response.data.attributes[0].value,
              godconstellationname: response.data.attributes[1].value,
              celestialobjectname: response.data.attributes[2].value,
              celestialobjecttype: response.data.attributes[3].value,
              datavisualizationcode: response.data.attributes[4].value,
              datasonificationname: response.data.attributes[5].value,
              musicauniversaliscode: response.data.attributes[6].value,
              description: response.data.description.split("*")[0],
              ipfsHash: response.data["image"].substring(7),
            });
          });
      }
    }
    if (allTokens && token) {
      fetchMetadata(token);
      const last = document.getElementById(id + "v_go_last").classList;
      const first = document.getElementById(id + "v_go_first").classList;
      const next = document.getElementById(id + "v_go_next").classList;
      const prev = document.getElementById(id + "v_go_previous").classList;
      if (
        parseInt(token) === 1000000 &&
        parseInt(token) !== 1000000 + (allTokens.length - 1)
      ) {
        last.remove("disabled");
        prev.add("disabled");
        first.add("disabled");
        next.remove("disabled");
      } else if (
        parseInt(token) !== 1000000 &&
        parseInt(token) === 1000000 + (allTokens.length - 1)
      ) {
        last.add("disabled");
        next.add("disabled");
        prev.remove("disabled");
        first.remove("disabled");
      } else if (parseInt(token) === 1000000 && allTokens.length - 1 === 0) {
        first.add("disabled");
        next.add("disabled");
        prev.add("disabled");
        last.add("disabled");
      } else {
        first.remove("disabled");
        next.remove("disabled");
        prev.remove("disabled");
        last.remove("disabled");
      }
    }
  }, [allTokens, token, baseURI, id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tokenInput >= 1000000 && tokenInput < 1000000 + allTokens.length) {
      handleViewToken(tokenInput);
    } else {
      e.target.classList.add("shake_err");
      setTimeout(() => {
        e.target.classList.remove("shake_err");
      }, 700);
      return;
    }
  };

  function handleTokenChange(number) {
    if (number === parseInt(token) || number < 1000000 || number >= 1000000 + allTokens.length) {
      return;
    } else {
      setMetadata(initMetadata);
      handleViewToken(number);
    }
  }

  function toggleImageSize() {
    const images = document.querySelectorAll(".goti" + id);
    if (images) {
      images.forEach((image, i) => {
        if (image.classList.contains("large")) {
          image.style.width = "426px";
          image.classList.remove("large");
        } else {
          image.style.width = "640px";
          image.classList.add("large");
        }
      });
    }
  }
  return (
    <Div>
      <div className="token_item selectable">
        <div className="buttons_row">
          <div
            className="go_mint_btn pointer"
            id={id + "v_go_first"}
            onClick={() => handleTokenChange(1000000)}
          >
            &#60;&#60;
          </div>
          <div
            className="go_mint_btn pointer"
            id={id + "v_go_previous"}
            onClick={() => handleTokenChange(parseInt(token) - 1)}
          >
            &#60;
          </div>
          <form
            className="go_input"
            autoComplete="off"
            onSubmit={(e) => handleSubmit(e)}
            noValidate="novalidate"
          >
            <input
              className="texter"
              minLength="1"
              maxLength="4"
              size="4"
              min="0"
              max={allTokens.length - 1}
              id="token_page"
              type="number"
              name="token page"
              placeholder="0000"
              onChange={(e) =>
                setTokenInput(parseInt(e.target.value) + 1000000)
              }
            ></input>
          </form>
          <div
            className="go_mint_btn pointer"
            id={id + "v_go_next"}
            onClick={() => handleTokenChange(parseInt(token) + 1)}
          >
            &#62;
          </div>
          <div
            className="go_mint_btn pointer"
            id={id + "v_go_last"}
            onClick={() => handleTokenChange(1000000 + (allTokens.length - 1))}
          >
            &#62;&#62;
          </div>
        </div>
        <div className="go_token_upper">
          <div className="go_token_box_wrap">
            <div
              className="go_token_box pointer"
              onClick={() => toggleImageSize(token)}
            >
              <GodImage
                id={id}
                baseURI={baseURI}
                token={token}
                ipfsHash={metadata.ipfsHash}
              />
            </div>
          </div>

          <div className="token_caption">
            <div>
              <h2>god #{token - 1000000}</h2>{" "}
              <span className="codeglyph">{metadata.name}</span>
            </div>
            {standAlone && (
              <>
                <div>
                  <b>owned by</b>
                  <span className="tokenowner">
                    {tokenOwner ? tokenOwner : "loading..."}
                  </span>
                </div>
                <div className="see_all_row">
                  <div
                    className={`go_mint_btn see_all pointer${
                      tokenOwner ? "" : " disabled"
                    }`}
                    onClick={() => {
                      if (!tokenOwner) return;
                      handleViewUser(tokenOwner);
                    }}
                  >
                    <p>see user catalogue</p>
                  </div>
                  <div
                    className={`go_mint_btn see_all pointer${
                      metadata.celestialobjectname !== "loading..."
                        ? ""
                        : " disabled"
                    }`}
                    onClick={() => {
                      window.location.href = "https://heaven.computer";
                    }}
                  >
                    <p>open in godremixer</p>
                  </div>
                  <div
                    className={`go_mint_btn see_all pointer${
                      metadata.celestialobjectname !== "loading..."
                        ? ""
                        : " disabled"
                    }`}
                    onClick={() =>
                      handleViewObjObserv(
                        objects.find(
                          (e) => e.name === metadata.celestialobjectname
                        )
                      )
                    }
                  >
                    <p>view in observatory</p>
                  </div>
                </div>
              </>
            )}
            {!standAlone && (
              <>
                <div>
                  <b>owned by</b>
                  <span className="tokenowner">
                    {tokenOwner ? tokenOwner : "loading..."}
                  </span>
                </div>
                <div className="see_all_row">
                  <div
                    className={`go_mint_btn see_all pointer${
                      metadata && metadata.ipfsHash !== "loading..."
                        ? ""
                        : " disabled"
                    }`}
                    onClick={() => {
                      if (!metadata || metadata.ipfsHash === "loading...")
                        return;
                      onToggleComponent("God Remixer", "close", id);
                      const mus = metadata.musicauniversaliscode.split(".");
                      const lead = Math.round(
                        (mathUnicode.indexOf(mus[0].charAt(0)) * lengths.lead) /
                          mathUnicode.length
                      );
                      const pad = Math.round(
                        (mathUnicode.indexOf(mus[1].charAt(0)) * lengths.pad) /
                          mathUnicode.length
                      );
                      const kick = Math.round(
                        (mathUnicode.indexOf(mus[2].charAt(0)) * lengths.kick) /
                          mathUnicode.length
                      );
                      const perc = Math.round(
                        (mathUnicode.indexOf(mus[3].charAt(0)) * lengths.perc) /
                          mathUnicode.length
                      );
                      const bass = Math.round(
                        (mathUnicode.indexOf(mus[4].charAt(0)) * lengths.bass) /
                          mathUnicode.length
                      );
                      const noise = Math.round(
                        (mathUnicode.indexOf(mus[5].charAt(0)) *
                          lengths.noise) /
                          mathUnicode.length
                      );
                      const scale = mus[6];
                      setGlobal({
                        godMounted: {
                          tokenId: token,
                          videoURL: baseURI + "/god/" + token,
                          lead: lead,
                          pad: pad,
                          kick: kick,
                          perc: perc,
                          bass: bass,
                          noise: noise,
                          scale: scale,
                        },
                      });
                      onToggleComponent("God Remixer", "open", id);
                    }}
                  >
                    <p>open in godremixer</p>
                  </div>
                  <div
                    className={`go_mint_btn see_all pointer${
                      metadata.celestialobjectname !== "loading..."
                        ? ""
                        : " disabled"
                    }`}
                    onClick={() =>
                      handleViewObjObserv(
                        objects.find(
                          (e) => e.name === metadata.celestialobjectname
                        )
                      )
                    }
                  >
                    <p>view in observatory</p>
                  </div>
                  <div
                    className={`go_mint_btn see_all pointer${
                      tokenOwner ? "" : " disabled"
                    }`}
                    onClick={() => {
                      if (!tokenOwner) return;
                      handleViewUser(tokenOwner);
                    }}
                  >
                    <p>see user catalogue</p>
                  </div>
                </div>
              </>
            )}
            <div>
              <p>{metadata.description}</p>
            </div>
          </div>
        </div>
        <table className="go_table">
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                element
              </th>
              <th scope="col" colSpan="1">
                feature
              </th>
              <th scope="col" colSpan="3">
                value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2">god archetype</td>
              <td colSpan="1">code</td>
              <td colSpan="3">{metadata.godarchetypecode}</td>
            </tr>
            <tr>
              <td colSpan="2">god constellation</td>
              <td colSpan="1">name</td>
              <td colSpan="3">{metadata.godconstellationname}</td>
            </tr>
            <tr>
              <td colSpan="2">celestial object</td>
              <td colSpan="1">name</td>
              <td colSpan="3">{metadata.celestialobjectname}</td>
            </tr>
            <tr>
              <td colSpan="2">celestial object</td>
              <td colSpan="1">type</td>
              <td colSpan="3">{metadata.celestialobjecttype}</td>
            </tr>
            <tr>
              <td colSpan="2">data visualization</td>
              <td colSpan="1">code</td>
              <td colSpan="3">{metadata.datavisualizationcode}</td>
            </tr>
            <tr>
              <td colSpan="2">data sonification</td>
              <td colSpan="1">name</td>
              <td colSpan="3">{metadata.datasonificationname}</td>
            </tr>
            <tr>
              <td colSpan="2">musica universalis</td>
              <td colSpan="1">code</td>
              <td colSpan="3" style={{ textTransform: "none" }}>
                {metadata.musicauniversaliscode}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th scope="row" colSpan="2">
                MP4 IPFS hash
              </th>
              <td colSpan="4">{metadata.ipfsHash}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Div>
  );
};

const Div = styled.div`
  video {
    background-image: url(loadImg);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: center;
  }
  .codeglyph {
    margin-top: -5px;
    padding: 0;
    font-size: 14px;
  }
  .go_token_upper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
`;

export default ViewToken;
