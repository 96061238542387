import { useEffect, useState, setGlobal } from 'reactn';
import main from 'assets/cursors/cursor.cur';
import n_resize from 'assets/cursors/n_resize.cur';
import s_resize from 'assets/cursors/s_resize.cur';
import e_resize from 'assets/cursors/e_resize.cur';
import w_resize from 'assets/cursors/w_resize.cur';
import ne_resize from 'assets/cursors/ne_resize.cur';
import nw_resize from 'assets/cursors/nw_resize.cur';
import se_resize from 'assets/cursors/se_resize.cur';
import sw_resize from 'assets/cursors/sw_resize.cur';
import { clamp } from "utils";

const useElementResize = (ref, options) => {
  const {
    defaultOffset,
    defaultSize,
    boundary,
    error = false,
    resizable = true,
    resizeThreshold = 10,
    constraintSize = 202,
  } = options;
  const [offset, setOffset] = useState(defaultOffset);
  const [size, setSize] = useState(defaultSize);
  const cursorPos = useCursor(ref, resizeThreshold, resizable);
  useEffect(() => {
    const root = document.querySelector(":root").style;
    const footerHeight = document.getElementById("taskbar").offsetHeight;
    const target = ref.current;
    if (!target) return;
    const dragTarget = options.dragRef && options.dragRef.current;
    const cover = document.createElement('div');
    cover.style.position = 'fixed';
    cover.style.top = 0;
    cover.style.left = 0;
    cover.style.right = 0;
    cover.style.bottom = 0;
    const previousOffset = { ...offset };
    const previousSize = { ...size };
    let _boundary;
    let originMouseX;
    let originMouseY;
    let targetClone;
    let cloneArray = [];
    function onDragging(e) {
      const zoom = root.getPropertyValue('--zoom');
      const winW = zoom ? window.innerWidth * 1/zoom  : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const winH = zoom ? window.innerHeight * 1/zoom  : window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const maxX = winW - target.offsetWidth - 2;
      const maxY = winH - target.offsetHeight - footerHeight - 2;
      const { pageX, pageY } = getComputedPagePosition(e, _boundary);
      const x0 = pageX - originMouseX + previousOffset.x;
      const y0 = pageY - originMouseY + previousOffset.y;
      const x = Math.round(clamp(x0, 0, maxX));
      const y = Math.round(clamp(y0, 0, maxY));
      setOffset({ x, y });
      if (error) {
        let targetParent = target.parentElement;
        targetClone = targetParent.cloneNode(true);
        targetClone.style.transform = 'translate(x, y)';
        targetClone.style.zIndex = '50';
        cloneArray.push(targetClone);
        targetParent.parentElement.appendChild(targetClone);
        if (cloneArray.length > 200) {
          window.localStorage.removeItem("loggedIntoHeaven");
          setGlobal({bluescreen: true});
        }
      }
    }
    function onDragEnd(e) {
        cover.remove();
        const { pageX, pageY } = getComputedPagePosition(e, _boundary);
        previousOffset.x += pageX - originMouseX;
        previousOffset.y += pageY - originMouseY;
        window.removeEventListener('pointermove', onDragging);
        window.removeEventListener('pointerup', onDragEnd);
    }
    function onDragStart(e) {
      window.addEventListener('pointermove', onDragging);
      window.addEventListener('pointerup', onDragEnd);
    }
    function onDraggingTop(e) {
      const { pageY } = getComputedPagePosition(e, _boundary);
      const { x } = previousOffset;
      const y = pageY - originMouseY + previousOffset.y;
      setOffset({ x, y });
    }
    function onDragEndTop(e) {
      const { pageY } = getComputedPagePosition(e, _boundary);
      previousOffset.y += pageY - originMouseY;
      window.removeEventListener('pointermove', onDraggingTop);
      window.removeEventListener('pointerup', onDragEndTop);
    }
    function onDragStartTop(e) {
      window.addEventListener('pointermove', onDraggingTop);
      window.addEventListener('pointerup', onDragEndTop);
    }
    function onDraggingLeft(e) {
      const { pageX } = getComputedPagePosition(e, _boundary);
      const x = pageX - originMouseX + previousOffset.x;
      const { y } = previousOffset;
      setOffset({ x, y });
    }
    function onDragEndLeft(e) {
      const { pageX } = getComputedPagePosition(e, _boundary);
      previousOffset.x += pageX - originMouseX;
      window.removeEventListener('pointermove', onDraggingLeft);
      window.removeEventListener('pointerup', onDragEndLeft);
    }
    function onDragStartLeft(e) {
      window.addEventListener('pointermove', onDraggingLeft);
      window.addEventListener('pointerup', onDragEndLeft);
    }
    function onResizingRight(e) {
      const { pageX } = getComputedPagePosition(e, _boundary);
      const width = pageX - originMouseX + previousSize.width;
      const { height } = previousSize;
      setSize({ width, height });
    }
    function onResizeEndRight(e) {
      const { pageX } = getComputedPagePosition(e, _boundary);
      previousSize.width += pageX - originMouseX;
      window.removeEventListener('pointermove', onResizingRight);
      window.removeEventListener('pointerup', onResizeEndRight);
    }
    function onResizeStartRight(e) {
      window.addEventListener('pointermove', onResizingRight);
      window.addEventListener('pointerup', onResizeEndRight);
    }
    function onResizingBottom(e) {
      const { pageY } = getComputedPagePosition(e, _boundary);
      const { width } = previousSize;
      const height = pageY - originMouseY + previousSize.height;
      setSize({ width, height });
    }
    function onResizeEndBottom(e) {
      const { pageY } = getComputedPagePosition(e, _boundary);
      previousSize.height += pageY - originMouseY;
      window.removeEventListener('pointermove', onResizingBottom);
      window.removeEventListener('pointerup', onResizeEndBottom);
    }
    function onResizeStartBottom(e) {
      window.addEventListener('pointermove', onResizingBottom);
      window.addEventListener('pointerup', onResizeEndBottom);
    }
    function onResizingLeft(e) {
      const { pageX } = getComputedPagePosition(e, _boundary);
      const width = -pageX + originMouseX + previousSize.width;
      const { height } = previousSize;
      setSize({ width, height });
    }
    function onResizeEndLeft(e) {
      const { pageX } = getComputedPagePosition(e, _boundary);
      previousSize.width += -pageX + originMouseX;
      window.removeEventListener('pointermove', onResizingLeft);
      window.removeEventListener('pointerup', onResizeEndLeft);
    }
    function onResizeStartLeft(e) {
      window.addEventListener('pointermove', onResizingLeft);
      window.addEventListener('pointerup', onResizeEndLeft);
    }
    function onResizingTop(e) {
      const { pageY } = getComputedPagePosition(e, _boundary);
      const height = -pageY + originMouseY + previousSize.height;
      const { width } = previousSize;
      setSize({ width, height });
    }
    function onResizeEndTop(e) {
      const { pageY } = getComputedPagePosition(e, _boundary);
      previousSize.height += -pageY + originMouseY;
      window.removeEventListener('pointermove', onResizingTop);
      window.removeEventListener('pointerup', onResizeEndTop);
    }
    function onResizeStartTop(e) {
      window.addEventListener('pointermove', onResizingTop);
      window.addEventListener('pointerup', onResizeEndTop);
    }
    function onResizingTopLeft(e) {
      const { pageX, pageY } = getComputedPagePosition(e, _boundary);
      const width = -pageX + originMouseX + previousSize.width;
      const height = -pageY + originMouseY + previousSize.height;
      setSize({ width, height });
    }
    function onResizeEndTopLeft(e) {
      const { pageX, pageY } = getComputedPagePosition(e, _boundary);
      previousSize.width += -pageX + originMouseX;
      previousSize.height += -pageY + originMouseY;
      window.removeEventListener('pointermove', onResizingTopLeft);
      window.removeEventListener('pointerup', onResizeEndTopLeft);
    }
    function onResizeStartTopLeft(e) {
      window.addEventListener('pointermove', onResizingTopLeft);
      window.addEventListener('pointerup', onResizeEndTopLeft);
    }
    function onResizingTopRight(e) {
      const { pageX, pageY } = getComputedPagePosition(e, _boundary);
      const width = pageX - originMouseX + previousSize.width;
      const height = -pageY + originMouseY + previousSize.height;
      setSize({ width, height });
    }
    function onResizeEndTopRight(e) {
      const { pageX, pageY } = getComputedPagePosition(e, _boundary);
      previousSize.width += pageX - originMouseX;
      previousSize.height += -pageY + originMouseY;
      window.removeEventListener('pointermove', onResizingTopRight);
      window.removeEventListener('pointerup', onResizeEndTopRight);
    }
    function onResizeStartTopRight(e) {
      window.addEventListener('pointermove', onResizingTopRight);
      window.addEventListener('pointerup', onResizeEndTopRight);
    }
    function onResizingBottomLeft(e) {
      const { pageX, pageY } = getComputedPagePosition(e, _boundary);
      const width = -pageX + originMouseX + previousSize.width;
      const height = pageY - originMouseY + previousSize.height;
      setSize({ width, height });
    }
    function onResizeEndBottomLeft(e) {
      const { pageX, pageY } = getComputedPagePosition(e, _boundary);
      previousSize.width += -pageX + originMouseX;
      previousSize.height += pageY - originMouseY;
      window.removeEventListener('pointermove', onResizingBottomLeft);
      window.removeEventListener('pointerup', onResizeEndBottomLeft);
    }
    function onResizeStartBottomLeft(e) {
      window.addEventListener('pointermove', onResizingBottomLeft);
      window.addEventListener('pointerup', onResizeEndBottomLeft);
    }
    function onResizingBottomRight(e) {
      const { pageX, pageY } = getComputedPagePosition(e, _boundary);
      const width = pageX - originMouseX + previousSize.width;
      const height = pageY - originMouseY + previousSize.height;
      setSize({ width, height });
    }
    function onResizeEndBottomRight(e) {
      const { pageX, pageY } = getComputedPagePosition(e, _boundary);
      previousSize.width += pageX - originMouseX;
      previousSize.height += pageY - originMouseY;
      window.removeEventListener('pointermove', onResizingBottomRight);
      window.removeEventListener('pointerup', onResizeEndBottomRight);
    }
    function onResizeStartBottomRight(e) {
      window.addEventListener('pointermove', onResizingBottomRight);
      window.addEventListener('pointerup', onResizeEndBottomRight);
    }
    function onPointerDown(e) {
      originMouseX = e.pageX;
      originMouseY = e.pageY;
      _boundary = { ...boundary };
      if (dragTarget && e.target === dragTarget) {
        document.body.appendChild(cover);
        return onDragStart(e);
      }
      if (e.target !== target || !resizable) return;
      switch (cursorPos) {
        case 'topLeft':
          _boundary.right = originMouseX + previousSize.width - constraintSize;
          _boundary.bottom =
            originMouseY + previousSize.height - constraintSize;
          onResizeStartTopLeft(e);
          onDragStart(e);
          break;
        case 'left':
          _boundary.right = originMouseX + previousSize.width - constraintSize;
          onResizeStartLeft(e);
          onDragStartLeft(e);
          break;
        case 'bottomLeft':
          _boundary.right = originMouseX + previousSize.width - constraintSize;
          _boundary.top = originMouseY - previousSize.height + constraintSize;
          onResizeStartBottomLeft(e);
          onDragStartLeft(e);
          break;
        case 'top':
          _boundary.bottom =
            originMouseY + previousSize.height - constraintSize;
          onResizeStartTop(e);
          onDragStartTop(e);
          break;
        case 'topRight':
          _boundary.bottom =
            originMouseY + previousSize.height - constraintSize;
          _boundary.left = originMouseX - previousSize.width + constraintSize;
          onDragStartTop(e);
          onResizeStartTopRight(e);
          break;
        case 'right':
          _boundary.left = originMouseX - previousSize.width + constraintSize;
          onResizeStartRight(e);
          break;
        case 'bottomRight':
          _boundary.top = originMouseY - previousSize.height + constraintSize;
          _boundary.left = originMouseX - previousSize.width + constraintSize;
          onResizeStartBottomRight(e);
          break;
        case 'bottom':
          _boundary.top = originMouseY - previousSize.height + constraintSize;
          onResizeStartBottom(e);
          break;
        default:
      }
    }
    target.addEventListener('pointerdown', onPointerDown);
    return () => {
      target.removeEventListener('pointerdown', onPointerDown);
      window.removeEventListener('pointermove', onDraggingLeft);
      window.removeEventListener('pointermove', onDraggingTop);
      window.removeEventListener('pointermove', onDragging);
      window.removeEventListener('pointerup', onDragEndTop);
      window.removeEventListener('pointerup', onDragEndLeft);
      window.removeEventListener('pointerup', onDragEnd);
      window.removeEventListener('pointermove', onResizingTop);
      window.removeEventListener('pointermove', onResizingRight);
      window.removeEventListener('pointermove', onResizingBottom);
      window.removeEventListener('pointermove', onResizingLeft);
      window.removeEventListener('pointermove', onResizingBottomLeft);
      window.removeEventListener('pointermove', onResizingTopLeft);
      window.removeEventListener('pointermove', onResizingTopRight);
      window.removeEventListener('pointermove', onResizingBottomRight);
      window.removeEventListener('pointerup', onResizeEndTop);
      window.removeEventListener('pointerup', onResizeEndRight);
      window.removeEventListener('pointerup', onResizeEndBottom);
      window.removeEventListener('pointerup', onResizeEndLeft);
      window.removeEventListener('pointerup', onResizeEndBottomLeft);
      window.removeEventListener('pointerup', onResizeEndTopLeft);
      window.removeEventListener('pointerup', onResizeEndTopRight);
      window.removeEventListener('pointerup', onResizeEndBottomRight);
      cover.remove();
    };
    // eslint-disable-next-line
  }, [boundary.top, boundary.right, boundary.bottom, boundary.left, cursorPos]);
  return { offset, size };
}
function useCursor(ref, threshold, resizable) {
  const [position, setPosition] = useState('');
  useEffect(() => {
    const target = ref.current;
    if (!target || !resizable) return;
    const cover = document.createElement('div');
    cover.style.position = 'fixed';
    cover.style.top = 0;
    cover.style.left = 0;
    cover.style.right = 0;
    cover.style.bottom = 0;
    let lock = false;
    function _setPosition(p) {
      setPosition(p);
      target.style.cursor = getCursorStyle(p);
      cover.style.cursor = getCursorStyle(p);
    }
    function onPointerDown(e) {
      if (e.target !== target) return;
      onHover(e);
      lock = true;
      document.body.appendChild(cover);
      window.addEventListener('pointerup', onPointerUp);
    }
    function onPointerUp(e) {
      lock = false;
      cover.remove();
      window.removeEventListener('pointerup', onPointerUp);
    }
    function onHoverEnd(e) {
      if (lock) return;
      _setPosition('');
    }
    function onHover(e) {
      if (lock) return;
      if (e.target !== target) return _setPosition('');
      const { offsetX, offsetY } = e;
      const { width, height } = target.getBoundingClientRect();
      if (offsetX < threshold) {
        if (offsetY < threshold) {
          _setPosition('topLeft');
        } else if (height - offsetY < threshold) {
          _setPosition('bottomLeft');
        } else {
          _setPosition('left');
        }
      } else if (offsetY < threshold) {
        if (width - offsetX < threshold) {
          _setPosition('topRight');
        } else {
          _setPosition('top');
        }
      } else if (width - offsetX < threshold) {
        if (height - offsetY < threshold) _setPosition('bottomRight');
        else _setPosition('right');
      } else if (height - offsetY < threshold) {
        _setPosition('bottom');
      } else {
        _setPosition('');
      }
    }
    target.addEventListener('pointerleave', onHoverEnd);
    target.addEventListener('pointermove', onHover);
    target.addEventListener('pointerdown', onPointerDown);
    return () => {
      cover.remove();
      target.removeEventListener('pointerleave', onHoverEnd);
      target.removeEventListener('pointermove', onHover);
      target.removeEventListener('pointerdown', onPointerDown);
      window.removeEventListener('pointerup', onPointerUp);
    };
    // eslint-disable-next-line
  }, []);
  return position;
}

function getComputedPagePosition(e, boundary) {
  let pageX = Math.round(e.pageX);
  let pageY = Math.round(e.pageY);
  if (!boundary) return { pageX, pageY };
  const { top, right, bottom, left } = boundary;
  if (pageX <= left) pageX = left;
  else if (pageX >= right) pageX = right;
  if (pageY <= top) pageY = top;
  else if (pageY >= bottom) pageY = bottom;
  return { pageX, pageY };
}
function getCursorStyle(pos) {
  switch (pos) {
    case 'top':
      return `url(${n_resize}), n-resize`;
    case 'topRight':
      return `url(${ne_resize}), ne-resize`;
    case 'right':
      return `url(${e_resize}), e-resize`;
    case 'bottomRight':
      return `url(${se_resize}), se-resize`;
    case 'bottom':
      return `url(${s_resize}), s-resize`;
    case 'bottomLeft':
      return `url(${sw_resize}), sw-resize`;
    case 'left':
      return `url(${w_resize}), w-resize`;
    case 'topLeft':
      return `url(${nw_resize}), nw-resize`;
    default:
      return `url(${main}), auto`;
  }
}
export default useElementResize;
