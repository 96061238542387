const leads = [
  require("assets/remixer/lead/0.wav"),
  require("assets/remixer/lead/1.wav"),
  require("assets/remixer/lead/2.wav"),
  require("assets/remixer/lead/3.wav"),
  require("assets/remixer/lead/4.wav"),
  require("assets/remixer/lead/5.wav"),
  require("assets/remixer/lead/6.wav"),
  require("assets/remixer/lead/7.wav"),
  require("assets/remixer/lead/8.wav"),
  require("assets/remixer/lead/9.wav"),
  require("assets/remixer/lead/10.wav"),
  require("assets/remixer/lead/11.wav"),
  require("assets/remixer/lead/12.wav"),
  require("assets/remixer/lead/13.wav"),
  require("assets/remixer/lead/14.wav"),
  require("assets/remixer/lead/15.wav"),
  require("assets/remixer/lead/16.wav"),
  require("assets/remixer/lead/17.wav"),
  require("assets/remixer/lead/18.wav"),
  require("assets/remixer/lead/19.wav"),
  require("assets/remixer/lead/20.wav"),
  require("assets/remixer/lead/21.wav"),
  require("assets/remixer/lead/22.wav"),
  require("assets/remixer/lead/23.wav"),
  require("assets/remixer/lead/24.wav"),
  require("assets/remixer/lead/25.wav"),
  require("assets/remixer/lead/26.wav"),
  require("assets/remixer/lead/27.wav"),
  require("assets/remixer/lead/28.wav"),
  require("assets/remixer/lead/29.wav"),
  require("assets/remixer/lead/30.wav"),
  require("assets/remixer/lead/31.wav"),
  require("assets/remixer/lead/32.wav"),
  require("assets/remixer/lead/33.wav"),
  require("assets/remixer/lead/34.wav"),
  require("assets/remixer/lead/35.wav"),
  require("assets/remixer/lead/36.wav"),
  require("assets/remixer/lead/37.wav"),
  require("assets/remixer/lead/38.wav"),
  require("assets/remixer/lead/39.wav"),
  require("assets/remixer/lead/40.wav"),
  require("assets/remixer/lead/41.wav"),
  require("assets/remixer/lead/42.wav"),
  require("assets/remixer/lead/43.wav"),
  require("assets/remixer/lead/44.wav"),
  require("assets/remixer/lead/45.wav"),
  require("assets/remixer/lead/46.wav"),
  require("assets/remixer/lead/47.wav"),
  require("assets/remixer/lead/48.wav"),
  require("assets/remixer/lead/49.wav"),
  require("assets/remixer/lead/50.wav"),
  require("assets/remixer/lead/51.wav"),
  require("assets/remixer/lead/52.wav"),
  require("assets/remixer/lead/53.wav"),
  require("assets/remixer/lead/54.wav"),
  require("assets/remixer/lead/55.wav"),
  require("assets/remixer/lead/56.wav"),
  require("assets/remixer/lead/57.wav"),
  require("assets/remixer/lead/58.wav"),
  require("assets/remixer/lead/59.wav"),
  require("assets/remixer/lead/60.wav"),
  require("assets/remixer/lead/61.wav"),
  require("assets/remixer/lead/62.wav"),
  require("assets/remixer/lead/63.wav"),
  require("assets/remixer/lead/64.wav"),
  require("assets/remixer/lead/65.wav"),
  require("assets/remixer/lead/66.wav"),
  require("assets/remixer/lead/67.wav"),
  require("assets/remixer/lead/68.wav"),
  require("assets/remixer/lead/69.wav"),
  // require("assets/remixer/lead/70.wav"),
  // require("assets/remixer/lead/71.wav"),
  // require("assets/remixer/lead/72.wav"),
  // require("assets/remixer/lead/73.wav"),
  // require("assets/remixer/lead/74.wav"),
];

export default leads;
