import React, { useEffect, useRef, useState, useGlobal } from "reactn";
import styled from "styled-components";
import { useScript } from "hooks";
import * as Tone from "tone";
import { objects } from "./utils/objects.js";
import { Star, ShootingStar } from "./Star.js";
import { messages, images } from "./utils/errors.js";
import synsample from "assets/god/music/observatory.wav";
import bubsample from "assets/god/music/bubbles.wav";
import watsample from "assets/god/music/water.wav";
import eaqsample from "assets/god/music/earthquake.wav";
import bassample from "assets/god/music/bass.wav";
import drosample from "assets/god/music/drop.wav";
import howsample from "assets/god/music/howl.wav";
import accsample from "assets/god/music/accumulate.wav";
import milkyway0 from "assets/god/milkyway/galaxy0.png";
import milkyway1 from "assets/god/milkyway/galaxy1.png";
import milkyway2 from "assets/god/milkyway/galaxy2.png";
import milkyway3 from "assets/god/milkyway/galaxy3.png";
import milkyway4 from "assets/god/milkyway/galaxy4.png";
import milkyway5 from "assets/god/milkyway/galaxy5.png";
import milkyway6 from "assets/god/milkyway/galaxy6.png";
import milkyway7 from "assets/god/milkyway/galaxy7.png";

const Observatory = ({ dropOptions, object, objectView, setObject, appId }) => {
  const sky_select = document.querySelector("#skymap_select_wrapper");
  const [planetarium, setPlanetarium] = useState(null);
  const started = useRef(false);
  const vibes = useRef(null);
  const shiningStars = useRef({ on: true, stars: [] });
  const shootingStars = useRef({ on: true, stars: [] });
  const wanderingStars = useRef({ on: true, stars: [] });
  const focusedObject = useRef(0);
  const fullscreen = useGlobal("fullscreen");
  const starCount = 0;

  const initSettings = {
    id: "skymap" + appId,
    gradient: dropOptions.Atmosphere || false,
    ground: dropOptions.Ground || false,
    cardinalpoints: dropOptions.CardinalPoints || true,
    showstars: dropOptions.Static || true,
    showstarlabels: dropOptions.StarsLabels || false,
    showgalaxy: dropOptions.MilkyWay || true,
    showgalaxyoutline: dropOptions.Outline || false,
    showplanets: dropOptions.SolarSystem || true,
    showplanetlabels: dropOptions.SolarSystemLabels || true,
    showorbits: dropOptions.Orbits || false,
    constellations: dropOptions.Constellations || true,
    constellationlabels: dropOptions.ConstellationsLabels || false,
    constellationboundaries: dropOptions.Boundaries || false,
    projection: dropOptions.Projection || "gnomic",
    gridlines_az: dropOptions.Azimuthal || false,
    gridlines_eq: dropOptions.Equatorial || false,
    gridlines_gal: dropOptions.Galactic || false,
    ecliptic: dropOptions.Ecliptic || false,
    meridian: dropOptions.Meridian || false,
    islive: dropOptions.Realtime || true,
    fullscreen: dropOptions.FullScreen || false,
    ra: objects[focusedObject.current].ra.decimal,
    dec: objects[focusedObject.current].dec.decimal,
    fontsize: "11px",
    fontfamily: "Verdana, Helvetica, sans-serif",
    appId: appId,
  };
  const stuquery = useScript("https://heaven.computer/lib/virtualSky/stuquery.min.js");
  const virtualSky = useScript("https://heaven.computer/lib/virtualSky/virtualsky.min.js");

  useEffect(() => {
    if (planetarium) planetarium.galaxyid = dropOptions.MilkyWayStyle;
  }, [dropOptions.MilkyWayStyle, planetarium]);

  useEffect(() => {
    if (planetarium && vibes.current) {
      if (dropOptions.Effects) {
        vibes.current.player("bubbles").mute = false;
        vibes.current.player("water").mute = false;
        vibes.current.player("earthquake").mute = false;
        vibes.current.player("bass").mute = false;
        vibes.current.player("drop").mute = false;
        vibes.current.player("howl").mute = false;
        vibes.current.player("burst").mute = false;
      } else {
        vibes.current.player("bubbles").mute = true;
        vibes.current.player("water").mute = true;
        vibes.current.player("earthquake").mute = true;
        vibes.current.player("bass").mute = true;
        vibes.current.player("drop").mute = true;
        vibes.current.player("howl").mute = true;
        vibes.current.player("burst").mute = true;
      }
    }
  }, [dropOptions.Effects, planetarium]);

  useEffect(() => {
    if (planetarium && vibes.current) {
      dropOptions.Music
        ? vibes.current.player("synth").start()
        : vibes.current.player("synth").stop();
    }
  }, [dropOptions.Music, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Wandering
        ? (wanderingStars.current.on = true)
        : (wanderingStars.current.on = false);
  }, [dropOptions.Wandering, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Shining
        ? (shiningStars.current.on = true)
        : (shiningStars.current.on = false);
  }, [dropOptions.Shining, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Shooting
        ? (shootingStars.current.on = true)
        : (shootingStars.current.on = false);
  }, [dropOptions.Shooting, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.MilkyWay
        ? (planetarium.showgalaxy = true)
        : (planetarium.showgalaxy = false);
  }, [dropOptions.MilkyWay, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Outline
        ? (planetarium.showgalaxyoutline = true)
        : (planetarium.showgalaxyoutline = false);
  }, [dropOptions.Outline, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.SolarSystem
        ? (planetarium.showplanets = true)
        : (planetarium.showplanets = false);
  }, [dropOptions.SolarSystem, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.SolarSystemLabels
        ? (planetarium.showplanetlabels = true)
        : (planetarium.showplanetlabels = false);
  }, [dropOptions.SolarSystemLabels, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Orbits
        ? (planetarium.showorbits = true)
        : (planetarium.showorbits = false);
  }, [dropOptions.Orbits, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Constellations
        ? (planetarium.constellation.lines = true)
        : (planetarium.constellation.lines = false);
  }, [dropOptions.Constellations, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.ConstellationsLabels
        ? (planetarium.constellation.labels = true)
        : (planetarium.constellation.labels = false);
  }, [dropOptions.ConstellationsLabels, planetarium]);

  useEffect(() => {
    if (planetarium && dropOptions.Boundaries !== undefined)
      planetarium.toggleConstellationBoundaries(dropOptions.Boundaries);
  }, [dropOptions.Boundaries, planetarium]);

  useEffect(() => {
    if (planetarium) {
      if (dropOptions.Static) {
        // document.querySelector("#go_box").style.backgroundImage = `url(${bg0})`;
        planetarium.showstars = true;
      } else {
        // document.querySelector("#go_box").style.backgroundImage = "none";
        planetarium.showstars = false;
      }
    }
  }, [dropOptions.Static, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.StarsLabels
        ? (planetarium.showstarlabels = true)
        : (planetarium.showstarlabels = false);
  }, [dropOptions.StarsLabels, planetarium]);

  useEffect(() => {
    const onCloseModal = (e) => {
      if (e.target.id !== "skymap_select_wrapper") return;
      sky_select.style.display = "none";
      dropOptions.Select = null;
    };
    if (sky_select) sky_select.addEventListener("mousedown", onCloseModal);
    // eslint-disable-next-line
  }, [sky_select, dropOptions.Select]);

  useEffect(() => {
    if (planetarium && dropOptions.Projection)
      planetarium.selectProjection(dropOptions.Projection);
  }, [planetarium, dropOptions.Projection]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Ground
        ? (planetarium.ground = true)
        : (planetarium.ground = false);
  }, [dropOptions.Ground, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Atmosphere
        ? (planetarium.gradient = true)
        : (planetarium.gradient = false);
  }, [dropOptions.Atmosphere, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.CardinalPoints
        ? (planetarium.cardinalpoints = true)
        : (planetarium.cardinalpoints = false);
  }, [dropOptions.CardinalPoints, planetarium]);

  useEffect(() => {
    if (planetarium && dropOptions.Azimuthal !== undefined)
      planetarium.toggleGridlinesAzimuthal(dropOptions.Azimuthal);
  }, [dropOptions.Azimuthal, planetarium]);

  useEffect(() => {
    if (planetarium && dropOptions.Equatorial !== undefined)
      planetarium.toggleGridlinesEquatorial(dropOptions.Equatorial);
  }, [dropOptions.Equatorial, planetarium]);

  useEffect(() => {
    if (planetarium && dropOptions.Galactic !== undefined)
      planetarium.toggleGridlinesGalactic(dropOptions.Galactic);
  }, [dropOptions.Galactic, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Date
        ? (document.querySelector("#skymap_date").style.display = "block")
        : (document.querySelector("#skymap_date").style.display = "none");
  }, [dropOptions.Date, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Location
        ? (document.querySelector("#skymap_location").style.display = "block")
        : (document.querySelector("#skymap_location").style.display = "none");
  }, [dropOptions.Location, planetarium]);

  useEffect(() => {
    let interval;
    const setTimeText = () => {
      if (document.querySelector("#skymap_location")) {
        document.querySelector("#skymap_location").innerHTML =
          Math.abs(planetarium.latitude.deg).toFixed(2) +
          "° " +
          (planetarium.latitude.rad > 0 ? "N" : "S") +
          " / " +
          Math.abs(planetarium.longitude.deg).toFixed(2) +
          "° " +
          (planetarium.longitude.rad > 0 ? "E" : "W");
      }
      if (document.querySelector("#skymap_date")) {
        document.querySelector("#skymap_date").innerHTML =
          planetarium.clock.toLocaleDateString(planetarium.langcode, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }) +
          " " +
          planetarium.clock.toLocaleTimeString(planetarium.langcode);
      }
    };
    if (planetarium) {
      if (dropOptions.Realtime) {
        interval = setInterval(setTimeText, 1000);
        planetarium.liveSky();
      } else {
        if (interval) clearInterval(interval);
        planetarium.liveSky();
      }
    } else {
      if (interval) clearInterval(interval);
    }
  }, [dropOptions.Realtime, planetarium]);

  useEffect(() => {
    if (planetarium && dropOptions.SpeedUp !== undefined) {
      planetarium.spinIt("up");
    }
  }, [dropOptions.SpeedUp, planetarium]);

  useEffect(() => {
    if (planetarium && dropOptions.SlowDown !== undefined) {
      planetarium.spinIt("down");
    }
  }, [dropOptions.SlowDown, planetarium]);

  useEffect(() => {
    if (planetarium && dropOptions.Normal !== undefined) {
      planetarium.spinIt(0);
    }
  }, [dropOptions.Normal, planetarium]);

  useEffect(() => {
    if (planetarium && dropOptions.ChangeTime !== undefined) {
      switch (dropOptions.ChangeTime) {
        case "-1Week":
          planetarium.setClock(-86400 * 7).calendarUpdate();
          break;
        case "+1Week":
          planetarium.setClock(86400 * 7).calendarUpdate();
          break;
        case "Now":
          planetarium.setClock("now").calendarUpdate();
          break;
        case "-1Day":
          planetarium.setClock(-86400).calendarUpdate();
          break;
        case "+1Day":
          planetarium.setClock(86400).calendarUpdate();
          break;
        default:
      }
    }
  }, [dropOptions.ChangeTime, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Ecliptic
        ? (planetarium.ecliptic = true)
        : (planetarium.ecliptic = false);
  }, [dropOptions.Ecliptic, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Meridian
        ? (planetarium.meridian = true)
        : (planetarium.meridian = false);
  }, [dropOptions.Meridian, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.Atmosphere
        ? (planetarium.gradient = true)
        : (planetarium.gradient = false);
  }, [dropOptions.Atmosphere, planetarium]);

  useEffect(() => {
    if (planetarium)
      dropOptions.CardinalPoints
        ? (planetarium.cardinalpoints = true)
        : (planetarium.cardinalpoints = false);
  }, [dropOptions.CardinalPoints, planetarium]);

  useEffect(() => {
    if (planetarium) planetarium.toggleFullScreen(dropOptions.Fullscreen, fullscreen);
  }, [dropOptions.Fullscreen, planetarium, fullscreen]);

  useEffect(() => {
    if (appId && planetarium && objects.indexOf(object) >= 0 && objectView) {
      focusedObject.current = objects.indexOf(object);
      const objpointer = document.querySelector(".objpointer" + appId);
      if (objpointer) objpointer.style.display = "none";
      planetarium.panTo(
        objects[focusedObject.current].ra.decimal,
        objects[focusedObject.current].dec.decimal,
        1000
      );
    }
  }, [object, objectView, planetarium, appId]);

  useEffect(() => {
    return () => {
      const scripts = document.querySelectorAll("script");
      if (scripts.length > 1) {
        for (let i = 0; i < scripts.length; i++)
          scripts[i].parentNode.removeChild(scripts[i]);
      }
      wanderingStars.current = { on: true, stars: [] };
      shootingStars.current = { on: true, stars: [] };
      shiningStars.current = { on: true, stars: [] };
      //focusedObject.current = 0;
      started.current = false;
      setPlanetarium(null);
      if (vibes.current && !started.current) {
        vibes.current.stopAll();
        vibes.current = null;
      }
    };
  }, []);

  function addObjectsPointers(planetarium) {
    for (let i in objects) {
      planetarium.addPointer({
        ra: objects[i].ra.decimal,
        dec: objects[i].dec.decimal,
        label: objects[i].label,
        name: objects[i].name,
        colour: `hsl(${360 * Math.random()},70%,80%)`,
        d: objects[i].magnitude,
      });
    }
  }

  function addShiningStar(ctx, count) {
    for (let i = 0; i < count; i++) {
      const s = new Star(ctx);
      shiningStars.current.stars.push(s);
    }
  }

  function addWanderingStar(w, h, count) {
    count = Math.round(Math.random() * count);
    for (let i = 0; i < count; i++) {
      let star = {
        x: Math.random() * w,
        y: Math.random() * h,
        vx: (-1 + Math.random() * 2) / 10,
        vy: (-1 + Math.random() * 2) / 10,
        m: Math.random() * 2,
      };
      wanderingStars.current.stars.push(star);
    }
  }

  function drawWanderingStar(ctx, star) {
    ctx.beginPath();
    ctx.arc(star.x, star.y, star.m, 0, 2 * Math.PI);
    if (star.vx > 0) {
      star.vx += 0.00001;
    } else {
      star.vx -= 0.00001;
    }
    if (star.vy > 0) {
      star.vy += 0.00001;
    } else {
      star.vy -= 0.00001;
    }
    star.x += star.vx;
    star.y += star.vy;
    ctx.fill();
    return star;
  }
  useEffect(() => {
    if (stuquery && virtualSky === "ready") {
      try {
        // eslint-disable-next-line
        setPlanetarium(S.virtualsky(initSettings));
        started.current = true;
      } catch (e) {
        const err_i = Math.floor(Math.random() * images.length);
        const errimg = images[err_i];
        const errmsg = messages[err_i];
        document.getElementById("skymap_errimg").src = errimg;
        document.getElementById("skymap_errmsg").innerHTML = errmsg;
        document.getElementById("skymap_err").style.display = "flex";
      }
    }
    // eslint-disable-next-line
  }, [stuquery, virtualSky]);

  useEffect(() => {
    function playSound(sound, noAcc) {
      if (!started.current || !vibes.current) return;
      try {
        if (noAcc) {
          if (sound.state !== "started") sound.start();
        } else {
          sound.start();
        }
      } catch (e) {
        console.log("err: ", e);
        return;
      }
    }

    function addShootingStar(ctx, r, count, color, speed) {
      if (!started.current) return;
      let radius;
      for (let i = 0; i < count; i++) {
        if (r === 0) {
          radius = Math.random() * 3;
        } else {
          radius = Math.random() * r;
        }
        if (radius && speed === 0) {
          speed = 10 + radius * 5;
        }
        let trailLength = radius * 15;

        const m = new ShootingStar(ctx, radius, speed, trailLength, color);
        shootingStars.current.stars.push(m);
      }
    }
    function meteorShower(ctx, starCount) {
      if (!started.current) return;
      if (vibes.current.player("burst").state === "stopped")
        vibes.current.player("burst").start();
      let count;
      if (starCount === 0) {
        count = Math.round(Math.random() * 10);
      } else {
        count = starCount / 500;
      }
      if (vibes.current.player("burst").state === "started") {
        vibes.current.player("burst").stop();
      }
      addShootingStar(
        ctx,
        3,
        count,
        "rgba(255,255,255,0.6)",
        0,
        Math.random() * 20 + 10
      );
    }
    function panRight(planetarium) {
      if (!started.current) return;
      if (planetarium.projection.id === "gnomic") {
        if (planetarium.fov === 60) {
          playSound(vibes.current.player("bass"));
          focusedObject.current > 0
            ? (focusedObject.current = focusedObject.current - 1)
            : (focusedObject.current = objects.length - 1);
          const objpointer = document.querySelector(".objpointer");
          if (objpointer) objpointer.style.display = "none";
          setObject(objects[focusedObject.current]);
          planetarium.panTo(
            objects[focusedObject.current].ra.decimal,
            objects[focusedObject.current].dec.decimal,
            1000
          );
        } else {
          playSound(vibes.current.player("bass"), true);
          planetarium.panTo(
            planetarium.ra_off * planetarium.r2d - 6,
            planetarium.dc_off * planetarium.r2d,
            0
          );
        }
      } else {
        playSound(vibes.current.player("bass"), true);
        planetarium.az_off += 2;
      }
    }
    function panLeft(planetarium) {
      if (!started.current) return;
      if (planetarium.projection.id === "gnomic") {
        if (planetarium.fov === 60) {
          playSound(vibes.current.player("bass"));
          focusedObject.current < objects.length - 1
            ? (focusedObject.current = focusedObject.current + 1)
            : (focusedObject.current = 0);
          const objpointer = document.querySelector(".objpointer");
          if (objpointer) objpointer.style.display = "none";
          setObject(objects[focusedObject.current]);
          planetarium.panTo(
            objects[focusedObject.current].ra.decimal,
            objects[focusedObject.current].dec.decimal,
            1000
          );
        } else {
          playSound(vibes.current.player("bass"), true);
          planetarium.panTo(
            planetarium.ra_off * planetarium.r2d + 6,
            planetarium.dc_off * planetarium.r2d,
            0
          );
        }
      } else {
        playSound(vibes.current.player("bass"), true);
        planetarium.az_off -= 2;
      }
    }

    function zoomIn(planetarium) {
      if (!started.current) return;
      if (!planetarium) return;
      if (planetarium.projection.id === "gnomic") {
        if (planetarium.fov <= 60 && planetarium.fov > 55) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov -= 0.75;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov <= 55 && planetarium.fov > 45) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov -= 0.65;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov <= 45 && planetarium.fov > 35) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov -= 0.55;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov <= 35 && planetarium.fov > 25) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov -= 0.45;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov <= 25 && planetarium.fov > 15) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov -= 0.35;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov <= 15 && planetarium.fov > 1) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov -= 0.3;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov <= 1) {
          playSound(vibes.current.player("howl"), true);
        }
      } else {
        if (planetarium.magnitude > 0) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.magnitude = planetarium.magnitude - 0.25;
        } else {
          playSound(vibes.current.player("howl"), true);
        }
      }
    }
    function zoomOut(planetarium) {
      if (!started.current) return;
      if (!planetarium) return;
      if (planetarium.projection.id === "gnomic") {
        if (planetarium.fov >= 1 && planetarium.fov < 15) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov += 0.75;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov >= 15 && planetarium.fov < 25) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov += 0.65;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov >= 25 && planetarium.fov < 35) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov += 0.55;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov >= 35 && planetarium.fov < 45) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov += 0.45;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov >= 45 && planetarium.fov < 55) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov += 0.35;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov >= 55 && planetarium.fov < 60) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.fov += 0.3;
          planetarium.changeFOV(0).drawImmediate();
        } else if (planetarium.fov >= 60) {
          playSound(vibes.current.player("howl"), true);
        }
      } else {
        if (planetarium.magnitude < 6) {
          playSound(vibes.current.player("earthquake"), true);
          planetarium.magnitude = planetarium.magnitude + 0.25;
        } else {
          playSound(vibes.current.player("howl"), true);
        }
      }
    }

    function drawStars(ctx, w, h) {
      if (wanderingStars.current.on) {
        for (let i = 0; i < wanderingStars.current.stars.length; i++) {
          drawWanderingStar(ctx, wanderingStars.current.stars[i]);
          if (
            wanderingStars.current.stars[i].x > w ||
            wanderingStars.current.stars[i].y > h ||
            wanderingStars.current.stars[i].x < 0 ||
            wanderingStars.current.stars[i].y < 0
          ) {
            wanderingStars.current.stars.splice(i, 1);
          }
        }
      }
      if (shootingStars.current.on) {
        for (let i = 0; i < shootingStars.current.stars.length; i++) {
          shootingStars.current.stars[i].update();
          if (
            shootingStars.current.stars[i].x > w ||
            shootingStars.current.stars[i].y > h ||
            shootingStars.current.stars[i].x < 0 ||
            shootingStars.current.stars[i].y < 0
          ) {
            if (shootingStars.current.stars[i].radius > 2.9) {
              playSound(vibes.current.player("water"));
            } else if (shootingStars.current.stars[i].radius <= 2.9 && shootingStars.current.stars[i].radius > 1.0) {
              playSound(vibes.current.player("bubbles"));
            }
            shootingStars.current.stars.splice(i, 1);
          }
        }
      }
      if (shiningStars.current.on) {
        for (let i = 0; i < shiningStars.current.stars.length; i++) {
          shiningStars.current.stars[i].update();
        }
      }
    }

    const canvas = document.getElementById("skymap" + appId + "_cnv");
    if (canvas) {
      canvas.classList.add('grabber')
      vibes.current = new Tone.Players({
        urls: {
          synth: synsample,
          bubbles: bubsample,
          water: watsample,
          earthquake: eaqsample,
          bass: bassample,
          drop: drosample,
          howl: howsample,
          burst: accsample,
        },
        onload: () => {
          vibes.current.player("synth").start();
          vibes.current.player("synth").loop = true;
        },
      }).toDestination();
      const w = canvas.parentElement.clientWidth;
      const h = canvas.parentElement.clientHeight;
      const ctx = canvas.getContext("2d");
      if (shiningStars.current.on)
        addShiningStar(ctx, 500 + Math.round(Math.random() * 200));
      let count = 0;
      if (planetarium) {
        addObjectsPointers(planetarium);
        setInterval(() => {
          if (started.current && planetarium) {
            planetarium.drawImmediate();
            drawStars(ctx, w, h);
          }
        }, 10);
        setInterval(() => {
          if (started.current && planetarium) {
            if (
              wanderingStars.current.on &&
              wanderingStars.current.stars.length < 50
            ) {
              addWanderingStar(w, h, 5);
            }
            if (shootingStars.current.on && count % 2 === 0) {
              addShootingStar(
                ctx,
                0,
                Math.round(Math.random() * 1),
                "rgba(255,255,255,0.6)",
                0
              );
            }
            count++;
          }
        }, 1000);

        document.addEventListener("keydown", function (e) {
          if (e.target.nodeName === "INPUT") return;
          switch (e.which) {
            case 32:
              meteorShower(ctx, starCount);
              break;
            case 37:
              panLeft(planetarium);
              break;
            case 38:
              zoomIn(planetarium);
              break;
            case 39:
              panRight(planetarium);
              break;
            case 40:
              zoomOut(planetarium);
              break;
            default:
          }
        });
      }
      const resizeObserver = new ResizeObserver((entries) => {
        if (planetarium) planetarium.resize();
      });
      resizeObserver.observe(canvas.parentElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planetarium]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dropOptions.Select === "Calendar") {
      const notValid = Object.values(e.target).find(
        (e) => e.type === "number" && (!e.value || e.value === "")
      );
      if (notValid) {
        document.querySelector("#skymap_select").classList.add("shake_err");
        setTimeout(function () {
          document
            .querySelector("#skymap_select")
            .classList.remove("shake_err");
        }, 700);
        return;
      }
      const year = Object.values(e.target).find((e) => e.id === "go_year")
        .value;
      const mon =
        Object.values(e.target).find((e) => e.id === "go_mon").value - 1;
      const day = Object.values(e.target).find((e) => e.id === "go_day").value;
      const hour = Object.values(e.target).find((e) => e.id === "go_hour")
        .value;
      const min = Object.values(e.target).find((e) => e.id === "go_min").value;
      const sec = Object.values(e.target).find((e) => e.id === "go_sec").value;
      if (
        mon > 12 ||
        mon < 1 ||
        day < 1 ||
        day > 31 ||
        hour >= 24 ||
        hour < 0 ||
        min < 0 ||
        min >= 60 ||
        sec < 0 ||
        sec >= 60
      ) {
        document.querySelector("#skymap_select").classList.add("shake_err");
        setTimeout(function () {
          document
            .querySelector("#skymap_select")
            .classList.remove("shake_err");
        }, 700);
        return;
      }
      const date = new Date(year, mon, day, hour, min, sec, 0);
      planetarium.updateClock(date);
      planetarium.calendarUpdate();
      planetarium.draw();
      dropOptions.Select = null;
      sky_select.style.display = "none";
    } else if (dropOptions.Select === "Location") {
      const lat = Object.values(e.target).find((e) => e.id === "go_lat").value;
      const lon = Object.values(e.target).find((e) => e.id === "go_lon").value;
      if (!lat || !lon || lat > 90 || lat < -90 || lon > 180 || lon < -180) {
        document.querySelector("#skymap_select").classList.add("shake_err");
        setTimeout(function () {
          document
            .querySelector("#skymap_select")
            .classList.remove("shake_err");
        }, 700);
        return;
      }
      planetarium.setLatitude(lat);
      planetarium.setLongitude(lon);
      planetarium.setClock(0);
      planetarium.draw();
      dropOptions.Select = null;
      sky_select.style.display = "none";
    }
  };

  return (
    <Div>
      <div id="skymap_select_wrapper">
        <div id="skymap_select">
          {dropOptions.Select === "Location" && (
            <form
              className="go_input"
              id="skymap_sel"
              autoComplete="off"
              noValidate="novalidate"
              onSubmit={(e) => handleSubmit(e)}
              style={{ flexDirection: "row" }}
            >
              <div id="sel_text">coordinates</div>
              <div>
                <input
                  className="go_mint_btn texter"
                  required
                  size="6"
                  id="go_lat"
                  type="number"
                  placeholder="latitude"
                  min="-90"
                  max="90"
                ></input>
                <input
                  className="go_mint_btn texter"
                  required
                  size="6"
                  id="go_lon"
                  type="number"
                  placeholder="longitude"
                  min="-180"
                  max="180"
                ></input>
              </div>
              <button type="submit" hidden />
            </form>
          )}
          {dropOptions.Select === "Calendar" && (
            <form
              className="go_input"
              id="skymap_sel"
              autoComplete="off"
              noValidate="novalidate"
              onSubmit={(e) => handleSubmit(e)}
              style={{ flexDirection: "column" }}
            >
              <div id="sel_text">date and time</div>
              <div>
                <input
                  className="go_mint_btn texter"
                  required
                  size="6"
                  id="go_day"
                  type="number"
                  placeholder="dd"
                  min="1"
                  max="31"
                ></input>
                <input
                  className="go_mint_btn texter"
                  required
                  size="6"
                  id="go_mon"
                  type="number"
                  placeholder="mm"
                  min="1"
                  max="12"
                ></input>
                <input
                  className="go_mint_btn texter"
                  required
                  size="6"
                  id="go_year"
                  type="number"
                  placeholder="yyyy"
                  min="1"
                  max="3000"
                ></input>
              </div>
              <div>
                <input
                  className="go_mint_btn texter"
                  required
                  size="6"
                  id="go_hour"
                  type="number"
                  placeholder="hh"
                  min="0"
                  max="24"
                ></input>
                <input
                  className="go_mint_btn texter"
                  required
                  size="6"
                  id="go_min"
                  type="number"
                  placeholder="mm"
                  min="0"
                  max="60"
                ></input>
                <input
                  className="go_mint_btn texter"
                  required
                  size="6"
                  id="go_sec"
                  type="number"
                  placeholder="ss"
                  min="0"
                  max="60"
                ></input>
              </div>
              <button type="submit" hidden />
            </form>
          )}
        </div>
      </div>
      <div id="skymap_infos">
        <div id="skymap_date"></div>
        <div id="skymap_location"></div>
      </div>
      <div id="skymap_err">
        <img alt="error" src="" id="skymap_errimg" />
        <br />
        <p id="skymap_errmsg"></p>
        <p>Please, refresh page and try again.</p>
      </div>
      <div
        id={"skymap" + appId}
        style={{ width: "100%", height: "100%", overflow: "hidden"}}
      ></div>
      <img alt="milky way" id="milkyway0" src={milkyway0} hidden />
      <img alt="milky way" id="milkyway1" src={milkyway1} hidden />
      <img alt="milky way" id="milkyway2" src={milkyway2} hidden />
      <img alt="milky way" id="milkyway3" src={milkyway3} hidden />
      <img alt="milky way" id="milkyway4" src={milkyway4} hidden />
      <img alt="milky way" id="milkyway5" src={milkyway5} hidden />
      <img alt="milky way" id="milkyway6" src={milkyway6} hidden />
      <img alt="milky way" id="milkyway7" src={milkyway7} hidden />
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  #skymap_err {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #skymap_infos {
    font-family: mozart, monospace;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: absolute;
    top: 3px;
    left: 6px;
  }
  #skymap_select_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
    align-items: center;
    justify-content: center;
    #skymap_select {
      border: 1px dashed #b19cd9;
      width: 340px;
      padding: 20px;
      max-width: 80%;
      max-height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(0, 0, 0);
      form {
        flex-wrap: wrap;
        input {
          min-width: 42px;
          width: 75px;
          margin: 0 5px;
        }
        flex-direction: column;
        div {
          flex-wrap: wrap;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          width: 100%;
        }
        div + div {
          margin-top: 10px;
        }
        #sel_text {
          display:block;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space:nowrap;
          max-width: calc(100% - 10px);
        }
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .map_inverted {
    backdrop-filter: invert(100%);
  }
  .cnv_inverted {
    filter: invert(100%);
  }
  .objpointer {
    position: absolute;
    letter-spacing: 10px;
    width: 20px;
    height: 20px;
    top: -10px;
    left: -10px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 2s;
  }
  .objpointer:hover {
    opacity: 1;
  }
}
`;

export default Observatory;
