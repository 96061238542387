import React from "react";

import { THEMES } from "../../palettes/user";

import Select from "./Select";

export const TOP = "TOP";
export const RGB_ADAPT_MID = "RGB_ADAPT_MID";
export const RGB_ADAPT_AVERAGE = "RGB_ADAPT_AVERAGE";
export const RGB_ADAPT_FIRST = "RGB_ADAPT_FIRST";
export const LAB_ADAPT_MID = "LAB_ADAPT_MID";
export const LAB_ADAPT_AVERAGE = "LAB_ADAPT_AVERAGE";
export const LAB_ADAPT_FIRST = "LAB_ADAPT_FIRST";
export const modeMap = {
  [RGB_ADAPT_MID]: { colorMode: "RGB", adaptMode: "MID" },
  [RGB_ADAPT_AVERAGE]: { colorMode: "RGB", adaptMode: "AVERAGE" },
  [RGB_ADAPT_FIRST]: { colorMode: "RGB", adaptMode: "FIRST" },
  [LAB_ADAPT_MID]: { colorMode: "LAB", adaptMode: "MID" },
  [LAB_ADAPT_AVERAGE]: { colorMode: "LAB", adaptMode: "AVERAGE" },
  [LAB_ADAPT_FIRST]: { colorMode: "LAB", adaptMode: "FIRST" }
};

const onDeleteColor = (e, props) => {
  props.onSetPaletteOption(
    "colors",
    props.value.colors.filter(
      (_, idx) => idx !== parseInt(e.target.dataset.idx, 10)
    )
  );
};

export default class EditPalette extends React.Component<Props, State> {

  render() {
    const currentTheme = Object.entries(THEMES).find(
      e => e[1] === this.props.value.colors
    );
    const customThemeName = "Custom";
    const currentThemeName = currentTheme ? currentTheme[0] : customThemeName;
    const colorAlgorithm = (
      <Select
        name={"colorDistanceAlgorithm"}
        types={this.props.types}
        value={this.props.value["colorDistanceAlgorithm"]}
        onSetFilterOption={this.props.onSetPaletteOption}
      />
    )
    const themePicker = (
      <Select
        id="themePicker"
        onSetFilterOption={this.props.onSetPaletteOption}
        name={currentThemeName}
        types={{
          options: THEMES,
        }}
      />
    );

    let i = 0;
    const colorSwatch = (
      <div className="colorArray">
        {this.props.value.colors.map(c => {
          const color = `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`;
          return (
            <div
              key={`${c}-${i++}`} // eslint-disable-line
              data-idx={i}
              title={`${color} - click to remove`}
              role="button"
              tabIndex="0"
              onKeyPress={e => {
                if (e.key === "Enter") {
                  onDeleteColor(e, this.props);
                }
              }}
              onClick={e => {
                onDeleteColor(e, this.props);
              }}
              style={{
                backgroundColor: color
              }}
            />
          );
        })}
      </div>
    );

    return (
      <>

        {colorSwatch}
        <div style={{marginBottom: '8px'}}>{themePicker}</div>
        {colorAlgorithm}
      </>
    );
  }
}
