import React, { useState, useEffect } from "react";
import styled from "styled-components";

// BODMAS calculator
const Calculator = () => {
  const [result, setResult] = useState("");

  const listen = ({ key }) => onClick(key);

  useEffect(() => {
    window.addEventListener("keyup", listen);
    return () => window.removeEventListener("keyup", listen);
  });

  const reset = () => setResult("");
  const backspace = () => setResult(result.slice(0, -1));

  const onClick = (button) => {
    if (button === "=" || button === "Enter") {
      calculate();
    } else if (button === "C" || button === "Delete") {
      reset();
    } else if (button === "CE" || button === "Backspace") {
      backspace();
    } else if (/[(^\d+-./*)]/g.test(button)) {
      setResult(result + button);
    } else {
      return;
    }
  };

  const solveSingle = (arr) => {
    arr = arr.slice();
    while (arr.length - 1) {
      if (arr[1] === "*") arr[0] = arr[0] * arr[2];
      if (arr[1] === "-") arr[0] = arr[0] - arr[2];
      if (arr[1] === "+") arr[0] = +arr[0] + +arr[2];
      if (arr[1] === "/") arr[0] = arr[0] / arr[2];
      arr.splice(1, 1);
      arr.splice(1, 1);
    }
    return arr[0];
  };

  const solve = (eq) => {
    if (eq.includes("(")) {
      let subEq = eq.split("(");
      for (let i = 1; i < subEq.length; i++) {
        eq = eq
          .replace(subEq[i].split(")")[0], solve(subEq[i].split(")")[0]))
          .replace(/[()]/g, "");
      }
    }
    let res = eq.split(/(\+|-)/g).map((x) =>
      x
        .trim()
        .split(/(\*|\/)/g)
        .map((a) => a.trim())
    );
    res = res.map((x) => solveSingle(x));
    return solveSingle(res);
  };

  const calculate = () => {
    let checkResult = "";
    if (result.includes("--")) {
      checkResult = result.replace("--", "+");
    } else {
      checkResult = result;
    }
    try {
      setResult((solve(checkResult) || "") + "");
    } catch (e) {
      setResult("error");
    }
  };

  return (
    <Div>
      <section className="result">
        <div className="divider"></div>
        <div className="result_content">
          <div className="result_content_text">
            <div><p>{result}</p></div>
          </div>
        </div>
        <div className="divider"></div>
      </section>

      <section className="keypad">
        <div>
          <button className="keys" name="(" onClick={e => onClick(e.target.name)}>(</button>
          <button className="keys" name="CE" onClick={e => onClick(e.target.name)}>CE</button>
          <button className="keys" name=")" onClick={e => onClick(e.target.name)}>)</button>
          <button className="keys" name="C" onClick={e => onClick(e.target.name)}>C</button>
        </div>
        <div>
          <button className="keys" name="1" onClick={e => onClick(e.target.name)}>1</button>
          <button className="keys" name="2" onClick={e => onClick(e.target.name)}>2</button>
          <button className="keys" name="3" onClick={e => onClick(e.target.name)}>3</button>
          <button className="keys" name="+" onClick={e => onClick(e.target.name)}>+</button>
        </div>
        <div>
          <button className="keys" name="4" onClick={e => onClick(e.target.name)}>4</button>
          <button className="keys" name="5" onClick={e => onClick(e.target.name)}>5</button>
          <button className="keys" name="6" onClick={e => onClick(e.target.name)}>6</button>
          <button className="keys" name="-" onClick={e => onClick(e.target.name)}>-</button>
        </div>
        <div>
          <button className="keys" name="7" onClick={e => onClick(e.target.name)}>7</button>
          <button className="keys" name="8" onClick={e => onClick(e.target.name)}>8</button>
          <button className="keys" name="9" onClick={e => onClick(e.target.name)}>9</button>
          <button className="keys" name="*" onClick={e => onClick(e.target.name)}>x</button>
        </div>
        <div>
          <button className="keys" name="." onClick={e => onClick(e.target.name)}>.</button>
          <button className="keys" name="0" onClick={e => onClick(e.target.name)}>0</button>
          <button className="keys" name="=" onClick={e => onClick(e.target.name)}>OK</button>
          <button className="keys" name="/" onClick={e => onClick(e.target.name)}>/</button>
        </div>
      </section>
    </Div>
  );
};

const Div = styled.div`
  p {
    user-select: text;
  }
  .result {
    padding: 2px 0;
    max-width: 168px;
    white-space: nowrap;
  }
  .divider {
    margin: 2px 2px 0 2px;
  }
  .result_content {
    overflow: hidden;
    margin: 2px;
    flex-shrink: 0;
    height: 26px;
    display: flex;
    align-items: center;
  }
  .result_content_text {
    background-color: white;
    box-shadow: inset -1px -1px 0px var(--colorgrad0), inset 1px 1px 0px 0px var(--colorgrad6),
      inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8);
    height: 100%;
    width: 100%;
    flex: 1;
  }
  .result_content_text div {
    margin: 0 7px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    justify-content: end;
  }
  button.keys {
    margin: 1px;
    font-size: 12px;
    box-shadow: inset -1px -1px 0px var(--colorgrad7), inset 1px 1px 0px var(--colorgrad4),
      inset -2px -2px 0px var(--colorgrad6), inset 2px 2px 0px var(--colorgrad0);
    background: var(--colorgrad3);
    border: 0;
    text-align: center;
    outline: none;
    width: 40px;
    height: 40px;
    color: var(--black);
  }
  button.keys:active {
    outline: 1px dotted #000000;
    outline-offset: -5px;
    background: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
`;

export default Calculator;
