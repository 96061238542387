import React, {
  useReducer,
  useRef,
  useCallback,
  setGlobal,
  useEffect,
  useGlobal,
} from "reactn";
import styled from "styled-components";
import { useMouse, useFullscreen } from "react-use";
import HeavenComputer from "artifacts/HeavenComputer.json";
import CDRom from "artifacts/GhostSoftwareCDROM.json";
import Astrodrop from "artifacts/Astrodrop.json";
import { ethers } from "ethers";
import GhostWindow from "components/GhostWindow";
import GodWindow from "components/GodWindow";
import SkyWindow from "components/SkyWindow";
import LinksWindow from "components/LinksWindow";
import { desktopIcons, desktopApps, appSettings } from "apps";
import { BackgroundProvider } from "./contexts/BackgroundContext.js";
import {
  OPEN_APP,
  CLOSE_APP,
  CLOSE_APPS,
  FOCUS_APP,
  MINIMIZE_APP,
  TOGGLE_MAXIMIZE_APP,
  FOCUS_ICON,
  SELECT_ICONS,
  FOCUS_DESKTOP,
  START_SELECT,
  END_SELECT,
  SHUT_DOWN,
  CANCEL_POWER_OFF,
  FOCUSING,
  POWER_STATE,
  Reducer,
} from "store";
import {
  // GhostClippy,
  StartUp,
  LogOff,
  TaskBar,
  Windows,
  Icons,
  Selector,
  Screensaver,
  Konami,
  BSoD,
  ShutDown,
  Background,
  Cursor,
} from "components";
import userClaim1 from "assets/json/claim1.json";
import userClaim2 from "assets/json/claim2.json";
import userClaim3 from "assets/json/claim3.json";
import userClaim4 from "assets/json/claim4.json";
import userClaim5 from "assets/json/claim5.json";
import userClaim6 from "assets/json/claim6.json";
// import Balloon from "components/Balloon/index.js";

const HC_ADDRESS = "0x80ADB36595239fe918c7D118C1F81e07d070801a";
const AD_ADDRESS = "0xE0B52d3444EF1518F3856c1F9502b71A3Bb4D4B7";
const CD_ADDRESS = "0xad78E15a5465C09F6E6522b0d98b1F3b6B67Ee7e";
const HC_ABI = HeavenComputer.abi;
const AD_ABI = Astrodrop;
const CD_ABI = CDRom.abi;

setGlobal({
  provider: null,
  contract: null,
  cdContract: null,
  adContract: null,
  connected: false,
  account: null,
  appLoading: null,
  turnedOff: false,
  bluescreen: false,
  fullscreen: false,
  cdMounted: null,
  godMounted: null,
});

const initState = {
  apps: desktopApps,
  nextAppID: desktopApps.length,
  nextZIndex: desktopApps.length,
  focusing: FOCUSING.WINDOW,
  icons: desktopIcons,
  selecting: false,
  powerState: POWER_STATE.TURN_ON,
};

const App = () => {
  const [state, dispatch] = useReducer(Reducer, initState);
  const [connected] = useGlobal("connected");
  const [account] = useGlobal("account");
  const [cdMounted] = useGlobal("cdMounted");
  const [cdCorrupted] = useGlobal("cdCorrupted");
  const [adContract] = useGlobal("adContract");
  const rootRef = useRef(document.getElementById("root"));
  const [fullscreen] = useGlobal("fullscreen");
  // eslint-disable-next-line no-unused-vars
  const isFullscreen = useFullscreen(rootRef, fullscreen);
  const ref = useRef(null);
  const mouse = useMouse(ref);
  const focusedAppId = getFocusedAppId();

  useEffect(() => {
    const getWeb3 = async () => {
      let web3;
      if (window.ethereum) {
        web3 = new ethers.providers.Web3Provider(window.ethereum, "any");
      } else {
        web3 = ethers.getDefaultProvider("homestead", {
          etherscan: "94D24EDIJGJXHM46IKYKQKMAZQPPT1U21F",
          infura: "0514ae6ca1ac42fbb120c4fced54bd4c",
          alchemy: "4htN0DsgL-Z6WJp9hQjSAYGIOlG1oDvd",
        });
        // web3 = new ethers.providers.JsonRpcProvider(`http://localhost:8545`);
      }
      const contract = new ethers.Contract(HC_ADDRESS, HC_ABI, web3);
      const cdContract = new ethers.Contract(CD_ADDRESS, CD_ABI, web3);
      const adContract = new ethers.Contract(AD_ADDRESS, AD_ABI, web3);
      setGlobal({
        web3: web3,
        adContract: adContract,
        cdContract: cdContract,
        contract: contract,
        provider: web3.provider,
      });
      if (web3) {
        web3.on("accountsChanged", function (accounts) {
          setGlobal({ account: ethers.utils.getAddress(accounts[0]) });
          setGlobal({ cdMounted: null });
        });
        web3.on("network", function (newNetwork, oldNetwork) {
          setGlobal({ chainId: newNetwork.chainId });
          if (oldNetwork) window.location.reload();
        });
      } else {
        dispatch({
          type: OPEN_APP,
          payload: {
            ...appSettings.Error,
            injectProps: {
              injectedProps: `No ethereum wallet detected.<br />Please download one or switch to a browser that supports it.`,
            },
          },
        });
      }
    };
    getWeb3();
  }, []);

  useEffect(() => {
    async function showPopUp() {
      if (connected && account) {
        let userTokenId;
        const claim1 = userClaim1;
        const claim2 = userClaim2;
        const claim3 = userClaim3;
        const claim4 = userClaim4;
        const claim5 = userClaim5;
        const claim6 = userClaim6;
        if (claim1.hasOwnProperty(account)) {
          userTokenId = claim1[account]["index"];
        } else if (claim2.hasOwnProperty(account)) {
          userTokenId = claim2[account]["index"];
        } else if (claim3.hasOwnProperty(account)) {
          userTokenId = claim3[account]["index"];
        } else if (claim4.hasOwnProperty(account)) {
          userTokenId = claim4[account]["index"];
        } else if (claim5.hasOwnProperty(account)) {
          userTokenId = claim5[account]["index"];
        } else if (claim6.hasOwnProperty(account)) {
          userTokenId = claim6[account]["index"];
        }
        const tokenIsClaimed = await adContract["isClaimed(uint256)"](
          userTokenId
        );
        if (userTokenId && !tokenIsClaimed) {
          dispatch({ type: OPEN_APP, payload: appSettings.PopUp });
        }
      }
    }
    showPopUp();
  }, [connected, account, adContract]);

  useEffect(() => {
    if (cdCorrupted === "broken") {
      dispatch({
        type: OPEN_APP,
        payload: {
          ...appSettings.Error,
          injectProps: {
            injectedProps: `The GhostSoftware CD-ROM appears to be damaged.<br />
              Please go to <i>My Computer > Disk Drive</i><br />
              to locate corrupted applications,<br />
              or go to <i>My Computer > CD Collection</i><br />
              to repair the the disk.`,
          },
        },
      });
    }
  }, [cdCorrupted]);

  const onFocusApp = useCallback((id) => {
    dispatch({
      type: FOCUS_APP,
      payload: id,
    });
  }, []);

  const onMaximizeWindow = useCallback(
    (id) => {
      if (focusedAppId === id) {
        dispatch({
          type: TOGGLE_MAXIMIZE_APP,
          payload: id,
        });
      }
    },
    [focusedAppId]
  );

  const onMinimizeWindow = useCallback(
    (id) => {
      if (focusedAppId === id) dispatch({ type: MINIMIZE_APP, payload: id });
    },
    [focusedAppId]
  );

  const onCloseApp = useCallback(
    (id) => {
      if (focusedAppId === id) dispatch({ type: CLOSE_APP, payload: id });
    },
    [focusedAppId]
  );

  function onPointerDownFooterApp(id) {
    if (focusedAppId === id) {
      dispatch({ type: MINIMIZE_APP, payload: id });
    } else {
      dispatch({ type: FOCUS_APP, payload: id });
    }
  }

  function onPointerDownIcon(id) {
    dispatch({ type: FOCUS_ICON, payload: id });
  }

  function onDoubleClickIcon(component, view) {
    const appSetting = Object.values(appSettings).find(
      (setting) => setting.component === component
    );
    if (appSetting.header.title === "CD Collection" && !connected) {
      dispatch({
        type: OPEN_APP,
        payload: {
          ...appSettings.Error,
          injectProps: {
            injectedProps:
              "Please connect via Connection Wizard to view collection.",
          },
        },
      });
    } else if (
      appSetting.header.title === "Disk Drive" &&
      (!connected || !cdMounted)
    ) {
      dispatch({
        type: OPEN_APP,
        payload: {
          ...appSettings.Error,
          injectProps: {
            injectedProps: "Drive is empty. Please insert a CD and try again.",
          },
        },
      });
    } else {
      dispatch({ type: OPEN_APP, payload: appSetting });
    }
  }

  function getFocusedAppId() {
    if (state.focusing !== FOCUSING.WINDOW) return -1;
    const focusedApp = [...state.apps]
      .sort((a, b) => b.zIndex - a.zIndex)
      .find((app) => !app.minimized);
    return focusedApp ? focusedApp.id : -1;
  }

  function onPointerDownFooter() {
    dispatch({ type: FOCUS_DESKTOP });
  }

  function onClickMenuItem(item) {
    let name = item.text;
    if (name && appSettings[name]) {
      if (name === "CDCollection") {
        if (connected) {
          dispatch({ type: OPEN_APP, payload: appSettings[name] });
        } else {
          dispatch({
            type: OPEN_APP,
            payload: {
              ...appSettings.Error,
              injectProps: {
                injectedProps:
                  "Please connect via Connection Wizard to view collection.",
              },
            },
          });
        }
      } else {
        dispatch({ type: OPEN_APP, payload: appSettings[name] });
      }
    } else if (appSettings[item]) {
      dispatch({ type: OPEN_APP, payload: appSettings[item] });
    } else if (item === "Log Off") {
      dispatch({
        type: SHUT_DOWN,
        payload: POWER_STATE.LOG_OFF,
      });
    } else if (item === "Shut Down") {
      dispatch({
        type: SHUT_DOWN,
        payload: POWER_STATE.TURN_OFF,
      });
    } else if (item === "Run") {
      dispatch({
        type: OPEN_APP,
        payload: {
          ...appSettings.Error,
          injectProps: {
            injectedProps: "You can run, but you can't hide.",
          },
        },
      });
    } else {
      dispatch({
        type: OPEN_APP,
        payload: {
          ...appSettings.Error,
          injectProps: {
            injectedProps: "Something went wrong.",
          },
        },
      });
    }
  }

  function onPointerDownDesktop(e) {
    if (e.target === e.currentTarget)
      dispatch({
        type: START_SELECT,
        payload: {
          x: mouse.docX,
          y: mouse.docY,
        },
      });
  }

  function onPointerUpDesktop(e) {
    dispatch({ type: END_SELECT });
  }

  function onIconsSelected(iconIds) {
    dispatch({ type: SELECT_ICONS, payload: iconIds });
  }

  function onCloseModal() {
    dispatch({ type: CANCEL_POWER_OFF });
  }

  function onToggleComponent(name, toggle, id, props) {
    if (toggle === "open") {
      const appSetting = Object.values(appSettings).find(
        (setting) => setting.header.title === name
      );
      if (appSetting.header.title === "CD Collection") {
        if (connected) {
          dispatch({ type: OPEN_APP, payload: appSettings.CDCollection });
        } else {
          dispatch({
            type: OPEN_APP,
            payload: {
              ...appSettings.Error,
              injectProps: {
                injectedProps:
                  "Please connect via Connection Wizard to view collection.",
              },
            },
          });
        }
      } else if (appSetting.header.title === "Warning") {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight - 37;
        const maxWidthOffset = windowWidth - 200;
        const maxHeightOffset = windowHeight - 125;
        let x = Math.round(Math.random() * maxWidthOffset);
        appSetting.defaultOffset = {
          x: x,
          y: Math.round(Math.random() * maxHeightOffset),
        };
        dispatch({ type: OPEN_APP, payload: appSetting });
      } else {
        dispatch({
          type: OPEN_APP,
          payload: {
            ...appSetting,
            injectProps: {
              injectedProps: props,
            },
          },
        });
      }
    } else {
      dispatch({ type: CLOSE_APPS, payload: name });
      dispatch({ type: FOCUS_APP, payload: id });
    }
  }

  if (window.location.host.split(".")[0] === "sky") {
    return (
      <>
        <SkyWindow />
        <Cursor />
      </>
    );
  }
  if (window.location.host.split(".")[0] === "ghost") {
    return (
      <>
        <GhostWindow />
        <Cursor />
      </>
    );
  }
  if (window.location.host.split(".")[0] === "god") {
    return (
      <>
        <GodWindow />
        <Cursor />
      </>
    );
  }
  if (window.location.pathname === "/links") {
    return (
      <>
        <LinksWindow />
        <Cursor />
      </>
    );
  }

  return (
    <Container
      ref={ref}
      onPointerUp={onPointerUpDesktop}
      onPointerDown={onPointerDownDesktop}
      id="main_app"
    >
      <StartUp />
      <Selector startPos={state.selecting} mouse={mouse} />
      <Icons
        flexFlow={"column"}
        height={"calc(100% - 37px)"}
        overflow={"visible"}
        icons={state.icons}
        onPointerDown={onPointerDownIcon}
        onDoubleClick={onDoubleClickIcon}
        displayFocus={state.focusing === FOCUSING.ICON}
        appSettings={appSettings}
        mouse={mouse}
        selecting={state.selecting}
        setSelectedIcons={onIconsSelected}
      />
      <BackgroundProvider>
        <Background />
        <Windows
          apps={state.apps}
          onPointerDown={onFocusApp}
          onClose={onCloseApp}
          onMinimize={onMinimizeWindow}
          onMaximize={onMaximizeWindow}
          focusedAppId={focusedAppId}
          onDoubleClickIcon={onDoubleClickIcon}
          onToggleComponent={onToggleComponent}
        />
      </BackgroundProvider>
      <TaskBar
        onToggleComponent={onToggleComponent}
        apps={state.apps}
        onPointerDownApp={onPointerDownFooterApp}
        focusedAppId={focusedAppId}
        onPointerDown={onPointerDownFooter}
        onClickMenuItem={onClickMenuItem}
      />
      {state.powerState !== POWER_STATE.TURN_ON && (
        <LogOff onClose={onCloseModal} mode={state.powerState} />
      )}
      <Screensaver />
      <Konami />
      <BSoD />
      <ShutDown />
      <Cursor />
    </Container>
  );
};

const Container = styled.div`
  filter: hue-rotate(var(--hueRotate));
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  overscroll-behavior: none;
  touch-action: pinch-zoom;
  > * {
    zoom: var(--zoom);
    moz-transform: scale(var(--zoom));
    transform-origin: 0 0;
  }
  *:not(input):not(textarea):not(p):not(span):not(a):not(td) {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export default App;
