import React from "react";
import styled from "styled-components";

const Sky2 = () => {
	return (
		<Div className="skysection2 content animate">
			<h2 className="animate">This experience is brought to you<br />entirely with CSS and HTML.</h2>
			<div className="suby animate">
				<div>
					<p>
						<strong>SkyLuxury</strong>, the latest HeavenComputer celestial enterprise, beckons the digital elite to explore an innovative venture in virtual celestial real estate, thoughtfully crafted for connoisseurs of technology and art who seek the ultimate sophistication the afterlife has to offer.
					</p>
					<p>
						Located at Heaven's <strong>intersection of art, technology & luxury</strong>, each residence invites you to revel in the ravishing allure of forsaking practicality and inclusivity, as we elevate digital opulence to new heights.
					</p>
				</div>
				<div>
					<p>
						Crafted exclusively with HTML and CSS, our residences boast intricate animations, rich layers of gradients and shadows, complex selectors and calculations, complete with a rare selection of <strong>limited-availability, experimental properties</strong> — a testament to our mastery of this art form, and to your connoisseurship of such excellence.
					</p>
					<p>
						One might ponder, why utilize CSS when more optimized and lightweight technologies like WebGL are available?
					</p>
				</div>
				<div>
					<p>
						The answer is simple: our properties are not merely interactive 3D residences; they are <strong>bold declarations of possessing the finest technology.</strong>
					</p>
					<p>
						Devoted to preserving the sanctity of our elite digital enclave, we've developed a technology that only provides seamless, smooth navigation for those with the finest devices. Lesser machines may encounter flickering, rough rendering, and excessive fan whirring — 	none of which befits the notion of <strong>quiet luxury</strong> we reserve for our bespoke audience.
					</p>
				</div>
				<div>
					<p>
						Performance optimization? Not at the cost of performance art.
					</p>
					<p>
						Why degrade gracefully when you can choose to not degrade at all?
					</p>
					<p>
						SkyLuxury is the epitome of digital opulence: expensive, glossy, and heavy — like a fancy coffee table book for your browser.
					</p>
					<h3>We transcended<br />glitch art<br /> to inaugurate<br />glitch chic.</h3>
				</div>
			</div>
		</Div>
	);
};

const Div = styled.div`
.suby {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-template-rows: auto;
	grid-gap: 2em;
	p {
		text-align: justify !important;
		max-width: 100% !important;
	}
	> div {
		height: 100%;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
	}
}

`;

export default Sky2;