import React from "react";
import styled from "styled-components";

const Sky1 = ({ createViewList, standAlone }) => {
	return (
		<Div className="skysection1 content animate selectable">
			<h2 className="animate">Welcome.</h2>
			<p className="animate">You have now entered the premises of SkyLuxury. Congratulations!</p>
			<p className="animate">Your device has successfully traversed a threshold that many cannot breach, and you are now part of a select group, privy to an exclusive domain of digital opulence.</p>
			<p className="animate">Keep scrolling down to learn more, or <span className="sl_link pointer" onClick={() => {
				createViewList("All Tokens", "Lobby");
				if (standAlone) window.history.pushState("", "", "/alltokens");
			}}>click here to visit the residences.</span></p>
			<p className="animate">As you proceed, remember: the experiences and vistas revealed here are crafted for a rarefied audience, designed to mesmerize and captivate – literally.</p>
			<p className="animate">Welcome to a quietly superior way of living, enveloped in the shadows of exclusivity, a distinguished acquisition for the discerning collector.</p>
			<div className="sphere-wrap animate">
				<div className="sphere animate"></div>
				<div className="sphere animate"></div>
				<div className="sphere animate"></div>
				<div className="sphere animate"></div>
				<div className="sphere animate"></div>
				<h1 className="line first animate back">a difference you can truly see</h1>
				<h1 className="line second animate back">a difference you can truly feel</h1>
				<h1 className="line second animate">a difference you can truly feel</h1>
				<h1 className="line third animate back">a difference you can truly feel</h1>
				<h1 className="line fourth animate back">a difference you can truly see</h1>
				<h1 className="line fourth animate">a difference you can truly see</h1>
			</div>
		</Div>
	);
};

const Div = styled.div`
	width: 100%;
	height: 100%;
	.sphere-wrap {
		height: 10em;
		margin: 20px;
		--sphereBaseSize: clamp(2rem, 14vw - 1rem, 20rem);
		--sphereTranslateX: calc(-1*var(--sphereBaseSize) - 80rem);
		width: 100%;
		position: relative;
	}
	.sl_link {
    --shadow: var(--textColor1);
  }
	
	h1.line {
		opacity: 0;
		padding: 0;
		margin: 0;
		line-height: 0;
		position: relative;
		--top:1.2em;
	}
	
	.sphere,
	.line {
		mix-blend-mode: difference;
	}
	
	.sphere {
		--sphereColorDarken: calc(var(--sphereSize) * 100%);
		--sphereTranslateY: 0;
		--sphereBgColor: #6A4DFF;
		--sphereHue: 4;
		--sphereColor: hwb(var(--sphereHue) 5% var(--sphereColorDarken));
		--sphereTranslateZ: calc(-2em + 1em * var(--sphereSize) - 0.1em);
		position: absolute;
		aspect-ratio: 1/1;
		width: calc(var(--sphereBaseSize) * var(--sphereSize));
		height: auto;
		border-radius: 50%;
		background-color: var(--sphereColor);
		background-image: radial-gradient(var(--sphereBgColor) -70%, var(--sphereColor));
		filter: drop-shadow(0.1rem calc(var(--sphereBaseSize) * var(--sphereSize) / 2) calc(4rem * var(--sphereSize)) var(--sphereColor)) blur(0.1rem);
		transform: translateX(var(--sphereTranslateX)) translateZ(var(--sphereTranslateZ)) translateY(var(--sphereTranslateY));
		left: 0;
		transition-property: transform;
		transition-timing-function: ease-in-out;
		--sphereDuration: 2s;
		transition-duration: var(--sphereDuration);
	}
	
	.line.back {
		transform: translateZ(-10em);
	}
	
	.line.first {
		top: var(--top);
	}
	
	.line.second {
		top: calc(var(--top) * 2);
	}
	
	.line.third {
		top: calc(var(--top) * 3);
	}
	
	.line.fourth {
		top: calc(var(--top) * 4);
	}
`;

export default Sky1;