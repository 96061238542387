const Stats = function () {
  let container = document.createElement("div");
  let beginTime = (performance || Date).now();
  let prevTime = beginTime;
  let msPanel = new Stats.Panel("MS");
  container.id = "stats_cont"
  container.appendChild(msPanel.dom);
  document.body.querySelector("#stats_div").appendChild(container);

  return {
    begin: () => beginTime = (performance || Date).now(),
    end: function () {
      try {
        let time = (performance || Date).now();
        msPanel.update(time - beginTime, 200, container.offsetWidth + 20,container.offsetHeight + 20);
        if (time > prevTime + 1000) {
          prevTime = time;
        }
        return time;
      } catch {}
    },
    update: function () {
      beginTime = this.end();
    },
  };
};

Stats.Panel = function (name) {
  let min = Infinity;
  let max = 0;
  let round = Math.round;
  let pixRes = round(window.devicePixelRatio || 1);
  const graphX = 3;
  const graphY = 10;
  const canvas = document.createElement("canvas");
  canvas.id = "stats_cnv"
  const resizeObserver = new ResizeObserver(entries => canvas.height = entries[0].target.offsetHeight);
  resizeObserver.observe(document.body.querySelector("#stats_div"));
  const context = canvas.getContext("2d", {
  willReadFrequently: true
});
  return {
    dom: canvas,
    update: function (value, maxValue, width, height) {
      min = Math.min(min, value);
      max = Math.max(max, value);
      document.querySelector("#stats_text").innerText = round(value) + " " + name + " (" + round(min) + "-" + round(max) + ")"
      context.fillStyle = "var(--colorgrad3)";
      context.globalAlpha = 1;
      context.fillRect(0, 0, width, graphY);
      context.fillStyle = 'var(--colorgrad7)'
      context.drawImage(canvas, graphX + pixRes, graphY, width - 6 - pixRes, height - 18, graphX, graphY, width - 6 - pixRes, height - 18);
      context.fillRect(graphX + width - 6 - pixRes, graphY, pixRes, height - 18);
      context.fillStyle = "var(--colorgrad3)";
      context.fillRect(graphX + width - 6 - pixRes, graphY, pixRes, round((1 - value / maxValue) * height - 18));
    },
  };
};

export { Stats as default };
