export const clamp = (num, min, max) =>
  num <= min ? min : num >= max ? max : num;

export const map = (n, x1, y1, x2, y2) =>
  ((n - x1) * (y2 - x2)) / (y1 - x1) + x2;

export const intersects = (x, y, cx, cy, r) => {
  let dx = x - cx;
  let dy = y - cy;
  return dx * dx + dy * dy <= r * r;
};

// const getHslFromCssVariable = (variable) => {
//   const rootStyles = getComputedStyle(document.documentElement);
//   const hsl = rootStyles.getPropertyValue(variable).trim();
//   const [h, s, l] = hsl.match(/\d+/g).map(Number);
//   return [h, s, l];
// };

// const extractSteps = (steps) => {
//   const colors = [];
//   for (let i = 0; i < steps; i++) {
//     const hsl = getHslFromCssVariable(`--colorgrad${i}`);
//     colors.push(hsl);
//   }
//   const originalLightSteps = colors.map(([h, s, l]) => l);
//   const originalSatSteps = colors.map(([h, s, l]) => s);
//   return { originalLightSteps, originalSatSteps };
// };

export const createColorShades = (color, numberOfShades) => {
  let [h, s, l] = color;
  const originalLightSteps = [100, 94, 87, 82, 75, 62, 52, 27, 5];
  const originalSatSteps = [0, 6, 3, 6, 7, 4, 3, 4, 0];
  // const { originalLightSteps, originalSatSteps } = extractSteps(numberOfShades);
  let baseIndex = 3;
  let baseLight = originalLightSteps[baseIndex];
  let baseSat = originalSatSteps[baseIndex];
  let relativeLightnessSteps = originalLightSteps.map(
    (step) => step - baseLight
  );
  let relativeSatSteps = originalSatSteps.map((step) => step - baseSat);
  let adjustedLightSteps = relativeLightnessSteps.map(
    (relStep) => l + relStep
  );
  let adjustedSatSteps = relativeSatSteps.map((relStep) => s + relStep);
  adjustedLightSteps = adjustedLightSteps
    .map((step) => Math.max(0, Math.min(100, step)))
    .slice(0, numberOfShades);
  adjustedSatSteps = adjustedSatSteps
    .map((step) => Math.max(0, Math.min(100, step)))
    .slice(0, numberOfShades);
  return adjustedLightSteps.map((lightStep, index) => {
    let satStep = adjustedSatSteps[index];
    return `hsl(${h}, ${satStep}%, ${lightStep}%)`;
  });
}

export const HSLToHex = (h, s, l) => {
  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;
  return (
    0 <= h && h < 60
      ? ((r = c), (g = x), (b = 0))
      : 60 <= h && h < 120
        ? ((r = x), (g = c), (b = 0))
        : 120 <= h && h < 180
          ? ((r = 0), (g = c), (b = x))
          : 180 <= h && h < 240
            ? ((r = 0), (g = x), (b = c))
            : 240 <= h && h < 300
              ? ((r = x), (g = 0), (b = c))
              : 300 <= h && h < 360 && ((r = c), (g = 0), (b = x)),
    (r = Math.round((r + m) * 255).toString(16)),
    (g = Math.round((g + m) * 255).toString(16)),
    (b = Math.round((b + m) * 255).toString(16)),
    r.length === 1 && (r = "0" + r),
    g.length === 1 && (g = "0" + g),
    b.length === 1 && (b = "0" + b),
    r + g + b
  );
}

const hexToHSL = hex => {
  hex = hex.replace(/^#/, '');
  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;
  if (max === min) {
    h = s = 0;
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
      default: h = 0;
    }
    h /= 6;
  }
  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100)
  };
};

export const HSLToRGB = (h, s, l) => {
  s /= 100;
  l /= 100;
  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;
  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);
  return [r, g, b];
};

export const RGBToHSL = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
      default: h = (r - g) / d + 4;
    }
    h /= 6;
  }
  return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
};

export const hslToObject = (color) => {
  if (color.startsWith("hsl")) {
    return JSON.parse(color.replace(/hsl\((\d+),\s*(\d+(?:\.\d+)?)%,\s*(\d+(?:\.\d+)?)%\)/,'{"h":$1,"s":$2,"l":$3}'));
  } else if (color.startsWith("#")) {
    return hexToHSL(color);
  } else if (color.startsWith('rgb')) {
    const [r, g, b] = (color.match(/\d+/g) || []).map(Number)
    const [h, s, l] = RGBToHSL(r, g, b);
    return { h, s, l };
  } else {
    return { h: 0, s: 50, l: 50 }
  }
}
