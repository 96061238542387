const File = [
  {
    type: 'item',
    disable: true,
    text: 'New',
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    disable: false,
    text: 'Save As...',
  },
];
const Add = [
  {
    type: 'item',
    text: 'Time/Date',
  },
];
const Format = [
  {
    type: 'item',
    text: 'Large Font',
    check: false,
  },
  {
    type: 'item',
    text: 'Word Wrap',
    check: true,
  },
];
const Help = [
  {
    type: 'item',
    text: 'About...',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Help Center',
    disable: false,
  },
];
const Edit = [
  {
    type: 'item',
    text: 'Select All',
    disable: true,
  },
  {
    type: 'item',
    text: 'Select None',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Close',
    disable: false,
  },
];

const optionsMenuData = { File, Add, Format, Help, Edit };
export default optionsMenuData;
