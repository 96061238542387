import React, { useEffect, useRef, useState } from "reactn";
import styled from "styled-components";
import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/droppable";
import "jquery-ui/ui/widgets/resizable";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widget";
import "jquery-ui/ui/widgets/mouse";
import cardsgif from "assets/soulitaire/cards.png";
import optionsMenuData from "./optionsMenuData";
import { OptionsMenu } from "components";
import grabber from "assets/cursors/grab.cur";
import pointer from "assets/cursors/point.cur";
import mover from "assets/cursors/move.cur";

const initDeck = Math.ceil(Math.random() * 11);

const Soulitaire = ({ onClose, onToggleComponent, id }) => {
  const [shuffled, setShuffled] = useState(null);
  const cardDeck = useRef(initDeck);
  const cards = useRef([]);
  const lastMove = useRef({});
  const flippedUp = useRef(null);

  function onClickOptionItem(item) {
    switch (item.text) {
      case "Card Style":
        editDeck();
        break;
      case "New":
        lastMove.current = {};
        optionsMenuData["Game"][3].disable = true;
        optionsMenuData["Game"][1].disable = true;
        setShuffled(shuffle(cards.current));
        startGame();
        break;
      case "Undo":
        undoMove();
        break;
      case "Restart":
        lastMove.current = {};
        optionsMenuData["Game"][3].disable = true;
        optionsMenuData["Game"][1].disable = true;
        startGame();
        break;
      case "Help Center":
        onToggleComponent("Help", "open", id);
        break;
      case "Close":
        onClose();
        break;
      default:
    }
  }

  function changeDeck() {
    const css = {
      backgroundPosition: `${-72 * cardDeck.current}px -400px`,
    };
    $("#soul_stock .cards").css(css);
    $("#soul_table .cards.flippedDown").css(css);
  }

  function editDeck() {
    let deck = cardDeck.current + 1;
    if (deck > 11) deck = 1;
    cardDeck.current = deck;
    changeDeck();
  }

  function shuffle(array) {
    let m = array.length,
      t,
      i;
    while (m) {
      i = Math.floor(Math.random() * m--);
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
    return array;
  }

  function startGame() {
    $("#soul_inner").css({pointerEvents: 'none'});
    setTimeout(() => $("#soul_inner").css({pointerEvents: 'auto'}), 2900);
    flippedUp.current = $("#soul_table .cards:not(.flippedDown)");

    $("#win").remove();
    clearTimeout(loop);
    loop = null;
    clearTimeout(anim);
    anim = null;

    const EL_CSS = {
      top: "0",
      left: "0",
      "z-index": "10",
    };

    const upper = $('<div id="soul_upper" class="mat_section">');
    const stock = $('<div id="soul_stock">');
    const table = $('<div id="soul_table" class="mat_section">').html(
      "<div></div><div></div><div></div><div></div><div></div><div></div><div></div>"
    );
    const waste = $('<div id="waste">');
    const foundation =
      '<div class="soul_blank"></div><div class="soul_foundation cn-0" id="found1"></div><div class="soul_foundation cn-0" id="found2"></div><div class="soul_foundation cn-0" id="found3"></div><div class="soul_foundation cn-0" id="found4"></div>';

    const shuffledClone = shuffled.slice(0);
    const tablCards = shuffledClone.splice(0, 28);
    const cTD = 3;

    const cardsCss = (suit, i) => {
      if (suit === "c-hearts") return `-${72 * i}px 0`;
      if (suit === "c-diams") return `-${72 * i}px -100px`;
      if (suit === "c-clubs") return `-${72 * i}px -200px`;
      if (suit === "c-spades") return `-${72 * i}px -300px`;
    };

    $.each(shuffledClone, function (i, el) {
      $(el)
        .css({
          top: ~~(i / cTD),
          left: ~~(i / cTD),
        })
        .appendTo(stock);
    });

    $.each(tablCards, function (i, el) {
      el = $(el).addClass("flippedDown");

      if (i === 0) {
        table.children("div:eq(0)").append(el);
      } else if (i === 1) {
        table.children("div:eq(1)").append(el);
      } else if (i === 2) {
        table.children("div:eq(1)").find(":empty").append(el);
      } else if (i === 3) {
        table.children("div:eq(2)").append(el);
      } else if (i <= 5) {
        table.children("div:eq(2)").find(":empty").append(el);
      } else if (i === 6) {
        table.children("div:eq(3)").append(el);
      } else if (i <= 9) {
        table.children("div:eq(3)").find(":empty").append(el);
      } else if (i === 10) {
        table.children("div:eq(4)").append(el);
      } else if (i <= 14) {
        table.children("div:eq(4)").find(":empty").append(el);
      } else if (i === 15) {
        table.children("div:eq(5)").append(el);
      } else if (i <= 20) {
        table.children("div:eq(5)").find(":empty").append(el);
      } else if (i === 21) {
        table.children("div:eq(6)").append(el);
      } else if (i <= 28) {
        table.children("div:eq(6)").find(":empty").append(el);
      }
    });

    table.find(":empty").removeClass("flippedDown");

    function cardMoved(divDragged, divDropped, parent) {
      divDragged.classList.add('nofx');
      optionsMenuData["Game"][1].disable = false;
      optionsMenuData["Game"][3].disable = false;
      $("#soul_table :empty").removeClass("flippedDown");
      if (
        parent.classList &&
        parent.classList.length > 0 &&
        parent.classList[0] === "cards"
      ) {
        lastMove.current = {
          type: "move",
          divDragged: divDragged,
          origin: parent,
          appendedTo: divDropped,
        };
        if (parent.classList.length > 1) {
          const suit = parent.classList[2];
          const num = parent.classList[3].substring(3);
          parent.style.backgroundPosition = cardsCss(suit, num - 1);
        }
      } else if (
        parent.classList &&
        parent.classList.value === "ui-droppable"
      ) {
        lastMove.current = {
          type: "empty",
          divDragged: divDragged,
          origin: parent,
          appendedTo: divDropped,
        };
      } else if (parent.classList && parent.classList[0] === "foundation") {
        lastMove.current = {
          type: "foundation",
          divDragged: divDragged,
          origin: parent,
          appendedTo: divDropped,
        };
      } else if (parent.id === "waste") {
        lastMove.current = { type: "waste", divDragged: divDragged };
      } else {
        lastMove.current = { type: "other", divDragged: divDragged };
      }
      if (
        $(".soul_foundation:eq(0) .cn-13").length &&
        $(".soul_foundation:eq(1) .cn-13").length &&
        $(".soul_foundation:eq(2) .cn-13").length &&
        $(".soul_foundation:eq(3) .cn-13").length
      ) {
        win();
      }
    }

    upper.append(stock, waste, foundation);
    $("#greentable").html("").append(upper, table);

    let timeout;

    $("#waste, #soul_table").on("dblclick", ".cards", function (e) {
      e.stopPropagation();
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      let validFoundationEmplacement = "";
      if ($(this).hasClass("cn-1")) {
        validFoundationEmplacement = ".soul_foundation:empty:first";
      } else {
        let e = $(this);
        $(".soul_foundation .cards").each(function (i, el) {
          if ($(el).is(".cn-" + (e.data("cid") - 1) + ".c-" + e.data("type"))) {
            validFoundationEmplacement = $(el).parent();
          }
        });
      }
      if (validFoundationEmplacement) {
        const parent = this.parentElement;
        $(this)
          .css(EL_CSS)
          .appendTo(validFoundationEmplacement)
          .delay(300)
          .queue(function () {
            $(this).css(EL_CSS).dequeue();
          });
        if (
          parent.id !== "waste" &&
          parent.classList.value !== "ui-droppable"
        ) {
          const suit = parent.classList[2];
          const num = parent.classList[3].substring(3);
          $(parent).css({ backgroundPosition: cardsCss(suit, num - 1) });
        }
        cardMoved(this, this.parentElement, parent);
      }
    });

    $("#soul_stock")
      .on("click", ".cards:last", function (e) {
        e.stopPropagation();
        lastMove.current = { type: "click" };
        optionsMenuData["Game"][1].disable = false;
        optionsMenuData["Game"][3].disable = false;
        const self = this;
        const suit = $(self).prop("classList")[2];
        const num = $(self).prop("classList")[3].substring(3);
        timeout = setTimeout(function () {
          let i = $("#waste .cards").length;
          const css = {
            top: ~~(i / cTD),
            left: ~~(i / cTD),
            zIndex: 10,
            backgroundPosition: cardsCss(suit, num - 1),
          };
          $(self).css(css).appendTo("#waste");
          timeout = null;
        }, 0);
      })
      .on("click", function (e) {
        const lastCard = $("#soul_stock .cards:last");
        e.stopPropagation();
        if (e.target.id !== "soul_stock" && e.target !== lastCard) return;
        lastMove.current = {};
        $($("#waste .cards").get().reverse()).each(function (i, el) {
          $(el)
            .css({
              top: ~~(i / cTD),
              left: ~~(i / cTD),
              backgroundPosition: `${-72 * cardDeck.current}px -400px`,
            })
            .appendTo("#soul_stock");
        });
      });

    $(".soul_foundation").droppable({
      tolerance: "pointer",
      hoverClass: "c-accept",
      accept: function (e) {
        if (
          (!$(this).children().length &&
            $(this).hasClass("cn-" + (e.data("cid") - 1))) ||
          $(this)
            .children(".cards:last-child")
            .is(".cn-" + (e.data("cid") - 1) + ".c-" + e.data("type"))
        ) {
          return true;
        }
      },
      drop: function (e, ui) {
        const parent = ui.draggable[0].parentElement;
        $(this).append(ui.draggable.css(EL_CSS));
        cardMoved(ui.draggable[0], this, parent);
      },
    });
    $("#soul_table > div").droppable({
      tolerance: "pointer",
      accept: function (e) {
        if (!$(this).children().length && e.hasClass("cn-13")) {
          return true;
        }
      },
      hoverClass: "c-accept",
      drop: function (e, ui) {
        const parent = ui.draggable[0].parentElement;
        $(this).append(ui.draggable.css(EL_CSS));
        cardMoved(ui.draggable[0], this, parent);
      },
    });

    $("#greentable .cards")
      .draggable({
        revert: "invalid",
        distance: 10,
        revertDuration: 300,
        zIndex: 100,
        start: function (e, ui) {
          if (
            ui.helper.parent("#soul_stock").length ||
            ui.helper.hasClass("flippedDown")
          ) {
            ui.helper.css("zIndex", "auto");
            return false;
          }
        },
      })
      .droppable({
        tolerance: "touch",
        hoverClass: "c-accept",
        accept: function (e) {
          if (
            !$(this).children().length &&
            !$(this).is("#soul_stock .cards") &&
            !$(this).is("#waste .cards") &&
            !$(this).is(".soul_foundation .cards") &&
            ((e.hasClass("c-red") && $(this).hasClass("c-black")) ||
              (e.hasClass("c-black") && $(this).hasClass("c-red")))
          ) {
            return $(this).hasClass("cn-" + (e.data("cid") + 1));
          }
        },
        drop: function (e, ui) {
          const parent = ui.draggable[0].parentElement;
          e.stopPropagation();
          $(this).append(
            ui.draggable.css({
              top: "20px",
              left: "auto",
              "z-index": "10",
            })
          );
          cardMoved(ui.draggable[0], this, parent);
        },
      });
      editDeck();
  }

  let loop, anim;

  function win() {
    lastMove.current = {};
    flippedUp.current = null;
    $(
      '<canvas id="win" style="position:absolute; z-index:200; top:0px; left:0px; pointer-events: none;"></canvas>'
    ).appendTo("body");
    const canvas = document.getElementById("win");
    const matt = document.getElementById("soul_inner");
    canvas.width = matt.offsetWidth;
    canvas.height = matt.offsetHeight;
    const resizeObserver = new ResizeObserver((entries) => {
      canvas.width = entries[0].target.clientWidth;
      canvas.height = entries[0].target.clientHeight;
    });
    resizeObserver.observe(matt);
    matt.appendChild(canvas);

    const context = canvas.getContext('2d', { willReadFrequently: true });
    const cwidth = 72;
    const cheight = 100;
    const particles = [];

    const Particle = function (id, x, y, sx, sy) {
      if (sx === 0) sx = 2;
      const cx = (id % 13) * cwidth;
      const cy = Math.floor(id / 13) * cheight;

      this.update = function () {
        if (x < -cwidth || x > canvas.width + cwidth) {
          const index = particles.indexOf(this);
          particles.splice(index, 1);
          return false;
        }
        if (y > canvas.height - cheight) {
          y = canvas.height - cheight;
          sy = -sy * 0.85;
        }
        sy += 0.98;

        context.drawImage(
          image,
          cx,
          cy,
          cwidth,
          cheight,
          x,
          y,
          cwidth,
          cheight
        );

        x += sx;
        y += sy;

        return true;
      };
    };

    const image = document.createElement("img");
    image.src = cardsgif;

    let cidH = 13;
    let cidD = 26;
    let cidC = 39;
    let cidS = 52;
    const throwCard = function (x, y, type) {
      let cid;
      if (type === "hearts") {
        cidH > 0 ? cidH-- : (cidH = 12);
        cid = cidH;
      } else if (type === "diams") {
        cidD > 13 ? cidD-- : (cidD = 25);
        cid = cidD;
      } else if (type === "clubs") {
        cidC > 26 ? cidC-- : (cidC = 38);
        cid = cidC;
      } else if (type === "spades") {
        cidS > 39 ? cidS-- : (cidS = 51);
        cid = cidS;
      }
      const particle = new Particle(
        cid,
        x,
        y,
        Math.floor(Math.random() * 6 - 3) * 2,
        -Math.random() * 16
      );
      particles.push(particle);
    };

    let switchType = 4;
    const types = ["hearts", "diams", "clubs", "spades"];
    loop = setInterval(function () {
      switchType > 0 ? switchType-- : (switchType = 3);
      const p1 = $(".soul_foundation").eq(switchType).position();
      if (p1) throwCard(p1.left, p1.top, types[switchType]);
    }, 2000);

    anim = setInterval(function () {
      let i = 0,
        l = particles.length;
      while (i < l) {
        particles[i].update() ? i++ : l--;
      }
    }, 1000 / 60);
  }
  function undoMove() {
    optionsMenuData["Game"][3].disable = true;
    if (lastMove.current.type) {
      if (lastMove.current.type === "click") {
        const self = $("#waste .cards:last");
        let i = $("#soul_stock .cards").length;
        const css = {
          top: ~~(i / 3),
          left: ~~(i / 3),
          zIndex: 10,
          backgroundPosition: `${-72 * cardDeck.current}px -400px`,
        };
        self.css(css).appendTo("#soul_stock");
        lastMove.current = {};
      } else if (lastMove.current.type === "move") {
        const cardClass =
          "." +
          lastMove.current.divDragged.classList.value.split(" ").join(".");
        lastMove.current.divDragged.style.position = "absolute";
        lastMove.current.divDragged.style.top = "20px";
        $(cardClass).appendTo(lastMove.current.origin);
        if (
          document.getElementById("table").contains(lastMove.current.origin)
        ) {
          lastMove.current.origin.classList.add("flippedDown");
          lastMove.current.origin.style.backgroundPosition = `${
            -72 * cardDeck.current
          }px -400px`;
        }
        lastMove.current = {};
      } else if (lastMove.current.type === "waste") {
        const cardClass =
          "." +
          lastMove.current.divDragged.classList.value.split(" ").join(".");
        let i = $("#waste .cards").length;
        const css = {
          top: ~~(i / 3),
          left: ~~(i / 3),
          zIndex: 10,
        };
        $(cardClass).css(css).appendTo("#waste");
        lastMove.current = {};
      } else if (lastMove.current.type === "empty") {
        const cardClass =
          "." +
          lastMove.current.divDragged.classList.value.split(" ").join(".");
        $(cardClass).appendTo(lastMove.current.origin);
        lastMove.current.divDragged.style.position = "absolute";
        lastMove.current.divDragged.style.top = "0";
        lastMove.current = {};
      } else if (lastMove.current.type === "foundation") {
        const cardClass =
          "." +
          lastMove.current.divDragged.classList.value.split(" ").join(".");
        $(cardClass).appendTo(lastMove.current.origin);
        lastMove.current.divDragged.style.position = "absolute";
        lastMove.current.divDragged.style.top = "0";
        lastMove.current = {};
      }
    }
  }

  useEffect(() => {
    for (let i = 0; i < 13; i++) {
      if (cards.current.length >= 52) return;
      cards.current.push(
        '<div class="cards c-red c-hearts cn-' +
          (i + 1) +
          '" data-type="hearts" data-cid="' +
          (i + 1) +
          '"></div>'
      );
      cards.current.push(
        '<div class="cards c-red c-diams cn-' +
          (i + 1) +
          '" data-type="diams" data-cid="' +
          (i + 1) +
          '"></div>'
      );
      cards.current.push(
        '<div class="cards c-black c-clubs cn-' +
          (i + 1) +
          '" data-type="clubs" data-cid="' +
          (i + 1) +
          '"></div>'
      );
      cards.current.push(
        '<div class="cards c-black c-spades cn-' +
          (i + 1) +
          '" data-type="spades" data-cid="' +
          (i + 1) +
          '"></div>'
      );
    }
    setShuffled(shuffle(cards.current));
  }, []);

  useEffect(() => {
    if (shuffled) {
      startGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shuffled]);

  return (
    <Div>
      <section className="window_toolbar">
        <OptionsMenu items={optionsMenuData} onClickItem={onClickOptionItem} />
      </section>
      <div className="window_content">
        <div className="window_content_inner" id="soul_inner">
          <div id="greentable"></div>
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .window_content {
    height: 100%;
  }
  #soul_inner {
    height: calc(100% - 4px);
    background-image: radial-gradient(
      ellipse,
      rgba(78, 183, 150, 0.75),
      rgba(71, 169, 138, 0.85),
      rgba(54, 145, 115, 0.95)
    );
  }
  @keyframes dealcards {
    from {
      top: 0;
    }
    to {
      top: 20px;
    }
  }
  #soul_table .cards > .cards {
    top: 20px;
    left: 0;
  }
  #soul_table .cards > .cards:not(.nofx) {
    animation: dealcards 2.9s;
  }
  #soul_blocker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    cursor: none;
  }
  .cards,
  .soul_blank,
  .soul_foundation,
  #waste,
  #soul_stock {
    position: absolute;
    min-width: 72px;
  }
  #soul_table,
  #soul_upper {
    padding: 12px;
  }
  .mat_section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: space-around;
  }
  #greentable {
    position: absolute;
    top: 0;
    left:0;
    min-height: 100%;
    min-width: 100%;
  }
  #win {
    height: 100%;
    width: 100%;
  }
  ::-moz-selection {
    background: transparent !important;
    color: transparent !important;
    text-shadow: none !important;
  }

  ::selection {
    background: transparent !important;
    color: transparent !important;
    text-shadow: none !important;
  }

  #soul_table .ui-draggable:not(.flippedDown),
  #waste .cards:last-child,
  .soul_foundation .cards {
    cursor: url(${grabber}), grab;
  }

  .ui-draggable-dragging {
    cursor: url(${mover}), move !important;
  }

  .soul_foundation,
  #soul_table > div,
  #soul_stock,
  .cards {
    border-radius: 6px;
    position: relative;
    height: 100px;
    width: 72px;
    background: url(${cardsgif}) no-repeat bottom right;
  }
  .c-accept,
  .soul_foundation.c-accept .cards:not(.ui-draggable-dragging) {
    border-radius: 6px;
    box-shadow: inset 0 0 0 100px rgba(255, 255, 255, 0.65);
  }
  .soul_blank,
  .soul_foundation,
  #soul_table > div,
  #soul_stock,
  #waste {
    display: inline-block;
    position: relative;
    height: 100px;
    width: 72px;
  }
  .soul_foundation .cards,
  #soul_stock .cards,
  #waste .cards {
    position: absolute;
    top: 0;
    left: 0;
  }
  #soul_stock {
    background-position: 0px -400px;
  }
  #soul_stock .cards,
  #soul_table .cards.flippedDown {
    background-position: calc(-72px * ${initDeck}) -400px;
  }
  #soul_table .cards .flippedUp {
    cursor: url(${pointer}), pointer !important;
  }
  #soul_stock .cards:last-child {
    cursor: url(${pointer}), pointer !important;
  }
  .soul_foundation,
  #soul_table > div {
    background-position: -864px -400px;
  }

  .soul_foundation .cn-1 {
    z-index: 1 !important;
  }
  .soul_foundation .cn-2 {
    z-index: 2 !important;
  }
  .soul_foundation .cn-3 {
    z-index: 3 !important;
  }
  .soul_foundation .cn-4 {
    z-index: 4 !important;
  }
  .soul_foundation .cn-5 {
    z-index: 5 !important;
  }
  .soul_foundation .cn-6 {
    z-index: 6 !important;
  }
  .soul_foundation .cn-7 {
    z-index: 7 !important;
  }
  .soul_foundation .cn-8 {
    z-index: 8 !important;
  }
  .soul_foundation .cn-9 {
    z-index: 9 !important;
  }
  .soul_foundation .cn-10 {
    z-index: 10 !important;
  }
  .soul_foundation .cn-11 {
    z-index: 11 !important;
  }
  .soul_foundation .cn-12 {
    z-index: 12 !important;
  }
  .soul_foundation .cn-13 {
    z-index: 13 !important;
  }
  .c-hearts.cn-1 {
    background-position: 0px 0;
  }
  .c-diams.cn-1 {
    background-position: 0px -100px;
  }
  .c-clubs.cn-1 {
    background-position: 0px -200px;
  }
  .c-spades.cn-1 {
    background-position: 0px -300px;
  }
  .c-hearts.cn-2 {
    background-position: -72px 0;
  }
  .c-diams.cn-2 {
    background-position: -72px -100px;
  }
  .c-clubs.cn-2 {
    background-position: -72px -200px;
  }
  .c-spades.cn-2 {
    background-position: -72px -300px;
  }
  .c-hearts.cn-3 {
    background-position: -144px 0;
  }
  .c-diams.cn-3 {
    background-position: -144px -100px;
  }
  .c-clubs.cn-3 {
    background-position: -144px -200px;
  }
  .c-spades.cn-3 {
    background-position: -144px -300px;
  }
  .c-hearts.cn-4 {
    background-position: -216px 0;
  }
  .c-diams.cn-4 {
    background-position: -216px -100px;
  }
  .c-clubs.cn-4 {
    background-position: -216px -200px;
  }
  .c-spades.cn-4 {
    background-position: -216px -300px;
  }
  .c-hearts.cn-5 {
    background-position: -288px 0;
  }
  .c-diams.cn-5 {
    background-position: -288px -100px;
  }
  .c-clubs.cn-5 {
    background-position: -288px -200px;
  }
  .c-spades.cn-5 {
    background-position: -288px -300px;
  }
  .c-hearts.cn-6 {
    background-position: -360px 0;
  }
  .c-diams.cn-6 {
    background-position: -360px -100px;
  }
  .c-clubs.cn-6 {
    background-position: -360px -200px;
  }
  .c-spades.cn-6 {
    background-position: -360px -300px;
  }
  .c-hearts.cn-7 {
    background-position: -432px 0;
  }
  .c-diams.cn-7 {
    background-position: -432px -100px;
  }
  .c-clubs.cn-7 {
    background-position: -432px -200px;
  }
  .c-spades.cn-7 {
    background-position: -432px -300px;
  }
  .c-hearts.cn-8 {
    background-position: -504px 0;
  }
  .c-diams.cn-8 {
    background-position: -504px -100px;
  }
  .c-clubs.cn-8 {
    background-position: -504px -200px;
  }
  .c-spades.cn-8 {
    background-position: -504px -300px;
  }
  .c-hearts.cn-9 {
    background-position: -576px 0;
  }
  .c-diams.cn-9 {
    background-position: -576px -100px;
  }
  .c-clubs.cn-9 {
    background-position: -576px -200px;
  }
  .c-spades.cn-9 {
    background-position: -576px -300px;
  }
  .c-hearts.cn-10 {
    background-position: -648px 0;
  }
  .c-diams.cn-10 {
    background-position: -648px -100px;
  }
  .c-clubs.cn-10 {
    background-position: -648px -200px;
  }
  .c-spades.cn-10 {
    background-position: -648px -300px;
  }
  .c-hearts.cn-11 {
    background-position: -720px 0;
  }
  .c-diams.cn-11 {
    background-position: -720px -100px;
  }
  .c-clubs.cn-11 {
    background-position: -720px -200px;
  }
  .c-spades.cn-11 {
    background-position: -720px -300px;
  }
  .c-hearts.cn-12 {
    background-position: -792px 0;
  }
  .c-diams.cn-12 {
    background-position: -792px -100px;
  }
  .c-clubs.cn-12 {
    background-position: -792px -200px;
  }
  .c-spades.cn-12 {
    background-position: -792px -300px;
  }
  .c-hearts.cn-13 {
    background-position: -864px 0;
  }
  .c-diams.cn-13 {
    background-position: -864px -100px;
  }
  .c-clubs.cn-13 {
    background-position: -864px -200px;
  }
  .c-spades.cn-13 {
    background-position: -864px -300px;
  }
`;

export default Soulitaire;
