import React, { useState, useEffect, useGlobal, setGlobal } from "reactn";
import Select from "../controls/Select";
import Painter from "./Sketch";
import Controls from "../controls/index.js";
import Palette from "../controls/Palette.js";
import * as Tone from "tone";
import optionsMenuData from "./optionsMenuData";
import FX from "./FXWrapper";
import { OptionsMenu } from "components";
import loading from "assets/icons/hourglass.gif";
import {
  ShuffleIcon,
  PlayIcon,
  PauseIcon,
  StopIcon,
  MuteIcon,
  UnmuteIcon,
  FFIcon,
  FRIcon,
} from "./Icons.js";
import { map } from "utils";
import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import hp from "assets/remixer/hp.png";
import lp from "assets/remixer/lp.png";
import bp from "assets/remixer/bp.png";
import notch from "assets/remixer/notch.png";
import hs from "assets/remixer/hs.png";
import ls from "assets/remixer/ls.png";

function App(props) {
  const [vidloaded, setVidloaded] = useState(false);
  const [started, setStarted] = useState(false);
  const [mode, setMode] = useState(1);
  const [imgMode, setImgMode] = useState("Select");
  const [track, setTrack] = useState("Select");
  const [audioFx, setAudioFx] = useState("Select");
  const [playing, setPlaying] = useState(false);
  const [songKey, setSongKey] = useState(24);
  const [fxThresh, setFxThresh] = useState(1);
  const [blendMode, setBlendMode] = useState({
    name: "Normal",
    value: "source-over",
  });
  const [mute, setMute] = useState(false);
  const [volume, setVolume] = useState(-20);
  const [view, setView] = useState("acoustics");
  const [enhance, setEnhance] = useState(null);
  const [corrupt, setCorrupt] = useState({ name: "Select", filters: null });
  const [dither, setDither] = useState(null);
  const [viewSamples, setViewSamples] = useState(true);
  const [viewThresh, setViewThresh] = useState(false);
  const [inputCanvas, setInputCanvas] = useState(null);
  const [outputCanvas, setOutputCanvas] = useState(null);
  const incnv = document.querySelector("#remixerInput");
  const outcnv = document.querySelector("#remixerOutput");
  const inputs = document.querySelectorAll("input[type=number].number-input");
  const [godMounted] = useGlobal("godMounted");
  const [paletteColor] = useGlobal("paletteColor");
  const imgFx = document.body.querySelector("#imgFx");
  const video = document.querySelector("video#rmx_vid");
  const [settings1, setSettings1] = useState({
    unmute: true,
    volume: -20,
    samples: 4,
    threshold: 50,
    oct: 2,
    delay: false,
    phaser: false,
    chorus: false,
    distortion: false,
    chebyshev: false,
    pingpong: false,
    tremolo: false,
    autofilter: false,
    reverb: false,
    shifter: false,
    panner: false,
    filter: "lowpass",
  });
  const [settings2, setSettings2] = useState({
    bUnmute: true,
    nUnmute: true,
    bVolume: -20,
    nVolume: -20,
    threshold: 50,
    viewUV: true,
    bFilter: "lowpass",
    nFilter: "highpass",
  });
  const [settings3, setSettings3] = useState({
    kUnmute: true,
    sUnmute: true,
    kVolume: -15,
    sVolume: -20,
    samples: 4,
    threshold: 35,
    kFilters: {
      delay: false,
      phaser: false,
      chorus: false,
      distortion: false,
      chebyshev: false,
      pingpong: false,
      tremolo: false,
      autofilter: false,
      reverb: false,
      panner: false,
    },
    sFilters: {
      delay: false,
      phaser: false,
      chorus: false,
      distortion: false,
      chebyshev: false,
      pingpong: false,
      tremolo: false,
      autofilter: false,
      reverb: false,
      panner: false,
    },
    kFilter: "highpass",
    sFilter: "highpass",
  });
  const [settings4, setSettings4] = useState({
    unmute: true,
    volume: -20,
    samples: 6,
    threshold: 35,
    dynamic: true,
    oct: 2,
    delay: false,
    phaser: false,
    chorus: false,
    distortion: false,
    chebyshev: false,
    pingpong: false,
    tremolo: false,
    autofilter: false,
    reverb: false,
    shifter: false,
    panner: false,
    filter: "lowpass",
  });

  useEffect(() => {
    return () => {
      Tone.Destination.mute = true;
      Tone.Transport.stop();
      setPlaying(false);
    };
  }, []);


  const handleEditPalette = (e) => {
    if (e.target.classList.contains("enabled")) {
      props.onToggleComponent("Edit Colors", "open", props.id, "paletteColor");
    }
  };

  function onClickOptionItem(item) {
    if (item.disable) return;
    switch (item.text) {
      case "Loop":
        item.check = !item.check;
        if (item.check === false) {
          Tone.Transport.loop = false;
        } else {
          Tone.Transport.loop = true;
        }
        break;
      case "Open...":
        handleOpen();
        break;
      case "Repeat":
        item.check = !item.check;
        if (item.check === false) {
          video.loop = false;
        } else {
          video.loop = true;
        }
        break;
      case "Initial Size":
        item.check = !item.check;
        if (item.check === false) {
          document.body.querySelector("#rmxr_div").style.height = "100%";
          document.body.querySelector("#rmxr_div").style.width = "100%";
          document.body.querySelector("#rmxr_cnv").style.height = "auto";
          document.body.querySelector("#rmxr_cnv").style.width = "auto";
        } else {
          document.body.querySelector("#rmxr_div").style.height = "364px";
          document.body.querySelector("#rmxr_div").style.width = "644px";
        }
        break;
      case "Fixed Ratio":
        item.check = !item.check;
        if (item.check === false) {
          document.body.querySelector("#rmxr_div").style.resize = "both";
          document.body.querySelector("#rmxr_div").style.height = "100%";
          document.body.querySelector("#wvfrm_div").style.resize = "horizontal";
        } else {
          document.body.querySelector("#rmxr_div").style.resize = "none";
          document.body.querySelector("#wvfrm_div").style.resize = "none";
          document.body.querySelector("#wvfrm_div").style.width = "100%";
          if (optionsMenuData["Settings"][0].items[1].check === false) {
            document.body.querySelector("#rmxr_div").style.height = "90%";
            document.body.querySelector("#rmxr_div").style.width = "100%";
          } else {
            document.body.querySelector("#rmxr_div").style.height = "364px";
            document.body.querySelector("#rmxr_div").style.width = "644px";
          }
        }
        break;
      case "Frame Stats":
        item.check = !item.check;
        if (item.check === false) {
          document.body.querySelector("#stats_div").style.display = "none";
          if (optionsMenuData["View"][0].check === false) {
            document.body.querySelector("#vis_screen").style.display = "none";
          }
        } else {
          if (optionsMenuData["View"][0].check === false) {
            document.body.querySelector("#stats_div").style.marginLeft = "0px";
            document.body.querySelector("#vis_screen").style.display = "flex";
          }
          document.body.querySelector("#stats_div").style.display = "flex";
        }
        break;
      case "Waveform":
        item.check = !item.check;
        if (item.check === false) {
          document.body.querySelector("#wvfrm_div").style.display = "none";
          if (optionsMenuData["View"][1].check === false) {
            document.body.querySelector("#vis_screen").style.display = "none";
          }
          document.body.querySelector("#stats_div").style.marginLeft = "0px";
        } else {
          if (optionsMenuData["View"][1].check === false) {
            document.body.querySelector("#vis_screen").style.display = "flex";
          }
          document.body.querySelector("#stats_div").style.marginLeft = "4px";
          document.body.querySelector("#wvfrm_div").style.display = "block";
        }
        break;
      case "Information":
        item.check = !item.check;
        if (item.check === false) {
          document.body.querySelectorAll(".fps_switch").forEach((item, i) => {
            item.style.display = "none";
          });
        } else {
          document.body.querySelectorAll(".fps_switch").forEach((item, i) => {
            item.style.display = "flex";
          });
        }
        break;
      case "Fullscreen":
        setGlobal({ fullscreen: !optionsMenuData["View"][3].check });
        item.check = !item.check;
        props.onMaximize();
        break;
      case "Help Center":
        props.onToggleComponent("Help", "open", props.id);
        break;
      case "Close":
        props.onClose();
        break;
      default:
    }
  }

  const toggleView = (e) => setView(e.target.innerText);

  useEffect(() => {
    mute ? (Tone.Destination.mute = true) : (Tone.Destination.mute = false);
    document.addEventListener("visibilitychange", function () {
      if (!mute) {
        document.hidden
          ? (Tone.Destination.mute = true)
          : (Tone.Destination.mute = false);
      } else {
        Tone.Destination.mute = true;
      }
    });
  }, [mute]);

  document.querySelector("#halftoneC")?.addEventListener("click", function () {
    props.onToggleComponent("Edit Colors", "open", props.id, "halftone");
  });

  useEffect(() => {
    $(".address_bar_play").draggable({
      axis: "x",
      revert: "valid",
      zIndex: 100,
      containment: $(".address_bar_track"),
      drag: (e, ui) => {
        document.querySelector(".address_bar_time").style.width =
          ui.position.left + "px";
      },
      stop: (e, ui) => {
        let time =
          ui.position.left /
          (document.querySelector(".address_bar_track").offsetWidth -
            document.querySelector(".address_bar_play").offsetWidth);
        video.currentTime = video.duration * time;
      },
    });
    if (video && video.readyState === 4) {
      setVidloaded(true);
      optionsMenuData["File"][0].disable = true;
    }
  }, [video]);

  useEffect(() => {
    if (paletteColor) props.onAddPaletteColor(paletteColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paletteColor]);

  useEffect(() => {
    if (incnv) {
      props.onSetInputCanvas(incnv);
      setInputCanvas(incnv);
    }
    if (outcnv) {
      setOutputCanvas(outcnv);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incnv, outcnv]);

  useEffect(() => {
    if (inputs && inputs.length > 0) {
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener("keypress", (e) => e.preventDefault());
      }
    }
  }, [inputs]);
  useEffect(() => {
    const drawToCanvasIn = (canvas, image) => {
      canvas.width = document.querySelector("#rmxr_cnv").offsetWidth;
      canvas.height = document.querySelector("#rmxr_cnv").offsetHeight;
      const ctx = canvas.getContext("2d", {
        willReadFrequently: true,
      });
      if (ctx)
        ctx.drawImage(
          image,
          0,
          0,
          Math.round(canvas.width),
          Math.round(canvas.height)
        );
    };
    const drawToCanvasOut = (canvas, image, image2) => {
      canvas.width = document.querySelector("#rmxr_cnv").offsetWidth;
      canvas.height = document.querySelector("#rmxr_cnv").offsetHeight;
      const ctx = canvas.getContext("2d", {
        willReadFrequently: true,
      });
      if (ctx) {
        ctx.drawImage(
          image2,
          0,
          0,
          Math.round(canvas.width),
          Math.round(canvas.height)
        );
        ctx.globalAlpha = fxThresh;
        ctx.globalCompositeOperation = blendMode.value;
        ctx.drawImage(
          image,
          0,
          0,
          Math.round(canvas.width),
          Math.round(canvas.height)
        );
        let dataURL = canvas.toDataURL();
        if (imgFx) imgFx.src = dataURL;
      }
    };
    if (props.viewEffects) {
      if (inputCanvas && props.inputImage) {
        drawToCanvasIn(inputCanvas, props.inputImage);
      }
      if (outputCanvas && props.outputImage && props.inputImage) {
        drawToCanvasOut(outputCanvas, props.outputImage, props.inputImage);
      }
    }
  }, [
    blendMode.value,
    fxThresh,
    imgFx,
    props.viewEffects,
    inputCanvas,
    props.inputImage,
    outputCanvas,
    props.outputImage,
  ]);

  function handleOpen() {
    props.onToggleComponent("God Observer", "open", props.id, "alltokens");
  }

  function handleStart() {
    setStarted(true);
    setPlaying(true);
    props.onLoadImage();
    Tone.start();
    Tone.Transport.start();
    Tone.Transport.volume = volume;
    Tone.Transport.loop = true;
    Tone.Transport.bpm.value = 90;
    Tone.Transport.loopStart = 0;
    Tone.Transport.loopEnd = "4n";

    document.querySelector("#rmxr_cnv").style.opacity = "1";

    setSettings1({
      ...settings1,
      delay: new FX({
        effect: Tone.FeedbackDelay,
        options: { feedback: 0.5, time: "8n" },
        bypass: true,
      }),
      phaser: new FX({
        effect: Tone.Phaser,
        options: { frequency: 800, octaves: 6, baseFrequency: 1200 },
        bypass: true,
      }),
      chorus: new FX({
        effect: Tone.Chorus,
        options: { frequency: 4, delayTime: 2.5, depth: 1 },
        bypass: true,
      }),
      distortion: new FX({
        effect: Tone.Distortion,
        options: { distortion: 1 },
        bypass: true,
      }),
      chebyshev: new FX({
        effect: Tone.Chebyshev,
        options: { order: 1 },
        bypass: true,
      }),
      pingpong: new FX({
        effect: Tone.PingPongDelay,
        options: { feedback: 0.2, time: "4n" },
        bypass: true,
      }),
      tremolo: new FX({
        effect: Tone.Tremolo,
        options: { frequency: 10, spread: 180, depth: 0.5 },
        bypass: true,
      }),
      autofilter: new FX({
        effect: Tone.AutoFilter,
        options: {},
        bypass: true,
      }),
      reverb: new FX({
        effect: Tone.Reverb,
        options: { decay: 2, preDelay: 0.1 },
        bypass: true,
      }),
      shifter: new FX({ effect: Tone.PitchShift, options: {}, bypass: false }),
      panner: new FX({ effect: Tone.Panner, options: {}, bypass: true }),
    });
    setSettings3({
      ...settings3,
      kFilters: {
        delay: new FX({
          effect: Tone.FeedbackDelay,
          options: { feedback: 0.5, time: "8n" },
          bypass: true,
        }),
        phaser: new FX({
          effect: Tone.Phaser,
          options: { frequency: 800, octaves: 6, baseFrequency: 1200 },
          bypass: true,
        }),
        chorus: new FX({
          effect: Tone.Chorus,
          options: { frequency: 4, delayTime: 2.5, depth: 1 },
          bypass: true,
        }),
        distortion: new FX({
          effect: Tone.Distortion,
          options: { distortion: 1 },
          bypass: true,
        }),
        chebyshev: new FX({
          effect: Tone.Chebyshev,
          options: { order: 1 },
          bypass: true,
        }),
        pingpong: new FX({
          effect: Tone.PingPongDelay,
          options: { feedback: 0.2, time: "4n" },
          bypass: true,
        }),
        tremolo: new FX({
          effect: Tone.Tremolo,
          options: { frequency: 10, spread: 180, depth: 0.5 },
          bypass: true,
        }),
        autofilter: new FX({
          effect: Tone.AutoFilter,
          options: {},
          bypass: true,
        }),
        reverb: new FX({
          effect: Tone.Reverb,
          options: { decay: 2, preDelay: 0.1 },
          bypass: true,
        }),
        panner: new FX({ effect: Tone.Panner, options: {}, bypass: true }),
      },
      sFilters: {
        delay: new FX({
          effect: Tone.FeedbackDelay,
          options: { feedback: 0.5, time: "8n" },
          bypass: true,
        }),
        phaser: new FX({
          effect: Tone.Phaser,
          options: { frequency: 800, octaves: 6, baseFrequency: 1200 },
          bypass: true,
        }),
        chorus: new FX({
          effect: Tone.Chorus,
          options: { frequency: 4, delayTime: 2.5, depth: 1 },
          bypass: true,
        }),
        distortion: new FX({
          effect: Tone.Distortion,
          options: { distortion: 1 },
          bypass: true,
        }),
        chebyshev: new FX({
          effect: Tone.Chebyshev,
          options: { order: 1 },
          bypass: true,
        }),
        pingpong: new FX({
          effect: Tone.PingPongDelay,
          options: { feedback: 0.2, time: "4n" },
          bypass: true,
        }),
        tremolo: new FX({
          effect: Tone.Tremolo,
          options: { frequency: 10, spread: 180, depth: 0.5 },
          bypass: true,
        }),
        autofilter: new FX({
          effect: Tone.AutoFilter,
          options: {},
          bypass: true,
        }),
        reverb: new FX({
          effect: Tone.Reverb,
          options: { decay: 2, preDelay: 0.1 },
          bypass: true,
        }),
        panner: new FX({ effect: Tone.Panner, options: {}, bypass: true }),
      },
    });
    setSettings4({
      ...settings4,
      delay: new FX({
        effect: Tone.FeedbackDelay,
        options: { feedback: 0.5, time: "8n" },
        bypass: false,
      }),
      phaser: new FX({
        effect: Tone.Phaser,
        options: { frequency: 800, octaves: 6, baseFrequency: 1200 },
        bypass: true,
      }),
      chorus: new FX({
        effect: Tone.Chorus,
        options: { frequency: 4, delayTime: 2.5, depth: 1 },
        bypass: true,
      }),
      distortion: new FX({
        effect: Tone.Distortion,
        options: { distortion: 1 },
        bypass: true,
      }),
      chebyshev: new FX({
        effect: Tone.Chebyshev,
        options: { order: 1 },
        bypass: true,
      }),
      pingpong: new FX({
        effect: Tone.PingPongDelay,
        options: { feedback: 0.2, time: "4n" },
        bypass: true,
      }),
      tremolo: new FX({
        effect: Tone.Tremolo,
        options: { frequency: 10, spread: 180, depth: 0.5 },
        bypass: true,
      }),
      autofilter: new FX({
        effect: Tone.AutoFilter,
        options: {},
        bypass: true,
      }),
      reverb: new FX({
        effect: Tone.Reverb,
        options: { decay: 2, preDelay: 0.1 },
        bypass: false,
      }),
      shifter: new FX({ effect: Tone.PitchShift, options: {}, bypass: false }),
      panner: new FX({ effect: Tone.Panner, options: {}, bypass: true }),
    });
  }

  const filterColorSection = (
    <>
      {props.optionTypes.palette && (
        <>
          <Palette
            disabled={started ? false : true}
            onClickAddColor={handleEditPalette}
            types={props.optionTypes.palette}
            value={props.options.palette}
            paletteOptions={props.options.palette.options}
            onAddPaletteColor={props.onAddPaletteColor}
            onSetFilterOption={props.onSetFilterOption}
            onSetPaletteOption={props.onSetPaletteOption}
            inputCanvas={inputCanvas}
          />
        </>
      )}
    </>
  );

  // function shuffleFilters() {
  //   //console.log(props.selectedFilter.filter.optionTypes['palette']);
  // }

  const filterOpacSection = (
    <>
      <section className="box_section">
        <div className="box_section_title">
          <span>Opacity</span>
        </div>
        <div className="rm_boxes">
          <input
            className={started ? "" : "disabled"}
            id="viewEffects"
            type="checkbox"
            onChange={(e) => {
              if (!started) return;
              props.onSetViewEffects(e.target.checked);
            }}
            checked={props.viewEffects}
          />
          <label htmlFor="viewEffects">
            <span></span>
          </label>
          <input
            value={fxThresh}
            type="range"
            className={`slider${started ? "" : " disabled"}`}
            min="0"
            max="1"
            step="0.1"
            onChange={(e) => {
              if (!started) return;
              setFxThresh(e.target.value);
            }}
          />
        </div>
      </section>
    </>
  );
  const filterBlendSection = (
    <>
      <section className="box_section">
        <div className="box_section_title">
          <span>Blend</span>
        </div>
        <div className="rm_boxes">
          <Select
            disabled={started ? false : true}
            id="blendFilters"
            onSetFilterOption={setBlendMode}
            name={blendMode.name}
            types={{
              options: [
                { name: "Normal", value: "source-over" },
                { name: "Lighter", value: "lighter" },
                { name: "Multiply", value: "multiply" },
                { name: "Screen", value: "screen" },
                { name: "Overlay", value: "overlay" },
                { name: "Lighten", value: "lighten" },
                { name: "Darken", value: "darken" },
                { name: "Color Dodge", value: "color-dodge" },
                { name: "Color Burn", value: "color-burn" },
                { name: "Hard Light", value: "hard-light" },
                { name: "Soft Light", value: "soft-light" },
                { name: "Difference", value: "difference" },
                { name: "Exclusion", value: "exclusion" },
                { name: "Hue", value: "hue" },
                { name: "Saturation", value: "saturation" },
                { name: "Color", value: "color" },
                { name: "Luminosity", value: "luminosity" },
              ],
            }}
          />
        </div>
      </section>
    </>
  );
  const audioSubSelSection = (
    <>
      {mode === 3 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Track</span>
          </div>
          <div className="rm_boxes">
            <Select
              disabled={started ? false : true}
              id="mainFilters"
              onSetFilterOption={setTrack}
              name={
                track === "Impact" || track === "Percussion"
                  ? track
                  : setTrack("Impact")
              }
              types={{
                options: [{ value: "Impact" }, { value: "Percussion" }],
              }}
            />
          </div>
        </section>
      )}
      {mode === 2 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Track</span>
          </div>
          <div className="rm_boxes">
            <Select
              disabled={started ? false : true}
              id="mainFilters"
              onSetFilterOption={setTrack}
              name={
                track === "Bass" || track === "Noise" ? track : setTrack("Bass")
              }
              types={{
                options: [{ value: "Bass" }, { value: "Noise" }],
              }}
            />
          </div>
        </section>
      )}
    </>
  );
  const filterSelectSection = (
    <>
      <section className="box_section">
        <div className="box_section_title">
          <span>Algorithm</span>
        </div>
        <div className="rm_boxes">
          <Select
            disabled={started ? false : true}
            id="mainFilters"
            onSetFilterOption={setImgMode}
            name={imgMode}
            types={{
              options: props.mainFilters,
            }}
          />
        </div>
      </section>
    </>
  );

  const audioEnvSection = (
    <>
      <section className="box_section">
        <div className="box_section_title">
          <span>Envelope</span>
        </div>
        <div className="envelope greyInset">
          <canvas id="env_cnv"></canvas>
        </div>
      </section>
    </>
  );

  const audioFilSection = (
    <>
      <section className="box_section">
        <div className="box_section_title">
          <span>Filter</span>
        </div>
        <div className="envelope greyInset">
          <canvas id="fil_cnv"></canvas>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "6px",
            flexWrap: "wrap",
          }}
        >
          <div
            className={`button filterbtn ${
              started && playing ? "" : "disabled"
            }`}
            onClick={(e) => {
              if (!started) return;
              if (mode === 1)
                setSettings1({ ...settings1, filter: "highpass" });
              if (mode === 2 && track === "Noise")
                setSettings2({ ...settings2, nFilter: "highpass" });
              if (mode === 2 && track === "Bass")
                setSettings2({ ...settings2, bFilter: "highpass" });
              if (mode === 3 && track === "Percussion")
                setSettings3({ ...settings3, sFilter: "highpass" });
              if (mode === 3 && track === "Impact")
                setSettings3({ ...settings3, kFilter: "highpass" });
              if (mode === 4)
                setSettings4({ ...settings4, filter: "highpass" });
            }}
            title="highpass"
          >
            <img
              className={vidloaded ? "" : "playerico disabled"}
              src={hp}
              width="16"
              height="16"
              alt="highpass"
            />
          </div>
          <div
            className={`button filterbtn ${
              started && playing ? "" : "disabled"
            }`}
            onClick={(e) => {
              if (!started) return;
              if (mode === 1) setSettings1({ ...settings1, filter: "lowpass" });
              if (mode === 2 && track === "Noise")
                setSettings2({ ...settings2, nFilter: "lowpass" });
              if (mode === 2 && track === "Bass")
                setSettings2({ ...settings2, bFilter: "lowpass" });
              if (mode === 3 && track === "Percussion")
                setSettings3({ ...settings3, sFilter: "lowpass" });
              if (mode === 3 && track === "Impact")
                setSettings3({ ...settings3, kFilter: "lowpass" });
              if (mode === 4) setSettings4({ ...settings4, filter: "lowpass" });
            }}
            title="lowpass"
          >
            <img
              className={vidloaded ? "" : "playerico disabled"}
              src={lp}
              width="16"
              height="16"
              alt="lowpass"
            />
          </div>
          <div
            className={`button filterbtn ${
              started && playing ? "" : "disabled"
            }`}
            onClick={(e) => {
              if (!started) return;
              if (mode === 1)
                setSettings1({ ...settings1, filter: "bandpass" });
              if (mode === 2 && track === "Noise")
                setSettings2({ ...settings2, nFilter: "bandpass" });
              if (mode === 2 && track === "Bass")
                setSettings2({ ...settings2, bFilter: "bandpass" });
              if (mode === 3 && track === "Percussion")
                setSettings3({ ...settings3, sFilter: "bandpass" });
              if (mode === 3 && track === "Impact")
                setSettings3({ ...settings3, kFilter: "bandpass" });
              if (mode === 4)
                setSettings4({ ...settings4, filter: "bandpass" });
            }}
            title="bandpass"
          >
            <img
              className={vidloaded ? "" : "playerico disabled"}
              src={bp}
              width="16"
              height="16"
              alt="bandpass"
            />
          </div>
          <div
            className={`button filterbtn ${
              started && playing ? "" : "disabled"
            }`}
            onClick={(e) => {
              if (!started) return;
              if (mode === 1) setSettings1({ ...settings1, filter: "notch" });
              if (mode === 2 && track === "Noise")
                setSettings2({ ...settings2, nFilter: "notch" });
              if (mode === 2 && track === "Bass")
                setSettings2({ ...settings2, bFilter: "notch" });
              if (mode === 3 && track === "Percussion")
                setSettings3({ ...settings3, sFilter: "notch" });
              if (mode === 3 && track === "Impact")
                setSettings3({ ...settings3, kFilter: "notch" });
              if (mode === 4) setSettings4({ ...settings4, filter: "notch" });
            }}
            title="notch"
          >
            <img
              className={vidloaded ? "" : "playerico disabled"}
              src={notch}
              width="16"
              height="16"
              alt="notch"
            />
          </div>
          <div
            className={`button filterbtn ${
              started && playing ? "" : "disabled"
            }`}
            onClick={(e) => {
              if (!started) return;
              if (mode === 1)
                setSettings1({ ...settings1, filter: "lowshelf" });
              if (mode === 2 && track === "Noise")
                setSettings2({ ...settings2, nFilter: "lowshelf" });
              if (mode === 2 && track === "Bass")
                setSettings2({ ...settings2, bFilter: "lowshelf" });
              if (mode === 3 && track === "Percussion")
                setSettings3({ ...settings3, sFilter: "lowshelf" });
              if (mode === 3 && track === "Impact")
                setSettings3({ ...settings3, kFilter: "lowshelf" });
              if (mode === 4)
                setSettings4({ ...settings4, filter: "lowshelf" });
            }}
            title="lowshelf"
          >
            <img
              className={vidloaded ? "" : "playerico disabled"}
              src={ls}
              width="16"
              height="16"
              alt="lowshelf"
            />
          </div>
          <div
            className={`button filterbtn ${
              started && playing ? "" : "disabled"
            }`}
            onClick={(e) => {
              if (!started) return;
              if (mode === 1)
                setSettings1({ ...settings1, filter: "highshelf" });
              if (mode === 2 && track === "Noise")
                setSettings2({ ...settings2, nFilter: "highshelf" });
              if (mode === 2 && track === "Bass")
                setSettings2({ ...settings2, bFilter: "highshelf" });
              if (mode === 3 && track === "Percussion")
                setSettings2({ ...settings2, sFilter: "highshelf" });
              if (mode === 3 && track === "Impact")
                setSettings2({ ...settings2, kFilter: "highshelf" });
              if (mode === 4)
                setSettings4({ ...settings4, filter: "highshelf" });
            }}
            title="highshelf"
          >
            <img
              className={vidloaded ? "" : "playerico disabled"}
              src={hs}
              width="16"
              height="16"
              alt="highshelf"
            />
          </div>
        </div>
      </section>
    </>
  );

  const filterControlsSection = (
    <>
      {Object.keys(props.optionTypes).length > 0 &&
        !(
          Object.keys(props.optionTypes).length === 1 &&
          props.optionTypes["palette"]
        ) &&
        props.selectedFilter.name !== "None" && (
          <section className="box_section">
            <div className="box_section_title">
              <span>{props.selectedFilter.name}</span>
            </div>
            <div style={{ margin: "0px 0" }}>
              <Controls
                filter={props.selectedFilter.name}
                options={props.options}
                optionTypes={props.optionTypes}
                onSetFilterOption={props.onSetFilterOption}
                inputCanvas={inputCanvas}
              />
            </div>
          </section>
        )}
    </>
  );
  const filterOptionsSection = (
    <>
      {imgMode === "Enhance" && (
        <>
          <section className="box_section">
            <div className="box_section_title">
              <span>Filters</span>
            </div>
            <div className="rm_boxes">
              <Select
                disabled={started ? false : true}
                id="subFilters"
                onSetFilter={props.onSelectFilter}
                onSetFilterOption={setEnhance}
                name={enhance ? enhance : "Select"}
                types={{
                  options: props.mainFilters[0].filters,
                }}
              />
            </div>
          </section>
        </>
      )}
      {imgMode === "Glitch" && (
        <>
          <section className="box_section">
            <div className="box_section_title">
              <span>Type</span>
            </div>
            <div className="rm_boxes">
              <Select
                id="subsubFilters"
                onSetFilterOption={setCorrupt}
                name={corrupt.name}
                types={{
                  options: props.mainFilters[1].filters,
                }}
              />
            </div>
          </section>
          {corrupt.filters && (
            <>
              <section className="box_section">
                <div className="box_section_title">
                  <span>Filters</span>
                </div>
                <Select
                  id="selectedFilter"
                  onSetFilterOption={props.onSelectFilter}
                  name={
                    corrupt.filters.find(
                      (f) => f && f.value === props.selectedFilter.name
                    )
                      ? corrupt.filters.find(
                          (f) => f && f.value === props.selectedFilter.name
                        ).value
                      : "Select"
                  }
                  types={{
                    options: corrupt.filters,
                  }}
                />
              </section>
            </>
          )}
        </>
      )}
      {imgMode === "Dither" && (
        <>
          <section className="box_section">
            <div className="box_section_title">
              <span>Filters</span>
            </div>
            <div className="rm_boxes">
              <Select
                id="subFilters"
                onSetFilter={props.onSelectFilter}
                onSetFilterOption={setDither}
                name={dither ? dither : "Select"}
                types={{
                  options: props.mainFilters[2].filters,
                }}
              />
            </div>
          </section>
        </>
      )}
    </>
  );

  const audioThreshSection = (
    <section className="box_section">
      <div className="box_section_title">
        <span>Threshold</span>
      </div>
      <div className="rm_boxes">
        {mode === 1 && (
          <>
            <input
              className={started ? "" : "disabled"}
              id="showsource"
              type="checkbox"
              onChange={(e) => {
                if (!started) return;
                setViewThresh(e.target.checked);
              }}
              checked={viewThresh}
            />
            <label htmlFor="showsource">
              <span></span>
            </label>
            <input
              value={settings1.threshold}
              id="posterslider"
              type="range"
              className={`slider${started ? "" : " disabled"}`}
              min="0"
              max="100"
              step="1"
              onChange={(e) => {
                if (!started) return;
                setSettings1({ ...settings1, threshold: e.target.value });
              }}
            />
          </>
        )}
        {mode === 2 && (
          <>
            <input
              id="showsource"
              type="checkbox"
              onChange={(e) => {
                setViewThresh(e.target.checked);
              }}
              checked={viewThresh}
            />
            <label htmlFor="showsource">
              <span></span>
            </label>
            <input
              value={settings2.threshold}
              id="posterslider"
              type="range"
              className="slider"
              min="0"
              max="100"
              step="1"
              onChange={(e) => {
                setSettings2({ ...settings2, threshold: e.target.value });
              }}
            />
          </>
        )}
        {mode === 3 && (
          <>
            <input
              id="showsource"
              type="checkbox"
              onChange={(e) => {
                setViewThresh(e.target.checked);
              }}
              checked={viewThresh}
            />
            <label htmlFor="showsource">
              <span></span>
            </label>
            <input
              value={settings3.threshold}
              id="posterslider"
              type="range"
              className="slider"
              min="0"
              max="100"
              step="1"
              onChange={(e) => {
                setSettings3({ ...settings3, threshold: e.target.value });
              }}
            />
          </>
        )}
        {mode === 4 && (
          <>
            <input
              id="showsource"
              type="checkbox"
              onChange={(e) => {
                setViewThresh(e.target.checked);
              }}
              checked={viewThresh}
            />
            <label htmlFor="showsource">
              <span></span>
            </label>
            <input
              value={settings4.threshold}
              id="posterslider"
              type="range"
              className="slider"
              min="0"
              max="100"
              step="1"
              onChange={(e) => {
                setSettings4({ ...settings4, threshold: e.target.value });
              }}
            />
          </>
        )}
      </div>
    </section>
  );

  const audioPanSection = (settings) => {
    return (
      <section className="box_section">
        <div className="box_section_title">
          <span>Left/Right</span>
        </div>
        <div className="rm_boxes">
          <input
            className={started ? "" : "disabled"}
            id="monotrack"
            type="checkbox"
            onChange={(e) =>
              settings.panner.bypass
                ? (settings.panner.bypass = false)
                : (settings.panner.bypass = true)
            }
            checked={settings.panner && !settings.panner.bypass}
          />
          <label htmlFor="monotrack">
            <span></span>
          </label>
          <input
            className={`slider${started ? "" : " disabled"}`}
            type="range"
            min="-1"
            max="1"
            step="0.1"
            value={settings.panner && settings.panner.effect.pan.value}
            onChange={(e) => {
              if (!settings.panner) return;
              settings.panner.effect.set({ pan: e.target.value });
            }}
          />
        </div>
      </section>
    );
  };

  const audioVolSection = (
    <section className="box_section">
      <div className="box_section_title">
        <span>Volume</span>
      </div>
      <div className="rm_boxes">
        {mode === 1 && (
          <>
            <input
              className={started ? "" : "disabled"}
              id="mutetrack"
              type="checkbox"
              onChange={(e) => {
                if (!started) return;
                setSettings1({ ...settings1, unmute: e.target.checked });
              }}
              checked={settings1.unmute}
            />
            <label htmlFor="mutetrack">
              <span></span>
            </label>
            <input
              value={settings1.volume}
              className={`slider${started ? "" : " disabled"}`}
              type="range"
              min="-40"
              max="0"
              step="1"
              onChange={(e) => {
                if (!started) return;
                setSettings1({ ...settings1, volume: e.target.value });
              }}
            />
          </>
        )}
        {mode === 2 && track === "Bass" && (
          <>
            <input
              id="mutetrack"
              type="checkbox"
              onChange={(e) => {
                settings2.bUnmute = e.target.checked;
              }}
              checked={settings2.bUnmute}
            />
            <label htmlFor="mutetrack">
              <span></span>
            </label>
            <input
              value={settings2.bVolume}
              className="slider"
              type="range"
              min="-40"
              max="0"
              step="1"
              onChange={(e) => {
                settings2.bVolume = e.target.value;
              }}
            />
          </>
        )}
        {mode === 2 && track === "Noise" && (
          <>
            <input
              id="mutetrack"
              type="checkbox"
              onChange={(e) => {
                settings2.nUnmute = e.target.checked;
              }}
              checked={settings2.nUnmute}
            />
            <label htmlFor="mutetrack">
              <span></span>
            </label>
            <input
              value={settings2.nVolume}
              className="slider"
              type="range"
              min="-40"
              max="0"
              step="1"
              onChange={(e) => {
                settings2.nVolume = e.target.value;
              }}
            />
          </>
        )}
        {mode === 3 && track === "Impact" && (
          <>
            <input
              id="mutetrack"
              type="checkbox"
              onChange={(e) => {
                settings3.kUnmute = e.target.checked;
              }}
              checked={settings3.kUnmute}
            />
            <label htmlFor="mutetrack">
              <span></span>
            </label>
            <input
              value={settings3.kVolume}
              className="slider"
              type="range"
              min="-40"
              max="0"
              step="1"
              onChange={(e) => {
                settings3.kVolume = e.target.value;
              }}
            />
          </>
        )}
        {mode === 3 && track === "Percussion" && (
          <>
            <input
              id="mutetrack"
              type="checkbox"
              onChange={(e) => {
                settings3.sUnmute = e.target.checked;
              }}
              checked={settings3.sUnmute}
            />
            <label htmlFor="mutetrack">
              <span></span>
            </label>
            <input
              value={settings3.sVolume}
              className="slider"
              type="range"
              min="-40"
              max="0"
              step="1"
              onChange={(e) => {
                settings3.sVolume = e.target.value;
              }}
            />
          </>
        )}
        {mode === 4 && (
          <>
            <input
              id="mutetrack"
              type="checkbox"
              onChange={(e) => {
                settings4.unmute = e.target.checked;
              }}
              checked={settings4.unmute}
            />
            <label htmlFor="mutetrack">
              <span></span>
            </label>
            <input
              value={settings4.volume}
              className="slider"
              type="range"
              min="-40"
              max="0"
              step="1"
              onChange={(e) => {
                settings4.volume = e.target.value;
              }}
            />
          </>
        )}
      </div>
    </section>
  );
  const getModeName = () => {
    if (mode === 1) return "Chromatic";
    if (mode === 2) return "Kinematic";
    if (mode === 3) return "Chiaroscuro";
    if (mode === 4) return "Edge Detect";
  };
  const audioSelectSection = (
    <>
      <section className="box_section">
        <div className="box_section_title">
          <span>Algorithm</span>
        </div>
        <Select
          disabled={started ? false : true}
          name={getModeName()}
          id="selectedMode"
          onSetFilterOption={setMode}
          types={{
            options: [
              { value: "Chromatic" },
              { value: "Kinematic" },
              { value: "Chiaroscuro" },
              { value: "Edge Detect" },
            ],
          }}
        />
      </section>
    </>
  );

  const audioPitchSection = (
    <>
      {mode === 1 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Pitch</span>
          </div>
          <div className="rm_boxes">
            <input
              value={settings1.shifter && settings1.shifter.effect.pitch}
              style={{ marginRight: "6px" }}
              type="range"
              className={`slider${started ? "" : " disabled"}`}
              min="0"
              max="24"
              step="1"
              onChange={(e) => {
                if (!settings1.shifter) return;
                e.target.value === 0
                  ? (settings1.shifter.bypass = true)
                  : (settings1.shifter.bypass = false);
                settings1.shifter.effect.set({ pitch: e.target.value });
              }}
            />
            <form
              className={`number-input ${started ? "" : " disabled"}`}
              noValidate="novalidate"
              onSubmit={(e) => {
                e.preventDefault();
                if (!started) return;
                settings1.oct = parseInt(
                  document.querySelector("input#pitch_parts").value
                );
              }}
            >
              <input
                defaultValue={settings1.oct}
                type="number"
                min="0"
                max="5"
                step="1"
                id="pitch_parts"
              />
              <div className="number-btns">
                <div
                  onClick={(e) => {
                    if (!started) return;
                    document.querySelector("input#pitch_parts").stepUp();
                    settings1.oct = parseInt(
                      document.querySelector("input#pitch_parts").value
                    );
                  }}
                >
                  <div />
                </div>
                <div
                  onClick={(e) => {
                    if (!started) return;
                    document.querySelector("input#pitch_parts").stepDown();
                    settings1.oct = parseInt(
                      document.querySelector("input#pitch_parts").value
                    );
                  }}
                >
                  <div />
                </div>
              </div>
            </form>
          </div>
        </section>
      )}
      {mode === 4 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Pitch</span>
          </div>
          <div className="rm_boxes">
            <input
              value={settings4.shifter && settings4.shifter.effect.pitch}
              style={{ marginRight: "6px" }}
              type="range"
              className={`slider${started ? "" : " disabled"}`}
              min="0"
              max="24"
              step="1"
              onChange={(e) => {
                if (!settings4.shifter) return;
                e.target.value === 0
                  ? (settings4.shifter.bypass = true)
                  : (settings4.shifter.bypass = false);
                settings4.shifter.effect.set({ pitch: e.target.value });
              }}
            />
            <form
              className={`number-input ${started ? "" : " disabled"}`}
              noValidate="novalidate"
              onSubmit={(e) => {
                e.preventDefault();
                if (!started) return;
                settings4.oct = parseInt(
                  document.querySelector("input#pitch_parts").value
                );
              }}
            >
              <input
                defaultValue={settings4.oct}
                type="number"
                min="0"
                max="5"
                step="1"
                id="pitch_parts"
              />
              <div className="number-btns">
                <div
                  onClick={(e) => {
                    if (!started) return;
                    document.querySelector("input#pitch_parts").stepUp();
                    settings4.oct = parseInt(
                      document.querySelector("input#pitch_parts").value
                    );
                  }}
                >
                  <div />
                </div>
                <div
                  onClick={(e) => {
                    if (!started) return;
                    document.querySelector("input#pitch_parts").stepDown();
                    settings4.oct = parseInt(
                      document.querySelector("input#pitch_parts").value
                    );
                  }}
                >
                  <div />
                </div>
              </div>
            </form>
          </div>
        </section>
      )}
    </>
  );

  const audioPartSection = (
    <>
      {mode === 1 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Particles</span>
          </div>
          <div className="rm_boxes">
            <input
              id="showlayer"
              className={started ? "" : "disabled"}
              type="checkbox"
              onChange={(e) => {
                if (!started) return;
                setViewSamples(e.target.checked);
              }}
              checked={viewSamples}
            />
            <label htmlFor="showlayer">
              <span></span>
            </label>
            <form
              className={`number-input ${started ? "" : " disabled"}`}
              noValidate="novalidate"
              onSubmit={(e) => {
                e.preventDefault();
                if (!started) return;
                setSettings1({
                  ...settings1,
                  samples: parseInt(
                    document.querySelector("input#number_parts").value
                  ),
                });
              }}
            >
              <input
                defaultValue={settings1.samples}
                type="number"
                min="0"
                max="16"
                step="1"
                id="number_parts"
              />
              <div className="number-btns">
                <div
                  onClick={(e) => {
                    if (!started) return;
                    document.querySelector("input#number_parts").stepUp();
                    setSettings1({
                      ...settings1,
                      samples: parseInt(
                        document.querySelector("input#number_parts").value
                      ),
                    });
                  }}
                >
                  <div />
                </div>
                <div
                  onClick={(e) => {
                    if (!started) return;
                    document.querySelector("input#number_parts").stepDown();
                    setSettings1({
                      ...settings1,
                      samples: parseInt(
                        document.querySelector("input#number_parts").value
                      ),
                    });
                  }}
                >
                  <div />
                </div>
              </div>
            </form>
            <button
              className={`button playerbtn ${
                !started || settings1.samples === 0 ? " disabled" : ""
              }`}
              id="randombtn"
            >
              <ShuffleIcon
                fill={started ? "#000000" : "var(--colorgrad5)"}
                className={started ? "playerico" : "playerico disabled"}
              />
            </button>
          </div>
        </section>
      )}
      {mode === 2 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Particles</span>
          </div>
          <div className="rm_boxes">
            <input
              id="showlayer"
              className={started ? "" : "disabled"}
              type="checkbox"
              onChange={(e) => {
                if (!started) return;
                setViewSamples(e.target.checked);
              }}
              checked={viewSamples}
            />
            <label htmlFor="showlayer">
              <span></span>
            </label>
            <button
              onClick={() => {
                if (mode === 2) {
                  setSettings2({
                    ...settings2,
                    threshold: Math.round(Math.random() * 70),
                  });
                }
              }}
              className={`button playerbtn ${!started ? " disabled" : ""}`}
              id="randombtn"
            >
              <ShuffleIcon
                fill={started ? "#000000" : "var(--colorgrad5)"}
                className={started ? "playerico" : "playerico disabled"}
              />
            </button>
            <input
              id="showlayer2"
              type="checkbox"
              onChange={(e) => {
                setSettings2({ ...settings2, viewUV: e.target.checked });
              }}
              checked={settings2.viewUV}
            />
            <label htmlFor="showlayer2">
              <span></span>
            </label>
          </div>
        </section>
      )}
      {mode === 3 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Particles</span>
          </div>
          <div className="rm_boxes">
            <input
              id="showlayer"
              type="checkbox"
              onChange={(e) => {
                setViewSamples(e.target.checked);
              }}
              checked={viewSamples}
            />
            <label htmlFor="showlayer">
              <span></span>
            </label>
            <form
              className={`number-input ${started ? "" : " disabled"}`}
              noValidate="novalidate"
              onSubmit={(e) => {
                e.preventDefault();
                if (!started) return;
                setSettings3({
                  ...settings3,
                  samples: parseInt(
                    document.querySelector("input#number_parts").value
                  ),
                });
              }}
            >
              <input
                defaultValue={settings3.samples}
                type="number"
                min="0"
                max="16"
                step="1"
                id="number_parts"
              />
              <div className="number-btns">
                <div
                  onClick={(e) => {
                    if (!started) return;
                    document.querySelector("input#number_parts").stepUp();
                    setSettings3({
                      ...settings3,
                      samples: parseInt(
                        document.querySelector("input#number_parts").value
                      ),
                    });
                  }}
                >
                  <div />
                </div>
                <div
                  onClick={(e) => {
                    if (!started) return;
                    document.querySelector("input#number_parts").stepDown();
                    setSettings3({
                      ...settings3,
                      samples: parseInt(
                        document.querySelector("input#number_parts").value
                      ),
                    });
                  }}
                >
                  <div />
                </div>
              </div>
            </form>
            <button
              className={`button playerbtn ${
                !started || settings3.samples === 0 ? " disabled" : ""
              }`}
              id="randombtn"
            >
              <ShuffleIcon
                fill={started ? "#000000" : "var(--colorgrad5)"}
                className={started ? "playerico" : "playerico disabled"}
              />
            </button>
          </div>
        </section>
      )}
      {mode === 4 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Particles</span>
          </div>
          <div className="rm_boxes">
            <input
              id="showlayer"
              type="checkbox"
              onChange={(e) => {
                setViewSamples(e.target.checked);
              }}
              checked={viewSamples}
            />
            <label htmlFor="showlayer">
              <span>
                <div />
              </span>
            </label>
            <form
              className={`number-input ${started ? "" : " disabled"}`}
              noValidate="novalidate"
              onSubmit={(e) => {
                e.preventDefault();
                if (!started) return;
                setSettings4({
                  ...settings4,
                  samples: parseInt(
                    document.querySelector("input#number_parts").value
                  ),
                });
              }}
            >
              <input
                defaultValue={settings4.samples}
                type="number"
                min="0"
                max="16"
                step="1"
                id="number_parts"
              />
              <div className="number-btns">
                <div
                  onClick={(e) => {
                    if (!started) return;
                    document.querySelector("input#number_parts").stepUp();
                    setSettings4({
                      ...settings4,
                      samples: parseInt(
                        document.querySelector("input#number_parts").value
                      ),
                    });
                  }}
                >
                  <div />
                </div>
                <div
                  onClick={(e) => {
                    if (!started) return;
                    document.querySelector("input#number_parts").stepDown();
                    setSettings4({
                      ...settings4,
                      samples: parseInt(
                        document.querySelector("input#number_parts").value
                      ),
                    });
                  }}
                >
                  <div />
                </div>
              </div>
            </form>
            <button
              className={`button playerbtn ${
                !started || settings4.samples === 0 ? " disabled" : ""
              }`}
              id="randombtn"
            >
              <ShuffleIcon
                fill={started ? "#000000" : "var(--colorgrad5)"}
                className={started ? "playerico" : "playerico disabled"}
              />
            </button>
            <div>
              <input
                id="sets4_dyn"
                type="checkbox"
                onChange={(e) => {
                  setSettings4({ ...settings4, dynamic: e.target.checked });
                }}
                checked={settings4.dynamic}
              />
              <label htmlFor="sets4_dyn">
                <span></span>
              </label>
            </div>
          </div>
        </section>
      )}
    </>
  );

  const audioFxSelect = (
    <>
      {mode === 1 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Effects</span>
          </div>
          <div className="rm_boxes">
            <Select
              disabled={started ? false : true}
              name={audioFx}
              id="audioFx"
              onSetFilterOption={setAudioFx}
              types={{
                options: [
                  { value: "Reverb" },
                  { value: "Delay" },
                  { value: "Phaser" },
                  { value: "Chorus" },
                  { value: "Ping Pong" },
                  { value: "Distortion" },
                  { value: "Auto Filter" },
                  { value: "Tremolo" },
                  { value: "Chebyshev" },
                ],
              }}
            />
          </div>
        </section>
      )}
      {mode === 3 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Effects</span>
          </div>
          <div className="rm_boxes">
            <Select
              disabled={started ? false : true}
              name={audioFx}
              id="audioFx"
              onSetFilterOption={setAudioFx}
              types={{
                options: [
                  { value: "Reverb" },
                  { value: "Delay" },
                  { value: "Phaser" },
                  { value: "Chorus" },
                  { value: "Ping Pong" },
                  { value: "Distortion" },
                  { value: "Auto Filter" },
                  { value: "Tremolo" },
                  { value: "Chebyshev" },
                ],
              }}
            />
          </div>
        </section>
      )}
      {mode === 4 && (
        <section className="box_section">
          <div className="box_section_title">
            <span>Effects</span>
          </div>
          <div className="rm_boxes">
            <Select
              disabled={started ? false : true}
              name={audioFx}
              id="audioFx"
              onSetFilterOption={setAudioFx}
              types={{
                options: [
                  { value: "Reverb" },
                  { value: "Delay" },
                  { value: "Phaser" },
                  { value: "Chorus" },
                  { value: "Ping Pong" },
                  { value: "Distortion" },
                  { value: "Auto Filter" },
                  { value: "Tremolo" },
                  { value: "Chebyshev" },
                ],
              }}
            />
          </div>
        </section>
      )}
    </>
  );

  const audioFxSection = (settings) => {
    return (
      <section className="box_section">
        {audioFx === "Reverb" && (
          <>
            <div className="box_section_title">
              <span>Reverb</span>
            </div>
            <div className="parts_menu">
              <input
                id="rmx_rvrb"
                type="checkbox"
                checked={settings.reverb && !settings.reverb.bypass}
                onChange={(e) =>
                  settings.reverb.bypass
                    ? (settings.reverb.bypass = false)
                    : (settings.reverb.bypass = true)
                }
              />
              <label htmlFor="rmx_rvrb">
                <span></span>
              </label>
              <span className="slider_label">dry/wet</span>
              <input
                type="range"
                className="slider"
                min="0"
                max="1"
                step="0.1"
                value={settings.reverb && settings.reverb.effect.wet.value}
                onChange={(e) => {
                  if (!settings.reverb) return;
                  settings.reverb.effect.set({ wet: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">decay</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0.001"
                max="4"
                step="0.01"
                value={settings.reverb && settings.reverb.effect.decay}
                onChange={(e) => {
                  if (!settings.reverb) return;
                  settings.reverb.effect.set({ decay: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">pre-delay</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="2"
                step="0.01"
                value={settings.reverb && settings.reverb.effect.preDelay}
                onChange={(e) => {
                  if (!settings.reverb) return;
                  settings.reverb.effect.set({ preDelay: e.target.value });
                }}
              />
            </div>
          </>
        )}
        {audioFx === "Chorus" && (
          <>
            <div className="box_section_title">
              <span>Chorus</span>
            </div>
            <div className="parts_menu">
              <input
                id="rmx_chr"
                type="checkbox"
                checked={settings.chorus && !settings.chorus.bypass}
                onChange={(e) =>
                  settings.chorus.bypass
                    ? (settings.chorus.bypass = false)
                    : (settings.chorus.bypass = true)
                }
              />
              <label htmlFor="rmx_chr">
                <span></span>
              </label>
              <span className="slider_label">dry/wet</span>
              <input
                type="range"
                className="slider"
                min="0"
                max="1"
                step="0.1"
                value={settings.chorus && settings.chorus.effect.wet.value}
                onChange={(e) => {
                  if (!settings.chorus) return;
                  settings.chorus.effect.set({ wet: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">frequency</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="100"
                step="1"
                value={
                  settings.chorus && settings.chorus.effect.frequency.value
                }
                onChange={(e) => {
                  if (!settings.chorus) return;
                  settings.chorus.effect.set({ frequency: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">delay time</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="10"
                step="0.1"
                value={settings.chorus && settings.chorus.effect.delayTime}
                onChange={(e) => {
                  if (!settings.chorus) return;
                  settings.chorus.effect.set({ delayTime: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">depth</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="1"
                step="0.1"
                value={settings.chorus && settings.chorus.effect.depth}
                onChange={(e) => {
                  if (!settings.chorus) return;
                  settings.chorus.effect.set({ depth: e.target.value });
                }}
              />
            </div>
          </>
        )}
        {audioFx === "Delay" && (
          <>
            <div className="box_section_title">
              <span>Delay</span>
            </div>
            <div className="parts_menu">
              <input
                id="rmx_dly"
                type="checkbox"
                checked={settings.delay && !settings.delay.bypass}
                onChange={(e) =>
                  settings.delay.bypass
                    ? (settings.delay.bypass = false)
                    : (settings.delay.bypass = true)
                }
              />
              <label htmlFor="rmx_dly">
                <span></span>
              </label>
              <span className="slider_label">dry/wet</span>
              <input
                type="range"
                className="slider"
                min="0"
                max="1"
                step="0.1"
                value={settings.delay && settings.delay.effect.wet.value}
                onChange={(e) => {
                  if (!settings.delay) return;
                  settings.delay.effect.set({ wet: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">delay time</span>
              <input
                value={settings.delay && settings.delay.effect.delayTime.value}
                type="range"
                className="slider longerSlider"
                min="0"
                max="1"
                step="0.01"
                onChange={(e) => {
                  if (!settings.delay) return;
                  settings.delay.effect.set({ delayTime: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">feedback</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="1"
                step="0.01"
                value={settings.delay && settings.delay.effect.feedback.value}
                onChange={(e) => {
                  if (!settings.delay) return;
                  settings.delay.effect.set({ feedback: e.target.value });
                }}
              />
            </div>
          </>
        )}
        {audioFx === "Distortion" && (
          <>
            <div className="box_section_title">
              <span>Distortion</span>
            </div>
            <div className="parts_menu">
              <input
                id="rmx_dst"
                type="checkbox"
                checked={settings.distortion && !settings.distortion.bypass}
                onChange={(e) =>
                  settings.distortion.bypass
                    ? (settings.distortion.bypass = false)
                    : (settings.distortion.bypass = true)
                }
              />
              <label htmlFor="rmx_dst">
                <span></span>
              </label>
              <span className="slider_label">dry/wet</span>
              <input
                value={
                  settings.distortion && settings.distortion.effect.wet.value
                }
                type="range"
                className="slider"
                min="0"
                max="1"
                step="0.1"
                onChange={(e) => {
                  if (!settings.distortion) return;
                  settings.distortion.effect.set({ wet: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">distortion</span>
              <input
                value={
                  settings.distortion && settings.distortion.effect.distortion
                }
                type="range"
                className="slider longerSlider"
                min="0"
                max="1"
                step="0.1"
                onChange={(e) => {
                  if (!settings.distortion) return;
                  settings.distortion.effect.set({
                    distortion: e.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
        {audioFx === "Chebyshev" && (
          <>
            <div className="box_section_title">
              <span>Chebyshev</span>
            </div>
            <div className="parts_menu">
              <input
                id="rmx_dst"
                type="checkbox"
                checked={settings.chebyshev && !settings.chebyshev.bypass}
                onChange={(e) =>
                  settings.chebyshev.bypass
                    ? (settings.chebyshev.bypass = false)
                    : (settings.chebyshev.bypass = true)
                }
              />
              <label htmlFor="rmx_dst">
                <span></span>
              </label>
              <span className="slider_label">dry/wet</span>
              <input
                type="range"
                className="slider"
                min="0"
                max="1"
                step="0.1"
                value={
                  settings.chebyshev && settings.chebyshev.effect.wet.value
                }
                onChange={(e) => {
                  if (!settings.chebyshev) return;
                  settings.chebyshev.effect.set({ wet: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">order</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="100"
                step="1"
                value={settings.chebyshev && settings.chebyshev.effect.order}
                onChange={(e) => {
                  if (!settings.chebyshev) return;
                  settings.chebyshev.effect.set({ order: e.target.value });
                }}
              />
            </div>
          </>
        )}
        {audioFx === "Phaser" && (
          <>
            <div className="box_section_title">
              <span>Phaser</span>
            </div>
            <div className="parts_menu">
              <input
                id="rmx_phsr"
                type="checkbox"
                checked={settings.phaser && !settings.phaser.bypass}
                onChange={(e) =>
                  settings.phaser.bypass
                    ? (settings.phaser.bypass = false)
                    : (settings.phaser.bypass = true)
                }
              />
              <label htmlFor="rmx_phsr">
                <span></span>
              </label>
              <span className="slider_label">dry/wet</span>
              <input
                type="range"
                className="slider"
                min="0"
                max="1"
                step="0.1"
                value={settings.phaser && settings.phaser.effect.wet.value}
                onChange={(e) => {
                  if (!settings.phaser) return;
                  settings.phaser.effect.set({ wet: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">frequency</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="22050"
                step="1"
                value={
                  settings.phaser && settings.phaser.effect.frequency.value
                }
                onChange={(e) => {
                  if (!settings.phaser) return;
                  settings.phaser.effect.set({ frequency: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">base freq</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="22050"
                step="1"
                value={settings.phaser && settings.phaser.effect.baseFrequency}
                onChange={(e) => {
                  if (!settings.phaser) return;
                  settings.phaser.effect.set({
                    baseFrequency: e.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
        {audioFx === "Auto Filter" && (
          <>
            <div className="box_section_title">
              <span>Auto Filter</span>
            </div>
            <div className="parts_menu">
              <input
                id="rmx_trem"
                type="checkbox"
                checked={settings.autofilter && !settings.autofilter.bypass}
                onChange={(e) =>
                  settings.autofilter.bypass
                    ? (settings.autofilter.bypass = false)
                    : (settings.autofilter.bypass = true)
                }
              />
              <label htmlFor="rmx_trem">
                <span></span>
              </label>
              <span className="slider_label">dry/wet</span>
              <input
                type="range"
                className="slider"
                min="0"
                max="1"
                step="0.1"
                value={
                  settings.autofilter && settings.autofilter.effect.wet.value
                }
                onChange={(e) => {
                  if (!settings.autofilter) return;
                  settings.autofilter.effect.set({ wet: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">frequency</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="22050"
                step="1"
                value={
                  settings.autofilter &&
                  settings.autofilter.effect.frequency.value
                }
                onChange={(e) => {
                  if (!settings.autofilter) return;
                  settings.autofilter.effect.set({
                    frequency: e.target.value,
                  });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">base freq</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="1000"
                step="0.1"
                value={
                  settings.autofilter &&
                  settings.autofilter.effect.baseFrequency
                }
                onChange={(e) => {
                  if (!settings.autofilter) return;
                  settings.autofilter.effect.set({
                    baseFrequency: e.target.value,
                  });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">octaves</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="32"
                step="1"
                value={
                  settings.autofilter && settings.autofilter.effect.octaves
                }
                onChange={(e) => {
                  if (!settings.autofilter) return;
                  settings.autofilter.effect.set({
                    octaves: e.target.value,
                  });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">depth</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="1"
                step="0.1"
                value={
                  settings.autofilter && settings.autofilter.effect.depth.value
                }
                onChange={(e) => {
                  if (!settings.autofilter) return;
                  settings.autofilter.effect.set({ depth: e.target.value });
                }}
              />
            </div>
          </>
        )}
        {audioFx === "Tremolo" && (
          <>
            <div className="box_section_title">
              <span>Tremolo</span>
            </div>
            <div className="parts_menu">
              <input
                id="rmx_trem"
                type="checkbox"
                checked={settings.tremolo && !settings.tremolo.bypass}
                onChange={(e) =>
                  settings.tremolo.bypass
                    ? (settings.tremolo.bypass = false)
                    : (settings.tremolo.bypass = true)
                }
              />
              <label htmlFor="rmx_trem">
                <span></span>
              </label>
              <span className="slider_label">dry/wet</span>
              <input
                type="range"
                className="slider"
                min="0"
                max="1"
                step="0.1"
                value={settings.tremolo && settings.tremolo.effect.wet.value}
                onChange={(e) => {
                  if (!settings.tremolo) return;
                  settings.tremolo.effect.set({ wet: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">frequency</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="1000"
                step="1"
                value={
                  settings.tremolo && settings.tremolo.effect.frequency.value
                }
                onChange={(e) => {
                  if (!settings.tremolo) return;
                  settings.tremolo.effect.set({ frequency: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">depth</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="1"
                step="0.1"
                value={settings.tremolo && settings.tremolo.effect.depth.value}
                onChange={(e) => {
                  if (!settings.tremolo) return;
                  settings.tremolo.effect.set({ depth: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">spread</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="180"
                step="1"
                value={settings.tremolo && settings.tremolo.effect.spread}
                onChange={(e) => {
                  if (!settings.tremolo) return;
                  settings.tremolo.effect.set({ spread: e.target.value });
                }}
              />
            </div>
          </>
        )}
        {audioFx === "Ping Pong" && (
          <>
            <div className="box_section_title">
              <span>Ping Pong</span>
            </div>
            <div className="parts_menu">
              <input
                id="rmx_pnpg"
                type="checkbox"
                checked={settings.pingpong && !settings.pingpong.bypass}
                onChange={(e) =>
                  settings.pingpong.bypass
                    ? (settings.pingpong.bypass = false)
                    : (settings.pingpong.bypass = true)
                }
              />
              <label htmlFor="rmx_pnpg">
                <span></span>
              </label>
              <span className="slider_label">dry/wet</span>
              <input
                type="range"
                className="slider"
                min="0"
                max="1"
                step="0.1"
                value={settings.pingpong && settings.pingpong.effect.wet.value}
                onChange={(e) => {
                  if (!settings.pingpong) return;
                  settings.pingpong.effect.set({ wet: e.target.value });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">delay time</span>
              <input
                type="range"
                className="slider longerSlider"
                min="0"
                max="1"
                step="0.01"
                value={settings.pingpong.effect.delayTime.value}
                onChange={(e) => {
                  if (!settings.pingpong) return;
                  settings.pingpong.effect.set({
                    delayTime: e.target.value,
                  });
                }}
              />
            </div>
            <div className="parts_menu">
              <span className="slider_label">feedback</span>
              <input
                value={
                  settings.pingpong && settings.pingpong.effect.feedback.value
                }
                type="range"
                className="slider longerSlider"
                min="0"
                max="1"
                step="0.1"
                onChange={(e) => {
                  if (!settings.pingpong) return;
                  settings.pingpong.effect.set({ feedback: e.target.value });
                }}
              />
            </div>
          </>
        )}
      </section>
    );
  };
  const sketchProps = {
    metadata: godMounted,
    mode: mode,
    playing: playing,
    songKey: songKey,
    started: started,
    viewThresh: viewThresh,
    viewSamples: viewSamples,
    settings1: settings1,
    settings2: settings2,
    settings3: settings3,
    settings4: settings4,
    viewEffects: props.viewEffects,
    opts: props.options,
    track: track,
    playbackRate: props.playbackRate,
  };

  return (
    <>
      <section className="window_toolbar">
        <OptionsMenu items={optionsMenuData} onClickItem={onClickOptionItem} />
      </section>
      <div className="divider"></div>
      <section className="address_bar">
        <div className="div_bar"></div>
        <div className="playerbtns">
          <button
            className={`button playerbtn ${
              (vidloaded && !started) || (started && !playing) ? "" : "disabled"
            }`}
            id="rmx_playbtn"
            onClick={() => {
              if (godMounted && vidloaded) {
                if (!started) {
                  handleStart();
                } else if (started && !playing) {
                  Tone.Transport.start();
                  if (!mute && Tone.Destination.mute)
                    Tone.Destination.mute = false;
                  setPlaying(true);
                }
              } else {
                return;
              }
            }}
          >
            <PlayIcon
              fill={
                (vidloaded && !started) || (started && !playing)
                  ? "#000000"
                  : "var(--colorgrad5)"
              }
              className={
                (vidloaded && !started) || (started && !playing)
                  ? "playerico"
                  : "playerico disabled"
              }
            />
          </button>
          <button
            className={`button playerbtn ${
              started && playing ? "" : "disabled"
            }`}
            id="rmx_pausebtn"
            onClick={() => {
              if (started && playing) {
                Tone.Transport.pause();
                if (!mute && !Tone.Destination.mute)
                  Tone.Destination.mute = true;
                setPlaying(false);
              }
            }}
          >
            <PauseIcon
              fill={started && playing ? "#000000" : "var(--colorgrad5)"}
              className={
                started && playing ? "playerico" : "playerico disabled"
              }
            />
          </button>

          <button
            className={`button playerbtn ${
              started && playing ? "" : "disabled"
            }`}
            onClick={() => {
              if (started && playing) {
                Tone.Transport.stop();
                if (!mute && !Tone.Destination.mute)
                  Tone.Destination.mute = true;
                setPlaying(false);
                video.pause();
                video.currentTime = 0;
                video.loop = false;
              }
            }}
          >
            <StopIcon
              fill={started && playing ? "#000000" : "var(--colorgrad5)"}
              className={
                started && playing ? "playerico" : "playerico disabled"
              }
            />
          </button>
          <button
            className={`button playerbtn ${started ? "" : "disabled"}`}
            id="mutebtn"
            onClick={() => {
              if (started) setMute(!mute);
            }}
          >
            {mute && (
              <UnmuteIcon
                fill={started ? "#000000" : "var(--colorgrad5)"}
                className={started ? "playerico" : "playerico disabled"}
              />
            )}
            {!mute && (
              <MuteIcon
                fill={started ? "#000000" : "var(--colorgrad5)"}
                className={started ? "playerico" : "playerico disabled"}
              />
            )}
          </button>
        </div>
        <div className="address_bar_content">
          <div className="address_bar_buttons">
            <div className="address_bar_rew">
              <FRIcon
                fill={"var(--colorgrad5)"}
                className={"playerico disabled"}
              />
            </div>
            <div className="address_bar_ff">
              <FFIcon
                fill={"var(--colorgrad5)"}
                className={"playerico disabled"}
              />
            </div>
          </div>
          <div className="address_bar_track">
            <div className="address_bar_time"></div>
            <div className="address_bar_play"></div>
          </div>
        </div>
      </section>
      <div className="divider"></div>
      <div id="remixer_window">
        <section className="remixer_toolbar">
          <div className="tab_switch">
            <div
              className={`tab_select ${view === "acoustics" ? "selected" : ""}`}
              onClick={(e) => toggleView(e)}
            >
              <p>
                <span>acoustics</span>
              </p>
            </div>
            <div
              className={`tab_select ${view === "optics" ? "selected" : ""}`}
              onClick={(e) => toggleView(e)}
            >
              <p>
                <span>optics</span>
              </p>
            </div>
          </div>
          <div className="fps_switch">
            <span>god.observer{godMounted && ` | ${godMounted.tokenId}`}</span>
          </div>
          <div className="slider_switch">
            <div className="slider_container" title="tempo">
              <div className="fps_switch">
                <span>{Math.round(90 * props.playbackRate)}</span>
              </div>
              <input
                value={props.playbackRate}
                type="range"
                className="slider thick_slider"
                min="0"
                max="2"
                step="0.1"
                onChange={(e) => {
                  Tone.Transport.bpm.value = Math.round(90 * e.target.value);
                  props.onSetInputPlaybackRate(e.target.value);
                }}
              />
            </div>
            <div className="slider_container" title="volume">
              <div className="fps_switch">
                <span>{parseInt(map(volume, -50, 10, 0, 100))}</span>
              </div>
              <input
                value={volume}
                type="range"
                className="slider thick_slider"
                min="-50"
                max="10"
                step="1"
                onChange={(e) => {
                  setVolume(e.target.value);
                  Tone.getDestination().volume.rampTo(e.target.value, 0.5);
                }}
              />
            </div>
            <div className="slider_container" title="key">
              <div className="fps_switch">
                <span>
                  {Tone.Frequency(songKey, "midi").toNote().slice(0, -1)}
                </span>
              </div>
              <input
                value={songKey}
                type="range"
                className="slider thick_slider"
                min="18"
                max="30"
                step="1"
                onChange={(e) => {
                  setSongKey(parseInt(e.target.value));
                }}
              />
            </div>
          </div>
        </section>

        <div id="remixer_panel">
          <div className="remixer_sidebar">
            {view === "acoustics" && audioSelectSection}
            {view === "acoustics" && audioPartSection}
            {view === "acoustics" && audioThreshSection}
            {view === "acoustics" && audioSubSelSection}
            {view === "acoustics" && audioVolSection}
            {view === "acoustics" && audioPitchSection}
            {view === "acoustics" && mode === 1 && audioPanSection(settings1)}
            {view === "acoustics" &&
              mode === 3 &&
              track === "Impact" &&
              audioPanSection(settings3.kFilters)}
            {view === "acoustics" &&
              mode === 3 &&
              track === "Percussion" &&
              audioPanSection(settings3.sFilters)}
            {view === "acoustics" && mode === 4 && audioPanSection(settings4)}
            {view === "acoustics" && track !== "Noise" && audioEnvSection}
            {view === "acoustics" && audioFilSection}
            {view === "acoustics" && audioFxSelect}
            {view === "acoustics" &&
              mode === 1 &&
              audioFx !== "Select" &&
              audioFxSection(settings1)}
            {view === "acoustics" &&
              mode === 3 &&
              track === "Impact" &&
              audioFx !== "Select" &&
              audioFxSection(settings3.kFilters)}
            {view === "acoustics" &&
              mode === 3 &&
              track === "Percussion" &&
              audioFx !== "Select" &&
              audioFxSection(settings3.sFilters)}
            {view === "acoustics" &&
              mode === 4 &&
              audioFx !== "Select" &&
              audioFxSection(settings4)}
            {view === "optics" && filterSelectSection}
            {view === "optics" && filterOptionsSection}
            {view === "optics" && filterOpacSection}
            {view === "optics" && filterBlendSection}
            {view === "optics" && filterColorSection}
            {view === "optics" && filterControlsSection}
          </div>
          <div className="remixer_screen">
            <canvas id="remixerOutput" hidden />
            <canvas id="remixerInput" hidden />
            <div id="rmxr_div" className="greyInset">
              {!vidloaded && godMounted && (
                <img
                  draggable={false}
                  style={{ objectFit: "none", width: "100%", height: "100%" }}
                  src={loading}
                  alt="loading"
                />
              )}
              {!vidloaded && !godMounted && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    color: "var(--colorgrad5)",
                  }}
                >
                  <span style={{
                    textAlign: "center"
                  }}>
                    click <i>file {'>'} open...</i> to select a god<br/>and click <i>open in godremixer</i>.
                  </span>
                </div>
              )}
              {vidloaded && godMounted && !started && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    color: "var(--colorgrad5)",
                  }}
                >
                  <span>
                    press <i>play</i>{" "}
                    <PlayIcon fill="var(--colorgrad5)" className="play_icon" />{" "}
                    to start.
                  </span>
                </div>
              )}
              <Painter {...sketchProps} />
            </div>
            <div id="vis_screen">
              <div id="wvfrm_div" className="greyInset" />
              <div id="stats_div" className="greyInset">
                <span id="stats_text" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
