import React, { useGlobal, useEffect } from "reactn";
import styled from "styled-components";
import bwcloud from "assets/icons/cloud_bw.ico";

const ShutDown = () => {
  const [turnedOff] = useGlobal("turnedOff");

  useEffect(() => {
    if(turnedOff) {
      console.log('turned off')
      setTimeout(() => {
        const safe = document.getElementById('safe');
        const shut = document.getElementById('shut');
        shut.style.display = "none";
        safe.style.display = "flex";
      }, 3000);
    }
  }, [turnedOff]);

  return (
    turnedOff && (
      <Div>
        <div id="safe">It is now safe to turn off your computer.</div>
        <div id="shut">
          <div className="startup">
            <div id="clouds">
              <div className="cloud foreground"></div>
              <div className="cloud background"></div>
              <div className="cloud foreground"></div>
              <div className="cloud background"></div>
              <div className="cloud foreground"></div>
              <div className="cloud background"></div>
              <div className="cloud background"></div>
              <div className="cloud foreground"></div>
              <div className="cloud background"></div>
              <div className="cloud background"></div>
            </div>
          </div>
        </div>
      </Div>
    )
  );
};

const Div = styled.div`
  touch-action: none;
  cursor: none;
  position: fixed;
  color: white;
  z-index: 1000000;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  font-family: 'graph35', monospace;
  letter-spacing: 3px;
  font-size: 2.5vh;
  * {
    cursor: none;
  }
  #shut {
    display: flex;
    z-index: 1;
  }
  #safe {
    position: fixed;
    padding: 30px;
    text-align: center;
    display:none;
    z-index: 2;
  }
  @keyframes BackGradient {
    0% {
      background-position: 100%;
    }

    100% {
      background-position: 0%;
    }
  }
  #clouds {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
    -webkit-animation: FadeIn 3s ease-out;
    animation: FadeIn 3s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100vh;

    background: linear-gradient(
      -90deg,
      #4d4d4d,
      #000000,
      #4d4d4d,
      #808080,
      #4d4d4d,
      #000000
    );
    background-size: 400% 400%;
    animation: BackGradient 5s ease infinite;
    z-index: 1300;
  }
  .cloud {
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: BackFloat, FadeFloat;
    animation-name: BackFloat, FadeFloat;
    z-index: 1;
  }
  .cloud.foreground {
    height: 10%;
    z-index: 3;
  }
  .cloud.background {
    height: 9.09090909%;
    -webkit-animation-duration: 17.5s;
    animation-duration: 17.5s;
  }
  @-webkit-keyframes BackFloat {
    from {
      transform: translateX(-55%) translateY(-50%) translateZ(0);
    }
    50% {
      transform: translateX(-5%) translateY(50%) translateZ(0);
    }
    to {
      transform: translateX(105%) translateY(-10%) translateZ(0);
    }
  }
  @keyframes BackFloat {
    from {
      transform: translateX(-55%) translateY(-50%) translateZ(0);
    }
    50% {
      transform: translateX(-5%) translateY(50%) translateZ(0);
    }
    to {
      transform: translateX(105%) translateY(-10%) translateZ(0);
    }
  }
  .cloud:nth-child(10) {
    -webkit-animation-delay: -15.38461538s;
    animation-delay: -15.38461538s;
    top: 60%;
  }
  .cloud.foreground:nth-child(10) {
    -webkit-animation-duration: -30s;
    animation-duration: -30s;
    height: 35%;
  }
  .cloud.background:nth-child(10) {
    -webkit-animation-duration: -27.5s;
    animation-duration: -27.5s;
    height: -3.40909091%;
  }
  .cloud:nth-child(9) {
    -webkit-animation-delay: -13.84615385s;
    animation-delay: -13.84615385s;
    top: 54%;
  }
  .cloud.foreground:nth-child(9) {
    -webkit-animation-duration: -26s;
    animation-duration: -26s;
    height: 32.5%;
  }
  .cloud.background:nth-child(9) {
    -webkit-animation-duration: -23.5s;
    animation-duration: -23.5s;
    height: -2.15909091%;
  }
  .cloud:nth-child(8) {
    -webkit-animation-delay: -12.30769231s;
    animation-delay: -12.30769231s;
    top: 48%;
  }
  .cloud.foreground:nth-child(8) {
    -webkit-animation-duration: -22s;
    animation-duration: -22s;
    height: 30%;
  }
  .cloud.background:nth-child(8) {
    -webkit-animation-duration: -19.5s;
    animation-duration: -19.5s;
    height: -0.90909091%;
  }
  .cloud:nth-child(7) {
    -webkit-animation-delay: -10.76923077s;
    animation-delay: -10.76923077s;
    top: 42%;
  }
  .cloud.foreground:nth-child(7) {
    -webkit-animation-duration: -18s;
    animation-duration: -18s;
    height: 27.5%;
  }
  .cloud.background:nth-child(7) {
    -webkit-animation-duration: -15.5s;
    animation-duration: -15.5s;
    height: 0.34090909%;
  }
  .cloud:nth-child(6) {
    -webkit-animation-delay: -9.23076923s;
    animation-delay: -9.23076923s;
    top: 36%;
  }
  .cloud.foreground:nth-child(6) {
    -webkit-animation-duration: -14s;
    animation-duration: -14s;
    height: 25%;
  }
  .cloud.background:nth-child(6) {
    -webkit-animation-duration: -11.5s;
    animation-duration: -11.5s;
    height: 1.59090909%;
  }
  .cloud:nth-child(5) {
    -webkit-animation-delay: -7.69230769s;
    animation-delay: -7.69230769s;
    top: 30%;
  }
  .cloud.foreground:nth-child(5) {
    -webkit-animation-duration: -10s;
    animation-duration: -10s;
    height: 22.5%;
  }
  .cloud.background:nth-child(5) {
    -webkit-animation-duration: -7.5s;
    animation-duration: -7.5s;
    height: 2.84090909%;
  }
  .cloud:nth-child(4) {
    -webkit-animation-delay: -6.15384615s;
    animation-delay: -6.15384615s;
    top: 24%;
  }
  .cloud.foreground:nth-child(4) {
    -webkit-animation-duration: -6s;
    animation-duration: -6s;
    height: 20%;
  }
  .cloud.background:nth-child(4) {
    -webkit-animation-duration: -3.5s;
    animation-duration: -3.5s;
    height: 4.09090909%;
  }
  .cloud:nth-child(3) {
    -webkit-animation-delay: -4.61538462s;
    animation-delay: -4.61538462s;
    top: 18%;
  }
  .cloud.foreground:nth-child(3) {
    -webkit-animation-duration: -2s;
    animation-duration: -2s;
    height: 17.5%;
  }
  .cloud.background:nth-child(3) {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    height: 5.34090909%;
  }
  .cloud:nth-child(2) {
    -webkit-animation-delay: -3.07692308s;
    animation-delay: -3.07692308s;
    top: 12%;
  }
  .cloud.foreground:nth-child(2) {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    height: 15%;
  }
  .cloud.background:nth-child(2) {
    -webkit-animation-duration: 4.5s;
    animation-duration: 4.5s;
    height: 6.59090909%;
  }
  .cloud:nth-child(1) {
    -webkit-animation-delay: -1.53846154s;
    animation-delay: -1.53846154s;
    top: 6%;
  }
  .cloud.foreground:nth-child(1) {
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    height: 12.5%;
  }
  .cloud.background:nth-child(1) {
    -webkit-animation-duration: 8.5s;
    animation-duration: 8.5s;
    height: 7.84090909%;
  }
  .cloud {
    background-image: url(${bwcloud});
  }
  .cloud.background {
    background-image: url(${bwcloud});
  }
`;

export default ShutDown;
