export const lengths = {
  vEffect: 164,
  emotion: 178,
  space: 152,
  lut: 34,
  lead: 70,
  pad: 20,
  kick: 17,
  perc: 72,
  bass: 25,
  noise: 65,
}

export const mathUnicode = [
  "∀",
  "∁",
  "∂",
  "∃",
  "∄",
  "∅",
  "∆",
  "∇",
  "∈",
  "∉",
  "∊",
  "∋",
  "∌",
  "∍",
  "∎",
  "∏",
  "∐",
  "∑",
  "−",
  "∓",
  "∔",
  "∕",
  "∖",
  "∗",
  "∘",
  "∙",
  "√",
  "∛",
  "∜",
  "∝",
  "∞",
  "∟",
  "∠",
  "∡",
  "∢",
  "∣",
  "∤",
  "∥",
  "∦",
  "∧",
  "∨",
  "∩",
  "∪",
  "∫",
  "∬",
  "∭",
  "∮",
  "∯",
  "∰",
  "∱",
  "∲",
  "∳",
  "∴",
  "∵",
  "∶",
  "∷",
  "∸",
  "∹",
  "∺",
  "∻",
  "∼",
  "∽",
  "∾",
  "∿",
  "≀",
  "≁",
  "≂",
  "≃",
  "≄",
  "≅",
  "≆",
  "≇",
  "≈",
  "≉",
  "≊",
  "≋",
  "≌",
  "≍",
  "≎",
  "≏",
  "≐",
  "≑",
  "≒",
  "≓",
  "≔",
  "≕",
  "≖",
  "≗",
  "≘",
  "≙",
  "≚",
  "≛",
  "≜",
  "≝",
  "≞",
  "≟",
  "≠",
  "≡",
  "≢",
  "≣",
  "≤",
  "≥",
  "≦",
  "≧",
  "≨",
  "≩",
  "≪",
  "≫",
  "≬",
  "≭",
  "≮",
  "≯",
  "≰",
  "≱",
  "≲",
  "≳",
  "≴",
  "≵",
  "≶",
  "≷",
  "≸",
  "≹",
  "≺",
  "≻",
  "≼",
  "≽",
  "≾",
  "≿",
  "⊀",
  "⊁",
  "⊂",
  "⊃",
  "⊄",
  "⊅",
  "⊆",
  "⊇",
  "⊈",
  "⊉",
  "⊊",
  "⊋",
  "⊌",
  "⊍",
  "⊎",
  "⊏",
  "⊐",
  "⊑",
  "⊒",
  "⊓",
  "⊔",
  "⊕",
  "⊖",
  "⊗",
  "⊘",
  "⊙",
  "⊚",
  "⊛",
  "⊜",
  "⊝",
  "⊞",
  "⊟",
  "⊠",
  "⊡",
  "⊢",
  "⊣",
  "⊤",
  "⊥",
  "⊦",
  "⊧",
  "⊨",
  "⊩",
  "⊪",
  "⊫",
  "⊬",
  "⊭",
  "⊮",
  "⊯",
  "⊰",
  "⊱",
  "⊲",
  "⊳",
  "⊴",
  "⊵",
  "⊶",
  "⊷",
  "⊸",
  "⊹",
  "⊺",
  "⊻",
  "⊼",
  "⊽",
  "⊾",
  "⊿",
  "⋀",
  "⋁",
  "⋂",
  "⋃",
  "⋄",
  "⋅",
  "⋆",
  "⋇",
  "⋈",
  "⋉",
  "⋊",
  "⋋",
  "⋌",
  "⋍",
  "⋎",
  "⋏",
  "⋐",
  "⋑",
  "⋒",
  "⋓",
  "⋔",
  "⋕",
  "⋖",
  "⋗",
  "⋘",
  "⋙",
  "⋚",
  "⋛",
  "⋜",
  "⋝",
  "⋞",
  "⋟",
  "⋠",
  "⋡",
  "⋢",
  "⋣",
  "⋤",
  "⋥",
  "⋦",
  "⋧",
  "⋨",
  "⋩",
  "⋪",
  "⋫",
  "⋬",
  "⋭",
  "⋮",
  "⋯",
  "⋰",
  "⋱",
  "⋲",
  "⋳",
  "⋴",
  "⋵",
  "⋶",
  "⋷",
  "⋸",
  "⋹",
  "⋺",
  "⋻",
  "⋼",
  "⋽",
  "⋾",
  "⋿",
  "⨀",
  "⨁",
  "⨂",
  "⨃",
  "⨄",
  "⨅",
  "⨆",
  "⨇",
  "⨈",
  "⨉",
  "⨊",
  "⨋",
  "⨌",
  "⨍",
  "⨎",
  "⨏",
  "⨐",
  "⨑",
  "⨒",
  "⨓",
  "⨔",
  "⨕",
  "⨖",
  "⨗",
  "⨘",
  "⨙",
  "⨚",
  "⨛",
  "⨜",
  "⨝",
  "⨞",
  "⨟",
  "⨠",
  "⨡",
  "⨢",
  "⨣",
  "⨤",
  "⨥",
  "⨦",
  "⨧",
  "⨨",
  "⨩",
  "⨪",
  "⨫",
  "⨬",
  "⨭",
  "⨮",
  "⨯",
  "⨰",
  "⨱",
  "⨲",
  "⨳",
  "⨴",
  "⨵",
  "⨶",
  "⨷",
  "⨸",
  "⨹",
  "⨺",
  "⨻",
  "⨼",
  "⨽",
  "⨾",
  "⨿",
  "⩀",
  "⩁",
  "⩂",
  "⩃",
  "⩄",
  "⩅",
  "⩆",
  "⩇",
  "⩈",
  "⩉",
  "⩊",
  "⩋",
  "⩌",
  "⩍",
  "⩎",
  "⩏",
  "⩐",
  "⩑",
  "⩒",
  "⩓",
  "⩔",
  "⩕",
  "⩖",
  "⩗",
  "⩘",
  "⩙",
  "⩚",
  "⩛",
  "⩜",
  "⩝",
  "⩞",
  "⩟",
  "⩠",
  "⩡",
  "⩢",
  "⩣",
  "⩤",
  "⩥",
  "⩦",
  "⩧",
  "⩨",
  "⩩",
  "⩪",
  "⩫",
  "⩬",
  "⩭",
  "⩮",
  "⩯",
  "⩰",
  "⩱",
  "⩲",
  "⩳",
  "⩴",
  "⩵",
  "⩶",
  "⩷",
  "⩸",
  "⩹",
  "⩺",
  "⩻",
  "⩼",
  "⩽",
  "⩾",
  "⩿",
  "⪀",
  "⪁",
  "⪂",
  "⪃",
  "⪄",
  "⪅",
  "⪆",
  "⪇",
  "⪈",
  "⪉",
  "⪊",
  "⪋",
  "⪌",
  "⪍",
  "⪎",
  "⪏",
  "⪐",
  "⪑",
  "⪒",
  "⪓",
  "⪔",
  "⪕",
  "⪖",
  "⪗",
  "⪘",
  "⪙",
  "⪚",
  "⪛",
  "⪜",
  "⪝",
  "⪞",
  "⪟",
  "⪠",
  "⪡",
  "⪢",
  "⪣",
  "⪤",
  "⪥",
  "⪦",
  "⪧",
  "⪨",
  "⪩",
  "⪪",
  "⪫",
  "⪬",
  "⪭",
  "⪮",
  "⪯",
  "⪰",
  "⪱",
  "⪲",
  "⪳",
  "⪴",
  "⪵",
  "⪶",
  "⪷",
  "⪸",
  "⪹",
  "⪺",
  "⪻",
  "⪼",
  "⪽",
  "⪾",
  "⪿",
  "⫀",
  "⫁",
  "⫂",
  "⫃",
  "⫄",
  "⫅",
  "⫆",
  "⫇",
  "⫈",
  "⫉",
  "⫊",
  "⫋",
  "⫌",
  "⫍",
  "⫎",
  "⫏",
  "⫐",
  "⫑",
  "⫒",
  "⫓",
  "⫔",
  "⫕",
  "⫖",
  "⫗",
  "⫘",
  "⫙",
  "⫚",
  "⫛",
  "⫝̸",
  "⫝",
  "⫞",
  "⫟",
  "⫠",
  "⫡",
  "⫢",
  "⫣",
  "⫤",
  "⫥",
  "⫦",
  "⫧",
  "⫨",
  "⫩",
  "⫪",
  "⫫",
  "⫬",
  "⫭",
  "⫮",
  "⫯",
  "⫰",
  "⫱",
  "⫲",
  "⫳",
  "⫴",
  "⫵",
  "⫶",
  "⫷",
  "⫸",
  "⫹",
  "⫺",
  "⫻",
  "⫼",
  "⫽",
  "⫾",
  "⫿",
  "℀",
  "℁",
  "ℂ",
  "℃",
  "℄",
  "℅",
  "℆",
  "ℇ",
  "℈",
  "℉",
  "ℊ",
  "ℋ",
  "ℌ",
  "ℍ",
  "ℎ",
  "ℏ",
  "ℐ",
  "ℑ",
  "ℒ",
  "ℓ",
  "℔",
  "ℕ",
  "№",
  "℗",
  "℘",
  "ℙ",
  "ℚ",
  "ℛ",
  "ℜ",
  "ℝ",
  "℞",
  "℟",
  "℠",
  "℡",
  "™",
  "℣",
  "ℤ",
  "℥",
  "Ω",
  "℧",
  "ℨ",
  "℩",
  "K",
  "Å",
  "ℬ",
  "ℭ",
  "℮",
  "ℯ",
  "ℰ",
  "ℱ",
  "Ⅎ",
  "ℳ",
  "ℴ",
  "ℵ",
  "ℶ",
  "ℷ",
  "ℸ",
  "ℹ",
  "℺",
  "℻",
  "ℼ",
  "ℽ",
  "ℾ",
  "ℿ",
  "⅀",
  "⅁",
  "⅂",
  "⅃",
  "⅄",
  "ⅅ",
  "ⅆ",
  "ⅇ",
  "ⅈ",
  "ⅉ",
  "⅊",
  "⅋",
  "⅌",
  "⅍",
  "ⅎ",
  "⅏",
  "⟀",
  "⟁",
  "⟂",
  "⟃",
  "⟄",
  "⟅",
  "⟆",
  "⟇",
  "⟈",
  "⟉",
  "⟊",
  "⟋",
  "⟌",
  "⟍",
  "⟎",
  "⟏",
  "⟐",
  "⟑",
  "⟒",
  "⟓",
  "⟔",
  "⟕",
  "⟖",
  "⟗",
  "⟘",
  "⟙",
  "⟚",
  "⟛",
  "⟜",
  "⟝",
  "⟞",
  "⟟",
  "⟠",
  "⟡",
  "⟢",
  "⟣",
  "⟤",
  "⟥",
  "⟦",
  "⟧",
  "⟨",
  "⟩",
  "⟪",
  "⟫",
  "⟬",
  "⟭",
  "⟮",
  "⟯",
  "⦀",
  "⦁",
  "⦂",
  "⦃",
  "⦄",
  "⦅",
  "⦆",
  "⦇",
  "⦈",
  "⦉",
  "⦊",
  "⦋",
  "⦌",
  "⦍",
  "⦎",
  "⦏",
  "⦐",
  "⦑",
  "⦒",
  "⦓",
  "⦔",
  "⦕",
  "⦖",
  "⦗",
  "⦘",
  "⦙",
  "⦚",
  "⦛",
  "⦜",
  "⦝",
  "⦞",
  "⦟",
  "⦠",
  "⦡",
  "⦢",
  "⦣",
  "⦤",
  "⦥",
  "⦦",
  "⦧",
  "⦨",
  "⦩",
  "⦪",
  "⦫",
  "⦬",
  "⦭",
  "⦮",
  "⦯",
  "⦰",
  "⦱",
  "⦲",
  "⦳",
  "⦴",
  "⦵",
  "⦶",
  "⦷",
  "⦸",
  "⦹",
  "⦺",
  "⦻",
  "⦼",
  "⦽",
  "⦾",
  "⦿",
  "⧀",
  "⧁",
  "⧂",
  "⧃",
  "⧄",
  "⧅",
  "⧆",
  "⧇",
  "⧈",
  "⧉",
  "⧊",
  "⧋",
  "⧌",
  "⧍",
  "⧎",
  "⧏",
  "⧐",
  "⧑",
  "⧒",
  "⧓",
  "⧔",
  "⧕",
  "⧖",
  "⧗",
  "⧘",
  "⧙",
  "⧚",
  "⧛",
  "⧜",
  "⧝",
  "⧞",
  "⧟",
  "⧠",
  "⧡",
  "⧢",
  "⧣",
  "⧤",
  "⧥",
  "⧦",
  "⧧",
  "⧨",
  "⧩",
  "⧪",
  "⧫",
  "⧬",
  "⧭",
  "⧮",
  "⧯",
  "⧰",
  "⧱",
  "⧲",
  "⧳",
  "⧴",
  "⧵",
  "⧶",
  "⧷",
  "⧸",
  "⧹",
  "⧺",
  "⧻",
  "⧼",
  "⧽",
  "⧾",
  "⧿",
  "⌀",
  "⌁",
  "⌂",
  "⌃",
  "⌄",
  "⌅",
  "⌆",
  "⌇",
  "⌈",
  "⌉",
  "⌊",
  "⌋",
  "⌌",
  "⌍",
  "⌎",
  "⌏",
  "⌐",
  "⌑",
  "⌒",
  "⌓",
  "⌔",
  "⌕",
  "⌖",
  "⌗",
  "⌘",
  "⌙",
  "⁒",
  "⇆",
  "⌜",
  "⌝",
  "⌞",
  "⌟",
  "⌠",
  "⌡",
  "⌢",
  "⌣",
  "⌤",
  "⌥",
  "⌦",
  "⌧",
  "⌨",
  "〈",
  "〉",
  "⌫",
  "⌬",
  "⌭",
  "⌮",
  "⌯",
  "⌰",
  "⌱",
  "⌲",
  "⌳",
  "⌴",
  "⌵",
  "⌶",
  "⌷",
  "⌸",
  "⌹",
  "⌺",
  "⌻",
  "⌼",
  "⌽",
  "⌾",
  "⌿",
  "⍀",
  "⍁",
  "⍂",
  "⍃",
  "⍄",
  "⍅",
  "⍆",
  "⍇",
  "⍈",
  "⍉",
  "⍊",
  "⍋",
  "⍌",
  "⍍",
  "⍎",
  "⍏",
  "⍐",
  "⍑",
  "⍒",
  "⍓",
  "⍔",
  "⍕",
  "⍖",
  "⍗",
  "⍘",
  "⍙",
  "⍚",
  "⍛",
  "⍜",
  "⍝",
  "⍞",
  "⍟",
  "⍠",
  "⍡",
  "⍢",
  "⍣",
  "⍤",
  "⍥",
  "⍦",
  "⍧",
  "⍨",
  "⍩",
  "⍪",
  "⍫",
  "⍬",
  "⍭",
  "⍮",
  "⍯",
  "⍰",
  "⍱",
  "⍲",
  "⍳",
  "⍴",
  "⍵",
  "⍶",
  "⍷",
  "⍸",
  "⍹",
  "⍺",
  "⍻",
  "⍼",
  "⍽",
  "⍾",
  "⍿",
  "⎀",
  "⎁",
  "⎂",
  "⎃",
  "⎄",
  "⎅",
  "⎆",
  "⎇",
  "⎈",
  "⎉",
  "⎊",
  "⎋",
  "⎌",
  "⎍",
  "⎎",
  "⎏",
  "⎐",
  "⎑",
  "⎒",
  "⎓",
  "⎔",
  "⎕",
  "⎖",
  "⎗",
  "⎘",
  "⎙",
  "⎚",
  "⎛",
  "⎜",
  "⎝",
  "⎞",
  "⎟",
  "⎠",
  "⎡",
  "⎢",
  "⎣",
  "⎤",
  "⎥",
  "⎦",
  "⎧",
  "⎨",
  "⎩",
  "⎪",
  "⎫",
  "⎬",
  "⎭",
  "⎮",
  "⎯",
  "⎰",
  "⎱",
  "⎲",
  "⎳",
  "⎴",
  "⎵",
  "⎶",
  "⎷",
  "⎸",
  "⎹",
  "⎺",
  "⎻",
  "⎼",
  "⎽",
  "⎾",
  "⎿",
  "⏀",
  "⏁",
  "⏂",
  "⏃",
  "⏄",
  "⏅",
  "⏆",
  "⏇",
  "⏈",
  "⏉",
  "⏊",
  "⏋",
  "⏌",
  "⏍",
  "⏎",
  "⏏",
  "⏐",
  "⏑",
  "⏒",
  "⏓",
  "⏔",
  "⏕",
  "⏖",
  "⏗",
  "⏘",
  "⏙",
  "⏚",
  "⏛",
  "⏜",
  "⏝",
  "⏞",
  "⏟",
  "⏠",
  "⏡",
  "⏢",
  "⏣",
  "⏤",
  "⏥",
  "⏦",
  "⏧",
];
