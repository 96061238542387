import React, { useEffect, setGlobal, useGlobal, useState } from "reactn";
import { ethers } from "ethers";
import HeavenComputer from "artifacts/HeavenComputer.json";
import SkyTop from "./sections";

const Lobby = ({
  handleViewToken,
  standAlone,
  createViewList,
  id,
  onToggleComponent,
}) => {
  const collectionId = 2;
  const [provider] = useGlobal("provider");
  const [connected] = useGlobal("connected");
  const [account] = useGlobal("account");
  const [contract] = useGlobal("contract");
  const [details, setDetails] = useState("");
  const [error, setError] = useState(null);
  const notConnectedError = "oops! looks like you're not connected."

  useEffect(() => {
    if (error === notConnectedError && connected) setError(null);
    else if (error) document.querySelector(".skyerror").innerHTML = error;
  }, [error, connected]);

  useEffect(() => {
    if (contract)
      contract["viewCollectionDetails(uint256)"](collectionId).then((r) =>
        setDetails(Object.values(r))
      );
  }, [contract]);

  async function purchase() {
    if (!connected) return;
    const abi = HeavenComputer.abi;
    const web3 = new ethers.providers.Web3Provider(provider);
    const signer = web3.getSigner(account);
    const writeContract = new ethers.Contract(contract.address, abi, signer);
    const price = details[1];
    const tx = await writeContract
      .purchase(collectionId, {
        value: price,
      })
      .catch((err) => {
        if (err.code === "ACTION_REJECTED") {
          setError("why would you reject sky?");
        } else if (err.code === "INSUFFICIENT_FUNDS") {
          setError("looks like there is not enough ETH in this wallet.");
        } else {
          setError("oops! something went wrong.");
        }
      });
    await tx
      .wait(
        setError("success! please don't close this window. your sky is coming..."),
        setGlobal({ appLoading: { name: "Sky Luxury", id: id } }),
      )
      .then((receipt) => {
        const mintedToken = receipt.events[0].args["tokenId"].toNumber();
        console.log("minted token: " + mintedToken);
        setGlobal({ appLoading: null });
        setError(null);
        handleViewToken(mintedToken);
      })
      .catch((err) => {
        setError("oops! something went wrong.");
        setGlobal({ appLoading: null });
        console.log(err);
      });
  }
  return (
    <>
      <SkyTop
        onToggleComponent={onToggleComponent}
        id={id}
        standAlone={standAlone}
        createViewList={createViewList}
        error={error}
        setError={setError}
        purchase={purchase}
        notConnectedError={notConnectedError}
      />
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
      <div className="sticky"></div>
    </>
  );
};

export default Lobby;