export const drawEnv = (context, canvas, coord, radius) => {
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.beginPath();
  context.fillRect(coord.a.x - radius/2, coord.a.y - radius/2, radius, radius);
  context.closePath();
  context.beginPath();
  context.fillRect(coord.d.x - radius/2, coord.d.y - radius/2, radius, radius);
  context.closePath();
  context.beginPath();
  context.fillRect(coord.s.x - radius/2, coord.s.y - radius/2, radius, radius);
  context.closePath();
  context.beginPath();
  context.fillRect(coord.r.x - radius/2, coord.r.y - radius/2, radius, radius);
  context.closePath();
  context.beginPath();
  context.moveTo(coord[0].x, coord[0].y);
  context.lineTo(coord.a.x, coord.a.y);
  context.lineTo(coord.d.x, coord.d.y);
  context.lineTo(coord.s.x, coord.s.y);
  context.lineTo(coord.r.x, coord.r.y);
  context.stroke();
  context.closePath();
};
