const Game = [
  {
    type: 'item',
    text: 'New'
  },
  {
    type: 'item',
    text: 'Restart',
    disable: true,
  },
  {
    type: 'separator',
  },
  {
    type: 'item',
    text: 'Undo',
    disable: true,
  }
];

const Help = [
  {
    type: 'item',
    text: 'About...',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Help Center',
    disable: false,
  },
];

const Edit = [
  {
    type: 'item',
    text: 'Card Style'
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Close',
    disable: false,
  },
];

const optionsMenuData = { Game, Help, Edit };
export default optionsMenuData;
