import Settings from "./Settings";
import Error from "./Error";
import Warning from "./Warning";
import MyComputer from "./MyComputer";
import RecycleBin from "./RecycleBin";
import GhostSoftware from "./GhostSoftware";
import Notepad from "./Notepad";
import Metamask from "./Metamask";
import Ghostsweeper from "./Ghostsweeper";
import Help from "./Help";
import Calculator from "./Calculator";
import GodRemixer from "./GodRemixer";
import ColorPicker from "./ColorPicker";
import Documents from "./Documents";
import GodObserver from "./GodObserver";
import DiskDrive from "./DiskDrive";
import Soulitaire from "./Soulitaire";
import CDCollection from "./CDCollection";
import PopUp from "./PopUp";
import Mall from "./Mall";
import SkyLuxury from "./SkyLuxury";
import bin0 from "assets/icons/bin0.ico";
import gradient from "assets/icons/gradient.ico";
// import sky from "assets/icons/sky.ico";
import sky from "apps/SkyLuxury/skyIcons.js";
import colorpicker from "assets/icons/colorpicker.ico";
import metamask from "assets/icons/metamask.ico";
import gift from "assets/icons/gift4.ico";
import ghost from "assets/icons/ghost.ico";
import ghostdocs from "assets/icons/folderghost.ico";
import skydocs from "assets/icons/foldersky.ico";
import goddocs from "assets/icons/foldergod.ico";
import error from "assets/icons/error.ico";
import computer from "assets/icons/computer.ico";
import notepad from "assets/icons/notepad.ico";
import documents from "assets/icons/documents.ico";
import calculator from "assets/icons/calculator.ico";
import help from "assets/icons/help.ico";
import settings from "assets/icons/settings.ico";
import observer from "assets/icons/observer.ico";
import sweeper from "assets/icons/sweeper.ico";
import cddrive from "assets/icons/cddrive.ico";
import cdclaim from "assets/icons/cdclaim.ico";
import soulitaire from "assets/icons/soulitaire.ico";
import mall from "assets/icons/mall.ico";
import remixer from "assets/icons/remixer.ico";
import warning from "assets/icons/warning.ico"

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight - 37;

window.addEventListener("resize", onWindowResize, false);

const desktopAppList = [
  // "SkyLuxury"
];

export const appSettings = {
  GradientGraphics: {
    header: {
      icon: gradient,
      title: "Gradient Graphics",
      alt: "Warning",
      buttons: ["close"],
    },
    component: Warning,
    defaultSize: {
      width: 200,
    },
    defaultOffset: {
      x: Math.round(windowWidth / 2 - 200 / 2),
      y: Math.round(windowHeight / 2 - 123 / 2),
    },
    error: true,
    mobileFriendly: true,
    status: false,
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  SkyLuxury: {
    header: {
      icon: sky,
      title: "Sky Luxury",
      docIcon: skydocs,
      buttons: ["minimize", "maximize", "close"],
    },
    component: SkyLuxury,
    defaultSize: {
      widthMultiplier: 88,
      heightMultiplier: 85,
    },
    mobileFriendly: true,
    status: true,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: false,
  },
  Metamask: {
    header: {
      icon: metamask,
      title: "Connection Wizard",
      buttons: ["help", "close"],
    },
    component: Metamask,
    defaultSize: {
      auto: true,
      maxWidth: 380,
      maxHeight: 384,
    },
    mobileFriendly: true,
    status: false,
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: false,
  },
  Error: {
    header: {
      icon: error,
      title: "Error",
      buttons: ["close"],
    },
    component: Error,
    defaultSize: {
      maxWidth: 290,
      auto: true,
    },
    defaultOffset: {
      x: Math.round(windowWidth / 2 - 290 / 2),
      y: Math.round(windowHeight / 2 - 123 / 2),
    },
    error: true,
    mobileFriendly: true,
    status: false,
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  Warning: {
    header: {
      icon: warning,
      title: "Warning",
      buttons: ["close"],
    },
    component: Warning,
    defaultSize: {
      width: 200,
    },
    defaultOffset: {
      x: Math.round(windowWidth / 2 - 200 / 2),
      y: Math.round(windowHeight / 2 - 123 / 2),
    },
    error: true,
    mobileFriendly: true,
    status: false,
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  MyComputer: {
    header: {
      icon: computer,
      title: "Heaven Computer",
      alt: "My Computer",
      buttons: ["minimize", "maximize", "close"],
    },
    defaultSize: {
      minHeight: 400,
    },
    component: MyComputer,
    mobileFriendly: true,
    status: true,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  RecycleBin: {
    header: {
      icon: bin0,
      title: "Recycle Bin",
      buttons: ["minimize", "maximize", "close"],
    },
    component: RecycleBin,
    mobileFriendly: true,
    status: true,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  Soulitaire: {
    header: {
      icon: soulitaire,
      title: "Soulitaire",
      buttons: ["minimize", "maximize", "close"],
    },
    component: Soulitaire,
    defaultSize: {
      minWidth: 590,
      minHeight: 450,
    },
    mobileFriendly: false,
    status: false,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: false,
  },
  Mall: {
    header: {
      icon: mall,
      title: "Mall Of Tortured Souls",
      buttons: ["minimize", "maximize", "close"],
    },
    component: Mall,
    defaultSize: {
      minWidth: 400,
      minHeight: 400,
    },
    mobileFriendly: false,
    status: true,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: false,
  },
  GodObserver: {
    header: {
      icon: observer,
      docIcon: goddocs,
      title: "God Observer",
      buttons: ["minimize", "maximize", "close"],
    },
    component: GodObserver,
    defaultSize: {
      minWidth: 400,
      minHeight: 400,
    },
    mobileFriendly: true,
    status: true,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  GodRemixer: {
    header: {
      icon: remixer,
      title: "God Remixer",
      alt: "GodRemixer (Trial Version)",
      buttons: ["minimize", "maximize", "close"],
    },
    component: GodRemixer,
    defaultSize: {
      widthMultiplier: 60,
      heightMultiplier: 60,
    },
    mobileFriendly: false,
    status: false,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: false,
  },
  DiskDrive: {
    header: {
      icon: cddrive,
      title: "Disk Drive",
      buttons: ["minimize", "maximize", "close"],
    },
    defaultSize: {
      maxWidth: 500,
      maxHeight: 400
    },
    component: DiskDrive,
    mobileFriendly: true,
    status: true,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  CDCollection: {
    header: {
      icon: cdclaim,
      title: "CD Collection",
      buttons: ["minimize", "maximize", "close"],
    },
    component: CDCollection,
    defaultSize: {
      maxWidth: 400,
      minHeight: 400
    },
    mobileFriendly: true,
    status: false,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: false,
  },
  Documents: {
    header: {
      icon: documents,
      title: "My Documents",
      buttons: ["minimize", "maximize", "close"],
    },
    defaultSize: {
      maxWidth: 500,
      maxHeight: 400
    },
    component: Documents,
    mobileFriendly: true,
    status: true,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  PopUp: {
    header: {
      icon: gift,
      title: "C O N G R A T U L A T I O N S ! ! ! ! ! ! ! !",
      buttons: ["minimize", "maximize", "close"],
    },
    component: PopUp,
    defaultSize: {
      widthMultiplier: 60,
      heightMultiplier: 40,
    },
    mobileFriendly: true,
    status: false,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  GhostSoftware: {
    header: {
      icon: ghost,
      docIcon: ghostdocs,
      title: "Ghost Software",
      buttons: ["minimize", "maximize", "close"],
    },
    defaultSize: {
      minHeight: 400,
    },
    component: GhostSoftware,
    mobileFriendly: true,
    status: true,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  Ghostsweeper: {
    header: {
      title: "Ghostsweeper",
      icon: sweeper,
      buttons: ["minimize", "close"],
    },
    defaultSize: {
      auto: true,
      minWidth: 194,
      minHeight: 275,
    },
    component: Ghostsweeper,
    mobileFriendly: true,
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  Notepad: {
    header: {
      icon: notepad,
      title: "Notepad",
      alt: "Untitled - Notepad",
      buttons: ["minimize", "maximize", "close"],
    },
    component: Notepad,
    mobileFriendly: true,
    status: false,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  ColorPicker: {
    header: {
      icon: colorpicker,
      title: "Edit Colors",
      buttons: ["minimize", "close"],
    },
    defaultSize: {
      auto: true,
      minWidth: 259,
      minHeight: 351,
    },
    component: ColorPicker,
    mobileFriendly: true,
    status: false,
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: false,
  },
  Calculator: {
    header: {
      icon: calculator,
      title: "Calculator",
      buttons: ["minimize", "close"],
    },
    component: Calculator,
    defaultSize: {
      auto: true,
      minWidth: 176,
      minHeight: 280,
    },
    mobileFriendly: true,
    status: false,
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  Help: {
    header: {
      icon: help,
      title: "Help",
      buttons: ["minimize", "maximize", "close"],
    },
    component: Help,
    mobileFriendly: true,
    status: true,
    resizable: true,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  Settings: {
    header: {
      icon: settings,
      title: "Settings",
      buttons: ["minimize", "close"],
    },
    component: Settings,
    defaultSize: {
      auto: true,
      maxWidth: 400,
      maxHeight: 492,
    },
    mobileFriendly: true,
    status: false,
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: false,
  },
};

export const randomizeDefaultOffset = (appSettings, resize, zoom) => {
  let windowWidth = zoom ? window.innerWidth * 1 / zoom : window.innerWidth;
  let windowHeight = zoom ? window.innerHeight * 1 / zoom - 37 : window.innerHeight - 37;
  Object.entries(appSettings).forEach(([key, appSetting]) => {
    if ((!appSetting.defaultOffset && !resize) || (appSetting.defaultOffset && resize)) {
      let width, height;
      const max = 0.95;
      const min = 0.3;
      if (appSetting.defaultSize && appSetting.defaultSize.auto) {
        width = appSetting.defaultSize.minWidth ? appSetting.defaultSize.minWidth : appSetting.defaultSize.maxWidth ? appSetting.defaultSize.maxWidth : 0
        height = appSetting.defaultSize.minHeight ? appSetting.defaultSize.minHeight : appSetting.defaultSize.maxHeight ? appSetting.defaultSize.maxHeight : 0
      } else if (appSetting.defaultSize && !appSetting.defaultSize.auto) {
        let w = appSetting.defaultSize.width;
        let h = appSetting.defaultSize.height;
        let wm = appSetting.defaultSize.widthMultiplier;
        let hm = appSetting.defaultSize.heightMultiplier;
        let maxW = appSetting.defaultSize.maxWidth;
        let maxH = appSetting.defaultSize.maxHeight;
        let minW = appSetting.defaultSize.minWidth;
        let minH = appSetting.defaultSize.minHeight;
        if (zoom) {
          w = w * (1 / zoom);
          h = h * (1 / zoom);
          maxH = maxH * (1 / zoom);
          maxW = maxW * (1 / zoom);
          minH = minH * (1 / zoom);
        }
        if (wm) {
          width = (wm * windowWidth) / 100;
        } else {
          w
            ? (width = w)
            : (width = Math.random() * (maxW ? maxW : windowWidth * max - (minW ? minW : windowWidth * min)) + (minW ? minW : windowWidth * min))
        }
        appSetting.defaultSize.width = width;
        if (hm) {
          height = (hm * windowHeight) / 100;
        } else {
          h
            ? (height = h)
            : (height = Math.random() * (maxH ? maxH : windowHeight * max - (minH ? minH : windowHeight * min)) + (minH ? minH : windowHeight * min))
        }
        appSetting.defaultSize.height = height;
      } else if ((!appSetting.defaultSize) || (appSetting.defaultSize && !appSetting.defaultSize.auto)) {
        const size = (d) => Math.random() * (d * max - d * min) + d * min
        width = size(windowWidth);
        height = size(windowHeight);
        appSetting.defaultSize = { width, height }
      }
      const maxWidthOffset = windowWidth - width;
      const maxHeightOffset = windowHeight - height;
      let x = Math.round(Math.random() * maxWidthOffset);
      if (!appSetting.defaultOffset) {
        appSetting.defaultOffset = {
          x: x,
          y: Math.round(Math.random() * maxHeightOffset),
        };
      } else {
        if (!appSetting.defaultOffset.x) {
          appSetting.defaultOffset.x = x;
        }
        if (!appSetting.defaultOffset.y) {
          appSetting.defaultOffset.y = Math.round(Math.random() * maxHeightOffset)
        }
      }
      if (windowWidth < 800) {
        let name = appSetting.header.title;
        let oddSizedApps = [
          "Gradient Graphics",
          "Warning",
          "Error",
          "Connection Wizard",
          "Settings",
          "PopUp",
          "Calculator",
          "Ghostsweeper",
          "Edit Colors",
        ];
        if (!oddSizedApps.includes(name)) {
          appSetting.defaultSize = {
            width: Math.round((windowWidth * 3) / 4),
            height: Math.round((windowHeight * 3) / 4),
          };
          appSetting.defaultOffset = { x: Math.round(windowWidth / 4), y: 0 };
        }
        if (name === "Sky Luxury") appSetting.maximized = true;
      }
    }
  });
}

randomizeDefaultOffset(appSettings, false, null);

let resizeTimer;
function onWindowResize() {
  if (resizeTimer) clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    randomizeDefaultOffset(appSettings, true, null);
  }, 500);
}

export const desktopApps = desktopAppList
  .filter((appName) => appSettings.hasOwnProperty(appName))
  .map((appName, id) => ({ ...appSettings[appName], defaultOffset: { x: Math.round(windowWidth / 2 - windowWidth * appSettings[appName].defaultSize.widthMultiplier / 200), y: Math.round(windowHeight / 2 - windowHeight * appSettings[appName].defaultSize.heightMultiplier / 200) }, id, zIndex: id }));


export {
  SkyLuxury,
  RecycleBin,
  ColorPicker,
  GodRemixer,
  Error,
  Warning,
  MyComputer,
  Notepad,
  Calculator,
  Metamask,
  GhostSoftware,
  Help,
  Documents,
  Settings,
  GodObserver,
  DiskDrive,
  Ghostsweeper,
  PopUp,
  CDCollection,
  Soulitaire,
  Mall,
};
