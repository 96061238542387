// import bg from "./bg_og.png"
const bgs = [
  require("./bg0.png"),
  require("./bg7.png"),
  require("./bg9.png"),
  require("./bg12.png"),
  require("./bg13.png"),
  require("./bg14.png"),
  require("./bg15.png"),
  require("./bg16.png"),
  require("./bg17.png"),
  require("./bg20.png"),
  require("./bg21.png"),
];

// const bgs = [
//   require("./bg1.png"),
//   require("./bg2.png"),
//   require("./bg3.png"),
//   require("./bg4.png"),
//   require("./bg5.png"),
//   require("./bg6.png"),
//   require("./bg8.png"),
//   require("./bg10.png"),
//   require("./bg11.png"),
//   require("./bg18.png"),
//   require("./bg19.png"),
//   require("./bg22.png"),
// ]

    
const bg = bgs[Math.floor(Math.random() * bgs.length)]
document.querySelector("#root").style.backgroundImage = "url(" + bg + ")"

export default bg;

