import React, {
  useEffect,
  useGlobal,
  useState,
  useRef,
  setGlobal,
} from "reactn";
import styled from "styled-components";
import bwcloud from "assets/icons/cloud_bw.ico";
import cloud from "assets/icons/cloud.ico";
import { useBattery } from "react-use";
import { getLanguageNameWithCountry } from "language-name-to-language-name";
import {
  isDesktop,
  browserName,
  browserVersion,
  getUA,
  osName,
  osVersion,
  isSafari,
  isIE,
} from "react-device-detect";

const StartUp = () => {
  const languages = useRef([]);
  const date = useRef(new Date().getTime());
  const batteryState = useBattery();
  const [loggedIn, setLoggedIn] = useState(false);
  const [bluescreen] = useGlobal("bluescreen");
  const [turnedOff] = useGlobal("turnedOff");
  const [level, setLevel] = useState("100");
  const [userDevice, setUserDevice] = useState("Unknown");
  const [platform, setPlatform] = useState("Unknown");
  const [desktop, setDesktop] = useState("Unknown");
  const [navigation, setNavigation] = useState("Unknown");
  const [langList, setLangList] = useState("Unknown");
  const nav = window.navigator;

  useEffect(() => {
    window.localStorage.getItem("loggedIntoHeaven")
      ? setLoggedIn(true)
      : setLoggedIn(false);
    // eslint-disable-next-line
  }, [window.localStorage.getItem("loggedIntoHeaven")]);

  useEffect(() => {
    setPlatform(`${osName} ${osVersion}`);
    setUserDevice(getUA);
    setDesktop(isDesktop);
    setNavigation(`${browserName} ${browserVersion}`);
    if (
      nav.languages !== null &&
      nav.languages !== undefined &&
      nav.languages.length > 1
    ) {
      for (let i = 0; i < nav.languages.length; i++) {
        languages.current.push(
          getLanguageNameWithCountry(nav.languages[i], "en", false)[
            "languageName"
          ]
        );
      }
      languages.current = languages.current.filter(
        (val, i, arr) => arr.indexOf(val) === i
      );
      setLangList(
        [
          languages.current.slice(0, -1).join(", "),
          languages.current.slice(-1)[0],
        ].join(languages.current.length < 2 ? "" : " and ")
      );
    }
  }, [nav]);

  useEffect(() => {
    if (batteryState.fetched) setLevel((batteryState.level * 100).toString());
    const counters = document.querySelectorAll(".counter");
    const speed = 1000;
    counters.forEach((counter) => {
      const updateCount = () => {
        const target = +counter.getAttribute("data-target");
        const count = +counter.innerText;
        const inc = target / speed;
        if (count < target) {
          counter.innerText = count + inc;
          setTimeout(updateCount, 1);
        } else {
          counter.innerText = target;
        }
      };
      updateCount();
    });
  }, [batteryState.fetched, batteryState.level]);

  useEffect(() => {
    if (!loggedIn && !bluescreen && !turnedOff) {
      if (document.querySelector("#startup_screen"))
        document
          .querySelector("#main_app")
          .appendChild(document.querySelector("#startup_screen"));
      const startUpWrap = document.getElementsByClassName("startup_wrap")[0];
      const clouds = document.getElementsByClassName("cloud_wrap")[0];
      const startUp = document.querySelector(".startup");
      function loadScreen() {
        startUp.classList.remove("initial");
        startUp.classList.add("visible");
        staggerElements(startUp.getElementsByTagName("div"), 0);
      }
      function staggerElements(elems, entry) {
        if (entry <= 2) {
          setTimeout(() => {
            staggerElements(elems, entry + 1);
          }, 400);
        } else if (entry < 7) {
          setTimeout(() => {
            staggerElements(elems, entry + 1);
          }, 200);
        } else if (entry < 12) {
          setTimeout(() => {
            staggerElements(elems, entry + 1);
          }, 200);
        } else if (entry < 16) {
          setTimeout(() => {
            staggerElements(elems, entry + 1);
          }, 400);
        } else if (entry < 18) {
          setTimeout(() => {
            staggerElements(elems, entry + 1);
          }, 1000);
        } else {
          return;
        }
        elems[entry] ? elems[entry].classList.add("visible") : showClouds();
      }
      function showClouds() {
        startUp.classList.add("hidden");
        startUpWrap.classList.add("hidden");
        clouds.style.display = "block";
        clouds.style.transition = "opacity 0.2s";
        setTimeout(() => {
          clouds.style.opacity = 0;
        }, 3500);
        setTimeout(() => {
          clouds.style.display = "none";
          window.localStorage.setItem("loggedIntoHeaven", "true");
          setLoggedIn(true);
          setGlobal({ turnedOff: false });
        }, 3600);
      }
      setTimeout(loadScreen, 900);
    } else {
      if (document.querySelector("#startup_screen"))
        document
          .querySelector("#main_app")
          .removeChild(document.querySelector("#startup_screen"));
    }
  }, [loggedIn, bluescreen, turnedOff]);

  function formatDate(timestamp) {
    const dateObject = new Date(timestamp * 1000);
    const options = {
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZoneName: "long",
      hour12: false,
    };
    const formattedTime = dateObject.toLocaleString("en-US", options);
    return formattedTime;
  }

  return (
    !loggedIn &&
    !bluescreen &&
    !turnedOff && (
      <Div id="startup_screen">
        <div className="startup_wrap initial">
          <div className="startup">
            <div className="startup_logo"></div>
            <div className="startup_header">
              <span>{userDevice}</span>
              <span>{formatDate(Math.floor(date.current / 1000))}</span>
              <span>HeavenComputerOS v1.3.0</span>
            </div>
            <div className="startup_message">
              <span id="startup_code">0xL1F35P4N</span>
            </div>
            <div className="startup_message">
              <span>{Math.floor(date.current / 1000)}</span>
            </div>
            <div className="startup_message">
              Connection : <span className="green">Online</span>
            </div>
            <div className="startup_message">
              Computer :{" "}
              <span className={desktop ? "green" : "red"}>
                {desktop
                  ? "True"
                  : "False. Please switch to a desktop computer."}
              </span>
            </div>
            <div className="startup_message">
              Platform : <span> {platform}</span>
            </div>
            <div className="startup_message">
              Browser : <span> {navigation}</span>{" "}
              {(isSafari || isIE) && (
                <span className="red">Browser not fully supported.</span>
              )}
            </div>
            <div className="startup_message">
              Battery State :{" "}
              <span>
                {batteryState.isSupported ? (
                  <>
                    {batteryState.charging ? "Charging" : "Not charging"} -{" "}
                    <span
                      className={level > 50 ? "counter green" : "counter red"}
                      data-target={level}
                    >
                      0
                    </span>
                    %
                  </>
                ) : (
                  <>{"Not Supported"}</>
                )}
              </span>
            </div>

            <div className="startup_message">
              User Languages : <span>{langList}</span>
            </div>
            <div className="startup_init">
              Initializing GHOST SOFTWARE..............<span>SUCCESS</span>
            </div>
            <div className="startup_init">
              Initializing GOD OBSERVER................<span>SUCCESS</span>
            </div>
            <div className="startup_init">
              Initializing SKY LUXURY..................<span>SUCCESS</span>
            </div>
            <div className="startup_init footer">Starting HEAVEN COMPUTER...</div>
          </div>
        </div>
        <div className="cloud_wrap">
          <div id="clouds">
            <div className="cloud foreground"></div>
            <div className="cloud background"></div>
            <div className="cloud foreground"></div>
            <div className="cloud background"></div>
            <div className="cloud foreground"></div>
            <div className="cloud background"></div>
            <div className="cloud background"></div>
            <div className="cloud foreground"></div>
            <div className="cloud background"></div>
            <div className="cloud background"></div>
          </div>
        </div>
      </Div>
    )
  );
};

const Div = styled.div`
  touch-action: none;
  cursor: none;
  position: fixed;
  z-index: 10000000;
  display: flex;
  width: 100%;
  height: 100%;
  * {
    cursor: none;
  }
  .red {
    color: #dc143c;
  }
  .green {
    color: #14dc8c;
  }
  .red,
  .green {
    font-weight: bold;
  }
  .counter {
    display: inline-flex;
    white-space: nowrap;
    max-width: calc(6ch + 6px);
    overflow: hidden;
  }
  .startup_header span:nth-child(3) {
    margin-top: 1vw;
  }
  #startup_code {
    color: transparent;
    white-space: nowrap;
  }
  #startup_code:before {
    content: "0xL1FE5P4N";
    width: 100%;
    overflow: hidden;
    text-indent: 0;
    color: white;
    animation: show 2500ms linear infinite;
    animation-delay: 3500ms;
  }
  @keyframes show {
    93% {
      color: transparent;
    }
    94% {
      color: white;
    }
    100% {
      color: white;
    }
  }
  #startup_code:before {
    animation: texte 2500ms linear infinite;
    animation-delay: 3500ms;
  }
  @keyframes texte {
    0 {
      content: "0xL1FE5P4N";
    }
    10% {
      content: "0xKVFZA9QJ";
    }
    20% {
      content: "0xSM9GYHUF";
    }
    30% {
      content: "0x8JVM23ZA";
    }
    50% {
      content: "0xBEU8C9OH";
    }
    60% {
      content: "0xOO9ZC3CZ";
    }
    70% {
      content: "0xA0AE8FUN";
    }
    80% {
      content: "0x56AZT3YL";
    }
    90% {
      content: "0x57S8XX01";
    }
    100% {
      content: "0x67FFG97Z";
    }
  }
  .startup_header {
    margin-right: 95px;
    font-size: calc(7px + 0.2vw);
    span {
      display: block;
    }
  }
  .footer {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
  .startup_message,
  .startup_init {
    font-size: calc(8px + 0.2vw);
  }
  .startup div {
    padding-top: 4px;
  }
  .startup_wrap,
  .cloud_wrap {
    cursor: none;
    font-family: "graph35", monospace;
    line-height: 1.2;
    letter-spacing: 1px;
    position: fixed;
    overflow: hidden;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    display: flex;
    z-index: 10;
  }
  .startup div:nth-child(3),
  .startup div:nth-child(11) {
    margin-top: 3vw;
  }
  .startup {
    display: flex;
    flex-direction: column;
    padding: 30px;
    color: white;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #000000;
  }

  .cloud_wrap {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
      -90deg,
      #a6c7df,
      #ffffff,
      #a6c7df,
      #c9d9ef,
      #a6c7df,
      #ffffff
    );
    background-size: 400% 400%;
    animation: Gradient 5s ease infinite;
    z-index: 1;
    display: none;
  }

  .startup > div {
    visibility: hidden;
  }

  .startup > div.visible {
    visibility: visible;
  }

  .startup_logo {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 10vh;
    height: 10vh;
    background-image: url(${bwcloud});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10vh 10vh;
  }

  .visible {
    visibility: visible;
  }

  .hidden {
    display: none;
  }

  @keyframes Gradient {
    0% {
      background-position: 0%;
    }

    100% {
      background-position: 100%;
    }
  }
  #clouds {
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin: auto;
    overflow: hidden;
    -webkit-animation: FadeIn 3s ease-out;
    animation: FadeIn 3s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  @-webkit-keyframes FadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes FadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .cloud {
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: Float, FadeFloat;
    animation-name: Float, FadeFloat;
    z-index: 1;
  }
  .cloud.foreground {
    height: 10%;
    z-index: 3;
  }
  .cloud.background {
    height: 9.09090909%;
    -webkit-animation-duration: 17.5s;
    animation-duration: 17.5s;
  }
  @-webkit-keyframes Float {
    from {
      transform: translateX(100%) translateY(-50%) translateZ(0);
    }
    50% {
      transform: translateX(55%) translateY(50%) translateZ(0);
    }
    to {
      transform: translateX(-5%) translateY(-10%) translateZ(0);
    }
  }
  @keyframes Float {
    from {
      transform: translateX(100%) translateY(-50%) translateZ(0);
    }
    50% {
      transform: translateX(55%) translateY(50%) translateZ(0);
    }
    to {
      transform: translateX(-5%) translateY(-10%) translateZ(0);
    }
  }
  @-webkit-keyframes FadeFloat {
    0%,
    100% {
      opacity: 0;
    }
    5%,
    90% {
      opacity: 1;
    }
  }
  @keyframes FadeFloat {
    0%,
    100% {
      opacity: 0;
    }
    5%,
    90% {
      opacity: 1;
    }
  }
  .cloud:nth-child(10) {
    -webkit-animation-delay: -15.38461538s;
    animation-delay: -15.38461538s;
    top: 60%;
  }
  .cloud.foreground:nth-child(10) {
    -webkit-animation-duration: -30s;
    animation-duration: -30s;
    height: 35%;
  }
  .cloud.background:nth-child(10) {
    -webkit-animation-duration: -27.5s;
    animation-duration: -27.5s;
    height: -3.40909091%;
  }
  .cloud:nth-child(9) {
    -webkit-animation-delay: -13.84615385s;
    animation-delay: -13.84615385s;
    top: 54%;
  }
  .cloud.foreground:nth-child(9) {
    -webkit-animation-duration: -26s;
    animation-duration: -26s;
    height: 32.5%;
  }
  .cloud.background:nth-child(9) {
    -webkit-animation-duration: -23.5s;
    animation-duration: -23.5s;
    height: -2.15909091%;
  }
  .cloud:nth-child(8) {
    -webkit-animation-delay: -12.30769231s;
    animation-delay: -12.30769231s;
    top: 48%;
  }
  .cloud.foreground:nth-child(8) {
    -webkit-animation-duration: -22s;
    animation-duration: -22s;
    height: 30%;
  }
  .cloud.background:nth-child(8) {
    -webkit-animation-duration: -19.5s;
    animation-duration: -19.5s;
    height: -0.90909091%;
  }
  .cloud:nth-child(7) {
    -webkit-animation-delay: -10.76923077s;
    animation-delay: -10.76923077s;
    top: 42%;
  }
  .cloud.foreground:nth-child(7) {
    -webkit-animation-duration: -18s;
    animation-duration: -18s;
    height: 27.5%;
  }
  .cloud.background:nth-child(7) {
    -webkit-animation-duration: -15.5s;
    animation-duration: -15.5s;
    height: 0.34090909%;
  }
  .cloud:nth-child(6) {
    -webkit-animation-delay: -9.23076923s;
    animation-delay: -9.23076923s;
    top: 36%;
  }
  .cloud.foreground:nth-child(6) {
    -webkit-animation-duration: -14s;
    animation-duration: -14s;
    height: 25%;
  }
  .cloud.background:nth-child(6) {
    -webkit-animation-duration: -11.5s;
    animation-duration: -11.5s;
    height: 1.59090909%;
  }
  .cloud:nth-child(5) {
    -webkit-animation-delay: -7.69230769s;
    animation-delay: -7.69230769s;
    top: 30%;
  }
  .cloud.foreground:nth-child(5) {
    -webkit-animation-duration: -10s;
    animation-duration: -10s;
    height: 22.5%;
  }
  .cloud.background:nth-child(5) {
    -webkit-animation-duration: -7.5s;
    animation-duration: -7.5s;
    height: 2.84090909%;
  }
  .cloud:nth-child(4) {
    -webkit-animation-delay: -6.15384615s;
    animation-delay: -6.15384615s;
    top: 24%;
  }
  .cloud.foreground:nth-child(4) {
    -webkit-animation-duration: -6s;
    animation-duration: -6s;
    height: 20%;
  }
  .cloud.background:nth-child(4) {
    -webkit-animation-duration: -3.5s;
    animation-duration: -3.5s;
    height: 4.09090909%;
  }
  .cloud:nth-child(3) {
    -webkit-animation-delay: -4.61538462s;
    animation-delay: -4.61538462s;
    top: 18%;
  }
  .cloud.foreground:nth-child(3) {
    -webkit-animation-duration: -2s;
    animation-duration: -2s;
    height: 17.5%;
  }
  .cloud.background:nth-child(3) {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    height: 5.34090909%;
  }
  .cloud:nth-child(2) {
    -webkit-animation-delay: -3.07692308s;
    animation-delay: -3.07692308s;
    top: 12%;
  }
  .cloud.foreground:nth-child(2) {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    height: 15%;
  }
  .cloud.background:nth-child(2) {
    -webkit-animation-duration: 4.5s;
    animation-duration: 4.5s;
    height: 6.59090909%;
  }
  .cloud:nth-child(1) {
    -webkit-animation-delay: -1.53846154s;
    animation-delay: -1.53846154s;
    top: 6%;
  }
  .cloud.foreground:nth-child(1) {
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    height: 12.5%;
  }
  .cloud.background:nth-child(1) {
    -webkit-animation-duration: 8.5s;
    animation-duration: 8.5s;
    height: 7.84090909%;
  }
  .cloud {
    background-image: url(${cloud});
  }
  .cloud.background {
    background-image: url(${cloud});
  }
`;

export default StartUp;
