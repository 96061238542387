import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { StartMenu } from "components";
// import RightClickMenu from "./RightClick.js";
import cloud from "assets/icons/cloud.ico";
import desktop from "assets/icons/desktop.ico";
import discord from "assets/icons/discord.ico";
import twitter from "assets/icons/twitter2.ico";
// import { desktopApps } from "apps";

const getTime = () => {
  const date = new Date();
  let hour = date.getHours();
  let hourPostFix = "AM";
  let min = date.getMinutes();
  if (hour >= 12) {
    hour -= 12;
    hourPostFix = "PM";
  }
  if (hour === 0) {
    hour = 12;
  }
  if (min < 10) {
    min = "0" + min;
  }
  if (hour < 10) {
    hour = "0" + hour;
  }
  return `${hour}:${min} ${hourPostFix}`;
};

const TaskBar = ({
  onPointerDownApp,
  apps,
  focusedAppId,
  onPointerDown,
  onClickMenuItem,
  onToggleComponent
}) => {
  const [time, setTime] = useState(getTime);
  const [menuOn, setMenuOn] = useState(false);
  const menu = useRef(null);
  const toggleMenu = useCallback(() => {
    const startBtn = document.getElementById("taskbar_start");
    if (startBtn.classList.contains("menuOn")) {
      startBtn.classList.remove("menuOn");
    } else {
      startBtn.classList.add("menuOn");
    }
    setMenuOn((on) => !on);
  }, []);
  function _onPointerDown(e) {
    if (e.target.closest(".taskbar_window")) return;
    onPointerDown();
  }
  function _onClickMenuItem(name) {
    onClickMenuItem(name);
    toggleMenu();
  }
  function viewDesktop() {
    for (let i = 0; i < apps.length; i++) apps[i].minimized = true;
  }
  useEffect(() => {
    const timer = setInterval(() => {
      const newTime = getTime();
      newTime !== time && setTime(newTime);
    }, 1000);
    return () => clearInterval(timer);
  }, [time]);
  useEffect(() => {
    const target = menu.current;
    if (!target) return;
    function onPointerDown(e) {
      if (
        !target.contains(e.target) &&
        menuOn &&
        e.target.id !== "taskbar_start"
      )
        toggleMenu();
    }
    window.addEventListener("pointerdown", onPointerDown);
    return () => {
      window.removeEventListener("pointerdown", onPointerDown);
    };
  }, [menuOn, toggleMenu]);

  return (
    <Div onPointerDown={_onPointerDown}>
      <div ref={menu} id="taskbar_start_menu">
        {menuOn && <StartMenu onClick={_onClickMenuItem} />}
      </div>
      <div id="taskbar">
      <div id="taskbar_start" onPointerDown={toggleMenu}>
        <img id="taskbar_cloud" draggable={false} alt="favicon" src={cloud} />{" "}
        START
      </div>
      <div className="task_div_bar">
        <div></div>
        <div></div>
      </div>
      <div id="desktop_icon" onPointerDown={viewDesktop}>
        <img draggable={false} alt="desktop" src={desktop} />
      </div>
      <div className="task_div_bar">
        <div></div>
        <div></div>
      </div>
      <div id="taskbar_windows">
      {[...apps].map(
        (app) =>
          !app.error && (
            <TaskBarTab
              key={app.id}
              id={app.id}
              icon={app.header.icon}
              title={app.header.title}
              onPointerDown={onPointerDownApp}
              focused={focusedAppId === app.id}
              onToggleComponent={onToggleComponent}
            />
          )
      )}
      </div>
      <div id="taskbar_end">
        <div className="task_div_bar">
          <div></div>
        </div>

        <div id="taskbar_time" title={time}>
          <a
            className="pointer"
            href="https://twitter.com/heaven_computer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="pointer"
              draggable={false}
              src={twitter}
              alt="twitter"
              title="Twitter"
            />
          </a>
          <a
            className="pointer"
            href="https://discord.gg/heavencomputer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="pointer"
              draggable={false}
              src={discord}
              alt="discord"
              title="Discord"
            />
          </a>
          <span>{time}</span>
        </div>
      </div>
      </div>
    </Div>
  );
};

const TaskBarTab = ({ id, icon, title, onPointerDown, focused, onToggleComponent }) => {
  function _onPointerDown() {
    onPointerDown(id);
  }
  
  // const [offset, setOffset] = useState({x: 0, y: 0});
  // // const openRightClickMenu = () => {
  // //
  // // }
  //
  // useEffect(() => {
  //   const onRightClick = (e) => {
  //       e.preventDefault();
  //       // console.log(e.clientX, e.clientY);
  //       const rightclickmenu =   document.querySelector("#taskbar_rightclick");
  //       const taskbarWindowElement = e.target.closest(".taskbar_window");
  //       if (!taskbarWindowElement) return;
  //       const textContent = taskbarWindowElement.textContent.trim();
  //       // console.log(e.offsetX, e.offsetY);
  //       setOffset({y: e.clientX, x: e.clientY});
  //       // rightclickmenu.style.display = 'flex';
  //       // const foundSetting = desktopApps.find(setting => setting.header.title === textContent || setting.header.alt === textContent);
  //       // if (foundSetting) {
  //       //     // onToggleComponent(textContent, "close", id);
  //       //     // console.log("Found setting:", foundSetting);
  //       // } else {
  //       //     // console.log("No setting found for text:", textContent);
  //       // }
  //   };
  //
  //   window.addEventListener("click", onRightClick);
  //   return () => {
  //     window.removeEventListener("click", onRightClick);
  //   };
  // }, [onToggleComponent, id]);
// <RightClickMenu id={"taskbar_rightclick"} options={['option a', 'option b']} top={offset.x - 100} left={offset.y} />
  return (
    <div
      onPointerDown={_onPointerDown}
      className={`taskbar_window ${focused ? "focus" : "cover"}`}
    >

      <div className="taskbar_icon">
        <img draggable={false} src={icon} alt={title} />
      </div>
      <div className="taskbar_text">{title}</div>
    </div>
  );
};

const Div = styled.div`
  #taskbar_windows {
    height: 100%;
    position: relative;
    width: 100%;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
  }
  #taskbar_start_menu {
    position: absolute;
    left: 0;
    bottom: 37px;
    // mix-blend-mode: difference;
    // backdrop-filter: blur(5px);
  }
  #taskbar {
    position: fixed;
    width: 100%;
    height: 37px;
    border: none;
    outline: none;
    padding: 4px;
    background: var(--colorgrad3);
    // mix-blend-mode: difference;
    // backdrop-filter: blur(5px);
    box-shadow: inset -1px -1px 0 0 var(--colorgrad7),
      inset 1px 1px 0 0 var(--colorgrad0), inset -2px -2px 0 0 var(--colorgrad5),
      inset 2px 2px 0 0 var(--colorgrad2);
    bottom: 0;
    display: flex;
    align-items: center;
  }
  #desktop_icon {
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 6px;
  }
  #taskbar_cloud {
    pointer-events: none;
  }
  .taskbar_window,
  #taskbar_start {
    box-shadow: inset -1px -1px 0 0 var(--colorgrad7),
      inset 1px 1px 0 0 var(--colorgrad0), inset -2px -2px 0 0 var(--colorgrad5),
      inset 2px 2px 0 0 var(--colorgrad2);
  }
  @media (hover: hover) {
    #desktop_icon:hover {
      box-shadow: inset -1px -1px 0 0 var(--colorgrad7),
        inset 1px 1px 0 0 var(--colorgrad0),
        inset -2px -2px 0 0 var(--colorgrad5),
        inset 2px 2px 0 0 var(--colorgrad2);
    }
  }
  #taskbar_start {
    font-family: "changa", sans-serif;
    font-weight: bold;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    padding: 4px 10px;
    font-size: 14px;
    position: relative;
    letter-spacing: 1px;
    margin-right: 2px;
    gap: 4px;
  }
  #desktop_icon img,
  #taskbar_start img {
    width: 16px;
    height: 16px;
  }
  #taskbar_end {
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 1;
  }
  #taskbar_time {
    width: min-content;
    display: flex;
    color: var(--black);
    height: 100%;
    overflow: hidden;
    font-size: 11px;
    font-weight: lighter;
    text-shadow: none;
    gap: 8px;
    padding: 0 8px;
    a, span {
      display: flex;
      align-self: center;
      justify-self: center;
    }
    span {
      display: inline;
      max-width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  #taskbar_time img {
    width: 16px;
    aspect-ratio: 1;
  }
  .task_div_bar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100%;
    margin: 0 2px;
  }
  .task_div_bar div:first-child {
    height: 100%;
    width: 1px;
    margin: 2px;
    background-color: var(--colorgrad6);
    content: "";
    box-shadow: 1px 0px 0px var(--colorgrad0);
  }
  .task_div_bar div:nth-child(2) {
    height: calc(100% - 6px);
    width: 3px;
    margin-left: 2px;
    background-color: var(--colorgrad3);
    content: "";
    box-shadow: inset -1px -1px 0px var(--colorgrad6),
      inset 1px 1px 0px var(--colorgrad0);
  }
  .taskbar_window {
    background: var(--colorgrad3);
    overflow: hidden;
    flex-grow: 1;
    max-width: 150px;
    padding: 0 8px;
    color: var(--black);
    height: 100%;
    font-size: 11px;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 4px;
  }
  .taskbar_window + .taskbar_window {
    margin-left: 3px;
  }
  .taskbar_icon {
    display: flex;
  }
  .taskbar_icon img {
    width: 16px;
    max-width: 100%;
    aspect-ratio: 1;
  }
  .taskbar_text {
    position: absolute;
    left: 30px;
    right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .taskbar_window.cover:active,
  .taskbar_window.focus:active {
    outline: 1px dotted #000000;
    outline-offset: -5px;
  }
  .taskbar_window.focus,
  #desktop_icon:active,
  #taskbar_start.menuOn,
  #taskbar_time {
    background-color: var(--colorgrad3);
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0),
      inset 1px 1px 0 0 var(--colorgrad7), inset -2px -2px 0 0 var(--colorgrad2),
      inset 2px 2px 0 0 var(--colorgrad3);
  }
`;

export default TaskBar;
