export const ShuffleIcon = (props) => (
  <svg
    className={props.className}
    fill={props.fill}
    width="12px"
    height="12px"
    viewBox="0 0 1200 1200"
    enableBackground="new 0 0 1200 1200"
    space="preserve"
  >
    <path
      id="path18074"
      d="M935.926,42.203v186.061H763.958c-54.408,0-114.484,26.559-164.729,77.32
  	c-50.242,50.761-104.842,126.065-191.527,249.904c-87.076,124.394-135.567,199.565-165.807,233.346
  	c-30.24,33.78-25.376,30.882-69.388,30.882H2.08H0v147.863h2.08h170.427c66.078,0,132.54-27.619,179.515-80.093
  	c46.975-52.475,91.312-125.164,176.742-247.208c85.82-122.601,140.381-195.159,175.512-230.651
  	c35.129-35.491,36.641-33.5,59.685-33.5h171.967v194.147L1200,306.276L935.926,42.203z M0,228.263v147.863h2.08h170.427
  	c44.012,0,39.148-2.975,69.388,30.805c19.456,21.734,51.507,67.826,91.49,125.915c5.419-7.773,7.973-11.521,13.708-19.716
  	c21.78-31.114,41.563-59.187,59.838-84.79c6.36-8.91,11.688-15.939,17.714-24.259c-27.021-39.039-49.525-70.001-72.623-95.803
  	c-46.975-52.474-113.437-80.015-179.515-80.015H2.079H0L0,228.263z M935.926,629.727v189.988H763.958
  	c-23.043,0-24.554,1.915-59.684-33.577c-23.237-23.477-56.146-65.093-99.809-124.76c-5.281,7.49-9.555,13.418-15.095,21.333
  	c-30.571,43.674-51.648,75.183-73.777,107.816c31.395,41.578,58.12,73.875,83.637,99.652
  	c50.242,50.763,110.319,77.397,164.729,77.397h171.968v190.22L1200,893.801L935.926,629.727z"
    />
  </svg>
);

export const PlayIcon = (props) => (
  <svg
    className={props.className}
    fill={props.fill}
    width="13px"
    height="13px"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon points="96 448 416 256 96 64 96 448" />
  </svg>
);
export const PauseIcon = (props) => (
  <svg
    className={props.className}
    fill={props.fill}
    width="14px"
    height="14px"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M224,432H144V80h80Z" />
    <path d="M368,432H288V80h80Z" />
  </svg>
);
export const MuteIcon = (props) => (
  <svg
    className={props.className}
    width="16px"
    height="16px"
    viewBox="0 0 512 512"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon points="237.65 176.1 144 176.1 144 335.9 237.65 335.9 368 440 368 72 237.65 176.1" />
  </svg>
);
export const UnmuteIcon = (props) => (
  <svg
    className={props.className}
    width="16px"
    height="16px"
    fill={props.fill}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon points="157.65 176.1 64 176.1 64 335.9 157.65 335.9 288 440 288 72 157.65 176.1" />
    <path
      d="M352,320c9.74-19.41,16-40.81,16-64,0-23.51-6-44.4-16-64"
      style={{
        fill: "none",
        stroke: props.fill,
        strokeLinecap: "square",
        strokeLinejoin: "round",
        strokeWidth: "32px",
      }}
    />
    <path
      d="M400,368c19.48-34,32-64,32-112s-12-77.7-32-112"
      style={{
        fill: "none",
        stroke: props.fill,
        strokeLinecap: "square",
        strokeLinejoin: "round",
        strokeWidth: "32px",
      }}
    />
  </svg>
);

export const StopIcon = (props) => (
  <svg
    className={props.className}
    fill={props.fill}
    width="12px"
    height="12px"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="80" y="80" width="352" height="352" />
  </svg>
);
export const FFIcon = (props) => (
  <svg
    className={props.className}
    width="11px"
    height="11px"
    viewBox="0 0 512 512"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon points="16 400 256 256 16 112 16 400" />
    <polygon points="256 400 496 256 256 112 256 400" />
  </svg>
);
export const FRIcon = (props) => (
  <svg
    className={props.className}
    width="11px"
    height="11px"
    viewBox="0 0 512 512"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon points="496 400 256 256 496 112 496 400" />
    <polygon points="256 400 16 256 256 112 256 400" />
  </svg>
);
