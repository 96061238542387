import React, { useEffect } from "react";
import Sketch from "react-p5";
import styled from "styled-components";
import { useIdle } from "react-use";
import icon from "assets/icons/cloud_bw.ico";

const Container = styled.div`
  touch-action: none;
  object-fit: cover;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: black;
  * {
    object-fit: cover;
    cursor: none;
  }
`;

const Screensaver = () => {
  let x;
  let y;
  let xspeed;
  let yspeed;
  let cloud;
  let r, g, b;
  const isIdle = useIdle(40e3);
  const root = document.querySelector(":root").style;
  const zoom = root.getPropertyValue("--zoom");
  const width = zoom
    ? (window.innerWidth * 1) / zoom
    : window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const height = zoom
    ? (window.innerHeight * 1) / zoom
    : window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  const preload = (p5) => {
    cloud = p5.loadImage(icon);
  };

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(width, height).parent(canvasParentRef);
    x = p5.random(width);
    y = p5.random(height);
    xspeed = 5;
    yspeed = 5;
    pickColor(p5);
  };

  const pickColor = (p5) => {
    r = p5.random(100, 256);
    g = p5.random(100, 256);
    b = p5.random(100, 256);
  };

  const draw = (p5) => {
    p5.background(0);
    p5.tint(r, g, b);
    p5.image(cloud, x, y);

    x = x + xspeed;
    y = y + yspeed;

    if (x + cloud.width >= width) {
      xspeed = -xspeed;
      x = width - cloud.width;
      pickColor(p5);
    } else if (x <= 0) {
      xspeed = -xspeed;
      x = 0;
      pickColor(p5);
    }

    if (y + cloud.height >= height) {
      yspeed = -yspeed;
      y = height - cloud.height;
      pickColor(p5);
    } else if (y <= 0) {
      yspeed = -yspeed;
      y = 0;
      pickColor(p5);
    }
  };

  useEffect(() => {
    const windows = document.querySelectorAll(".window")
    if (windows && windows.length > 0) {
      isIdle ? windows.forEach(window => window.parentNode.style.visibility = 'hidden') : windows.forEach(window => window.parentNode.style.visibility = 'visible')
    }
  }, [isIdle]);

  return (
    isIdle && (
      <Container className="screensaver">
        <Sketch setup={setup} draw={draw} preload={preload} />
      </Container>
    )
  );
};

export default Screensaver;
