import React, { useGlobal, useEffect, useState } from "reactn";
import styled from "styled-components";
import { useComboKey } from "hooks";

const sequence = ["Control", "Alt", "Delete"];

const BSoD = () => {
  const [bluescreen] = useGlobal("bluescreen");
  const [keysPressed, setKeysPressed] = useState(false);

  const handleMatch = () => setKeysPressed(true);

  useComboKey(sequence, handleMatch);

  useEffect(() => {
    if (keysPressed) {
      if (window.localStorage.getItem("loggedIntoHeaven")) {
        window.localStorage.removeItem("loggedIntoHeaven");
      }
      window.location.reload();
    }
  }, [keysPressed]);

  useEffect(() => {
    if (bluescreen && window.localStorage.getItem("loggedIntoHeaven")) {
      window.localStorage.removeItem("loggedIntoHeaven");
    }
  }, [bluescreen]);

  return (
    bluescreen && (
      <Div>
        <div className="bsod">
          <div className="lost_wrap centered">
            <div className="lost">BSoR</div>
            <div className="shadow"></div>
          </div>
          <div className="row">A fatal exception 404 has occurred at C0DE:D34TH in 0xL1F35P4N.</div>
          <div className="row">Alas! The current life session must be terminated.</div>
          <div className="row">&nbsp;</div>
          <div className="row centered"><h2>Blue Screen of Resurrection</h2></div>
          <div className="row">&nbsp;</div>
          <div className="row">* The computer ran into an error and needs to be resurrected.</div>
          <div className="row">* Press <strong>CTRL + ALT + DEL</strong> to resurrect.</div>
          <div className="row">* You will lose any unsaved information in all applications.</div>
          <div className="row">&nbsp;</div>
          <div className="row centered">Resurrect computer to continue<span className="blink">&#9611;</span></div>
        </div>
      </Div>
    )
  );
};

const Div = styled.div`
  touch-action:none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100000;
  overflow: hidden;
  background-color: #000084;
  .centered {
    align-self: center;
  }
  * {
    cursor: none;
  }
  strong {
    font-weight: 900;
  }
  .bsod {
    text-align: left;
    padding: 30px;
    display: flex;
    flex-flow: column;
    font-family: 'graph35', monospace;
    font-size: 1.4vh;
    line-height: 1.1;
    letter-spacing: 3px;
    color: #bbb;
    overflow: hidden;
    & div {
      padding-top: 6px;
    }
  }

.lost {
    border: 1px solid black;
    background-color: #bbb;
    color: #000084;
    width: 8ch;
    height: 4ch;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.lost_wrap{
  margin-bottom: 40px;
}

.shadow {
    position: relative;
    top: 1ch;
    left: 1ch;
    background-color: black;
    color: #000084;
    width: 8ch;
    height: 4ch;
    z-index: 1;
}

.blink {
  margin: 0 6px;
    animation: blinkingText .8s infinite;
}

@keyframes blinkingText {
    0% {
        opacity: 0;
    }

    49% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}
`;

export default BSoD;
