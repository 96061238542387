import programs from "assets/icons/programs.ico";

const desktopList = [
  "MyComputer",
  "Metamask",
  "SkyLuxury",
  "GodObserver",
  "GhostSoftware",
  "GodRemixer",
  "CDCollection",
  "Documents",
  "Notepad",
  "Calculator",
  "Mall",
  "RecycleBin",
];
const myComputerList = [
  "GhostSoftware",
  "GodObserver",
  "SkyLuxury",
  "CDCollection",
  "GodRemixer",
  "GradientGraphics",
  "Metamask",
  "Notepad",
  "Calculator",
  "Ghostsweeper",
  "Soulitaire",
  "Mall",
  "Documents",
  "DiskDrive",
  "Help",
  "Settings",
];
const myDocumentsList = [
  "GhostSoftware",
  "GodObserver",
  "SkyLuxury"
];
const diskDriveList = ["Mall"];
const falseFocus = (icons) =>
  icons.map((component, id) => ({ component, id, focused: false }));
export const desktopIcons = falseFocus(desktopList);
export const myComputerIcons = falseFocus(myComputerList);
export const myDocumentsIcons = falseFocus(myDocumentsList);
export const diskDriveIcons = falseFocus(diskDriveList);
export const recycleBinIcons = [];
export const startMenuIcons = [
  {
    type: "menu",
    text: "Games",
    icon: programs,
    items: [
      {
        type: "item",
        text: "Ghostsweeper",
      },
      {
        type: "item",
        text: "Soulitaire",
      },
      {
        type: "item",
        text: "Mall",
      },
    ],
  },
  {
    type: "item",
    text: "SkyLuxury",
  },
  {
    type: "item",
    text: "GodObserver",
  },
  {
    type: "item",
    text: "GhostSoftware",
  },
  {
    type: "item",
    text: "GradientGraphics",
  },
  {
    type: "item",
    text: "GodRemixer",
  },
  {
    type: "item",
    text: "CDCollection",
  },
  {
    type: "item",
    text: "Notepad",
  },
  {
    type: "item",
    text: "Calculator",
  }
];