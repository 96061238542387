import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import OptionsMenu from './OptionsMenu';

const Options = ({
  items,
  onClickItem,
  className,
  height = 20,
}) => {
  const dropDown = useRef(null);
  const [openOption, setOpenOption] = useState('');
  function hoverOption(option) {
    if (openOption) setOpenOption(option);
  }
  function _onClickItem(name) {
    setOpenOption('');
    onClickItem(name);
  }
  function onPointerUp(e) {
    if (dropDown.current !== null && !dropDown.current.contains(e.target)) setOpenOption('');
  }
  useEffect(() => {
    window.addEventListener('pointerup', onPointerUp);
    return () => {
      window.removeEventListener('pointerup', onPointerUp);
    };
  }, []);
  return (
    <div className={className} ref={dropDown}>
      {Object.keys(items).map(name => (
        <div className="options-menu" key={name}>
          <div
            key={name}
            onPointerDown={() => {
              setOpenOption(name);
            }}
            onPointerEnter={() => hoverOption(name)}
            className={`options-menu_label ${
              openOption === name ? 'options-menu_label--active' : ''
            }`}
          >
            {name}
          </div>
          {openOption === name && (
            <OptionsMenu
              onClick={_onClickItem}
              items={items[name]}
              position={{ top: `${height}px` }}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default styled(Options)`
  display: flex;
  height: ${({ height }) => height || 20}px;
  line-height: ${({ height }) => height || 20}px;
  position: relative;
  flex-direction: row;
  width: 100%;
  z-index: 30;
  .options-menu {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .options-menu_label--active {
    background-color: #266EBB;
    color: #fff;
  }
  .options-menu_label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 6px;
    &:hover {
      background-color: #266EBB;
      color: #fff;
    }
  }
`;
