import React, { useGlobal, useState, useEffect } from "reactn";
import { SkyLuxury, Help } from "apps";
import styled from "styled-components";
import sky from "assets/icons/sky.ico";

const SkyWindow = () => {
  const [appLoading] = useGlobal("appLoading");
  const [view, setView] = useState(null);
  const [helpView, setHelpView] = useState("Website");
  const [help, setHelp] = useState(false);

  function onClose() {
    if (help) {
      window.history.pushState("", "", "/");
      setHelp(false);
    } else {
      window.location.href = "https://heaven.computer";
    }
  }

  function onHelp(v) {
    setHelpView(v);
    window.history.pushState("", "", "/help");
    setHelp(true);
  }

  useEffect(() => {
    let path = window.location.pathname;
    if (path === "/alltokens") {
      setView("All Tokens");
    } else if (path.startsWith("/token/")) {
      const token = path.match(/\/token\/([^/]+)/);
      if (token && token[1]) {
        setView("token?" + token[1]);
      } else {
        setView("token?");
      }
    } else if (path.startsWith("/user/")) {
      const user = path.match(/\/user\/([^/]+)/);
      if (user && user[1]) {
        setView("user?" + user[1]);
      } else {
        setView("user?");
      }
    } else if (path === "/help") {
      setHelpView("Website");
      window.history.pushState("", "", "/help");
      setHelp(true);
    } else if (path.startsWith("/sky/")) {
      const token = path.match(/\/sky\/([^/]+)/);
      if (token && token[1]) {
        setView("sky?" + token[1]);
      } else {
        setView("sky?");
      }
    } else {
      setView("Lobby");
    }
  }, []);

  return (
    <Div>
      <div className="window">
        <header className="window_header">
          <img alt="favicon" src={sky} className="window_header_icon" />
          <div className="window_header_title">Sky Luxury</div>
          {!help && <button
            key="help"
            onPointerUp={() => { onHelp("Website") }}
            className="header_button header_button--help"
          />}
          <button
            key="close"
            onPointerUp={onClose}
            className="header_button header_button--close"
          />
        </header>
        {help ? (
          <Help onClose={onClose} helpView={helpView} />
        ) : (
          <SkyLuxury
            standAlone={true}
            onClose={onClose}
            onHelp={onHelp}
            windowView={view}
          />
        )}
        <section className="window_statusbar">
          <div>
            {appLoading
              ? "Please wait."
              : "Done"}
          </div>
          <div>
            <p>
              {appLoading
                ? "Loading..."
                : ""}
            </p>
          </div>
        </section>
      </div>
    </Div>
  );
};

const Div = styled.div`
  -webkit-touch-callout:none;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
  touch-action: pinch-zoom;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  background: var(--colorgrad3);
  padding: 1px;
  box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad2);
  flex-direction: column;
  font-size: 11px;
  line-height: 11px;
  *:not(input):not(textarea):not(p):not(span):not(a):not(td) {
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
  }

  .header_button {
    height: 18px;
    width: 18px;
    outline: none;
    color: var(--colorgrad7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 3px;
    padding: 0;
    border: 0;
    background: var(--colorgrad3);
    box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad0),
      inset -2px -2px 0 0 var(--colorgrad5), inset 2px 2px 0 0 var(--colorgrad2);
  }
  .header_button--help:after {
    content: "?";
    align-self: center;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .header_button:active {
    background-color: var(--colorgrad3);
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
  .header_button--minimize:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 3px;
    width: 8px;
    height: 2px;
    background: var(--colorgrad7);
  }
  .header_button--maximize:after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid var(--colorgrad7);
    border-top-width: 2px;
  }
  .header_button--close:before,
  .header_button--close:after {
    transform-origin: center center;
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 12px;
    background: var(--colorgrad7);
  }
  .header_button--close:before {
    transform: rotateZ(-45deg);
  }
  .header_button--close:after {
    transform: rotateZ(45deg);
  }
  .window {
    width: 100%;
    height: 100%;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad5), inset 1px 1px 0 0 var(--colorgrad0);
    background: var(--colorgrad3);
    padding: 4px;
    display: flex;
    flex-direction: column;
  }
  @keyframes type {
    from {
      width: 0;
    }
  }
  .window_header {
    padding: 1px;
    background: linear-gradient(90deg, #f5c8bf, #fcd7d1, #f9e4e0, #f5c8bf);
    height: 22px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    color: white;
    font-weight: 700;
  }
  .window_header_icon {
    pointer-events: none;
    height: 16px;
    margin-left: 3px;
  }
  .window_header_title {
    flex: 1;
    pointer-events: none;
    padding: 5px;
    letter-spacing: 1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .window_statusbar {
    display: flex;
  }
  .window_statusbar p {
    height: 20px;
    width: 17ch;
    animation: type 4s steps(17, end) infinite;
    overflow: hidden;
  }
  .window_statusbar div:first-child {
    white-space: nowrap;
    overflow: hidden;
    margin-top: 3px;
    margin-right: 1.5px;
    padding: 5px;
    height: 20px;
    width: 50%;
    background-color: var(--colorgrad3);
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
  .window_statusbar div:nth-child(2) {
    margin-top: 3px;
    margin-left: 1.5px;
    padding: 5px;
    height: 20px;
    width: 50%;
    background-color: var(--colorgrad3);
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
  .window_statusbar::after {
    position: absolute;
    bottom: 6px;
    right: 6px;
    height: 12px;
    width: 12px;
    content: "";
    background-image: url("data:image/gif;base64,R0lGODlhDAAMAJEAAAAAAP///5mZmf///yH5BAEAAAMALAAAAAAMAAwAAAIbnI8TmSF83IMSKvFWw3dnHnFV+GVGhZZXmaoFADs=");
  }
`;

export default SkyWindow;
