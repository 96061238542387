import React from "react";

const Range = (props: {
  name: string,
  label: string,
  types: { range: [number, number] },
  value: number,
  step: ?number,
  onSetFilterOption: (string, any) => void
}) => (

  <div className="parts_menu filter_slider">
    <span className="slider_label">{props.label}</span>
    <input
      className="slider longerSlider"
      type="range"
      min={props.types.range[0]}
      max={props.types.range[1]}
      value={props.value}
      step={props.step || 1}
      onChange={e =>
        props.onSetFilterOption(props.name, parseFloat(e.target.value))
      }
    />
  </div>
);

export default Range;
