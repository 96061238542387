import React from "react";
import styled from "styled-components";

const Sky6 = () => {
	return (
		<Div className="skysection6 content animate selectable">
			<h3>Make a splash in high society with our infinity* pool.</h3>
			<p>Its tranquil, crystalline waters render lifelike caustics, refractions, and fluid dynamics, designed to wash your sins away as you plunge into the highest echelon of magnificence SkyLuxury has to offer.
			</p>
			<p>Like its waters, the marble borders of this baptismal pool are made with <strong>SVG filter primitives.</strong> A combination of turbulence, generative seeds and filter composites are used to create the unique veining, refined texture and durability that only the finest digital marble has to offer.</p>
			<h3>A baptismal basin for the digital elite.</h3>
			<p>The caveat?
				<br />
				Much like true generational wealth, this is reserved for only some <strong>lucky mints.</strong>
			</p>
			<p>Will you be one of the fortunate few?</p>
			<p>*infinitely deep...</p>
		</Div>
	);
};


const Div = styled.div`
	filter: url(#waterRefraction);
`;

export default Sky6;