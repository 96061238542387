const View = [
  {
    type: "item",
    text: "Main",
    disable: true,
  },
  {
    type: "separator",
  },
  {
    type: "item",
    text: "Back",
    disable: true,
  },
  {
    type: "item",
    text: "Forward",
    disable: true,
  },
];
const Links = [
  {
    type: "item",
    text: "Website",
    disable: false,
  },
  {
    type: "item",
    text: "Twitter",
    disable: false,
  },
  {
    type: "item",
    text: "OpenSea",
    disable: false,
  },
  {
    type: "item",
    text: "Etherscan",
    disable: false,
  },
  {
    type: "item",
    text: "Discord",
    disable: false,
  },
];
const Edit = [
  {
    type: "item",
    text: "Select All",
    disable: false,
  },
  {
    type: "item",
    text: "Select None",
    disable: true,
  },
  {
    type: "separator",
  },
  {
    type: "item",
    text: "Refresh",
    disable: true,
  },
  {
    type: "item",
    text: "Close",
    disable: false,
  },
];

const optionsMenuData = { View, Links, Edit };
export default optionsMenuData;
