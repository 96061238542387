import React from "react";
import styled from "styled-components";

const Sky3 = () => {
	return (
		<Div className="skysection3 content animate selectable">
			<div className="stagger animate">
				<p>
					As a sophisticated, affluent and successful professional, you appreciate purity in design and independence. We understand that. That’s why SkyLuxury relies on no dependencies, no libraries, no external assets, and, as a matter of fact, <strong className="animate">no JavaScript at all.</strong>
				</p>
				<p className="animate">
					As a sophisticated, affluent and successful professional, you appreciate purity in design and independence. We understand that. That’s why SkyLuxury relies on no dependencies, no libraries, no external assets, and, as a matter of fact, <strong className="animate">no JavaScript at all.</strong>
				</p>
			</div>

			<p className="stagger animate">
				A feature exclusive to pure HTML and CSS works of art, those who appreciate the finer details are welcome to delve deeper with the <strong>Inspect Element</strong> tool. Built into most modern browsers, it allows you to explore the structure and styling of your residence directly from your browser, providing an insider’s view of the craftsmanship that makes this celestial enterprise truly unique. It’s like having a private tour of a high-end estate, revealing the intricate artistry and technical mastery that define SkyLuxury.
			</p>
			<p className="stagger animate">
				SkyLuxury residences are neither small nor large; they are whatever size you need them to be. Every element of your residence is <strong>fully responsive</strong>, adjusting seamlessly to various screen sizes. Experience elegance and adaptability in every aspect of our digital real estate.
			</p>
		</Div>
	);
};

const Div = styled.div`
	.stagger {
		width: clamp(100px, 90%, 900px);
	}
`;

export default Sky3;