import React, {
  useContext,
  setGlobal,
  useGlobal,
  useEffect,
  useState,
} from "reactn";
import styled from "styled-components";
import { BackgroundContext } from "../../contexts/BackgroundContext";
import lutImages from "./luts";
import Display from "./Display";
import { NumberInput } from "components";
import bg from "assets/bg";
import { isMobile } from "react-device-detect";
import { hslToObject, createColorShades } from "utils";
import { randomizeDefaultOffset, appSettings } from "apps";
// other ideas for SETTINGS

// paint ms
// font selection on notepad
// display stretch cover/fit tile or center
// color palette selection
// overlay filters and gradients
// dither filter
// add icons to desktop (would look like wallpaper selection in display properties)
// toggle god observer title

const Settings = ({ onClose, onToggleComponent, id }) => {
  const [fullscreen] = useGlobal("fullscreen");
  const root = document.documentElement;
  const compStyle = getComputedStyle(root);
  const [hueRotate, setHueRotate] = useState(parseInt(compStyle.getPropertyValue("--hueRotate")));
  const [zoom, setZoom] = useState(parseInt(compStyle.getPropertyValue("--zoom")));
  const [bgImage, setBgImage] = useContext(BackgroundContext);

  useEffect(() => {
    let canvas = document.getElementById("bgCanvas");
    if (canvas) {
      const dataUrl = canvas.toDataURL();
      document.querySelector("#bgSelector").style.backgroundImage =
        "url(" + dataUrl + ")";
    }
    const btn = document.querySelector("#bgresetbtn");
    if (bgImage) {
      if (btn) btn.classList.remove("disabled");
    } else {
      if (btn) btn.classList.add("disabled");
    }
  }, [bgImage]);

  useEffect(() => {
    const root = document.documentElement;
    const compStyle = getComputedStyle(root);
    const deepEqual = (a, b) => a === b || (typeof a === 'object' && a !== null && typeof b === 'object' && b !== null && Object.keys(a).length === Object.keys(b).length && Object.keys(a).every(key => deepEqual(a[key], b[key])));

    // theme color
    const themeColor = hslToObject(compStyle.getPropertyValue("--colorgrad3"));
    const initThemeColor = hslToObject(compStyle.getPropertyValue("--initThemeColor"));
    document.querySelector("#themeColorSelector").style.background = `hsl(${themeColor.h}, ${themeColor.s}%, ${themeColor.l}%)`;
    deepEqual(themeColor, initThemeColor) ? document.querySelector("#themeresetbtn").classList.add("disabled") : document.querySelector("#themeresetbtn").classList.remove("disabled");

    // header color
    const headerColor = hslToObject(compStyle.getPropertyValue("--headerColor"));
    const initHeaderColor = hslToObject(compStyle.getPropertyValue("--initHeaderColor"));
    document.querySelector("#headerColorSelector").style.background = `hsl(${headerColor.h}, ${headerColor.s}%, ${headerColor.l}%)`;
    deepEqual(headerColor, initHeaderColor) ? document.querySelector("#headerresetbtn").classList.add("disabled") : document.querySelector("#headerresetbtn").classList.remove("disabled");
  }, []);

  const editColors = (type) => {
    onToggleComponent("Edit Colors", "open", id, type);
  };

  const toggleFullscreen = () => {
    fullscreen
      ? setGlobal({ fullscreen: false })
      : setGlobal({ fullscreen: true });
  };

  const uploadBg = (img) => {
    setBgImage(img);
  };

  const randomBg = () => {
    setBgImage(lutImages[Math.floor(Math.random() * lutImages.length)]);
  };

  const resetBg = () => {
    setBgImage(null);
    document.querySelector("#bgSelector").style.backgroundImage = "url(" + bg + ")";
    document.querySelector("#bgresetbtn").classList.add("disabled");
  };

  const resetHeaderColor = () => {
    const root = document.documentElement;
    const compStyle = getComputedStyle(root);

    // reset selector
    const initHeaderColor = hslToObject(compStyle.getPropertyValue("--initHeaderColor"));
    const { h, s, l } = initHeaderColor;
    document.querySelector("#headerColorSelector").style.background = `hsl(${h}, ${s}%, ${l}%)`;
    document.querySelector("#headerresetbtn").classList.add("disabled");

    // reset color
    root.style.setProperty("--headerColor", "var(--initHeaderColor)");
  }

  const resetThemeColor = () => {
    const root = document.documentElement;
    const compStyle = getComputedStyle(root);

    // reset selector
    const initThemeColor = hslToObject(compStyle.getPropertyValue("--initThemeColor"));
    const { h, s, l } = initThemeColor;
    document.querySelector("#themeColorSelector").style.background = `hsl(${h}, ${s}%, ${l}%)`;
    document.querySelector("#themeresetbtn").classList.add("disabled");

    // reset color
    root.style.setProperty("--black", "black");
    let shades = createColorShades(Object.values(initThemeColor), 9);
    shades.forEach((shade, index) => {
      root.style.setProperty(`--colorgrad${index}`, shade);
    });
  };

  const handleZoom = (e) => {
    let z = e / 100;
    root.style.setProperty("--zoom", z);
    randomizeDefaultOffset(appSettings, true, z);
    setZoom(z);
  };

  return (
    <Div>
      <div className="upper_setting">
        <section className="box_section">
          <p>
            Enhance your HeavenComputer experience by customizing the settings
            below.
          </p>
        </section>
        <section className="box_section">
          <div className="box_section_title">
            <span>Background</span>
          </div>
          <Display randomBg={randomBg} uploadBg={uploadBg} resetBg={resetBg} />
        </section>
      </div>
      <div className="lower_setting">
        <section className="box_section">
          <div className="box_section_title">
            <span>Colors</span>
          </div>
          <div className="set_buttons_row">
            <div
              className="pointer"
              id="themeColorSelector"
              onPointerUp={() => {
                editColors("themeColor");
              }}
            />
            <div
              id="themeeditbtn"
              className="button"
              onPointerUp={() => {
                editColors("themeColor");
              }}
            >
              Edit
            </div>
            <div
              id="themeresetbtn"
              className="button"
              onPointerUp={() => {
                resetThemeColor();
              }}
            >
              Reset
            </div>
          </div>
          <div className="set_buttons_row">
            <div
              className="pointer"
              id="headerColorSelector"
              onPointerUp={() => editColors("headerColor")}
            />
            <div
              id="headereditbtn"
              className="button"
              onPointerUp={() => editColors("headerColor")}
            >
              Edit
            </div>
            <div
              id="headerresetbtn"
              className="button"
              onPointerUp={() => {
                resetHeaderColor();
              }}
            >
              Reset
            </div>
          </div>
          <input
            value={hueRotate ? hueRotate : "0"}
            className="slider"
            id="huerotate_slider"
            type="range"
            min="0"
            max="360"
            step="1"
            onChange={(e) => {
              root.style.setProperty("--hueRotate", e.target.value + "deg");
              setHueRotate(e.target.value);
            }}
          />
        </section>


        <section className="box_section">
          <div className="box_section_title">
            <span>Zoom</span>
          </div>
          <div>
            <NumberInput
              min={50}
              max={150}
              step={5}
              handleInput={handleZoom}
              id="zoom_input"
              defaultValue={zoom * 100}
              unit={"%"}
            />
            {!isMobile && (
              <>
                <form>
                  <input
                    type="checkbox"
                    id="fullscreen"
                    checked={fullscreen}
                    onChange={() => toggleFullscreen()}
                  />
                  <label htmlFor="fullscreen">
                    <span>Fullscreen</span>
                  </label>
                </form>
              </>
            )}
          </div>
        </section>
      </div>
    </Div>
  );
};

const Div = styled.div`
  background: var(--colorgrad3);
  padding: 17px 12px 12px 12px;
  height: 460px;
  .slider {
    width: 100%;
  }
  #huerotate_slider {
    margin-bottom: -10px;
    height: 21px;
    background: linear-gradient(
      to right,
      hsl(0, 98%, 68%),
      hsl(60, 98%, 68%),
      hsl(120, 98%, 68%),
      hsl(180, 98%, 68%),
      hsl(240, 98%, 68%),
      hsl(300, 98%, 68%),
      hsl(360, 98%, 68%)
    );
  }
  input[type="range"]::-webkit-slider-thumb {
    width: 10px;
    height: 24px;
  }
  .upper_setting {
    & .box_section + .box_section {
      margin-top: 15px;
    }
  }
  .zoom_input {
    height: 21px;
  }
  .lower_setting {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    > :nth-child(1) {
      min-width: 65%;
    }
    > :nth-child(2) > :nth-child(2) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    & .box_section + .box_section {
      margin-left: 15px;
    }
  }
  .drop_wrap {
    margin-top: 10px;
  }
  i {
    font-style: italic;
  }
  p {
    line-height: 1.3;
  }
  .button {
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 9px;
  }
  .set_buttons_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .button + .button {
      margin-left: 6px;
    }
  }
  .set_buttons_row + .set_buttons_row {
    padding-top: 10px;
  }
  #headerColorSelector,
  #themeColorSelector {
    height: 21px;
    display: flex;
    min-width: 60px;
    margin-right: 6px;
    flex-grow: 1;
    box-shadow: inset -1px -1px 0px var(--colorgrad0),
      inset 1px 1px 0px 0px var(--colorgrad6),
      inset -2px -2px 0px var(--colorgrad4),
      inset 2px 2px 0px 0px var(--colorgrad8);
  }
  #bgSelector {
    background-size: cover;
    background-position: 50%;
  }
  .box_section {
    & p {
      white-space: normal !important;
    }
  }
`;

export default Settings;
