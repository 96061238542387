const hiDrums = [
  require("assets/remixer/drum/hi/0.wav"),
  require("assets/remixer/drum/hi/1.wav"),
  require("assets/remixer/drum/hi/2.wav"),
  require("assets/remixer/drum/hi/3.wav"),
  require("assets/remixer/drum/hi/4.wav"),
  require("assets/remixer/drum/hi/5.wav"),
  require("assets/remixer/drum/hi/6.wav"),
  require("assets/remixer/drum/hi/7.wav"),
  require("assets/remixer/drum/hi/8.wav"),
  require("assets/remixer/drum/hi/9.wav"),
  require("assets/remixer/drum/hi/10.wav"),
  require("assets/remixer/drum/hi/11.wav"),
  require("assets/remixer/drum/hi/12.wav"),
  require("assets/remixer/drum/hi/13.wav"),
  require("assets/remixer/drum/hi/14.wav"),
  require("assets/remixer/drum/hi/15.wav"),
  require("assets/remixer/drum/hi/16.wav"),
  require("assets/remixer/drum/hi/17.wav"),
  require("assets/remixer/drum/hi/18.wav"),
  require("assets/remixer/drum/hi/19.wav"),
  require("assets/remixer/drum/hi/20.wav"),
  require("assets/remixer/drum/hi/21.wav"),
  require("assets/remixer/drum/hi/22.wav"),
  require("assets/remixer/drum/hi/23.wav"),
  require("assets/remixer/drum/hi/24.wav"),
  require("assets/remixer/drum/hi/25.wav"),
  require("assets/remixer/drum/hi/26.wav"),
  require("assets/remixer/drum/hi/27.wav"),
  require("assets/remixer/drum/hi/28.wav"),
  require("assets/remixer/drum/hi/29.wav"),
  require("assets/remixer/drum/hi/30.wav"),
  require("assets/remixer/drum/hi/31.wav"),
  require("assets/remixer/drum/hi/32.wav"),
  require("assets/remixer/drum/hi/33.wav"),
  require("assets/remixer/drum/hi/34.wav"),
  require("assets/remixer/drum/hi/35.wav"),
  require("assets/remixer/drum/hi/36.wav"),
  require("assets/remixer/drum/hi/37.wav"),
  require("assets/remixer/drum/hi/38.wav"),
  require("assets/remixer/drum/hi/39.wav"),
  require("assets/remixer/drum/hi/40.wav"),
  require("assets/remixer/drum/hi/41.wav"),
  require("assets/remixer/drum/hi/42.wav"),
  require("assets/remixer/drum/hi/43.wav"),
  require("assets/remixer/drum/hi/44.wav"),
  require("assets/remixer/drum/hi/45.wav"),
  require("assets/remixer/drum/hi/46.wav"),
  require("assets/remixer/drum/hi/47.wav"),
  require("assets/remixer/drum/hi/48.wav"),
  require("assets/remixer/drum/hi/49.wav"),
  require("assets/remixer/drum/hi/50.wav"),
  require("assets/remixer/drum/hi/51.wav"),
  require("assets/remixer/drum/hi/52.wav"),
  require("assets/remixer/drum/hi/53.wav"),
  require("assets/remixer/drum/hi/54.wav"),
  require("assets/remixer/drum/hi/55.wav"),
  require("assets/remixer/drum/hi/56.wav"),
  require("assets/remixer/drum/hi/57.wav"),
  require("assets/remixer/drum/hi/58.wav"),
  require("assets/remixer/drum/hi/59.wav"),
  require("assets/remixer/drum/hi/60.wav"),
  require("assets/remixer/drum/hi/61.wav"),
  require("assets/remixer/drum/hi/62.wav"),
  require("assets/remixer/drum/hi/63.wav"),
  require("assets/remixer/drum/hi/64.wav"),
  require("assets/remixer/drum/hi/65.wav"),
  require("assets/remixer/drum/hi/66.wav"),
  require("assets/remixer/drum/hi/67.wav"),
  require("assets/remixer/drum/hi/68.wav"),
  require("assets/remixer/drum/hi/69.wav"),
  require("assets/remixer/drum/hi/70.wav"),
  require("assets/remixer/drum/hi/71.wav"),
];

export default hiDrums;
