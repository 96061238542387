import { RANGE } from "../constants";
import { cloneCanvas } from "../utils";
import lutImages from "../../Settings/luts";
import imageToBase64 from "image-to-base64/browser";

const optionTypes = {
  thresh: { type: RANGE, range: [0, 64], step: 1, default: 64, label: 'threshold' },
  type: { type: RANGE, range: [0, 48], step: 1, default: 0, label: 'type' },
};

const defaults = {
  thresh: optionTypes.thresh.default,
  type: optionTypes.type.default,
};

let imgLut = new Image();
let canvasLut = document.createElement("Canvas");
let ctxLut = canvasLut.getContext("2d", {
  willReadFrequently: true
});

imageToBase64(lutImages[0])
  .then((response) => {
    imgLut.crossOrigin = "Anonymous2";
    imgLut.src = "data:image/png;base64," + response;
  })
  .catch((error) => {
    console.log(error);
  });

imgLut.onload = function () {
  canvasLut.width = imgLut.width;
  canvasLut.height = imgLut.height;
  ctxLut.drawImage(imgLut, 0, 0);
};

const lut = (input, options) => {
  const { thresh, type } = options;
  const output = cloneCanvas(input, false);
  const inputCtx = input.getContext("2d", {
  willReadFrequently: true
});
  const outputCtx = output.getContext("2d", {
  willReadFrequently: true
});

  imageToBase64(lutImages[type])
    .then((response) => {
      imgLut.crossOrigin = "Anonymous2";
      imgLut.src = "data:image/png;base64," + response;
    })
    .catch((error) => {
      console.log(error);
    });

  if (!inputCtx || !outputCtx) {
    return input;
  }

  const buf = inputCtx.getImageData(0, 0, input.width, input.height).data;

  let lutWidth = canvasLut.width;
  let imgData = buf;
  let filterData = ctxLut.getImageData(0, 0, canvasLut.width, canvasLut.height);

  for (let i = 0; i < imgData.length; i += 4) {
    let r = Math.floor(imgData[i] / 4);
    let g = Math.floor(imgData[i + 1] / 4);
    let b = Math.floor(imgData[i + 2] / 4);

    let lutX = (b % 8) * thresh + r;
    let lutY = Math.floor(b / 8) * thresh + g;
    let lutIndex = (lutY * lutWidth + lutX) * 4;

    imgData[i] = filterData.data[lutIndex];
    imgData[i + 1] = filterData.data[lutIndex + 1];
    imgData[i + 2] = filterData.data[lutIndex + 2];
    imgData[i + 3] = 255;
  }
  outputCtx.putImageData(
    new ImageData(imgData, output.width, output.height),
    0,
    0
  );

  return output;
};

const defaultFunc = {
  name: "LUT",
  func: lut,
  optionTypes,
  options: defaults,
  defaults,
};

export default defaultFunc;
