import React, { useEffect, setGlobal, useState, useGlobal } from "reactn";
import { map, createColorShades } from "utils";
import styled from "styled-components";
import lightarrow from "assets/remixer/arrow.png";
import crosshair from "assets/remixer/crosshair.png";
import Colorbar from "./Color";
import Lightbar from "./Light";
import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import { HSLToRGB, RGBToHSL, hslToObject } from "utils";

const numberRows = 52;
const ColorPicker = ({ onClose, injectedProps }) => {
  const root = document.documentElement;
  const compStyle = getComputedStyle(root);
  const [globalProp] = useGlobal(injectedProps);
  let mainColor;
  if (injectedProps === "headerColor") {
    mainColor = compStyle.getPropertyValue("--headerColor") ? hslToObject(compStyle.getPropertyValue("--headerColor")) : { h: 0, s: 73, l: 85 };
  } else if (injectedProps === "themeColor") {
    mainColor = compStyle.getPropertyValue("--colorgrad3") ? hslToObject(compStyle.getPropertyValue("--colorgrad3")) : { h: 284, s: 6, l: 82 };
  } else {
    mainColor = globalProp;
  }
  const [selectedColor, setSelectedColor] = useState(
    mainColor ? [mainColor.h, mainColor.s, mainColor.l] : [0, 0, 50]
  );
  const [selectedLight, setSelectedLight] = useState(50);

  useEffect(() => {
    $("#crosshair").draggable({
      revert: "valid",
      zIndex: 100,
      containment: $("#color_wrap"),
      stop: (e, ui) => {
        let h = (ui.position.left / 208) * 360;
        let s = 100 - (ui.position.top / 208) * 100;
        setSelectedColor([
          Math.round(h),
          Math.round(s),
          Math.round(selectedLight),
        ]);
      },
    });
    $("#lightarrow").draggable({
      axis: "y",
      revert: "valid",
      zIndex: 100,
      containment: $("#light_wrap"),
      stop: (e, ui) => {
        let id = ui.position.top;
        let l = map(id, 0, numberRows * 4 - 1, 100, 0);
        setSelectedLight(l);
        setSelectedColor([
          selectedColor[0],
          selectedColor[1],
          l,
        ]);
      },
    });
  }, [selectedLight, selectedColor]);

  useEffect(() => {
    if (selectedColor) {
      document.querySelector(
        "#selected_color"
      ).style.backgroundColor = `hsl(${selectedColor[0]}, ${selectedColor[1]}%, ${selectedColor[2]}%)`;
      let divs = document.body.querySelectorAll(".hues");
      let h = selectedColor[0];
      for (let y = numberRows; y > 0; y--) {
        let l = Math.floor((y / numberRows) * 100);
        let bg = `hsl(${h}, ${selectedColor[1]}%, ${l}%)`;
        if (divs[numberRows - y]) divs[numberRows - y].style.backgroundColor = bg;
      }
    }
  }, [selectedColor]);

  useEffect(() => {
    if (injectedProps !== "paletteColor") {
      let h, s, l;
      mainColor ? ({ h, s, l } = mainColor) : ({ h, s, l } = null);
      adjustLight(l);
      const foundPix = document.querySelector(`#pix_${h}_${s}`);
      foundPix
        ? adjustColor(foundPix, [Math.round(h), Math.round(s), Math.round(l)])
        : adjustColor(document.querySelector(".pix"), [
          Math.round(h),
          Math.round(s),
          Math.round(l),
        ]);
      setSelectedColor([Math.round(h), Math.round(s), Math.round(l)]);
    } else {
      adjustLight(selectedColor[2]);
      setSelectedColor([
        selectedColor[0],
        selectedColor[1],
        selectedColor[2],
      ]);
    }
    // eslint-disable-next-line
  }, [injectedProps]);

  function selectColor(e) {
    if (e.target.classList.contains("pix")) {
      let div = e.target.outerHTML;
      let [r, g, b] = div
        .substring(div.indexOf("rgb"))
        .replace(/[^\d,.-]/g, "")
        .split(",");
      let hsl = RGBToHSL(r, g, b);
      adjustColor(e.target, hsl);
    }
  }
  function adjustColor(e, c) {
    const crosshair = document.body.querySelector("#crosshair");
    crosshair.style.left = e.offsetLeft - crosshair.offsetWidth / 2 + "px";
    crosshair.style.top = e.offsetTop - crosshair.offsetHeight / 2 + "px";
    setSelectedColor([
      Math.round(c[0]),
      Math.round(c[1]),
      Math.round(selectedLight),
    ]);
  }
  function selectLight(e) {
    if (e.target.classList.contains("hues")) {
      let id = e.target.id.substring(e.target.id.indexOf("hue") + 3);
      adjustLight(map(id, 0, numberRows - 1, 100, 0));
      setSelectedColor([
        selectedColor[0],
        selectedColor[1],
        map(id, 0, numberRows - 1, 100, 0),
      ]);
    }
  }
  function adjustLight(l) {
    const lightarrow = document.body.querySelector("#lightarrow");
    lightarrow.style.top = 97 - l + "%";
    setSelectedLight(l);
  }
  return (
    <Div>
      <div id="panel">
        <div id="color_wrap">
          <div className="inset_wrap">
            <div id="huesaturation" onClick={(e) => selectColor(e)}>
              <img
                draggable={false}
                src={crosshair}
                alt="crosshair"
                id="crosshair"
                className="pointer"
              />
              <Colorbar />
            </div>
          </div>
        </div>
        <div id="light_wrap">
          <div className="inset_wrap">
            <div id="lightness" onClick={(e) => selectLight(e)}>
              <Lightbar />
            </div>
          </div>
          <img
            draggable={false}
            src={lightarrow}
            alt="lightarrow"
            id="lightarrow"
            className="pointer"
          />
        </div>
      </div>
      <div id="bottom_panel">
        <div id="selected_color" className="inset_wrap"></div>
        <div className="color_vals">
          <span>
            <span>
              H<u>u</u>e:
            </span>
            <div>{Math.trunc(selectedColor[0])}</div>
          </span>
          <span>
            <span>
              S<u>a</u>t:
            </span>
            <div>{Math.trunc(selectedColor[1])}</div>
          </span>
          <span>
            <span>
              <u>L</u>um:
            </span>
            <div>{Math.trunc(selectedColor[2])}</div>
          </span>
        </div>
        <div className="color_vals">
          <span>
            <span>
              <u>R</u>ed:
            </span>
            <div>
              {
                HSLToRGB(
                  selectedColor[0],
                  selectedColor[1],
                  selectedColor[2]
                )[0]
              }
            </div>
          </span>
          <span>
            <span>
              <u>G</u>reen:
            </span>
            <div>
              {
                HSLToRGB(
                  selectedColor[0],
                  selectedColor[1],
                  selectedColor[2]
                )[1]
              }
            </div>
          </span>
          <span>
            <span>
              Bl<u>u</u>e:
            </span>
            <div>
              {
                HSLToRGB(
                  selectedColor[0],
                  selectedColor[1],
                  selectedColor[2]
                )[1]
              }
            </div>
          </span>
        </div>
      </div>

      <button
        className="button"
        onClick={() => {
          if (injectedProps === "headerColor") {
            let hsl =
              "hsl(" +
              selectedColor[0] +
              "," +
              selectedColor[1] +
              "%," +
              selectedColor[2] +
              "%)";
            root.style.setProperty("--headerColor", hsl);
            document.querySelector("#headerColorSelector").style.background = hsl;
            document.querySelector("#headerresetbtn").classList.remove("disabled");
          } else if (injectedProps === "themeColor") {
            let hsl =
              "hsl(" +
              selectedColor[0] +
              "," +
              selectedColor[1] +
              "%," +
              selectedColor[2] +
              "%)";
            let shades = createColorShades(selectedColor, 9);
            selectedColor[2] < 50
              ? root.style.setProperty("--black", "white")
              : root.style.setProperty("--black", "black");
            shades.forEach((shade, index) => {
              root.style.setProperty(`--colorgrad${index}`, shade);
            });
            document.querySelector("#themeColorSelector").style.background = hsl;
            document.querySelector("#themeresetbtn").classList.remove("disabled");
          } else if (injectedProps === "paletteColor") {
            setGlobal({
              [injectedProps]: HSLToRGB(
                selectedColor[0],
                selectedColor[1],
                selectedColor[2]
              ).concat(255),
            });
          } else {
            setGlobal({
              [injectedProps]: {
                h: selectedColor[0],
                s: selectedColor[1],
                l: selectedColor[2],
              },
            });
          }
          onClose();
        }}
      >
        {injectedProps === "headerColor" && "Select header color"}
        {injectedProps === "themeColor" && "Select theme color"}
        {(injectedProps === "paletteColor" || injectedProps === "halftone") &&
          "Add to custom colors"}
      </button>
    </Div>
  );
};

const Div = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px;

  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & button {
    width: 100%;
    font-size: 11px;
    margin-top: 7px;
  }
  .color_vals span {
    flex-direction: row;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }
  .color_vals div {
    margin-left: 4px;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 30px;
    height: 18px;
    margin-bottom: 1px;
    background: white;
    color: black;
    box-shadow: inset -1px -1px 0px var(--colorgrad0),
      inset 1px 1px 0px 0px var(--colorgrad6),
      inset -2px -2px 0px var(--colorgrad4),
      inset 2px 2px 0px 0px var(--colorgrad8);
  }

  #crosshair {
    bottom: 0;
    left: 0;
    z-index: 100;
    overflow: hidden;
    position: absolute;
    border: none;
    outline: none;
  }
  #lightarrow {
    top: 50%;
    left: 18px;
    z-index: 100;
    position: absolute;
    border: none;
    outline: none;
  }
  #panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 241px;
  }
  #color_wrap,
  #light_wrap {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  #color_wrap {
    overflow: hidden;
    position: relative;
  }
  #light_wrap {
    margin-left: 8px;
  }
  #bottom_panel {
    margin-top: 7px;
    padding-right: 4px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: space-between;
  }
  #light_wrap {
    position: relative;
    margin-right: 5px;
  }
  #lightness {
    box-sizing: content-box;
    width: 12px;
  }
  #huesaturation {
    background: white;
    filter: saturate(110%) brightness(110%) opacity(0.9);
    overflow: hidden;
  }

  .inset_wrap {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 2px;
    box-shadow: inset -1px -1px 0px var(--colorgrad0),
      inset 1px 1px 0px 0px var(--colorgrad6),
      inset -2px -2px 0px var(--colorgrad4),
      inset 2px 2px 0px 0px var(--colorgrad7);
  }
  #selected_color {
    width: 72px;
    height: 56px;
  }
`;

export default ColorPicker;
