import * as types from "../constants";
import { paletteList } from "../palettes";
import Stats from "./Stats.js"

export const importState = (json: string) => {
  const deserialized = JSON.parse(json);
  return {
    type: types.LOAD_STATE,
    data: deserialized
  };
};

export const setInputPlaybackRate = (rate: number) => ({
  type: types.SET_INPUT_PLAYBACK_RATE,
  rate
});

export const setInputCanvas = (canvas: HTMLCanvasElement) => ({
  type: types.SET_INPUT_CANVAS,
  canvas
});

export const loadImage = (
  image: HTMLImageElement,
  time: ?number = 0,
  video: ?HTMLVideoElement,
  dispatch: ?any
) => ({
  type: types.LOAD_IMAGE,
  image,
  time,
  video,
  dispatch
});

export const loadVideoAsync = () => (dispatch: Dispatch) => {
  const stats = new Stats();
  const video = document.querySelector("video#rmx_vid");
  const vidsrc = video.getElementsByTagName("source")[0].src;
    const i = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d", {
  willReadFrequently: true
});
    const loadFrame = (timestamp) => {
      stats.begin();
      URL.revokeObjectURL(i.src);
      if (vidsrc) {
        i.width = video.videoWidth;
        i.height = video.videoHeight;
        ctx.drawImage(video, 0, 0);
        canvas.toBlob(blob => {
          if (blob) {
            i.src = URL.createObjectURL(blob);
            i.onload = () => {
              if (vidsrc) {
                stats.end();
                let frame = requestAnimationFrame(loadFrame);
                if (video.paused) cancelAnimationFrame(frame);
                dispatch(loadImage(i, video.currentTime, video, dispatch));
              }
            };
          }
        });
      }
    };
    video.onplaying = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      loadFrame();
    };
    video.src = vidsrc;
    video.loop = true;
};


export const setConvertGrayscale = (value: boolean) => ({
  type: types.SET_GRAYSCALE,
  value
});

export const selectFilter = (name: string, filter: Filter) => ({
  type: types.SELECT_FILTER,
  name,
  filter
});

export const filterImage = (image: HTMLImageElement) => ({
  type: types.FILTER_IMAGE,
  image
});

export const addPaletteColor = (color: ColorRGBA) => ({
  type: types.ADD_PALETTE_COLOR,
  color
});

export const setFilterOption = (optionName: string, value: any) => {
  const paletteObject = paletteList.find(p => p.name === value);

  return {
    type: types.SET_FILTER_OPTION,
    optionName,
    value: paletteObject ? paletteObject.palette : value
  };
};

export const setFilterPaletteOption = (optionName: string, value: any) => ({
  type: types.SET_FILTER_PALETTE_OPTION,
  optionName,
  value
});

export const setViewEffects = (enabled: boolean) => ({
  type: types.SET_VIEW_EFFECTS,
  enabled
});
