import React, { useGlobal, useEffect, useState, setGlobal } from "reactn";
import styled from "styled-components";
import GhostImage from "./GhostImage.js";
import loadImg from "assets/ghost/loading.gif";
import errorImg from "assets/icons/error_small.ico";
import { displayMetadata } from "./displaymetadata.js";
import axios from "axios";

const ViewToken = ({
  token,
  baseURI,
  allTokens,
  handleViewToken,
  handleViewUser,
  handleViewGhost,
  id,
  tokenOwner
}) => {
  const [appLoading] = useGlobal("appLoading");
  const [statusImg, setStatusImg] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [ipfsHash, setIpfsHash] = useState(null);
  const [error, setError] = useState(false);
  const [tokenInput, setTokenInput] = useState(null);

  function download3DFile(tokenId) {
    if (
      appLoading &&
      appLoading.name === "Ghost Software" &&
      appLoading.id === id
    )
      return;
    setGlobal({ appLoading: { name: "Ghost Software", id: id } });
    axios
      .get("https://api.heaven.computer/ghostexporter/" + tokenId, {
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "ghostsoftware_" + tokenId + ".zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setGlobal({ appLoading: null });
      })
      .catch(() => {
        setGlobal({ appLoading: null });
      });
  }

  useEffect(() => {
    const video = document.querySelector("source#vid" + token);
    if (video) {
      video.onerror = (event) => {
        setError(true);
        setStatusImg(errorImg);
      };
      video.addEventListener("canplay", (event) => {
        setError(false);
        setStatusImg(null);
      });
    }
  }, [token]);

  useEffect(() => {
    function fetchMetadata(tokenId) {
      const foundMetadata = displayMetadata.find(
        (item) => parseInt(item.tokenId) === parseInt(tokenId)
      );
      setIpfsHash(foundMetadata.ipfsHash);
      setAttributes(foundMetadata.attributes);
      setName(foundMetadata.name);
      setDescription(foundMetadata.description);
    }
    if (allTokens && token) {
      fetchMetadata(token);
      const last = document.getElementById(id + "v_gs_last").classList;
      const first = document.getElementById(id + "v_gs_first").classList;
      const next = document.getElementById(id + "v_gs_next").classList;
      const prev = document.getElementById(id + "v_gs_previous").classList;
      if (parseInt(token) === 0 && parseInt(token) !== allTokens.length - 1) {
        last.remove("disabled");
        prev.add("disabled");
        first.add("disabled");
        next.remove("disabled");
      } else if (
        parseInt(token) !== 0 &&
        parseInt(token) === allTokens.length - 1
      ) {
        last.add("disabled");
        next.add("disabled");
        prev.remove("disabled");
        first.remove("disabled");
      } else if (parseInt(token) === 0 && allTokens.length - 1 === 0) {
        first.add("disabled");
        next.add("disabled");
        prev.add("disabled");
        last.add("disabled");
      } else {
        first.remove("disabled");
        next.remove("disabled");
        prev.remove("disabled");
        last.remove("disabled");
      }
    }
  }, [allTokens, token, baseURI, id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tokenInput >= 0 && tokenInput < 7777) {
      handleViewToken(tokenInput);
    } else {
      e.target.classList.add("shake_err");
      setTimeout(() => {
        e.target.classList.remove("shake_err");
      }, 700);
      return;
    }
  };

  function handleTokenChange(e) {
    let number = parseInt(e.target.value);
    if (number === parseInt(token) || number < 0 || number >= allTokens.length) {
      return;
    } else {
      handleViewToken(number.toString());
    }
  }
  function toggleImageSize() {
    const images = document.querySelectorAll(".gsti"+id);
    if (images) {
      images.forEach((image, i) => {
        if (image.classList.contains("large")) {
          image.style.width = "300px";
          image.classList.remove("large");
        } else {
          image.style.width = "600px";
          image.classList.add("large");
        }
      });
    }
  }
  return (
    <Div>
      {(!token || !allTokens) && <p style={{padding: "10px"}}>Loading...</p>}
      {allTokens && token && (
        <>
          <div className="token_item">
            <div className="buttons_row">
              <button
                className="button"
                value={0}
                id={id + "v_gs_first"}
                onClick={(e) => handleTokenChange(e)}
              >
                &#60;&#60;
              </button>
              <button
                className="button"
                value={parseInt(token) - 1}
                id={id + "v_gs_previous"}
                onClick={(e) => handleTokenChange(e)}
              >
                &#60;
              </button>
              <form
                className="input"
                autoComplete="off"
                onSubmit={(e) => handleSubmit(e)}
                noValidate="novalidate"
              >
                <input
                  className="texter"
                  minLength="1"
                  maxLength="4"
                  size="4"
                  min="0"
                  max="7776"
                  id="token_page"
                  type="number"
                  name="token page"
                  placeholder="0000"
                  onChange={(e) => setTokenInput(e.target.value)}
                ></input>
              </form>
              <button
                className="button"
                id={id + "v_gs_next"}
                value={parseInt(token) + 1}
                onClick={(e) => handleTokenChange(e)}
              >
                &#62;
              </button>
              <button
                className="button"
                value={allTokens.length - 1}
                id={id + "v_gs_last"}
                onClick={(e) => handleTokenChange(e)}
              >
                &#62;&#62;
              </button>
            </div>
            <div className="gs_upper">
              <div className="token_box_wrap">
                <div
                  className="token_box"
                  onClick={() => toggleImageSize(token)}
                >
                  {!error && statusImg && (
                    <div className="token_image status">
                      <img alt="favicon" src={loadImg} />
                    </div>
                  )}
                  {error && statusImg && (
                    <div className="token_image status err_img">
                      <img alt="favicon" src={errorImg} />
                    </div>
                  )}
                  {!statusImg && (
                    <GhostImage token={token} ipfsHash={ipfsHash} id={id} />
                  )}
                </div>
              </div>

              <div className="token_caption">
                <div>
                  <h2>ghost #{token}</h2>{" "}
                  <span>{name && name.split("#")[0]}</span>
                </div>
                <div>
                  <b>owned by</b>
                  <span className="tokenowner">{tokenOwner ? tokenOwner : "loading..."}</span>
                </div>
                <div className="see_all_row">
                  <div
                    className="button see_all"
                    onClick={() => handleViewGhost(token)}
                  >
                    <i>see live ghost temple</i>
                  </div>
                  <div
                    className="button see_all"
                    onClick={() => handleViewUser(tokenOwner)}
                  >
                    <i>see all user ghosts</i>
                  </div>
                  <div
                    className="button see_all"
                    onClick={() => download3DFile(token)}
                  >
                    <i>download ghost 3d files</i>
                  </div>
                </div>
                <div>
                  <b>description</b> <p>{description}</p>
                </div>
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th scope="col" colSpan="1">
                    element
                  </th>
                  <th scope="col" colSpan="1">
                    feature
                  </th>
                  <th scope="col" colSpan="3">
                    value
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="1">ghost</td>
                  <td colSpan="1">energy emotion</td>
                  <td colSpan="3">
                    {attributes ? attributes["ghost energy emotion"] : ""}
                  </td>
                </tr>
                <tr>
                  <td colSpan="1">ghost</td>
                  <td colSpan="1">energy gender</td>
                  <td colSpan="3">
                    {attributes ? attributes["ghost energy gender"] : ""}
                  </td>
                </tr>
                <tr>
                  <td colSpan="1">ghost</td>
                  <td colSpan="1">skin surface</td>
                  <td colSpan="3">
                    {attributes ? attributes["ghost skin surface"] : ""}
                  </td>
                </tr>
                <tr>
                  <td colSpan="1">ghost</td>
                  <td colSpan="1">skin shade</td>
                  <td colSpan="3">
                    {attributes ? attributes["ghost skin shade"] : ""}
                  </td>
                </tr>
                <tr>
                  <td colSpan="1">temple</td>
                  <td colSpan="1">chiaroscuro</td>
                  <td colSpan="3">
                    {attributes ? attributes["temple chiaroscuro"] : ""}
                  </td>
                </tr>
                <tr>
                  <td colSpan="1">temple</td>
                  <td colSpan="1">ambiance shade</td>
                  <td colSpan="3">
                    {attributes ? attributes["temple ambiance shade"] : ""}
                  </td>
                </tr>
                <tr>
                  <td colSpan="1">temple</td>
                  <td colSpan="1">water shade</td>
                  <td colSpan="3">
                    {attributes ? attributes["temple water shade"] : ""}
                  </td>
                </tr>
                {attributes["temple mist"] && (
                  <tr>
                    <td colSpan="1">temple</td>
                    <td colSpan="1">mist</td>
                    <td colSpan="3">
                      {attributes ? attributes["temple mist"] + "%" : ""}
                    </td>
                  </tr>
                )}
                {attributes["ghost skeleton corruption"] && (
                  <tr>
                    <td colSpan="1">ghost</td>
                    <td colSpan="1">skeleton corruption</td>
                    <td colSpan="3">
                      {attributes
                        ? attributes["ghost skeleton corruption"]
                        : ""}
                    </td>
                  </tr>
                )}
                {attributes["ghost distortion"] && (
                  <tr>
                    <td colSpan="1">ghost</td>
                    <td colSpan="1">distortion</td>
                    <td colSpan="3">
                      {attributes ? attributes["ghost distortion"] + "%" : ""}
                    </td>
                  </tr>
                )}
                {attributes["ghost transparency"] && (
                  <tr>
                    <td colSpan="1">ghost</td>
                    <td colSpan="1">transparency</td>
                    <td colSpan="3">
                      {attributes ? attributes["ghost transparency"] + "%" : ""}
                    </td>
                  </tr>
                )}
                {attributes["film color corruption"] && (
                  <tr>
                    <td colSpan="1">film</td>
                    <td colSpan="1">color corruption</td>
                    <td colSpan="3">
                      {attributes
                        ? attributes["film color corruption"] + "%"
                        : ""}
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th scope="row" colSpan="1">
                    MP4 IPFS hash
                  </th>
                  <td colSpan="4">{ipfsHash ? ipfsHash : ""}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      )}
    </Div>
  );
};

const Div = styled.div`
  video {
    background-image: url(loadImg);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: center;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .token_caption, .token_caption p, td {
    color: black !important;
  }
  .input {
    box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad0),
      inset -2px -2px 0 0 var(--colorgrad5), inset 2px 2px 0 0 var(--colorgrad2);
    background: var(--colorgrad3);
    padding: 4px;
    height: 28px;
    margin: 0 10px;
    input {
      width: 40px;
      text-decoration: none;
      font-size: 11px;
      letter-spacing: 2px;
      border: 0;
      outline: 0;
      box-shadow: inset -1px -1px 0px var(--colorgrad0), inset 1px 1px 0px 0px var(--colorgrad6),
        inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8);
      background: #ffffff;
      height: 20px;
      padding: 4px;
      white-space: nowrap;
    }
  }
  .token_caption div p {
    padding-top: 10px;
    line-height: 14px;
  }
  .token_item {
    max-width: 100%;
    max-height: 100%;
  }
  .token_image {
    width: 300px;
    max-width: 100%;
    aspect-ratio: 3 / 4;
  }
`;

export default ViewToken;
