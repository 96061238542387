import { useEffect } from "react";

const useDropDown = (
  dropup,
  dropdown,
  disabled,
  scrollContainer = "",
  handleDropDown
) => {

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (disabled) return;
      if (
        dropdown &&
        !dropdown.contains(e.target) &&
        !dropup.contains(e.target)
      ) {
        dropdown.classList.add("closed");
      }
    };

    const handleClickDropDown = (e) => {
      if (
        e.target.innerText &&
        e.target.parentNode.classList.contains("dropdown_option")
      ) {
        let text = e.target.innerText;
        handleDropDown(text);
        dropup.getElementsByTagName("span")[0].innerText = text;
        dropdown.classList.add("closed");
      }
    };

    const handleClickDropUp = (e) => {
      if (disabled) return;
      const rect = dropup.getBoundingClientRect();
      dropdown.style.width = rect.width - 2 + "px";
      let classes = dropdown.classList;
      classes.contains("closed")
        ? classes.remove("closed")
        : classes.add("closed");
    };

    const handleScroll = () => {
      if (dropdown) dropdown.classList.add("closed");
    };

    if (dropup) {
      disabled
        ? dropup.classList.add("disabled")
        : dropup.classList.remove("disabled");
      Array.from(dropup.children).forEach((child) => {
        disabled
          ? child.classList.add("disabled")
          : child.classList.remove("disabled");
      });
    }

    if (scrollContainer) {
      const e = document.querySelector(scrollContainer);
      e?.addEventListener("scroll", handleScroll);
    }
    dropup?.addEventListener("pointerup", handleClickDropUp);
    dropdown?.addEventListener("pointerup", handleClickDropDown);
    document.addEventListener("pointerdown", handleClickOutside);

    return () => {
      if (scrollContainer) {
        const e = document.querySelector(scrollContainer);
        e?.removeEventListener("scroll", handleScroll);
      }
      dropup?.removeEventListener("pointerup", handleClickDropUp);
      dropdown?.removeEventListener("pointerup", handleClickDropDown);
      document.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [dropdown, dropup, disabled, scrollContainer, handleDropDown]);
};

export default useDropDown;
