import React, { useEffect, useRef, setGlobal, useState } from "reactn";
import { clamp, HSLToHex } from "utils";
import styled from "styled-components";
import * as THREE from "three";
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { Water } from "./Water.js";
import { Sky } from "./Sky.js";
import { playerData } from "store";
import music from "assets/mall/mall.mp3";
import tiles from "assets/ghost/tilebg.png";
import flamingo from "assets/mall/Flamingo.obj";
import tipTex from "assets/mall/tip_tex.png";
import simps1 from "assets/mall/simps1.png";
import simps2 from "assets/mall/simps2.png";
import moonTex from "assets/mall/moon_texture.jpg";
import water from "assets/mall/waternormals.jpeg";
import mallmodel from "assets/mall/Mall.obj";
import malltex from "assets/mall/MallColor.png";
import mallmetal from "assets/mall/MallMetallic.png";
import mallrough from "assets/mall/MallRoughness.png";
import mallnormal from "assets/mall/MallNormals.png";
import mallemiss from "assets/mall/MallEmission.png";
import sparkpng from "assets/mall/star_spark.png";
import glowpng from "assets/mall/star_glow.png";
import loadImg from "assets/ghost/loading.gif";
import { isDesktop } from "react-device-detect";

const Mall = ({ onClose }) => {
  const [started, setStarted] = useState(false);
  const canvasReference = useRef();
  const controls = useRef();
  const clock = useRef();
  const manager = useRef();
  const raycaster = useRef();
  const player = useRef();
  const gravitycaster = useRef();
  const renderer = useRef();
  const camera = useRef();
  const scene = useRef();
  const animation = useRef();
  const ghostsCaptured = useRef([]);

  const rnd = (decPairs, n) => decPairs[n] / 255;
  const isEven = (n) => !(n & 1);
  const getIndex = (string, subString, index) =>
    string.split(subString, index).join(subString).length;
  const replacement = (decPairs, replaced, n) => {
    let val = null;
    let next = decPairs.find(
      (e) => Math.round((e / 255) * 10) !== Math.round((decPairs[n] / 255) * 10)
    );
    while (val === null || val === replaced) {
      val = Math.round((next / 255) * 10);
    }
    return val;
  };
  const pairs = () => {
    let hashString = "0x";
    for (let i = 0; i < 64; i++) {
      let val = Math.floor(Math.random().toFixed(3) * 255);
      hashString = hashString + val.toString(16);
    }
    let hashPairs = [];
    for (let j = 0; j < 32; j++)
      hashPairs.push(hashString.slice(2 + j * 2, 4 + j * 2));
    return hashPairs.map((x) => parseInt(x, 16));
  };

  useEffect(() => {
    setStarted(true);
    const mallMusic = new Audio(music);
    let ghost0, ghost1, ghost2, ghost3, ghost4, ghost5, ghost6;
    let sun, ocean, mall;
    let mixers = [];
    let velocity = new THREE.Vector3();
    let moveForward = false;
    let moveBackward = false;
    let moveLeft = false;
    let moveRight = false;
    let canJump = false;
    let prevTime = performance.now();

    const renderPCMovement = (
      rcollision,
      lcollision,
      bcollision,
      fcollision,
      gravity
    ) => {
      let time = performance.now();
      const playerSpeed = 1600 - playerData.speed * 100; // 400~1500
      let delta = (time - prevTime) / playerSpeed;
      velocity.x -= velocity.x * 10.0 * delta;
      velocity.z -= velocity.z * 10.0 * delta;
      if (rcollision || lcollision || fcollision || bcollision) {
        player.current.position.set(
          playerData.pos_x,
          playerData.pos_y,
          playerData.pos_z
        );
      }
      if (moveForward && !fcollision) velocity.z -= 400.0 * delta;
      if (moveBackward && !bcollision) velocity.z += 400.0 * delta;
      if (moveLeft && !rcollision && !lcollision) velocity.x -= 400.0 * delta;
      if (moveRight && !rcollision && !lcollision) velocity.x += 400.0 * delta;
      if (
        (moveForward && (moveRight || moveLeft)) ||
        (moveBackward && (moveRight || moveLeft))
      )
        velocity.x = 0;

      player.current.translateX(velocity.x * delta);
      player.current.translateZ(velocity.z * delta);

      if (!playerData.fly_mode) {
        if (player.current.position.y > 0) {
          player.current.position.y = playerData.pos_y;
          velocity.y -= 9.8 * 10 * delta;
          player.current.translateY(velocity.y * delta);
          if (gravity && player.current.position.y <= gravity) {
            velocity.y = 0;
            player.current.position.y = gravity;
            canJump = true;
          }
        } else {
          velocity.x = 0;
          velocity.z = 0;
          velocity.y -= 0.001 * delta;
          player.current.translateY(velocity.y * 0.5 * delta);
        }
      }
      player.current.position.x = clamp(player.current.position.x, -1900, 1900);
      player.current.position.y = clamp(player.current.position.y, -1900, 1900);
      player.current.position.z = clamp(player.current.position.z, -1900, 1900);
      prevTime = time;
    };

    const onKeyDown = (event) => {
      switch (event.keyCode) {
        case 38: // up
        case 87: // w
          moveForward = true;
          break;
        case 37: // left
        case 65: // a
          moveLeft = true;
          break;
        case 40: // down
        case 83: // s
          moveBackward = true;
          break;
        case 39: // right
        case 68: // d
          moveRight = true;
          break;
        case 32: // space
          if (!playerData.fly_mode) {
            if (canJump === true) velocity.y += playerData.jump_velocity;
            canJump = false;
          }
          break;
        case 16: // shift
          playerData.fly_mode = true;
          break;
        default:
          break;
      }
    };

    const onKeyUp = (event) => {
      switch (event.keyCode) {
        case 38: // up
        case 87: // w
          moveForward = false;
          break;
        case 37: // left
        case 65: // a
          moveLeft = false;
          break;
        case 40: // down
        case 83: // s
          moveBackward = false;
          break;
        case 39: // right
        case 68: // d
          moveRight = false;
          break;
        case 16: // shift
          playerData.fly_mode = false;
          break;
        default:
          break;
      }
    };

    let ghostObjs = [];
    function init() {
      if (mallMusic.isPlaying && mallMusic.currentTime > 0) {
        mallMusic.pause();
        mallMusic.currentTime = 0;
      }
      // LOADING
      manager.current = new THREE.LoadingManager();
      manager.current.onStart = function () {
        setGlobal({ appLoading: { name: "Mall Of Tortured Souls", id: 0 } });
        const loading = document.getElementById("mall_loading");
        if (loading) loading.style.display = "flex";
      };
      manager.current.onLoad = function () {
        setGlobal({ appLoading: null });
        const loading = document.getElementById("mall_loading");
        if (loading) loading.style.display = "none";
        mallMusic.loop = true;
      };
      manager.current.onError = function (url) {
        const loading = document.getElementById("mall_loading");
        if (loading)
          loading.innerHTML = "An error occurred. Please refresh the page.";
      };
      const height = document.getElementById("mall_window").offsetHeight;
      const width = document.getElementById("mall_window").offsetWidth;

      // LIGHTS CAMERA ACTION
      scene.current = new THREE.Scene();
      scene.current.background = new THREE.Color(0x006aff);
      scene.current.fog = new THREE.Fog(0x000000, 0, 400);
      camera.current = new THREE.PerspectiveCamera(
        55,
        width / height,
        1,
        11000
      );
      renderer.current = new THREE.WebGLRenderer({
        antialias: true,
      });
      clock.current = new THREE.Clock();

      // RAYCASTER SETTINGS
      const rc_height = playerData.height - 2.5;
      raycaster.current = new THREE.Raycaster(
        new THREE.Vector3(),
        new THREE.Vector3(),
        0,
        playerData.collision_dist
      );
      gravitycaster.current = new THREE.Raycaster(
        new THREE.Vector3(),
        new THREE.Vector3(),
        0,
        rc_height
      );

      // RENDERER SETTINGS
      renderer.current.setPixelRatio(window.devicePixelRatio);
      renderer.current.setSize(width, height);
      renderer.current.physicallyCorrectLights = true;
      renderer.current.outputEncoding = THREE.sRGBEncoding;
      // renderer.current.gammaFactor = 2.2;
      renderer.current.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.current.shadowMap.enabled = true;
      renderer.current.shadowMapSoft = true;
      renderer.current.autoClear = true;

      // CONTROL SETTINGS
      controls.current = new PointerLockControls(camera.current, document.body);
      player.current = controls.current.getObject();
      player.current.position.set(
        playerData.pos_x,
        playerData.pos_y + playerData.height,
        playerData.pos_z
      );
      player.current.rotation.y = (playerData.direction / 180) * Math.PI;
      playerData.fly_mode = true;
      player.current.step = playerData.step_size;
      scene.current.add(player.current);

      const blocker = document.getElementById("blocker");
      const instructions = document.getElementById("instructions");
      const raptured = document.getElementById("raptured");
      const captured = document.getElementById("captured");
      const restart = document.getElementById("restart");
      function lockControl() {
        if (controls.current && !controls.current.isLocked)
          controls.current.lock();
      }
      blocker.addEventListener("pointerdown", function () {
        if (captured) captured.style.display = "flex";
        setTimeout(() => (playerData.fly_mode = false), 200);
        lockControl();
      });
      restart.addEventListener("pointerdown", function () {
        raptured.removeEventListener("pointerdown", lockControl);
        raptured.style.display = "none";
        blocker.style.display = "flex";
        mallMusic.pause();
        mallMusic.currentTime = 0;
        ghostsCaptured.current = ["complete"];
        if (player.current) {
          scene.current.remove(player.current);
        }
      });
      if (
        ghostsCaptured.current.length < 7 &&
        ghostsCaptured.current[0] !== "complete"
      ) {
        raptured.addEventListener("pointerdown", lockControl);
      } else {
        raptured.removeEventListener("pointerdown", lockControl);
      }

      controls.current.addEventListener("lock", function () {
        document.addEventListener("keydown", onKeyDown, false);
        document.addEventListener("keyup", onKeyUp, false);
        instructions.style.display = "none";
        blocker.style.display = "none";
        if (ghostsCaptured.current.length < 7) mallMusic.play();
      });

      controls.current.addEventListener("unlock", function () {
        document.removeEventListener("keydown", onKeyDown, false);
        document.removeEventListener("keup", onKeyUp, false);
        if (captured) captured.style.display = "none";
        blocker.style.display = "flex";
        instructions.style.display = "flex";
        if (ghostsCaptured.current.length < 7) mallMusic.pause();
        moveForward = false;
        moveBackward = false;
        moveLeft = false;
        moveRight = false;
      });

      // INIT OBJECTS
      initSceneObjects();
      initGhost(
        ghost0.tex,
        ghost0.model,
        ghost0.decPairs,
        -220,
        0,
        40,
        Math.PI / 4,
        0
      ); // ground floor, store
      initGhost(
        ghost1.tex,
        ghost1.model,
        ghost1.decPairs,
        340,
        53,
        400,
        Math.PI,
        1
      ); // 1st floor, balcony
      initGhost(
        ghost2.tex,
        ghost2.model,
        ghost2.decPairs,
        -283,
        53,
        332,
        0.2 * Math.PI,
        2
      ); // 1st floor, bathroom
      initGhost(
        ghost3.tex,
        ghost3.model,
        ghost3.decPairs,
        -278,
        105,
        408,
        (2 / 3) * Math.PI,
        3
      ); // 2nd floor, corner
      initGhost(ghost4.tex, ghost4.model, ghost4.decPairs, 0, 0, 420, Math.PI, 4); // ground floor, elevator
      initGhost(
        ghost5.tex,
        ghost5.model,
        ghost5.decPairs,
        -200,
        105,
        60,
        -0.75 * Math.PI,
        5
      ); // 2nd floor, terrace
      initGhost(ghost6.tex, ghost6.model, ghost6.decPairs, 0, 153, 430, 0, 6); // rooftop
      canvasReference.current.appendChild(renderer.current.domElement);
    }
    const approximatelyEqual = (v1, v2, epsilon = 10) =>
      Math.abs(v1 - v2) < epsilon;
    function animate() {
      animation.current = requestAnimationFrame(animate);
      ocean.material.uniforms["time"].value += 1.0 / 60.0;
      if (!ghostsCaptured.current.includes("complete")) {
        if (
          approximatelyEqual(player.current.position.x, -220) &&
          approximatelyEqual(player.current.position.z, 40) &&
          approximatelyEqual(player.current.position.y, 16) &&
          !ghostsCaptured.current.includes(0)
        )
          captureGhost(0);
        if (
          approximatelyEqual(player.current.position.x, 340) &&
          approximatelyEqual(player.current.position.z, 400) &&
          approximatelyEqual(player.current.position.y, 69) &&
          !ghostsCaptured.current.includes(1)
        )
          captureGhost(1);
        if (
          approximatelyEqual(player.current.position.x, -283) &&
          approximatelyEqual(player.current.position.z, 332) &&
          approximatelyEqual(player.current.position.y, 69) &&
          !ghostsCaptured.current.includes(2)
        )
          captureGhost(2);
        if (
          approximatelyEqual(player.current.position.x, -278) &&
          approximatelyEqual(player.current.position.z, 408) &&
          approximatelyEqual(player.current.position.y, 121) &&
          !ghostsCaptured.current.includes(3)
        )
          captureGhost(3);
        if (
          approximatelyEqual(player.current.position.x, 0) &&
          approximatelyEqual(player.current.position.z, 420) &&
          approximatelyEqual(player.current.position.y, 16) &&
          !ghostsCaptured.current.includes(4)
        )
          captureGhost(4);
        if (
          approximatelyEqual(player.current.position.x, -200) &&
          approximatelyEqual(player.current.position.z, 60) &&
          approximatelyEqual(player.current.position.y, 121) &&
          !ghostsCaptured.current.includes(5)
        )
          captureGhost(5);
        if (
          approximatelyEqual(player.current.position.x, 0) &&
          approximatelyEqual(player.current.position.z, 430) &&
          approximatelyEqual(player.current.position.y, 169) &&
          !ghostsCaptured.current.includes(6)
        )
          captureGhost(6);

        if (ghostsCaptured.current.length === 7) raptureGhosts();
      }
      renderPCMovement(
        getRightCollision(player.current.position),
        getLeftCollision(player.current.position),
        getBackwardCollision(player.current.position),
        getForwardCollision(player.current.position),
        getGravityCollision(player.current.position)
      );
      playerData.pos_x = player.current.position.x;
      playerData.pos_y = player.current.position.y;
      playerData.pos_z = player.current.position.z;
      playerData.direction = setLoop(
        (player.current.rotation.y / Math.PI) * 180
      );
      renderer.current.render(scene.current, camera.current);
      if (clock.current) {
        const delta = clock.current.getDelta();
        if (mixers && mixers.length > 0) {
          for (let i = 0; i < mixers.length; i++) {
            mixers[i].update(delta);
          }
        }
      }

      if (player.current.position.y < -300) rugUser(mallMusic);
    }
    const mood = (decPairs) => Math.round(rnd(decPairs, 31) * 27);
    const gen = (decPairs) => (rnd(decPairs, 30) < 0.5 ? "f" : "m");
    const decPairs0 = pairs();
    const decPairs1 = pairs();
    const decPairs2 = pairs();
    const decPairs3 = pairs();
    const decPairs4 = pairs();
    const decPairs5 = pairs();
    const decPairs6 = pairs();
    async function loadData() {
      const [
        model0,
        tex0,
        model1,
        tex1,
        model2,
        tex2,
        model3,
        tex3,
        model4,
        tex4,
        model5,
        tex5,
        model6,
        tex6,
      ] = await Promise.all([
        import(`assets/mall/models/${gen(decPairs0)}/${mood(decPairs0)}.fbx`),
        import(`assets/mall/matcap/m${Math.round(rnd(decPairs0, 5) * 52)}.png`),
        import(`assets/mall/models/${gen(decPairs1)}/${mood(decPairs1)}.fbx`),
        import(`assets/mall/matcap/m${Math.round(rnd(decPairs1, 5) * 52)}.png`),
        import(`assets/mall/models/${gen(decPairs2)}/${mood(decPairs2)}.fbx`),
        import(`assets/mall/matcap/m${Math.round(rnd(decPairs2, 5) * 52)}.png`),
        import(`assets/mall/models/${gen(decPairs3)}/${mood(decPairs3)}.fbx`),
        import(`assets/mall/matcap/m${Math.round(rnd(decPairs3, 5) * 52)}.png`),
        import(`assets/mall/models/${gen(decPairs4)}/${mood(decPairs4)}.fbx`),
        import(`assets/mall/matcap/m${Math.round(rnd(decPairs4, 5) * 52)}.png`),
        import(`assets/mall/models/${gen(decPairs5)}/${mood(decPairs5)}.fbx`),
        import(`assets/mall/matcap/m${Math.round(rnd(decPairs5, 5) * 52)}.png`),
        import(`assets/mall/models/${gen(decPairs6)}/${mood(decPairs6)}.fbx`),
        import(`assets/mall/matcap/m${Math.round(rnd(decPairs6, 5) * 52)}.png`),
      ]);
      ghost0 = { model: model0, tex: tex0, decPairs: decPairs0 };
      ghost1 = { model: model1, tex: tex1, decPairs: decPairs1 };
      ghost2 = { model: model2, tex: tex2, decPairs: decPairs2 };
      ghost3 = { model: model3, tex: tex3, decPairs: decPairs3 };
      ghost4 = { model: model4, tex: tex4, decPairs: decPairs4 };
      ghost5 = { model: model5, tex: tex5, decPairs: decPairs5 };
      ghost6 = { model: model6, tex: tex6, decPairs: decPairs6 };
    }
    loadData().then(() => {
      if (ghost0 && ghost1 && ghost2 && ghost3 && ghost4 && ghost5 && ghost6) {
        if (started) {
          init();
          animate();
        }
      }
    });
    function initGhost(
      paramTex,
      paramModel,
      decPairs,
      paramX,
      paramY,
      paramZ,
      rotation,
      ghostId
    ) {
      const limbGlitch = isEven(rnd(decPairs, 6) * 10);
      const animGlitch = isEven(rnd(decPairs, 7) * 10)
        ? isEven(rnd(decPairs, 8) * 10)
        : isEven(rnd(decPairs, 9) * 10);
      const polyGlitch = isEven(rnd(decPairs, 10) * 10)
        ? isEven(rnd(decPairs, 11) * 10)
        : isEven(rnd(decPairs, 12) * 10);
      const vertGlitch = isEven(rnd(decPairs, 13) * 10);

      const tex = paramTex.default;
      const model = paramModel.default;
      const matCap = new THREE.TextureLoader(manager.current).load(tex);
      new THREE.FileLoader(manager.current).load(model, function (data) {
        let glitch = data;
        if (limbGlitch) {
          const limbs = data
            .split("\n")
            .filter((n) => n.startsWith("	;Model::mixamorig:"));
          const limb =
            limbs[Math.round(rnd(decPairs, 14) * (limbs.length - 1))];
          let re = new RegExp(`^([\\s\\S]*?)\\n` + limb + `*(?:\\n.*){1}`, "g");
          glitch = glitch.replace(re, "$1");
        }
        if (animGlitch) {
          const replaced = Math.round(rnd(decPairs, 0) * 10);
          const anims = glitch
            .split("\n")
            .filter((n) => n.startsWith("	Deformer: "));
          const anim =
            anims[Math.round(rnd(decPairs, 15) * (anims.length - 1))];
          let anim0 = glitch.substring(glitch.indexOf(anim));
          let anim1 = anim0.slice(
            getIndex(anim0, "{", 2),
            getIndex(anim0, "}", 5)
          );
          let anim2 = anim1.replace(
            new RegExp(replaced, "g"),
            replacement(decPairs, replaced, 0)
          );
          glitch = glitch.replace(anim1, anim2);
        }
        if (polyGlitch) {
          const replaced = Math.round(rnd(decPairs, 1) * 10);
          const poly0 = glitch.substring(
            glitch.indexOf(
              `PolygonVertexIndex: *${gen(decPairs) === "f" ? "19936" : "19768"
              }`
            )
          );
          const poly1 = poly0.slice(poly0.indexOf("-"), poly0.indexOf("}"));
          let poly = poly1.replace(
            new RegExp(replaced, "g"),
            replacement(decPairs, replaced, 1)
          );
          glitch = glitch.replace(poly1, poly);
        }
        if (vertGlitch) {
          const replaced = Math.round(rnd(decPairs, 2) * 10);
          let vert0 = glitch.substring(
            glitch.indexOf(
              `Vertices: *${gen(decPairs) === "f" ? "14958" : "14832"}`
            )
          );
          let vert1 = vert0.slice(vert0.indexOf("-"), vert0.indexOf("}"));
          let vert = vert1.replace(
            new RegExp(replaced, "g"),
            replacement(decPairs, replaced, 2)
          );
          glitch = glitch.replace(vert1, vert);
        }

        let buffer = new TextEncoder(manager.current).encode(glitch);
        let object = new FBXLoader(manager.current).parse(buffer);

        const mixer = new THREE.AnimationMixer(object);
        const action = mixer.clipAction(object.animations[0]);
        action.play();
        mixers.push(mixer);
        const map = (n, x1, y1, x2, y2) =>
          Math.round(((n - x1) * (y2 - x2)) / (y1 - x1) + x2);
        const color0 = HSLToHex(map(decPairs[29], 0, 255, 0, 360), 1, 0.5);
        const color = Number(`0x${color0}`);

        let ghostLight = new THREE.PointLight(color, 150, 100);
        ghostLight.position.set(0, 15, 0);
        object.add(ghostLight);

        object.traverse(function (child) {
          if (child.isMesh) {
            child.material = new THREE.MeshMatcapMaterial({
              matcap: matCap,
              flatShading:
                isEven(rnd(decPairs, 16) * 10) &&
                isEven(rnd(decPairs, 17) * 10) &&
                isEven(rnd(decPairs, 18) * 10),
              transparent:
                isEven(rnd(decPairs, 19) * 10) &&
                isEven(rnd(decPairs, 20) * 10) &&
                isEven(rnd(decPairs, 21) * 10),
              opacity:
                isEven(rnd(decPairs, 19) * 10) &&
                  isEven(rnd(decPairs, 20) * 10) &&
                  isEven(rnd(decPairs, 21) * 10)
                  ? rnd(decPairs, 22)
                  : 1,
            });
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });
        object.rotation.y = rotation;
        object.scale.set(0.3, 0.3, 0.3);
        object.position.set(paramX, paramY + 8, paramZ);
        ghostObjs.push({ id: ghostId, obj: object });
        //object.userData = {name: "ghost"}
        scene.current.add(object);
      });
    }

    function getGravityCollision(pos) {
      if (playerData.fly_mode) return false;
      const downward = new THREE.Vector3(0, -1, 0);
      gravitycaster.current.set(pos, downward);
      const intersectionsGravity = gravitycaster.current.intersectObjects(
        mall.children,
        true
      );
      return intersectionsGravity.length > 0
        ? playerData.height + intersectionsGravity[0].point.y - 2.5
        : 0;
    }

    function getForwardCollision(pos) {
      if (playerData.fly_mode) return false;
      pos = player.current.position.clone();
      const cameraDirection = new THREE.Vector3();
      const direction = camera.current.getWorldDirection(cameraDirection);
      const forward = new THREE.Vector3(direction.x, 0, direction.z);

      raycaster.current.far = playerData.collision_dist - 10;
      raycaster.current.set(pos, forward);
      const intersections = raycaster.current.intersectObjects(
        mall.children,
        true
      );
      if (intersections.length > 0) return true;

      pos.y -= player.current.height - player.current.step;
      raycaster.current.set(pos, forward);
      const intersections_step = raycaster.current.intersectObjects(
        mall.children,
        true
      );
      if (intersections_step.length > 0) return true;
    }
    function getBackwardCollision(pos) {
      if (playerData.fly_mode) return false;
      pos = player.current.position.clone();
      const cameraDirection = new THREE.Vector3();
      const direction = camera.current.getWorldDirection(cameraDirection);
      const backward = new THREE.Vector3(direction.x, 0, -direction.z);

      raycaster.current.far = playerData.collision_dist;
      raycaster.current.set(pos, backward);
      const intersections = raycaster.current.intersectObjects(
        mall.children,
        true
      );
      if (intersections.length > 0) return true;

      pos.y -= player.current.height - player.current.step;
      raycaster.current.set(pos, backward);
      const intersections_step = raycaster.current.intersectObjects(
        mall.children,
        true
      );
      if (intersections_step.length > 0) return true;
    }
    function getLeftCollision(pos) {
      if (playerData.fly_mode) return false;
      pos = player.current.position.clone();
      const cameraDirection = new THREE.Vector3();
      const direction = camera.current.getWorldDirection(cameraDirection);
      const side = new THREE.Vector3(-direction.x, 0, direction.z);

      raycaster.current.far = playerData.collision_dist;
      raycaster.current.set(pos, side);
      const intersections = raycaster.current.intersectObjects(
        mall.children,
        true
      );
      if (intersections.length > 0) return true;

      pos.y -= player.current.height - player.current.step;
      raycaster.current.set(pos, side);
      const intersections_step = raycaster.current.intersectObjects(
        mall.children,
        true
      );
      if (intersections_step.length > 0) return true;
    }
    function getRightCollision(pos) {
      if (playerData.fly_mode) return false;
      pos = player.current.position.clone();
      const cameraDirection = new THREE.Vector3();
      const direction = camera.current.getWorldDirection(cameraDirection);
      const side = new THREE.Vector3(-direction.x, 0, -direction.z);

      raycaster.current.far = playerData.collision_dist;
      raycaster.current.set(pos, side);
      const intersections = raycaster.current.intersectObjects(
        mall.children,
        true
      );
      if (intersections.length > 0) return true;

      pos.y -= player.current.height - player.current.step;
      raycaster.current.set(pos, side);
      const intersections_step = raycaster.current.intersectObjects(
        mall.children,
        true
      );
      if (intersections_step.length > 0) return true;
    }
    function initSceneObjects() {
      // CREATE LIGHT
      const hemLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 0.75);
      hemLight.position.set(0, 0, 0);
      scene.current.add(hemLight);

      // CREATE SKY
      const sky = new Sky();
      sky.scale.setScalar(10000);
      scene.current.add(sky);
      const skyUniforms = sky.material.uniforms;
      skyUniforms["turbidity"].value = 10;
      skyUniforms["rayleigh"].value = 4;
      skyUniforms["mieCoefficient"].value = 0.005;
      skyUniforms["mieDirectionalG"].value = 0.8;

      // CREATE SUN
      sun = new THREE.Vector3();
      const pmremGenerator = new THREE.PMREMGenerator(renderer.current);
      const phi = THREE.MathUtils.degToRad(90 - 1);
      const theta = THREE.MathUtils.degToRad(180);
      sun.setFromSphericalCoords(1, phi, theta);

      // CREATE OCEAN
      const oceanGeometry = new THREE.CircleGeometry(10000, 1000);
      ocean = new Water(oceanGeometry, {
        textureWidth: 512,
        textureHeight: 512,
        waterNormals: new THREE.TextureLoader(manager.current).load(
          water,
          function (texture) {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          }
        ),
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0x006aff,
        distortionScale: 3.7,
      });
      ocean.position.set(0, -2, 0);
      ocean.material.side = THREE.DoubleSide;
      ocean.rotation.x = -Math.PI / 2;
      scene.current.add(ocean);

      const bottomMaterial = new THREE.ShaderMaterial({
        uniforms: {
          color1: {
            value: new THREE.Color(0x000000),
          },
          color2: {
            value: new THREE.Color(0x006aff),
          },
        },
        vertexShader: `
          varying vec2 vUv;
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
          }
        `,
        fragmentShader: `
          uniform vec3 color1;
          uniform vec3 color2;
          varying vec2 vUv;
          void main() {
            gl_FragColor = vec4(mix(color1, color2, vUv.y), 0.5);
          }
        `,
        transparent: true,
        side: THREE.DoubleSide,
      });

      const bottomGeometry = new THREE.SphereGeometry(
        9000,
        50,
        50,
        0,
        2 * Math.PI,
        0,
        0.5 * Math.PI
      );
      const bottom = new THREE.Mesh(bottomGeometry, bottomMaterial);
      bottom.rotation.x = Math.PI;
      bottom.position.set(0, -2.1, 0);
      scene.current.add(bottom);

      // CREATE MOON
      const texLoader = new THREE.TextureLoader(manager.current);
      let moonMaterial = new THREE.MeshBasicMaterial({
        map: texLoader.load(moonTex),
        transparent: true,
        opacity: 0.6,
        fog: false,
      });
      const moonGeometry = new THREE.SphereGeometry(80, 32);
      const moon = new THREE.Mesh(moonGeometry, moonMaterial);
      moon.position.set(0, 1000, 2100);
      scene.current.add(moon);
      const haloMaterial = new THREE.ShaderMaterial({
        vertexShader: `
          varying vec3 vertexNormal;
          void main() {
            vertexNormal = normalize(normalMatrix * normal);
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 0.85 );
          }
        `,
        fragmentShader: `
          varying vec3 vertexNormal;
          void main() {
            float intensity = pow(0.075 - dot(vertexNormal, vec3(0, 0, 1.0)), 1.0);
            gl_FragColor = vec4(1.0, 1.0, 1.0, 0.3) * intensity;
          }
        `,
        fog: false,
        blending: THREE.AdditiveBlending,
        side: THREE.BackSide,
      });
      const moonHalo = new THREE.Mesh(
        new THREE.SphereGeometry(80, 32),
        haloMaterial
      );
      moon.add(moonHalo);
      const moonLight = new THREE.PointLight(0xffffff, 1000, 2000);
      moonLight.position.set(0, -200, -400);
      moon.add(moonLight);

      sky.material.uniforms["sunPosition"].value.copy(sun);
      ocean.material.uniforms["sunDirection"].value.copy(sun).normalize();
      scene.current.environment = pmremGenerator.fromScene(sky).texture;

      // CREATE STARS
      const spark = texLoader.load(sparkpng);
      const glow = texLoader.load(glowpng);
      let vertices = [];
      let materials = [];
      let geometry = new THREE.BufferGeometry();

      for (let i = 0; i < 10000; i++) {
        let x = Math.random() * 2000 - 1000;
        let y = Math.random() * 2000 - 1000;
        let z = Math.random() * 2000 - 1000;
        vertices.push(x, y, z);
      }

      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );

      let parameters = [
        [[Math.random(), 0.9, 0.6], spark, 1.4],
        [[Math.random(), 0.9, 0.6], glow, 1.2],
        [[Math.random(), 0.9, 0.6], glow, 1.15],
        [[Math.random(), 0.9, 0.6], spark, 1.5],
        [[Math.random(), 0.9, 0.6], spark, 1.25],
      ];

      for (let i = 0; i < parameters.length; i++) {
        let color = parameters[i][0];
        let sprite = parameters[i][1];
        let size = parameters[i][2];

        materials[i] = new THREE.PointsMaterial({
          size: size,
          map: sprite,
          blending: THREE.AdditiveBlending,
          depthTest: false,
          transparent: true,
        });
        materials[i].color.setHSL(color[0], color[1], color[2]);

        let stars = new THREE.Points(geometry, materials[i]);

        stars.rotation.x = Math.random() * 6;
        stars.rotation.y = Math.random() * 6;
        stars.rotation.z = Math.random() * 6;

        scene.current.add(stars);
      }

      // CREATE MALL
      const mallLoader = new OBJLoader(manager.current);
      mallLoader.load(mallmodel, function (object) {
        mall = object;

        mall.scale.set(8, 8, 8);
        mall.traverse((child) => {
          if (child.isMesh) {
            child.material = new THREE.MeshStandardMaterial({
              map: texLoader.load(malltex),
              color: 16777215,
              emissive: 16777215,
              metalnessMap: texLoader.load(mallmetal),
              metalness: 1,
              roughness: 1,
              normalMap: texLoader.load(mallnormal),
              roughnessMap: texLoader.load(mallrough),
              emissiveMap: texLoader.load(mallemiss),
              side: THREE.DoubleSide,
            });
            //child.material.map.encoding = 3001;
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });
        scene.current.add(mall);
      });

      const wallGeo = new THREE.PlaneGeometry(130, 30);
      const wallMat = new THREE.MeshPhongMaterial({
        map: texLoader.load(simps1),
        //color: 0x00ff00,
        transparent: true,
        side: THREE.DoubleSide,
      });
      const wall = new THREE.Mesh(wallGeo, wallMat);
      wall.position.set(307.8, 131.9, 365);
      wall.rotation.y = -Math.PI / 2;
      const wallLight = new THREE.PointLight(0xffffff, 50, 70);
      const wallLight2 = new THREE.PointLight(0xffffff, 50, 70);
      wallLight.position.set(30, 0, 15);
      wallLight2.position.set(-30, 0, 15);
      wall.add(wallLight);
      wall.add(wallLight2);
      scene.current.add(wall);

      const wallMat2 = new THREE.MeshPhongMaterial({
        map: texLoader.load(simps2),
        //color: 0x00ff00,
        transparent: true,
        side: THREE.DoubleSide,
      });

      const wall2 = new THREE.Mesh(wallGeo, wallMat2);
      wall2.position.set(220.8, 131.9, 440);
      wall2.rotation.y = -Math.PI;
      const wall2Light = new THREE.PointLight(0xffffff, 50, 70);
      const wall2Light2 = new THREE.PointLight(0xffffff, 50, 70);
      wall2Light.position.set(30, 0, 15);
      wall2Light2.position.set(-30, 0, 15);
      wall2.add(wall2Light);
      wall2.add(wall2Light2);
      scene.current.add(wall2);

      const tipGeo = new THREE.PlaneGeometry(30, 4);
      const tipMat = new THREE.MeshPhongMaterial({
        map: texLoader.load(tipTex),
        color: 0xffffff,
        emissive: 0xfffffff,
        transparent: true,
        side: THREE.DoubleSide,
      });
      const tip = new THREE.Mesh(tipGeo, tipMat);
      tip.position.set(-106, 11.2, 393.18);
      scene.current.add(tip);

      const objLoader = new OBJLoader(manager.current);
      objLoader.load(flamingo, function (object) {
        object.traverse((child) => {
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });
        const flamLight = new THREE.PointLight(0xff00dd, 100, 100);
        flamLight.position.set(0, 15, 0);
        object.add(flamLight);
        object.position.set(160, 108.9, 315);
        object.rotation.y = -0.3 * Math.PI;
        scene.current.add(object);
      });
    }

    const raptureGhosts = () => {
      playerData.fly_mode = true;
      if (ghostObjs.length === 7) {
        ghostObjs.forEach((ghost) => {
          ghost.obj.updateMatrix();
          ghost.obj.translateY(0.7);
        });
      }
      if (player.current.position.y > 100) {
        const blocker = document.getElementById("blocker");
        const raptured = document.getElementById("raptured");
        if (raptured) {
          raptured.style.display = "flex";
          if (blocker) blocker.style.display = "none";
        }
      }
      if (player.current.position.y > 1000) {
        const restart = document.getElementById("restart");
        if (restart) {
          restart.style.display = "flex";
        }
      }
      player.current.translateY(0.7);
      player.current.position.add(new THREE.Vector3(0, 0, 0));
    };
    const captureGhost = (ghostId) => {
      ghostsCaptured.current.push(ghostId);
      if (ghostsCaptured.current.length === 7) return;
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      const capture = async (e) => {
        e.style.display = "flex";
        await delay(1000);
        e.style.opacity = "1";
        await delay(4500);
        e.style.opacity = "0";
        await delay(1000);
        e.style.display = "none";
      };
      const blocker = document.getElementById("blocker");
      const captured = document.getElementById("captured");
      if (blocker) blocker.style.display = "none";
      if (captured) capture(captured);
    };
    return () => {
      cancelAnimationFrame(animation.current);
      clearCanvas();
      if (mallMusic.isPlaying) mallMusic.pause();
      mallMusic.loop = false;
      mallMusic.currentTime = 0;
      mallMusic.removeAttribute("src");
      mallMusic.load();
    };
  }, [started]);

  useEffect(() => {
    const mall_window = document.getElementById("mall_window");
    const resizeObserver = new ResizeObserver((entries) =>
      onWindowResize(
        entries[0].target.clientWidth,
        entries[0].target.clientHeight
      )
    );
    resizeObserver.observe(mall_window);
  }, []);

  useEffect(() => {
    if (ghostsCaptured.current.length === 7) {
      setGlobal({ cdCorrupted: "fixed" });
    }
  }, [ghostsCaptured.current.length]);

  const onWindowResize = (width, height) => {
    if (camera.current) {
      camera.current.aspect = width / height;
      camera.current.updateProjectionMatrix();
      renderer.current.setSize(width, height);
    }
  };

  function setLoop(degrees) {
    if (degrees >= 180) return degrees - 360;
    else if (degrees <= -180) return degrees + 360;
    else return degrees;
  }

  function restart() {
    document.getElementById("blocker").style.display = "flex";
    document.getElementById("instructions").style.display = "flex";
    const raptured = document.getElementById("raptured");
    if (raptured) raptured.style.display = "none";
    const captured = document.getElementById("captured");
    if (captured) captured.style.display = "none";
    const rugDiv = document.getElementById("rugged");
    if (rugDiv) rugDiv.style.display = "none";
    clearCanvas();
    player.current.position.set(
      playerData.pos_x,
      playerData.pos_y + playerData.height,
      playerData.pos_z
    );
    player.current.rotation.y = (playerData.direction / 180) * Math.PI;
    playerData.fly_mode = true;
    player.current.step = playerData.step_size;
    scene.current.add(player.current);
    camera.current.lookAt(0, 0, 0);
  }

  const rugUser = (mallMusic) => {
    ghostsCaptured.current = [];
    controls.current.unlock();
    mallMusic.pause();
    mallMusic.currentTime = 0;
    if (player.current) {
      scene.current.remove(player.current);
    }
    const blocker = document.getElementById("blocker");
    const rugDiv = document.getElementById("rugged");
    if (rugDiv) {
      rugDiv.style.display = "flex";
      if (blocker) blocker.style.display = "none";
    }
  };

  function clearCanvas() {
    playerData.height = 16;
    playerData.pos_x = 0;
    playerData.pos_y = 20;
    playerData.pos_z = 200;
    playerData.jump_velocity = 40;
    playerData.direction = 0;
    playerData.collision_dist = 20;
    playerData.step_size = 5;
    playerData.speed = 10;
    playerData.fly_mode = false;
  }

  return (
    <Div>
      <div className="window_content" id="mall_window">
        <div className="window_content_inner">
          <div id="mall_loading" className="container">
            <img draggable={false} src={loadImg} alt="loading" />
          </div>

          <div id="blocker" className="pointer container crt">
            <div id="instructions">
              {!isDesktop && <h4>Mall Of Tortured Souls works best on a desktop or laptop computer.</h4>}
              {isDesktop && ghostsCaptured.current[0] !== "complete" && (
                <h4>Capture all seven ghosts</h4>
              )}
              {isDesktop && ghostsCaptured.current[0] === "complete" && (
                <h4>Seven ghosts have been raptured</h4>
              )}
              {isDesktop && (<>
                <p>Move:&#160;&#160;⇦⇧⇨⇩/WASD</p>
                <p>Jump:&#160;&#160;SPACE&#160;&#160;&#160;&#160;&#160;</p>
                <p>Look:&#160;&#160;MOUSE&#160;&#160;&#160;&#160;&#160;</p>
                <p>Pause:&#160;ESC&#160;&#160;&#160;&#160;&#160;&#160;&#160;</p>
                <p>Start:&#160;CLICK&#160;&#160;&#160;&#160;&#160;</p></>
              )}

            </div>
          </div>

          <div id="rugged" onClick={restart}>
            <div className="container crt grow"></div>
            <div className="container grayen"></div>
            <div className="container rug">
              <h4>RUGGED!</h4>
              <p>you are ngmi.</p>
              <p>restart?</p>
            </div>
          </div>

          <div id="raptured" onClick={restart}>
            <div className="container">
              <h4>RAPTURED!</h4>
              <p>
                All ghosts have been captured and
                <br />
                are now ascending to heaven in a rapture.
                <br />
                wgmi.
              </p>
              <p id="restart">restart?</p>
            </div>
          </div>

          {ghostsCaptured.current.length < 7 && (
            <div id="captured">
              <div className="container">
                {ghostsCaptured.current.length === 1 && (
                  <h4>
                    One ghost captured.
                    <br />
                    Six ghosts to go.
                  </h4>
                )}
                {ghostsCaptured.current.length === 2 && (
                  <h4>
                    Two ghosts captured.
                    <br />
                    Five ghosts to go.
                  </h4>
                )}
                {ghostsCaptured.current.length === 3 && (
                  <h4>
                    Three ghosts captured.
                    <br />
                    Four ghosts to go.
                  </h4>
                )}
                {ghostsCaptured.current.length === 4 && (
                  <h4>
                    Four ghosts captured.
                    <br />
                    Three ghosts to go.
                  </h4>
                )}
                {ghostsCaptured.current.length === 5 && (
                  <h4>
                    Five ghosts captured.
                    <br />
                    Two ghosts to go.
                  </h4>
                )}
                {ghostsCaptured.current.length === 6 && (
                  <h4>
                    Six ghosts captured.
                    <br />
                    One ghost to go.
                  </h4>
                )}
              </div>
            </div>
          )}
          <div
            className="canvasreference"
            id="mall_canvas"
            ref={canvasReference}
          ></div>
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 1px 0 0 0;
  text-align: center;
  font-family: "graph35";
  font-size: clamp(10px, 0.9vw, 20px);
  letter-spacing: 2px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  text-transform: uppercase;
  .window_content {
    height: 100%;
  }
  h4 {
    line-height: 2vh;
    margin-bottom: 24px;
  }
  p {
    color: white;
    line-height: 1.8vh;
    font-size: clamp(10px, 0.6vw, 14px);
    margin: 2px 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px;
  }
  .window_content_inner {
    height: calc(100% - 3px);
    overflow: hidden;
  }
  .crt {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAEAQMAAACTPww9AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAAA1JREFUCNdjOACEQAAACQgBgeEHEZ0AAAAASUVORK5CYII=);
    background-size: 2px 2px;
  }
  #mall_loading {
    background-image: url(${tiles});
    background-repeat: repeat;
    z-index: 3;
  }
  .grayen {
    animation: grayen 6s forwards;
  }
  .grow {
    top: 0;
    animation: grow 5s forwards;
    animation-timing-function: cubic-bezier(0.32, 0.48, 0.55, 0.75);
  }
  #restart {
    display: none;
  }
  #rugged p {
    text-align: justify;
  }
  #captured {
    color: white;
    display: none;
    opacity: 0;
    transition: opacity 1s;
  }
  #rugged,
  #raptured {
    color: white;
    display: none;
    h4,
    p {
      opacity: 0;
      animation-name: fadein;
      animation-duration: 4s;
      animation-delay: 1s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
    h4 + p {
      letter-spacing: 8px;
      margin: 8px 0 24px 0;
    }
  }
  #blocker {
    color: white;
    z-index: 2;
    backdrop-filter: brightness(0.8) grayscale(0.4);
  }
  #instructions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      max-width: 200px;
      text-align: center;
    }
  }
`;

export default Mall;
