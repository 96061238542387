import * as Tone from "tone";

const padSamples = [
  {
    synth: Tone.AMSynth,
    options: {
      harmonicity: 3.999,
      oscillator: {
        type: "square",
      },
      envelope: {
        attack: 0.03,
        decay: 0.3,
        sustain: 0.7,
        release: 0.8,
      },
      modulation: {
        volume: 12,
        type: "square6",
      },
      modulationEnvelope: {
        attack: 2,
        decay: 3,
        sustain: 0.8,
        release: 0.1,
      },
    },
  },
  {
    synth: Tone.AMSynth,
    options: {
      harmonicity: 2,
      oscillator: {
        type: "amsine2",
        modulationType: "sine",
        harmonicity: 1.01,
      },
      envelope: {
        attack: 0.006,
        decay: 4,
        sustain: 0.04,
        release: 1.2,
      },
      modulation: {
        volume: 13,
        type: "amsine2",
        modulationType: "sine",
        harmonicity: 12,
      },
      modulationEnvelope: {
        attack: 0.006,
        decay: 0.2,
        sustain: 0.2,
        release: 0.4,
      },
    },
  },
  {
    synth: Tone.DuoSynth,
    options: {
      vibratoAmount: 0.5,
      vibratoRate: 5,
      harmonicity: 1.5,
      voice0: {
        volume: -10,
        portamento: 0,
        oscillator: {
          type: "sine",
        },
        filterEnvelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
        envelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
      },
      voice1: {
        volume: -10,
        portamento: 0,
        oscillator: {
          type: "sine",
        },
        filterEnvelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
        envelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
      },
    },
  },
  {
    synth: Tone.DuoSynth,
    options: {
      vibratoAmount: 0.5,
      vibratoRate: 10,
      harmonicity: 1.5,
      voice0: {
        volume: -5,
        portamento: 0,
        oscillator: {
          type: "sine",
        },
        filterEnvelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
        envelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
      },
      voice1: {
        volume: 0,
        portamento: 0,
        oscillator: {
          type: "sine",
        },
        filterEnvelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
        envelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
      },
    },
  },
  {
    synth: Tone.DuoSynth,
    options: {
      vibratoAmount: 0.5,
      vibratoRate: 5,
      harmonicity: 1.5,
      voice0: {
        volume: -10,
        portamento: 0,
        oscillator: {
          type: "sine",
        },
        filterEnvelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
        envelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
      },
      voice1: {
        volume: -10,
        portamento: 0,
        oscillator: {
          type: "sine",
        },
        filterEnvelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
        envelope: {
          attack: 0.01,
          decay: 0,
          sustain: 1,
          release: 0.5,
        },
      },
    },
  },
  {
    synth: Tone.FMSynth,
    options: {
      harmonicity: 8,
      modulationIndex: 2,
      oscillator: {
        type: "sine",
      },
      envelope: {
        attack: 0.001,
        decay: 2,
        sustain: 0.1,
        release: 2,
      },
      modulation: {
        type: "square",
      },
      modulationEnvelope: {
        attack: 0.002,
        decay: 0.2,
        sustain: 0,
        release: 0.2,
      },
    },
  },
  {
    synth: Tone.FMSynth,
    options: {
      harmonicity: 3.01,
      modulationIndex: 14,
      oscillator: {
        type: "triangle",
      },
      envelope: {
        attack: 0.2,
        decay: 0.3,
        sustain: 0.1,
        release: 1.2,
      },
      modulation: {
        type: "square",
      },
      modulationEnvelope: {
        attack: 0.01,
        decay: 0.5,
        sustain: 0.2,
        release: 0.1,
      },
    },
  },
  // {
  //   synth: Tone.FMSynth,
  //   options: {
  //     harmonicity: 0.5,
  //     modulationIndex: 1.2,
  //     oscillator: {
  //       type: "fmsawtooth",
  //       modulationType: "sine",
  //       modulationIndex: 20,
  //       harmonicity: 3,
  //     },
  //     envelope: {
  //       attack: 0.05,
  //       decay: 0.3,
  //       sustain: 0.1,
  //       release: 1.2,
  //     },
  //     modulation: {
  //       volume: 0,
  //       type: "triangle",
  //     },
  //     modulationEnvelope: {
  //       attack: 0.35,
  //       decay: 0.1,
  //       sustain: 1,
  //       release: 0.01,
  //     },
  //   },
  // },
  {
    synth: Tone.MembraneSynth,
    options: {
      pitchDecay: 0.05,
      octaves: 10,
      oscillator: {
        type: "sine",
      },
      envelope: {
        attack: 0.001,
        decay: 0.4,
        sustain: 0.01,
        release: 1.4,
        attackCurve: "exponential",
      },
    },
  },
  {
    synth: Tone.MembraneSynth,
    options: {
      pitchDecay: 0.08,
      octaves: 10,
      oscillator: {
        type: "sine",
      },
      envelope: {
        attack: 0.001,
        decay: 0.8,
        sustain: 0.1,
        release: 2,
        attackCurve: "step",
      },
    },
  },
  {
    synth: Tone.MembraneSynth,
    options: {
      pitchDecay: 0.08,
      octaves: 4,
      oscillator: {
        type: "sine",
      },
      envelope: {
        attack: 0.001,
        decay: 0.8,
        sustain: 1,
        release: 2,
        attackCurve: "step",
      },
    },
  },
  {
    synth: Tone.MembraneSynth,
    options: {
      pitchDecay: 1,
      octaves: 4,
      oscillator: {
        type: "sine",
      },
      envelope: {
        attack: 0.001,
        decay: 0.4,
        sustain: 0.01,
        release: 1.4,
        attackCurve: "exponential",
      },
    },
  },
  {
    synth: Tone.MonoSynth,
    options: {
      volume: 10,
      oscillator: {
        type: "sawtooth",
      },
      filter: {
        Q: 2,
        type: "bandpass",
        rolloff: -24,
      },
      envelope: {
        attack: 0.01,
        decay: 0.1,
        sustain: 0.2,
        release: 0.6,
      },
      filterEnvelope: {
        attack: 0.02,
        decay: 0.4,
        sustain: 1,
        release: 0.7,
        releaseCurve: "linear",
        baseFrequency: 20,
        octaves: 5,
      },
    },
  },
  {
    synth: Tone.MonoSynth,
    options: {
      oscillator: {
        type: "fmsquare5",
        modulationType: "triangle",
        modulationIndex: 2,
        harmonicity: 0.501,
      },
      filter: {
        Q: 1,
        type: "lowpass",
        rolloff: -24,
      },
      envelope: {
        attack: 0.01,
        decay: 0.1,
        sustain: 0.4,
        release: 2,
      },
      filterEnvelope: {
        attack: 0.01,
        decay: 0.1,
        sustain: 0.8,
        release: 1.5,
        baseFrequency: 50,
        octaves: 4.4,
      },
    },
  },
  // {
  //   synth: Tone.MonoSynth,
  //   options: {
  //     oscillator: {
  //       type: "pwm",
  //       modulationFrequency: 1,
  //     },
  //     filter: {
  //       Q: 6,
  //       rolloff: -24,
  //     },
  //     envelope: {
  //       attack: 0.025,
  //       decay: 0.3,
  //       sustain: 0.9,
  //       release: 2,
  //     },
  //     filterEnvelope: {
  //       attack: 0.245,
  //       decay: 0.131,
  //       sustain: 0.5,
  //       release: 2,
  //       baseFrequency: 20,
  //       octaves: 7.2,
  //       exponent: 2,
  //     },
  //   },
  // },
  // {
  //   synth: Tone.MonoSynth,
  //   options: {
  //     oscillator: {
  //       type: "square",
  //     },
  //     filter: {
  //       Q: 2,
  //       type: "lowpass",
  //       rolloff: -12,
  //     },
  //     envelope: {
  //       attack: 0.005,
  //       decay: 3,
  //       sustain: 0,
  //       release: 0.45,
  //     },
  //     filterEnvelope: {
  //       attack: 0.001,
  //       decay: 0.32,
  //       sustain: 0.9,
  //       release: 3,
  //       baseFrequency: 700,
  //       octaves: 2.3,
  //     },
  //   },
  // },
  // {
  //   synth: Tone.MonoSynth,
  //   options: {
  //     oscillator: {
  //       type: "sawtooth",
  //     },
  //     filter: {
  //       Q: 3,
  //       type: "highpass",
  //       rolloff: -12,
  //     },
  //     envelope: {
  //       attack: 0.01,
  //       decay: 0.3,
  //       sustain: 0,
  //       release: 0.9,
  //     },
  //     filterEnvelope: {
  //       attack: 0.01,
  //       decay: 0.1,
  //       sustain: 0,
  //       release: 0.1,
  //       baseFrequency: 800,
  //       octaves: -1.2,
  //     },
  //   },
  // },
  {
    synth: Tone.Synth,
    options: {
      oscillator: {
        type: "fatsine4",
        spread: 60,
        count: 10,
      },
      envelope: {
        attack: 0.4,
        decay: 0.01,
        sustain: 1,
        attackCurve: "sine",
        releaseCurve: "sine",
        release: 0.4,
      },
    },
  },
  // {
  //   synth: Tone.Synth,
  //   options: {
  //     portamento: 0.0,
  //     oscillator: {
  //       type: "square4",
  //     },
  //     envelope: {
  //       attack: 2,
  //       decay: 1,
  //       sustain: 0.2,
  //       release: 2,
  //     },
  //   },
  // },
  // {
  //   synth: Tone.Synth,
  //   options: {
  //     oscillator: {
  //       type: "pulse",
  //       width: 0.8,
  //     },
  //     envelope: {
  //       attack: 0.01,
  //       decay: 0.05,
  //       sustain: 0.2,
  //       releaseCurve: "bounce",
  //       release: 0.4,
  //     },
  //   },
  // },
  {
    synth: Tone.Synth,
    options: {
      oscillator: {
        partials: [1, 0, 2, 0, 3],
      },
      envelope: {
        attack: 0.001,
        decay: 1.2,
        sustain: 0,
        release: 1.2,
      },
    },
  },
  {
    synth: Tone.Synth,
    options: {
      oscillator: {
        type: "fatcustom",
        partials: [0.2, 1, 0, 0.5, 0.1],
        spread: 40,
        count: 3,
      },
      envelope: {
        attack: 0.001,
        decay: 1.6,
        sustain: 0,
        release: 1.6,
      },
    },
  },
  {
    synth: Tone.Synth,
    options: {
      oscillator: {
        type: "fatsawtooth",
        count: 3,
        spread: 30,
      },
      envelope: {
        attack: 0.01,
        decay: 0.1,
        sustain: 0.5,
        release: 0.4,
        attackCurve: "exponential",
      },
    },
  },
  {
    synth: Tone.Synth,
    options: {
      oscillator: {
        type: "triangle",
      },
      envelope: {
        attack: 0.005,
        decay: 0.1,
        sustain: 0.3,
        release: 1,
      },
    },
  },
  {
    synth: Tone.Synth,
    options: {
      oscillator: {
        type: "sine",
      },
      envelope: {
        attack: 0.001,
        decay: 0.1,
        sustain: 0.1,
        release: 1.2,
      },
    },
  },
  {
    synth: Tone.Synth,
    options: {
      oscillator: {
        type: "sine",
      },
      envelope: {
        attack: 0.1,
        decay: 0.1,
        sustain: 0.1,
        release: 1.2,
      },
    },
  },
];

export default padSamples;
