import React, { useRef, memo, useGlobal } from "reactn";
import styled from "styled-components";
import HeaderButtons from "./HeaderButtons";
import { useElementResize } from "hooks";
import { clamp, hslToObject } from "utils";

function Windows({
  apps,
  onPointerDown,
  onClose,
  onHelp,
  onMinimize,
  onMaximize,
  focusedAppId,
  onDoubleClickIcon,
  onToggleComponent,
}) {
  const root = document.documentElement;
  const compStyle = getComputedStyle(root);
  const state = {
    zoom: compStyle.getPropertyValue('--zoom'),
    headerColor: compStyle.getPropertyValue('--headerColor'),
    themeColor: compStyle.getPropertyValue('--colorgrad3')
  }
  const headerColor = hslToObject(state.headerColor);
  const themeColor = hslToObject(state.themeColor);
  const zoom = state.zoom ? state.zoom : 1;

  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      {apps.map((app) => (
        <StyledWindow
          show={!app.minimized}
          key={app.id}
          id={app.id}
          onPointerDown={onPointerDown}
          onPointerUpClose={onClose}
          onDoubleClickIcon={onDoubleClickIcon}
          onToggleComponent={onToggleComponent}
          onPointerUpHelp={onHelp}
          onPointerUpMinimize={onMinimize}
          onPointerUpMaximize={onMaximize}
          headerColor={headerColor}
          themeColor={themeColor}
          zoom={zoom}
          focused={focusedAppId === app.id} // for styledWindow
          {...app}
        />
      ))}
    </div>
  );
}

const Window = memo(function ({
  injectProps,
  id,
  onToggleComponent,
  onPointerDown,
  onPointerUpClose,
  onPointerUpMinimize,
  onPointerUpMaximize,
  header,
  defaultSize,
  defaultOffset,
  resizable,
  error,
  maximized,
  component,
  zIndex,
  focused,
  className,
  onDoubleClickIcon,
  zoom
}) {
  function _onPointerUpHelp() {
    window.open("https://metamask.io/", "_blank");
  }
  function _onPointerDown() {
    onPointerDown(id);
  }
  function _onPointerUpClose() {
    onPointerUpClose(id);
  }
  function _onPointerUpMinimize() {
    onPointerUpMinimize(id);
  }
  function _onPointerUpMaximize() {
    if (resizable) onPointerUpMaximize(id);
  }
  const [appLoading] = useGlobal("appLoading");
  const dragRef = useRef(null);
  const ref = useRef(null);
  const windowWidth = zoom ? window.innerWidth * 1/zoom  : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const windowHeight = zoom ? window.innerHeight * 1/zoom  : window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const { offset, size } = useElementResize(ref, {
    dragRef,
    defaultOffset,
    defaultSize,
    boundary: {
      top: 0,
      left: 0,
      right: windowWidth - 3,
      bottom: windowHeight - 40, //footerHeight
    },
    error,
    resizable,
    resizeThreshold: 10,
  });

  let minW = size && size.minWidth ? size.minWidth + "px" : "auto";
  let maxW = size && size.maxWidth ? size.maxWidth + "px" : windowWidth;
  let minH = size && size.minHeight ? size.minHeight + "px" : "auto";
  let maxH = size && size.maxHeight ? size.maxHeight + "px" : windowHeight;
  let w = maximized
    ? windowWidth
    : size && size.width
    ? clamp(size.width, minW ? minW : 0, maxW ? maxW : windowWidth) + "px"
    : "auto";
  let h = maximized
    ? windowHeight - 37
    : size && size.height
    ? clamp(size.height, minH ? minH : 0, maxH ? maxH : windowHeight - 37) +
      "px"
    : "auto";
  let x = maximized ? "0" : clamp(offset.x, 0, windowWidth - w);
  let y = maximized ? "0" : clamp(offset.y, 0, windowHeight - 37 - h);
  return (
    <div
      className={
        className +
        " app_" +
        header.title.toLowerCase().replace(/[^a-z0-9]/g, "")
      }
      onPointerDown={_onPointerDown}
      style={{
        transform: `translate(${x}px,${y}px)`,
        width: w,
        height: h,
        maxWidth: maxW,
        minWidth: minW,
        minHeight: minH,
        maxHeight: maxH,
        zIndex,
      }}
    >
      <div className="window" ref={ref}>
        <header className="window_header" ref={dragRef}>
          {error ? "" : <img
            src={header.icon}
            alt={header.alt ? header.alt : header.title}
            className="window_header_icon"
          />}
          <div className="window_header_title">
            {header.alt ? header.alt : header.title}
          </div>
          <HeaderButtons
            buttons={header.buttons}
            onMaximize={_onPointerUpMaximize}
            onMinimize={_onPointerUpMinimize}
            onHelp={_onPointerUpHelp}
            onClose={_onPointerUpClose}
            maximized={maximized}
            resizable={resizable}
            focused={focused}
          />
        </header>
        {component({
          id: id,
          offset: offset,
          size: size,
          onDoubleClickIcon: onDoubleClickIcon,
          onToggleComponent: onToggleComponent,
          onClose: _onPointerUpClose,
          onMinimize: _onPointerUpMinimize,
          onMaximize: _onPointerUpMaximize,
          maximized: maximized,
          focused,
          ...injectProps,
        })}
        <section className="window_statusbar">
          <div>
            {appLoading &&
            appLoading.name === header.title &&
            appLoading.id === id
              ? "Please wait."
              : "Done"}
          </div>
          <div>
            <p>
              {appLoading &&
              appLoading.name === header.title &&
              appLoading.id === id
                ? "Loading..."
                : ""}
            </p>
          </div>
        </section>
      </div>
    </div>
  );
});

const StyledWindow = styled(Window)`
  box-sizing: border-box;
  display: ${({ show }) => (show ? "flex" : "none")};
  position: absolute;
  background: var(--colorgrad3);
  padding: 1px;
  box-shadow: inset -1px -1px 0 0 var(--colorgrad7),
    inset 1px 1px 0 0 var(--colorgrad2);
  flex-direction: column;
  font-size: 11px;
  line-height: 11px;
  .window {
    width: 100%;
    height: 100%;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad5),
      inset 1px 1px 0 0 var(--colorgrad0);
    background: var(--colorgrad3);
    padding: 4px;
    display: flex;
    flex-direction: column;
    @keyframes type {
      from {
        width: 0;
      }
    }
  .window_header {
    padding: 1px;
    background: ${({ focused, headerColor, themeColor }) =>
      focused
        ? `linear-gradient(90deg, hsl(${headerColor.h}, ${headerColor.s}%, ${
            headerColor.l - 2
          }%), hsl(${headerColor.h}, ${clamp(
            headerColor.s - 15,
            0,
            100
          )}%, ${clamp(headerColor.l + 5, 0, 100)}%), hsl(${
            headerColor.h
          }, ${clamp(headerColor.s - 20, 0, 100)}%, ${clamp(
            headerColor.l + 8,
            0,
            100
          )}%),	hsl(${headerColor.h}, ${headerColor.s}%, ${headerColor.l - 2}%))`
        : `hsl(${themeColor.h}, ${clamp(themeColor.s - 5, 0, 50)}%, ${clamp(themeColor.l + 10, 50, 85)}%)`};
    height: 22px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    font-weight: 700;
  }
  .window_header_icon {
    pointer-events: none;
    height: 16px;
    margin-left: 3px;
  }
  .window_header_title {
    color: white;
    flex: 1;
    pointer-events: none;
    padding: 5px;
    letter-spacing: 1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .window_statusbar {
    margin-top: 4px;
    display: ${({ status }) => (status ? "flex" : "none")};
    align-items: center;
    div + div {
      margin-left: 3px;
    }
  }
  .window_statusbar p {
    width: 15ch;
    animation: type 4s steps(15, end) infinite;
    overflow: hidden;
  }
  .window_statusbar div {
    padding: 0 5px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    height: 20px;
    width: 50%;
    background-color: var(--colorgrad3);
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0),
      inset 1px 1px 0 0 var(--colorgrad7), inset -2px -2px 0 0 var(--colorgrad2),
      inset 2px 2px 0 0 var(--colorgrad3);
  }

  .window_statusbar::after {
    position: absolute;
    bottom: 6px;
    right: 6px;
    height: 12px;
    width: 12px;
    content: "";
    background-image: url("data:image/gif;base64,R0lGODlhDAAMAJEAAAAAAP///5mZmf///yH5BAEAAAMALAAAAAAMAAwAAAIbnI8TmSF83IMSKvFWw3dnHnFV+GVGhZZXmaoFADs=");
  }
`;

export default Windows;
