import { PALETTE } from "../constants";
import { nearest } from "../palettes";
import { cloneCanvas } from "../utils";
import convolve, { GAUSSIAN_3X3_WEAK } from "./convolve";
import jitter from "./jitter";
import exposure from "./exposure";
import channelSeparation from "./channelSeparation";

const optionTypes = {
  jitterX: jitter.optionTypes.jitterX,
  jitterXSpread: jitter.optionTypes.jitterXSpread,
  palette: { type: PALETTE, default: nearest }
};

const defaults = {
  jitterX: 1,
  jitterXSpread: jitter.defaults.jitterXSpread,
  palette: { ...optionTypes.palette.default, options: { levels: 1 } }
};

const vhs = (
  input: HTMLCanvasElement,
  options: {
    jitterX: number,
    jitterXSpread: number,
    palette: Palette
  } = defaults
): HTMLCanvasElement => {
  const { jitterX, jitterXSpread, palette } = options;

  let output = cloneCanvas(input, false);

  const inputCtx = input.getContext("2d", {
  willReadFrequently: true
});
  const outputCtx = output.getContext("2d", {
  willReadFrequently: true
});

  if (!inputCtx || !outputCtx) {
    return input;
  }

  output = jitter.func(input, { ...jitter.defaults, jitterX, jitterXSpread });

  output = exposure.func(output, {
    ...exposure.defaults,
    brightness: 60,
    contrast: -0.2,
    exposure: 0.7,
    gamma: 0.6,
    palette: palette
  });
  output = channelSeparation.func(output, {
    ...channelSeparation.defaults,
    rOffsetX: 2,
    rOffsetY: 2,
    gOffsetX: 2,
    gOffsetY: 1,
    bOffsetX: 2,
    bOffsetY: 1
  });
  output = convolve.func(output, {
    ...convolve.defaults,
    select: GAUSSIAN_3X3_WEAK
  });

  return output;
};

const defaultFunc = {
  name: "VHS emulation",
  func: vhs,
  options: defaults,
  optionTypes,
  defaults
}

export default defaultFunc;
