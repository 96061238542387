const File = [
  {
    type: 'item',
    text: 'Open...',
    disable: false,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Save',
    disable: true,
  },
  {
    type: 'item',
    text: 'Save As...',
    disable: true,
  },
];
const View = [
  {
    type: 'item',
    text: 'Waveform',
    check: true,
  },
  {
    type: 'item',
    text: 'Frame Stats',
    check: false,
  },
  {
    type: 'item',
    text: 'Information',
    check: true,
  },
  {
    type: 'item',
    text: 'Fullscreen',
    check: false,
  },
];
const Settings = [
  {
    type: 'menu',
    position: {
      left: 'calc(100% - 4px)',
      top: '-20px',
    },
    text: 'Video',
    items: [
      {
        type: 'item',
        text: 'Fixed Ratio',
        check: false,
      },
      {
        type: 'item',
        text: 'Initial Size',
        check: false,
      },
      {
        type: 'item',
        text: 'Repeat',
        check: true,
      }
    ],
  },
  {
    type: 'menu',
    position: {
      left: 'calc(100% - 4px)',
      top: '-20px',
    },
    text: 'Audio',
    items: [
      {
        type: 'item',
        text: 'Loop',
        check: true,
      },
    ],
  },
];
const Help = [
  {
    type: 'item',
    text: 'About...',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Help Center',
    disable: false,
  },
];
const Edit = [
  {
    type: 'item',
    text: 'Select All',
    disable: true,
  },
  {
    type: 'item',
    text: 'Select None',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Refresh',
    disable: true,
  },
  {
    type: 'item',
    text: 'Close',
    disable: false,
  },
];

const optionsMenuData = { File, Settings, View, Help, Edit };
export default optionsMenuData;
