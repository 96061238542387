import React from "react";
import styled from "styled-components";

const Sky8 = () => {
	return (
		<Div className="skysection8 content animate selectable">
			<div className="inner-circle animate">
				<div>
					<strong>Doors? Windows?</strong>
					<p>
						Who needs the world outside when you have an eternal stream of mesmerizing, hypnotizing loops that make you want to stay indoors...
					</p>
					<strong>Forever.</strong>
					<p>
						We mean it. You are never leaving. Even if you wanted to.
					</p>
				</div>
			</div>
			<div className="outer-circle">
				<div className="moving-text">
					<p className="char">s</p>
					<p className="char">k</p>
					<p className="char">y</p>
					<p className="char">.</p>
					<p className="char">l</p>
					<p className="char">u</p>
					<p className="char">x</p>
					<p className="char">u</p>
					<p className="char">r</p>
					<p className="char">y</p>
					<p className="char"> </p>
					<p className="char">i</p>
					<p className="char">s</p>
					<p className="char"> </p>
					<p className="char">t</p>
					<p className="char">h</p>
					<p className="char">e</p>
					<p className="char"> </p>
					<p className="char">b</p>
					<p className="char">e</p>
					<p className="char">s</p>
					<p className="char">t</p>
					<p className="char"> </p>
					<p className="char">a</p>
					<p className="char">n</p>
					<p className="char">d</p>
					<p className="char"> </p>
					<p className="char">l</p>
					<p className="char">a</p>
					<p className="char">s</p>
					<p className="char">t</p>
					<p className="char"> </p>
					<p className="char">r</p>
					<p className="char">e</p>
					<p className="char">a</p>
					<p className="char">l</p>
					<p className="char"> </p>
					<p className="char">e</p>
					<p className="char">s</p>
					<p className="char">t</p>
					<p className="char">a</p>
					<p className="char">t</p>
					<p className="char">e</p>
					<p className="char"> </p>
					<p className="char">y</p>
					<p className="char">o</p>
					<p className="char">u</p>
					<p className="char"> </p>
					<p className="char">w</p>
					<p className="char">i</p>
					<p className="char">l</p>
					<p className="char">l</p>
					<p className="char"> </p>
					<p className="char">e</p>
					<p className="char">v</p>
					<p className="char">e</p>
					<p className="char">r</p>
					<p className="char"> </p>
					<p className="char">n</p>
					<p className="char">e</p>
					<p className="char">e</p>
					<p className="char">d</p>
					<p className="char">!</p>
					<p className="char"> </p>
				</div>
			</div>
		</Div>
	);
};

const Div = styled.div`
display: flex;
justify-content: center;
--pathSize: 200px;
p {
	line-height: 1.15;
}
strong {
  font-size: 1.4em;
  letter-spacing: 3px;
  line-height: 1.1;
}
.inner-circle {
	z-index: 2;
	--cx: 50%;
	--cy: 50%;
	--w: calc(var(--pathSize) * 1.2);
	--h: calc(var(--pathSize) * 1.2);
	top: var(--cx);
	left: var(--cy);
	width: var(--w);
	height: var(--h);
	transform: translate(-50%, -50%);
	position: absolute;
	text-align: justify;
	text-align-last: center;
	border-radius: 50%;
	overflow: hidden;
}

.inner-circle>div {
height: 100%;
	* {
		text-align: justify !important;
		text-align-last: center;
	}
}

.inner-circle:before,
.inner-circle>div:before {
	content: '';
	float: left;
	height: 100%;
	width: 50%;
	shape-outside: radial-gradient(farthest-side at var(--d, right), transparent 98%, red 0);
}

.inner-circle>div:before {
	float: right;
	--d: left;
}

.outer-circle {
	position: absolute;
	width: calc(var(--pathSize) * 2);
	aspect-ratio: 1/1;
	transform: translate(calc(var(--pathSize)/50),0);
}

.moving-text {
	position: absolute;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	aspect-ratio: 1/1;
}

.char {
	offset-path: path("M 200, 200 m -150, 0 a 150,150 0 1,0 300,0 a 150,150 0 1,0 -300,0");
	--interval: -1s;
	--totalchars: 64;
	animation: offsetText calc(var(--interval) * var(--totalchars) * -1) linear infinite, changeTextColor calc(var(--interval) * var(--totalchars) * -1) ease-in-out infinite alternate;
}

.char:nth-child(1) {
	animation-delay: calc(0 * var(--interval));
}

.char:nth-child(2) {
	animation-delay: calc(1 * var(--interval));
}

.char:nth-child(3) {
	animation-delay: calc(2 * var(--interval));
}

.char:nth-child(4) {
	animation-delay: calc(3 * var(--interval));
}

.char:nth-child(5) {
	animation-delay: calc(4 * var(--interval));
}

.char:nth-child(6) {
	animation-delay: calc(5 * var(--interval));
}

.char:nth-child(7) {
	animation-delay: calc(6 * var(--interval));
}

.char:nth-child(8) {
	animation-delay: calc(7 * var(--interval));
}

.char:nth-child(9) {
	animation-delay: calc(8 * var(--interval));
}

.char:nth-child(10) {
	animation-delay: calc(9 * var(--interval));
}

.char:nth-child(11) {
	animation-delay: calc(10 * var(--interval));
}

.char:nth-child(12) {
	animation-delay: calc(11 * var(--interval));
}

.char:nth-child(13) {
	animation-delay: calc(12 * var(--interval));
}

.char:nth-child(14) {
	animation-delay: calc(13 * var(--interval));
}

.char:nth-child(15) {
	animation-delay: calc(14 * var(--interval));
}

.char:nth-child(16) {
	animation-delay: calc(15 * var(--interval));
}

.char:nth-child(17) {
	animation-delay: calc(16 * var(--interval));
}

.char:nth-child(18) {
	animation-delay: calc(17 * var(--interval));
}

.char:nth-child(19) {
	animation-delay: calc(18 * var(--interval));
}

.char:nth-child(20) {
	animation-delay: calc(19 * var(--interval));
}

.char:nth-child(21) {
	animation-delay: calc(20 * var(--interval));
}

.char:nth-child(22) {
	animation-delay: calc(21 * var(--interval));
}

.char:nth-child(23) {
	animation-delay: calc(22 * var(--interval));
}

.char:nth-child(24) {
	animation-delay: calc(23 * var(--interval));
}

.char:nth-child(25) {
	animation-delay: calc(24 * var(--interval));
}

.char:nth-child(26) {
	animation-delay: calc(25 * var(--interval));
}

.char:nth-child(27) {
	animation-delay: calc(26 * var(--interval));
}

.char:nth-child(28) {
	animation-delay: calc(27 * var(--interval));
}

.char:nth-child(29) {
	animation-delay: calc(28 * var(--interval));
}

.char:nth-child(30) {
	animation-delay: calc(29 * var(--interval));
}

.char:nth-child(31) {
	animation-delay: calc(30 * var(--interval));
}

.char:nth-child(32) {
	animation-delay: calc(31 * var(--interval));
}

.char:nth-child(33) {
	animation-delay: calc(32 * var(--interval));
}

.char:nth-child(34) {
	animation-delay: calc(33 * var(--interval));
}

.char:nth-child(35) {
	animation-delay: calc(34 * var(--interval));
}

.char:nth-child(36) {
	animation-delay: calc(35 * var(--interval));
}

.char:nth-child(37) {
	animation-delay: calc(36 * var(--interval));
}

.char:nth-child(38) {
	animation-delay: calc(37 * var(--interval));
}

.char:nth-child(39) {
	animation-delay: calc(38 * var(--interval));
}

.char:nth-child(40) {
	animation-delay: calc(39 * var(--interval));
}

.char:nth-child(41) {
	animation-delay: calc(40 * var(--interval));
}

.char:nth-child(42) {
	animation-delay: calc(41 * var(--interval));
}

.char:nth-child(43) {
	animation-delay: calc(42 * var(--interval));
}

.char:nth-child(44) {
	animation-delay: calc(43 * var(--interval));
}

.char:nth-child(45) {
	animation-delay: calc(44 * var(--interval));
}

.char:nth-child(46) {
	animation-delay: calc(45 * var(--interval));
}

.char:nth-child(47) {
	animation-delay: calc(46 * var(--interval));
}

.char:nth-child(48) {
	animation-delay: calc(47 * var(--interval));
}

.char:nth-child(49) {
	animation-delay: calc(48 * var(--interval));
}

.char:nth-child(50) {
	animation-delay: calc(49 * var(--interval));
}

.char:nth-child(51) {
	animation-delay: calc(50 * var(--interval));
}

.char:nth-child(52) {
	animation-delay: calc(51 * var(--interval));
}

.char:nth-child(53) {
	animation-delay: calc(52 * var(--interval));
}

.char:nth-child(54) {
	animation-delay: calc(53 * var(--interval));
}

.char:nth-child(55) {
	animation-delay: calc(54 * var(--interval));
}

.char:nth-child(56) {
	animation-delay: calc(55 * var(--interval));
}

.char:nth-child(57) {
	animation-delay: calc(56 * var(--interval));
}

.char:nth-child(58) {
	animation-delay: calc(57 * var(--interval));
}

.char:nth-child(59) {
	animation-delay: calc(58 * var(--interval));
}

.char:nth-child(60) {
	animation-delay: calc(59 * var(--interval));
}

.char:nth-child(61) {
	animation-delay: calc(60 * var(--interval));
}

.char:nth-child(62) {
	animation-delay: calc(61 * var(--interval));
}

.char:nth-child(63) {
	animation-delay: calc(62 * var(--interval));
}

.char:nth-child(64) {
	animation-delay: calc(63 * var(--interval));
}
`;

export default Sky8;