import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { OptionsMenu } from "components";
import optionsMenuData from "./optionsMenuData";

const Notepad = ({ onClose, onToggleComponent, id }) => {
  const [docText, setDocText] = useState("");
  const [wordWrap, setWordWrap] = useState(true);
  const [largeFont, setLargeFont] = useState(false);

  useEffect(() => {
    if (docText.length > 0) {
      optionsMenuData["Edit"][0].disable = false;
      optionsMenuData["File"][0].disable = false;
    } else {
      optionsMenuData["Edit"][0].disable = true;
      optionsMenuData["File"][0].disable = true;
    }
  }, [docText]);
  useEffect(() => {
    if (window.getSelection().focusOffset > 0) {
      optionsMenuData["Edit"][0].disable = true;
      optionsMenuData["Edit"][1].disable = false;
    } else {
      optionsMenuData["Edit"][0].disable = false;
      optionsMenuData["Edit"][1].disable = true;
    }
  });

  function onClickOptionItem(item) {
    switch (item.text) {
      case "New":
        setDocText("");
        document.getElementById("content").value = "";
        break;
      case "Save As...":
        saveTextAsFile();
        break;
      case "Select All":
        selectAll();
        break;
      case "Select None":
        onSelectNone();
        break;
      case "Close":
        onClose();
        break;
      case "Large Font":
        optionsMenuData["Format"][0].check = !largeFont;
        setLargeFont(on => !on);
        break;
      case "Word Wrap":
        optionsMenuData["Format"][1].check = !wordWrap;
        setWordWrap(on => !on);
        break;
      case "Help Center":
        onToggleComponent("Help", "open", id);
        break;
      case "Time/Date":
        const date = new Date();
        setDocText(
          `${docText}${date.toLocaleTimeString()} ${date.toLocaleDateString()}`
        );
        break;
      default:
    }
  }
  function onSelectNone() {
    window.getSelection().removeAllRanges();
  }
  function selectAll() {
    const el = document.getElementById("content");
    if (document.body.createTextRange) {
      let range = document.body.createTextRange();
      range.moveToElementText(el);
      range.select();
    } else if (window.getSelection) {
      let selection = window.getSelection();
      let range = document.createRange();
      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  function saveTextAsFile() {
    let textToWrite = document.getElementById("content").innerHTML;
    let textFileAsBlob = new Blob([textToWrite], { type: "text/plain" });
    let fileNameToSaveAs = "Untitled.txt";
    let downloadLink = document.createElement("a");
    downloadLink.download = fileNameToSaveAs;
    downloadLink.innerHTML = "Download File";
    if (window.webkitURL != null) {
      downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
    } else {
      downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
      downloadLink.onclick = destroyClickedElement;
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
    }
    downloadLink.click();
  }
  const destroyClickedElement = (event) =>
    document.body.removeChild(event.target);

  function onTextAreaKeyDown(e) {
    if (e.which === 9) {
      e.preventDefault();
      e.persist();
      let start = e.target.selectionStart;
      let end = e.target.selectionEnd;
      setDocText(`${docText.substring(0, start)}\t${docText.substring(end)}`);
      requestAnimationFrame(() => {
        e.target.selectionStart = start + 1;
        e.target.selectionEnd = start + 1;
      });
    }
  }

  return (
    <Div>
      <section className="window_toolbar">
        <OptionsMenu items={optionsMenuData} onClickItem={onClickOptionItem} />
      </section>
      <div className="window_content">
        <TextArea
          className="texter"
          id="content"
          wordWrap={wordWrap}
          value={docText}
          onChange={(e) => setDocText(e.target.value)}
          onKeyDown={onTextAreaKeyDown}
          spellCheck={false}
          largeFont={largeFont}
        />
      </div>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  background: var(--colorgrad3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  .window_content {
    height: calc(100% - 24px);
  }
`;

const TextArea = styled.textarea`
  outline: none;
  border: none;
  width: 100%;
  letter-spacing: 3px;
  height: 100%;
  padding: 5px;
  flex: auto;
  font-family: "graph35", monospace;
  resize: none;
  overflow-y: scroll;
  user-select: text !important;
  ${(props) => (!props.largeFont ? "font-size: 16px;" : "font-size: 24px;")}
  ${(props) =>
    !props.wordWrap
      ? "white-space: nowrap; overflow-x: scroll;"
      : "white-space: wrap; overflow-x: hidden;"}
`;

export default Notepad;
