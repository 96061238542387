import React from "react";
import {
  BOOL,
  RANGE,
  ENUM,
  COLOR,
  BOOL_RANGE
} from "../../constants";
import Select from "./Select";
import Bool from "./Bool";
import Color from "./Color";
import Range from "./Range";
import BoolRange from "./BoolRange";

const Controls = (props) => (
  <>
    {Object.entries(props.optionTypes).map(e => {
      const [name, oType] = e;
      switch (oType.type) {
        case RANGE:
          return (
            <div key={name} className={'menu_entries ' + (e[1].classes ? e[1].classes : "")}>
            <Range
              key={name}
              name={name}
              types={oType}
              label={e[1].label}
              value={props.options[name]}
              step={oType && oType.step}
              onSetFilterOption={props.onSetFilterOption}
            />
            </div>
          );
        case COLOR:
          return (
            <div key={name} className={'menu_entries no_margin ' + (e[1].classes ? e[1].classes : "")}>
            <Color
              label={e[1].label}
              name={name}
              boolean={props.options['boolean']}
              color={props.options['color']}
              key={name}
              onSetFilterOption={props.onSetFilterOption}
            />
            </div>
        );
        case BOOL_RANGE:
        return (
          <div key={name} className={'menu_entries ' + (e[1].classes ? e[1].classes : "")}>
          <BoolRange
            step={oType && oType.step}
            key={name}
            name={name}
            label={e[1].label}
            boolean={props.options['boolean']}
            types={oType}
            slider={props.options['slider']}
            onSetFilterOption={props.onSetFilterOption}
          />
          </div>
        );
        case BOOL:
          return (
            <div key={name} className={'menu_entries ' + (e[1].classes ? e[1].classes : "")}>
            <Bool
              key={name}
              name={name}
              types={oType}
              label={e[1].label}
              value={props.options[name]}
              onSetFilterOption={props.onSetFilterOption}
            />
            </div>
          );
        case ENUM:
          return (
            <div key={name} className={'menu_entries ' + (e[1].classes ? e[1].classes : "")}>
            <Select
              key={name}
              name={name}
              types={oType}
              value={props.options[name]}
              onSetFilterOption={props.onSetFilterOption}
            />
            </div>
          );
        default:
          return null;
      }
    })}
  </>
);

export default Controls;
