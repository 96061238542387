import React, { useGlobal, setGlobal, useState, useEffect, useRef } from "reactn";
import styled from "styled-components";
import { OptionsMenu } from "components";
import optionsMenuData from "./optionsMenuData";
import cdclaim from "assets/icons/cdclaim.ico";
import cd from "assets/cdcollect/cd_03_dith.gif";
import GSCDRom from "./GSCDRom";
import empty from "assets/sweeper/empty.png";
import forward from 'assets/icons/forward.ico'
import back from 'assets/icons/back.ico'

const CDCollection = ({ onClose, onToggleComponent, id }) => {
  const cdBackPages = useRef([]);
  const cdNextPages = useRef([]);
  const [cdContract] = useGlobal("cdContract");
  const [account] = useGlobal("account");
  const [cdMounted] = useGlobal("cdMounted");
  const [view, setView] = useState("Home");
  const [repair, setRepair] = useState(null);
  const [cdCorrupted] = useGlobal("cdCorrupted");
  const [cdBalance, setCdBalance] = useState(0);
  const selection = window.getSelection().anchorNode;

  useEffect(() => {
    async function getCdBalance() {
      let balance = await cdContract["balanceOf(address)"](account);
      setCdBalance(parseInt(balance));
    }
    if (cdContract && account) getCdBalance();
  }, [cdContract, account]);

  const insertCD = (cdName) => {
    if (cdMounted) {
      setGlobal({ cdMounted: null });
      onToggleComponent("Disk Drive", "close", id);
      onToggleComponent("Ghostsweeper", "close", id);
      onToggleComponent("Mall Of Tortured Souls", "close", id);
      onToggleComponent("Soulitaire", "close", id);
    } else {
      setGlobal({ cdMounted: cdName });
    }
  };

  useEffect(() => {
    return () => {
      cdBackPages.current = [];
      cdNextPages.current = [];
    };
  }, []);

  useEffect(() => {
    const nextCD = document.getElementById("nextCD");
    const backCD = document.getElementById("backCD");
    if (cdNextPages.current.length > 0) {
      optionsMenuData["View"][3].disable = false;
      nextCD.classList.add("hasactive");
      nextCD.style.opacity = "1";
    } else {
      optionsMenuData["View"][3].disable = true;
      nextCD.classList.remove("hasactive");
      nextCD.style.opacity = "0.5";
    }
    if (cdBackPages.current.length > 0) {
      optionsMenuData["View"][2].disable = false;
      backCD.classList.add("hasactive");
      backCD.style.opacity = "1";
    } else {
      optionsMenuData["View"][2].disable = true;
      backCD.classList.remove("hasactive");
      backCD.style.opacity = "0.5";
    }
    if ((cdNextPages.current.length || cdBackPages.current.length) > 0) {
      optionsMenuData["Edit"][3].disable = false;
    } else {
      optionsMenuData["Edit"][3].disable = true;
    }
    if (cdBalance > 0) {
      optionsMenuData["Collection"][0].disable = false;
    } else {
      optionsMenuData["Collection"][0].disable = true;
    }
    if (view === "GhostSoftware") {
      if (selection) {
        optionsMenuData["Edit"][0].disable = true;
        optionsMenuData["Edit"][1].disable = false;
      } else {
        optionsMenuData["Edit"][0].disable = false;
        optionsMenuData["Edit"][1].disable = true;
      }
    } else {
      optionsMenuData["Edit"][0].disable = true;
      optionsMenuData["Edit"][1].disable = true;
    }
  }, [view, selection, cdBalance]);

  const onRefresh = () => {
    if (selection) onSelectNone();
    cdBackPages.current = [];
    cdNextPages.current = [];
    setView("Home");
  };

  function createViewList(curr, prev) {
    if (curr === prev) return;
    if (selection) onSelectNone();
    cdBackPages.current.push(prev);
    setView(curr);
  }

  function onClickOptionItem(item) {
    switch (item.text) {
      case "Home":
        createViewList("Home", view);
        break;
      case "GhostSoftware":
        if (cdBalance > 0) createViewList("GhostSoftware", view);
        break;
      case "Back":
        handleNavigation("back");
        break;
      case "Forward":
        handleNavigation("forward");
        break;
      case "Refresh":
        onRefresh();
        break;
      case "Select All":
        onSelectAll();
        break;
      case "Select None":
        onSelectNone();
        break;
      case "Close":
        onClose();
        break;
      case "About...":
        onToggleComponent("Help", "open", id, 'CD')
        break;
      case "Help Center":
        onToggleComponent("Help", "open", id)
        break;
      default:
    }
  }
  function onSelectNone() {
    window.getSelection().removeAllRanges();
  }
  function onSelectAll() {
    const el = document.getElementById("cd_inner");
    if (document.body.createTextRange) {
      let range = document.body.createTextRange();
      range.moveToElementText(el);
      range.select();
    } else if (window.getSelection) {
      let selection = window.getSelection();
      let range = document.createRange();
      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  function handleNavigation(flow) {
    if (flow === "back") {
      if (cdBackPages.current.length > 0) {
        cdNextPages.current.unshift(view);
        setView(cdBackPages.current[cdBackPages.current.length - 1]);
        cdBackPages.current[0] === cdBackPages.current[cdBackPages.current.length - 1]
          ? (cdBackPages.current = [])
          : cdBackPages.current.pop();
      } else {
        return;
      }
    } else if (flow === "forward") {
      if (cdNextPages.current.length > 0) {
        cdBackPages.current.push(view);
        setView(cdNextPages.current[0]);
        cdNextPages.current[0] === cdNextPages.current[cdNextPages.current.length - 1]
          ? (cdNextPages.current = [])
          : cdNextPages.current.shift();
      } else {
        return;
      }
    }
  }

  return (
    <Div>
      <section className="window_toolbar">
        <OptionsMenu items={optionsMenuData} onClickItem={onClickOptionItem} />
      </section>
      <div className="divider"></div>
      <section className="address_bar">
        <div className="div_bar"></div>
        <div className="address_bar_title">
          A<u>d</u>dress
        </div>
        <div className="address_bar_content">
          <div className="address_bar_content_text">
            <img draggable={false} src={cdclaim} alt="favicon" className="address_bar_content_img" />
            <span>heaven://cd-rom</span>
            <div className="address_bar_buttons">
              <div className="address_bar_go"><div /></div>
              <div
                id="backCD"
                title="back"
                className="address_bar_back"
                onClick={() => handleNavigation("back")}
              ><img draggable={false} alt="back" src={back} /></div>
              <div
                id="nextCD"
                title="forward"
                className="address_bar_forward"
                onClick={() => handleNavigation("forward")}
              ><img draggable={false} alt="forward" src={forward} /></div>
            </div>
          </div>
        </div>
      </section>
      <section className="window_content">
        <div id="cd_inner" className="window_content_inner">
          {view === "Home" && (
            <>
              <div className="token_rows">
                <div className="token_item">
                  {cdBalance > 0 ? (
                    <>
                      <div
                        className="token_box pointer"
                        onClick={() => createViewList("GhostSoftware", view)}
                      >
                        <img draggable={false} className="cd_display" src={cd} alt="cd" />
                      </div>
                      <div className="token_caption">
                        <div>GhostSoftware CD-ROM</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div id="empty" className="button disabled">
                        <div>EMPTY SLOT</div>
                        <img draggable={false} className="cd_display" src={empty} alt="cd" />
                      </div>
                      <div className="token_caption">
                        <div>[ ? ]</div>
                      </div>
                    </>
                  )}
                </div>
                <div className="token_item">
                  <div id="empty" className="button disabled">
                    <div>EMPTY SLOT</div>
                    <img draggable={false} className="cd_display" src={empty} alt="cd" />
                  </div>
                  <div className="token_caption">
                    <div>[ ? ]</div>
                  </div>
                </div>
                <div className="token_item">
                  <div id="empty" className="button disabled">
                    <div>EMPTY SLOT</div>
                    <img draggable={false} className="cd_display" src={empty} alt="cd" />
                  </div>
                  <div className="token_caption">
                    <div>[ ? ]</div>
                  </div>
                </div>
              </div>
            </>
          )}
          {view === "GhostSoftware" && (
            <>
              <div className="greyOutset test">
                <div className="whiteInset test"><p>GhostSoftware CD-ROM</p></div>
                  <div className="test">
                    <div className="buttons_row">
                      <button
                        className="button"
                        onClick={() => insertCD("ghostsoftware")}
                      >
                        {cdMounted ? "EJECT" : "INSERT"}
                      </button>
                      <button
                        className={cdMounted ? "button" : "button disabled"}
                        onClick={() => onToggleComponent("Disk Drive", "open")}
                      >
                        OPEN
                      </button>
                      <button
                        className={
                          cdCorrupted === "broken"
                            ? "button"
                            : "button disabled"
                        }
                        onClick={() => setRepair((on) => !on)}
                      >
                        REPAIR
                      </button>
                      <div className="whiteInset">
                        {cdMounted
                          ? cdCorrupted === "broken"
                            ? "DAMAGED"
                            : "ON"
                          : "OFF"}
                      </div>
                    </div>
                  </div>

                    <GSCDRom repair={repair} />
                    <div style={{marginTop: '4px'}} className="whiteInset whiteText test">
                      <p>GhostSoftware CD-ROM is a limited edition compact disc
                      rewarded to supporters of the GhostSoftware project. It
                      includes ghostly puzzle and card games compatible with the
                      HeavenComputerOS. Legend has it that those who win all the
                      games that come with this CD will have their computers
                      hacked by the ghosts of seven tortured souls.
                      <br />
                      The writing is on the wall.</p>
                    </div>
                </div>
            </>
          )}
        </div>
      </section>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  background: var(--colorgrad3);
  .greyOutset {
    box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad0),
      inset -2px -2px 0 0 var(--colorgrad5), inset 2px 2px 0 0 var(--colorgrad2);
    background: var(--colorgrad3);
    padding: 4px;
    height: 100%;
  }
  .whiteInset {
    display: flex;
    bottom: 0;
    box-shadow: inset -1px -1px 0px var(--colorgrad0), inset 1px 1px 0px 0px var(--colorgrad6),
      inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8);
    background: #ffffff;
    text-overflow: ellipsis;
    p {
      min-height: 22px;
      padding: 6px;
    }
  }
  .whiteText {
    min-height: 80px;
    overflow: hidden;
    padding: 2px;
    white-space: normal !important;
    p {
      height: 100%;
      overflow-y: scroll;
      padding: 6px;
    }
  }
  .test {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  img.cd_display {
    max-width: 100%;
    max-height: 100%;
    padding: 20px;
  }
  .cd_display.empty {
    width: 100%;
    height: 100%;
  }
  p,
  span,
  a,
  .td {
    user-select: text;
  }
  #empty img {
    width: 284px;
  }
  #empty {
    position: relative;
    display: flex;
    div {
      position: absolute;
    }
  }
  .button {
    display: inline-block;
  }
  .table {
    padding: 6px;
    max-width: 510px;
    letter-spacing: 1px;
    font-size: 11px;
  }
  .buttons_row {
    min-width: 10px;
    .button + .button,
    .button + div {
      margin-left: 10px;
    }
  }
  .token_box,
  #empty {
    min-width: 200px;
    min-height: 200px;
    max-width: 300px;
    max-height: 300px;
  }
  .td {
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    align-self: center;
    padding: 8px;
    font-size: 11px;
    line-height: 11px;
    display: inline;
    text-align: center;
    background: #ffffff;
    white-space: break-spaces;
    box-shadow: inset -1px -1px 0px var(--colorgrad0), inset 1px 1px 0px 0px var(--colorgrad6),
      inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8);
  }
  .td.tfoot,
  .thead .td {
    width: 100%;
    line-height: 13px;
    align-items: center;
    display: flex;
  }
  span {
    display: block;
  }
  .box_section {
    width: auto;
  }
  .buttons_row button {
    width: 80px;
    font-size: 11px;
  }
  .window_content_inner {
    height: calc(100% - 4px);
    background-color: #ffffff;
    overflow-x: hidden !important;
  }
  .upper {
    display: flex;
    flex-direction: row;
  }
`;

export default CDCollection;
