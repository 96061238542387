import React from "react";
import crosshair from "assets/cursors/crosshair.cur";

const Selector = ({ mouse, startPos }) => {
  function getRect() {
    return {
      x: Math.min(startPos.x, mouse.docX),
      y: Math.min(startPos.y, mouse.docY),
      w: Math.abs(startPos.x - mouse.docX),
      h: Math.abs(startPos.y - mouse.docY),
    };
  }
  if (startPos) {
    const { x, y, w, h } = getRect();
    return (
      <div
        style={{
          transform: `translate(${x}px,${y}px)`,
          width: w+100,
          height: h+100,
          position: "fixed",
          cursor: `url(${crosshair}), crosshair`,
        }}
      >
        <div
          style={{
            width: w,
            height: h,
            position: "absolute",
            border: "1px dotted darkgray",
            cursor: `url(${crosshair}), crosshair`,
          }}
        >
        </div>
      </div>
    );
  }
  return null;
};

export default Selector;
