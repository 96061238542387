import {
  LOAD_IMAGE,
  LOAD_STATE,
  FILTER_IMAGE,
  SELECT_FILTER,
  SET_GRAYSCALE,
  SET_VIEW_EFFECTS,
  SET_INPUT_CANVAS,
  SET_INPUT_PLAYBACK_RATE,
  SET_FILTER_OPTION,
  SET_FILTER_PALETTE_OPTION,
  ADD_PALETTE_COLOR,
} from "../constants";

import { grayscale, filterIndex } from "../filters";
import { paletteList } from "../palettes";

export const initialState = {
  selected: {
    name: "None",
    filter: {
      name: "None",
      func: null,
      options: [null],
      optionTypes: {},
      defaults: {},
    },
  },
  convertGrayscale: false,
  inputCanvas: null,
  inputImage: null,
  outputImage: null,
  viewEffects: true,
  time: null,
  video: null,
  videoPlaybackRate: 1,
};

const Reducer = (state = initialState, action = { type: "" }) => {
  switch (action.type) {
    case LOAD_STATE:
      const localFilter = filterIndex[action.data.selected.filter.name];
      const deserializedFilter = {
        ...localFilter,
        options: action.data.selected.filter.options,
      };
      if (deserializedFilter.options.palette != null) {
        const localPalette = paletteList.find(
          (p) => p.palette.name === deserializedFilter.options.palette.name
        );
        if (localPalette) {
          deserializedFilter.options.palette = {
            ...localPalette.palette,
            options: deserializedFilter.options.palette.options,
          };
        }
      }
      return {
        ...state,
        selected: {
          ...action.data.selected,
          filter: deserializedFilter,
        },
        convertGrayscale: action.data.convertGrayscale,
      };
    case SET_INPUT_CANVAS:
      return {
        ...state,
        inputCanvas: action.canvas,
      };
    case SET_INPUT_PLAYBACK_RATE:
      if (state.video) {
        state.video.playbackRate = action.rate; // eslint-disable-line
      }
      return {
        ...state,
        videoPlaybackRate: action.rate,
      };
    case LOAD_IMAGE:
      const newState = {
        ...state,
        inputImage: action.image,
        time: action.time || 0,
        video: action.video || null,
        viewEffects: action.video && state.viewEffects,
      };

      if (
        state.selected.filter.name !== "None" &&
        state.viewEffects &&
        state.inputCanvas
      ) {
        const output = state.convertGrayscale
          ? state.selected.filter.func(
              grayscale.func(state.inputCanvas),
              state.selected.filter.options,
              action.dispatch
            )
          : state.selected.filter.func(
              state.inputCanvas,
              state.selected.filter.options,
              action.dispatch
            );
        if (output instanceof HTMLCanvasElement) {
          newState.outputImage = output;
        }
      }

      return newState;
    case SET_GRAYSCALE:
      return {
        ...state,
        convertGrayscale: action.value,
      };
    case SET_VIEW_EFFECTS:
      return {
        ...state,
        viewEffects: action.enabled,
      };
    case SELECT_FILTER:
      return {
        ...state,
        selected: {
          name: action.name,
          filter: action.filter.filter,
        },
      };
    case SET_FILTER_OPTION:
      return {
        ...state,
        selected: {
          ...state.selected,
          filter: {
            ...state.selected.filter,
            options: {
              ...state.selected.filter.options,
              [action.optionName]: action.value,
            },
          },
        },
      };
    case SET_FILTER_PALETTE_OPTION:
      if (
        !state.selected.filter.options ||
        !state.selected.filter.options.palette
      ) {
        return state;
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          filter: {
            ...state.selected.filter,
            options: {
              ...state.selected.filter.options,
              palette: {
                ...state.selected.filter.options.palette,
                options: {
                  ...state.selected.filter.options.palette.options,
                  [action.optionName]: action.value,
                },
              },
            },
          },
        },
      };
    case ADD_PALETTE_COLOR:
      if (
        !state.selected.filter.options ||
        !state.selected.filter.options.palette
      ) {
        return state;
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          filter: {
            ...state.selected.filter,
            options: {
              ...state.selected.filter.options,
              palette: {
                ...state.selected.filter.options.palette,
                options: {
                  ...state.selected.filter.options.palette.options,
                  colors: [
                    ...state.selected.filter.options.palette.options.colors,
                    action.color,
                  ],
                },
              },
            },
          },
        },
      };
    case FILTER_IMAGE:
      return {
        ...state,
        outputImage: action.image,
      };
    default:
      return state;
  }
};

export default Reducer;
