import { useEffect, useState } from "react";

const useComboKey = (hotKeys, onMatch) => {
  // eslint-disable-next-line 
  const [pressedKeys, setPressedKeys] = useState({});

  useEffect(() => {
    const downHandler = ({ key }) => {
      setPressedKeys(prevState => {
        const newState = { ...prevState, [key]: true };
        const allPressed = hotKeys.every(k => newState[k]);
        if (allPressed) {
          onMatch();
        }
        return newState;
      });
    };
  
    const upHandler = ({ key }) => {
      setPressedKeys(prevState => ({ ...prevState, [key]: false }));
    };
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [hotKeys, onMatch]);

  return null;
};

export default useComboKey;
