import React from "react";
import styled from "styled-components";

const Rain = () => {

	return (
		<Div className="rain">
			<i className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
				className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
					className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
						className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
							className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
								className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
									className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
										className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
											className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
												className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
													className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
														className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
															className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																	className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																		className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																			className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																				className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																					className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																						className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																							className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																								className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																									className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																										className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																											className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																												className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																													className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																														className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																															className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																	className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																		className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																			className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																				className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																					className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																						className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																							className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																								className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																									className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																										className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																											className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																												className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																													className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																														className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																															className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																																className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																																	className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																																		className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																																			className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i><i
																																																				className="raindrop"></i><i className="raindrop"></i><i className="raindrop"></i>
		</Div>
	);
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: var(--display);
  --stop: 60vmin;
  mask-image: linear-gradient(to bottom, #000 var(--stop), #0000 calc(10% + var(--stop)));
.raindrop {
  background: white;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  height: 50px;
  position: absolute;
  width: 3px;
}

.raindrop:nth-of-type(1) {
  animation-name: rain-1;
  animation-delay: 5s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 75%;
  opacity: 0.6;
  top: -86%;
}

@keyframes rain-1 {
  from {
    left: 75%;
    opacity: 0.6;
    top: -86%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 126%;
  }
}

.raindrop:nth-of-type(2) {
  animation-name: rain-2;
  animation-delay: 11s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 2%;
  opacity: 0.49;
  top: -79%;
}

@keyframes rain-2 {
  from {
    left: 2%;
    opacity: 0.49;
    top: -79%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 119%;
  }
}

.raindrop:nth-of-type(3) {
  animation-name: rain-3;
  animation-delay: 13s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 50%;
  opacity: 0.52;
  top: -51%;
}

@keyframes rain-3 {
  from {
    left: 50%;
    opacity: 0.52;
    top: -51%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 91%;
  }
}

.raindrop:nth-of-type(4) {
  animation-name: rain-4;
  animation-delay: 6s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 82%;
  opacity: 0.52;
  top: -60%;
}

@keyframes rain-4 {
  from {
    left: 82%;
    opacity: 0.52;
    top: -60%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 100%;
  }
}

.raindrop:nth-of-type(5) {
  animation-name: rain-5;
  animation-delay: 6s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 64%;
  opacity: 0.47;
  top: -59%;
}

@keyframes rain-5 {
  from {
    left: 64%;
    opacity: 0.47;
    top: -59%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 99%;
  }
}

.raindrop:nth-of-type(6) {
  animation-name: rain-6;
  animation-delay: 18s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 37%;
  opacity: 0.45;
  top: -78%;
}

@keyframes rain-6 {
  from {
    left: 37%;
    opacity: 0.45;
    top: -78%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 118%;
  }
}

.raindrop:nth-of-type(7) {
  animation-name: rain-7;
  animation-delay: 17s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 73%;
  opacity: 0.38;
  top: -81%;
}

@keyframes rain-7 {
  from {
    left: 73%;
    opacity: 0.38;
    top: -81%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 121%;
  }
}

.raindrop:nth-of-type(8) {
  animation-name: rain-8;
  animation-delay: 11s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 27%;
  opacity: 0.42;
  top: -56%;
}

@keyframes rain-8 {
  from {
    left: 27%;
    opacity: 0.42;
    top: -56%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 96%;
  }
}

.raindrop:nth-of-type(9) {
  animation-name: rain-9;
  animation-delay: 0s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 92%;
  opacity: 0.35;
  top: -94%;
}

@keyframes rain-9 {
  from {
    left: 92%;
    opacity: 0.35;
    top: -94%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 134%;
  }
}

.raindrop:nth-of-type(10) {
  animation-name: rain-10;
  animation-delay: 15s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 77%;
  opacity: 0.6;
  top: -52%;
}

@keyframes rain-10 {
  from {
    left: 77%;
    opacity: 0.6;
    top: -52%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 92%;
  }
}

.raindrop:nth-of-type(11) {
  animation-name: rain-11;
  animation-delay: 3s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 9%;
  opacity: 0.5;
  top: -88%;
}

@keyframes rain-11 {
  from {
    left: 9%;
    opacity: 0.5;
    top: -88%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 128%;
  }
}

.raindrop:nth-of-type(12) {
  animation-name: rain-12;
  animation-delay: 12s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 8%;
  opacity: 0.6;
  top: -71%;
}

@keyframes rain-12 {
  from {
    left: 8%;
    opacity: 0.6;
    top: -71%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 111%;
  }
}

.raindrop:nth-of-type(13) {
  animation-name: rain-13;
  animation-delay: 16s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 46%;
  opacity: 0.4;
  top: -91%;
}

@keyframes rain-13 {
  from {
    left: 46%;
    opacity: 0.4;
    top: -91%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 131%;
  }
}

.raindrop:nth-of-type(14) {
  animation-name: rain-14;
  animation-delay: 18s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 83%;
  opacity: 0.35;
  top: -61%;
}

@keyframes rain-14 {
  from {
    left: 83%;
    opacity: 0.35;
    top: -61%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 101%;
  }
}

.raindrop:nth-of-type(15) {
  animation-name: rain-15;
  animation-delay: 3s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 49%;
  opacity: 0.53;
  top: -86%;
}

@keyframes rain-15 {
  from {
    left: 49%;
    opacity: 0.53;
    top: -86%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 126%;
  }
}

.raindrop:nth-of-type(16) {
  animation-name: rain-16;
  animation-delay: 7s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 29%;
  opacity: 0.39;
  top: -85%;
}

@keyframes rain-16 {
  from {
    left: 29%;
    opacity: 0.39;
    top: -85%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 125%;
  }
}

.raindrop:nth-of-type(17) {
  animation-name: rain-17;
  animation-delay: 16s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 77%;
  opacity: 0.33;
  top: -96%;
}

@keyframes rain-17 {
  from {
    left: 77%;
    opacity: 0.33;
    top: -96%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 136%;
  }
}

.raindrop:nth-of-type(18) {
  animation-name: rain-18;
  animation-delay: 7s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 83%;
  opacity: 0.53;
  top: -95%;
}

@keyframes rain-18 {
  from {
    left: 83%;
    opacity: 0.53;
    top: -95%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 135%;
  }
}

.raindrop:nth-of-type(19) {
  animation-name: rain-19;
  animation-delay: 13s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 14%;
  opacity: 0.49;
  top: -97%;
}

@keyframes rain-19 {
  from {
    left: 14%;
    opacity: 0.49;
    top: -97%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 137%;
  }
}

.raindrop:nth-of-type(20) {
  animation-name: rain-20;
  animation-delay: 14s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 63%;
  opacity: 0.38;
  top: -68%;
}

@keyframes rain-20 {
  from {
    left: 63%;
    opacity: 0.38;
    top: -68%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 108%;
  }
}

.raindrop:nth-of-type(21) {
  animation-name: rain-21;
  animation-delay: 5s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 98%;
  opacity: 0.33;
  top: -65%;
}

@keyframes rain-21 {
  from {
    left: 98%;
    opacity: 0.33;
    top: -65%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 105%;
  }
}

.raindrop:nth-of-type(22) {
  animation-name: rain-22;
  animation-delay: 18s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 1%;
  opacity: 0.36;
  top: -89%;
}

@keyframes rain-22 {
  from {
    left: 1%;
    opacity: 0.36;
    top: -89%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 129%;
  }
}

.raindrop:nth-of-type(23) {
  animation-name: rain-23;
  animation-delay: 13s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 66%;
  opacity: 0.46;
  top: -89%;
}

@keyframes rain-23 {
  from {
    left: 66%;
    opacity: 0.46;
    top: -89%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 129%;
  }
}

.raindrop:nth-of-type(24) {
  animation-name: rain-24;
  animation-delay: 2s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 64%;
  opacity: 0.47;
  top: -54%;
}

@keyframes rain-24 {
  from {
    left: 64%;
    opacity: 0.47;
    top: -54%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 94%;
  }
}

.raindrop:nth-of-type(25) {
  animation-name: rain-25;
  animation-delay: 15s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 53%;
  opacity: 0.39;
  top: -84%;
}

@keyframes rain-25 {
  from {
    left: 53%;
    opacity: 0.39;
    top: -84%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 124%;
  }
}

.raindrop:nth-of-type(26) {
  animation-name: rain-26;
  animation-delay: 19s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 45%;
  opacity: 0.53;
  top: -72%;
}

@keyframes rain-26 {
  from {
    left: 45%;
    opacity: 0.53;
    top: -72%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 112%;
  }
}

.raindrop:nth-of-type(27) {
  animation-name: rain-27;
  animation-delay: 1s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 19%;
  opacity: 0.42;
  top: -96%;
}

@keyframes rain-27 {
  from {
    left: 19%;
    opacity: 0.42;
    top: -96%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 136%;
  }
}

.raindrop:nth-of-type(28) {
  animation-name: rain-28;
  animation-delay: 8s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 90%;
  opacity: 0.46;
  top: -70%;
}

@keyframes rain-28 {
  from {
    left: 90%;
    opacity: 0.46;
    top: -70%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 110%;
  }
}

.raindrop:nth-of-type(29) {
  animation-name: rain-29;
  animation-delay: 4s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 25%;
  opacity: 0.55;
  top: -56%;
}

@keyframes rain-29 {
  from {
    left: 25%;
    opacity: 0.55;
    top: -56%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 96%;
  }
}

.raindrop:nth-of-type(30) {
  animation-name: rain-30;
  animation-delay: 0s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 80%;
  opacity: 0.4;
  top: -54%;
}

@keyframes rain-30 {
  from {
    left: 80%;
    opacity: 0.4;
    top: -54%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 94%;
  }
}

.raindrop:nth-of-type(31) {
  animation-name: rain-31;
  animation-delay: 2s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 83%;
  opacity: 0.53;
  top: -78%;
}

@keyframes rain-31 {
  from {
    left: 83%;
    opacity: 0.53;
    top: -78%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 118%;
  }
}

.raindrop:nth-of-type(32) {
  animation-name: rain-32;
  animation-delay: 9s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 57%;
  opacity: 0.31;
  top: -89%;
}

@keyframes rain-32 {
  from {
    left: 57%;
    opacity: 0.31;
    top: -89%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 129%;
  }
}

.raindrop:nth-of-type(33) {
  animation-name: rain-33;
  animation-delay: 2s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 11%;
  opacity: 0.56;
  top: -54%;
}

@keyframes rain-33 {
  from {
    left: 11%;
    opacity: 0.56;
    top: -54%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 94%;
  }
}

.raindrop:nth-of-type(34) {
  animation-name: rain-34;
  animation-delay: 9s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 48%;
  opacity: 0.54;
  top: -60%;
}

@keyframes rain-34 {
  from {
    left: 48%;
    opacity: 0.54;
    top: -60%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 100%;
  }
}

.raindrop:nth-of-type(35) {
  animation-name: rain-35;
  animation-delay: 18s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 64%;
  opacity: 0.53;
  top: -94%;
}

@keyframes rain-35 {
  from {
    left: 64%;
    opacity: 0.53;
    top: -94%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 134%;
  }
}

.raindrop:nth-of-type(36) {
  animation-name: rain-36;
  animation-delay: 14s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 90%;
  opacity: 0.49;
  top: -63%;
}

@keyframes rain-36 {
  from {
    left: 90%;
    opacity: 0.49;
    top: -63%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 103%;
  }
}

.raindrop:nth-of-type(37) {
  animation-name: rain-37;
  animation-delay: 0s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 22%;
  opacity: 0.32;
  top: -70%;
}

@keyframes rain-37 {
  from {
    left: 22%;
    opacity: 0.32;
    top: -70%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 110%;
  }
}

.raindrop:nth-of-type(38) {
  animation-name: rain-38;
  animation-delay: 4s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 70%;
  opacity: 0.57;
  top: -84%;
}

@keyframes rain-38 {
  from {
    left: 70%;
    opacity: 0.57;
    top: -84%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 124%;
  }
}

.raindrop:nth-of-type(39) {
  animation-name: rain-39;
  animation-delay: 10s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 5%;
  opacity: 0.57;
  top: -79%;
}

@keyframes rain-39 {
  from {
    left: 5%;
    opacity: 0.57;
    top: -79%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 119%;
  }
}

.raindrop:nth-of-type(40) {
  animation-name: rain-40;
  animation-delay: 15s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 29%;
  opacity: 0.48;
  top: -55%;
}

@keyframes rain-40 {
  from {
    left: 29%;
    opacity: 0.48;
    top: -55%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 95%;
  }
}

.raindrop:nth-of-type(41) {
  animation-name: rain-41;
  animation-delay: 14s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 30%;
  opacity: 0.46;
  top: -51%;
}

@keyframes rain-41 {
  from {
    left: 30%;
    opacity: 0.46;
    top: -51%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 91%;
  }
}

.raindrop:nth-of-type(42) {
  animation-name: rain-42;
  animation-delay: 6s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 37%;
  opacity: 0.58;
  top: -61%;
}

@keyframes rain-42 {
  from {
    left: 37%;
    opacity: 0.58;
    top: -61%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 101%;
  }
}

.raindrop:nth-of-type(43) {
  animation-name: rain-43;
  animation-delay: 18s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 95%;
  opacity: 0.59;
  top: -59%;
}

@keyframes rain-43 {
  from {
    left: 95%;
    opacity: 0.59;
    top: -59%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 99%;
  }
}

.raindrop:nth-of-type(44) {
  animation-name: rain-44;
  animation-delay: 17s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 32%;
  opacity: 0.35;
  top: -53%;
}

@keyframes rain-44 {
  from {
    left: 32%;
    opacity: 0.35;
    top: -53%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 93%;
  }
}

.raindrop:nth-of-type(45) {
  animation-name: rain-45;
  animation-delay: 5s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 24%;
  opacity: 0.46;
  top: -91%;
}

@keyframes rain-45 {
  from {
    left: 24%;
    opacity: 0.46;
    top: -91%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 131%;
  }
}

.raindrop:nth-of-type(46) {
  animation-name: rain-46;
  animation-delay: 9s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 25%;
  opacity: 0.58;
  top: -71%;
}

@keyframes rain-46 {
  from {
    left: 25%;
    opacity: 0.58;
    top: -71%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 111%;
  }
}

.raindrop:nth-of-type(47) {
  animation-name: rain-47;
  animation-delay: 16s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 64%;
  opacity: 0.35;
  top: -97%;
}

@keyframes rain-47 {
  from {
    left: 64%;
    opacity: 0.35;
    top: -97%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 137%;
  }
}

.raindrop:nth-of-type(48) {
  animation-name: rain-48;
  animation-delay: 11s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 97%;
  opacity: 0.54;
  top: -58%;
}

@keyframes rain-48 {
  from {
    left: 97%;
    opacity: 0.54;
    top: -58%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 98%;
  }
}

.raindrop:nth-of-type(49) {
  animation-name: rain-49;
  animation-delay: 9s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 35%;
  opacity: 0.46;
  top: -81%;
}

@keyframes rain-49 {
  from {
    left: 35%;
    opacity: 0.46;
    top: -81%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 121%;
  }
}

.raindrop:nth-of-type(50) {
  animation-name: rain-50;
  animation-delay: 7s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 51%;
  opacity: 0.41;
  top: -58%;
}

@keyframes rain-50 {
  from {
    left: 51%;
    opacity: 0.41;
    top: -58%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 98%;
  }
}

.raindrop:nth-of-type(51) {
  animation-name: rain-51;
  animation-delay: 9s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 86%;
  opacity: 0.48;
  top: -86%;
}

@keyframes rain-51 {
  from {
    left: 86%;
    opacity: 0.48;
    top: -86%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 126%;
  }
}

.raindrop:nth-of-type(52) {
  animation-name: rain-52;
  animation-delay: 16s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 10%;
  opacity: 0.42;
  top: -99%;
}

@keyframes rain-52 {
  from {
    left: 10%;
    opacity: 0.42;
    top: -99%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 139%;
  }
}

.raindrop:nth-of-type(53) {
  animation-name: rain-53;
  animation-delay: 1s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 28%;
  opacity: 0.32;
  top: -79%;
}

@keyframes rain-53 {
  from {
    left: 28%;
    opacity: 0.32;
    top: -79%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 119%;
  }
}

.raindrop:nth-of-type(54) {
  animation-name: rain-54;
  animation-delay: 0s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 52%;
  opacity: 0.39;
  top: -53%;
}

@keyframes rain-54 {
  from {
    left: 52%;
    opacity: 0.39;
    top: -53%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 93%;
  }
}

.raindrop:nth-of-type(55) {
  animation-name: rain-55;
  animation-delay: 16s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 25%;
  opacity: 0.37;
  top: -63%;
}

@keyframes rain-55 {
  from {
    left: 25%;
    opacity: 0.37;
    top: -63%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 103%;
  }
}

.raindrop:nth-of-type(56) {
  animation-name: rain-56;
  animation-delay: 0s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 10%;
  opacity: 0.49;
  top: -79%;
}

@keyframes rain-56 {
  from {
    left: 10%;
    opacity: 0.49;
    top: -79%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 119%;
  }
}

.raindrop:nth-of-type(57) {
  animation-name: rain-57;
  animation-delay: 3s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 14%;
  opacity: 0.43;
  top: -74%;
}

@keyframes rain-57 {
  from {
    left: 14%;
    opacity: 0.43;
    top: -74%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 114%;
  }
}

.raindrop:nth-of-type(58) {
  animation-name: rain-58;
  animation-delay: 2s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 7%;
  opacity: 0.54;
  top: -94%;
}

@keyframes rain-58 {
  from {
    left: 7%;
    opacity: 0.54;
    top: -94%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 134%;
  }
}

.raindrop:nth-of-type(59) {
  animation-name: rain-59;
  animation-delay: 7s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 18%;
  opacity: 0.36;
  top: -74%;
}

@keyframes rain-59 {
  from {
    left: 18%;
    opacity: 0.36;
    top: -74%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 114%;
  }
}

.raindrop:nth-of-type(60) {
  animation-name: rain-60;
  animation-delay: 0s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 14%;
  opacity: 0.49;
  top: -95%;
}

@keyframes rain-60 {
  from {
    left: 14%;
    opacity: 0.49;
    top: -95%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 135%;
  }
}

.raindrop:nth-of-type(61) {
  animation-name: rain-61;
  animation-delay: 1s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 74%;
  opacity: 0.39;
  top: -72%;
}

@keyframes rain-61 {
  from {
    left: 74%;
    opacity: 0.39;
    top: -72%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 112%;
  }
}

.raindrop:nth-of-type(62) {
  animation-name: rain-62;
  animation-delay: 14s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 4%;
  opacity: 0.38;
  top: -80%;
}

@keyframes rain-62 {
  from {
    left: 4%;
    opacity: 0.38;
    top: -80%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 120%;
  }
}

.raindrop:nth-of-type(63) {
  animation-name: rain-63;
  animation-delay: 10s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 45%;
  opacity: 0.56;
  top: -61%;
}

@keyframes rain-63 {
  from {
    left: 45%;
    opacity: 0.56;
    top: -61%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 101%;
  }
}

.raindrop:nth-of-type(64) {
  animation-name: rain-64;
  animation-delay: 0s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 26%;
  opacity: 0.32;
  top: -95%;
}

@keyframes rain-64 {
  from {
    left: 26%;
    opacity: 0.32;
    top: -95%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 135%;
  }
}

.raindrop:nth-of-type(65) {
  animation-name: rain-65;
  animation-delay: 16s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 57%;
  opacity: 0.42;
  top: -71%;
}

@keyframes rain-65 {
  from {
    left: 57%;
    opacity: 0.42;
    top: -71%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 111%;
  }
}

.raindrop:nth-of-type(66) {
  animation-name: rain-66;
  animation-delay: 11s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 62%;
  opacity: 0.44;
  top: -76%;
}

@keyframes rain-66 {
  from {
    left: 62%;
    opacity: 0.44;
    top: -76%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 116%;
  }
}

.raindrop:nth-of-type(67) {
  animation-name: rain-67;
  animation-delay: 13s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 50%;
  opacity: 0.55;
  top: -52%;
}

@keyframes rain-67 {
  from {
    left: 50%;
    opacity: 0.55;
    top: -52%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 92%;
  }
}

.raindrop:nth-of-type(68) {
  animation-name: rain-68;
  animation-delay: 18s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 75%;
  opacity: 0.44;
  top: -51%;
}

@keyframes rain-68 {
  from {
    left: 75%;
    opacity: 0.44;
    top: -51%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 91%;
  }
}

.raindrop:nth-of-type(69) {
  animation-name: rain-69;
  animation-delay: 1s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 70%;
  opacity: 0.43;
  top: -78%;
}

@keyframes rain-69 {
  from {
    left: 70%;
    opacity: 0.43;
    top: -78%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 118%;
  }
}

.raindrop:nth-of-type(70) {
  animation-name: rain-70;
  animation-delay: 9s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 67%;
  opacity: 0.54;
  top: -75%;
}

@keyframes rain-70 {
  from {
    left: 67%;
    opacity: 0.54;
    top: -75%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 115%;
  }
}

.raindrop:nth-of-type(71) {
  animation-name: rain-71;
  animation-delay: 13s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 47%;
  opacity: 0.32;
  top: -83%;
}

@keyframes rain-71 {
  from {
    left: 47%;
    opacity: 0.32;
    top: -83%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 123%;
  }
}

.raindrop:nth-of-type(72) {
  animation-name: rain-72;
  animation-delay: 11s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 87%;
  opacity: 0.39;
  top: -85%;
}

@keyframes rain-72 {
  from {
    left: 87%;
    opacity: 0.39;
    top: -85%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 125%;
  }
}

.raindrop:nth-of-type(73) {
  animation-name: rain-73;
  animation-delay: 1s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 79%;
  opacity: 0.45;
  top: -70%;
}

@keyframes rain-73 {
  from {
    left: 79%;
    opacity: 0.45;
    top: -70%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 110%;
  }
}

.raindrop:nth-of-type(74) {
  animation-name: rain-74;
  animation-delay: 16s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 12%;
  opacity: 0.51;
  top: -72%;
}

@keyframes rain-74 {
  from {
    left: 12%;
    opacity: 0.51;
    top: -72%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 112%;
  }
}

.raindrop:nth-of-type(75) {
  animation-name: rain-75;
  animation-delay: 11s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 81%;
  opacity: 0.47;
  top: -68%;
}

@keyframes rain-75 {
  from {
    left: 81%;
    opacity: 0.47;
    top: -68%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 108%;
  }
}

.raindrop:nth-of-type(76) {
  animation-name: rain-76;
  animation-delay: 11s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 78%;
  opacity: 0.59;
  top: -83%;
}

@keyframes rain-76 {
  from {
    left: 78%;
    opacity: 0.59;
    top: -83%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 123%;
  }
}

.raindrop:nth-of-type(77) {
  animation-name: rain-77;
  animation-delay: 9s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 42%;
  opacity: 0.33;
  top: -66%;
}

@keyframes rain-77 {
  from {
    left: 42%;
    opacity: 0.33;
    top: -66%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 106%;
  }
}

.raindrop:nth-of-type(78) {
  animation-name: rain-78;
  animation-delay: 0s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 62%;
  opacity: 0.46;
  top: -94%;
}

@keyframes rain-78 {
  from {
    left: 62%;
    opacity: 0.46;
    top: -94%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 134%;
  }
}

.raindrop:nth-of-type(79) {
  animation-name: rain-79;
  animation-delay: 0s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 74%;
  opacity: 0.53;
  top: -82%;
}

@keyframes rain-79 {
  from {
    left: 74%;
    opacity: 0.53;
    top: -82%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 122%;
  }
}

.raindrop:nth-of-type(80) {
  animation-name: rain-80;
  animation-delay: 18s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 55%;
  opacity: 0.54;
  top: -70%;
}

@keyframes rain-80 {
  from {
    left: 55%;
    opacity: 0.54;
    top: -70%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 110%;
  }
}

.raindrop:nth-of-type(81) {
  animation-name: rain-81;
  animation-delay: 18s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 96%;
  opacity: 0.6;
  top: -81%;
}

@keyframes rain-81 {
  from {
    left: 96%;
    opacity: 0.6;
    top: -81%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 121%;
  }
}

.raindrop:nth-of-type(82) {
  animation-name: rain-82;
  animation-delay: 19s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 84%;
  opacity: 0.31;
  top: -74%;
}

@keyframes rain-82 {
  from {
    left: 84%;
    opacity: 0.31;
    top: -74%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 114%;
  }
}

.raindrop:nth-of-type(83) {
  animation-name: rain-83;
  animation-delay: 12s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 59%;
  opacity: 0.58;
  top: -68%;
}

@keyframes rain-83 {
  from {
    left: 59%;
    opacity: 0.58;
    top: -68%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 108%;
  }
}

.raindrop:nth-of-type(84) {
  animation-name: rain-84;
  animation-delay: 3s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 41%;
  opacity: 0.5;
  top: -83%;
}

@keyframes rain-84 {
  from {
    left: 41%;
    opacity: 0.5;
    top: -83%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 123%;
  }
}

.raindrop:nth-of-type(85) {
  animation-name: rain-85;
  animation-delay: 5s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 36%;
  opacity: 0.4;
  top: -66%;
}

@keyframes rain-85 {
  from {
    left: 36%;
    opacity: 0.4;
    top: -66%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 106%;
  }
}

.raindrop:nth-of-type(86) {
  animation-name: rain-86;
  animation-delay: 16s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 24%;
  opacity: 0.33;
  top: -77%;
}

@keyframes rain-86 {
  from {
    left: 24%;
    opacity: 0.33;
    top: -77%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 117%;
  }
}

.raindrop:nth-of-type(87) {
  animation-name: rain-87;
  animation-delay: 8s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 88%;
  opacity: 0.56;
  top: -68%;
}

@keyframes rain-87 {
  from {
    left: 88%;
    opacity: 0.56;
    top: -68%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 108%;
  }
}

.raindrop:nth-of-type(88) {
  animation-name: rain-88;
  animation-delay: 7s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 96%;
  opacity: 0.46;
  top: -77%;
}

@keyframes rain-88 {
  from {
    left: 96%;
    opacity: 0.46;
    top: -77%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 117%;
  }
}

.raindrop:nth-of-type(89) {
  animation-name: rain-89;
  animation-delay: 2s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 68%;
  opacity: 0.41;
  top: -59%;
}

@keyframes rain-89 {
  from {
    left: 68%;
    opacity: 0.41;
    top: -59%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 99%;
  }
}

.raindrop:nth-of-type(90) {
  animation-name: rain-90;
  animation-delay: 2s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 58%;
  opacity: 0.55;
  top: -78%;
}

@keyframes rain-90 {
  from {
    left: 58%;
    opacity: 0.55;
    top: -78%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 118%;
  }
}

.raindrop:nth-of-type(91) {
  animation-name: rain-91;
  animation-delay: 6s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 47%;
  opacity: 0.41;
  top: -69%;
}

@keyframes rain-91 {
  from {
    left: 47%;
    opacity: 0.41;
    top: -69%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 109%;
  }
}

.raindrop:nth-of-type(92) {
  animation-name: rain-92;
  animation-delay: 17s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 63%;
  opacity: 0.39;
  top: -72%;
}

@keyframes rain-92 {
  from {
    left: 63%;
    opacity: 0.39;
    top: -72%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 112%;
  }
}

.raindrop:nth-of-type(93) {
  animation-name: rain-93;
  animation-delay: 9s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 23%;
  opacity: 0.44;
  top: -100%;
}

@keyframes rain-93 {
  from {
    left: 23%;
    opacity: 0.44;
    top: -100%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 140%;
  }
}

.raindrop:nth-of-type(94) {
  animation-name: rain-94;
  animation-delay: 18s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 32%;
  opacity: 0.59;
  top: -69%;
}

@keyframes rain-94 {
  from {
    left: 32%;
    opacity: 0.59;
    top: -69%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 109%;
  }
}

.raindrop:nth-of-type(95) {
  animation-name: rain-95;
  animation-delay: 1s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 9%;
  opacity: 0.58;
  top: -62%;
}

@keyframes rain-95 {
  from {
    left: 9%;
    opacity: 0.58;
    top: -62%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 102%;
  }
}

.raindrop:nth-of-type(96) {
  animation-name: rain-96;
  animation-delay: 2s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 99%;
  opacity: 0.48;
  top: -58%;
}

@keyframes rain-96 {
  from {
    left: 99%;
    opacity: 0.48;
    top: -58%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 98%;
  }
}

.raindrop:nth-of-type(97) {
  animation-name: rain-97;
  animation-delay: 6s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 53%;
  opacity: 0.33;
  top: -63%;
}

@keyframes rain-97 {
  from {
    left: 53%;
    opacity: 0.33;
    top: -63%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 103%;
  }
}

.raindrop:nth-of-type(98) {
  animation-name: rain-98;
  animation-delay: 13s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 6%;
  opacity: 0.43;
  top: -55%;
}

@keyframes rain-98 {
  from {
    left: 6%;
    opacity: 0.43;
    top: -55%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 95%;
  }
}

.raindrop:nth-of-type(99) {
  animation-name: rain-99;
  animation-delay: 17s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 96%;
  opacity: 0.6;
  top: -91%;
}

@keyframes rain-99 {
  from {
    left: 96%;
    opacity: 0.6;
    top: -91%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 131%;
  }
}

.raindrop:nth-of-type(100) {
  animation-name: rain-100;
  animation-delay: 9s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 87%;
  opacity: 0.41;
  top: -69%;
}

@keyframes rain-100 {
  from {
    left: 87%;
    opacity: 0.41;
    top: -69%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 109%;
  }
}

.raindrop:nth-of-type(101) {
  animation-name: rain-101;
  animation-delay: 11s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 8%;
  opacity: 0.31;
  top: -66%;
}

@keyframes rain-101 {
  from {
    left: 8%;
    opacity: 0.31;
    top: -66%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 106%;
  }
}

.raindrop:nth-of-type(102) {
  animation-name: rain-102;
  animation-delay: 7s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 76%;
  opacity: 0.44;
  top: -74%;
}

@keyframes rain-102 {
  from {
    left: 76%;
    opacity: 0.44;
    top: -74%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 114%;
  }
}

.raindrop:nth-of-type(103) {
  animation-name: rain-103;
  animation-delay: 3s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 42%;
  opacity: 0.33;
  top: -51%;
}

@keyframes rain-103 {
  from {
    left: 42%;
    opacity: 0.33;
    top: -51%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 91%;
  }
}

.raindrop:nth-of-type(104) {
  animation-name: rain-104;
  animation-delay: 19s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 91%;
  opacity: 0.54;
  top: -98%;
}

@keyframes rain-104 {
  from {
    left: 91%;
    opacity: 0.54;
    top: -98%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 138%;
  }
}

.raindrop:nth-of-type(105) {
  animation-name: rain-105;
  animation-delay: 14s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 84%;
  opacity: 0.44;
  top: -57%;
}

@keyframes rain-105 {
  from {
    left: 84%;
    opacity: 0.44;
    top: -57%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 97%;
  }
}

.raindrop:nth-of-type(106) {
  animation-name: rain-106;
  animation-delay: 12s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 25%;
  opacity: 0.39;
  top: -95%;
}

@keyframes rain-106 {
  from {
    left: 25%;
    opacity: 0.39;
    top: -95%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 135%;
  }
}

.raindrop:nth-of-type(107) {
  animation-name: rain-107;
  animation-delay: 5s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 22%;
  opacity: 0.55;
  top: -73%;
}

@keyframes rain-107 {
  from {
    left: 22%;
    opacity: 0.55;
    top: -73%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 113%;
  }
}

.raindrop:nth-of-type(108) {
  animation-name: rain-108;
  animation-delay: 15s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 88%;
  opacity: 0.35;
  top: -85%;
}

@keyframes rain-108 {
  from {
    left: 88%;
    opacity: 0.35;
    top: -85%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 125%;
  }
}

.raindrop:nth-of-type(109) {
  animation-name: rain-109;
  animation-delay: 14s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 64%;
  opacity: 0.36;
  top: -67%;
}

@keyframes rain-109 {
  from {
    left: 64%;
    opacity: 0.36;
    top: -67%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 107%;
  }
}

.raindrop:nth-of-type(110) {
  animation-name: rain-110;
  animation-delay: 11s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 51%;
  opacity: 0.36;
  top: -60%;
}

@keyframes rain-110 {
  from {
    left: 51%;
    opacity: 0.36;
    top: -60%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 100%;
  }
}

.raindrop:nth-of-type(111) {
  animation-name: rain-111;
  animation-delay: 1s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 96%;
  opacity: 0.56;
  top: -61%;
}

@keyframes rain-111 {
  from {
    left: 96%;
    opacity: 0.56;
    top: -61%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 101%;
  }
}

.raindrop:nth-of-type(112) {
  animation-name: rain-112;
  animation-delay: 9s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 19%;
  opacity: 0.51;
  top: -70%;
}

@keyframes rain-112 {
  from {
    left: 19%;
    opacity: 0.51;
    top: -70%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 110%;
  }
}

.raindrop:nth-of-type(113) {
  animation-name: rain-113;
  animation-delay: 8s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 17%;
  opacity: 0.39;
  top: -95%;
}

@keyframes rain-113 {
  from {
    left: 17%;
    opacity: 0.39;
    top: -95%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 135%;
  }
}

.raindrop:nth-of-type(114) {
  animation-name: rain-114;
  animation-delay: 3s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 30%;
  opacity: 0.54;
  top: -96%;
}

@keyframes rain-114 {
  from {
    left: 30%;
    opacity: 0.54;
    top: -96%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 136%;
  }
}

.raindrop:nth-of-type(115) {
  animation-name: rain-115;
  animation-delay: 1s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 21%;
  opacity: 0.36;
  top: -74%;
}

@keyframes rain-115 {
  from {
    left: 21%;
    opacity: 0.36;
    top: -74%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 114%;
  }
}

.raindrop:nth-of-type(116) {
  animation-name: rain-116;
  animation-delay: 19s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 65%;
  opacity: 0.46;
  top: -95%;
}

@keyframes rain-116 {
  from {
    left: 65%;
    opacity: 0.46;
    top: -95%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 135%;
  }
}

.raindrop:nth-of-type(117) {
  animation-name: rain-117;
  animation-delay: 8s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 4%;
  opacity: 0.47;
  top: -55%;
}

@keyframes rain-117 {
  from {
    left: 4%;
    opacity: 0.47;
    top: -55%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 95%;
  }
}

.raindrop:nth-of-type(118) {
  animation-name: rain-118;
  animation-delay: 9s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 92%;
  opacity: 0.41;
  top: -64%;
}

@keyframes rain-118 {
  from {
    left: 92%;
    opacity: 0.41;
    top: -64%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 104%;
  }
}

.raindrop:nth-of-type(119) {
  animation-name: rain-119;
  animation-delay: 8s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 54%;
  opacity: 0.46;
  top: -80%;
}

@keyframes rain-119 {
  from {
    left: 54%;
    opacity: 0.46;
    top: -80%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 120%;
  }
}

.raindrop:nth-of-type(120) {
  animation-name: rain-120;
  animation-delay: 12s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 38%;
  opacity: 0.53;
  top: -82%;
}

@keyframes rain-120 {
  from {
    left: 38%;
    opacity: 0.53;
    top: -82%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 122%;
  }
}

.raindrop:nth-of-type(121) {
  animation-name: rain-121;
  animation-delay: 15s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 82%;
  opacity: 0.6;
  top: -71%;
}

@keyframes rain-121 {
  from {
    left: 82%;
    opacity: 0.6;
    top: -71%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 111%;
  }
}

.raindrop:nth-of-type(122) {
  animation-name: rain-122;
  animation-delay: 12s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 33%;
  opacity: 0.33;
  top: -93%;
}

@keyframes rain-122 {
  from {
    left: 33%;
    opacity: 0.33;
    top: -93%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 133%;
  }
}

.raindrop:nth-of-type(123) {
  animation-name: rain-123;
  animation-delay: 9s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 16%;
  opacity: 0.55;
  top: -62%;
}

@keyframes rain-123 {
  from {
    left: 16%;
    opacity: 0.55;
    top: -62%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 102%;
  }
}

.raindrop:nth-of-type(124) {
  animation-name: rain-124;
  animation-delay: 2s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 78%;
  opacity: 0.48;
  top: -86%;
}

@keyframes rain-124 {
  from {
    left: 78%;
    opacity: 0.48;
    top: -86%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 126%;
  }
}

.raindrop:nth-of-type(125) {
  animation-name: rain-125;
  animation-delay: 19s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 43%;
  opacity: 0.43;
  top: -66%;
}

@keyframes rain-125 {
  from {
    left: 43%;
    opacity: 0.43;
    top: -66%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 106%;
  }
}

.raindrop:nth-of-type(126) {
  animation-name: rain-126;
  animation-delay: 10s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 56%;
  opacity: 0.59;
  top: -92%;
}

@keyframes rain-126 {
  from {
    left: 56%;
    opacity: 0.59;
    top: -92%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 132%;
  }
}

.raindrop:nth-of-type(127) {
  animation-name: rain-127;
  animation-delay: 6s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 17%;
  opacity: 0.44;
  top: -70%;
}

@keyframes rain-127 {
  from {
    left: 17%;
    opacity: 0.44;
    top: -70%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 110%;
  }
}

.raindrop:nth-of-type(128) {
  animation-name: rain-128;
  animation-delay: 13s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 61%;
  opacity: 0.35;
  top: -75%;
}

@keyframes rain-128 {
  from {
    left: 61%;
    opacity: 0.35;
    top: -75%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 115%;
  }
}

.raindrop:nth-of-type(129) {
  animation-name: rain-129;
  animation-delay: 13s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 86%;
  opacity: 0.55;
  top: -83%;
}

@keyframes rain-129 {
  from {
    left: 86%;
    opacity: 0.55;
    top: -83%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 123%;
  }
}

.raindrop:nth-of-type(130) {
  animation-name: rain-130;
  animation-delay: 7s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 1%;
  opacity: 0.58;
  top: -51%;
}

@keyframes rain-130 {
  from {
    left: 1%;
    opacity: 0.58;
    top: -51%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 91%;
  }
}

.raindrop:nth-of-type(131) {
  animation-name: rain-131;
  animation-delay: 10s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 89%;
  opacity: 0.51;
  top: -55%;
}

@keyframes rain-131 {
  from {
    left: 89%;
    opacity: 0.51;
    top: -55%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 95%;
  }
}

.raindrop:nth-of-type(132) {
  animation-name: rain-132;
  animation-delay: 8s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 29%;
  opacity: 0.38;
  top: -78%;
}

@keyframes rain-132 {
  from {
    left: 29%;
    opacity: 0.38;
    top: -78%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 118%;
  }
}

.raindrop:nth-of-type(133) {
  animation-name: rain-133;
  animation-delay: 8s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 61%;
  opacity: 0.37;
  top: -73%;
}

@keyframes rain-133 {
  from {
    left: 61%;
    opacity: 0.37;
    top: -73%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 113%;
  }
}

.raindrop:nth-of-type(134) {
  animation-name: rain-134;
  animation-delay: 6s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 8%;
  opacity: 0.5;
  top: -84%;
}

@keyframes rain-134 {
  from {
    left: 8%;
    opacity: 0.5;
    top: -84%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 124%;
  }
}

.raindrop:nth-of-type(135) {
  animation-name: rain-135;
  animation-delay: 12s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 75%;
  opacity: 0.52;
  top: -58%;
}

@keyframes rain-135 {
  from {
    left: 75%;
    opacity: 0.52;
    top: -58%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 98%;
  }
}

.raindrop:nth-of-type(136) {
  animation-name: rain-136;
  animation-delay: 0s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 17%;
  opacity: 0.53;
  top: -60%;
}

@keyframes rain-136 {
  from {
    left: 17%;
    opacity: 0.53;
    top: -60%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 100%;
  }
}

.raindrop:nth-of-type(137) {
  animation-name: rain-137;
  animation-delay: 14s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 72%;
  opacity: 0.53;
  top: -58%;
}

@keyframes rain-137 {
  from {
    left: 72%;
    opacity: 0.53;
    top: -58%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 98%;
  }
}

.raindrop:nth-of-type(138) {
  animation-name: rain-138;
  animation-delay: 1s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 53%;
  opacity: 0.42;
  top: -66%;
}

@keyframes rain-138 {
  from {
    left: 53%;
    opacity: 0.42;
    top: -66%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 106%;
  }
}

.raindrop:nth-of-type(139) {
  animation-name: rain-139;
  animation-delay: 3s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 66%;
  opacity: 0.42;
  top: -94%;
}

@keyframes rain-139 {
  from {
    left: 66%;
    opacity: 0.42;
    top: -94%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 134%;
  }
}

.raindrop:nth-of-type(140) {
  animation-name: rain-140;
  animation-delay: 5s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 10%;
  opacity: 0.4;
  top: -76%;
}

@keyframes rain-140 {
  from {
    left: 10%;
    opacity: 0.4;
    top: -76%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 116%;
  }
}

.raindrop:nth-of-type(141) {
  animation-name: rain-141;
  animation-delay: 12s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 31%;
  opacity: 0.35;
  top: -96%;
}

@keyframes rain-141 {
  from {
    left: 31%;
    opacity: 0.35;
    top: -96%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 136%;
  }
}

.raindrop:nth-of-type(142) {
  animation-name: rain-142;
  animation-delay: 19s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 59%;
  opacity: 0.45;
  top: -65%;
}

@keyframes rain-142 {
  from {
    left: 59%;
    opacity: 0.45;
    top: -65%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 105%;
  }
}

.raindrop:nth-of-type(143) {
  animation-name: rain-143;
  animation-delay: 19s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 98%;
  opacity: 0.59;
  top: -71%;
}

@keyframes rain-143 {
  from {
    left: 98%;
    opacity: 0.59;
    top: -71%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 111%;
  }
}

.raindrop:nth-of-type(144) {
  animation-name: rain-144;
  animation-delay: 0s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 79%;
  opacity: 0.4;
  top: -73%;
}

@keyframes rain-144 {
  from {
    left: 79%;
    opacity: 0.4;
    top: -73%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 113%;
  }
}

.raindrop:nth-of-type(145) {
  animation-name: rain-145;
  animation-delay: 2s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 30%;
  opacity: 0.36;
  top: -100%;
}

@keyframes rain-145 {
  from {
    left: 30%;
    opacity: 0.36;
    top: -100%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 140%;
  }
}

.raindrop:nth-of-type(146) {
  animation-name: rain-146;
  animation-delay: 3s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 69%;
  opacity: 0.5;
  top: -73%;
}

@keyframes rain-146 {
  from {
    left: 69%;
    opacity: 0.5;
    top: -73%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 113%;
  }
}

.raindrop:nth-of-type(147) {
  animation-name: rain-147;
  animation-delay: 9s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 42%;
  opacity: 0.31;
  top: -55%;
}

@keyframes rain-147 {
  from {
    left: 42%;
    opacity: 0.31;
    top: -55%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 95%;
  }
}

.raindrop:nth-of-type(148) {
  animation-name: rain-148;
  animation-delay: 8s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 66%;
  opacity: 0.37;
  top: -63%;
}

@keyframes rain-148 {
  from {
    left: 66%;
    opacity: 0.37;
    top: -63%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 103%;
  }
}

.raindrop:nth-of-type(149) {
  animation-name: rain-149;
  animation-delay: 11s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 22%;
  opacity: 0.59;
  top: -94%;
}

@keyframes rain-149 {
  from {
    left: 22%;
    opacity: 0.59;
    top: -94%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 134%;
  }
}

.raindrop:nth-of-type(150) {
  animation-name: rain-150;
  animation-delay: 4s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 22%;
  opacity: 0.31;
  top: -59%;
}

@keyframes rain-150 {
  from {
    left: 22%;
    opacity: 0.31;
    top: -59%;
  }

  to {
    width: 0px;
    opacity: 0;
    top: 99%;
  }
}
`;

export default Rain;