import React, { useEffect } from "react";
import styled from "styled-components";
import error from "assets/icons/error.ico";

function onDownload() {
  window.open("https://metamask.io/", "_blank");
}

const Error = ({ onClose, injectedProps = "Something went wrong.", id }) => {
  useEffect(() => {
    document.getElementById(injectedProps.substring(0, 5) + id).innerHTML = '<p>' + injectedProps + '</p>';
  }, [injectedProps, id]);

  return (
    <Div>
      <div className="error_top">
        <img draggable={false} src={error} alt="error" className="error_img" />
        <div className="error_messages" id={injectedProps.substring(0, 5) + id}></div>
      </div>
      <div className="error_bottom">
        <button onClick={onClose} className="button">
          OK
        </button>
        {injectedProps.includes("No ethereum wallet detected.") && (
          <button onClick={onDownload} className="button">
            Download
          </button>
        )}
      </div>
    </Div>
  );
};

const Div = styled.div`
  background: var(--colorgrad3);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  z-index: 100;
  i {
    font-style: italic;
  }
  .error_top {
    display: flex;
  }
  .error_img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  .error_messages {
    text-align: justify;
    width: calc(100% - 20px);
    display: flex;
    align-self: center;
    flex-direction: column;
    line-height: 15px;
    text-align: center;
  }
  .error_bottom {
    margin-top: 10px;
    display: flex;
    align-self: center;
    width: 50%;
    justify-content: space-evenly;
  }
  .error_button + .error_button {
    margin-left: 10px;
  }
  .error_button:active {
    outline: 1px dotted #000000;
    outline-offset: -5px;
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
`;

export default Error;
