import React from "react";
import EditPalette from "./EditPalette.js";

const Palette = (props: {
  value: PaletteType,
  inputCanvas: ?HTMLCanvasElement,
  paletteOptions: { [string]: any },
  onSetFilterOption: (string, PaletteType) => {},
  onSetPaletteOption: (string, any) => {},
  onAddPaletteColor: (ColorRGBA) => {},
}) => {
  return (
    <>
      <section className={"box_section"}>
        <div className="box_section_title">
          <span>Palette</span>
        </div>
        <div className={`${props.value.name === "nearest" ? " rm_boxes" : ""}`}>
          <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-between', margin: '0 1px 0px 1px'}}>
          <input
            className={props.disabled === true ? "disabled" : ""}
            id="palette_type"
            type="checkbox"
            onChange={(e) => {
              if (props.disabled) return;
              e.target.checked
                ? props.onSetFilterOption("palette", "User/Adaptive")
                : props.onSetFilterOption("palette", "Nearest");
            }}
            checked={props.value.name !== "nearest"}
          />
          <label htmlFor="palette_type">
            <span></span>
          </label>
          {props.value.name !== "nearest" && (
            <button
              onClick={(e) => props.onClickAddColor(e)}
              className={`${
                props.paletteOptions && props.paletteOptions.colors >= 16
                  ? "disabled"
                  : "enabled"
              }`}
              id="edit_color_btn"
            >+</button>
          )}
          </div>
          {props.value.name !== "nearest" && (
            <>

              <EditPalette
                onClickAddColor={props.onClickAddColor}
                value={props.paletteOptions}
                types={props.value.optionTypes.colorDistanceAlgorithm}
                onAddPaletteColor={props.onAddPaletteColor}
                onSetPaletteOption={props.onSetPaletteOption}
                inputCanvas={props.inputCanvas}
              />
            </>
          )}

          {props.value.name === "nearest" && (
            <>
              <span className="slider_label">{props.label}</span>
              <input
                className={`slider${!props.disabled ? "" : " disabled"}`}
                type="range"
                min={props.value.optionTypes.levels.range[0]}
                max={props.value.optionTypes.levels.range[1]}
                value={props.paletteOptions["levels"]}
                step={1}
                onChange={(e) => {
                  if (props.disabled) return;
                  props.onSetPaletteOption(
                    "levels",
                    parseFloat(e.target.value)
                  );
                }}
              />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Palette;
