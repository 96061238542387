import React, { useGlobal, useEffect, setGlobal } from "reactn";

const Color = ({name, boolean, color, label, onSetFilterOption}) => {
  const [halftone] = useGlobal("halftone");
  useEffect(() => {
    halftone ? onSetFilterOption('color', `hsl(${halftone.h}, ${halftone.s}%, ${halftone.l}%)`) : setGlobal({halftone: { h: 0, s: 0, l: 0 }})
  }, [halftone, onSetFilterOption]);

  return (
    <>
      <div className="rm_boxes">
        <input
          id={`bool${name}`}
          type="checkbox"
          checked={boolean}
          onChange={(e) =>
            onSetFilterOption('boolean', e.target.checked)
          }
        />
        <label htmlFor={`bool${name}`}  className="boollabel">
          {label}
        </label>
        <div className={"colorArray pointer"} id="halftoneC">
          <div
            style={{
              backgroundColor: `${color}`,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Color;
