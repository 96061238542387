import React, { setGlobal, useEffect } from "reactn";
import loading from "assets/sky/output.gif";
import styled from "styled-components";

const SkyResidence = ({ token, id }) => {
  useEffect(() => {
    if (token) {
      console.log(token)
      const loader = document.querySelector("#slloader" + id);
      const object = document.querySelector("#slobject" + id);
      loader.style.transition = "opacity 0.2s";
      object.style.transition = "opacity 0.2s";
      loader.style.opacity = 1;
      object.style.opacity = 0;
      loader.style.transition = "opacity 1s";
      object.style.transition = "opacity 1s";
      setGlobal({ appLoading: { name: "Sky Luxury", id: id } });
    }
  }, [id, token]);

  return (
    <Div>
      {token && (
        <>
          <div id={"slloader" + id}>
            <img
              draggable={false}
              alt={"token" + token}
              src={loading}
            />
          </div>
          <object
            onLoad={async (e) => {
              document.querySelector("#slloader" + id).style.opacity = 0;
              e.target.style.opacity = 1;
              setGlobal({ appLoading: null });
            }}
            id={"slobject" + id}
            data={`https://api.heaven.computer/sky/${token}`}
            aria-label={token}
          />
        </>
      )}
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  div {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  object {
    z-index: 2;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

export default SkyResidence;
