const Game = [
  {
    type: 'item',
    text: 'New',
    disable: true,
  },
  {
    type: 'separator',
  },
  {
    type: 'item',
    text: 'Beginner',
  },
  {
    type: 'item',
    text: 'Intermediate',
  },
  {
    type: 'item',
    text: 'Expert',
  }
];

const Help = [
  {
    type: 'item',
    text: 'About...',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Help Center',
    disable: false,
  },
];

const Edit = [
  {
    type: 'item',
    text: 'Rug Mode',
    check: false,
  },
  {
    type: 'separator',
  },
  {
    type: 'item',
    text: 'Close',
    disable: false,
  },
];

const optionsMenuData = { Game, Help, Edit };
export default optionsMenuData;
