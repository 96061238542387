
import { connect } from "react-redux";
import {
  setFilterOption,
  setFilterPaletteOption,
  addPaletteColor,
  importState,
  loadVideoAsync,
  selectFilter,
  setConvertGrayscale,
  setViewEffects,
  setInputCanvas,
  setInputPlaybackRate,
} from "../actions";
import App from "../components/App";
import { mainFilterLister } from "../filters";

const mapStateToProps = (state, ownProps) => ({
  inputImage: state.filters.inputImage,
  outputImage: state.filters.outputImage,
  mainFilters: mainFilterLister,
  selectedFilter: state.filters.selected,
  convertGrayscale: state.filters.convertGrayscale,
  time: state.filters.time,
  inputVideo: state.filters.video,
  playbackRate: state.filters.videoPlaybackRate,
  viewEffects: state.filters.viewEffects,
  optionTypes: state.filters.selected.filter.optionTypes,
  options: state.filters.selected.filter.options,
  inputCanvas: ownProps.inputCanvas
});

const mapDispatchToProps = (dispatch) => ({
  onAddPaletteColor: (value) => dispatch(addPaletteColor(value)),
  onSetFilterOption: (name, value) => dispatch(setFilterOption(name, value)),
  onSetPaletteOption: (name, value) =>
    dispatch(setFilterPaletteOption(name, value)),
  onLoadImage: () => dispatch(loadVideoAsync()),
  onSelectFilter: (name, filter) => dispatch(selectFilter(name, filter)),
  onConvertGrayscale: val => dispatch(setConvertGrayscale(val)),
  onSetViewEffects: enabled => dispatch(setViewEffects(enabled)),
  onSetInputCanvas: canvas => dispatch(setInputCanvas(canvas)),
  onSetInputPlaybackRate: rate => dispatch(setInputPlaybackRate(rate)),
  onImportState: json => dispatch(importState(json)),
});

const ContainedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ContainedApp;
