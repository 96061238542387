import React, { useEffect, useState, useRef, useId, useGlobal } from "reactn";
import styled from "styled-components";
import optionsMenuData from "./optionsMenuData";
import help from "assets/icons/help.ico";
import { OptionsMenu } from "components";
import etherscan from "assets/icons/etherscan.svg";
import opensea from "assets/icons/opensea.svg";
import forward from "assets/icons/forward.ico";
import back from "assets/icons/back.ico";

const Help = ({ onClose, injectedProps }) => {
  const appId = useId().replaceAll(":", "");
  const [contract] = useGlobal("contract");
  const [godDetails, setGodDetails] = useState("");
  const [skyDetails, setSkyDetails] = useState("");
  const backPages = useRef([]);
  const nextPages = useRef([]);
  const [view, setView] = useState("Website");
  const selection = window.getSelection().anchorNode;

  useEffect(() => {
    if (contract)
      contract["viewCollectionDetails(uint256)"]("2").then((r) =>
        setSkyDetails(Object.values(r))
      );
  }, [contract]);

  useEffect(() => {
    if (contract)
      contract["viewCollectionDetails(uint256)"]("1").then((r) =>
        setGodDetails(Object.values(r))
      );
  }, [contract]);

  useEffect(() => {
    const nextHelp = document.getElementById("nextHelp" + appId);
    const backHelp = document.getElementById("backHelp" + appId);
    if (nextPages.current.length > 0) {
      optionsMenuData["View"][3].disable = false;
      nextHelp.classList.add("hasactive");
      nextHelp.style.opacity = "1";
    } else {
      optionsMenuData["View"][3].disable = true;
      nextHelp.classList.remove("hasactive");
      nextHelp.style.opacity = "0.5";
    }
    if (backPages.current.length > 0) {
      optionsMenuData["View"][2].disable = false;
      backHelp.classList.add("hasactive");
      backHelp.style.opacity = "1";
    } else {
      optionsMenuData["View"][2].disable = true;
      backHelp.classList.remove("hasactive");
      backHelp.style.opacity = "0.5";
    }
    if ((nextPages.current.length || backPages.current.length) > 0) {
      optionsMenuData["Edit"][3].disable = false;
    } else {
      optionsMenuData["Edit"][3].disable = true;
    }
    if (selection) {
      optionsMenuData["Edit"][0].disable = true;
      optionsMenuData["Edit"][1].disable = false;
    } else {
      optionsMenuData["Edit"][0].disable = false;
      optionsMenuData["Edit"][1].disable = true;
    }
  }, [view, selection, appId]);

  function onClickOptionItem(item) {
    switch (item.text) {
      case "Artist":
        createViewList("Artist", view);
        break;
      case "Website":
        createViewList("Website", view);
        break;
      case "God Observer":
        createViewList("GO", view);
        break;
      case "Sky Luxury":
        createViewList("SL", view);
        break;
      case "Ghost Software":
        createViewList("GS", view);
        break;
      case "CD Collection":
        createViewList("CD", view);
        break;
      case "Contact Me":
        createViewList("Contact", view);
        break;
      case "Back":
        handleNavigation("back");
        break;
      case "Forward":
        handleNavigation("forward");
        break;
      case "Refresh":
        onRefresh();
        break;
      case "Select All":
        onSelectAll();
        break;
      case "Select None":
        onSelectNone();
        break;
      case "Close":
        onClose();
        break;
      default:
    }
  }

  useEffect(() => {
    if (injectedProps && injectedProps !== "documents") setView(injectedProps);
  }, [injectedProps]);

  useEffect(() => {
    return () => {
      backPages.current = [];
      nextPages.current = [];
    };
  }, []);
  const onRefresh = () => {
    if (selection) onSelectNone();
    backPages.current = [];
    nextPages.current = [];
    setView("Artist");
  };

  function onSelectNone() {
    window.getSelection().removeAllRanges();
  }
  function onSelectAll() {
    const el = document.getElementById("help_inner");
    if (document.body.createTextRange) {
      let range = document.body.createTextRange();
      range.moveToElementText(el);
      range.select();
    } else if (window.getSelection) {
      let selection = window.getSelection();
      let range = document.createRange();
      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  function createViewList(curr, prev) {
    if (curr === prev) return;
    if (selection) onSelectNone();
    backPages.current.push(prev);
    setView(curr);
  }

  function handleNavigation(flow) {
    if (flow === "back") {
      if (backPages.current.length > 0) {
        nextPages.current.unshift(view);
        setView(backPages.current[backPages.current.length - 1]);
        backPages.current[0] === backPages.current[backPages.current.length - 1]
          ? (backPages.current = [])
          : backPages.current.pop();
      } else {
        return;
      }
    } else if (flow === "forward") {
      if (nextPages.current.length > 0) {
        backPages.current.push(view);
        setView(nextPages.current[0]);
        nextPages.current[0] === nextPages.current[nextPages.current.length - 1]
          ? (nextPages.current = [])
          : nextPages.current.shift();
      } else {
        return;
      }
    }
  }

  return (
    <Div>
      <section className="window_toolbar">
        <OptionsMenu items={optionsMenuData} onClickItem={onClickOptionItem} />
      </section>
      <div className="divider"></div>
      <section className="address_bar">
        <div className="div_bar"></div>
        <div className="address_bar_title">
          A<u>d</u>dress
        </div>
        <div className="address_bar_content">
          <div className="address_bar_content_text">
            <img draggable={false} src={help} alt="favicon" className="address_bar_content_img" />
            <span>heaven://help</span>
            <div className="address_bar_buttons">
              <div className="address_bar_go">
                <div />
              </div>
              <div
                id={"backHelp" + appId}
                title="back"
                className="address_bar_back"
                onClick={() => handleNavigation("back")}
              >
                <img draggable={false} alt="back" src={back} />
              </div>
              <div
                id={"nextHelp" + appId}
                title="forward"
                className="address_bar_forward"
                onClick={() => handleNavigation("forward")}
              >
                <img draggable={false} alt="forward" src={forward} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="window_content">
        <div id="help_inner" className="window_content_inner">
          {view === "Website" && (
            <div>
              <h2>About heaven.computer</h2>
              <div className="pagediv"></div>
              <p>Last Updated: August 15, 2021</p>
              <p>
                HeavenComputer (a.k.a. heaven.computer or Heaven Computer) is
                the <span
                  className="classicLink pointer"
                  onClick={() => createViewList("Artist", view)}
                >passion project</span> of a software engineering student and has no affilliation whatsoever with any organization,
                institution or church, and is intended for artistic and
                entretainment purposes only. HeavenComputer is a decentralized
                application operating on the Ethereum blockchain acting as an
                online art exhibition and "NFT" (Non-Fungible Token) collection,
                and uses smart contracts to enable the trade of digital
                creations on a peer-to-peer web3 environment. This privacy
                policy describes how this website (HeavenComputer or “we”)
                collects, uses and shares personal information of users of
                https://heaven.computer. We may change this privacy policy at
                any time. If we make any changes, we will change the Last
                Updated date above.
              </p>
              <h2>Cookie Policy</h2>
              <div className="pagediv"></div>
              <p>
                Like any other website, heaven.computer uses 'cookies'. A small
                cookie is stored on your device in order to enhance your
                experience using the website. More specifically, it is used to
                remember if the user has visited the page before and prevent the
                initial animation from being played. You can easily delete this
                cookie in your browser or by navigating to{" "}
                <i>Start {'>'} Shut Down</i> on https://heaven.computer.
                <br />
                For more general information on cookies, please read{" "}
                <a
                  className="classicLink pointer"
                  href="https://www.privacypolicyonline.com/what-are-cookies/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  "What Are Cookies" from Cookie Consent.
                </a>
              </p>
              <h2>Privacy Policy</h2>
              <div className="pagediv"></div>
              <p>
                We may collect information that is publicly available when you
                visit the website, such as computer operating system type,
                browser type and/or browser language. We don't store nor sell
                any of this data.
              </p>
            </div>
          )}
          {view === "Artist" && (
            <div>
              <h2>About godmin</h2>
              <div className="pagediv"></div>
              <p>
                HeavenComputer was 100% ideated, coded and designed by the
                independent solo developer that goes by the pseudonym of Godmin.
                Godmin was born in Brazil, Rio de Janeiro, and that is where she
                was raised and lived for most of her life. She taught herself
                how to code starting at age 10 by making websites on Geocities
                and Angelfire so she could display her pixel dollz creations,
                and HeavenComputer is an ode and homage to these days. In the
                years that followed, Godmin continued her coding saga by
                creating MySpace themes, and then later Tumblr. Another skill in
                which Godmin is entirely self-taught is English: Godmin learned
                it by playing Legend Of Zelda: Ocarina of Time and World of
                Warcraft and translating music and interviews given by her
                favorite artists, Britney Spears and Oasis. Godmin is a six-time
                college drop out — her unfinished degrees include Astronomy,
                Performing Arts and Software Engineering, and they reflect
                Godmin's diversity of interests. She started learning about web3
                and studying Solidity in 2019, and started developing
                HeavenComputer in May 2020. She spent 18 months obsessively
                crafting the website, smart contracts, servers and art, so she
                could create an unique experience, full of easter eggs and fun
                details. HeavenComputer is believed to be the very first 100%
                female-created NFT project in Ethereum. Godmin also finds it
                rather uncomfortable to speak of herself in the third person,
                but she thought doing it in the first person was weirder.
              </p>
            </div>
          )}
          {view === "GS" && (
            <div>
              <h2>Ghost Software Help</h2>
              <div className="pagediv"></div>
              <p>
                All the NFT artwork minted on Ghost Software is licensed under{" "}
                <a
                  className="classicLink pointer"
                  href="https://www.niftylicense.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NFT License (NIFTY)
                </a>{" "}
                and the video/image associated with the NFT will be hosted on a
                decentralized and permanent file sharing service,{" "}
                <a
                  className="classicLink pointer"
                  href="https://ipfs.io/#how"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IPFS
                </a>
                , and{" "}
                <a
                  className="classicLink pointer"
                  href="https://docs.ipfs.io/concepts/persistence/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  pinned
                </a>{" "}
                for as long as the project is active. The token metadata will
                remain stored on a centralized database for as long as the
                project hasn't reached its maximum number of invocations, and
                after that, the respective metadata will also be uploaded to
                IPFS and pinned for indefinite time. The contract's baseURI will
                then be modified to point to the IPFS address of the contracts
                and finally locked to prevent any future changes. Although we
                intend to pin the IPFS files, it is recommended that the owner
                of the token pins the IPFS files associated with their NFT
                themselves.
              </p>
              <p>
                <strong>Project name:</strong> HeavenComputer
                <br />
                <strong>Contract address:</strong>{" "}
                0x80ADB36595239fe918c7D118C1F81e07d070801a
                <br />
                <strong>Collection ID:</strong> 0<br />
                <strong>Collection name:</strong> GhostSoftware
                <br />
                <strong>Price:</strong> 0.07 ETH
                <br />
                <strong>Current invocations:</strong> 7777
                <br />
                <strong>Maximum invocations:</strong> 7777
                <br />
                <strong>Paused:</strong> No
                <br />
                <strong>Locked:</strong> No
                <br />
                <strong>Sold Out:</strong> Yes
              </p>
              <a
                className="classicLink pointer"
                href="https://etherscan.io/address/0x80ADB36595239fe918c7D118C1F81e07d070801a"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img draggable={false}
                  className="link_button pointer"
                  src={etherscan}
                  alt="etherscan"
                  title="etherscan"
                />
              </a>
              <a
                className="classicLink pointer"
                href="https://opensea.io/collection/heavencomputer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img draggable={false}
                  className="link_button pointer"
                  src={opensea}
                  alt="opensea"
                  title="opensea"
                />
              </a>
            </div>
          )}
          {view === "CD" && (
            <div>
              <h2>CD Collection Help</h2>
              <div className="pagediv"></div>
              <p>
                The artwork of each CD in the CD Collection is licensed under
                Creative Commons Attribution-NonCommercial-ShareAlike 4.0
                International{" "}
                <a
                  className="classicLink pointer"
                  href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CC BY-NC-SA 4.0
                </a>{" "}
                license and the image associated with the NFT is hosted on a
                decentralized and permanent file sharing service,{" "}
                <a
                  className="classicLink pointer"
                  href="https://ipfs.io/#how"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IPFS
                </a>
                , and{" "}
                <a
                  className="classicLink pointer"
                  href="https://docs.ipfs.io/concepts/persistence/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  pinned
                </a>{" "}
                for as long as the project is active.
              </p>
              <p>
                <strong>Project name:</strong> CD Collection
                <br />
                <strong>Contract address:</strong>{" "}
                0xE0B52d3444EF1518F3856c1F9502b71A3Bb4D4B7 (via{" "}
                <a
                  className="classicLink pointer"
                  href="https://astrodrop.xyz/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  astrodrop
                </a>
                )
                <br />
                <strong>Price:</strong> 0 ETH
              </p>
              <p>
                <strong>Collection address:</strong>{" "}
                0xad78E15a5465C09F6E6522b0d98b1F3b6B67Ee7e
                <br />
                <strong>Collection name:</strong> GhostSoftware CD-ROM
                <br />
                <strong>Collection ID:</strong> 0
              </p>
              <a
                className="classicLink pointer"
                href="https://etherscan.io/address/0xad78E15a5465C09F6E6522b0d98b1F3b6B67Ee7e"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img draggable={false}
                  className="link_button pointer"
                  src={etherscan}
                  alt="etherscan"
                  title="etherscan"
                />
              </a>
              <a
                className="classicLink pointer"
                href="https://opensea.io/collection/ghostsoftwarecdrom"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img draggable={false}
                  className="link_button pointer"
                  src={opensea}
                  alt="opensea"
                  title="opensea"
                />
              </a>
            </div>
          )}
          {view === "SL" && (
            <div>
              <h2>Sky Luxury Help</h2>
              <div className="pagediv"></div>
              <p>
                All the NFT artwork minted on Sky Luxury is licensed under
                Creative Commons Attribution-ShareAlike 4.0 International{" "}
                <a
                  className="classicLink pointer"
                  href="https://creativecommons.org/licenses/by-sa/4.0/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CC BY-SA 4.0
                </a>{" "}
                and the video/image and HTML code associated with the NFT will be hosted on a
                decentralized and permanent file sharing service,{" "}
                <a
                  className="classicLink pointer"
                  href="https://ipfs.io/#how"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IPFS
                </a>
                , and{" "}
                <a
                  className="classicLink pointer"
                  href="https://docs.ipfs.io/concepts/persistence/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  pinned
                </a>{" "}
                for as long as the project is active. The token metadata will
                remain stored on a centralized database for as long as the
                project hasn't reached its maximum number of invocations, and
                after that, the respective metadata will also be uploaded to
                IPFS and pinned for indefinite time. The contract's baseURI will
                then be modified to point to the IPFS address of the contracts
                and finally locked to prevent any future changes. Although we
                intend to pin the IPFS files, it is recommended that the owner
                of the token pins the IPFS files associated with their NFT
                themselves.
              </p>
              <p>
                Special thanks to: {" "}
                <a
                  className="classicLink pointer"
                  href="https://codepen.io/thebabydino"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ana Tudor
                </a> (for CSS polyhedra tutorials), <a
                  className="classicLink pointer"
                  href="https://codepen.io/amit_sheen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Amit Sheen
                </a> (for CSS 3D scenes tutorials), <a
                  className="classicLink pointer"
                  href="https://codepen.io/josetxu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Josetxu 
                </a> and <a
                  className="classicLink pointer"
                  href="https://nimbupani.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Divya Manian 
                </a> (for CSS tessellations).
              </p>             
              <p>
                <strong>Project name:</strong> HeavenComputer
                <br />
                <strong>Contract address:</strong>{" "}
                0x80ADB36595239fe918c7D118C1F81e07d070801a
                <br />
                <strong>Collection ID:</strong> 2<br />
                <strong>Collection name:</strong> SkyLuxury
                <br />
                <strong>Price:</strong> 0.15 ETH
                <br />
                <strong>Current invocations:</strong> {parseInt(skyDetails[2])}
                <br />
                <strong>Maximum invocations:</strong> 333
                <br />
                <strong>Paused:</strong> {skyDetails[4] ? "Yes" : "No"}
                <br />
                <strong>Locked:</strong> No
                <br />
                <strong>Sold Out:</strong>{" "}
                {parseInt(skyDetails[2]) <= parseInt(skyDetails[3]) ? "No" : "Yes"}
              </p>
              <a
                className="classicLink pointer"
                href="https://etherscan.io/address/0x80ADB36595239fe918c7D118C1F81e07d070801a"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img draggable={false}
                  className="link_button pointer"
                  src={etherscan}
                  alt="etherscan"
                  title="etherscan"
                />
              </a>
              <a
                className="classicLink pointer"
                href="https://opensea.io/collection/heavencomputer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img draggable={false}
                  className="link_button pointer"
                  src={opensea}
                  alt="opensea"
                  title="opensea"
                />
              </a>
            </div>
          )}
          {view === "GO" && (
            <div>
              <h2>God Observer Help</h2>
              <div className="pagediv"></div>
              <p>
                All the NFT artwork minted on God Observer is licensed under
                Creative Commons Attribution-ShareAlike 4.0 International{" "}
                <a
                  className="classicLink pointer"
                  href="https://creativecommons.org/licenses/by-sa/4.0/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CC BY-SA 4.0
                </a>{" "}
                and the video/image associated with the NFT will be hosted on a
                decentralized and permanent file sharing service,{" "}
                <a
                  className="classicLink pointer"
                  href="https://ipfs.io/#how"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IPFS
                </a>
                , and{" "}
                <a
                  className="classicLink pointer"
                  href="https://docs.ipfs.io/concepts/persistence/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  pinned
                </a>{" "}
                for as long as the project is active. The token metadata will
                remain stored on a centralized database for as long as the
                project hasn't reached its maximum number of invocations, and
                after that, the respective metadata will also be uploaded to
                IPFS and pinned for indefinite time. The contract's baseURI will
                then be modified to point to the IPFS address of the contracts
                and finally locked to prevent any future changes. Although we
                intend to pin the IPFS files, it is recommended that the owner
                of the token pins the IPFS files associated with their NFT
                themselves.
              </p>
              <p>
                All the images and videos used to create the artwork in
                GodObserver were listed as being in the public domain, and when
                not, appropriate attribution following specific license
                guidelines has been given. If you believe a mistake has been
                made, please contact me{" "}
                <a
                  className="classicLink pointer"
                  href="mailto:godmin@heaven.computer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  godmin@heaven.computer
                </a>
                .
              </p>
              <p>
                <strong>Project name:</strong> HeavenComputer
                <br />
                <strong>Contract address:</strong>{" "}
                0x80ADB36595239fe918c7D118C1F81e07d070801a
                <br />
                <strong>Collection ID:</strong> 1<br />
                <strong>Collection name:</strong> GodObserver
                <br />
                <strong>Price:</strong> 0.15 ETH
                <br />
                <strong>Current invocations:</strong> {parseInt(godDetails[2])}
                <br />
                <strong>Maximum invocations:</strong> 3334
                <br />
                <strong>Paused:</strong> {godDetails[4] ? "Yes" : "No"}
                <br />
                <strong>Locked:</strong> No
                <br />
                <strong>Sold Out:</strong>{" "}
                {parseInt(godDetails[2]) <= parseInt(godDetails[3]) ? "No" : "Yes"}
              </p>
              <a
                className="classicLink pointer"
                href="https://etherscan.io/address/0x80ADB36595239fe918c7D118C1F81e07d070801a"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img draggable={false}
                  className="link_button pointer"
                  src={etherscan}
                  alt="etherscan"
                  title="etherscan"
                />
              </a>
              <a
                className="classicLink pointer"
                href="https://opensea.io/collection/heavencomputer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img draggable={false}
                  className="link_button pointer"
                  src={opensea}
                  alt="opensea"
                  title="opensea"
                />
              </a>
            </div>
          )}
          {view === "Contact" && (
            <div>
              <h2>Contact</h2>
              <div className="pagediv"></div>
              <p>
                Tweet me at{" "}
                <a
                  className="classicLink pointer"
                  href="https://www.twitter.com/heaven_computer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @heaven_computer
                </a>
                <br />
                Join the discord{" "}
                <a
                  className="classicLink pointer"
                  href="https://discord.gg/heavencomputer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  heaven.computer
                </a>
                <br />
                Contact me on{" "}
                <a
                  className="classicLink pointer"
                  href="mailto:godmin@heaven.computer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  godmin@heaven.computer
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  background: var(--colorgrad3);
  p, span, a, td, i, u, b, strong, h1, h2, h3, h4, h5 {
    user-select: text !important;
  }
  h2 {
    font-weight: 800;
    font-size: 1.5em;
    padding: 4px 0;
    line-height: 16px;
  }
  p {
    padding: 4px 0 20px 0;
    line-height: 14px;
  }
  span {
    display: block;
  }
  .window_content_inner {
    padding: 10px;
    height: calc(100% - 4px);
  }
  img.link_button {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`;

export default Help;
