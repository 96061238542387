import React, { useEffect, useState, setGlobal } from "reactn";
import axios from "axios";
import styled from "styled-components";
import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import empty from "assets/sweeper/empty.png";
import bg2 from "assets/god/spaceship/bg2.gif";
import star0 from "assets/god/spaceship/star0.gif";
import star1 from "assets/god/spaceship/star1.gif";
import star2 from "assets/god/spaceship/star2.gif";
import star3 from "assets/god/spaceship/star3.gif";
import star4 from "assets/god/spaceship/star4.gif";
import star5 from "assets/god/spaceship/star5.gif";
import star6 from "assets/god/spaceship/star6.gif";
import star7 from "assets/god/spaceship/star7.gif";
import star8 from "assets/god/spaceship/star8.gif";
import star9 from "assets/god/spaceship/star9.gif";
import star10 from "assets/god/spaceship/star10.gif";
import star11 from "assets/god/spaceship/star11.gif";
import star12 from "assets/god/spaceship/star12.gif";
import star13 from "assets/god/spaceship/star13.gif";
import star14 from "assets/god/spaceship/star14.gif";
import star15 from "assets/god/spaceship/star15.gif";
import star16 from "assets/god/spaceship/star16.gif";
import star17 from "assets/god/spaceship/star17.gif";
import star18 from "assets/god/spaceship/star18.gif";
import star19 from "assets/god/spaceship/star19.gif";
import star20 from "assets/god/spaceship/star20.gif";
import star21 from "assets/god/spaceship/star21.gif";
import star22 from "assets/god/spaceship/star22.gif";
import star23 from "assets/god/spaceship/star23.gif";
import star24 from "assets/god/spaceship/star24.gif";
import star25 from "assets/god/spaceship/star25.gif";
import star26 from "assets/god/spaceship/star26.gif";
import star27 from "assets/god/spaceship/star27.gif";
import star28 from "assets/god/spaceship/star28.gif";
import star29 from "assets/god/spaceship/star29.gif";
import cluster0 from "assets/god/spaceship/cluster0.gif";
import cluster1 from "assets/god/spaceship/cluster1.gif";
import cluster2 from "assets/god/spaceship/cluster2.gif";
import cluster3 from "assets/god/spaceship/cluster3.gif";
import cluster4 from "assets/god/spaceship/cluster4.gif";
import cluster5 from "assets/god/spaceship/cluster5.gif";
import cluster6 from "assets/god/spaceship/cluster6.gif";
import cluster7 from "assets/god/spaceship/cluster7.gif";
import cluster8 from "assets/god/spaceship/cluster8.gif";
import cluster9 from "assets/god/spaceship/cluster9.gif";
import planet0 from "assets/god/spaceship/planet0.gif";
import planet1 from "assets/god/spaceship/planet1.gif";
import planet2 from "assets/god/spaceship/planet2.gif";
import planet3 from "assets/god/spaceship/planet3.gif";
import planet4 from "assets/god/spaceship/planet4.gif";
import planet5 from "assets/god/spaceship/planet5.gif";
import planet6 from "assets/god/spaceship/planet6.gif";
import planet7 from "assets/god/spaceship/planet7.gif";
import planet8 from "assets/god/spaceship/planet8.gif";
import planet9 from "assets/god/spaceship/planet9.gif";
import planet10 from "assets/god/spaceship/planet10.gif";
import planet11 from "assets/god/spaceship/planet11.gif";
import planet12 from "assets/god/spaceship/planet12.gif";
import planet13 from "assets/god/spaceship/planet13.gif";
import planet14 from "assets/god/spaceship/planet14.gif";
import planet15 from "assets/god/spaceship/planet15.gif";
import planet16 from "assets/god/spaceship/planet16.gif";
import planet17 from "assets/god/spaceship/planet17.gif";
import planet18 from "assets/god/spaceship/planet18.gif";
import planet19 from "assets/god/spaceship/planet19.gif";
import planet20 from "assets/god/spaceship/planet20.gif";
import planet21 from "assets/god/spaceship/planet21.gif";
import planet22 from "assets/god/spaceship/planet22.gif";
import planet23 from "assets/god/spaceship/planet23.gif";
import planet24 from "assets/god/spaceship/planet24.gif";
import planet25 from "assets/god/spaceship/planet25.gif";
import planet26 from "assets/god/spaceship/planet26.png";
import planet27 from "assets/god/spaceship/planet27.png";
import planet28 from "assets/god/spaceship/planet28.png";
import planet29 from "assets/god/spaceship/planet29.png";
import planet30 from "assets/god/spaceship/planet30.png";
import planet31 from "assets/god/spaceship/planet31.gif";
import planet32 from "assets/god/spaceship/planet32.png";
import planet33 from "assets/god/spaceship/planet33.png";
import planet34 from "assets/god/spaceship/planet34.png";
import planet35 from "assets/god/spaceship/planet35.png";
import planet36 from "assets/god/spaceship/planet36.png";
import planet37 from "assets/god/spaceship/planet37.png";
import planet38 from "assets/god/spaceship/planet38.gif";
import planet39 from "assets/god/spaceship/planet39.gif";
import planet40 from "assets/god/spaceship/planet40.gif";
import planet41 from "assets/god/spaceship/planet41.gif";
import planet42 from "assets/god/spaceship/planet42.png";
import planet43 from "assets/god/spaceship/planet43.gif";
import planet44 from "assets/god/spaceship/planet44.png";
import planet45 from "assets/god/spaceship/planet45.png";
import planet46 from "assets/god/spaceship/planet46.png";
import planet47 from "assets/god/spaceship/planet47.png";
import planet48 from "assets/god/spaceship/planet48.png";
import planet49 from "assets/god/spaceship/planet49.gif";
import planet50 from "assets/god/spaceship/planet50.gif";
import planet51 from "assets/god/spaceship/planet51.gif";
import planet52 from "assets/god/spaceship/planet52.gif";
import planet53 from "assets/god/spaceship/planet53.gif";
import planet54 from "assets/god/spaceship/planet54.gif";
import planet55 from "assets/god/spaceship/planet55.png";
import planet56 from "assets/god/spaceship/planet56.png";
import planet57 from "assets/god/spaceship/planet57.png";
import planet58 from "assets/god/spaceship/planet58.png";
import planet59 from "assets/god/spaceship/planet59.png";
import ufo0 from "assets/god/spaceship/ufo0.gif";
import ufo1 from "assets/god/spaceship/ufo1.gif";
import ufo2 from "assets/god/spaceship/ufo2.gif";
import ufo3 from "assets/god/spaceship/ufo3.gif";
import ufo4 from "assets/god/spaceship/ufo4.gif";
import ufo5 from "assets/god/spaceship/ufo5.gif";
import ufo6 from "assets/god/spaceship/ufo6.gif";
import ufo7 from "assets/god/spaceship/ufo7.gif";
import ufo8 from "assets/god/spaceship/ufo8.gif";
import ufo9 from "assets/god/spaceship/ufo9.gif";
import ufo10 from "assets/god/spaceship/ufo10.gif";
import rock0 from "assets/god/spaceship/rock0.png";
import rock1 from "assets/god/spaceship/rock1.png";
import rock2 from "assets/god/spaceship/rock2.png";
import rock3 from "assets/god/spaceship/rock3.png";
import galaxy0 from "assets/god/spaceship/galaxy0.gif";
import galaxy1 from "assets/god/spaceship/galaxy1.png";
import galaxy2 from "assets/god/spaceship/galaxy2.png";
import galaxy3 from "assets/god/spaceship/galaxy3.gif";
import galaxy4 from "assets/god/spaceship/galaxy4.gif";
import earth from "assets/god/spaceship/1000.gif";
import loading from "assets/god/ui/loading.gif";

const SpaceShuttle = ({ newToken, baseURI }) => {
  const [imgBlob, setImgBlob] = useState(null);
  const [tokenMetadata, setTokenMetadata] = useState(null);

  useEffect(() => {
    function fetchMetadata(tokenId, baseURI) {
      axios
        .get(baseURI + "/token/" + tokenId, {
          responseType: "json",
        })
        .then((response) => {
          setTokenMetadata(response.data);
          setGlobal({ appLoading: null });
          setGlobal({ loading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    function fetchImageBlob(tokenId, baseURI) {
      axios
        .get(baseURI + "/god/" + tokenId, { responseType: "blob" })
        .then((response) => {
          fetchMetadata(newToken, baseURI);
          const myUrl = (window.URL || window.webkitURL).createObjectURL(
            new Blob([response.data])
          );
          setImgBlob(myUrl);
        })
        .catch((error) => {
          console.log(error, "error");
          return;
        });
    }
    if (newToken !== undefined && baseURI !== undefined)
      fetchImageBlob(newToken, baseURI);
  }, [newToken, baseURI]);

  useEffect(() => {
    $(`.spaceship_gif`).draggable({
      scroll: false,
      stack: "span",
    });
  }, []);

  return (
    <>
      <Div>
        <div id="starsBg" className="selectable">
          <div>
            <p>welcome onboard the god.observer space shuttle!</p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={ufo3} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star26} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star1} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet2} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={galaxy2} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star20} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star17} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star14} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet17} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star28} />
            </span>
          </div>
          <div>
            <h1>you have been assigned god #{newToken}</h1>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster1} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star23} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster4} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star15} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet6} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star2} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={rock2} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star22} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet9} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star24} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet7} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star20} />
            </span>
            <img
              alt="shrine"
              className="spaceship_gif"
              style={{ filter: "contrast(200%)" }}
              src={cluster2}
            />
          </div>
          <div>
            <p>
              {tokenMetadata
                ? `that goes by the name of ${tokenMetadata.name.split("#100")[0]}`
                : "we are currently fetching data from the telescope"}
            </p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star0} />
            </span>
            <img
              alt="shrine"
              className="spaceship_gif"
              style={{ position: "absolute", right: "70%" }}
              src={planet0}
            />
            <span className="spaceship_gif">
              <img alt="space gif" src={planet1} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={galaxy4} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star6} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={rock0} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <img
              alt="shrine"
              className="spaceship_gif"
              style={{ filter: "grayscale(100%)" }}
              src={cluster0}
            />
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={ufo2} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet10} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star6} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star1} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet4} />
            </span>
          </div>
          <div>
            <p>
              {tokenMetadata
                ? `and has the archetype code ${tokenMetadata.attributes[0].value}`
                : "once we have the data, it will appear here"}
            </p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster4} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet12} />
            </span>
          </div>
          <div>
            <img
              alt="shrine"
              style={{ position: "absolute", right: "10%" }}
              className="spaceship_gif"
              src={planet14}
            />
            <span className="spaceship_gif">
              <img alt="space gif" src={star10} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet11} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={rock3} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star4} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet43} />
            </span>
          </div>
          <div>
            <img
              alt="shrine"
              className="spaceship_gif"
              style={{ position: "absolute", left: 0 }}
              src={ufo9}
            />
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster6} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet16} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star15} />
            </span>
          </div>
          <div>
            <p>
              {tokenMetadata
                ? `from the constellation of ${tokenMetadata.attributes[1].value.toLowerCase()}`
                : "and your god will be displayed"}
            </p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={ufo8} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star12} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster8} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet18} />
            </span>

            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet38} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star27} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star9} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet20} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star3} />
            </span>
          </div>
          <div>
            <p>
              {tokenMetadata
                ? `located in the ${tokenMetadata.attributes[2].value.toLowerCase()}`
                : "at the very end of this trip"}
            </p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star10} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet8} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star25} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={galaxy3} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet24} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet22} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={rock1} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star16} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet45} />
            </span>
            <img
              alt="shrine"
              style={{ position: "absolute", right: "5%" }}
              className="spaceship_gif"
              src={galaxy1}
            />
          </div>
          <div>
            <img
              alt="shrine"
              className="spaceship_gif"
              src={ufo10}
              style={{ position: "absolute", left: 0 }}
            />
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star22} />
            </span>
            <img
              alt="shrine"
              className="spaceship_gif"
              style={{ position: "absolute", left: "70%" }}
              src={star3}
            />
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star27} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet5} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet59} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={ufo0} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star2} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster5} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet26} />
            </span>
          </div>
          <div>
            <p>
              {tokenMetadata
                ? `which is a ${tokenMetadata.attributes[3].value.toLowerCase()}`
                : "this may take a few minutes"}
            </p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet40} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star28} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster7} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet3} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={galaxy2} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet19} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star5} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet27} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet28} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet32} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster6} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star8} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet33} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet34} />
            </span>
          </div>
          <div>
            <p>
              {tokenMetadata
                ? `processed with data visualization ${tokenMetadata.attributes[4].value}`
                : "or more..."}
            </p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet35} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet36} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={galaxy0} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={rock2} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet42} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet29} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster7} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={ufo4} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet37} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star19} />
            </span>
            <img
              alt="shrine"
              className="spaceship_gif"
              style={{ position: "absolute", right: 0 }}
              src={ufo5}
            />
          </div>
          <div>
            <img
              alt="shrine"
              className="spaceship_gif"
              src={cluster0}
              style={{ filter: "grayscale(100%)" }}
            />
            <span className="spaceship_gif">
              <img alt="space gif" src={planet23} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet39} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster3} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star7} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet57} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={rock3} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star5} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster8} />
            </span>
          </div>
          <div>
            <p>
              {tokenMetadata
                ? `and with data sonification ${tokenMetadata.attributes[5].value}`
                : "as gods tend to be elusive"}
            </p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet48} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star7} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={galaxy0} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star11} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet21} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet58} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet15} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet47} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet49} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star4} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet50} />
            </span>
          </div>
          <div>
            <p>
              {tokenMetadata
                ? `of musica universalis ${tokenMetadata.attributes[6].value}`
                : "in the meantime"}
            </p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster4} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet51} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star25} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet52} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star29} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star12} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet56} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet30} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet41} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet31} />
            </span>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star18} />
            </span>
          </div>
          <div>
            <p>
              {tokenMetadata
                ? `${tokenMetadata.description.split('*')[0]}`
                : "have fun rearranging the sky!"}
            </p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet53} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star14} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet54} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={ufo1} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet13} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={star21} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={cluster9} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet44} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={star13} />
            </span>
            <img
              alt="shrine"
              className="spaceship_gif"
              src={cluster2}
              style={{ filter: "contrast(200%)" }}
            />
          </div>
          <div>
            <p>
              we hope you had an <i>out of this world</i> experience!
            </p>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={ufo6} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet55} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={ufo7} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="empty" src={empty} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={rock0} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet25} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              <img alt="space gif" src={planet46} />
            </span>
            <span className="spaceship_gif">
              <img alt="space gif" src={galaxy2} />
            </span>
          </div>
          <div>
            <span className="spaceship_gif">
              {imgBlob ? (
                <video
                  autoPlay
                  loop
                  muted
                  controls={false}
                  playsInline
                  preload="auto"
                  className="go_token_image"
                  id={"token" + newToken}
                  src={imgBlob}
                />
              ) : (
                <img
                  draggable={false}
                  style={{ objectFit: "none", width: "426px", height: "240px" }}
                  alt={"token" + newToken}
                  className="go_token_image"
                  id={"token" + newToken}
                  src={loading}
                />
              )}
            </span>
          </div>
          <div>
            <span>
              <img draggable={false} alt="space gif" src={earth} />
            </span>
          </div>
        </div>
      </Div>
    </>
  );
};

const Div = styled.div`
  width: 100%;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: url(${bg2});
  background-position: top;
  background-repeat: repeat;
  flex-direction: column;
  margin-top: 10px;
  h1,
  h2,
  h3,
  h4,
  p {
    max-width: 50%;
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    font-size: 14px;
    line-height: 1.5;
    justify-self: center;
    align-self: center;
    display: flex;
    margin: 10px;
    i {
      margin: 0 7px;
    }
  }
  .spaceship_gif {
    align-self: center;
  }
  .clockwise {
    animation: clockwise 2s infinite linear;
  }
  .counterclock {
    animation: counterclock 2s infinite linear;
  }
  @keyframes clockwise {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  @keyframes counterclock {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  #starsBg {
    width: 100%;
    display: block;
    div {
      width: 100%;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }
`;

export default SpaceShuttle;
