import React, { useEffect, useState, setGlobal } from "reactn";
import styled from "styled-components";
import { objects, images } from "./utils/objects.js";
import loading from "assets/god/ui/loading.gif";

const AllObjects = ({ handleViewObject, id }) => {
  const [page, setPage] = useState(1);
  const max = 6;

  useEffect(() => {
    if (!objects) {
      setGlobal({ appLoading: { name: "God Observer", id: id } });
    } else {
      setGlobal({ appLoading: null });
    }
  }, [id]);

  useEffect(() => {
    if (objects && objects.length > 0) {
      const last = document.getElementById(id + "ao_go_last").classList;
      const first = document.getElementById(id + "ao_go_first").classList;
      const next = document.getElementById(id + "ao_go_next").classList;
      const prev = document.getElementById(id + "ao_go_previous").classList;
      const rnd = document.getElementById(id + "ao_go_random").classList;
      if (objects.length <= max) {
        prev.add("disabled");
        next.add("disabled");
        first.add("disabled");
        last.add("disabled");
        rnd.add("disabled");
      } else {
        rnd.remove("disabled");
        next.remove("disabled");
        prev.remove("disabled");
        first.remove("disabled");
        last.remove("disabled");
      }
      if (page === 1 && objects.length <= max) {
        prev.add("disabled");
        next.add("disabled");
        first.add("disabled");
        last.add("disabled");
      } else if (page === 1 && objects.length > max) {
        prev.add("disabled");
        next.remove("disabled");
        first.add("disabled");
        last.remove("disabled");
      } else if (page >= objects.length / max) {
        next.add("disabled");
        prev.remove("disabled");
        first.remove("disabled");
        last.add("disabled");
      } else {
        next.remove("disabled");
        prev.remove("disabled");
        first.remove("disabled");
        last.remove("disabled");
      }
    }
  }, [page, id]);

  function handlePageChange(number) {
    if (number === page || objects.length <= max) return;
    if (number < 1) {
      return;
    } else if (number - 1 > objects.length / max) {
      return;
    } else {
      setPage(number);
    }
  }

  return (
    <Div>
      {(!objects || objects.length === 0) && <p>Loading...</p>}
      {objects && objects.length > 0 && (
        <div className="buttons_row">
          <div
            className="go_mint_btn pointer"
            id={id + "ao_go_first"}
            onClick={() => handlePageChange(1)}
          >
            &#60;&#60;
          </div>
          <div
            className="go_mint_btn pointer"
            id={id + "ao_go_previous"}
            onClick={() => handlePageChange(page - 1)}
          >
            &#60;
          </div>
          <div
            className="go_mint_btn pointer"
            id={id + "ao_go_random"}
            onClick={() => {
              let num = Math.ceil(Math.random() * (objects.length / max));
              do {num = Math.ceil(Math.random() * (objects.length / max))} while (num === page)
              handlePageChange(num)
            }}
          >
            ?
          </div>
          <div
            className="go_mint_btn pointer"
            id={id + "ao_go_next"}
            onClick={() => handlePageChange(page + 1)}
          >
            &#62;
          </div>
          <div
            className="go_mint_btn pointer"
            id={id + "ao_go_last"}
            onClick={() =>
              handlePageChange(Math.round((objects.length + 1) / max))
            }
          >
            &#62;&#62;
          </div>
        </div>
      )}
      <div className="token_rows">
        {objects &&
          Object.values(objects).map((object, index) => {
            if (objects.slice((page - 1) * max, page * max).includes(object)) {
              return (
                <div className="token_item" id={index} key={index}>
                  <div
                    style={{ position: "relative" }}
                    className="go_token_box pointer"
                    onPointerUp={() => handleViewObject(object)}
                  >
                    <img
                      style={{
                        zIndex: 1,
                        objectFit: "none",
                        width: "426px",
                        height: "239px",
                      }}
                      src={loading}
                      alt={object.name}
                      id={"loadingobs" + object.img}
                    />
                    {images[object.img] && (
                      <img
                        style={{ zIndex: 2, display: "none" }}
                        onLoad={(e) => {
                          document.getElementById(
                            "loadingobs" + object.img
                          ).style.display = "none";
                          document.getElementById(
                            "imgurl" + object.img
                          ).style.display = "block";
                        }}
                        src={images[object.img]}
                        alt={object.name}
                        id={"imgurl" + object.img}
                      />
                    )}
                  </div>
                  <div className="token_caption">
                    <div>{object.name.toLowerCase()}</div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  color: white;
  img {
    width: 100%;
    max-width: 426px;
  }
`;

export default AllObjects;
