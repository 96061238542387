const Bin = [
  {
    type: 'item',
    text: 'Empty',
    disable: true,
  },
];
const Help = [
  {
    type: 'item',
    text: 'About...',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Help Center',
    disable: false,
  },
];
const Edit = [
  {
    type: 'item',
    text: 'Select All',
    disable: true,
  },
  {
    type: 'item',
    text: 'Select None',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Close',
    disable: false,
  },
];

const optionsMenuData = { Bin, Help, Edit };
export default optionsMenuData;
