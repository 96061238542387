import { RANGE, PALETTE } from "../constants";
import * as palettes from "../palettes";
import { cloneCanvas, fillBufferPixel, getBufferIndex, rgba } from "../utils";

const optionTypes = {
  thresholdR: { type: RANGE, range: [0, 255], step: 0.5, default: 127.5, label: 'red' },
  thresholdG: { type: RANGE, range: [0, 255], step: 0.5, default: 127.5, label: 'green' },
  thresholdB: { type: RANGE, range: [0, 255], step: 0.5, default: 127.5, label: 'blue' },
  palette: { type: PALETTE, default: palettes.nearest }
};

const defaults = {
  thresholdR: optionTypes.thresholdR.default,
  thresholdG: optionTypes.thresholdG.default,
  thresholdB: optionTypes.thresholdB.default,
  palette: optionTypes.palette.default
};

const binarize = (
  input: HTMLCanvasElement,
  options: {
    thresholdR: number,
    thresholdG: number,
    thresholdB: number,
    palette: Palette
  } = defaults
): HTMLCanvasElement => {
  const getColor = (val: number, threshold: number): number =>
    val > threshold ? 255 : 0;

  const { thresholdR, thresholdG, thresholdB, palette } = options;
  const output = cloneCanvas(input, false);

  const inputCtx = input.getContext("2d", {
  willReadFrequently: true,
});
  const outputCtx = output.getContext("2d", {
  willReadFrequently: true,
});

  if (!inputCtx || !outputCtx) {
    return input;
  }

  const buf = inputCtx.getImageData(0, 0, input.width, input.height).data;

  for (let x = 0; x < input.width; x += 1) {
    for (let y = 0; y < input.height; y += 1) {
      const i = getBufferIndex(x, y, input.width);
      const prePaletteCol = rgba(
        getColor(buf[i], thresholdR),
        getColor(buf[i + 1], thresholdG),
        getColor(buf[i + 2], thresholdB),
      );
      const col = palette.getColor(prePaletteCol, palette.options);
      fillBufferPixel(buf, i, col[0], col[1], col[2], 255);
    }
  }

  outputCtx.putImageData(new ImageData(buf, output.width, output.height), 0, 0);
  return output;
};

const defaultFunc = {
  name: "Binarize",
  func: binarize,
  optionTypes,
  options: defaults,
  defaults
};

export default defaultFunc;
