import React, {
  setGlobal,
  useGlobal,
  useState,
  useEffect,
  useCallback,
  useRef,
  useId,
} from "reactn";
import styled from "styled-components";
import { OptionsMenu } from "components";
import optionsMenuData from "./optionsMenuData";
import Main from "./Main";
import ViewToken from "./ViewToken";
import AllTokens from "./AllTokens";
import GhostTemple from "./GhostTemple";
import ghost from "assets/icons/ghost2.ico";
import tiles from "assets/ghost/tilebg.png";
import forward from "assets/icons/forward.ico";
import back from "assets/icons/back.ico";
import "./index.scss";

const GhostSoftware = ({
  id,
  onClose,
  standAlone,
  onHelp,
  onToggleComponent,
  injectedProps,
  windowView,
}) => {
  const appId = useId().replaceAll(":", "");
  const backPages = useRef([]);
  const nextPages = useRef([]);
  const collectionId = 0;
  const [contract] = useGlobal("contract");
  const [account] = useGlobal("account");
  const [web3] = useGlobal("web3");
  const [view, setView] = useState(standAlone ? windowView : "Main Hall");
  const [token, setToken] = useState(null);
  const [allTokens, setAllTokens] = useState(null);
  const [newToken, setNewToken] = useState(null);
  const [myTokens, setMyTokens] = useState(null);
  const [userTokens, setUserTokens] = useState(null);
  const [tokenOwner, setTokenOwner] = useState(null);
  const selection = window.getSelection().anchorNode;
  const baseURI =
    "https://heaven.mypinata.cloud/ipfs/QmPMTixiCh5f59i6cVEUUGM4qWJA5h4yZJukzZYGeVMj1S";

  useEffect(() => {
    return () => {
      backPages.current = [];
      nextPages.current = [];
    };
  }, []);

  useEffect(() => {
    const nextGhost = document.getElementById("nextGhost" + appId);
    const backGhost = document.getElementById("backGhost" + appId);
    if (nextPages.current.length > 0) {
      optionsMenuData["View"][3].disable = false;
      nextGhost.classList.remove("disabled");
    } else {
      optionsMenuData["View"][3].disable = true;
      nextGhost.classList.add("disabled");
    }
    if (backPages.current.length > 0) {
      optionsMenuData["View"][2].disable = false;
      backGhost.classList.remove("disabled");
    } else {
      optionsMenuData["View"][2].disable = true;
      backGhost.classList.add("disabled");
    }
    if ((nextPages.current.length || backPages.current.length) > 0) {
      optionsMenuData["Edit"][3].disable = false;
    } else {
      optionsMenuData["Edit"][3].disable = true;
    }
    if (
      view === "Main Hall" ||
      view === "View Token" ||
      view === "Minting" ||
      view === "Token Temple"
    ) {
      if (selection) {
        optionsMenuData["Edit"][0].disable = true;
        optionsMenuData["Edit"][1].disable = false;
      } else {
        optionsMenuData["Edit"][0].disable = false;
        optionsMenuData["Edit"][1].disable = true;
      }
    } else {
      optionsMenuData["Edit"][0].disable = true;
      optionsMenuData["Edit"][1].disable = true;
    }
  }, [view, newToken, selection, appId]);

  const onRefresh = () => {
    if (selection) onSelectNone();
    backPages.current = [];
    nextPages.current = [];
    setMyTokens(null);
    setNewToken(null);
    setToken(null);
    setView("Main Hall");
  };

  const getTokenOwner = useCallback(async (token) => {
      setTokenOwner(null);
      if (token) {
        let owner = await contract["ownerOf(uint256)"](token);
        const name = await web3.lookupAddress(owner);
        name ? setTokenOwner(name) : setTokenOwner(owner);
      }
    },
    [contract, web3]
  );

  const getAllCollectionTokens = useCallback(async () => {
    setAllTokens(null);
    let tokens = [];
    await contract["viewAllCollectionTokens(uint256)"](collectionId).then(
      (r) => {
        for (let i = 0; i < r.length; i++) {
          tokens.push(r[i].toString());
        }
      }
    );
    setAllTokens(tokens.sort());
    return tokens;
  }, [contract]);

  const getMyTokens = useCallback(async () => {
    setMyTokens(null);
    let tokenIds = [];
    let balance = await contract["balanceOf(address)"](account);
    for (let i = 0; i < parseInt(balance); i++) {
      await contract["tokenOfOwnerByIndex(address,uint256)"](account, i).then(
        (id) => {
          if (id > 7777) return;
          tokenIds.push(id.toString());
        }
      );
    }
    setMyTokens(tokenIds.sort());
  }, [contract, account]);

  const getUserTokens = useCallback(async (user) => {
      setUserTokens(null)
      let tokenIds = [];
      let balance = await contract["balanceOf(address)"](user).catch((e) => {
        setView("Error");
        return;
      });
      for (let i = 0; i < parseInt(balance); i++) {
        await contract["tokenOfOwnerByIndex(address,uint256)"](user, i).then(
          (id) => {
            if (id > 7777) return;
            tokenIds.push(id.toString());
          }
        );
      }
      setUserTokens(tokenIds.sort());
    },
    [contract]
  );

  useEffect(() => {
    if (contract) getAllCollectionTokens();
  }, [contract, getAllCollectionTokens]);

  useEffect(() => {
    if (contract && account) getMyTokens();
  }, [contract, account, getMyTokens, getAllCollectionTokens]);

  useEffect(() => {
    if (injectedProps && injectedProps === "documents") setView("My Tokens");
  }, [injectedProps]);

  const createViewList = useCallback(
    (curr, prev) => {
      if (curr === prev) return;
      if (selection) onSelectNone();
      backPages.current.push(prev);
      setView(curr);
    },
    [selection]
  );

  function onClickOptionItem(item) {
    switch (item.text) {
      case "Main Hall":
        if (standAlone) window.history.pushState("", "", path(item.text));
        createViewList("Main Hall", view);
        break;
      case "Ghost Temple":
        setGlobal({ appLoading: { name: "Ghost Software", id: id } });
        if (allTokens) {
          let token = allTokens[Math.floor(Math.random() * allTokens.length)];
          handleViewGhost(token);
        } else {
          getAllCollectionTokens().then((tokens) => {
            let token = tokens[Math.floor(Math.random() * tokens.length)];
            handleViewGhost(token);
          });
        }
        break;
      case "Token Temple":
        if (allTokens) {
          setGlobal({ appLoading: { name: "Ghost Software", id: id } });
          let token = allTokens[Math.floor(Math.random() * allTokens.length)];
          setGlobal({ appLoading: null });
          handleViewToken(token);
        } else {
          setGlobal({ appLoading: { name: "Ghost Software", id: id } });
          getAllCollectionTokens().then((tokens) => {
            let token = tokens[Math.floor(Math.random() * tokens.length)];
            setGlobal({ appLoading: null });
            handleViewToken(token);
          });
        }
        break;
      case "All Tokens":
        if (standAlone) window.history.pushState("", "", path(item.text));
        createViewList("All Tokens", view);
        break;
      case "My Tokens":
        if (standAlone) return;
        createViewList("My Tokens", view);
        break;
      case "Back":
        handleNavigation("back");
        break;
      case "Forward":
        handleNavigation("forward");
        break;
      case "Refresh":
        onRefresh();
        break;
      case "Select All":
        onSelectAll();
        break;
      case "Select None":
        onSelectNone();
        break;
      case "Close":
        onClose();
        break;
      case "About...":
        standAlone ? onHelp("GS") : onToggleComponent("Help", "open", id, "GS");
        break;
      case "Help Center":
        standAlone ? onHelp("Home") : onToggleComponent("Help", "open", id);
        break;
      default:
    }
  }
  function onSelectNone() {
    window.getSelection().removeAllRanges();
  }
  function onSelectAll() {
    const el = document.getElementById("gs_inner");
    if (document.body.createTextRange) {
      let range = document.body.createTextRange();
      range.moveToElementText(el);
      range.select();
    } else if (window.getSelection) {
      let selection = window.getSelection();
      let range = document.createRange();
      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
  function handleViewToken(token) {
    if (standAlone) window.history.pushState("", "", "/token/" + token);
    setToken(token);
    getTokenOwner(token);
    createViewList("View Token", view);
  }
  function handleViewUser(user) {
    if (standAlone) window.history.pushState("", "", "/user/" + user);
    getUserTokens(user);
    createViewList("User Tokens", view);
  }
  function handleViewGhost(token) {
    if (standAlone) window.history.pushState("", "", "/ghost/" + token);
    setToken(token);
    createViewList("Ghost Temple", view);
  }
  function handleNavigation(flow) {
    if (flow === "back") {
      if (backPages.current.length > 0) {
        nextPages.current.unshift(view);
        if (standAlone)
          window.history.pushState(
            "",
            "",
            path(backPages.current[backPages.current.length - 1])
          );
        setView(backPages.current[backPages.current.length - 1]);
        backPages.current[0] === backPages.current[backPages.current.length - 1]
          ? (backPages.current = [])
          : backPages.current.pop();
      } else {
        return;
      }
    } else if (flow === "forward") {
      if (nextPages.current.length > 0) {
        backPages.current.push(view);
        if (standAlone)
          window.history.pushState("", "", path(nextPages.current[0]));
        setView(nextPages.current[0]);
        nextPages.current[0] === nextPages.current[nextPages.current.length - 1]
          ? (nextPages.current = [])
          : nextPages.current.shift();
      } else {
        return;
      }
    }
  }

  const path = (view) => {
    if (view === "Main Hall") {
      return "/";
    } else if (view === "View Token" && token) {
      return "/token/" + token.toString();
    } else if (view === "Ghost Temple" && token) {
      return "/ghost/" + token.toString();
    } else if (view === "User Tokens" && tokenOwner) {
      return "/user/" + tokenOwner.toString();
    } else {
      return view ? "/" + view.replace(/\s+/g, "").toLowerCase() : "/";
    }
  };

  useEffect(() => {
    if (standAlone) {
      optionsMenuData["Temple"][1].disable = true;
    }
    if (standAlone && windowView) {
      const query = windowView.substring(windowView.indexOf("?") + 1).trim();
      if (windowView === "All Tokens" || windowView === "Main Hall") {
        setView(windowView);
      } else if (windowView.startsWith("token?") || windowView.startsWith("ghost?")) {
        if (allTokens && (isNaN(query) || query < 0 || query > 7776)) {
          setView("Error");
          return;
        }
        getTokenOwner(query);
        setToken(query);
        windowView.startsWith("token?") ? setView("View Token") : setView("Ghost Temple");
      } else if (windowView.startsWith("user?")) {
        getUserTokens(query);
        setTokenOwner(query);
        setView("User Tokens");
      } else {
        setView("Error");
      }
    }
  }, [standAlone, windowView, allTokens, getUserTokens, getTokenOwner]);

  return (
    <Div>
      <section className="window_toolbar">
        <OptionsMenu items={optionsMenuData} onClickItem={onClickOptionItem} />
      </section>
      <div className="divider"></div>
      <section className="address_bar">
        <div className="div_bar"></div>
        <div className="address_bar_title">
          A<u>d</u>dress
        </div>
        <div className="address_bar_content">
          <div className="address_bar_content_text">
            <img
              draggable={false}
              src={ghost}
              alt="favicon"
              className="address_bar_content_img"
            />
            <span>https://ghost.software{path(view)}</span>
            <div className="address_bar_buttons">
              <div className="address_bar_go">
                <div />
              </div>
              <div
                id={"backGhost" + appId}
                title="back"
                className="address_bar_back"
                onClick={() => handleNavigation("back")}
              >
                <img draggable={false} alt="back" src={back} />
              </div>
              <div
                id={"nextGhost" + appId}
                title="forward"
                className="address_bar_forward"
                onClick={() => handleNavigation("forward")}
              >
                <img draggable={false} alt="forward" src={forward} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="window_content">
        <div id="gs_inner" className="window_content_inner">
          {view === "Error" && (<p style={{padding: "10px"}}>Not found.</p>)}
          {view === "Main Hall" && (
            <Main
              id={id}
              onToggleComponent={onToggleComponent}
              setTokenOwner={setTokenOwner}
              standAlone={standAlone}
              createViewList={createViewList}
            />
          )}
          {view === "Ghost Temple" && <GhostTemple id={id} token={token} />}
          {view === "Token Temple" && (
            <ViewToken
              id={id}
              token={token}
              allTokens={allTokens}
              baseURI={baseURI}
              handleViewToken={handleViewToken}
              handleViewUser={handleViewUser}
              handleViewGhost={handleViewGhost}
              tokenOwner={tokenOwner}
            />
          )}
          {view === "All Tokens" && (
            <AllTokens
              type={"all"}
              id={id}
              allTokens={allTokens}
              handleViewToken={handleViewToken}
              baseURI={baseURI}
            />
          )}
          {view === "My Tokens" && (
            <AllTokens
              type={"mine"}
              id={id}
              allTokens={myTokens}
              handleViewToken={handleViewToken}
              baseURI={baseURI}
            />
          )}
          {view === "User Tokens" && (
            <AllTokens
              type={"user"}
              id={id}
              allTokens={userTokens}
              handleViewToken={handleViewToken}
              baseURI={baseURI}
            />
          )}
          {view === "View Token" && (
            <ViewToken
              id={id}
              token={token}
              allTokens={allTokens}
              baseURI={baseURI}
              handleViewToken={handleViewToken}
              handleViewUser={handleViewUser}
              handleViewGhost={handleViewGhost}
              tokenOwner={tokenOwner}
            />
          )}
        </div>
      </section>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  background: var(--colorgrad3);
  p,
  span,
  a,
  td,
  i,
  u,
  b,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5 {
    user-select: text !important;
  }
  span {
    display: block;
  }
  .window_content_inner {
    background-image: url(${tiles});
    background-repeat: repeat;
    height: calc(100% - 4px);
  }
`;

export default GhostSoftware;
