import React, { createContext, useState } from "react";

export const BackgroundContext = createContext();

export const BackgroundProvider = (props) => {
  const [randomizeBg, setRandomizeBg] = useState(false);
  const [lutImage, setLutImage] = useState(false);
  return (
    <BackgroundContext.Provider
      value={[randomizeBg, setRandomizeBg, lutImage, setLutImage]}
    >
      {props.children}
    </BackgroundContext.Provider>
  );
};
