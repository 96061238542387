

/* eslint-disable react/no-unused-prop-types */

import React from "react";

const Bool = (props: {
  name: string,
  value: boolean,
  onSetFilterOption: (string, boolean) => {},
  label: string,
}) => (

  <div className="filter_menu boolcheck">
    <input
      id={`bool${props.name}`}
      type="checkbox"
      checked={props.value}
      onChange={e => props.onSetFilterOption(props.name, e.target.checked)}
    />
    <label className="boollabel" htmlFor={`bool${props.name}`}>{props.label}</label>
  </div>
);

export default Bool;
