export const closestNote = (note, scale) => scale.reduce((prev, curr) => Math.abs(curr - note) < Math.abs(prev - note) ? curr : prev);

export const majorScale = (n0, oct) => [
  n0 + 12 * oct,
  n0 + 2 + 12 * oct,
  n0 + 4 + 12 * oct,
  n0 + 5 + 12 * oct,
  n0 + 7 + 12 * oct,
  n0 + 9 + 12 * oct,
  n0 + 11 + 12 * oct,
];
export const minorScale = (n0, oct) => [
  n0 + 12 * oct,
  n0 + 2 + 12 * oct,
  n0 + 3 + 12 * oct,
  n0 + 5 + 12 * oct,
  n0 + 7 + 12 * oct,
  n0 + 8 + 12 * oct,
  n0 + 10 + 12 * oct,
];
export const minorScale2 = (n0, oct) => [
  n0 + 12 * oct,
  n0 + 2 + 12 * oct,
  n0 + 3 + 12 * oct,
  n0 + 5 + 12 * oct,
  n0 + 7 + 12 * oct,
  n0 + 8 + 12 * oct,
  n0 + 10 + 12 * oct,
  n0 + 12 + 12 * oct,
  n0 + 14 + 12 * oct,
  n0 + 15 + 12 * oct,
  n0 + 17 + 12 * oct,
  n0 + 19 + 12 * oct,
  n0 + 20 + 12 * oct,
  n0 + 22 + 12 * oct,
];
export const majorScale2 = (n0, oct) => [
  n0 + 12 * oct,
  n0 + 2 + 12 * oct,
  n0 + 4 + 12 * oct,
  n0 + 5 + 12 * oct,
  n0 + 7 + 12 * oct,
  n0 + 9 + 12 * oct,
  n0 + 11 + 12 * oct,
  n0 + 12 + 12 * oct,
  n0 + 14 + 12 * oct,
  n0 + 17 + 12 * oct,
  n0 + 19 + 12 * oct,
  n0 + 21 + 12 * oct,
  n0 + 23 + 12 * oct,
];
