import React, { useState } from "react";
import styled from "styled-components";

const StartSubMenu = ({ className, data, style, onClick }) => {
  const [hoverIndex, setHoverIndex] = useState(-1);
  return (
    <div style={{ ...style }} className={className}>
      {data.map((item, index) => (
        <StartSubMenuItem
          onClick={onClick}
          onHover={setHoverIndex}
          key={index}
          hover={hoverIndex === index}
          item={item}
          index={index}
          className={className}
        />
      ))}
    </div>
  );
};

const StartSubMenuItem = ({
  index,
  item,
  className,
  hover,
  onHover,
  onClick,
}) => {
  function _onPointerOver() {
    onHover(index);
  }
  function _onClick() {
    onClick(item);
  }
  switch (item.type) {
    case "item":
      return (
        <div
          onClick={_onClick}
          onPointerEnter={_onPointerOver}
          className={`${className}-item`}
        >
          <img
            draggable={false}
            className={`${className}-img`}
            src={item.icon}
            alt="favicon"
          />
          <div className={`${className}-text`}>
            {item.title}
          </div>
        </div>
      );
    case "menu":
      return (
        <div
          onPointerEnter={_onPointerOver}
          className={`${className}-item ${hover ? "hover" : ""}`}
        >
          <img
            draggable={false}
            className={`${className}-img`}
            src={item.icon}
            alt="favicon"
          />
          <div className={`${className}-text`}>{item.text}</div>
          <div className={`${className}-arrow`}>
            {hover && (
              <StyledStartSubMenu
                data={item.items}
                bottom={item.bottom}
                onClick={onClick}
              />
            )}
          </div>
        </div>
      );
    default:
      return null;
  }
};

const StyledStartSubMenu = styled(StartSubMenu)`
  position: absolute;
  z-index: 1;
  margin-left: 8px;
  max-width: calc(100vw/3 - 2px);
  bottom: ${({ bottom }) => bottom || "-1px"};
  background: var(--colorgrad3);
  box-shadow: inset -1px -1px 0px var(--colorgrad8),
    inset 1px 1px 0px var(--colorgrad4), inset -2px -2px 0px var(--colorgrad6),
    inset 2px 2px 0px var(--colorgrad0);
  &-item {
    margin: 3px;
    height: 22px;
    display: flex;
    align-items: center;
    padding: 0 7px;
    position: relative;
    color: var(--black);
  }
  &-item:hover {
    background-color: #266ebb;
    color: white;
  }
  &-img {
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }
  &-text {
    font-size: 11px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-arrow {
    margin-left: auto;
    display: flex;
    height: 100%;
    &:before {
      align-self: center;
      content: "";
      border-width: 4px;
      border-style: solid;
      border-right: 0;
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }
`;

export default StyledStartSubMenu;
