import React from "react";
import styled from "styled-components";
import warning from "assets/icons/warning.ico";

const Warning = ({ onClose, onToggleComponent, injectedProps = "Coming soon.", id }) => {
  // useEffect(() => {
  //   document.getElementById(injectedProps.substring(0, 5) + id).innerHTML = '<p>' + injectedProps + '</p>';
  // }, [injectedProps, id]);
  return (
    <Div>
      <div className="warning_top">
        <img draggable={false} src={warning} alt="warning" className="warning_img" />
        <div className="warning_messages">Coming soon.</div>
      </div>
      <div className="warning_bottom">
        <button onClick={onClose} className="button">
          OK
        </button>
        <button onClick={() => onToggleComponent("Warning", "open", id)} className="button">
            Wen?
        </button>
      </div>
    </Div>
  );
};

const Div = styled.div`
  background: var(--colorgrad3);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  z-index: 100;
  i {
    font-style: italic;
  }
  .warning_top {
    display: flex;
    width: 100%;
  }
  .warning_img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  .warning_messages {
    position: absolute;
    left: 20px;
    display: flex;
    width: calc(100% - 20px);
    align-self: center;
    flex-direction: column;
    line-height: 15px;
    text-align: center;
  }
  .warning_bottom {
    margin-top: 10px;
    display: flex;
    align-self: center;
    justify-content: space-evenly;
    button + button {
      margin-left: 10px;
    }
  }
  .warning_button:active {
    outline: 1px dotted #000000;
    outline-offset: -5px;
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
`;

export default Warning;
