//import { closestNote } from "../../synths/scales";
//import { map } from "utils";

export const frequencyBins = [
  16,
  20,
  25,
  31.5,
  40,
  50,
  63,
  80,
  100,
  125,
  160,
  200,
  250,
  315,
  400,
  500,
  630,
  800,
  1000,
  1250,
  1600,
  2000,
  2500,
  3150,
  4000,
  5000,
  6300,
  8000,
  10000,
  12500,
  16000,
  20000,
  22050,
];

function getDerivedStateFromProps(Q, f, type, range) {
  let newData = frequencyBins.slice();
  newData.fill(0);
  let filterPattern = [0, 0, 0];
  let min = -range / 2;

  switch (type) {
    case "allpass":
      filterPattern = [Q, Q, Q];
      break;
    case "lowpass":
      filterPattern = [0, Q, min];
      break;
    case "highpass":
      filterPattern = [min, Q, 0];
      break;
    case "bandpass":
      filterPattern = [0, Q, 0];
      break;
    case "notch":
      filterPattern = [0, -Q, 0];
      break;
    case "lowshelf":
      filterPattern = [Q, 0, min];
      break;
    case "highshelf":
      filterPattern = [min, 0, Q];
      break;
    default:
      filterPattern = [0, 0, 0];
      break;
  }

  for (let i = 0; i < newData.length; i++) {
    if (frequencyBins[i] === f) {
      newData[i] = filterPattern[1];
    } else if (frequencyBins[i + 1] === f) {
      newData[i] =
        filterPattern[0] + Math.abs(filterPattern[1] - filterPattern[0]) / 2;
    } else if (frequencyBins[i - 1] === f) {
      newData[i] =
        filterPattern[1] - Math.abs(filterPattern[2] - filterPattern[1]) / 2;
    } else if (frequencyBins[i] < f) {
      newData[i] = filterPattern[0];
    } else if (frequencyBins[i] > f) {
      newData[i] = filterPattern[2];
    }
  }
  return newData;
}

export const drawFilter = (ctx, canvas, radius, coord, f, Q, type) => {
  let yRange;
  if (type === "notch" || type === "bandpass") {
    yRange = 6;
  } else if (type === "highshelf" || type === "lowshelf") {
    yRange = 10.25;
  } else {
    yRange = 4.6;
  }
  const filterData = getDerivedStateFromProps(Q, f, type, yRange);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  let midY = canvas.height / 2;
  let yStepSize = canvas.height / yRange;
  let xStepSize = (canvas.width + 4) / filterData.length;
  let graphPoints;
  if (type === "notch") {
    graphPoints = [
      0,
      frequencyBins.indexOf(f) + 4,
      frequencyBins.indexOf(f) - 3,
      frequencyBins.indexOf(f) + 2,
      frequencyBins.indexOf(f) + 3,
      frequencyBins.length - 1,
    ];
  } else if (type === "bandpass") {
    graphPoints = [
      0,
      frequencyBins.indexOf(f) + 4,
      frequencyBins.indexOf(f) - 3,
      frequencyBins.indexOf(f) + 2,
      frequencyBins.indexOf(f) + 3,
      frequencyBins.length - 1,
    ];
  // }if (type === "notch") {
  //   graphPoints = [
  //     0,
  //     frequencyBins.indexOf(f) - 3,
  //     frequencyBins.indexOf(f) - 4,
  //     frequencyBins.indexOf(f) + 2,
  //     frequencyBins.indexOf(f) + 4,
  //     frequencyBins.length - 1,
  //   ];
  } else if (type === "lowpass") {
    graphPoints = [
      0,
      frequencyBins.indexOf(f) - 4,
      frequencyBins.indexOf(f) + 2,
      frequencyBins.indexOf(f) + 4,
      frequencyBins.length - 1,
    ];
  } else if (type === "highpass") {
    graphPoints = [
      0,
      frequencyBins.indexOf(f) - 5,
      frequencyBins.indexOf(f) + 2,
      frequencyBins.indexOf(f) + 3,
      frequencyBins.indexOf(f) + 4,
      frequencyBins.length - 1,
    ];
  } else if (type === "highshelf") {
    graphPoints = [
      0,
      frequencyBins.indexOf(f) - 2,
      frequencyBins.indexOf(f),
      frequencyBins.indexOf(f) + 2,
      frequencyBins.indexOf(f) + 4,
      frequencyBins.length - 1,
    ];
  } else {
    graphPoints = [
      0,
      frequencyBins.indexOf(f) - 4,
      frequencyBins.indexOf(f),
      frequencyBins.indexOf(f) + 2,
      frequencyBins.length - 1,
    ];
  }
  ctx.beginPath();
  ctx.fillRect(coord.x, coord.y, radius, radius);
  ctx.closePath();

  ctx.beginPath();
  ctx.moveTo(0.5, midY - filterData[0] * yStepSize - 0.5);

  for (let i = 1; i < filterData.length; i++) {
    if (!graphPoints.includes(i)) continue;
    if (type === "lowshelf" || type === "highshelf") {
      if (frequencyBins[i] === f) continue;
    }
    let last_idx = graphPoints.includes(i - 1) ? i - 1 : i - 2;
    let x = i * xStepSize;
    let y = midY - filterData[i] * yStepSize;
    let cpxOffset = xStepSize;
    let cp1x = last_idx * xStepSize + cpxOffset;
    let cp1y = midY - filterData[last_idx] * yStepSize;
    let cp2x = i * xStepSize - cpxOffset;
    let cp2y = midY - filterData[i] * yStepSize;
    ctx.bezierCurveTo(
      cp1x - 2.5,
      cp1y - 0.5,
      cp2x + 0.5,
      cp2y - 0.5,
      x + 0.5,
      y - 0.5
    );
  }
  ctx.stroke();
};
