import React from "react";
import styled from "styled-components";

const SkyLast = () => {
  return (
    <Div className="skysection10 content animate selectable">
      <div className="neonSign">
        <div className="neonCircle animate"></div>
        <div className="neonScript">
          <div className="skySign pinkNeon neonGlow">Sky</div>
          <div className="luxurySign blueNeon neonGlow">LUXURY</div>
        </div>
      </div>
      <div className='wavy animate'>
        <div className='moving-text'>
          <p className="char">M</p>
          <p className="char">O</p>
          <p className="char">V</p>
          <p className="char">E</p>
          <p className="char">•</p>
          <p className="char">I</p>
          <p className="char">N</p>
          <p className="char">•</p>
          <p className="char">T</p>
          <p className="char">O</p>
          <p className="char">D</p>
          <p className="char">A</p>
          <p className="char">Y</p>
          <p className="char">•</p>
          <p className="char">M</p>
          <p className="char">O</p>
          <p className="char">V</p>
          <p className="char">E</p>
          <p className="char">•</p>
          <p className="char">I</p>
          <p className="char">N</p>
          <p className="char">•</p>
          <p className="char">T</p>
          <p className="char">O</p>
          <p className="char">D</p>
          <p className="char">A</p>
          <p className="char">Y</p>
          <p className="char">•</p>
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
width: 100%;
height: 100%;
.neonSign {
  --baseFont: calc(var(--fontMultiplier)*var(--font)*0.8) !important;
  display: flex;
  opacity: 0.9;
  .neonGlow {
    --neonGlow:
      var(--neonShadow),
      .1vw 0vw .25vw var(--color0), .2vw 0vw .25vw var(--color0), .4vw 0vw .25vw var(--color0),
      .1vw 0vw .1vw var(--color1), .2vw 0vw .1vw var(--color1), .4vw 0vw 0vw var(--color1),
      .1vw 0vw 1vw var(--color1), .2vw 0vw 2vw var(--color1), .4vw 0vw 2vw var(--color1), .2vw 0vw 5vw var(--color1),
      .1vw 0vw .5vw var(--color2), .2vw 0vw .5vw var(--color2), .4vw 0vw 6vw var(--color2),
      .1vw 0vw 10vw var(--color2), .4vw 0vw 10vw var(--color2);
  }
  .neonCircle {
    height: calc(var(--fontMultiplier)*var(--font)*3.5*0.8) !important;
  }
}

@container skytop style(--sectionIndex: var(--sections)) {
  .neonSign {
    --flickerFunc: linear(0 0% 0.5%, 0.1723 0.7925%,
    0.2408 0.865%, 0.1991 1.6425%,
    0 7.69% 13.69%, 0.3451 3.795%,
    0.0006 3.94%, 0.5332 4.37%,
    0.0001 4.895%, 0.595 5.175%,
    0.0001 6.29%, 0.6263 6.755%,
    0.0004 7.7775%, 0.4634 7.8725%,
    0.4869 8.075%, 0.6052 8.455%,
    0.6504 8.9525%, 0.0011 9.0925%,
    0.6832 10.3875%, 0.2716 11.5425%,
    0.5783 11.6675%, 0.5956 13.0475%,
    0.1565 13.6625%, 0.869 15.095%,
    0.4566 15.745%, 0.3918 15.975%,
    0.4768 16.1675%, 0.3041 17.29%,
    0.5036 17.295%, 0.9561 17.96%,
    0.4202 18.1475%, 0.9997 18.59%,
    0.9389 18.9675%, 0.5199 19.5325%,
    0.9878 19.6325%, 0.5212 19.8075%,
    0.8476 21.1625%, 0.5724 21.615%,
    0.7517 21.9%, 0.5716 22.4%,
    0.8177 22.8325%, 0.9997 23.865%, 1 25%, 1 100%);

    .luxurySign {
      animation: flickerOn 4s infinite var(--flickerFunc) alternate !important;
      animation-iteration-count: 1 !important;
    }

    .skySign {
      animation: flickerOn2 1s infinite var(--flickerFunc) alternate !important;
      animation-delay: 0.5s;
      animation-iteration-count: 1 !important;
    }
  }
}

.wavy {
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 75px;
  bottom: 1vmin;
  z-index: 2;
}

.moving-text  {
  width: 100%;
  height: 75px;
  mask: linear-gradient(
    to right,
    transparent,
    #000 75px,
    #000 calc(100% - 75px),
    transparent
  );
}

.char {
  offset-path: path("M-64.5,28c44.4,0,44.4,26,88.9,26c44.4,0,44.4-26,88.9-26c44.4,0,44.4,26,88.9,26c44.4,0,44.4-26,88.9-26c44.4,0,44.4,26,88.9,26c44.4,0,44.4-26,88.9-26c44.4,0,44.4,26,88.9,26c44.4,0,44.4-26,88.9-26");
  --interval: -1s;
	--totalchars: 28;
  animation: offsetText calc(var(--interval) * var(--totalchars) * -1) linear infinite, changeTextColor calc(var(--interval) * var(--totalchars) * -1) ease-in-out infinite alternate;
}

.char:nth-child(1) {
	animation-delay: calc(0 * var(--interval));
}

.char:nth-child(2) {
	animation-delay: calc(1 * var(--interval));
}

.char:nth-child(3) {
	animation-delay: calc(2 * var(--interval));
}

.char:nth-child(4) {
	animation-delay: calc(3 * var(--interval));
}

.char:nth-child(5) {
	animation-delay: calc(4 * var(--interval));
}

.char:nth-child(6) {
	animation-delay: calc(5 * var(--interval));
}

.char:nth-child(7) {
	animation-delay: calc(6 * var(--interval));
}

.char:nth-child(8) {
	animation-delay: calc(7 * var(--interval));
}

.char:nth-child(9) {
	animation-delay: calc(8 * var(--interval));
}

.char:nth-child(10) {
	animation-delay: calc(9 * var(--interval));
}

.char:nth-child(11) {
	animation-delay: calc(10 * var(--interval));
}

.char:nth-child(12) {
	animation-delay: calc(11 * var(--interval));
}

.char:nth-child(13) {
	animation-delay: calc(12 * var(--interval));
}

.char:nth-child(14) {
	animation-delay: calc(13 * var(--interval));
}

.char:nth-child(15) {
	animation-delay: calc(14 * var(--interval));
}

.char:nth-child(16) {
	animation-delay: calc(15 * var(--interval));
}

.char:nth-child(17) {
	animation-delay: calc(16 * var(--interval));
}

.char:nth-child(18) {
	animation-delay: calc(17 * var(--interval));
}

.char:nth-child(19) {
	animation-delay: calc(18 * var(--interval));
}

.char:nth-child(20) {
	animation-delay: calc(19 * var(--interval));
}

.char:nth-child(21) {
	animation-delay: calc(20 * var(--interval));
}

.char:nth-child(22) {
	animation-delay: calc(21 * var(--interval));
}

.char:nth-child(23) {
	animation-delay: calc(22 * var(--interval));
}

.char:nth-child(24) {
	animation-delay: calc(23 * var(--interval));
}

.char:nth-child(25) {
	animation-delay: calc(24 * var(--interval));
}

.char:nth-child(26) {
	animation-delay: calc(25 * var(--interval));
}

.char:nth-child(27) {
	animation-delay: calc(26 * var(--interval));
}

.char:nth-child(28) {
	animation-delay: calc(27 * var(--interval));
}
`;

export default SkyLast;