const View = [
  {
    type: "item",
    text: "Intro",
    disable: false,
  },
  {
    type: "separator",
  },
  {
    type: "item",
    text: "Back",
    disable: true,
  },
  {
    type: "item",
    text: "Forward",
    disable: true,
  },
];
const Observatory = [
  {
    type: "menu",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Milky Way",
    items: [
      {
        type: "item",
        text: "Milky Way",
        check: true,
      },
      {
        type: "item",
        text: "Outline",
        check: false,
      },
      {
        type: "toggle",
        position: {
          left: "calc(100% - 4px)",
          top: "-20px",
        },
        text: "Style",
        items: [
          {
            type: "option",
            text: "Azure Bliss",
            select: true,
            code: "MilkyWay",
          },
          {
            type: "option",
            text: "Dusty Dusk",
            select: false,
            code: "MilkyWay",
          },
          {
            type: "option",
            text: "Purple Haze",
            select: false,
            code: "MilkyWay",
          },
          {
            type: "option",
            text: "Wine Smoke",
            select: false,
            code: "MilkyWay",
          },
          {
            type: "option",
            text: "Violet Dawn",
            select: false,
            code: "MilkyWay",
          },
          {
            type: "option",
            text: "Blue Bubbles",
            select: false,
            code: "MilkyWay",
          },
          {
            type: "option",
            text: "Night Galatea",
            select: false,
            code: "MilkyWay",
          },
          {
            type: "option",
            text: "Pale Magenta",
            select: false,
            code: "MilkyWay",
          },
        ],
      },
    ],
  },
  {
    type: "menu",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Solar System",
    items: [
      {
        type: "item",
        text: "Solar System",
        check: true,
      },
      {
        type: "item",
        text: "Labels",
        check: true,
        code: "SolarSystem",
      },
      {
        type: "item",
        text: "Orbits",
        check: false,
      },
    ],
  },
  {
    type: "menu",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Constellations",
    items: [
      {
        type: "item",
        text: "Constellations",
        check: false,
      },
      {
        type: "item",
        text: "Labels",
        check: false,
        code: "Constellations",
      },
      {
        type: "item",
        text: "Boundaries",
        check: false,
      },
    ],
  },
  {
    type: "menu",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Stars",
    items: [
      {
        type: "item",
        text: "Labels",
        check: false,
        code: "Stars",
      },
      {
        type: "item",
        text: "Static",
        check: true,
      },
      {
        type: "item",
        text: "Shooting",
        check: true,
      },
      {
        type: "item",
        text: "Shining",
        check: true,
      },
      {
        type: "item",
        text: "Wandering",
        check: true,
      },
    ],
  },
  // {
  //   type: 'menu',
  //   position: {
  //     left: 'calc(100% - 4px)',
  //     top: '-20px',
  //   },
  //   text: 'Coordinates',
  //   items: [
  //     {
  //       type: 'item',
  //       text: 'Fixed Ratio',
  //       check: false,
  //     },
  //     {
  //       type: 'item',
  //       text: 'Initial Size',
  //       check: false,
  //     },
  //     {
  //       type: 'item',
  //       text: 'Repeat',
  //       check: true,
  //     }
  //   ],
  // },
  {
    type: "menu",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Gridlines",
    items: [
      {
        type: "item",
        text: "Azimuthal",
        check: false,
      },
      {
        type: "item",
        text: "Equatorial",
        check: false,
      },
      {
        type: "item",
        text: "Galactic",
        check: false,
      },
      {
        type: "separator",
      },
      {
        type: "item",
        text: "Ecliptic",
        check: false,
      },
      {
        type: "item",
        text: "Meridian",
        check: false,
      },
    ],
  },
  {
    type: "toggle",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Projection",
    items: [
      {
        type: "option",
        text: "Gnomic",
        select: true,
        code: "Projection",
      },
      {
        type: "option",
        text: "Polar",
        select: false,
        code: "Projection",
      },
      {
        type: "option",
        text: "Lambert",
        select: false,
        code: "Projection",
      },
      {
        type: "option",
        text: "Stereographic",
        select: false,
        code: "Projection",
      },
      {
        type: "option",
        text: "Orthographic",
        select: false,
        code: "Projection",
      },
      {
        type: "option",
        text: "Equirectangular",
        select: false,
        code: "Projection",
      },
      {
        type: "option",
        text: "Mollweide",
        select: false,
        code: "Projection",
      },
      {
        type: "option",
        text: "Planechart",
        select: false,
        code: "Projection",
      },
      {
        type: "option",
        text: "Fisheye",
        select: false,
        code: "Projection",
      },
    ],
  },
  {
    type: "menu",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Map",
    items: [
      {
        type: "item",
        text: "Ground",
        check: false,
      },
      {
        type: "item",
        text: "Atmosphere",
        check: false,
      },
      {
        type: "item",
        text: "Cardinal Points",
        check: true,
      },
    ],
  },
  {
    type: "separator",
  },
  {
    type: "item",
    text: "Invert",
    check: false,
    disable: true,
  },
  {
    type: "item",
    text: "Fullscreen",
    check: false,
    disable: true,
  },
  {
    type: "separator",
  },
  {
    type: "menu",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Date",
    items: [
      {
        type: "item",
        text: "Date",
        check: false,
      },
      {
        type: "item",
        text: "Realtime",
        check: true,
      },
      {
        type: "item",
        text: "Select...",
        code: "Calendar",
      },
      {
        type: "menu",
        position: {
          left: "calc(100% - 4px)",
          top: "-20px",
        },
        text: "Time",
        code: "NoLive",
        items: [
          {
            type: "item",
            text: "-1 Week",
            code: "Calendar",
          },
          {
            type: "item",
            text: "-1 Day",
            code: "Calendar",
          },
          {
            type: "item",
            text: "Now",
            code: "Calendar",
          },
          {
            type: "item",
            text: "+1 Day",
            code: "Calendar",
          },
          {
            type: "item",
            text: "+1 Week",
            code: "Calendar",
          },
        ],
      },
      {
        type: "menu",
        position: {
          left: "calc(100% - 4px)",
          top: "-20px",
        },
        text: "Speed",
        code: "NoLive",
        items: [
          {
            type: "item",
            text: "Slow Down",
            code: "Speed",
          },
          {
            type: "item",
            text: "Normal",
            code: "Speed",
          },
          {
            type: "item",
            text: "Speed Up",
            code: "Speed",
          },
        ],
      },
    ],
  },
  {
    type: "menu",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Location",
    items: [
      {
        type: "item",
        text: "Location",
        check: false,
      },
      {
        type: "item",
        text: "Select...",
        code: "Location",
      },
    ],
  },
  {
    type: "menu",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Sounds",
    items: [
      {
        type: "item",
        text: "Effects",
        check: true,
      },
      {
        type: "item",
        text: "Music",
        check: true,
      },
    ],
  },
];
const Catalogue = [
  {
    type: "option",
    text: "All Tokens",
    disable: false,
    select: false,
    code: "Catalogue",
  },
  {
    type: "option",
    text: "All Objects",
    disable: false,
    select: true,
    code: "Catalogue",
  },
  {
    type: "option",
    text: "My Tokens",
    disable: false,
    select: false,
    code: "Catalogue",
  },
  {
    type: "separator",
  },
  {
    type: "toggle",
    position: {
      left: "calc(100% - 4px)",
      top: "-20px",
    },
    text: "Objects",
    items: [
      {
        type: "option",
        text: "Andromeda Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Ant Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Antennae Galaxies",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Atoms for Peace Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Backward Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Barnard's Loop",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Bat Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Bean Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Blinking Planetary",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Blue Planetary",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Blue Snowball Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Bode's Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Boomerang Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Bow-Tie Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Bubble Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Butterfly Galaxies",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Butterfly Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Calabash Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "California Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Carina Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cartwheel Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cassiopeia A",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cat's Eye Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cat's Eye Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cat's Paw Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Centaurus A",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Christmas Tree Cluster",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cigar Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Circinus Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cone Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Corona Australis Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cosmic Bat Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cosmic Reef",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cosmic Seashell",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Crab Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Crescent Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cygnus A",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Cygnus Loop",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Dandelion Puffball Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Dark Horse Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Defiant Finger",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Dolphin Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Dragon's Head Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Dragonfish Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Dumbbell Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Eagle Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Egg Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Eight-Burst Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Elephant's Trunk Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Engraved Hourglass Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Eskimo Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Evil Eye Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Eye Of Sauron Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Fighting Dragons Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Fine Ring Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Fireworks Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Fishhead Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Flame Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Flaming Star Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Fleming 1",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Fornax A",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Fox Fur Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Frosty Leo Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Gabriela Mistral Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Ghost Head Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Ghost Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Ghost Of Jupiter Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Ghost of Cassiopeia Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Godzilla Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Gum Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Helix Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Hercules A",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Hidden Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Hockey Stick Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Homunculus Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Horsehead Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Hubble's Variable Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "I Zwicky 18",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Iras Ghost Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Iris Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Jack-o-Lantern Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Jellyfish Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Jewel Bug Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Kepler's Supernova",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Keyhole Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Kissing Crescents",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "LL Pegasi",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Lagoon Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Large Magellanic Cloud",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Lemon Slice Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Leo Triplet",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Lindsay-Shapley Ring",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Little Gem Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Little Ghost Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Manatee Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Medusa Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Monkey Head Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Needle Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "North America Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Orion Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Oyster Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Pacman Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Pelican Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Pencil Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Perseus A",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Phantom Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Phantom Streak Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Pinwheel Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Red Rectangle Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Red Spider Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Retina Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Ring Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Rosette Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Running Chicken Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Running Man Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Sadr Region",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Saturn Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Seagull Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Seashell Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Silver Sliver Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Skull Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Skull and Crossbones Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Small Magellanic Cloud",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Snow Angel Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Soap Bubble Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Soccer Ball Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Sombrero Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Soul Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Southern Owl Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Southern Pinwheel Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Spaghetti Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Spider Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Spindle Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Spiral Planetary Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Squid Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Statue Of Liberty Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Sunflower Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Swan Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Tarantula Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "The Seven Sisters Cluster",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Thor's Helmet",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Triangulum Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Trifid Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Turtle Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Twin Jet Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Tycho's Supernova",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "War and Peace Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Westerlund 2",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Whale Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Whirlpool Galaxy",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Witch Head Nebula",
        select: false,
        code: "Object",
      },
      {
        type: "option",
        text: "Witch's Broom Nebula",
        select: false,
        code: "Object",
      },
    ],
  },
];
const Random = [
  {
    type: "item",
    text: "Token Catalogue",
    disable: false,
  },
  {
    type: "item",
    text: "Object Catalogue",
    disable: false,
  },
];
const Help = [
  {
    type: "item",
    text: "About...",
    disable: false,
  },
  {
    type: "separator",
  },
  {
    type: "item",
    text: "Help Center",
    disable: false,
  },
];
const Edit = [
  {
    type: "item",
    text: "Select All",
    disable: true,
  },
  {
    type: "item",
    text: "Select None",
    disable: true,
  },
  {
    type: "separator",
  },
  {
    type: "item",
    text: "Refresh",
    disable: true,
  },
  {
    type: "item",
    text: "Close",
    disable: false,
  },
];

const optionsMenuData = { View, Observatory, Catalogue, Random, Help, Edit };
export default optionsMenuData;
