import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { OptionsMenu } from 'components';
import optionsMenuData from './optionsMenuData';
import dead from 'assets/sweeper/dead.png';
import smile from 'assets/sweeper/smile.png';
import win from 'assets/sweeper/win.png';
import ohh from 'assets/sweeper/ohh.png';
import empty from 'assets/sweeper/empty.png';
import open1 from 'assets/sweeper/open1.png';
import open2 from 'assets/sweeper/open2.png';
import open3 from 'assets/sweeper/open3.png';
import open4 from 'assets/sweeper/open4.png';
import open5 from 'assets/sweeper/open5.png';
import open6 from 'assets/sweeper/open6.png';
import open7 from 'assets/sweeper/open7.png';
import open8 from 'assets/sweeper/open8.png';
import flag from 'assets/sweeper/flag.png';
import mine from 'assets/sweeper/mine-ceil.png';
import mineDeath from 'assets/sweeper/mine-death.png';
import misFlagged from 'assets/sweeper/misflagged.png';
import question from 'assets/sweeper/question.png';
import digit0 from 'assets/sweeper/digit0.png';
import digit1 from 'assets/sweeper/digit1.png';
import digit2 from 'assets/sweeper/digit2.png';
import digit3 from 'assets/sweeper/digit3.png';
import digit4 from 'assets/sweeper/digit4.png';
import digit5 from 'assets/sweeper/digit5.png';
import digit6 from 'assets/sweeper/digit6.png';
import digit7 from 'assets/sweeper/digit7.png';
import digit8 from 'assets/sweeper/digit8.png';
import digit9 from 'assets/sweeper/digit9.png';
import digit_ from 'assets/sweeper/digit-.png';

const digits = [
  digit0,
  digit1,
  digit2,
  digit3,
  digit4,
  digit5,
  digit6,
  digit7,
  digit8,
  digit9,
];
function renderDigits(number) {
  let numberStr;
  if (number < 0) {
    const _number = -number % 100;
    if (_number === 0) {
      numberStr = '00';
    } else if (_number < 10) {
      numberStr = '0' + _number;
    } else {
      numberStr = String(_number);
    }
    return (
      <>
        <img draggable={false} src={digit_} alt="-" />
        {numberStr.split('').map((n, i) => (
          <img draggable={false} src={digits[n]} key={i} alt={n} />
        ))}
      </>
    );
  }

  numberStr = number < 999 ? String(number) : '999';
  if (number < 10) numberStr = '00' + numberStr;
  else if (number < 100) numberStr = '0' + numberStr;
  return numberStr
    .split('')
    .map((n, i) => <img draggable={false} key={i} src={digits[n]} alt={n} />);
}

function genoptionsMenuData(difficulty) {
  let _Game = [...optionsMenuData.Game];
  _Game[2].check = difficulty === 'Beginner';
  _Game[3].check = difficulty === 'Intermediate';
  _Game[4].check = difficulty === 'Expert';
  return { Game: _Game, Help: optionsMenuData.Help, Edit: optionsMenuData.Edit };
}

function MineSweeperView({
  ceils,
  className,
  changeCeilState,
  onReset,
  openCeil,
  openCeils,
  mines,
  status,
  seconds,
  onClose,
  difficulty,
  openingCeil,
  openingCeils,
  onRug,
  onToggleComponent,
  id
}) {
  const face = useRef(null);
  const [mouseDownContent, setMouseDownContent] = useState(false);
  const [openBehavior, setOpenBehavior] = useState({ index: -1, behavior: '' });
  function remainMines() {
    return (
      mines -
      ceils.filter(ceil => ceil.state === 'flag' || ceil.state === 'misflagged')
        .length
    );
  }
  function statusFace() {
    if (mouseDownContent) return <img draggable={false} alt="ohh" src={ohh} />;
    switch (status) {
      case 'died':
        return <img draggable={false} alt="dead" src={dead} />;
      case 'won':
        return <img draggable={false} alt="win" src={win} />;
      default:
        return <img draggable={false} alt="smile" src={smile} />;
    }
  }
  function onPointerDownContent(e) {
    if (e.button !== 0) return;
    if (
      face.current.contains(e.target) ||
      status === 'won' ||
      status === 'died'
    )
      return;
    setMouseDownContent(true);
  }
  useEffect(() => {
    const { index, behavior } = openBehavior;
    switch (behavior) {
      case 'single':
        return openingCeil(index);
      case 'multi':
        return openingCeils(index);
      default:
        openingCeil(-1);
    }
    // eslint-disable-next-line
  }, [openBehavior.index, openBehavior.behavior]);
  function onPointerDownCeils(e) {
    const index = Array.prototype.indexOf.call(
      e.currentTarget.children,
      e.target.closest('.mine__ceil'),
    );
    if (e.button === 2 && e.buttons === 2 && index !== -1) {
      changeCeilState(index);
    } else if (e.button === 0 && e.buttons === 1) {
      setOpenBehavior({
        index,
        behavior: 'single',
      });
    } else if (e.buttons === 3) {
      setOpenBehavior({
        index,
        behavior: 'multi',
      });
    }
  }
  function onPointerOverCeils(e) {
    const index = Array.prototype.indexOf.call(
      e.currentTarget.children,
      e.target.closest('.mine__ceil'),
    );
    setOpenBehavior({
      index,
      behavior: openBehavior.behavior,
    });
  }
  function onPointerUpCeils() {
    const { behavior, index } = openBehavior;
    if (index === -1) return;
    if (behavior === 'single') {
      openCeil(index);
    } else if (behavior === 'multi') {
      openCeils(index);
    }
  }
  function onClickOptionItem(item) {
    switch (item.text) {
      case 'Rug Mode':
        onRug();
        break;
      case 'Close':
        onClose();
        break;
      case 'Beginner':
      case 'Intermediate':
      case 'Expert':
        onReset(item.text);
        break;
      case 'Help Center':
        onToggleComponent("Help", "open", id)
        break;
      case 'New':
        onReset();
        break;
      default:
    }
  }
  useEffect(() => {
    window.addEventListener('mouseup', onPointerUp);
    return () => {
      window.removeEventListener('mouseup', onPointerUp);
    };
  }, []);
  function onPointerUp(e) {
    setOpenBehavior({ index: -1, behavior: '' });
    setMouseDownContent(false);
  }
  useEffect(() => {
    window.addEventListener('mouseup', onPointerUp);
    return () => {
      window.removeEventListener('mouseup', onPointerUp);
    };
  }, []);
  return (
    <div className={className} onContextMenu={e => e.preventDefault()}>
      <div className="mine__options">
        <OptionsMenu
          items={genoptionsMenuData(difficulty)}
          onClickItem={onClickOptionItem}
        />
      </div>
      <section className="mine__content" onPointerDown={onPointerDownContent}>
        <div className="mine__score-bar">
          <div className="mine__digits__outer">
            {renderDigits(remainMines())}
          </div>
          <div className="mine__face__outer">
            <button ref={face} className="mine__face" onClick={() => onReset()}>
              {statusFace()}
              <img draggable={false} alt="smile" src={smile} />
            </button>
          </div>
          <div className="mine__digits__outer">{renderDigits(seconds)}</div>
        </div>
        <div
          className="mine__content__inner"
          onPointerDown={onPointerDownCeils}
          onPointerOver={onPointerOverCeils}
          onPointerUp={onPointerUpCeils}
        >
          <Ceils ceils={ceils} />
        </div>
      </section>
    </div>
  );
}
function getTextImg(index) {
  return [empty, open1, open2, open3, open4, open5, open6, open7, open8][index];
}
function Ceils({ ceils }) {
  function renderContent(ceil) {
    const { state, minesAround, opening } = ceil;
    switch (state) {
      case 'open':
        return <MinesAround mines={minesAround} />;
      case 'flag':
        return <Flag />;
      case 'misflagged':
        return <MisFlagged />;
      case 'mine':
        return <Mine />;
      case 'die':
        return <Die />;
      case 'unknown':
        return opening ? <QuestionOpen /> : <Question />;
      default:
        return opening ? <CeilBackgroundOpen /> : <CeilBackgroundCover />;
    }
  }

  return ceils.map((ceil, index) => (
    <div key={index} className="mine__ceil">
      {renderContent(ceil)}
    </div>
  ));
}

const Die = () => (
  <>
    <CeilBackgroundOpen />
    <img draggable={false} alt="death" src={mineDeath} />
  </>
);
const MisFlagged = () => (
  <>
    <CeilBackgroundOpen />
    <img draggable={false} alt="misFlagged" src={misFlagged} />
  </>
);
const Flag = () => (
  <>
    <CeilBackgroundCover />
    <img draggable={false} alt="flag" src={flag} />
  </>
);
const MinesAround = ({ mines }) => (
  <>
    <CeilBackgroundOpen />
    <img draggable={false} alt="mines-around" src={getTextImg(mines)} />
  </>
);
const Question = () => (
  <>
    <CeilBackgroundCover />
    <img draggable={false} alt="question" src={question} />
  </>
);
const QuestionOpen = () => (
  <>
    <CeilBackgroundOpen />
    <img draggable={false} alt="question" src={question} />
  </>
);
const Mine = () => (
  <>
    <CeilBackgroundOpen />
    <img draggable={false} alt="mine" src={mine} />
  </>
);

const CeilBackgroundCover = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-left: #ffffff solid 2px;
  border-top: #ffffff solid 2px;
  border-right: var(--colorgrad6) solid 2px;
  border-bottom: var(--colorgrad6) solid 2px;
`;
const CeilBackgroundOpen = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-left: var(--colorgrad6) solid 1px;
  border-top: var(--colorgrad6) solid 1px;
`;

export default styled(MineSweeperView)`
  img {
    pointer-events: none;
  }
  .mine__options {
    height: 20px;
  }
  .mine__content {
    border-left: #ffffff solid 3px;
    border-top: #ffffff solid 3px;
    border-bottom: var(--colorgrad6) solid 3px;
    border-right: var(--colorgrad6) solid 3px;
    /* background-color: var(--colorgrad6);
    padding-bottom: 2px; */
    padding: 5px;
  }
  .mine__score-bar {
    height: 34px;
    border-radius: 1px;
    border-top: var(--colorgrad6) solid 2px;
    border-left: var(--colorgrad6) solid 2px;
    border-right: #ffffff solid 2px;
    border-bottom: #ffffff solid 2px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
  }
  .mine__digits__outer {
    width: 40px;
    height: 24px;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #fff;
    text-align: right;
  }
  .mine__face__outer {
    width: 24px;
    height: 24px;
    border-top: 1px solid var(--colorgrad6);
    border-left: 1px solid var(--colorgrad6);
    border-radius: 2px;
    transform: translateX(1px);
  }
  .mine__face {
    border-radius: 2px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(192, 192, 192);
    border-width: 2px;
    border-style: solid;
    border-color: #ffffff var(--colorgrad6) var(--colorgrad6)
      #ffffff;
    outline: none;
    &:active:hover {
      border-width: 1px;
      border-color: var(--colorgrad6);
      img {
        transform: translate(1px, 1px);
      }
      img:nth-child(1) {
        display: none;
      }
      img:nth-child(2) {
        display: block;
      }
    }
    img:nth-child(2) {
      display: none;
    }
  }
  .mine__content__inner {
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, 18px);
    grid-template-rows: repeat(${({ rows }) => rows}, 18px);
    border-top: var(--colorgrad6) solid 3px;
    border-left: var(--colorgrad6) solid 3px;
    border-right: #ffffff solid 3px;
    border-bottom: #ffffff solid 3px;
  }
  .mine__ceil {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      position: absolute;
      width: 16px;
      height: 16px;
    }
  }
`;
