import React, { useEffect } from "reactn";
import blinkie0 from "assets/god/intro/150x20_0.gif";
import blinkie1 from "assets/god/intro/150x20_1.gif";
import blinkie2 from "assets/god/intro/150x20_2.gif";
import blinkie3 from "assets/god/intro/150x20_3.gif";
import blinkie4 from "assets/god/intro/150x20_4.gif";
import blinkie5 from "assets/god/intro/150x20_5.gif";
import blinkie6 from "assets/god/intro/150x20_6.gif";
import banner0 from "assets/god/intro/99x57_0.gif";
import banner1 from "assets/god/intro/99x57_1.gif";
import banner2 from "assets/god/intro/99x57_2.gif";
import banner3 from "assets/god/intro/99x57_3.gif";
import banner4 from "assets/god/intro/99x57_4.gif";
import button0 from "assets/god/intro/88x31_0.gif";
import godtag from "assets/god/research/godtag.png";

const IntroSub = () => {
  useEffect(() => {}, []);

  return (
    <div id="go_bottom" className="go_scrollbar">
      <div>
        <img className="blinkies" alt="blinkies" src={banner0} />
        <img className="blinkies" alt="blinkies" src={banner1} />
        <img className="blinkies" alt="blinkies" src={banner2} />
        <img className="blinkies" alt="blinkies" src={banner3} />
        <img className="blinkies" alt="blinkies" src={banner4} />
        <div className="no_border">
          <img className="blinkies" alt="blinkies" src={blinkie0} />
          <img className="blinkies" alt="blinkies" src={blinkie1} />
          <img className="blinkies" alt="blinkies" src={blinkie2} />
          <img className="blinkies" alt="blinkies" src={blinkie3} />
          <img className="blinkies" alt="blinkies" src={blinkie4} />
          <img className="blinkies" alt="blinkies" src={blinkie5} />
          <img className="blinkies" alt="blinkies" src={blinkie6} />
        </div>
        <div className="no_border">
          <img className="blinkies" alt="blinkies" src={button0} />
        </div>
        <div className="no_border">
          <img alt="banner" src={godtag} />
        </div>
      </div>
    </div>
  );
};

export default IntroSub;
