import { useHotKey } from "hooks";
const sequence = [
  "ArrowUp",
  "ArrowUp",
  "ArrowDown",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowLeft",
  "ArrowRight",
  "b",
  "a",
];

const Konami = () => {

  useHotKey(sequence, () => {
    const delay = 100;
    let elems = [];
    let chars = [];
    let elmax = 0;
    let chmax = 0;

    function scramble() {
      if (document.getElementById) {
        let i, j, parent, span, words;
        getAllText(document.body);
        for (i = 0; i < elmax; i++) {
          parent = elems[i].parentNode;
          words = elems[i].nodeValue.split(" ");
          for (j = 0; j < words.length; j++) {
            if (words[j].match(/^\w{2,}$/)) {
              span = document.createElement("em");
              span.appendChild(document.createTextNode(words[j]));
              parent.insertBefore(span, elems[i]);
              chars[chmax++] = span;
            } else
              parent.insertBefore(document.createTextNode(words[j]), elems[i]);
            if (j !== words.length - 1)
              parent.insertBefore(document.createTextNode(" "), elems[i]);
          }
          parent.removeChild(elems[i]);
        }
        setTimeout(scrumble(), delay * 1000);
      }
    }

    function scrumble() {
      let i, l1, r, l2;
      for (i = 0; i < chmax; i++) {
        l1 = chars[i].firstChild.nodeValue.length;
        do {
          r = i + Math.floor(Math.random() * (chmax - i));
          l2 = chars[r].firstChild.nodeValue.length;
        } while (i < chmax - 256 && l1 !== l2);
        setTimeout(swap(i, r), 40 * i);
      }
    }

    function swap(i, j) {
      let k = chars[i].firstChild.nodeValue;
      let l = chars[j].firstChild.nodeValue;
      chars[i].firstChild.nodeValue = l;
      chars[j].firstChild.nodeValue = k;
    }

    function getAllText(el) {
      if (el.nodeType === 3 && !el.nodeValue.match(/^\s*$/))
        elems[elmax++] = el;
      if (el.childNodes.length)
        for (let i = 0; i < el.childNodes.length; i++)
          getAllText(el.childNodes[i]);
    }
    scramble();
  });

  return null;
};

export default Konami;
