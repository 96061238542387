import { RANGE } from "../constants";
const optionTypes = {
  levels: { type: RANGE, range: [1, 256], default: 1 }
};

const defaults = {
  levels: optionTypes.levels.default
};

const getColor = (
  color: ColorRGBA,
  options: { levels: number } = defaults
): ColorRGBA => {
  return color.map(c => {
    const bucket = Math.round(c / options.levels);
    return Math.round(bucket * options.levels);
  });
};

const defaultFunc = {
  name: "nearest",
  getColor,
  options: defaults,
  optionTypes,
  defaults
};

export default defaultFunc;
