import React, { useEffect } from "reactn";
import styled from "styled-components";
import godbutton from "assets/god/research/godbutton.gif";
import godblinkie from "assets/god/research/godblinkie.gif";
import godtag from "assets/god/research/godtag.png";
import atom from "assets/god/research/atom.gif";
import constr from "assets/god/research/constr.gif";
import marquee from "assets/god/research/marquee.gif";
import book from "assets/god/research/book.gif";
import netscape from "assets/god/research/netscape.gif";
import precession from "assets/god/research/precession.gif";
import bullet from "assets/god/research/bullet.png";
import seti from "assets/god/research/seti.gif";
import fits from "assets/god/research/fits.jpg";

export const ResearchMenu = () => {
  function scrollToSection(id) {
    document.querySelector(".rs_section" + id).scrollIntoView({
      behavior: "smooth",
    });
  }

  return (
    <MenuDiv>
      <div id="objitem" className="selectable">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Abstract
        </h3>
        <ul>
          <li
            onClick={() => {
              scrollToSection(0);
            }}
          >
            <span className="go_links pointer">Abstract</span>
          </li>
        </ul>
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Introduction
        </h3>
        <ul>
          <li
            onClick={() => {
              scrollToSection(1);
            }}
          >
            <span className="go_links pointer">Brief History</span>
          </li>
          <li
            onClick={() => {
              scrollToSection(2);
            }}
          >
            <span className="go_links pointer">On-chain Astrotheology</span>
          </li>
        </ul>
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Space Travel
        </h3>
        <ul>
          <li
            onClick={() => {
              scrollToSection(3);
            }}
          >
            <span className="go_links pointer">Space Shuttle</span>
          </li>
        </ul>
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Blockchain Sortilege
        </h3>
        <ul>
          <li
            onClick={() => {
              scrollToSection(4);
            }}
          >
            <span className="go_links pointer">Blockchain</span>
          </li>
          <li
            onClick={() => {
              scrollToSection(5);
            }}
          >
            <span className="go_links pointer">Sortilege</span>
          </li>
          <li
            onClick={() => {
              scrollToSection(6);
            }}
          >
            <span className="go_links pointer">Horoscopic Astrology</span>
          </li>
        </ul>
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Astronomical Imaging
        </h3>
        <ul>
          <li
            onClick={() => {
              scrollToSection(7);
            }}
          >
            <span className="go_links pointer">Image Processing</span>
          </li>
        </ul>
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Preprogrammed Pareidolia
        </h3>
        <ul>
          <li
            onClick={() => {
              scrollToSection(8);
            }}
          >
            <span className="go_links pointer">God Frequency</span>
          </li>
          <li
            onClick={() => {
              scrollToSection(9);
            }}
          >
            <span className="go_links pointer">Pareidolia</span>
          </li>
          <li
            onClick={() => {
              scrollToSection(10);
            }}
          >
            <span className="go_links pointer">Motion Vectors</span>
          </li>
        </ul>
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Data Sonification
        </h3>
        <ul>
          <li
            onClick={() => {
              scrollToSection(11);
            }}
          >
            <span className="go_links pointer">GodRemixer</span>
          </li>
          <li
            onClick={() => {
              scrollToSection(12);
            }}
          >
            <span className="go_links pointer">Chromatic</span>
          </li>
          <li
            onClick={() => {
              scrollToSection(13);
            }}
          >
            <span className="go_links pointer">Kinematic</span>
          </li>
          <li
            onClick={() => {
              scrollToSection(14);
            }}
          >
            <span className="go_links pointer">Chiaroscuro</span>
          </li>
          <li
            onClick={() => {
              scrollToSection(15);
            }}
          >
            <span className="go_links pointer">Edge Detect</span>
          </li>
        </ul>
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Conclusion
        </h3>
        <ul>
          <li
            onClick={() => {
              scrollToSection(16);
            }}
          >
            <span className="go_links pointer">Conclusion</span>
          </li>
        </ul>
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          References
        </h3>
        <ul>
          <li
            onClick={() => {
              scrollToSection(17);
            }}
          >
            <span className="go_links pointer">References</span>
          </li>
        </ul>
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Footnotes
        </h3>
        <ul>
          <li
            onClick={() => {
              scrollToSection(18);
            }}
          >
            <span className="go_links pointer">Footnotes</span>
          </li>
        </ul>
      </div>
    </MenuDiv>
  );
};
const MenuDiv = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 0 0 0;
  align-items: flex-start;
  flex-direction: column;
  ol,
  ul {
    margin: 10px 20px 15px 20px;
  }
  li {
    margin: 10px;
  }
  #objitem {
    position: absolute;
    width: 100%;
    padding: 0 15px 15px 15px;
  }
  #objimg {
    border: 1px dashed #b19cd9;
    padding: 6px;
    p {
      color: var(--colorgrad5);
      margin-top: 2px;
      margin-bottom: -2px;
    }
    img {
      max-width: 100%;
    }
  }
  #objtable {
    max-width: 100%;
    white-space: nowrap;
    p {
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 4px 0;
      b {
        font-size: 12px;
        letter-spacing: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--colorgrad5);
        letter-spacing: 1px;
      }
      b + span {
        padding-left: 12px;
      }
    }
    p + p {
      border-top: 1px dashed white;
    }
  }
  #objbody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    div {
      margin-top: 10px;
    }
  }
`;
export const Research = ({ onToggleComponent, id, createViewList, standAlone }) => {
  const centerTitle = document.querySelector(".center_title");
  const mainTop = document.getElementById("go_top");

  function scrollToRef(id) {
    const allLi = document
      .querySelector(".rs_section17")
      .querySelectorAll("li");
    allLi.forEach((li) => li.classList.remove("li_highlight"));
    const li = allLi[id - 1];
    li.classList.add("li_highlight");
    li.scrollIntoView({ behavior: "smooth" });
  }

  function scrollFromRef(el) {
    const allLi = document
      .querySelector(".rs_section17")
      .querySelectorAll("li");
    const id = Array.from(allLi).findIndex((li) => li === el.parentNode) + 1;
    document
      .querySelector("#reflink" + id)
      .scrollIntoView({ behavior: "smooth" });
    allLi.forEach((li) => li.classList.remove("li_highlight"));
  }

  useEffect(() => {
    if (centerTitle) centerTitle.style.width = "inherit";
    return () => {
      if (centerTitle) centerTitle.style.width = "inherit";
    };
  }, [centerTitle]);

  useEffect(() => {
    if (mainTop) mainTop.style.display = "flex";
    return () => {
      if (mainTop) mainTop.style.display = "none";
    };
  }, [mainTop]);

  return (
    <Div className="selectable">
      <h2>God Observer: a study on On-Chain Astrotheology</h2>
      <section className="rs_content rs_section0">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Abstract
        </h3>
        <p>
          Humanity has searched for intelligent life in celestial objects from
          the earliest ages of history. In ancient times, this intelligence was
          often imagined to be a deity or godlike being. More recently, despite
          the fact that Western societies have sought to separate science from
          religious creationism and often rejects concepts as gods in its quest
          for truth, it is arguable that if an extraterrestrial civilization
          were sufficiently advanced to make contact with us, it would likely be
          so far beyond us in its development as to resemble "gods" even to our
          modern sensibilities. The advent of radio technology marked the onset
          of a new wave of SETIs (search for extraterrestrial intelligence), all
          of which have failed or have yet to yield the expected results. That's
          why we, the researchers of HeavenComputer Labs, have developed the
          GodObserver™ project, which includes a space shuttle program, a
          high-end telescope and and innovative data remixer software, that aims
          to test and prove the feasibility of astronomical god observations by
          utilizing the most advanced SETI technology to date,{" "}
          <i>on-chain astrotheology</i> and its five-step methodology.
        </p>
      </section>
      <span className="rs_marquee">
        <img alt="blinkies" src={marquee} />
      </span>
      <section className="rs_content">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Introduction
        </h3>
        <h4 className="rs_section1">Brief History</h4>
        <p>
          The history of humans worshipping the stars, planets and heavenly
          bodies is as old as the history of humankind itself. For many
          millennia, humans have looked to the skies and become awestruck by
          what they have observed. Not just for its glimmer and glitter — in the
          early ages of our existence, understanding the skies above us could
          mean harvesting better crops, not losing one’s path in the wilderness,
          and other survival skills unimaginable to most but comprehensible to
          the one who not only gazes at stars, but deeply observes them,
          catalogues them, follows them.
        </p>
        <p>
          In the Western world, the first popular speculations about outer space
          civilizations date back to the Greek philosophers Democritus and
          Epicurus, who considered the universe to be the result of a chance
          clutter of atoms, and as such it is highly likely that there are
          countless worlds scattered throughout the cosmos, some like ours and
          others completely different
          <sup
            id="reflink1"
            className="pointer"
            onClick={() => {
              scrollToRef(1);
            }}
          >
            [<span className="pointer">1</span>]
          </sup>
          . As the Roman Epicurean poet Lucretius put it: “Nothing in the
          universe is unique and alone and therefore in other regions there must
          be other earths inhabited by different tribes of men and breeds of
          beasts.”
          <sup
            id="reflink2"
            className="pointer"
            onClick={() => {
              scrollToRef(2);
            }}
          >
            [<span className="pointer">2</span>]
          </sup>{" "}
          Since then, beyond the many Epicurean disciples that followed, ideas
          about the plurality of worlds have spread and permeated a significant
          portion of the debate between science and religion.
        </p>
        <p>
          In a dramatic example, the monk Giordano Bruno was burned alive by the
          Roman Inquisition in 1600 for preaching, among other things, that
          every star is a Sun and that every Sun has its planets
          <sup
            id="reflink3"
            className="pointer"
            onClick={() => {
              scrollToRef(3);
            }}
          >
            [<span className="pointer">3</span>]
          </sup>
          . A few decades later, Galileo Galilei, widely regarded as the father
          of observational astronomy for his notorious pioneering use and
          invention of astronomical tools such as the telescope
          <sup
            id="reflink4"
            className="pointer"
            onClick={() => {
              scrollToRef(4);
            }}
          >
            [<span className="pointer">4</span>]
          </sup>
          , almost faced a similar fate after he, in a cunningly clever elusion,
          invited the Church and other believers of Aristotelian geocentrism for
          a debate in his work
          <i>Dialogue Concerning the Two Chief World Systems</i>
          <sup
            id="reflink5"
            className="pointer"
            onClick={() => {
              scrollToRef(5);
            }}
          >
            [<span className="pointer">5</span>]
          </sup>{" "}
          by challenging all of their world views and beliefs. Galileo was then
          accused of heresy and imprisoned for the rest of his life. His
          imprisonment, nonetheless, was not in vain for it did little to halt
          the revolution that had been sparked by his breakthroughs.
          Justifiably, Galileo is considered by the likes of Stephen Hawking and
          Albert Einstein to be the father of modern science. “And yet it
          moves!” — Galileo would likely say, as Europe moved into what became
          known as Age of Enlightenment in the years following his death.
          <sup
            id="reflink6"
            className="pointer"
            onClick={() => {
              scrollToRef(6);
            }}
          >
            [<span className="pointer">6</span>]
          </sup>
        </p>
        <p>
          One consequence of the ferocious rejection of religious dogma that
          took place during the Age of Enlightenment, however, is that
          disciplines once believed to be one, the “science” and “religion” of
          celestial bodies — respectively, astronomy and astrology — gradually
          diverged over the course of the 17th century and never fully melded
          again. To this day, the belief, or even the slight suggestion, that
          gods can be found in astronomical bodies is one that is bound to
          receive frowns from both scientists and religionists alike.
        </p>
        <p>
          Most conservative religions deny the possibility of extraterrestrial
          life, especially of the intelligent variety. In the case of
          Christianity, for example, God is the creator and creation is
          described in the Bible, and we do not see any mention of other planets
          and creatures. On the contrary, humans are the chosen kind and
          therefore privileged creatures. All earthly animals and plants exist
          to serve us. Being intelligent beings is a unique gift that puts us at
          the very top of an arbitrary pyramid of existence. So what would
          happen to this principle if we made contact with another intelligent
          civilization? Leaving aside the numerous difficulties of such a
          contact — from the serendipity of life itself to the technological
          challenges of interstellar travel — the answers would likely depend on
          the level of intelligence of the members of this civilization.
        </p>
        <p>
          When we open ourselves up the possibility that intelligent
          extraterrestrial life exists, the probability that they are smarter
          than we are is high. If they are the ones who can reach us, there is
          no doubt that they are much more developed than we are. Not
          necessarily more intelligent, but perhaps with more time to develop
          their technologies. After all, we are still in the infancy of the
          technological age: the first steam locomotive was invented a little
          over 200 years ago, in 1814
          <sup
            id="reflink7"
            className="pointer"
            onClick={() => {
              scrollToRef(7);
            }}
          >
            [<span className="pointer">7</span>]
          </sup>
          . Despite how new our relationships with machines are, some claim that
          in a few decades we will reach a point where our hybridization with
          machines will be so profound that we will no longer be able to
          dissociate ourselves from them
          <sup
            id="reflink8"
            className="pointer"
            onClick={() => {
              scrollToRef(8);
            }}
          >
            [<span className="pointer">8</span>]
          </sup>
          . If these predictions come true — and, if you look around you might
          see they are already happening — we will be a new species, beyond
          human. Maybe akin to how we imagine aliens to be.
        </p>
        <p>
          Like Clarke’s third law
          <sup
            id="reflink9"
            className="pointer"
            onClick={() => {
              scrollToRef(9);
            }}
          >
            [<span className="pointer">9</span>]
          </sup>{" "}
          would suggest, their technology, if sufficiently advanced, would be to
          us indistinguishable from magic. Either more intelligent or more
          technologically advanced, our reaction to making contact with such
          beings would be a mixture of adoration and terror. If they were much
          more advanced than we are, to the point of having developed
          technologies that are able release them from their bodies, these
          beings would only have an immaterial spiritual-like existence or would
          have completely transformed by hybridizing with machines. They would
          perhaps be nothing but data inhabiting energy fields in space. They
          would have the power to create life, choosing their properties. They
          could, for example, have created us, or some of our ancestors, as part
          of an experiment or simulation
          <sup
            id="reflink10"
            className="pointer"
            onClick={() => {
              scrollToRef(10);
            }}
          >
            [<span className="pointer">10</span>]
          </sup>
          . They could be watching us, as we watch animals in the zoo or in the
          laboratory. These immaterial yet omnipresent entities would then be
          our creators. At this point, it would be difficult to distinguish them
          from gods.
        </p>
        <p>
          For almost a century, scientists have been scanning the skies with
          their radio telescopes, trying to listen for signals from intelligent
          civilizations. Many SETI (search for extraterrestrial intelligence)
          have launched since the advent of radio, notably Breakthrough Listen
          <sup
            id="reflink11"
            className="pointer"
            onClick={() => {
              scrollToRef(11);
            }}
          >
            [<span className="pointer">11</span>]
          </sup>
          , which received over a 100 million in funding in 2015 making it the
          “largest SETI ever”, and SETI@home
          <sup
            id="reflink12"
            className="pointer"
            onClick={() => {
              scrollToRef(12);
            }}
          >
            [<span className="pointer">12</span>]
          </sup>
          , which despite having failed in its extraterrestrial search,
          succeeded in proving the viability of volunteer computing and
          populating Geocities websites with cool gifs such as the one below:
        </p>
        <p style={{ display: "flex", alignSelf: "center" }}>
          <img alt="blinkies" src={seti} />
        </p>
        <p>
          Sadly, so far nothing has been found. In fact, many scientists deem
          this quest a huge waste of time and money. Chances that anything
          significant will ever be found are extremely remote. How could we know
          what frequencies would these alien beings use to send their signals?
          And how to decipher them? How do we even know they are sending radio
          signals, or trying to communicate in the first place? On the other
          hand, advocates of the quest claim that a positive outcome would
          profoundly change our civilization. Confirmation of the existence of
          another form of intelligent life in the universe would spark a
          revolution. Some even claim it would be the greatest announcement of
          all time, and that’s hard to argue against. It would mean we are not
          alone anymore. And it would mean that, if our galactic neighbors
          really are more advanced and peaceful, then maybe they could help us
          heal some of our societal ailments such as famine, wars, racism and
          religious conflicts. Perhaps they could help us solve our own
          scientific challenges. And if they could do so, how different would
          they be from the gods that so many believe exist? It is not by chance
          that all throughout history, many religions have directed their
          prayers to the stars, and not the altars.
        </p>
        <h4 className="rs_section2">On-chain Astrotheology</h4>
        <p>
          Despite the challenges and heretic nature of SETIs, researchers at the
          HeavenComputer Labs do not shy away from subversive science and have
          been, since the successful conception of our on-chain errormancy
          technology used to detect ghosts in the blockchain, GhostSoftware™,
          working non-stop on developing a new and more advanced tech in
          divination aimed to detect not only ghosts, but god themselves:
          GodObserver™, on-chain astrotheology.
        </p>
        <p>It operates on five key steps:</p>
        <ol>
          <li>
            <b>Space travel</b> in a program that allows its applicants to
            volunteer to go on board a space shuttle in the mission set to
            launch the GodObserver™ Telescope into orbit
          </li>
          <li>
            <b>Blockchain sortilege</b> algorithms where the observer or
            minter’s address and the mint transaction block number generates a
            seed which assists in the selection of one or more elements of the
            god, the celestial object it inhabits and its observation properties
          </li>
          <li>
            <b>Astronomical imaging</b> from raw telescope data, such as x-ray,
            optical, radio and infrared data, that generates an appealing
            color-composite multi-wavelength representation of a deep sky object
          </li>
          <li>
            <b>Preprogrammed pareidolia</b> in layering the raw data of the
            video of an archetypal representation of god with the raw data of
            the color-composite multi-wavelength astronomical object data
          </li>
          <li>
            <b>Data sonification</b> by the means of painting aesthetically
            synesthetic soundscapes wherein each element of the sound is mapped
            from an element of the image, specifically the elements of color,
            motion, outlines and lights/shadows
          </li>
        </ol>
      </section>
      <span className="rs_marquee">
        <img alt="blinkies" src={marquee} />
      </span>
      <section className="rs_content">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Space Travel
        </h3>
        <h4 className="rs_section3">Space Shuttle</h4>
        <p>
          As the first step to the GodObserver™ program, we've opened the
          applications for volunteers who wish go on board a space shuttle in
          the mission set to launch the first ever GodObserver™ Telescope into
          orbit. These heroic volunteers will be responsible for operating the
          spaceship, rearranging planets and stars, launching the telescope into
          orbit and subsequently reading and remixing the data collected in
          these missions by the telescope. We have made 3334 passes available
          for those who wish to partake in this revolutionary program. These
          passes are in the form of tokens in the blockchain, so that the
          Blockchain Sortilege method will match each participant (also referred
          to in this document as minter and observer) with its corresponding god
          observation. For more information on Blockchain Sortilege other
          methods used in this program, please refer to the document below. We
          kindly request that all participants study this research paper prior
          to boarding on the shuttle.
        </p>
        <p>
          Click{" "}
          <span
            className="go_links pointer"
            onClick={() => {
              createViewList("Intro", "Research");
              if (standAlone) window.history.pushState("", "", "/");
            }}
          >
            here
          </span>{" "}
          if you wish to apply.
        </p>
      </section>
      <span className="rs_marquee">
        <img alt="blinkies" src={marquee} />
      </span>
      <section className="rs_content">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Blockchain Sortilege
        </h3>
        <h4 className="rs_section4">Blockchain</h4>
        <p>
          Similarly to how GhostSoftware™ decrypts the transmission hash into a
          seed that morphs the visual representations of a ghost based on
          its stone tape
          <sup
            id="reflink13"
            className="pointer"
            onClick={() => {
              scrollToRef(13);
            }}
          >
            [<span className="pointer">13</span>]
          </sup>
          recordings, GodObserver™ does the same for the god’s representation
          based on the raw observational data from the telescope that captures
          it. More simply put, it runs a sortilege algorithm on-chain and based
          on the observer or minter’s blockchain address and the mint
          transaction block number generates a hexadecimal hash such as this
          one:
        </p>
        <p>
          0x0879e983da4c8380de59d3b9170169c19af4b3485891114d23340e02f80b0b08
        </p>
        <p>
          And that hash is then decrypted and used to determine the traits and
          attributes of the observed god, so that each god minted is unique and
          personal to whoever mints it.
        </p>
        <p>
          Besides its sortilege capabilities, the use of blockchain as a tool
          for SETIs is one that seems natural since the success of SETI@Home's
          volunteer computing
          <sup
            id="reflink14"
            className="pointer"
            onClick={() => {
              scrollToRef(14);
            }}
          >
            [<span className="pointer">14</span>]
          </sup>{" "}
          program, as the notion of decentralized systems is a common factor
          between these technologies. And on an ideological level, should
          humankind ever make contact with an alien civilization, any logs, data
          and records of said contact should ideally be safely stored and
          distributed in a decentralized and transparent platform like the
          blockchain.
        </p>
        <h4 className="rs_section5">Sortilege</h4>
        <p>
          This method has been named “Blockchain Sortilege” because it utilizes
          blockchain data for randomly selecting one or more elements of the
          god, the celestial object it inhabits and its observation properties —
          as in the practice of sortilege, common among the ancient Romans
          <sup
            id="reflink15"
            className="pointer"
            onClick={() => {
              scrollToRef(15);
            }}
          >
            [<span className="pointer">15</span>]
          </sup>
          , of drawing lots (from which the word “lottery” comes), or{" "}
          <i>sortes</i>, as a means of fortunetelling and general foretelling,
          similarly to casting runes or drawing tarot cards.
        </p>
        <p>
          Those who believe more traditional tools of sortilege like the tarot
          cards to be intrinsically more appropriate for this practice than
          software created for the same purpose fail to understand either
          divination or technology. While it is acceptable to prefer one tool
          over the other, it is also important to note that one's preferred tool
          isn't the only means of divination by sortilege, and whatever it may
          be, the reason it has been favored at a certain time in history is
          mostly due to its accessibility, not because it was uniquely qualified
          for occult arts. Cultures that did not possess or recognize a means
          for producing Tarot cards, for example, created divination tools out
          of sticks, stones, fire, or observing the flights of birds and the ebb
          and flow of oceans...
        </p>
        <p>
          As Heinz von Foerster, the father of second-order cybernetics, once
          put it
          <sup
            id="reflink16"
            className="pointer"
            onClick={() => {
              scrollToRef(16);
            }}
          >
            [<span className="pointer">16</span>]
          </sup>
          :
          <span className="go_quote">
            Harmlessly enough, computing (from com-putare) literally means to
            reflect, to contemplate (putare) things in concert (com), without
            any explicit reference to numerical quantities. Indeed, I shall use
            this term in its most general sense to indicate any operation (not
            necessarily numerical) that transforms, modifies, rearranges,
            orders, and so on, observed physical entities ("objects") or their
            representations ("symbols"). For instance, the simple permutation of
            the three letters A, B, C, in which the last letter now goes
            first—C, A, B—I shall call a computation; similarly the operation
            that obliterates the commas between the letters—CAB—and likewise the
            semantic transformation that changes CAB into taxi, and so on.
          </span>
          The blockchain functions as a sort of computer, and computers, like
          magic, transform things. The blockchain transforms its users in the
          same way its users transform the blockchain. As dwellers of the
          blockchain, we are influenced by our environment in the same way the
          Moon affects the ocean tides and, as some would believe, our moods.
        </p>
        <h4 className="rs_section6">Horoscopic Astrology</h4>
        <p>
          The mapping of an celestial object to an individual based on its time
          and place of birth is similar to the principle of horoscopic astrology
          <sup
            id="reflink17"
            className="pointer"
            onClick={() => {
              scrollToRef(17);
            }}
          >
            [<span className="pointer">17</span>]
          </sup>
          , however on-chain astrotheology provides a scientifically updated
          astronomical chart which focuses mainly on deep sky objects such as
          nebulae, supernovae and star clusters, as opposed to the same old
          twelve constellations of the outdated zodiac system, and assigns the
          object to the individual based on their “time” and “place” on the
          blockchain, namely the transaction block number (“time”) and the
          minter’s address (“place”).
        </p>
        <p style={{ display: "flex", alignSelf: "center", maxWidth: "500px" }}>
          <img alt="blinkies" src={precession} />
        </p>
        <span className="go_caption">
          Axial precession, an unknown concept in the old zodiac system
        </span>
      </section>
      <span className="rs_marquee">
        <img alt="blinkies" src={marquee} />
      </span>
      <section className="rs_content">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Astronomical Imaging
        </h3>
        <h4 className="rs_section7">Image Processing</h4>
        <p>
          After the blockchain has sorted the properties of the god, such as its
          constellation and celestial object, the GodObserver™ algorithm works
          on fetching the preprocessed data from the telescope that creates the
          image of the god.
        </p>
        <p>
          One common misconception is that telescopes snap pictures of celestial
          bodies in the same way one snaps a selfie with their phones, that it
          clicks, instantaneously captures the light and returns the images we
          see from the Hubble Space Telescope. While part of the mechanics of
          astrophotography is similar to the way phone cameras work, an
          astronomical image is, simply put, produced by the combination of two
          or more datasets, (e.g. x-ray, infrared, radio, optical) layered on
          top of one another and colorized
          <sup
            id="reflink18"
            className="pointer"
            onClick={() => {
              scrollToRef(18);
            }}
          >
            [<span className="pointer">18</span>]
          </sup>
          , either according to its visible light frequency, scientific
          conventions or aesthetic preferences of whoever processes the data.
        </p>
        <p>
          The process is better explained in the paper,{" "}
          <i>The Aesthetics of Astrophysics</i> (A. Rector et al.)
          <sup
            id="reflink19"
            className="pointer"
            onClick={() => {
              scrollToRef(19);
            }}
          >
            [<span className="pointer">19</span>]
          </sup>
          :
          <span className="go_quote">
            To produce a color image it is necessary to have at least two data
            sets, and preferably three or more. A data set is defined as a
            two-dimensional image of a particular waveband, polarization, or
            other distinct characteristic, e.g., an optical image through a
            single filter, a radio image at or in a particular waveband and/or
            polarization, or an X-ray image over a specified energy range. [...]
            In brief, the process can be distilled into the following steps: (1)
            convert (or “project”) each data set into a grayscale image; (2)
            import these images as layers into an IP software package; (3)
            adjust the intensity scaling of each layer to better show detail
            (i.e., increase contrast in regions of interest); (4) assign a color
            to each layer; (5) fine tune the image, which includes overall color
            balance, the removal of residual artifacts, and orientation and
            framing; and (6) prepare the image for electronic distribution and
            print production.
          </span>
          Despite its origins in analogue photography, most of the space images
          we see are essentially colorized raw telescope data. The availability
          of this data, along with the accessibility of image processing
          software such as FITS Liberator, has made it possible for amateur
          astronomers and astrophotography enthusiasts around the world to read
          and interpret scientific data, and create their own astronomy images
          without necessarily owning or even using the expensive telescopes and
          gears that were previously necessary to achieve this.
        </p>
        <p style={{ display: "flex", alignSelf: "center", maxWidth: "500px" }}>
          <img alt="blinkies" src={fits} />
        </p>
        <span className="go_caption">The ESA/ESO/NASA FITS Liberator</span>
      </section>
      <span className="rs_marquee">
        <img alt="blinkies" src={marquee} />
      </span>
      <section className="rs_content">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Preprogrammed Pareidolia
        </h3>
        <h4 className="rs_section8">God Frequency</h4>
        <p>
          When manipulating raw data of astronomical images, our team of
          researchers discovered an unexplored and previously unknown frequency
          band, which we named the “god frequency”. This frequency band cannot
          be measured in Hertz or any unit we know so far, but can be detected
          by the naked human eye when processed through a method called
          “Preprogrammed Pareidolia”.
        </p>
        <h4 className="rs_section9">Pareidolia</h4>
        <p>
          Pareidolia can be defined as
          <sup
            id="reflink20"
            className="pointer"
            onClick={() => {
              scrollToRef(20);
            }}
          >
            [<span className="pointer">20</span>]
          </sup>
          :
          <span className="go_quote">
            […] the tendency for perception to impose a meaningful
            interpretation on a nebulous stimulus, usually visual, so that one
            sees an object, pattern, or meaning where there is none. Common
            examples are perceived images of animals, faces, or objects in cloud
            formations, seeing faces in inanimate objects, or lunar pareidolia
            like the Man in the Moon or the Moon rabbit.
          </span>
          To preprogram a pareidolia, we train an A.I. to scan astronomical
          images and detect the shapes and outlines of what we call “god
          archetypes” and use the vectors of their motions to manifest these
          gods on the image. Archetypes can be anthropomorphized or zoomorphized
          representations of deities, or heavenly events and phenomena. The
          observer sees then only the motions of the god archetype layered over
          its celestial body and the blurriness of these visions promote a
          feeling of pareidolia, in which they can only faintly envision god.
        </p>
        <h4 className="rs_section10">Motion Vectors</h4>
        <p>
          By discovering the god frequency band, GodObserver™ researchers were
          able to prove that astronomical images previously thought to be
          static, in fact hold hidden frequency data which can be translated
          into motion vectors that make the gods visible to the naked human eye.
        </p>
        <p>
          We are able to achieve this motion manifestation due to particular
          properties of digital video compression
          <sup
            id="reflink21"
            className="pointer"
            onClick={() => {
              scrollToRef(21);
            }}
          >
            [<span className="pointer">21</span>]
          </sup>
          , which consists of a combination of various video frame types:
        </p>
        <ul>
          <li>
            <b>I-frames</b> (Intra-coded picture) which contain information to
            the complete image, similarly to a JPG or BMP image file.
          </li>
          <li>
            <b>P-frames</b> (Predicted picture) which holds only the pixels that
            have changed from the previous frame. For example, in a scene where
            a car moves across a stationary background, only the car's movements
            need to be encoded. The encoder does not need to store the
            unchanging background pixels in the P‑frame, thus saving space.
          </li>
          <li>
            <b>B-frames</b> (Bidirectional predicted picture) saves even more
            space by using differences between the current frame and both the
            preceding and following frames to specify its content.
          </li>
        </ul>
        <p>
          For the creation of the GodObserver™ observations, we have conserved
          the astronomical image as I-frame and layered the god frequency as
          P-frames over it, making use of both image data and motion vector
          displacements, but mostly the latter, of the P-frames to reveal the
          “god archetypes” within the celestial objects.
        </p>
        <p>
          In the process of visualizing the data from the god frequency band,
          some lower vibration frequencies are translated into sound waves
          instead, which causes some GodObserver™ images to have sound effects
          applied to them, such as vibratos, chorus and high-pass filters. The
          translation of frequencies within the god frequency into sound to
          assist with the reading and interpreting of this data is known as
          "data sonification".
        </p>
      </section>
      <span className="rs_marquee">
        <img alt="blinkies" src={marquee} />
      </span>
      <section className="rs_content">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Data Sonification
        </h3>
        <h4 className="rs_section11">GodRemixer</h4>
        <p>
          Besides the visualization of the god frequency, one important step of
          deciphering the signals from the gods is the sonification process,
          defined as the act or process of producing sound. The sonification
          process is used to recreate the concept of <i>musica universalis</i>
          <sup
            id="reflink22"
            className="pointer"
            onClick={() => {
              scrollToRef(22);
            }}
          >
            [<span className="pointer">22</span>]
          </sup>
          , made popular by famed 16th-century astronomer Johannes Kepler, who
          devoted much of his time looking over charts and analyzing data to
          find the musical sound the he believed to be the true nature of the
          cosmos. In his work <i>Harmonices Mundi</i> (Harmony of the Worlds),
          Kepler reintroduces the concept of <i>musica universalis</i> and
          proposes that there is a correlation between musical intervals and
          harmonies and the motions of the six known planets of the time. He
          believed that this harmony, although inaudible, could be heard by
          one's soul, and that it gave a "very agreeable feeling of bliss,
          afforded him by this music in the imitation of God." With the purpose
          of further exploring this concept, the GodObserver™ researchers have
          developed a software called <span
            className="go_links pointer"
            onClick={() => {
              onToggleComponent("God Remixer", "open", id);
            }}
          >
            GodRemixer
          </span>.
        </p>
        <p>
          All users of the HeavenComputerOS have a Trial Version with limited
          features of the GodRemixer software pre-installed in their devices,
          and early and loyal users will receive a CD-ROM in the future
          containing a full version of the software which will allow them to
          save their remixes. Be mindful when using the GodRemixer app, this is
          new and experimental technology, and as such tech tends to be, the
          software may have bugs and run slow.
        </p>
        <p>
          With an interface inspired by the ESA/ESO/NASA FITS Liberator, a
          software that allows its users to interpret and edit FITS data files
          as if they were image files, the GodRemixer application allows its
          users to edit their god files as both image and audio files. The
          interface features two tabs, one for reading and editing{" "}
          <b>acoustics</b> data and another one for <b>optics</b> data. Since
          its main focus is acoustics, it hasn't yet been optimized for heavy
          optical data input and may operate slow when using certain optics
          algorithm. The optics algorithms exist mainly as auxiliaries for the
          interpretation of the acoustic data, and the acoustics algorithms will
          be further explained in the next sections.
        </p>
        <p>
          In order to transform a video into sound, we must first define which
          elements our eyes see when looking at a video and how to translate
          these elements into sound that can be perceived by our ears in a
          similar way that images are perceived by our eyes. These main elements
          can be narrowed down to four:
        </p>
        <ul>
          <li>
            Colors, or <b>Chromatic</b>
          </li>
          <li>
            Movement, or <b>Kinematic</b>
          </li>
          <li>
            Lights/Shadows, or <b>Chiaroscuro</b>
          </li>
          <li>
            Outlines, or <b>Edge Detect</b>
          </li>
        </ul>
        <h4 className="rs_section12">Chromatic</h4>
        <p>
          Chromatic — or that which is related to or produced by color, but
          could also mean that which gives all the tones of the chromatic
          musical scale
          <sup
            id="reflink23"
            className="pointer"
            onClick={() => {
              scrollToRef(23);
            }}
          >
            [<span className="pointer">23</span>]
          </sup>{" "}
          — is a self-explanatory term for how this algorithm works, as it
          translates the colors into a musical note within a scale, either the
          natural major (Ionian mode) or minor (Aeolian mode) scales. It
          achieves that by mapping the hue values as a 360 degree color wheel
          which is split into eight equal parts that represent each note of an
          octave. This note is then played by the <b>lead synth</b>.
        </p>
        <h4 className="rs_section13">Kinematic</h4>
        <p>
          Kinematics is a subfield of physics that describes the motion of
          points, objects, or system of objects. The vertical motion vectors
          detected in the video are used to map the notes played by the{" "}
          <b>bass</b>, and the horizontal motion vectors are used to map the
          panning (left and right audio channel distribution) of the{" "}
          <b>noise</b> sound.
        </p>
        <h4 className="rs_section14">Chiaroscuro</h4>
        <p>
          Chiaroscuro, Italian for 'light-dark', is a technical term used in
          arts for the use of contrasts between lights and shadow to evoke a
          sense of depth and volume or other subjective emotions. The shadows of
          the image are mapped to a <b>kick</b> instrument, whereas its
          highlights are mapped to a <b>percussion</b> instrument of higher
          frequency, generally a snare or rimshot.
        </p>
        <h4 className="rs_section15">Edge Detect</h4>
        <p>
          Edge detection is an image processing technique for outlining the
          boundaries of objects within a static image or video. Whenever a sound
          particle touches these outlines, they trigger a note that is played by
          the <b>pad</b> instrument.
        </p>
      </section>
      <span className="rs_marquee">
        <img alt="blinkies" src={marquee} />
      </span>
      <section className="rs_content rs_section16">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Conclusion
        </h3>
        <p>
          If the cutting-edge technology known as <i>on-chain astrotheology</i>{" "}
          proves to be successful, the GodObserver™ project, along with its
          Telescope, GodRemixer and all its accompanying tech, will be the first
          SETI program to combine the methods of blockchain sortilege,
          astronomical imaging, preprogrammed pareidolia, and data sonification
          to observe the gods that surely exist in the skies. These methods and
          the speculations that motivated their inception may currently sound
          whimsical, delusional, ludicrous even. Yet if one day one or more of
          methods or even the concept behind this research may prove to be
          infallible, then the ideas behind this manifesto may be regarded as
          intellectually conventional, even morally urgent — albeit today
          fanciful. For as the technological revolution of space exploration
          unfolds, what might once have been the stuff of millennialist fantasy
          is set to become a scientifically feasible research program. Its
          adoption or rejection will become, ultimately, a social policy issue.
          Passively or actively, we will have to choose just how much of space
          and its dwellers we wish to conquer or conserve — if any — in eras to
          come.
        </p>
      </section>
      <span className="rs_marquee">
        <img alt="blinkies" src={marquee} />
      </span>
      <section className="rs_content rs_section17">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          References
        </h3>
        <ol>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://www.jstor.org/stable/3556368"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ancient Atomists on the Plurality of Worlds
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://www.worldscientific.com/doi/10.1142/9789814616980_0010"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Search for Our Cosmic Ancestry
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://blogs.scientificamerican.com/observations/was-giordano-bruno-burned-at-the-stake-for-believing-in-exoplanets/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Was Giordano Bruno Burned at the Stake for Believing in
              Exoplanets?
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://books.google.pt/books?id=mPIgAAAAMAAJ"
              target="_blank"
              rel="noopener noreferrer"
            >
              A Short History of Science to the Nineteenth Century
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://en.wikipedia.org/wiki/Dialogue_Concerning_the_Two_Chief_World_Systems"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dialogue Concerning the Two Chief World Systems
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://www.ipl.org/essay/Science-And-Enlightenment-The-Enlightenment-And-The-PJCCDZW24FU"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Age Of Enlightenment: Copernicus And Galileo
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://www.historytoday.com/archive/george-stephensons-first-steam-locomotive"
              target="_blank"
              rel="noopener noreferrer"
            >
              George Stephenson's First Steam Locomotive
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://www.nickbostrom.com/ethics/values.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              What is Transhumanism?
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://en.wikipedia.org/wiki/Clarke%27s_three_laws"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clarke's Three Laws
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://www.simulation-argument.com/simulation.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Simulation Argument
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://breakthroughinitiatives.org/initiative/1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Breakthrough Listen
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://setiathome.berkeley.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              SETI@home
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://en.wikipedia.org/wiki/Stone_Tape"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stone Tape Theory
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://en.wikipedia.org/wiki/Volunteer_computing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Volunteer Computing
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://en.wikipedia.org/wiki/Sortes_(ancient_Rome)"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sortes (ancient Rome)
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://books.google.pt/books?id=Ueiv9cRR9OQC&pg=PA217"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cybersemiotics: Why Information is Not Enough!
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://en.wikipedia.org/wiki/Horoscopic_astrology"
              target="_blank"
              rel="noopener noreferrer"
            >
              Horoscopic Astrology
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://chandra.harvard.edu/imaging/resources.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Image Processing
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://iopscience.iop.org/article/10.1088/1538-3873/aa5457/pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Aesthetics of Astrophysics
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://en.wikipedia.org/wiki/Pareidolia"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pareidolia
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://en.wikipedia.org/wiki/Video_compression_picture_types"
              target="_blank"
              rel="noopener noreferrer"
            >
              Video Compression
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://en.wikipedia.org/wiki/Musica_universalis"
              target="_blank"
              rel="noopener noreferrer"
            >
              Musica Universalis
            </a>
          </li>
          <li>
            <span
              onClick={(e) => {
                scrollFromRef(e.target);
              }}
              className="pointer"
            >
              ^
            </span>
            <a
              className="pointer"
              href="https://en.wikipedia.org/wiki/Chromatic_scale"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chromatic Scale
            </a>
          </li>
        </ol>
      </section>
      <span className="rs_marquee">
        <img alt="blinkies" src={marquee} />
      </span>
      <section className="rs_content rs_section18">
        <h3>
          <img alt="blinkies" src={bullet} style={{ marginRight: "6px" }} />
          Footnotes
        </h3>
        Thank you for reading. If you would like to cite this scientific
        article, please use one of the following blinkies and link back to my
        page:
        <img className="blinkies" alt="blinkies" src={godblinkie} />
        <br />
        <img className="blinkies" alt="blinkies" src={godbutton} />
        <br />
        <img className="blinkies" alt="blinkies" src={godtag} />
        <span>
          And don't forget to sign the{" "}
          <a
            className="pointer"
            href="https://discord.gg/heavencomputer"
            target="_blank"
            rel="noopener noreferrer"
          >
            guestbook
          </a>
          . <img alt="blinkies" src={book} />
        </span>
      </section>
      <span className="rs_marquee">
        <img alt="blinkies" src={marquee} />
      </span>
      <section className="rs_sectionfoot">
        <div id="rs_warn">
          <img alt="blinkies" src={constr} />
          <div>
            <span>
              <b>Warning</b> this page is currently under construction and may
              stay so forever.
            </span>
            <span>
              <b>Warning</b> the content on this page may or may not be
              scientifically accurate, depends how I'm feeling.
            </span>
          </div>
          <img alt="blinkies" src={atom} />
        </div>
        <b>
          This page printed on recycled electrons. Conserve charge parity — It's
          the Law!
        </b>
        <div>
          <img alt="blinkies" src={netscape} />
        </div>
      </section>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 1em;
  line-height: 16px;
  letter-spacing: 1px;
  padding: 20px;
  section p {
    line-height: 14px;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .go_caption {
    align-self: center;
    color: var(--colorgrad5);
  }
  .li_highlight {
    background: rgba(255,255,255,0.2);
  }
  .go_quote {
    display: block;
    padding: 10px;
    font-style: italic;
    &:before {
      content: "« ";
    }
    &:after {
      content: " »";
    }
  }
  h4 {
    padding: 10px 0;
  }
  sup {
    font-size: .83em;
    vertical-align: super;
  }
  sup:hover span {
    color: #b19cd9;
    border-bottom: 1px dashed white;
  }
  p + p {
    margin-top: 10px;
  }
  ol,
  ul {
    margin-left: 40px;
  }
  ul + p, ol + p, p + ul, ul + h4, p + ol {
    margin-top :10px;
  }
  li + li {
    margin-top: 5px;
  }
  li span {
    font-weight: 800;
    color: var(--colorgrad5);
    margin-right: 4px;
  }
  li span:hover {
    color: white;
  }
  img {
    max-width: 100%;
  }
  .rs_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px 0;
  }
  .rs_sectionfoot {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    b + div {
      padding-top: 10px;
    }
    b {
      text-align: center;
    }
  }
  .rs_marquee {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    div {
      align-self: center;
      width: 80%;
      border-bottom: 1px dashed white;
    }
  }
  #rs_warn {
    width: 80%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    span {
      text-align: center;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
`;
