import { PALETTE } from "../constants";
import { nearest } from "../palettes";
import {
  cloneCanvas,
  fillBufferPixel,
  getBufferIndex,
  rgba
} from "../utils";


const optionTypes = {
  palette: { type: PALETTE, default: nearest }
};

const defaults = {
  palette: optionTypes.palette.default
};

const noise = (input,options) => {
  const output = cloneCanvas(input, false);

  const inputCtx = input.getContext("2d", {
  willReadFrequently: true
});
  const outputCtx = output.getContext("2d", {
  willReadFrequently: true
});

  if (!inputCtx || !outputCtx) {
    return input;
  }

  const buf = inputCtx.getImageData(0, 0, input.width, input.height).data;

  for (let x = 0; x < input.width; x += 1) {
    for (let y = 0; y < input.height; y += 1) {
      const i = getBufferIndex(x, y, input.width);
        const r = buf[i] + (Math.random() - 0.5) * 255;
        const g = buf[i + 1] + (Math.random() - 0.5) * 255;
        const b = buf[i + 2] + (Math.random() - 0.5) * 255;
        const color = options.palette.getColor(rgba(r, g, b, buf[i + 3]), options.palette.options);
        fillBufferPixel(buf, i, color[0], color[1], color[2], buf[i + 3]);
      }
    }

  outputCtx.putImageData(new ImageData(buf, output.width, output.height), 0, 0);
  return output;
};

const defaultFunc = {
  name: "Noise",
  func: noise,
  options: defaults,
  optionTypes,
  defaults
};

export default defaultFunc;
