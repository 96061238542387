import React, { useReducer, useRef, useEffect, useGlobal, setGlobal } from "reactn";
import styled from "styled-components";
import { Icons, OptionsMenu } from "components";
import optionsMenuData from "./optionsMenuData";
import { useMouse } from "react-use";
import { FOCUS_ICON, SELECT_ICONS, FOCUSING, Reducer } from "store";
import { appSettings, diskDriveIcons } from "apps";
import drive from "assets/icons/cddrive.ico";

const DiskDrive = ({ id, onClose, onDoubleClickIcon, onToggleComponent }) => {
  const initState = {
    focusing: FOCUSING.WINDOW,
    icons: diskDriveIcons,
    selecting: false,
  };
  const [cdMounted] = useGlobal("cdMounted");
  const [state, dispatch] = useReducer(Reducer, initState);
  const ref = useRef(null);
  const mouse = useMouse(ref);

  useEffect(() => {
    if(!cdMounted) onClose();
  }, [cdMounted, onClose]);

  useEffect(() => {
    optionsMenuData["Edit"][0].disable = state.focusing !== FOCUSING.WINDOW;
    optionsMenuData["Edit"][1].disable = state.focusing === FOCUSING.WINDOW;
  }, [state.focusing]);

  function onClickOptionItem(item) {
    switch (item.text) {
      case "Select All":
        onSelectAll();
        break;
      case "Select None":
        onSelectNone();
        break;
      case "Close":
        onClose();
        break;
      case "Help Center":
        onToggleComponent("Help", "open", id)
        break;
      default:
    }
  }
  function onSelectNone(id) {
    state.focusing = FOCUSING.WINDOW;
  }
  function onPointerDownIcon(id) {
    setGlobal({ cdCorrupted: "broken" });
    dispatch({ type: FOCUS_ICON, payload: id });
  }
  function onSelectAll() {
    let iconIds = [...Array(state.icons.length).keys()];
    dispatch({ type: SELECT_ICONS, payload: iconIds });
  }
  return (
    <Div>
      <section className="window_toolbar">
        <OptionsMenu items={optionsMenuData} onClickItem={onClickOptionItem} />
      </section>
      <div className="divider"></div>
      <section className="address_bar">
        <div className="div_bar"></div>
        <div className="address_bar_title">
          A<u>d</u>dress
        </div>
        <div className="address_bar_content">
          <div className="address_bar_content_text">
            <img draggable={false} src={drive} alt="favicon" className="address_bar_content_img" />
            <span>heaven://diskdrive/{cdMounted}</span>
            <div className="address_bar_buttons">
              <div className="address_bar_go"><div /></div>
            </div>
          </div>
        </div>
      </section>
      <div className="window_content">
        <div className="window_content_inner">
          <Icons
            txtColor={'black'}
            flexFlow={'row'}
            overflow={'auto'}
            height={'100%'}
            icons={state.icons}
            onPointerDown={onPointerDownIcon}
            onDoubleClick={onDoubleClickIcon}
            displayFocus={state.focusing === FOCUSING.ICON}
            appSettings={appSettings}
            mouse={mouse}
            selecting={state.selecting}
          />
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  background: var(--colorgrad3);
  .window_content_inner {
    height: calc(100% - 4px);
  }
`;

export default DiskDrive;
