import React, { useEffect, useState } from "react";
import loading from "assets/god/ui/loading.gif";

const GodImage = ({ token, baseURI, ipfsHash, id }) => {
  const [imgURL, setImgURL] = useState(null);

  useEffect(() => {
    if (imgURL && token) {
      document.getElementById("loaderimg" + token + id).style.display = "block";
      document.getElementById("token" + token + id).style.display = "none";
    } else {
      document.getElementById("loaderimg" + token + id).style.display = "none";
      document.getElementById("token" + token + id).style.display = "block";
    }
  }, [imgURL, token, id]);

  useEffect(() => {
    setImgURL(null)
    if (token !== undefined && baseURI !== undefined) {
      if (ipfsHash && ipfsHash !== "loading...") {
        setImgURL("https://heaven.mypinata.cloud/ipfs/" + ipfsHash);
      } else {
        if (ipfsHash && ipfsHash === 'loading...') {
          document.getElementById("loaderimg" + token + id).style.display = "block";
          document.getElementById("token" + token + id).style.display = "none";
        } else {
          if (!ipfsHash) setImgURL(baseURI + "/god/" + token);
        }
      }
    }
  }, [token, baseURI, ipfsHash, id]);

  return (
    <div style={{ position: "relative" }}>
      <img
        draggable={false}
        style={{
          zIndex: 1,
          objectFit: "none",
        }}
        alt={"token" + token}
        className={"go_token_image goti"+id}
        id={"loaderimg" + token + id}
        src={loading}
      />
      <video
        autoPlay
        loop
        muted
        controls={false}
        playsInline
        preload="auto"
        className={"go_token_image shrine_gif goti"+id}
        onError={() => {
          document.getElementById("token" + token + id).src = "https://api.heaven.computer/god/" + token;
        }}
        onLoadedData={() => {
          document.getElementById("loaderimg" + token + id).style.display = "none";
          document.getElementById("token" + token + id).style.display = "block";
        }}
        style={{ zIndex: 2, display: "none" }}
        id={"token" + token + id}
        src={imgURL}
      />
    </div>
  );
};

export default GodImage;
