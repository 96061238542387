export class Star {
  constructor(context) {
    let w = document.body.clientWidth;
    let h = document.body.clientHeight;
    this.x = Math.random() * w;
    this.y = Math.random() * h;
    this.color = `hsla(${360 * Math.random()},90%,90%, ${Math.random()})`;
    this.context = context;
    this.radius = Math.random() * 1;
    this.originalRadius = this.radius;
    this.maxDuration = Math.abs(Math.random() * 200) + 50;
    this.timing = 0;
    this.expanding = true;
  }

  update(t) {
    if (!this.expanding) {
      this.radius -= this.originalRadius / this.maxDuration;
      this.timing--;
    } else {
      this.radius += this.originalRadius / this.maxDuration;
      this.timing++;
    }

    if (this.timing === 0) {
      this.expanding = true;
    } else if (this.timing >= this.maxDuration) {
      this.expanding = false;
    }

    this.draw();
  }

  draw(x = this.x, y = this.y, radius = this.radius, color = this.color) {
    const c = this.context;
    c.save();
    c.beginPath();
    c.arc(x, y, radius, 0, Math.PI * 2, false);
    c.fillStyle = color;
    c.fill();
    c.closePath();
    c.restore();
  }
}

export class ShootingStar extends Star {
  constructor(context, radius, speed, trailLength, color) {
    super();
    this.context = context;
    this.y = (this.y / 5) * Math.random();
    this.originalPosition = {
      x: this.x,
      y: this.y,
    };
    this.color = color;
    this.radius = radius;
    this.speed = speed;
    this.trailLength = trailLength;
  }

  update() {
    if (this.x > window.innerWidth + this.radius + this.trailLength) {
      this.x = -this.radius * 10;
    }
    if (this.y > window.innerHeight + this.radius + this.trailLength) {
      this.y = -this.radius * 10;
    }
    this.draw(this.x, this.y, this.radius * 1.5, "rgba(255, 255, 255, 0.2");
    for (let i = 0; i <= this.trailLength; i++) {
      this.draw(
        this.x - i * 2,
        this.y - i * 2,
        this.radius - (i * this.radius) / (this.trailLength * 1.7),
        this.color
      );
    }
    this.speed += 0.01;
    this.x += this.speed;
    this.y += this.speed;
  }
}
