import React, { useReducer, useRef, useEffect } from "reactn";
import styled from "styled-components";
import { Icons, OptionsMenu } from "components";
import optionsMenuData from "./optionsMenuData";
import { useMouse } from "react-use";
import { FOCUS_ICON, SELECT_ICONS, FOCUSING, Reducer } from "store";
import { appSettings, recycleBinIcons } from "apps";
import bin0 from "assets/icons/bin0.ico";
import bin1 from "assets/icons/bin1.ico";

const RecycleBin = ({ onClose, onDoubleClickIcon, onToggleComponent, id }) => {
  const initState = {
    focusing: FOCUSING.WINDOW,
    icons: recycleBinIcons,
    selecting: false,
  };

  const [state, dispatch] = useReducer(Reducer, initState);
  const ref = useRef(null);
  const mouse = useMouse(ref);

  useEffect(() => {
    onSelectNone()
    appSettings.RecycleBin.header.icon = recycleBinIcons.length > 0 ? bin1 : bin0;
    state.icons = recycleBinIcons;
    optionsMenuData["Bin"][0].disable = recycleBinIcons.length === 0;
    // eslint-disable-next-line
  }, [recycleBinIcons.length]);

  useEffect(() => {
    optionsMenuData["Edit"][0].disable = recycleBinIcons.length === 0 || state.focusing !== FOCUSING.WINDOW;
    optionsMenuData["Edit"][1].disable = state.focusing === FOCUSING.WINDOW;
    // eslint-disable-next-line
  }, [recycleBinIcons.length, state.focusing]);

  function onClickOptionItem(item) {
    switch (item.text) {
      case "Empty":
        onEmpty();
        break;
      case "Select All":
        onSelectAll();
        break;
      case "Select None":
        onSelectNone();
        break;
      case "Close":
        onClose();
        break;
      case "Help Center":
        onToggleComponent("Help", "open", id);
        break;
      default:
    }
  }
  function onEmpty() {
    onSelectNone();
    recycleBinIcons.splice(0, recycleBinIcons.length);
  }
  function onSelectNone() {
    state.focusing = FOCUSING.WINDOW;
  }
  function onPointerDownIcon(id) {
    dispatch({ type: FOCUS_ICON, payload: id });
  }
  function onSelectAll() {
    let iconIds = [...Array(state.icons.length).keys()];
    dispatch({ type: SELECT_ICONS, payload: iconIds });
  }
  return (
    <Div>
      <section className="window_toolbar">
        <OptionsMenu items={optionsMenuData} onClickItem={onClickOptionItem} />
      </section>
      <div className="divider"></div>
      <section className="address_bar">
        <div className="div_bar"></div>
        <div className="address_bar_title">
          A<u>d</u>dress
        </div>
        <div className="address_bar_content">
          <div className="address_bar_content_text">
            <img
              draggable={false}
              src={appSettings.RecycleBin.header.icon}
              alt="favicon"
              className="address_bar_content_img"
            />
            <span>heaven://recyclebin</span>
            <div className="address_bar_buttons">
              <div className="address_bar_go"><div /></div>
            </div>
          </div>
        </div>
      </section>
      <div className="window_content">
        <div className="window_content_inner">
          <Icons
            flexFlow={'row'}
            overflow={'auto'}
            height={'100%'}
            width={'100%'}
            txtColor={'black'}
            icons={state.icons}
            onPointerDown={onPointerDownIcon}
            onDoubleClick={onDoubleClickIcon}
            displayFocus={state.focusing === FOCUSING.ICON}
            appSettings={appSettings}
            mouse={mouse}
            selecting={state.selecting}
          />
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  background: var(--colorgrad3);
  .window_content_inner {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    overflow: hidden;
  }
  .window_content {
    overflow: hidden;
  }
`;

export default RecycleBin;
