const View = [
  {
    type: 'item',
    text: 'Lobby',
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Back',
    disable: true,
  },
  {
    type: 'item',
    text: 'Forward',
    disable: true,
  },
];
const Residence = [
  {
    type: 'item',
    text: 'All Tokens',
  },
  {
    type: 'item',
    text: 'My Tokens',
  },
];

const Random = [
  {
    type: 'item',
    text: 'Token Residence',
  },
  {
    type: 'item',
    text: 'Sky Residence',
  },
];
const Help = [
  {
    type: 'item',
    text: 'About...',
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Help Center',
    disable: false,
  },
];
const Edit = [
  {
    type: 'item',
    text: 'Select All',
    disable: false,
  },
  {
    type: 'item',
    text: 'Select None',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Close',
    disable: false,
  },
];

const optionsMenuData = { View, Residence, Random, Help, Edit };
export default optionsMenuData;
