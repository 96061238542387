const random = (min, max) => Math.round(Math.random() * (max - min + 1)) + min;

export const initWallGradients = (canvasName) => {
  const presets = generatePresets();
  const sceneColors = presets.flatMap(p => p.colors);
  const sixHues = findMostDifferentHues(sceneColors, 6);
  const fourHues = findMostDifferentHues(sceneColors, 4);
  const twoHues = findMostDifferentHues(sceneColors, 2);
  const gradArr = [];
  const blendArr = [];
  const propArr = [];
  for (let i = 0; i < presets.length; i++) {
    const { gradient, blendMode, properties } = generateGradient(i, presets[i]);
    gradArr.push(gradient);
    blendArr.push(blendMode);
    propArr.push(properties);
  }
  let gradStr = "background-image: var(--shade), " + gradArr.join(", ");
  let blendStr = "background-blend-mode: normal, " + blendArr.join(", ");
  const animCss = generateAnimations(presets, { ...propArr.reduce((acc, obj) => ({ ...acc, ...obj }), {}) });
  let sphereCss = "";
  let polyCss = ".poly {";
  let contCss = ".content {";
  twoHues.forEach((color, i) => {
    polyCss += `--polyColor${i}: ${color};`;
  })
  sixHues.forEach((color, i) => {
    contCss += `--textColor${i + 1}: ${color};`;
  })
  fourHues.forEach((color, i) => {
    sphereCss += `.sphere:nth-of-type(${i + 1}){--sphereSize:${Math.random() * (0.2 - 1) + 1} !important;--sphereBgColor: ${color} !important;--sphereTranslateY:${(Math.random() * (-3 - 4) + 4).toFixed(2)}em !important}`;
    sphereCss += `.sphere:nth-of-type(${fourHues.length - i}){--sphereHue: ${hexToHwb(color)[0]} !important;}`;
  })

  const initCss = `${polyCss}} ${contCss}} ${sphereCss} ${canvasName} {${animCss.props} ${gradStr}; ${blendStr};`;
  const css = `${animCss.defs} ${initCss} animation-name: ${animCss.animations.names}; animation-iteration-count: infinite; animation-timing-function: ${animCss.animations.timing}; animation-duration: ${animCss.animations.dur};} ${animCss.keyframes}`;
  return css;
}

// GENERATE FUNCTIONS
const hexToRGB = (hex) => {
  hex = hex.replace(/^#/, '');
  let r, g, b;
  if (hex.length === 3) {
    r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
    g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
    b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    return;
  }
  return [r, g, b];
}
const rgbToHwb = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;
  const w = (1 - max) / 1;
  const b_ = 1 - w;

  let h = 0;
  if (delta !== 0) {
    if (max === r) {
      h = ((g - b) / delta) % 6;
    } else if (max === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }
  }
  h = Math.round(h * 60);

  return [h, w * 100, b_ * 100];
};

// Convert HEX to HWB
const hexToHwb = (hex) => {
  const rgb = hexToRGB(hex);
  return rgbToHwb(...rgb);
};
const rgbToHSL = (r, g, b) => {
  const max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;
  if (max === min) {
    h = s = 0;
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
      default: h = 0;
    }
    h /= 6;
  }
  return [h, s, l];
}
const findMostDifferentHues = (colors, num) => {
  let maxDifference = 0;
  let mostDifferent = [];
  const hues = colors.map(hex => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return rgbToHSL(r, g, b)[0] * 360;
  });

  const calculateTotalDifference = (huesArray) => {
    let totalDifference = 0;
    for (let i = 0; i < huesArray.length; i++) {
      for (let j = i + 1; j < huesArray.length; j++) {
        let diff = Math.abs(huesArray[i] - huesArray[j]);
        diff = Math.min(diff, 360 - diff);
        totalDifference += diff;
      }
    }
    return totalDifference;
  };

  const getCombinations = (arr, k) => {
    let i, j, combs, head, tailcombs;
    if (k > arr.length || k <= 0) {
      return [];
    }
    if (k === arr.length) {
      return [arr];
    }
    if (k === 1) {
      combs = [];
      for (i = 0; i < arr.length; i++) {
        combs.push([arr[i]]);
      }
      return combs;
    }
    combs = [];
    for (i = 0; i < arr.length - k + 1; i++) {
      head = arr.slice(i, i + 1);
      tailcombs = getCombinations(arr.slice(i + 1), k - 1);
      for (j = 0; j < tailcombs.length; j++) {
        combs.push(head.concat(tailcombs[j]));
      }
    }
    return combs;
  };

  const hueCombinations = getCombinations(hues, num);

  hueCombinations.forEach(combination => {
    const totalDifference = calculateTotalDifference(combination);
    if (totalDifference > maxDifference) {
      maxDifference = totalDifference;
      mostDifferent = combination.map(hue => {
        const index = hues.indexOf(hue);
        return colors[index].slice(0, 7);
      });
    }
  });

  return mostDifferent;
};


const generatePresets = () => {
  const numGradients = 3;
  const scheme = colorSchemes[Math.floor(Math.random() * colorSchemes.length)];
  const stopPosFunction = stopPosFunctions[Math.floor(Math.random() * stopPosFunctions.length)];

  // PRESETS HELPER FUNCTIONS
  const getColorFromScheme = (scheme, first) => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 60;
    const lightness = 70;
    const colorPalette = (p, h, s, l) => {
      const hslToHex = (f, h, s, l) => {
        l /= 100;
        const a = s * Math.min(l, 1 - l) / 100;
        const c = n => {
          const k = (n + h / 30) % 12;
          const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
          return Math.round(255 * color).toString(16).padStart(2, '0');
        };
        const alpha = Math.round(Math.random() * (255 - 200) + 200).toString(16).padStart(2, '0');
        return `#${c(0)}${c(8)}${c(4)}${f ? "" : alpha}`;
      }
      switch (p) {
        case "analogous":
          return [hslToHex(first, h, s, l), hslToHex(first, h + 30, s, l), hslToHex(first, h - 30, s, l)];
        case "complementary":
          return [hslToHex(first, h, s, l), hslToHex(first, (h + 180) % 360, s, l)];
        case "split complementary":
          const complement = (h + 180) % 360;
          return [hslToHex(first, h, s, l), hslToHex(first, complement - 30, s, l), hslToHex(first, complement + 30, s, l)];
        case "triadic":
          return [hslToHex(first, h, s, l), hslToHex(first, (h + 120) % 360, s, l), hslToHex(first, (h + 240) % 360, s, l)];
        case "tetradic":
          return [
            hslToHex(first, h, s, l),
            hslToHex(first, (h + 180) % 360, s, l),
            hslToHex(first, (h + 90) % 360, s, l),
            hslToHex(first, (h + 270) % 360, s, l)
          ];
        case "monochromatic":
          return [hslToHex(first, h, s, l), hslToHex(first, h, s, Math.max(0, l - 20)), hslToHex(first, h, s, Math.min(100, l + 20))];
        case "square":
          return [hslToHex(first, h, s, l), hslToHex(first, (h + 90) % 360, s, l), hslToHex(first, (h + 180) % 360, s, l), hslToHex(first, (h + 270) % 360, s, l)];
        case "rectangle":
          return [hslToHex(first, h, s, l), hslToHex(first, (h + 60) % 360, s, l), hslToHex(first, (h + 180) % 360, s, l), hslToHex(first, (h + 240) % 360, s, l)];
        case "shades":
          return [hslToHex(first, h, s, l), hslToHex(first, h, s, l - 15), hslToHex(first, h, s, l - 30)];
        case "tints and tones":
          return [hslToHex(first, h, s, l), hslToHex(first, h, Math.max(0, s - 20), l + 20), hslToHex(first, h, s + 20, l - 20)];
        case "high contrast":
          return [hslToHex(first, h, s, l), hslToHex(first, (h + 90) % 360, s, 100 - l)];
        case "luminance gradient":
          return [hslToHex(first, h, s, l), hslToHex(first, h, s, Math.max(0, l - 15)), hslToHex(first, h, s, Math.min(100, l + 15))];
        default:
          return "unknown";
      }
    };
    const colors = colorPalette(scheme, hue, saturation, lightness);
    return colors[Math.floor(Math.random() * colors.length)];
  }

  const randomConicSettings = (scheme, stopPosFunction, numColorStops, oldCx, oldCy) => {
    let color = [];
    for (let i = 0; i < numColorStops; i++) color.push(getColorFromScheme(scheme, false))
    let cx = `${Math.floor(Math.random() * 101)}%`;
    let cy = `${Math.floor(Math.random() * 101)}%`;
    if (oldCx && (cx === oldCx || cy === oldCy)) ({ cx, cy } = randomConicSettings(stopPosFunction, numColorStops, cx, cy));
    return {
      startAngle: `${Math.floor(Math.random() * 360)}deg`,
      endAngle: `${Math.floor(Math.random() * 360)}deg`,
      cx: `${Math.floor(Math.random() * 101)}%`,
      cy: `${Math.floor(Math.random() * 101)}%`,
      color
    }
  }
  const randomLinearSettings = (oldAngle) => {
    const linearAngleOptions = [
      `${Math.floor(Math.random() * 360)}deg`,
      `${Math.random().toFixed(2)}turn`,
      `${(Math.random() * Math.PI).toFixed(2)}rad`,
    ];
    let angle = linearAngleOptions[Math.floor(Math.random() * linearAngleOptions.length)]
    if (oldAngle && oldAngle === angle) angle = randomLinearSettings(angle)
    return { angle }
  }

  const getStops = (numColorStops, stopPosFunction) => {
    let allPos = []
    let positions = [];
    for (let j = 0; j < numColorStops; j++) {
      let position = (j / (numColorStops - 1)) * 100;
      switch (stopPosFunction) {
        case "random":
          for (let i = 0; i < numColorStops; i++) {
            positions.push(Math.random());
          }
          positions.sort();
          position = positions[j] * 100;
          break;
        case "ease":
          const tEase = j / (numColorStops - 1);
          position = tEase * tEase * (3 - 2 * tEase) * 100;
          break;
        case "bezier":
          const tBezier = j / (numColorStops - 1);
          const c1 = (1 - tBezier) ** 3;
          const c2 = 3 * (1 - tBezier) ** 2 * tBezier;
          const c3 = 3 * (1 - tBezier) * tBezier ** 2;
          const c4 = tBezier ** 3;
          position = c1 * (Math.random() * 2 - 1).toFixed(2) + c2 * (Math.random() * 2 - 1).toFixed(2) + c3 * (Math.random() * 2 - 1).toFixed(2) + c4 * (Math.random() * 2 - 1).toFixed(2) * 100;
          break;
        default:
          position = (j / (numColorStops - 1)) * 100;
      }
      allPos.push(Math.round(position));
    }
    return allPos;
  }
  let presets = []
  for (let i = 0; i < numGradients; i++) {
    let colors = []
    let newColors = []
    let newStops = []
    const filteredBlendModes = blendModes.filter(e => e !== "normal");
    const gradientType = gradientTypes[Math.floor(Math.random() * gradientTypes.length)];
    const blendMode = filteredBlendModes[Math.floor(Math.random() * filteredBlendModes.length)];
    const numColorStops = random(3, 6);
    const settings = gradientType.includes("linear") ? randomLinearSettings() : randomConicSettings(scheme, stopPosFunction, numColorStops);
    const newSettings = gradientType.includes("linear") ? randomLinearSettings(settings.angle) : randomConicSettings(scheme, stopPosFunction, numColorStops, settings.cx, settings.cy);
    for (let j = 0; j < numColorStops; j++) colors.push(getColorFromScheme(scheme, i === 0))
    for (let j = 0; j < numColorStops; j++) newColors.push(getColorFromScheme(scheme, i === 0))
    for (let j = 0; j < numColorStops; j++) newStops.push(Math.floor(Math.random() * 101))
    const stops = getStops(numColorStops, stopPosFunction);
    const duration = random(15, 60) + "s";
    const bezier = [(Math.random() * 2 - 1).toFixed(2), (Math.random() * 2 - 1).toFixed(2), (Math.random() * 2 - 1).toFixed(2), (Math.random() * 2 - 1).toFixed(2)];
    presets.push({
      bezier,
      duration,
      gradientType,
      blendMode,
      numColorStops,
      settings,
      stopPosFunction,
      colors,
      newSettings,
      newColors,
      stops,
      newStops: newStops.sort()
    })
  }
  return presets;
}

const generateGradient = (i, preset) => {
  const { gradientType, blendMode, numColorStops, settings, stopPosFunction } = preset;
  const suffix = (gradientType, gradientIndex, stopIndex = "0") => `${gradientType.split('-').map(g => g.charAt(0)).join('')}-${gradientIndex}-${stopIndex}`;
  const angleVar = '--angle-0-';
  const angleAltVar = '--angle-1-';
  const centerXVar = '--percentage-cx-';
  const centerYVar = '--percentage-cy-';
  const shapeRadius = '--length-s-';
  const colorVar = '--color-';
  const stopsVar = '--percentage-s-';
  const properties = {};
  let gradient = gradientType + "(";
  if (gradientType.includes("linear")) {
    const { angle } = settings;
    const angleProp = `${angleVar + angle.replace(/[^a-zA-Z]+/g, '')}-${suffix(gradientType, i)}`;
    properties[angleProp] = angle;
    gradient += `var(${angleProp}), `;
  } else if (gradientType.includes("radial")) {
    const { size, shape, cx, cy } = settings;
    if (shape === "circle") {
      properties[shapeRadius + suffix(gradientType, i)] = size;
      gradient += `${shape} var(${shapeRadius + suffix(gradientType, i)}) `;
    } else {
      properties[shapeRadius + suffix(gradientType, i, "0")] = size.split(" ")[0];
      properties[shapeRadius + suffix(gradientType, i, "1")] = size.split(" ")[1];
      gradient += `${shape} var(${shapeRadius + suffix(gradientType, i, "0")}) var(${shapeRadius + suffix(gradientType, i, "1")}) `;
    }
    properties[centerXVar + suffix(gradientType, i)] = cx;
    properties[centerYVar + suffix(gradientType, i)] = cy;
    gradient += `at var(${centerXVar + suffix(gradientType, i)}) var(${centerYVar + suffix(gradientType, i)}), `;
  } else if (gradientType.includes("conic")) {
    const { startAngle, endAngle, cx, cy, color } = settings;
    properties[angleVar + startAngle.replace(/[^a-zA-Z]+/g, '') + "-" + suffix(gradientType, i)] = startAngle;
    gradient += `from var(${angleVar + startAngle.replace(/[^a-zA-Z]+/g, '') + "-" + suffix(gradientType, i)}) `;
    properties[centerXVar + suffix(gradientType, i)] = cx;
    properties[centerYVar + suffix(gradientType, i)] = cy;
    gradient += `at var(${centerXVar + suffix(gradientType, i)}) var(${centerYVar + suffix(gradientType, i)}), `;
    let prevAngle = 0;
    if (stopPosFunction === "hard") {
      properties[angleAltVar + "deg-" + suffix(gradientType, i)] = endAngle;
      properties[angleAltVar + "deg-" + suffix(gradientType, i)] = endAngle;
      properties[colorVar + suffix(gradientType, i)] = color;
      gradient += `var(${colorVar + suffix(gradientType, i)}) var(${angleAltVar + "deg-" + suffix(gradientType, i)}), var(${colorVar + suffix(gradientType, i)}) var(${angleAltVar + "deg-" + suffix(gradientType, i)}), `;
    } else {
      for (let j = 0; j < numColorStops; j++) {
        const angle = `${prevAngle + parseInt(endAngle)}deg`;
        properties[angleAltVar + "deg-" + suffix(gradientType, i, j)] = angle;
        properties[colorVar + suffix(gradientType, i, j)] = color[j];
        gradient += `var(${colorVar + suffix(gradientType, i, j)}) var(${angleAltVar + "deg-" + suffix(gradientType, i, j)}), `;
        prevAngle = parseFloat(angle);
      }
    }
  }
  for (let j = 0; j < numColorStops; j++) {
    const color = preset.colors[j];
    const position = preset.stops[j];
    properties[colorVar + suffix(gradientType, i, j)] = color;
    if (stopPosFunction === "hard" && j !== 0) {
      const prevColor = gradient
        .split(", ")
        .slice(-2, -1)[0]
        .split(" ")[0];
      gradient += `${prevColor} var(${stopsVar + suffix(gradientType, i, j)}), `;
    }
    const firstStop = stopPosFunction === "hard" && j === 0;
    if (!firstStop) properties[stopsVar + suffix(gradientType, i, j)] = position + "%";
    gradient += `var(${colorVar + suffix(gradientType, i, j)}) ${!firstStop ? `var(${stopsVar + suffix(gradientType, i, j)})` : position + "%"}, `;
  }
  gradient = gradient.slice(0, -2) + ")";
  return { gradient, blendMode, properties };
};

const generateAnimations = (presets, properties) => {
  let kf = [];
  let dur = [];
  let anim = [];
  let defs = [];
  let tf = [];
  let props = [];
  const getNewValue = (parts, value) => {
    const propertyType = parts[3];
    const preset = presets[parseInt(parts[parts.length - 2], 10)];
    const { settings, newSettings, stops, newStops, colors, newColors } = preset;
    switch (propertyType) {
      case "s":
        if (settings.size && parts[2] === "length") {
          return newSettings.size;
        } else {
          const stopIndex = stops.indexOf(Number(value.slice(0, -1)));
          return newStops[stopIndex] + "%";
        }
      case "cx":
      case "cy":
        return newSettings[propertyType];
      case "0":
        return newSettings.angle || newSettings.startAngle;
      case "1":
        return newSettings.endAngle;
      default:
        if (parts[2] === "color") {
          const colorIndex = colors.indexOf(value);
          if (colorIndex >= 0) {
            return newColors[colorIndex];
          }
        } else {
          return null;
        }
    }
  };
  for (const [property, value] of Object.entries(properties)) {
    props.push(`${property}: ${value};`);
    const parts = property.split("-")
    const newValue = getNewValue(parts, value)
    defs.push(`@property ${property} {syntax:"<${parts[2]}>";inherits:true;initial-value:${value};}`);
    anim.push(property.replace(/[-]+/g, ''));
    kf.push(`@keyframes ${property.replace(/[-]+/g, '')} {0%{${property}: ${value}}50%{${property}:${newValue}}100%{${property}:${value}}}`);
  }
  presets.forEach(e => {
    tf.push(`cubic-bezier(${e.bezier[0]}, ${e.bezier[1]}, ${e.bezier[2]}, ${e.bezier[3]})`);
    dur.push(e.duration);
  });
  return {
    props: props.join(" "),
    defs: defs.join(" "),
    keyframes: kf.join(" "),
    animations: {
      names: anim.join(", "),
      timing: tf.join(", "),
      dur: dur.join(", "),
    }
  };
};

// CONSTANTS

const gradientTypes = [
  "linear-gradient",
  "repeating-linear-gradient",
];

const blendModes = [
  "normal",
  "multiply",
  "screen",
  "overlay",
  "darken",
  "lighten",
  "hard-light",
  "soft-light",
  "difference",
  "exclusion",
  "hue",
  "saturation",
  "color",
  "luminosity",
];

const colorSchemes = [
  "analogous",
  "complementary",
  "split complementary",
  "triadic",
  "tetradic",
  "monochromatic",
  "square",
  "rectangle",
  "shades",
  "tints and tones",
  "high contrast",
  "luminance gradient"
];

const stopPosFunctions = ["linear", "ease"];