import React, { useEffect } from "reactn";
import { OptionsMenu } from "components";
import styled from "styled-components";
import computer from "assets/icons/computer.ico";
import optionsMenuData from "./optionsMenuData";
import forward from "assets/icons/forward.ico";
import back from "assets/icons/back.ico";

const LinksWindow = () => {
  const selection = window.getSelection().anchorNode;

  useEffect(() => {
    if (selection) {
      optionsMenuData["Edit"][0].disable = true;
      optionsMenuData["Edit"][1].disable = false;
    } else {
      optionsMenuData["Edit"][0].disable = false;
      optionsMenuData["Edit"][1].disable = true;
    }
  }, [selection]);

  function onClose() {
    window.location.href = "https://heaven.computer";
  }

  function onClickOptionItem(item) {
    switch (item.text) {
      case "Discord":
        window.open("https://discord.gg/heavencomputer", '_blank').focus();
        break;
      case "Website":
        window.open("https://heaven.computer", '_blank').focus();
        break;
      case "Twitter":
        window.open("https://twitter.com/heaven_computer", '_blank').focus();
        break;
      case "OpenSea":
        window.open("https://opensea.io/collection/heavencomputer", '_blank').focus();
        break;
      case "Etherscan":
        window.open("https://etherscan.io/address/0x80adb36595239fe918c7d118c1f81e07d070801a", '_blank').focus();
        break;
      case "Refresh":
        onRefresh();
        break;
      case "Select All":
        onSelectAll();
        break;
      case "Select None":
        onSelectNone();
        break;
      case "Close":
        onClose();
        break;
      default:
    }
  }

  const onRefresh = () => {
    if (selection) onSelectNone();
  };

  function onSelectNone() {
    window.getSelection().removeAllRanges();
  }
  function onSelectAll() {
    const el = document.getElementById("help_inner");
    if (document.body.createTextRange) {
      let range = document.body.createTextRange();
      range.moveToElementText(el);
      range.select();
    } else if (window.getSelection) {
      let selection = window.getSelection();
      let range = document.createRange();
      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
  return (
    <Div>
      <div className="window">
        <header className="window_header">
          <img alt="favicon" src={computer} className="window_header_icon" />
          <div className="window_header_title">☆ﾟ.*･｡ﾟh e a v e n . c o m p u t e r ☁⋅.˳˳.⋅ॱ˙˙ॱ⋅.˳˳.⋅ॱ˙˙ॱᐧ.˳˳.⋅</div>
          <button
            key="close"
            onPointerUp={onClose}
            className="header_button header_button--close"
          />
        </header>
        <section className="window_toolbar">
          <OptionsMenu items={optionsMenuData} onClickItem={onClickOptionItem} />
        </section>
        <div className="divider"></div>
        <section className="address_bar">
          <div className="div_bar"></div>
          <div className="address_bar_title">
            A<u>d</u>dress
          </div>
          <div className="address_bar_content">
            <div className="address_bar_content_text">
              <img
                draggable={false}
                src={computer}
                alt="favicon"
                className="address_bar_content_img"
              />
              <span>heaven://links</span>
              <div className="address_bar_buttons">
                <div className="address_bar_go">
                  <div />
                </div>
                <div id={"backHelp"} title="back" className="address_bar_back disabled">
                  <img draggable={false} alt="back" src={back} />
                </div>
                <div
                  id={"nextHelp"}
                  title="forward"
                  className="address_bar_forward disabled"
                >
                  <img draggable={false} alt="forward" src={forward} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="window_content">
          <div id="help_inner" className="window_content_inner">
            <p>
              <a
                className="classicLink pointer"
                href="https://heaven.computer"
                target="_blank"
                rel="noopener noreferrer"
              >
                HeavenComputer
              </a>{" "}
              is a blockchain-based art platform created by the Brazilian
              software engineer and multimedia artist that goes by the pseudonym <i>godmin</i>.
              <br /><br />
              Its genesis collection,{" "}
              <a
                className="classicLink pointer"
                href="https://ghost.software"
                target="_blank"
                rel="noopener noreferrer"
              >
                ghost.software
              </a>
              , released in August 2021, features a variety of 3D files that
              are corrupted using an on-chain algorithm that gives the output a
              ghost-like appearance. Every ghost has an unique name, description and
              glitch pattern, which are generated and customized to each minter.
              The <i>film color corruption</i> trait means the colors in the scene were also glitched by the means of a corrupted color grading (LUT) file.
              Apart from the 3d glitch art and unique names and descriptions, another
              feature of ghost.software collection is the live temple, an immersive 3D and VR display of the glitched scene.
              <br /><br />
              The second art minted in collection,{" "}
              <a
                className="classicLink pointer"
                href="https://god.observer"
                target="_blank"
                rel="noopener noreferrer"
              >
                god.observer
              </a>, released May 2022, consists of the static image of a celestial
              object turned into raw data juxtaposed with the motion vectors of a video,
              both of which are selected by a seeded hash generated at the time of mint.
              The <i>data sonification</i> trait means converting the data into audio,
              applying audio effects, and converting back into raw data, so it outputs unusual visual artifacts as a way to imagine extraterrestrial interference in space data. Besides
              a customizable interactive Observatory and Research lore, another feature of
              god.observer collection is the ability to transform the token's video data into audio and
              remix it using the GodRemixer app.
            </p>
            <div className="pagediv"></div>
            <h3>HeavenComputer Website</h3>
            <p>
              <a
                className="classicLink pointer"
                href="https://heaven.computer"
                target="_blank"
                rel="noopener noreferrer"
              >
                heaven.computer
              </a>{" "}
              (best viewed on desktop)
            </p>

            <div className="pagediv"></div>
            <h3>Twitter Feed</h3>

            <p>
              <a
                className="classicLink pointer"
                href="https://www.twitter.com/heaven_computer"
                target="_blank"
                rel="noopener noreferrer"
              >
                @heaven_computer
              </a>
            </p>

            <div className="pagediv"></div>
            <h3>OpenSea Collection</h3>
            <p>
              <a
                className="classicLink pointer"
                href="https://opensea.io/collection/heavencomputer"
                target="_blank"
                rel="noopener noreferrer"
              >
                opensea.io/collection/heavencomputer
              </a>
            </p>

            <div className="pagediv"></div>
            <h3>Etherscan Contract</h3>
            <p>
              <a
                className="classicLink pointer"
                href="https://etherscan.io/address/0x80adb36595239fe918c7d118c1f81e07d070801a"
                target="_blank"
                rel="noopener noreferrer"
              >
                0x80ADB36595239fe918c7D118C1F81e07d070801a
              </a>
            </p>

            <div className="pagediv"></div>
            <h3>Discord Community</h3>
            <p>
              <a
                className="classicLink pointer"
                href="https://discord.gg/heavencomputer"
                target="_blank"
                rel="noopener noreferrer"
              >
                discord.gg/heavencomputer
              </a>
            </p>

            <div className="pagediv"></div>
            <h3>Interviews & Podcasts</h3>
            <ul>
              <li>
                <a
                  className="classicLink pointer"
                  href="http://open.spotify.com/episode/1NPlsbFwnCUlsohdRpbJTS?si=3b5eda2d64fc44df"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  JPEG 2000 Podcast
                </a>{" "}
                September 2022
              </li>
              <li>
                <a
                  className="classicLink pointer"
                  href="https://open.spotify.com/episode/4VkRNRrqQxSwHcQGKo3lna?si=xNYYKPC8S2S1tWGQcf2V7w&nd=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  That's Nifty Podcast
                </a>{" "}
                June 2022
              </li>
              <li>
                <a
                  className="classicLink pointer"
                  href="https://www.proof.xyz/artist-spotlight-exploring-the-web1-genesis-of-heaven-computer-with-godmin/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PROOF Podcast with Kevin Rose
                </a>{" "}
                March 2022
              </li>
              <li>
                <a
                  className="classicLink pointer"
                  href="https://zine.zora.co/godmin-heaven-computer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Interview for Zora Zine
                </a>{" "}
                February 2022
              </li>
              <li>
                <a
                  className="classicLink pointer"
                  href="https://www.youtube.com/watch?v=9W7NCMik0Ak"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter Spaces with Zeneca_33
                </a>{" "}
                January 2022
              </li>
              <li>
                <a
                  className="classicLink pointer"
                  href="https://www.youtube.com/watch?v=P4kgAJogvAk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PRSPR Podcast with Maxine Ryan
                </a>{" "}
                September 2021
              </li>
              <li>
                <a
                  className="classicLink pointer"
                  href="https://drive.google.com/file/d/1L4ZCiUsTzjKh2as-HJrx2QXuOHO0OVuP/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OpenSea Interview: Collection Highlight
                </a>{" "}
                August 2021
              </li>
            </ul>
          </div>
        </div>
        <section className="window_statusbar">
          <div>Done</div>
          <div></div>
        </section>
      </div>
    </Div>
  );
};

const Div = styled.div`
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
  touch-action: pinch-zoom;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  background: var(--colorgrad3);
  padding: 1px;
  box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad2);
  flex-direction: column;
  font-size: 11px;
  line-height: 11px;
  *:not(input):not(textarea):not(p):not(span):not(a):not(td) {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ol,
  ul {
    margin-left: 20px;
  }
  li + li {
    margin-top: 10px;
  }
  p,
  span,
  a,
  td,
  i,
  u,
  b,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5 {
    user-select: text !important;
  }
  i {
    font-style: italic;
  }
  h3 {
    font-size: 14px;
    padding: 4px 0;
    line-height: 16px;
  }
  p,
  ul {
    padding: 4px 0 20px 0;
    line-height: 14px;
    font-size: 13px;
  }
  span {
    display: block;
  }
  .window_content_inner {
    padding: 10px;
    height: calc(100% - 4px);
  }
  .header_button {
    height: 18px;
    width: 18px;
    outline: none;
    color: var(--colorgrad7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 3px;
    padding: 0;
    border: 0;
    background: var(--colorgrad3);
    box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad0),
      inset -2px -2px 0 0 var(--colorgrad5), inset 2px 2px 0 0 var(--colorgrad2);
  }
  .header_button--help:after {
    content: "?";
    align-self: center;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .header_button:active {
    background-color: var(--colorgrad3);
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
  .header_button--minimize:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 3px;
    width: 8px;
    height: 2px;
    background: var(--colorgrad7);
  }
  .header_button--maximize:after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid var(--colorgrad7);
    border-top-width: 2px;
  }
  .header_button--close:before,
  .header_button--close:after {
    transform-origin: center center;
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 12px;
    background: var(--colorgrad7);
  }
  .header_button--close:before {
    transform: rotateZ(-45deg);
  }
  .header_button--close:after {
    transform: rotateZ(45deg);
  }
  .window {
    width: 100%;
    height: 100%;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad5), inset 1px 1px 0 0 var(--colorgrad0);
    background: var(--colorgrad3);
    padding: 4px;
    display: flex;
    flex-direction: column;
  }
  @keyframes type {
    from {
      width: 0;
    }
  }
  .window_header {
    padding: 1px;
    background: linear-gradient(90deg, #f5c8bf, #fcd7d1, #f9e4e0, #f5c8bf);
    height: 22px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    color: white;
    font-weight: 700;
  }
  .window_header_icon {
    pointer-events: none;
    height: 16px;
    margin-left: 3px;
  }
  .window_header_title {
    flex: 1;
    pointer-events: none;
    padding: 5px;
    letter-spacing: 1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .window_statusbar {
    display: flex;
  }
  .window_statusbar p {
    height: 20px;
    width: 17ch;
    animation: type 4s steps(17, end) infinite;
    overflow: hidden;
  }
  .window_statusbar div:first-child {
    white-space: nowrap;
    overflow: hidden;
    margin-top: 3px;
    margin-right: 1.5px;
    padding: 5px;
    height: 20px;
    width: 50%;
    background-color: var(--colorgrad3);
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
  .window_statusbar div:nth-child(2) {
    margin-top: 3px;
    margin-left: 1.5px;
    padding: 5px;
    height: 20px;
    width: 50%;
    background-color: var(--colorgrad3);
    background-image: linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      );
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7),
      inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
  .window_statusbar::after {
    position: absolute;
    bottom: 6px;
    right: 6px;
    height: 12px;
    width: 12px;
    content: "";
    background-image: url("data:image/gif;base64,R0lGODlhDAAMAJEAAAAAAP///5mZmf///yH5BAEAAAMALAAAAAAMAAwAAAIbnI8TmSF83IMSKvFWw3dnHnFV+GVGhZZXmaoFADs=");
  }
`;

export default LinksWindow;