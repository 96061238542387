import React, { useEffect, useState, useGlobal, setGlobal } from "reactn";
import SkyImage from "./SkyImage";
import styled from "styled-components";
import { displayMetadata } from "./utils/displaymetadata.js";

const AllTokens = ({ baseURI, allTokens, handleViewToken, type, id, minToken }) => {
  const [connected] = useGlobal("connected");
  const [page, setPage] = useState(1);
  const max = 6;

  useEffect(() => {
    if ((type !== "mine" && !allTokens) || (type === "mine" && connected && !allTokens)) {
      setGlobal({ appLoading: { name: "Sky Luxury", id: id } });
    } else {
      setGlobal({ appLoading: null });
    }
  }, [allTokens, id, connected, type]);

  useEffect(() => {
    if (allTokens && allTokens.length > 0) {
      const last = document.getElementById(id + "a_sl_last").classList;
      const first = document.getElementById(id + "a_sl_first").classList;
      const next = document.getElementById(id + "a_sl_next").classList;
      const prev = document.getElementById(id + "a_sl_previous").classList;
      const rnd = document.getElementById(id + "a_sl_random").classList;
      if (allTokens.length <= max) {
        prev.add("disabled");
        next.add("disabled");
        first.add("disabled");
        last.add("disabled");
        rnd.add("disabled");
      } else {
        rnd.remove("disabled");
        next.remove("disabled");
        prev.remove("disabled");
        first.remove("disabled");
        last.remove("disabled");
      }
      if (page === 1 && allTokens.length <= max) {
        prev.add("disabled");
        next.add("disabled");
        first.add("disabled");
        last.add("disabled");
      } else if (page === 1 && allTokens.length > max) {
        prev.add("disabled");
        next.remove("disabled");
        first.add("disabled");
        last.remove("disabled");
      } else if (page >= allTokens.length / max) {
        next.add("disabled");
        prev.remove("disabled");
        first.remove("disabled");
        last.add("disabled");
      } else {
        next.remove("disabled");
        prev.remove("disabled");
        first.remove("disabled");
        last.remove("disabled");
      }
    }
  }, [page, allTokens, id]);

  function handlePageChange(number) {
    if (number === page || allTokens.length <= max) return;
    if (number < 1) {
      return;
    } else if (number - 1 > allTokens.length / max) {
      return;
    } else {
      setPage(number);
    }
  }

  return (
    <Div>
      {!allTokens && type !== "mine" && (
        <p style={{ marginLeft: "10px" }}>Loading...</p>
      )}
      {!connected && type === "mine" && !allTokens && (
        <p style={{ marginLeft: "10px" }}>
          Please connect via Connection Wizard to view your skies.
        </p>
      )}
      {connected && type === "mine" && !allTokens && (
        <p style={{ marginLeft: "10px" }}>Loading...</p>
      )}
      {connected && type === "mine" && allTokens && allTokens.length === 0 && (
        <p style={{ marginLeft: "10px" }}>No skies found in your catalogue.</p>
      )}
      {allTokens && allTokens.length > 0 && (
        <div className="buttons_row">
          <div
            className="sl_btn glass pointer"
            id={id + "a_sl_first"}
            onClick={() => handlePageChange(1)}
          >
            <span>&#60;&#60;</span>
          </div>
          <div
            className="sl_btn glass pointer"
            id={id + "a_sl_previous"}
            onClick={() => handlePageChange(page - 1)}
          >
            <span>&#60;</span>
          </div>
          <div
            className="sl_btn glass pointer"
            id={id + "a_sl_random"}
            onClick={() => {
              let num = Math.ceil(Math.random() * (allTokens.length / max));
              do { num = Math.ceil(Math.random() * (allTokens.length / max)) } while (num === page)
              handlePageChange(num)
            }}
          >
            <span>?</span>
          </div>
          <div
            className="sl_btn glass pointer"
            id={id + "a_sl_next"}
            onClick={() => handlePageChange(page + 1)}
          >
            <span>&#62;</span>
          </div>
          <div
            className="sl_btn glass pointer"
            id={id + "a_sl_last"}
            onClick={() =>
              handlePageChange(Math.ceil(allTokens.length / max))
            }
          >
            <span>&#62;&#62;</span>
          </div>
        </div>
      )}
      <div className="token_rows">
        {allTokens &&
          allTokens.length > 0 &&
          allTokens.map((token, index) => {
            if (allTokens.slice((page - 1) * max, page * max).includes(token)) {
              const foundMetadata = displayMetadata.find(
                (item) => parseInt(item.tokenId) === parseInt(token)
              );
              return (
                <div className="token_item" id={index} key={index}>
                  <div
                    className="sl_token_box glass pointer"
                    onPointerUp={() => handleViewToken(token)}
                  >
                    <SkyImage
                      id={id}
                      baseURI={baseURI}
                      token={token}
                      ipfsHash={foundMetadata ? foundMetadata.ipfsHash : null}
                    />
                  </div>
                  <div className="token_caption">
                    <div>sky #{token - minToken}</div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 10px;
  img {
    width: 100%;
    max-width: 426px;
  }
  #sl_token_box {
    width: 426px;
    height: 240px;
  }
`;

export default AllTokens;
