import React, { useEffect, useState } from "react";
import loadImg from "assets/ghost/loading.gif";

const GhostImage = ({ token, ipfsHash, id }) => {
  const [imgURL, setImgURL] = useState(null);

  useEffect(() => {
    document.getElementById("loaderimg" + token + id).style.display = "block";
    document.getElementById("token" + token + id).style.display = "none";
    if (token !== undefined && ipfsHash) {
      setImgURL("https://heaven.mypinata.cloud/ipfs/" + ipfsHash);
    }
  }, [token, ipfsHash, id]);
  return (
    <div style={{ position: "relative" }}>
      <img
        draggable={false}
        style={{
          zIndex: 1,
          objectFit: "none",
        }}
        alt={"token" + token}
        className={"token_image pointer gsti"+id}
        id={"loaderimg" + token + id}
        src={loadImg}
      />
      <video
        autoPlay
        loop
        muted
        controls={false}
        playsInline
        preload="auto"
        className={"token_image pointer gsti"+id}
        id={"token" + token + id}
        src={imgURL}
        onLoadedData={() => {
          document.getElementById("loaderimg" + token + id).style.display = "none";
          document.getElementById("token" + token + id).style.display = "block";
        }}
        style={{ zIndex: 2, display: "none" }}
      />
    </div>
  );
};

export default GhostImage;
