import React, { useEffect, useState } from "reactn";
import styled from "styled-components";
import { images } from "./utils/objects.js";
import loading from "assets/god/ui/loading.gif";

const ViewObject = ({ object, view, createViewList }) => {
  const [objImg, setObjImg] = useState(null);
  const zeroPad = (num) => {
    if (num === "-00") return num;
    if (num.toString().startsWith("-")) {
      return "-0" + num.toString().slice(-1);
    } else {
      return ("+0" + num).slice(-2);
    }
  };
  const zeroPadFormat = (num) => {
    if (num === "-00") return num;
    if (Math.abs(num) > 10) {
      if (num.toString().startsWith("-")) {
        return num;
      } else {
        return "+" + num;
      }
    } else {
      if (num.toString().startsWith("-")) {
        return "-0" + num.toString().slice(-1);
      } else {
        return ("+0" + num).slice(-3);
      }
    }
  };
  useEffect(() => {
    setObjImg(null);
    if (object) {
      const img = images[object.img];
      setObjImg(img);
    }
  }, [object]);

  return (
    <Div>
      {!object && <p>Loading...</p>}
      {object && (
        <div id="objitem" className="selectable">
          <h1 id="objtitle">{object.name}</h1>
          <div id="objbody">
            <div id="objimg">
              <img
                id="loadingobj"
                src={loading}
                className="go_token_image"
                alt={object.name}
                style={{
                  zIndex: 1,
                  objectFit: "none",
                }}
              />
              <img
                src={objImg}
                onLoad={() => {
                  document.getElementById("loadingobj").style.display = "none";
                  document.getElementById("img" + object.name).style.display =
                    "block";
                }}
                id={"img" + object.name}
                className="go_token_image"
                alt={object.name}
                style={{ zIndex: 2, display: "none" }}
              />
              <p className="small_note">credit: {object.credit}*</p>
            </div>
            <div id="objtable">
              <p>
                <b>{object.label}</b>
                <span>identifier</span>
              </p>
              <p>
                <b>{object.type}</b>
                <span>type</span>
              </p>
              <p>
                <b>{object.constellation}</b>
                <span>constellation</span>
              </p>
              <p>
                <b>
                  {zeroPad(object.ra.h)}h {zeroPad(object.ra.m)}m{" "}
                  {zeroPad(Math.round(object.ra.s))}s
                </b>
                <span>ra</span>
              </p>
              <p>
                <b>
                  {zeroPadFormat(object.dec.d)}° {zeroPad(object.dec.m)}′{" "}
                  {zeroPad(Math.round(object.dec.s))}″
                </b>
                <span>dec</span>
              </p>
              <p>
                <b>{object.magnitude.toFixed(2)}</b>
                <span>magnitude</span>
              </p>
              <p></p>
            </div>
            {view === "Object" && (
              <div
                className="go_mint_btn pointer"
                onClick={() => createViewList("ObjObservatory", view)}
              >
                <span>view observatory</span>
              </div>
            )}
            {view === "ObjObservatory" && (
              <div
                className="go_mint_btn pointer"
                onClick={() => createViewList("Object", view)}
              >
                <span>view catalogue</span>
              </div>
            )}
            <div>
              <h3>Explanation:</h3>
              <p style={{ letterSpacing: "1px", lineHeight: "16px" }}>
                {object.description}
              </p>
              <p className="small_note">
                (text from{" "}
                <a
                  className="pointer"
                  href={object.desccred}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {object.desccred.substring(8).slice(0, -1).startsWith("en")
                    ? object.desccred.substring(11).slice(0, -1)
                    : object.desccred.substring(8).slice(0, -1)}
                </a>
                )
              </p>
            </div>
            <div>
              <p className="small_note">
                [*] edits may have been made, such as cropping, color
                correction, and other effects.
              </p>
            </div>
          </div>
        </div>
      )}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 0 0 0;
  justify-content: center;
  #objitem {
    position: absolute;
    width: 100%;
    padding: 0 15px 15px 15px;
  }
  #objtitle {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p.small_note {
    color: var(--colorgrad5) !important;
    margin-top: 2px;
    margin-bottom: -2px;
  }
  #objimg {
    border: 1px dashed #b19cd9;
    padding: 6px;
    max-width: 100%;
    div {
      max-width: 100%;
    }
    p {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      max-width: 100%;
    }
  }
  #objtable {
    max-width: 100%;
    white-space: nowrap;
    p {
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 4px 0;
      b {
        font-size: 12px;
        letter-spacing: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--colorgrad5);
        letter-spacing: 1px;
      }
      b + span {
        padding-left: 12px;
      }
    }
    p + p {
      border-top: 1px dashed white;
    }
  }
  #objbody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    div {
      margin-top: 10px;
    }
  }
`;

export default ViewObject;
