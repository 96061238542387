import { BOOL, PALETTE } from "../constants";
import * as palettes from "../palettes";
import { cloneCanvas, fillBufferPixel, getBufferIndex, rgba } from "../utils";

const optionTypes = {
  invertR: { type: BOOL, default: true, label: 'red' },
  invertG: { type: BOOL, default: true, label: 'green' },
  invertB: { type: BOOL, default: true, label: 'blue', classes: 'no_margin' },
  palette: { type: PALETTE, default: palettes.nearest }
};
const defaults = {
  invertR: optionTypes.invertR.default,
  invertG: optionTypes.invertG.default,
  invertB: optionTypes.invertB.default,
  palette: optionTypes.palette.default
};
const invert = (
  input: HTMLCanvasElement,
  options: {
    invertR: boolean,
    invertG: boolean,
    invertB: boolean,
    palette: Palette
  } = defaults
): HTMLCanvasElement => {
  const { invertR, invertG, invertB, palette } = options;
  const output = cloneCanvas(input, false);
  const inputCtx = input.getContext("2d", {
    willReadFrequently: true
  });
  const outputCtx = output.getContext('2d', { willReadFrequently: true });
  if (!inputCtx || !outputCtx) return input;
  const buf = inputCtx.getImageData(0, 0, input.width, input.height).data;
  for (let x = 0; x < input.width; x += 1) {
    for (let y = 0; y < input.height; y += 1) {
      const i = getBufferIndex(x, y, input.width);
      const r = invertR ? 255 - buf[i] : buf[i];
      const g = invertG ? 255 - buf[i + 1] : buf[i + 1];
      const b = invertB ? 255 - buf[i + 2] : buf[i + 2];
      const rgbaCol = rgba(r, g, b, 255)
      const col = palette.getColor(rgbaCol, palette.options);
      fillBufferPixel(buf, i, col[0], col[1], col[2], col[3]);
    }
  }
  outputCtx.putImageData(new ImageData(buf, output.width, output.height), 0, 0);
  return output;
};

const defaultFunc = {
  name: "Invert",
  func: invert,
  options: defaults,
  optionTypes,
  defaults
};

export default defaultFunc;
