import React, { useGlobal, setGlobal } from "reactn";
import styled from "styled-components";
import { ethers } from "ethers";

const Metamask = ({ onClose }) => {
  const [provider] = useGlobal("provider");
  const [connected] = useGlobal("connected");
  const [account] = useGlobal("account");
  const [chainId] = useGlobal("chainId");

  const handleConnectToMetamask = async () => {
    let status = document.getElementById("metamask_status");
    if (provider) {
      status.innerHTML = "Connecting...";
      provider
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          setGlobal({
            connected: true,
            account: ethers.utils.getAddress(accounts[0]),
          });
          status.innerHTML = "Successfully connected";
        })
        .catch((err) => {
          if (err.code === 4001 || err.code === -32002) {
            status.innerHTML = "Please accept connection to Metamask";
          } else {
            status.innerHTML = "Something went wrong";
          }
        });
    }
  };

  return (
    <Div>
      <div className="window_content_grey">
        <p>Welcome to the Connection Wizard!</p>
        <div className="box_section">
          {provider &&
            "Please use your Ethereum wallet to connect your computer to the web3."}
          {!provider && "No Ethereum wallet detected. Click [?] for more info."}
        </div>
        {provider && (
          <>
             <div
                className={connected ? "button disabled" : "button"}
                id="metamask_connect"
                onClick={handleConnectToMetamask}
              >
                Connect
              </div>
            <section className="box_section">
              <div className="box_section_title">
                <span>Status</span>
              </div>
              <p id="metamask_status">
                {connected ? "Successfully connected" : "Not connected"}
              </p>
            </section>
            <section className="box_section">
              <div className="box_section_title">
                <span>Network</span>
              </div>
              <p>{chainId === 1 ? "Ethereum Mainnet" : "Unknown"}</p>
            </section>
            <section className="box_section">
              <div className="box_section_title">
                <span>Account</span>
              </div>
              <p>{account ? account : "Unknown"}</p>
            </section>
          </>
        )}
      </div>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  background: var(--colorgrad3);
  overflow: hidden;
  text-align: left;
  line-height: 1.2;
  padding: 6px;
  p:first-child {
    padding-bottom: 4px;
  }
  p:not(:first-child) {
    padding: 4px 0;
  }
  .box_section:not(:last-child) {
    margin: 10px 0;
  }
  .box_section:last-child {
    margin-top: 10px;
  }
  .button {
    margin: 5px 0;
  }
  .window_content_grey {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 4px;
    background: var(--colorgrad3);
  }
`;

export default Metamask;
