import { PALETTE } from "../constants";
import { nearest } from "../palettes";
import { cloneCanvas, fillBufferPixel, getBufferIndex, rgba } from "../utils";

const optionTypes = {palette: { type: PALETTE, default: nearest }};

const defaults = {palette: { ...optionTypes.palette.default, options: { levels: 1 } }};

const grayscale = (input: HTMLCanvasElement, options): HTMLCanvasElement => {
  const { palette } = options;
  const output = cloneCanvas(input, false);
  const inputCtx = input.getContext("2d", {
  willReadFrequently: true
});
  const outputCtx = output.getContext("2d", {
  willReadFrequently: true
});

  if (!inputCtx || !outputCtx) return input;

  const buf = inputCtx.getImageData(0, 0, input.width, input.height).data;

  for (let x = 0; x < input.width; x += 1) {
    for (let y = 0; y < input.height; y += 1) {
      const i = getBufferIndex(x, y, input.width);
      const grey = rgba(Math.round((buf[i] + buf[i + 1] + buf[i + 2]) / 3),Math.round((buf[i] + buf[i + 1] + buf[i + 2]) / 3), Math.round((buf[i] + buf[i + 1] + buf[i + 2]) / 3), Math.round((buf[i] + buf[i + 1] + buf[i + 2]) / 3));
      const color = palette.getColor(grey, palette.options);
      fillBufferPixel(buf, i, color[0], color[1], color[2], buf[i + 3]);
    }
  }

  outputCtx.putImageData(new ImageData(buf, output.width, output.height), 0, 0);
  return output;
};

const defaultFunc = {
  name: "Desaturate",
  func: grayscale,
  options: defaults,
  optionTypes,
  defaults
}

export default defaultFunc;
