import React, { useGlobal, useEffect } from "reactn";
import { Rain, Sky1, Sky2, Sky3, Sky4, Sky5, Sky6, Sky7, Sky8, SkyLast } from ".";
import { initWallGradients } from "../utils/gradientgen";
import styled from "styled-components";
import texter from "assets/cursors/text.cur";

const SkyTop = ({
  setError,
  purchase,
  error,
  notConnectedError,
  standAlone,
  createViewList,
  id,
  onToggleComponent,
}) => {
  const wrongChain = "please connect to ethereum mainnet."
  const [connected] = useGlobal("connected");
  const [chainId] = useGlobal("chainId");

  useEffect(() => {
    const canvasName = ".gradient";
    const css = initWallGradients(canvasName);
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(css));
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <Div className="skytop selectable">
      {error && <p className="skyerror animate"></p>}
      <div
        onClick={() => {
          if (standAlone) {
            window.location.href = "https://heaven.computer";
          } else {
            if (connected && chainId === 1) {
              setError(null);
              purchase();
            } else if (connected && chainId !== 1) {
              if (error === wrongChain) return;
              // onToggleComponent("Connection Wizard", "open", id);
              setError(wrongChain);
            } else {
              if (error === notConnectedError) return;
              // onToggleComponent("Connection Wizard", "open", id);
              setError(notConnectedError);
            }
          }
        }}
        className={"glass sl_mint animate " + (!connected && error === notConnectedError ? "disabled" : "pointer")}><span>MINT YOUR SKY</span></div>
      <Rain />
      <div className="scene animate">
        <div className="wall gradient"></div>
        <div className="wall gradient"></div>
        <div className="wall gradient"></div>
        <div className="wall gradient"></div>
        <div className="floor gradient">
          <Sky1 createViewList={createViewList} standAlone={standAlone} />
          <Sky2 />
          <Sky3 />
          <Sky4 onToggleComponent={onToggleComponent} standAlone={standAlone} id={id} />
          <Sky5 />
          <Sky6 />
          <Sky7 />
          <Sky8 />
          <SkyLast />
        </div>
        <div className="roof animate"></div>
        <div className="ground animate"></div>
        <div className="neonSign">
          <div className="neonCircle"></div>
          <input type="checkbox" className="hidden" id="toggleSign" />
          <label className="neonScript" htmlFor="toggleSign">
            <div className="skySign pinkNeon neonGlow crosshair">Sky</div>
            <div className="luxurySign blueNeon neonGlow crosshair">L<span
              className="flicker crosshair">U</span>XUR<span className="flicker crosshair">Y</span></div>
          </label>
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
filter: url(#grainyNoise) !important;
animation-name: flipSectionIndex, hide;
animation-fill-mode: forwards;
animation-timing-function: steps(var(--sections), end), linear;
animation-direction: alternate;
animation-duration: auto;
animation-timeline: --scrollTimeline;
animation-range: normal, var(--range) calc(var(--range) * 2);
container-name: skytop;
container-type: size;
.content p, .content h1, .content h2, .content h3, .content strong, .animate.content:last-of-type .neonGlow {
  user-select: text !important;
  cursor: url(${texter}), help;
}
/* SECTION ZERO */

@keyframes flicker2 {
  23.5%,
  25.5%,
  27%,
  81.5%,
  82.5%,
  100% {
    color: var(--color0);
    text-shadow: var(--neonGlow);
  }

  24%,
  26%,
  82% {
    color: var(--neonOff);
    text-shadow: var(--neonShadow);
  }
}

@keyframes flicker1 {
  0%,
  14%,
  14.2%,
  49.6%,
  51%,
  52%,
  52.2%,
  100% {
    color: var(--color0);
    text-shadow: var(--neonGlow);
  }

  14.1%,
  49.7%,
  52.1% {
    color: var(--neonOff);
    text-shadow: var(--neonShadow);
  }
}

@keyframes enter {
  from {
    transform: translateZ(-400vw) rotateX(80deg) rotateZ(-150deg);
  }

  to {
    transform: translateZ(0px) rotateX(0deg) rotateZ(0deg);
  }
}

@keyframes fadeCeil {
  to {
    --ceilColor: transparent;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes flipSectionIndex {
  from {
    --sectionIndex: 0;
  }

  to {
    --sectionIndex: var(--sections);
  }
}


@keyframes hide {
  to {
    --display: none;
  }
}

@keyframes fadeWall {
  to {
    --wallShade0: transparent;
    --wallShade1: transparent;
  }
}

/* SECTION ONE */

@keyframes sky1 {
 to {
    z-index: -1;
    visibility: hidden;
  }
}

@keyframes moveSphere {
  to {
    --sphereTranslateX: calc(100vw + var(--sphereBaseSize));
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* SECTION TWO */

@keyframes sky2 {
  0%,
  100% {
    z-index: 2;
    visibility: visible;
  }
}

@keyframes suby {
  0% {
    transform: translateY(150vh);
  }

  20%,
  50% {
    transform: none;
  }

  100% {
    transform: translateY(-150vh);
  }
}


@keyframes sky2h2 {
  15% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* SECTION THREE */

@keyframes sky3 {
  0%,
  100% {
    z-index: 3;
    visibility: visible;
  }
}

@keyframes sky3p {
  to {
    top: 0%;
    transform: none;
  }
}

@keyframes exitP {
  to {
    transform: translateX(calc(150vw * var(--exit)));
  }
}

/* SECTION FOUR */

@keyframes sky4 {
  0%,
  100% {
    z-index: 4;
    visibility: visible;
  }

  0% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  70% {
    transform: none;
  }

  100% {
    transform: translateY(-150vh);
  }
}

@keyframes enterPoly {

  0%,
  25% {
    --translatePoly: 110vh;
  }

  50% {
    --translatePoly: 0;
  }

  75%,
  100% {
    --translatePoly: -110vh;
  }
}

@keyframes enterRight {
  from {
    transform: translateX(150vw);
  }

  to {
    transform: none;
  }
}

@keyframes enterLeft {
  from {
    transform: translateX(-150vw);
  }

  to {
    transform: none;
  }
}

@keyframes rotPoly {
  0% {
    transform: rotateY(1turn);
  }
}

@keyframes rotShade {
  to {
    filter: brightness(calc(.5 + var(--j)*.1));
  }
}

@keyframes rotShape {

  0%,
  50%,
  100% {
    width: var(--finalW);
    --angleOpening: var(--finalAngle);
  }

  25%,
  75% {
    width: var(--baseW);
    --angleOpening: var(--initAngle);
  }
}

/* SECTION FIVE */

@keyframes sky5 {
  0%,
  100% {
    z-index: 5;
    visibility: visible;
  }

  0% {
    transform: translateY(150vh);
  }

  25%,
  50% {
    transform: none;
  }

  100% {
    transform: translateY(150vh);
  }
}

/* SECTION SIX */

@keyframes sky6 {
  0%,
  100% {
    z-index: 6;
    visibility: visible;
  }

  0% {
    transform: translateY(-150vh) scale(0);
  }

  30%, 50% {
    transform: none;
  }

  100% {
    transform: translateY(150vh) scale(0);
  }
}

/* SECTION SEVEN */

@keyframes sky7 {
  0%,
  100% {
    z-index: 7;
    visibility: visible;
  }
  0% {
    transform: translateY(-150vh);
  }
  25%,
  50% {
    transform: none;
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-150vh);
  }
}

/* SECTION EIGHT */

@keyframes sky8 {
  0%,
  100% {
    z-index: 8;
  }
  0% {
    visibility: visible;
    transform: scale(0);
  }
  30%,
  65% {
    transform: scale(1);
  }
  99% {
    visibility: visible;
    transform: scale(6);
  }
  100% {
    transform: scale(6);
    visibility: hidden;
  }
}

/* SECTION TEN */

@keyframes skylast {
  0%, 100% {
    z-index: var(--sections);
    visibility: visible;
  }
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes mint {
  to {
    bottom: calc(100% - var(--top));
    scale: 100%;
  }
}

@keyframes growNeonCircle {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes flickerOn2 {
  70% {
    color: var(--neonOff);
    text-shadow: var(--neonShadow);
  }
}

@keyframes flickerOn {
  25% {
    color: var(--neonOff);
    text-shadow: var(--neonShadow);
  }
}
@keyframes offsetText {
  from {
    offset-distance: 0;
  }

  to {
    offset-distance: 100%;
  }
}

@keyframes changeTextColor {
  16% {
    color: var(--textColor1);
  }
  32% {
    color: var(--textColor2);
  }
  48% {
    color: var(--textColor3);
  }
  64% {
    color: var(--textColor4);
  }
  80% {
    color: var(--textColor5);
  }
  96% {
    color: var(--textColor6);
  }
}

.skysection8, .content:last-of-type {
  .char {
    aspect-ratio: 1/1;
    font-weight: 500;
    position: absolute;
    offset-distance: 0;
    color: var(--textColor6);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  }
}

.content {
  width: clamp(100px, 90%, 900px);
  .sl_link {
    --size: 4px;
		transition: text-shadow 1.5s;
		text-decoration: underline;
		text-decoration-style: dotted;
		text-underline-offset: 2px;
	}
	.sl_link:hover {
    text-shadow:
      1px 1px 1px rgba(0, 0, 0, 0.4),
      0 0 var(--size) var(--shadow),
      1px 1px var(--size) var(--shadow),
      -1px -1px var(--size) var(--shadow);
	}
}

p {
  font-size: clamp(0.6875rem, 0.5313rem + 0.5vw, 0.8125rem);
  margin: 0.5em 0;
  font-weight: 400;
  letter-spacing: 1.5px;
  text-align: center;
  line-height: 1.2;
}

h1, h2, h3, h4 {
  text-align: center;
  line-height: 1.1;
}

h1 {
  font-size: clamp(1.375rem, 1.0625rem + 1vw, 1.625rem);
  margin: 1em 0;
  letter-spacing: 8px;
}

h2 {
  font-size: clamp(1.25rem, 0.9375rem + 1vw, 1.5rem);
  margin: 0.8em 0;
  letter-spacing: 3px;
}

h3 {
  font-size: clamp(1rem, 0.6875rem + 1vw, 1.25rem);
  padding: 0.8em 0;
  letter-spacing: 3px;
}

h4 {
  font-size: clamp(0.75rem, 0.4375rem + 1vw, 1rem);
  padding: 0.15em 0;
  letter-spacing: 3px;
}

input.hidden {
  opacity: 0;
  display: none;
}

// SCENE

.wall,
.roof,
.ground {
  pointer-events: none;
}

.scene {
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform-origin: 50% 50% 70vmin;
}

.neonCircle {
  font-size: var(--baseFont);
  height: calc(var(--fontMultiplier)*var(--font)*3.5);
  transition-property: box-shadow, border;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
  overflow: visible;
  aspect-ratio: 1/1;
  border-radius: 50%;
  --border-w: 0.08em;
  --color0: #ffffff;
  --color1: #dedede;
  --color2: #fff7e1;
  border: .03em solid var(--color1);
  box-shadow: 0px 0px 0px var(--border-w) var(--color0), 0px 0px var(--border-w) calc(var(--border-w)*10/8) var(--color1), inset 0px 0px var(--border-w) calc(var(--border-w)/2) var(--color1), 0px 0px calc(var(--border-w) * 12.25)var(--border-w) var(--color2), inset 0px 0px calc(var(--border-w) * 12.25)var(--border-w) var(--color2);
  position: absolute;
}

.neonGlow {
  transition-property: color, text-shadow;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
  --neonGlow:
    var(--neonShadow),
    .1vw 0vw .25vw var(--color0), .2vw 0vw .25vw var(--color0), .4vw 0vw .25vw var(--color0),
    .1vw 0vw .1vw var(--color1), .2vw 0vw .1vw var(--color1), .4vw 0vw 0vw var(--color1),
    .1vw 0vw 1vw var(--color1), .2vw 0vw 2vw var(--color1), .4vw 0vw 2vw var(--color1), .2vw 0vw 5vw var(--color1),
    .1vw 0vw .5vw var(--color2), .2vw 0vw .5vw var(--color2), .4vw 0vw 6vw var(--color2),
    .1vw 0vw 10vw var(--color2), .2vw 0vw 30vw var(--color2), .4vw 0vw 10vw var(--color2);
  color: var(--color0);
  text-shadow: var(--neonGlow);
}

.scene:has(#toggleSign:checked) {
  .flicker {
    animation: none !important;
  }

  .neonCircle {
    --color0: rgb(52, 52, 52);
    --color1: rgba(0, 0, 0, 0.184);
    --color2: transparent;
  }

  .neonSign {
    --neonShade: rgba(95, 89, 102, 0);
  }

  .neonGlow {
    text-shadow: var(--neonShadow);
    color: rgb(52, 52, 52);
  }

  .ground {
    background-color: #333333;
    --groundColor0: transparent;
    --groundColor1: transparent;
  }

  .wall::after {
    --wallShade0: var(--wallShade1);
    box-shadow: inset 0 0 5em 0.1em rgba(0, 0, 0, 0.35);
  }

  .roof {
    box-shadow: none;
    --wallShade0: var(--wallShade1);
  }
}

.pinkNeon {
  --color0: #ffb6e3;
  --color1: #ff9fe956;
  --color2: #ff4db2d0;
}

.blueNeon {
  --color0: #28D7FE;
  --color1: #345cfb4a;
  --color2: rgba(61, 100, 255, 0.328);
}

.skySign {
  position: relative;
  top: -0.07em;
  letter-spacing: 5px;
  font-size: calc(var(--baseFont) * 5/3);
  font-family: leafery;
  font-weight: 600;
}

.luxurySign {
  position: relative;
  bottom: 0.5em;
  letter-spacing: 0.15em;
  font-size: var(--baseFont);
  font-family: mohaw;
}

.wall {
  position: absolute;
  display: var(--display);
}

.wall::after {
  display: inherit;
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  --units: 0.5vmax;
  --brick1: #aa5959;
  --brick1-light: #af6a6a;
  --brick2: #a15959;
  --brick2-light: #ab6d6d;
  --lines: #e9e9e9;
  --gp-ln: 50%/calc(var(--units) * 10) calc(var(--units) * 5);
  --gp-cn: 50%/calc(var(--units) * 5) calc(var(--units) * 5);
  --shade: radial-gradient(var(--wallShade1), var(--wallShade1) 100%);
  background:
    var(--shade) 100%,
    repeating-conic-gradient(from 90deg at 95% 55%, var(--lines) 0% 25%, #fff0 0% 100%) var(--gp-cn),
    repeating-linear-gradient(180deg,
      var(--lines) 0 5%,
      transparent 0 50%,
      var(--lines) 0 55%,
      var(--brick1-light) 0 85%,
      var(--brick1) 85% 100%) var(--gp-ln),
    repeating-linear-gradient(90deg,
      var(--brick2) 0 47.5%,
      var(--lines) 0 50%,
      var(--brick2-light) 50% 97.5%,
      var(--lines) 0 100%) var(--gp-ln);
}

.wall:nth-of-type(1)::after,
.wall:nth-of-type(3)::after {
  transition-property: --wallShade0, box-shadow;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
  transition-duration: 1s;
}

.wall:nth-of-type(1)::after,
.wall:nth-of-type(2)::after {
  --gp-ln: 50%/calc(var(--units) * 5) calc(var(--units) * 10);
  --gp-cn: 25vmin 50%/calc(var(--units) * 5) calc(var(--units) * 5);
  --shade: radial-gradient(var(--wallShade1), var(--wallShade1) 100%);
  background:
    var(--shade) 100%,
    repeating-conic-gradient(from 180deg at 55% 95%, var(--lines) 0% 25%, #fff0 0% 100%) var(--gp-cn),
    repeating-linear-gradient(90deg,
      var(--lines) 0 5%,
      transparent 0 50%,
      var(--lines) 0 55%,
      var(--brick1-light) 0 85%,
      var(--brick1) 85% 100%) var(--gp-ln),
    repeating-linear-gradient(180deg,
      var(--brick2) 0 47.5%,
      var(--lines) 0 50%,
      var(--brick2-light) 50% 97.5%,
      var(--lines) 0 100%) var(--gp-ln);
}

.wall:nth-of-type(3)::after {
  --shade: radial-gradient(var(--wallShade0), var(--wallShade1) 100%) !important;
}

.gradient {
  --shade: radial-gradient(var(--wallShade0), var(--wallShade1) 100%);
}

.wall:nth-of-type(1) {
  left: 0;
  top: 0;
  width: 50vmin;
  height: 50%;
  transform: rotateY(-90deg) scale(2);
  transform-origin: left top;
}

.wall:nth-of-type(2) {
  right: 0;
  top: 0;
  width: 50vmin;
  height: 50%;
  transform: rotateY(90deg) scale(2);
  transform-origin: top right;
}

.wall:nth-of-type(3) {
  left: 0;
  top: 0;
  width: 50%;
  height: 50vmin;
  transform: rotateX(90deg) scale(2);
  transform-origin: left top;
}

.wall:nth-of-type(4) {
  left: 0;
  bottom: 0;
  width: 50%;
  height: 50vmin;
  transform: rotateX(-90deg) scale(2);
  transform-origin: left bottom;
}

.scene>.neonSign {
  display: var(--display);
  transform: rotateX(90deg);
  transform-origin: left top;
  height: 100vmin;
  width: 100%;

  .luxurySign .flicker:first-child {
    animation: flicker1 5.5s infinite;
    animation-delay: 10s;
  }

  .neonScript {
    transform: rotateZ(180deg);
  }

  .luxurySign .flicker:nth-child(2) {
    animation: flicker2 20s infinite;
  }
}

.neonScript {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  line-height: 1.2;

  span {
    display: inline !important;
  }
}

.neonSign {
  --fontMultiplier: 10;
  --font: clamp(min(0.6vw, 0.5rem), calc(16px - 0.6vw), max(2vw, 1rem));
  --baseFont: calc(var(--fontMultiplier)*var(--font));
  aspect-ratio: 1/1;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  bottom: 0;
  --neonOff: rgb(67, 67, 67);
  --neonShade: rgba(141, 133, 150, 0.7);
  --neonShadow: 0 3rem 15rem, 0 0 0.1rem, 0 0 2em var(--neonShade), 0 0 0.6em var(--neonShade), 0 0 1em var(--neonShade), 0 1rem 0.3rem #000;
  backface-visibility: hidden;
  * {
    backface-visibility: hidden;
  }
}

.ground {
  transition: background-color cubic-bezier(0.1, 0.7, 1.0, 0.1) 1s, --groundColor0 cubic-bezier(0.1, 0.7, 1.0, 0.1) 1s, --groundColor1 cubic-bezier(0.1, 0.7, 1.0, 0.1) 1s;
  aspect-ratio: 1;
  backface-visibility: hidden;
  z-index: 0;
  --w: 100%;
  background-color: #777;
  --diffusion: 10%;
  --opening: 30;
  --groundColor0: rgba(0, 0, 0, 0.7);
  --groundColor1: rgba(255, 255, 255, 0.2);
  mask-image: radial-gradient(#ffffff90, #0000 70%);
  background-image:
    conic-gradient(from calc(180deg / (-100 / var(--opening))) at 50% 50%,
      var(--groundColor0) 0%,
      var(--groundColor1) var(--diffusion),
      var(--groundColor1) calc(var(--opening) * 1% - var(--diffusion)),
      var(--groundColor0) calc(var(--opening) * 1%),
      var(--groundColor0) 100%),
    repeating-linear-gradient(45deg, #fff1, #0001, #fff1 10px), repeating-linear-gradient(-45deg, #fff1, #0001, #fff1 10px);
  background-blend-mode: soft-light, normal, normal;
  position: absolute;
  width: var(--w);
  perspective: inherit;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(3.2);
  display: var(--display);
}

.floor {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.roof {
  transition: box-shadow .7s ease-in-out, --wallShade0 .9s ease-in-out;
  display: var(--display);
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateZ(100vmin);
  background-image: radial-gradient(var(--wallShade0), color-mix(in srgb, var(--wallShade0) 65%, #000) 70%), linear-gradient(to bottom right, black 50%, var(--ceilColor) 50%);
  background-size: 100% 100%, 3px 3px;
  --neo: rgb(96, 96, 96);
  box-shadow: inset 3em 3em 5em var(--neo), inset -3em -3em 5em var(--neo);
}

`;

export default SkyTop;