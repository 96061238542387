import React from "react";

const Range = (props) => {
  return (
    <div className="parts_menu rm_boxes filter_slider">
      <input
        id={`bool${props.name}`}
        type="checkbox"
        checked={props.boolean}
        onChange={(e) => props.onSetFilterOption("boolean", e.target.checked)}
      />
      <label htmlFor={`bool${props.name}`}>
        <span></span>
      </label>
      <span className="slider_label">{props.label}</span>
      <input
        className="slider"
        type="range"
        min={props.types.range[0]}
        max={props.types.range[1]}
        value={props.slider}
        step={props.step || 1}
        onChange={(e) =>
          props.onSetFilterOption("slider", parseFloat(e.target.value))
        }
      />
    </div>
  );
};

export default Range;
