import { RGBToHSL } from "utils";

export class Sampler1 {
  constructor(p5, img, particles, scale, posX, posY, oct, key) {
    this.p5 = p5;
    this.img = img;
    this.scale = scale;
    this.particles = particles;
    this.posX = this.p5.constrain(posX, 0, this.p5.width);
    this.posY = this.p5.constrain(posY, 0, this.p5.height);
    this.wid = 20;
    this.drag = false;
    this.trig = false;
    this.theta = 0;
    this.phase = 0;
    this.oct = oct;
    this.key = key;
    this.particles.colorMode(this.p5.HSL);
  }

  anim() {
    let shift = 0.35;
    if (this.trig) {
      if (this.phase < 1) {
        this.phase += shift;
      } else {
        this.phase = 1;
      }
    } else {
      if (this.phase > 0) {
        this.phase -= shift;
      } else {
        this.phase = 0;
      }
    }
  }

  color() {
    this.hsl = RGBToHSL(
      ...this.img.get(this.posX / this.scale, this.posY / this.scale)
    );
    this.hue = this.hsl[0];
    this.saturation = this.hsl[1];
    this.bright = this.hsl[2];
    //console.log(this.hue, this.saturation, this.bright);
  }

  wave() {
    if (this.trig) {
      this.xSpace = 1;
      this.amplitude = 0;
      this.period = this.wid / 2;
      this.dx = (this.p5.TWO_PI / this.period) * this.xSpace;
      this.yValues = new Array(this.p5.floor(this.wid / this.xSpace));
      this.theta += 0.4;
      this.waveX = this.theta;
      this.amplitude = 6 * this.phase;
      for (let i = 0; i < this.yValues.length; i++) {
        this.yValues[i] = this.p5.sin(this.waveX) * this.amplitude;
        this.waveX += this.dx;
      }
      this.particles.noStroke();
      this.particles.fill(255, 255, 255);

      for (let x = 0; x < this.yValues.length; x++) {
        this.particles.ellipse(
          this.posX - this.wid / 2 + x * this.xSpace,
          this.posY + this.yValues[x],
          2
        );
      }
    }
  }

  show(tx, ty) {
    if (this.drag) {
      this.posX = this.p5.constrain(tx, 0, this.p5.width);
      this.posY = this.p5.constrain(ty, 0, this.p5.height);
    }
    this.particles.fill(this.hsl);
    if (this.trig) {
      this.particles.stroke(255);
      this.particles.strokeWeight(3);
    } else {
      this.particles.stroke(0);
      this.particles.strokeWeight(1);
    }
    this.particles.square(
      this.posX - this.wid / 2,
      this.posY - this.wid / 2,
      this.wid
    );
    this.particles.noFill();
  }

  play() {
    this.note = this.p5.int(
      this.p5.map(
        this.hue,
        0,
        360,
        this.key + 12 * this.oct,
        this.key + 12 + 12 * this.oct
      )
    );
    //this.amp = this.p5.int(this.p5.map(this.bright, 0, 100, 0, 1));
  }

  resample(randX1, randY1, randX2, randY2) {
    this.posX = this.p5.random(randX1, randX2);
    this.posY = this.p5.random(randY1, randY2);
  }

  randomize(w, h) {
    this.posX = this.p5.random(w);
    this.posY = this.p5.random(h);
  }

  pressed(tx, ty) {
    let d1 = this.p5.dist(tx, ty, this.posX, this.posY);
    if (d1 < this.wid / 2) {
      this.drag = true;
    }
  }

  released() {
    this.drag = false;
  }
}

export class Sampler2 {
  constructor(p5, historyLength, minValue, maxValue, particles) {
    this.p5 = p5;
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.historyLength = historyLength;
    this.history = new Float32Array(historyLength);
    this.index = 0;
    this.particles = particles;
    this.trig = false;
  }

  addSample(sample) {
    this.history[this.index] = sample;
    this.index = (this.index + 1) % this.historyLength;
  }

  getNormalizedSample(offset) {
    let i = (this.index + offset) % this.historyLength;
    let range = this.maxValue - this.minValue;
    return (this.history[i] - this.minValue) / range;
  }

  draw(width, height, dir) {
    this.particles.push();
    this.particles.noFill();
    this.particles.strokeWeight(1);
    this.particles.stroke(0);
    if (dir === "u") {
      this.particles.translate(0, this.p5.height / 2);
      this.particles.line(
        0,
        this.p5.height / 4,
        this.p5.width,
        this.p5.height / 4
      );
    } else {
      this.particles.line(
        0,
        this.p5.height / 4,
        this.p5.width,
        this.p5.height / 4
      );
    }
    this.particles.stroke(255);
    this.particles.beginShape();
    let range = this.maxValue - this.minValue;
    if (this.trig) this.particles.strokeWeight(3);
    for (let offset = 0; offset < this.historyLength; offset++) {
      let i = (this.index + offset) % this.historyLength;
      let x = (offset * width) / this.historyLength;
      let normalized = (this.history[i] - this.minValue) / range;
      let y = height - normalized * height;
      this.nX = this.p5.map(x, 0, width, -1, 1);
      this.nY = this.p5.map(y, 0, height, -1, 1);
      this.particles.vertex(x, y);
    }
    this.particles.endShape();
    this.particles.pop();
  }
}

export class Sampler3 {
  constructor(p5, img, particles, scale, pitch, interval, posX, posY) {
    this.p5 = p5;
    this.img = img;
    this.scale = scale;
    this.pitch = pitch;
    this.interval = interval;
    this.particles = particles;
    this.posX = posX;
    this.posY = posY;
    this.wid = 20;
    this.drag = false;
    this.trig = false;
    this.theta = 0;
    this.phase = 0;
  }

  color() {
    this.bright = this.p5.brightness(
      this.img.get(this.posX / this.scale, (this.img.height - this.posY) / this.scale)
    );
    // this.two = this.p5.brightness(
    //   this.vid.get(this.posX / this.scale, (this.img.height - this.posY) / this.scale)
    // );
    // console.log(this.two);
  }

  anim() {
    let shift = 0.35;
    if (this.trig) {
      if (this.phase < 1) {
        this.phase += shift;
      } else {
        this.phase = 1;
      }
    } else {
      if (this.phase > 0) {
        this.phase -= shift;
      } else {
        this.phase = 0;
      }
    }
  }

  show(tx, ty) {
    if (this.drag) {
      this.posX = this.p5.constrain(tx, 0, this.p5.width);
      this.posY = this.p5.constrain(ty, 0, this.p5.height);
    }

    this.particles.colorMode(this.p5.RGB);

    if (this.trig) {
      this.particles.strokeWeight(3);
    } else {
      this.particles.strokeWeight(1);
    }
    if (this.bright > 0) {
      this.particles.stroke(0, 0, 0);
    } else {
      this.particles.stroke(255, 255, 255);
    }
    this.particles.noFill();

    this.particles.square(
      this.posX - this.wid / 2,
      this.posY - this.wid / 2,
      this.wid
    );

    this.particles.noFill();
    this.bright === 0
      ? this.particles.stroke(0, 0, 0)
      : this.particles.stroke(255, 255, 255);
  }

  wave() {
    if (this.trig) {
      this.xSpace = 1;
      this.amplitude = 0;
      this.period = this.wid / 2;
      this.dx = (this.p5.TWO_PI / this.period) * this.xSpace;
      this.yValues = new Array(this.p5.floor(this.wid / this.xSpace));
      this.theta += 0.4;
      this.waveX = this.theta;
      this.amplitude = 6 * this.phase;
      for (let i = 0; i < this.yValues.length; i++) {
        this.yValues[i] = this.p5.sin(this.waveX) * this.amplitude;
        this.waveX += this.dx;
      }
      this.particles.noStroke();
      if (this.bright > 0) {
        this.particles.fill(0, 0, 0);
      } else {
        this.particles.fill(255, 255, 255);
      }

      for (let x = 0; x < this.yValues.length; x++) {
        this.particles.ellipse(
          this.posX - this.wid / 2 + x * this.xSpace,
          this.posY + this.yValues[x],
          2
        );
      }
    }
  }

  resample(randX1, randY1, randX2, randY2) {
    this.posX = this.p5.random(randX1, randX2);
    this.posY = this.p5.random(randY1, randY2);
  }

  randomize(w, h) {
    this.posX = this.p5.random(w);
    this.posY = this.p5.random(h);
  }

  pressed(tx, ty) {
    let d1 = this.p5.dist(tx, ty, this.posX, this.posY);
    if (d1 < this.wid / 2) {
      this.drag = true;
    }
  }

  released() {
    this.drag = false;
  }
}

export class Sampler4 {
  constructor(p5, img, particles, notes, scale, interval, ind, oct, key) {
    this.p5 = p5;
    this.img = img;
    this.particles = particles;
    this.notes = notes;
    this.scale = scale;
    this.interval = interval;
    this.wid = 20;
    this.posX = this.p5.random(this.p5.width);
    this.posY = this.p5.random(this.p5.height);
    this.i = ind;
    this.dir = this.p5.int(this.p5.random(4));
    this.sp = this.i * 0.25 + 4;
    this.trig = false;
    this.theta = 0;
    this.phase = 0;
    this.oct = oct;
    this.key = key;
    this.drag = false;
  }

  anim() {
    let shift = 0.35;
    if (this.trig) {
      if (this.phase < 1) {
        this.phase += shift;
      } else {
        this.phase = 1;
      }
    } else {
      if (this.phase > 0) {
        this.phase -= shift;
      } else {
        this.phase = 0;
      }
    }
  }

  wave(moving) {
    this.moving = moving;
    if (this.trig) {
      this.xSpace = 1;
      this.amplitude = 0;
      this.period = this.wid / 2;
      this.dx = (this.p5.TWO_PI / this.period) * this.xSpace;
      this.yValues = new Array(this.p5.floor(this.wid / this.xSpace));
      this.theta += 0.4;
      this.waveX = this.theta;
      this.amplitude = 6 * this.phase;
      for (let i = 0; i < this.yValues.length; i++) {
        this.yValues[i] = this.p5.sin(this.waveX) * this.amplitude;
        this.waveX += this.dx;
      }
      this.particles.noStroke();
      this.particles.fill(255, 255, 255);
      if (moving) {
        if (this.dir === 0) {
          for (let x = 0; x < this.yValues.length; x++) {
            this.particles.ellipse(
              this.posX - this.sp - this.wid / 2 + x * this.xSpace,
              this.posY + this.yValues[x],
              2
            );
          }
        } else if (this.dir === 1) {
          for (let x = 0; x < this.yValues.length; x++) {
            this.particles.ellipse(
              this.posX - this.wid / 2 + x * this.xSpace,
              this.posY - this.sp + this.yValues[x],
              2
            );
          }
        } else if (this.dir === 2) {
          for (let x = 0; x < this.yValues.length; x++) {
            this.particles.ellipse(
              this.posX + this.sp - this.wid / 2 + x * this.xSpace,
              this.posY + this.yValues[x],
              2
            );
          }
        } else if (this.dir === 3) {
          for (let x = 0; x < this.yValues.length; x++) {
            this.particles.ellipse(
              this.posX - this.wid / 2 + x * this.xSpace,
              this.posY + this.sp + this.yValues[x],
              2
            );
          }
        }
      } else {
        for (let x = 0; x < this.yValues.length; x++) {
          this.particles.ellipse(
            this.posX - this.wid / 2 + x * this.xSpace,
            this.posY + this.yValues[x],
            2
          );
        }
      }
    }
  }

  move() {
    if (this.dir === 0) {
      this.posX += this.sp;
    } else if (this.dir === 1) {
      this.posY += this.sp;
    } else if (this.dir === 2) {
      this.posX -= this.sp;
    } else if (this.dir === 3) {
      this.posY -= this.sp;
    }
    if (this.posX > this.p5.width) {
      this.posX = 0;
    } else if (this.posX < 0) {
      this.posX = this.p5.width;
    }
    if (this.posY > this.p5.height) {
      this.posY = 0;
    } else if (this.posY < 0) {
      this.posY = this.p5.height;
    }
  }

  show(tx, ty) {
    if (this.drag && !this.moving) {
      this.posX = this.p5.constrain(tx, 0, this.p5.width);
      this.posY = this.p5.constrain(ty, 0, this.p5.height);
    }
    this.particles.colorMode(this.p5.RGB);
    if (this.trig) {
      this.particles.stroke(255, 255, 255);
      this.particles.strokeWeight(3);
    } else {
      this.particles.stroke(0, 0, 0);
      this.particles.strokeWeight(1);
    }
    this.particles.noFill();
    this.particles.square(
      this.posX - this.wid / 2,
      this.posY - this.wid / 2,
      this.wid
    );
    this.particles.noFill();
  }

  randomize(w, h) {
    this.posX = this.p5.random(w);
    this.posY = this.p5.random(h);
    this.dir = this.p5.int(this.p5.random(4));
  }

  play() {
    this.bright = this.p5.brightness(
      this.img.get(this.posX / this.scale, this.posY / this.scale)
    );
    this.note = this.p5.int(
      this.p5.map(
        this.key + this.i * this.interval,
        this.key,
        this.key + this.notes * this.interval,
        this.key + 12 * this.oct,
        this.key + 24 + 12 * this.oct
      )
    );
  }

  resample(randX1, randY1, randX2, randY2) {
    this.posX = this.p5.random(randX1, randX2);
    this.posY = this.p5.random(randY1, randY2);
  }

  pressed(tx, ty) {
    let d1 = this.p5.dist(tx, ty, this.posX, this.posY);
    if (d1 < this.wid / 2) {
      this.drag = true;
    }
  }

  released() {
    this.drag = false;
  }
}
