import React from "reactn";
import styled from "styled-components";

const Display = ({ randomBg, resetBg, uploadBg }) => {
  function handleImage(e) {
    const file = e.target.files[0];
    if (!file) return;
    const validTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'image/svg+xml', 'image/bmp', 'image/x-icon'];
    if (!validTypes.includes(file.type)) {
      e.target.parentElement.classList.add("shake_err");
      setTimeout(() => {
        e.target.parentElement.classList.remove("shake_err");
      }, 700);
      return;
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      const mime = event.target.result.match(/^data:(.*);base64,/);
      if (!mime || !['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml', 'image/bmp', 'image/x-icon'].includes(mime[1])) {
        e.target.parentElement.classList.add("shake_err");
        setTimeout(() => {
          e.target.parentElement.classList.remove("shake_err");
        }, 700);
        return;
      }
      uploadBg(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = '';
  }
  //
  // <form className="options-row">
  //   <div>
  //     <input
  //       type="radio"
  //       id="0.75"
  //       checked={zoom === "0.75"}
  //       name="zoom"
  //       value="0.75"
  //       onChange={(e) => handleZoom(e)}
  //     />
  //     <label htmlFor="0.75">
  //       <span>Custom</span>
  //     </label>
  //   </div>
  //   <div>
  //     <input
  //       type="radio"
  //       id="1"
  //       checked={zoom === "1"}
  //       name="zoom"
  //       value="1"
  //       onChange={(e) => handleZoom(e)}
  //     />
  //     <label htmlFor="1">
  //       <span>Random</span>
  //     </label>
  //   </div>
  // </form>

  // theme colors

  // filters

  // filter radio
  // overlay backdrop-filter:
  // sepia
  // invert
  // greyscale
  // solid color

  // blend dropdown
  // overlay mix-blend-mode:
  // normal;
  // multiply;
  // screen;
  // overlay;
  // darken;
  // lighten;
  // color-dodge;
  // color-burn;
  // hard-light;
  // soft-light;
  // difference;
  // exclusion;
  // hue;
  // saturation;
  // color;
  // luminosity;
  // plus-darker;
  // plus-lighter;

  // adjust sliders
  // app filter:
  // brightness
  // contrast
  // saturation

  return (
    <Div>
      <div id="display_wrap">
        <div className="transform-gpu" id="display_head_wrap">
          <div className="pixel-border_head" id="display_head">
            <div className="dither-border" id="top"></div>
            <div className="dither-border" id="bottom"></div>
            <div className="dither-border" id="left"></div>
            <div className="dither-border" id="right"></div>
            <label htmlFor="bgSelectInput">
              <input
                id="bgSelectInput"
                type="file"
                accept="image/png, image/jpeg, image/gif, image/webp, image/svg+xml, image/bmp, image/x-icon"
                onChange={(e) => {
                  handleImage(e);
                }}
              />

              <div
                className="center_screen pointer"
                id="bgSelector"
              ></div>
            </label>
          </div>
        </div>
        <div id="display_body_wrap">
          <div id="display_body">

            <div id="display_chin_wrap">
              <div className="pixel-border_chin display_chin grayinsetshadow"></div>
              <div className="pixel-border_chin display_chin blackoutline"></div>
              <div className="pixel-border_chin display_chin leftwhiteshadow"></div>
              <div className="pixel-border_chin display_chin topbottomshadows">
                <div className="display_btns">
                  <div id="display_button"></div>
                  <div id="display_led"></div>
                </div>
              </div>
            </div>

            <div id="display_neck"></div>

            <div className="pixel-border_shoulder" id="display_shoulder"></div>
          </div>
        </div>
      </div>
      <div className="set_buttons_wrap">
        <div className="set_buttons">
          <label htmlFor="bgeditbtn">
            <input
              id="bgeditbtn"
              type="file"
              accept="image/png, image/jpeg, image/gif, image/webp, image/svg+xml, image/bmp, image/x-icon"
              onChange={(e) => {
                handleImage(e);
              }}
            />

            <div id="bgeditbtn" className="button">
              Browse...
            </div>
          </label>

          <div
            id="bgglitchbtn"
            className="button"
            onPointerUp={() => randomBg()}
          >
            Glitch
          </div>
          <div
            id="bgresetbtn"
            className="button disabled"
            onPointerUp={() => resetBg()}
          >
            Reset
          </div>
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  input[type="file"] {
    display: none;
  }
  label[for="bgSelectInput"] {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .display_btns {
    position: absolute;
    top: 1.5px;
    display: flex;
    padding-left: 10px;
    div + div {
      margin-left: 4px;
    }
  }

  #display_button {
    margin-top: 0.5px;
    width: 10px;
    height: 4px;
    background: var(--colorgrad3);
    border-right: 1px solid var(--colorgrad0);
    box-shadow: inset 0 -1px 0px var(--colorgrad3),
      inset 0 1px 0px var(--colorgrad6), inset 1px 0px 0px var(--colorgrad6);
  }
  #display_led {
    margin-top: 0.5px;
    width: 4px;
    height: 4px;
    background: #01ff00;
    box-shadow: inset 0 -1px 0px var(--colorgrad8), inset 0 1px 0px var(--colorgrad0),
      inset 1px 0px 0px var(--colorgrad0), inset -1px 0px 0px var(--colorgrad8);
  }
  #display_body_wrap {
    display: block;
    position: relative;
    height: 26px;
    width: 198px;
    border-top: 1px solid var(--colorgrad8);
    overflow: hidden;
  }
  #display_body {
    position: relative;
    top: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pixel-border_shoulder {
    position: relative;
    clip-path: polygon(
      0px calc(100% - 1px),
      1px calc(100% - 1px),
      1px 100%,
      calc(100% - 1px) 100%,
      calc(100% - 1px) calc(100% - 1px),
      100% calc(100% - 1px),
      100% 1px,
      calc(100% - 1px) 1px,
      calc(100% - 1px) 0px,
      1px 0px,
      1px 1px,
      0px 1px
    );
  }
  .pixel-border_shoulder {
    border: 1px solid transparent;
  }
  .pixel-border_shoulder::after {
    content: "";
    position: absolute;
    margin: -1px;
    clip-path: polygon(
      0px calc(100% - 1px),
      1px calc(100% - 1px),
      1px 100%,
      calc(100% - 1px) 100%,
      calc(100% - 1px) calc(100% - 1px),
      100% calc(100% - 1px),
      100% 1px,
      calc(100% - 1px) 1px,
      calc(100% - 1px) 0px,
      1px 0px,
      1px 1px,
      0px 1px,
      0px 50%,
      1px 50%,
      1px 1px,
      calc(100% - 1px) 1px,
      calc(100% - 1px) calc(100% - 1px),
      1px calc(100% - 1px),
      1px 50%,
      0px 50%
    );
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--colorgrad6);
    display: block;
    pointer-events: none;
  }
  #display_shoulder {
    background: var(--colorgrad3);
    width: 112px;
    height: 6px;
    box-shadow: inset 1px 1px 0px var(--colorgrad0);
  }
  .set_buttons_wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 0 2px 0;
    width: 70%;
    max-width: 100%;
  }
  .drop_wrap {
    width: 100%;
  }
  .set_buttons {
    padding: 0 1px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    .button {
      width: auto;
    }
    > * + * {
      margin-left: 10px;
    }
    > * {
      flex: 4 1 50px;
    }
  }
  #display_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  #display_head_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    filter: drop-shadow(0 1px 0 var(--colorgrad8));
    overflow: hidden;
  }
  .pixel-border_chin {
    position: relative;
    border: 1px solid transparent;
    clip-path: polygon(
      0px calc(100% - 4px),
      1px calc(100% - 4px),
      1px calc(100% - 3px),
      2px calc(100% - 3px),
      2px calc(100% - 2px),
      3px calc(100% - 2px),
      3px calc(100% - 1px),
      4px calc(100% - 1px),
      4px 100%,
      calc(100% - 4px) 100%,
      calc(100% - 4px) calc(100% - 1px),
      calc(100% - 3px) calc(100% - 1px),
      calc(100% - 3px) calc(100% - 2px),
      calc(100% - 2px) calc(100% - 2px),
      calc(100% - 2px) calc(100% - 3px),
      calc(100% - 1px) calc(100% - 3px),
      calc(100% - 1px) calc(100% - 4px),
      100% calc(100% - 4px),
      100% 4px,
      calc(100% - 1px) 4px,
      calc(100% - 1px) 3px,
      calc(100% - 2px) 3px,
      calc(100% - 2px) 2px,
      calc(100% - 3px) 2px,
      calc(100% - 3px) 1px,
      calc(100% - 4px) 1px,
      calc(100% - 4px) 0px,
      4px 0px,
      4px 1px,
      3px 1px,
      3px 2px,
      2px 2px,
      2px 3px,
      1px 3px,
      1px 4px,
      0px 4px
    );
  }
  .pixel-border_chin::after {
    content: "";
    position: absolute;
    margin: -1px -1px;
    clip-path: polygon(
      0px calc(100% - 4px),
      1px calc(100% - 4px),
      1px calc(100% - 2px),
      2px calc(100% - 2px),
      2px calc(100% - 1px),
      4px calc(100% - 1px),
      4px 100%,
      calc(100% - 4px) 100%,
      calc(100% - 4px) calc(100% - 1px),
      calc(100% - 2px) calc(100% - 1px),
      calc(100% - 2px) calc(100% - 2px),
      calc(100% - 1px) calc(100% - 2px),
      calc(100% - 1px) calc(100% - 4px),
      100% calc(100% - 4px),
      100% 4px,
      calc(100% - 1px) 4px,
      calc(100% - 1px) 2px,
      calc(100% - 2px) 2px,
      calc(100% - 2px) 1px,
      calc(100% - 4px) 1px,
      calc(100% - 4px) 0px,
      4px 0px,
      4px 1px,
      2px 1px,
      2px 2px,
      1px 2px,
      1px 4px,
      0px 4px,
      0px 50%,
      2px 50%,
      2px 5px,
      3px 5px,
      3px 3px,
      5px 3px,
      5px 2px,
      calc(100% - 5px) 2px,
      calc(100% - 5px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 5px,
      calc(100% - 2px) 5px,
      calc(100% - 2px) calc(100% - 5px),
      calc(100% - 3px) calc(100% - 5px),
      calc(100% - 3px) calc(100% - 3px),
      calc(100% - 5px) calc(100% - 3px),
      calc(100% - 5px) calc(100% - 2px),
      5px calc(100% - 2px),
      5px calc(100% - 3px),
      3px calc(100% - 3px),
      3px calc(100% - 5px),
      2px calc(100% - 5px),
      2px 50%,
      0px 50%
    );
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    pointer-events: none;
  }
  #display_chin_wrap {
    position: relative;
    top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 86px;
    height: 18px;
    &:before {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      left: -4px;
      background: var(--colorgrad4);
      z-index: 1;
      border-left: 1px solid var(--colorgrad6);
    }
  }
  .display_chin {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .display_chin.grayinsetshadow {
    left: 0px;
    top: -1px;
    z-index: 10;
    &:after {
      background: var(--colorgrad6);
    }
    &:before {
      width: 2px;
      height: 3px;
      position: absolute;
      right: 1px;
      bottom: 0;
      content: "";
      background: var(--colorgrad6);
    }
  }
  .display_chin.blackoutline {
    z-index: 1;
    &:after {
      background: var(--colorgrad8);
    }
  }
  .display_chin.leftwhiteshadow {
    background: var(--colorgrad3);
    width: 12px;
    left: -3px;
    top: -1px;
    &:after {
      margin: -1px;
      background: linear-gradient(
          to right,
          var(--colorgrad0) 0px,
          var(--colorgrad0) 5px,
          transparent 5px,
          transparent 100%
        ),
        linear-gradient(
          to top,
          var(--colorgrad6) 0px,
          var(--colorgrad6) 2px,
          transparent 2px,
          transparent 100%
        );
    }
  }
  .display_chin.topbottomshadows {
    background: linear-gradient(
      to right,
      transparent 0px,
      transparent 10px,
      var(--colorgrad3) 10px,
      var(--colorgrad3) 100%
    );
    border-left: 1px solid var(--colorgrad8);
    transform: rotate(180deg);
    top: -1px;
    right: 0px;
    width: calc(100% + 1px);
    z-index: 10;
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      width: calc(100% + 4px);
      height: 10px;
      border-top: 2px solid var(--colorgrad6);
      border-bottom: 4px solid var(--colorgrad6);
      background: transparent;
    }
  }
  #display_neck {
    position: relative;
    background: var(--colorgrad3);
    width: 67px;
    height: 8px;
    border-top: 1px solid var(--colorgrad8);
    border-right: 1px solid var(--colorgrad8);
    border-left: 1px solid var(--colorgrad6);
    box-shadow: inset -2px 2px 0px var(--colorgrad6),
      inset 2px 2px 0px var(--colorgrad0);
    &:after {
      content: "";
      position: absolute;
      width: 2px;
      height: 2px;
      background: var(--colorgrad4);
    }
  }
  .center_screen {
    position: relative;
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    padding: 2px;

    box-shadow: inset -1px -1px 0px var(--colorgrad0),
      inset 1px 1px 0px var(--colorgrad6), inset -2px -2px 0px var(--colorgrad5),
      inset 2px 2px 0px var(--colorgrad8);
  }
  #display_head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 140px;
    background: var(--colorgrad3);
    border-top: 3px solid var(--colorgrad0);
    border-left: 3px solid var(--colorgrad0);
    border-right: 3px solid var(--colorgrad6);
    border-bottom: 3px solid var(--colorgrad6);
  }
  .pixel-border_head {
    clip-path: polygon(
      0px calc(100% - 1px),
      1px calc(100% - 1px),
      1px 100%,
      calc(100% - 1px) 100%,
      calc(100% - 1px) calc(100% - 1px),
      100% calc(100% - 1px),
      100% 1px,
      calc(100% - 1px) 1px,
      calc(100% - 1px) 0px,
      1px 0px,
      1px 1px,
      0px 1px
    );
  }
  .dither-border#top,
  .dither-border#bottom,
  .dither-border#left,
  .dither-border#right {
    position: absolute;
    background-size: 2px 2px;
    background-position: 0 0, -1px -1px;
    background-image: linear-gradient(
        45deg,
        var(--gradient) 25%,
        transparent 25%,
        transparent 75%,
        var(--gradient) 75%,
        var(--gradient)
      ),
      linear-gradient(
        45deg,
        var(--gradient) 25%,
        transparent 25%,
        transparent 75%,
        var(--gradient) 75%,
        var(--gradient)
      );
    image-rendering: pixelated;
  }
  .dither-border#top {
    --gradient: var(--colorgrad0);
    top: 0;
    width: 100%;
    height: 2px;
  }
  .dither-border#bottom {
    --gradient: var(--colorgrad6);
    bottom: 0;
    width: 100%;
    height: 2px;
  }
  .dither-border#left {
    --gradient: var(--colorgrad0);
    left: 0;
    height: 100%;
    width: 2px;
  }
  .dither-border#right {
    --gradient: var(--colorgrad6);
    right: 0;
    height: 100%;
    width: 2px;
  }
`;

export default Display;
