import React from "react";
import styled from "styled-components";

const Sky7 = () => {
	return (
		<Div className="skysection7 content animate selectable">
			<h3>Unlock the full potential and reveal hidden facets of your digital abode.</h3>
			<div className="animate">
				<p>
					Every element of SkyLuxury is designed to be fully interactive, inviting you to engage with your environment in innovative ways.
				</p>
				<p>
					Navigate your surroundings effortlessly: rotate the scene <span className="sl_link" style={{ cursor: "n-resize" }}>up</span>, <span className="sl_link" style={{ cursor: "s-resize" }}>down</span>, <span className="sl_link" style={{ cursor: "w-resize" }}>left</span> and <span className="sl_link" style={{ cursor: "e-resize" }}>right</span>, and fully marvel at the grandeur of your celestial home.
				</p>
				<p>
					Zoom <span className="sl_link" style={{ cursor: "zoom-in" }}>in</span> or <span className="sl_link" style={{ cursor: "zoom-out" }}>out</span> on the exquisite walls to drench yourself in the undeniable delights of their keleidescopic gradients.
				</p>
				<p>
					<span className="sl_link" style={{ cursor: "help" }}>Dive</span> into the infinity pool with a simple click and <span className="sl_link" style={{ cursor: "alias" }}>submerge</span> in the serene depths of your private oasis.
				</p>
				<p>
					<span className="sl_link" style={{ cursor: "crosshair" }}>Pause</span> a transient piece of furniture with a click to appreciate the geometric elegance of its curves and edges.
				</p>
				<p>
					Adjust the lighting of your residence by simply <span className="sl_link" style={{ cursor: "all-scroll" }}>scrolling</span> up and down to tailor the environment to your exact preference and create a perfect heavenly ambiance, ensuring the mood in your digital sanctuary in the clouds is always just right.
				</p>
			</div>
		</Div>
	);
};

const Div = styled.div`
	div.animate {
		opacity: 0;
	}
	.sl_link {
		--shadow: var(--textColor4);
	}
	`;

export default Sky7;