import React, { useState, useEffect, useGlobal } from "reactn";
import styled from "styled-components";
import { map, HSLToHex } from "utils";

const Sprite = () => {
  const [connected] = useGlobal("connected");
  const [account] = useGlobal("account");
  const [web3] = useGlobal("web3");
  const [spriteData, setSpriteData] = useState("");
  const [ens, setEns] = useState(null);


  useEffect(() => {
    if (web3 && connected && account)
      web3.lookupAddress(account).then((e) => setEns(e));
  }, [web3, account, connected]);

  useEffect(() => {
    class SpriteImage {
      constructor(canvas, settings) {
        let hashPairs = [];
        for (let i = 0; i < 20; i++) {
          hashPairs.push(account.slice(2 + i * 2, 4 + i * 2));
        }
        let decPairs = hashPairs.map((x) => parseInt(x, 16));
        function generatePastelPalette() {
            const palette = [];
            const hueStep = 72;
            const saturation = 50;
            const lightness = 70;
            let randomHue = Math.floor(Math.random() * 360);
            for (let i = 0; i < 5; i++) {
                const hue = (randomHue + i * hueStep) % 360;
                const color = HSLToHex(hue, saturation / 100, lightness / 100);
                palette.push('#'+color);
            }
            return palette;
        }
        const { cellXrow } = settings;
        let sortedColors = [];
        const color = generatePastelPalette();
        for (let j = 0; j < 18; j++) {
          let cor = color[Math.round(map(decPairs[j], 0, 255, 0, 4))];
          sortedColors.push(cor);
        }
        this.ctx = canvas.getContext("2d", { willReadFrequently: true });
        this.settings = {
          color,
          map,
          decPairs,
          sortedColors,
          cellXrow,
          cellSize: canvas.width / cellXrow,
          rowWidth: canvas.width,
        };
      }

      drawCell(x, y, cellSize, sortedColor) {
        this.ctx.fillStyle = sortedColor;
        this.ctx.fillRect(x, y, cellSize, cellSize);
      }

      drawRows(j) {
        const {
          sortedColors,
          map,
          decPairs,
          cellXrow,
          cellSize,
          rowWidth,
        } = this.settings;
        let cellsAmount = 0;
        const maxCellCurrentRow = map(decPairs[19], 0, 255, 1, cellXrow / 2);
        for (let i = 0; i < cellXrow / 2; i++) {
          const x = i * cellSize;
          const y = j * cellSize;
          const sortedColor = sortedColors[j + i];
          const skip = decPairs[j + i] % 2 ? 0 : 1;
          if (skip && cellsAmount < maxCellCurrentRow) {
            this.drawCell(x, y, cellSize, sortedColor);
            this.drawCell(rowWidth - cellSize - x, y, cellSize, sortedColor);
            cellsAmount++;
          }
        }
      }

      draw(cellSize) {
        const { rowWidth } = this.settings;
        this.ctx.clearRect(0, 0, rowWidth, rowWidth);
        for (let j = 0; j < cellSize; j++) {
          this.drawRows(j);
        }
      }

      init() {
        const { cellSize } = this.settings;
        try {
          this.draw(cellSize);
        } catch (e) {
          console.error(e);
        }
      }
    }
    if (connected) {
      const canvas = document.createElement("canvas");
      canvas.width = 24;
      canvas.height = 24;
      const sprite = new SpriteImage(canvas, {
        cellXrow: 5,
      });
      sprite.init();
      setSpriteData(canvas.toDataURL());
    }
  }, [account, connected]);

  return (
    <Div>
      {!connected && (
        <header>
          <span id="header_text" title="Not connected">
            Not connected
          </span>
        </header>
      )}
      {connected && (
        <header>
          <img id="header_img" alt="user icon" src={spriteData} />
          <span id="header_text" className="connected" title={account}>
            {ens ? ens : account}
          </span>
        </header>
      )}
    </Div>
  );
};

const Div = styled.div`
  align-items: center;
  color: #fff;
  height: 50px;
  overflow: hidden;
  display: flex;
  width: 100%;
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px;
  }
  #header_img {
    margin: 5px;
    -webkit-filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 white);
    filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 white);
  }
  #header_text {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    letter-spacing: 1px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
    font-family: "changa", sans-serif;
    font-weight: 600;
  }
  #header_text.connected {
    margin: 0 0 0 5px;
    width: 100%;
  }
`;

export default Sprite;
