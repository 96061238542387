import React, { useEffect, useState, useRef } from "react";
import empty from "assets/sweeper/empty.png";
// import video from "assets/remixer/god20.mp4";
import vert from "../../shaders/effect.vert";
import frag from "../../shaders/effect.frag";
import { FlowCalculator } from "./opticalflow/flow.js";
import { Sampler1, Sampler2, Sampler3, Sampler4 } from "./Samplers.js";
import {
  closestNote,
  minorScale,
  minorScale2,
  majorScale,
  majorScale2,
} from "../../synths/scales.js";
import { clamp, map, intersects } from "utils";
import { drawEnv } from "./Envelope";
import { drawFilter, frequencyBins } from "./Filter";
import hiDrums from "../../synths/hiDrums";
import loDrums from "../../synths/loDrums";
import leadSamples from "../../synths/leads";
import shakerSamples from "../../synths/shakers";
import padSamples from "../../synths/pads";
import bassSamples from "../../synths/basses";
import jsfeat from "jsfeat";
import * as Tone from "tone";
import Sketch from "react-p5";
import Waveform from "./Waveform";
let vid, cnv, scale, cnv2;
let mode = 1;
let fps = 25;
let viewThresh = true;
let viewSamples = true;
let mXin, mYin, mXout, mYout;
let drawRect = false;
let shader;
let thresh3_gl, thresh3_0;
let imgFx;
let thresh1, particles1;
let thresh2, particles2;
let thresh3, particles3;
let thresh4, particles4;
let slider1, slider2, slider3, slider4;
let rectLayer;
let notes1 = 4;
let notes3 = 4;
let notes4 = 6;
let samples1 = [];
let samples3 = [];
let samples4 = [];
let sample3isPressed = false;
let sample1isPressed = false;
let sample4isPressed = false;
let pitch3 = 60;
let interval3 = 5;
let previousPixels;
let step = 5;
let sum2 = 0;
let initNotes = 16;
let buffer;
let result;
let lowThreshold4 = 80;
let highThreshold4 = 80;
let interval4 = 4;
let midiNote1;
let musicalScale, musicalScale2;
let uMotionGraph, vMotionGraph;
let flow;
let flowLength;
/*
  M U S I C
*/
const toneFFT = new Tone.FFT(128);
const toneWaveform_1 = new Tone.Waveform(2048);
const toneWaveform_2 = new Tone.Waveform(2048);
const toneWaveform_3 = new Tone.Waveform(2048);
const toneWaveform_4 = new Tone.Waveform(2048);

// console.log(
//   "shaker: " + shakerSamples.indexOf(shakerSample),
//   "lead: " + leadSamples.indexOf(leadSample),
//   "bass: " + bassSamples.indexOf(bassSample),
//   "pad: " + padSamples.indexOf(padSample),
//   "snare: " + hiDrums.indexOf(snareSample),
//   "kick: " + loDrums.indexOf(kickSample)
// );
// lead: 47, 48, 59
// shaker:
// pad: 16
// kick:
// bass: 6?

/*

  1 - L E A D

*/

let attack1 = 0.001;
let decay1 = 2;
let sustain1 = 0.9;
let release1 = 2;

const filter_1 = new Tone.Filter({
  frequency: closestNote(2000, frequencyBins),
  Q: 2,
  type: "lowpass",
});

let lead_1;

/*

  2 - N O I S E + B A S S

*/
let bassVelocity = 0;
let attack_b2 = 0.001;
let decay_b2 = 2;
let sustain_b2 = 0.9;
let release_b2 = 5;

const filter_b2 = new Tone.Filter({
  frequency: closestNote(300, frequencyBins),
  Q: 2,
  type: "lowpass",
});
let bass_2;

const filter_n2 = new Tone.Filter({
  frequency: closestNote(400, frequencyBins),
  Q: 2,
  type: "highpass",
});

const noise_panner2 = new Tone.Panner();
// const noise_delay2 = new Tone.FeedbackDelay({
//   delayTime: "8n",
//   feedback: 0.3,
//   wet: 0.5,
// });
const noise_lp2 = new Tone.Filter({
  frequency: 300,
  Q: 2,
  type: "lowpass",
});
let noise_2;

/*

  3 - K I C K + S N A R E

*/

let attack_k3 = 0.001;
let decay_k3 = 2;
let sustain_k3 = 0.9;
let release_k3 = 2;

const filter_k3 = new Tone.Filter({
  frequency: closestNote(25, frequencyBins),
  Q: 2,
  type: "highpass",
});

let kick_3;

let attack_s3 = 0.001;
let decay_s3 = 2;
let sustain_s3 = 0.9;
let release_s3 = 2;

const filter_s3 = new Tone.Filter({
  frequency: closestNote(25, frequencyBins),
  Q: 2,
  type: "highpass",
});

let snare_3;

/*

  4 - P A D

*/

let decay4 = 0.9;
let attack4 = 0.1;
let sustain4 = 0.4;
let release4 = 0.4;

const filter_4 = new Tone.Filter({
  frequency: closestNote(600, frequencyBins),
  Q: 2,
  type: "lowpass",
});

let pad_4;

const Painter = (props) => {
  const [toneWaveform, setToneWaveform] = useState(toneWaveform_1);
  const [ratio, setRatio] = useState({
    w: 640,
    h: 360,
  });
  const filterFreq = useRef(0);

  useEffect(() => {
    if (props.viewEffects && props.mode === 3 && props.viewThresh)
      thresh3.resizeCanvas(
        document.querySelector("#rmxr_div").offsetWidth - 4,
        document.querySelector("#rmxr_div").offsetHeight - 4
      );
  }, [props.viewEffects, props.viewThresh, props.mode]);

  useEffect(() => {
    let currBeat = 0;
    const totalBeats = 64;
    const loop = (time) => {
      // loop1
      for (let i = 0; i < notes1; i++) {
        try {
          if (
            currBeat ===
            Math.floor(
              (samples1[i].posX /
                document.body.querySelector("#rmxr_cnv").offsetWidth) *
                totalBeats
            )
          ) {
            samples1[i].trig = true;
            midiNote1 = Tone.Frequency(
              closestNote(
                samples1[i].note,
                musicalScale(samples1[i].key, samples1[i].oct)
              ),
              "midi"
            ).toNote();
            lead_1.triggerAttackRelease(
              midiNote1,
              decay1 * (1 + sustain1) + release1,
              time + attack1,
              samples1[i].bright / 100
            );
          } else {
            setTimeout(function () {
              samples1[i].trig = false;
            }, 5000);
          }
        } catch {
          console.log();
        }
      }

      // loop2
      if (
        flow &&
        flow.flow &&
        flow.flow.u !== 0 &&
        flow.flow.v !== 0 &&
        currBeat % 8 === 0
      ) {
        try {
          let bassNote = Math.round(
            map(
              flow.flow.v * 2,
              -1,
              1,
              samples1[0].key + 24,
              samples1[0].key + 36
            )
          );
          let midiNote2 = Tone.Frequency(
            closestNote(bassNote, musicalScale(samples1[0].key, 2)),
            "midi"
          ).toNote();
          vMotionGraph.trig = true;
          uMotionGraph.trig = true;
          bass_2.triggerAttackRelease(
            midiNote2,
            decay_b2 * (1 + sustain_b2) + release_b2,
            time + attack_b2,
            bassVelocity
          );
        } catch {}
      } else {
        vMotionGraph.trig = false;
        uMotionGraph.trig = false;
      }

      // loop3
      for (let i = 0; i < notes3; i++) {
        try {
          if (
            currBeat ===
            Math.floor(
              (samples3[i].posX /
                document.body.querySelector("#rmxr_cnv").offsetWidth) *
                totalBeats
            )
          ) {
            if (samples3[i].bright > 0) {
              samples3[i].trig = true;
              snare_3.triggerAttackRelease(
                "C4",
                decay_s3 * (1 + sustain_s3) + release_s3,
                time + attack_s3,
                1
              );
            } else {
              samples3[i].trig = true;
              kick_3.triggerAttackRelease(
                "C4",
                decay_k3 * (1 + sustain_k3) + release_k3,
                time + attack_k3,
                1
              );
            }
          } else {
            samples3[i].trig = false;
          }
        } catch {
          console.log();
        }
      }

      // loop4
      for (let i = 0; i < notes4; i++) {
        try {
          if (samples4[i].bright > 0) {
            samples4[i].trig = true;
            let midiNote4 = Tone.Frequency(
              closestNote(
                samples4[i].note,
                musicalScale2(samples4[i].key, samples4[i].oct)
              ),
              "midi"
            ).toNote();
            pad_4.triggerAttackRelease(
              midiNote4,
              pad_4.options.envelope.decay *
                (1 + pad_4.options.envelope.sustain) +
                pad_4.options.envelope.release,
              time + pad_4.options.envelope.attack,
              1
            );
          } else {
            samples4[i].trig = false;
          }
        } catch {
          console.log();
        }
      }
      currBeat = (currBeat + 1) % totalBeats;
    };
    Tone.Transport.scheduleRepeat(loop);
  }, []);

  useEffect(() => {
    if (
      props.settings3.kFilters.chorus &&
      props.settings3.kFilters.reverb &&
      props.settings3.kFilters.phaser &&
      props.settings3.kFilters.delay &&
      props.settings3.kFilters.chebyshev &&
      props.settings3.kFilters.autofilter &&
      props.settings3.kFilters.tremolo &&
      props.settings3.kFilters.pingpong &&
      props.settings3.kFilters.distortion &&
      props.settings3.kFilters.panner
    ) {
      kick_3.chain(
        filter_k3,
        props.settings3.kFilters.chorus,
        props.settings3.kFilters.distortion,
        props.settings3.kFilters.reverb,
        props.settings3.kFilters.phaser,
        props.settings3.kFilters.delay,
        props.settings3.kFilters.chebyshev,
        props.settings3.kFilters.tremolo,
        props.settings3.kFilters.pingpong,
        props.settings3.kFilters.autofilter,
        props.settings3.kFilters.panner,
        toneWaveform_3,
        toneFFT,
        Tone.Destination
      );
    }
  }, [
    props.settings3.kFilters.panner,
    props.settings3.kFilters.chorus,
    props.settings3.kFilters.reverb,
    props.settings3.kFilters.phaser,
    props.settings3.kFilters.delay,
    props.settings3.kFilters.chebyshev,
    props.settings3.kFilters.autofilter,
    props.settings3.kFilters.tremolo,
    props.settings3.kFilters.pingpong,
    props.settings3.kFilters.distortion,
  ]);

  useEffect(() => {
    if (
      props.settings3.sFilters.chorus &&
      props.settings3.sFilters.reverb &&
      props.settings3.sFilters.phaser &&
      props.settings3.sFilters.delay &&
      props.settings3.sFilters.chebyshev &&
      props.settings3.sFilters.autofilter &&
      props.settings3.sFilters.tremolo &&
      props.settings3.sFilters.pingpong &&
      props.settings3.sFilters.distortion &&
      props.settings3.sFilters.panner
    ) {
      snare_3.chain(
        filter_s3,
        props.settings3.sFilters.chorus,
        props.settings3.sFilters.distortion,
        props.settings3.sFilters.reverb,
        props.settings3.sFilters.phaser,
        props.settings3.sFilters.delay,
        props.settings3.sFilters.chebyshev,
        props.settings3.sFilters.tremolo,
        props.settings3.sFilters.pingpong,
        props.settings3.sFilters.autofilter,
        props.settings3.sFilters.panner,
        toneWaveform_3,
        toneFFT,
        Tone.Destination
      );
    }
  }, [
    props.settings3.sFilters.panner,
    props.settings3.sFilters.chorus,
    props.settings3.sFilters.reverb,
    props.settings3.sFilters.phaser,
    props.settings3.sFilters.delay,
    props.settings3.sFilters.chebyshev,
    props.settings3.sFilters.autofilter,
    props.settings3.sFilters.tremolo,
    props.settings3.sFilters.pingpong,
    props.settings3.sFilters.distortion,
  ]);

  useEffect(() => {
    if (
      props.settings4.chorus &&
      props.settings4.reverb &&
      props.settings4.phaser &&
      props.settings4.delay &&
      props.settings4.chebyshev &&
      props.settings4.autofilter &&
      props.settings4.tremolo &&
      props.settings4.pingpong &&
      props.settings4.distortion &&
      props.settings4.panner &&
      props.settings4.shifter
    ) {
      pad_4.chain(
        filter_4,
        props.settings4.shifter,
        props.settings4.chorus,
        props.settings4.distortion,
        props.settings4.reverb,
        props.settings4.phaser,
        props.settings4.delay,
        props.settings4.chebyshev,
        props.settings4.tremolo,
        props.settings4.pingpong,
        props.settings4.autofilter,
        props.settings4.panner,
        toneWaveform_4,
        toneFFT,
        Tone.Destination
      );
    }
  }, [
    props.settings4.panner,
    props.settings4.shifter,
    props.settings4.chorus,
    props.settings4.reverb,
    props.settings4.phaser,
    props.settings4.delay,
    props.settings4.chebyshev,
    props.settings4.autofilter,
    props.settings4.tremolo,
    props.settings4.pingpong,
    props.settings4.distortion,
  ]);

  useEffect(() => {
    if (
      props.settings1.chorus &&
      props.settings1.reverb &&
      props.settings1.phaser &&
      props.settings1.delay &&
      props.settings1.chebyshev &&
      props.settings1.autofilter &&
      props.settings1.tremolo &&
      props.settings1.pingpong &&
      props.settings1.distortion &&
      props.settings1.panner &&
      props.settings1.shifter
    ) {
      lead_1.chain(
        filter_1,
        props.settings1.shifter,
        props.settings1.chorus,
        props.settings1.distortion,
        props.settings1.reverb,
        props.settings1.phaser,
        props.settings1.delay,
        props.settings1.chebyshev,
        props.settings1.tremolo,
        props.settings1.pingpong,
        props.settings1.autofilter,
        props.settings1.panner,
        toneWaveform_1,
        toneFFT,
        Tone.Destination
      );
    }
  }, [
    props.settings1.panner,
    props.settings1.shifter,
    props.settings1.chorus,
    props.settings1.reverb,
    props.settings1.phaser,
    props.settings1.delay,
    props.settings1.chebyshev,
    props.settings1.autofilter,
    props.settings1.tremolo,
    props.settings1.pingpong,
    props.settings1.distortion,
  ]);

  useEffect(() => {
    if (props.mode === 1) setToneWaveform(toneWaveform_1);
    if (props.mode === 2) setToneWaveform(toneWaveform_2);
    if (props.mode === 3) setToneWaveform(toneWaveform_3);
    if (props.mode === 4) setToneWaveform(toneWaveform_4);
  }, [props.mode]);

  useEffect(() => {
    if (particles1) particles1.resizeCanvas(ratio.w, ratio.h);
    if (thresh1) thresh1.resizeCanvas(ratio.w, ratio.h);
    if (particles2) particles2.resizeCanvas(ratio.w, ratio.h);
    if (thresh2) thresh2.resizeCanvas(ratio.w, ratio.h);
    if (particles3) particles3.resizeCanvas(ratio.w, ratio.h);
    if (thresh3) thresh3.resizeCanvas(ratio.w, ratio.h);
    if (particles4) particles4.resizeCanvas(ratio.w, ratio.h);
    if (thresh4) thresh4.resizeCanvas(ratio.w, ratio.h);
    if (rectLayer) rectLayer.resizeCanvas(ratio.w, ratio.h);
  }, [ratio]);

  useEffect(() => {
    const fil_cnv = document.querySelector("#fil_cnv");
    let filterRes, filterFreq, filterType;
    if (props.mode === 1) {
      filterRes = filter_1.Q.value;
      filterFreq = filter_1.frequency.value;
      filterType = props.settings1.filter;
    } else if (props.mode === 2) {
      if (props.track === "Noise") {
        filterRes = filter_n2.Q.value;
        filterFreq = filter_n2.frequency.value;
        filterType = props.settings2.nFilter;
      } else {
        filterRes = filter_b2.Q.value;
        filterFreq = filter_b2.frequency.value;
        filterType = props.settings2.bFilter;
      }
    } else if (props.mode === 3) {
      if (props.track === "Percussion") {
        filterRes = filter_s3.Q.value;
        filterFreq = filter_s3.frequency.value;
        filterType = props.settings3.sFilter;
      } else {
        filterRes = filter_k3.Q.value;
        filterFreq = filter_k3.frequency.value;
        filterType = props.settings3.kFilter;
      }
    } else if (props.mode === 4) {
      filterRes = filter_4.Q.value;
      filterFreq = filter_4.frequency.value;
      filterType = props.settings4.filter;
    }
    if (fil_cnv && props.started) {
      const ctx = fil_cnv.getContext("2d", {
  willReadFrequently: true
});
      fil_cnv.width = fil_cnv.parentElement.offsetWidth - 4;
      fil_cnv.height = fil_cnv.parentElement.offsetHeight - 4;
      let radius = 5;
      let margin = 2.5;
      let drag = false;
      let coord = {
        x:
          map(
            frequencyBins.indexOf(filterFreq),
            0,
            frequencyBins.length,
            0,
            fil_cnv.width + radius
          ) - 4.5,
        y:
          map(
            filterRes,
            0,
            5,
            (fil_cnv.height - radius) / 2,
            radius / 2 - margin
          ) - 2.5,
      };
      if (filterType === "highpass") {
        coord = {
          x:
            map(
              frequencyBins.indexOf(filterFreq),
              0,
              frequencyBins.length,
              0,
              fil_cnv.width + radius
            ) - 2.5,
          y: map(
            filterRes,
            0,
            5,
            (fil_cnv.height - radius) / 2,
            radius / 2 - margin
          ),
        };
      } else if (filterType === "notch") {
        coord = {
          x:
            map(
              frequencyBins.indexOf(filterFreq),
              0,
              frequencyBins.length,
              0,
              fil_cnv.width + radius
            ) - 4.5,
          y:
            map(
              filterRes,
              0,
              5,
              (fil_cnv.height - radius) / 2,
              fil_cnv.height - radius / 2 - margin
            ) + 1.5,
        };
      } else if (filterType === "lowshelf" || filterType === "lowpass") {
        coord = {
          x:
            map(
              frequencyBins.indexOf(filterFreq),
              0,
              frequencyBins.length,
              0,
              fil_cnv.width + radius
            ) - 9.5,
          y: map(
            filterRes,
            0,
            5,
            (fil_cnv.height - radius) / 2,
            radius / 2 - margin
          ),
        };
      } else if (filterType === "highshelf") {
        coord = {
          x:
            map(
              frequencyBins.indexOf(filterFreq),
              0,
              frequencyBins.length,
              0,
              fil_cnv.width + radius
            ) - radius,
          y: map(
            filterRes,
            0,
            5,
            (fil_cnv.height - radius) / 2,
            radius / 2 - margin
          ),
        };
      }
      const resizeObserver = new ResizeObserver((entries) => {
        let scale = (fil_cnv.parentElement.offsetWidth - 4) / fil_cnv.width;
        coord.x = coord.x * scale;
        fil_cnv.width = fil_cnv.parentElement.offsetWidth - 4;
        fil_cnv.height = fil_cnv.parentElement.offsetHeight - 4;
      });
      function onPointerDown(event) {
        const rect = fil_cnv.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;
        if (intersects(coord.x, coord.y, x, y, radius)) {
          drag = true;
        }
      }
      function onPointerMove(event) {
        const rect = fil_cnv.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;
        if (drag) {
          x = clamp(x, radius, fil_cnv.width - radius);
          let newFIndex = Math.round(
            map(x, 0, fil_cnv.width - radius, 0, frequencyBins.length - 1)
          );
          if (filterType === "highpass" || filterType === "highshelf") {
            coord.x =
              map(
                newFIndex,
                0,
                frequencyBins.length,
                0,
                fil_cnv.width + radius
              ) -
              radius / 2;
          } else if (filterType === "lowshelf" || filterType === "lowpass") {
            coord.x =
              map(
                newFIndex,
                0,
                frequencyBins.length,
                0,
                fil_cnv.width + radius
              ) - 9.5;
          } else {
            coord.x =
              map(
                newFIndex,
                0,
                frequencyBins.length,
                0,
                fil_cnv.width + radius
              ) - 3.5;
          }
          filterFreq = frequencyBins[newFIndex];

          if (filterType === "notch") {
            coord.x =
              map(
                newFIndex,
                0,
                frequencyBins.length,
                0,
                fil_cnv.width + radius
              ) - 4.5;
            coord.y = clamp(
              y,
              (fil_cnv.height - radius) / 2,
              fil_cnv.height - radius / 2 - margin
            );
            filterRes = map(
              coord.y,
              (fil_cnv.height - radius) / 2,
              fil_cnv.height - radius / 2 - margin,
              0,
              5
            );
          } else {
            coord.y = clamp(
              y,
              radius / 2 - margin,
              (fil_cnv.height - radius) / 2
            );
            filterRes = map(
              coord.y,
              radius / 2 - margin,
              (fil_cnv.height - radius) / 2,
              5,
              0
            );
          }

          if (props.mode === 1) {
            filter_1.set({ frequency: filterFreq, Q: filterRes });
          } else if (props.mode === 2) {
            if (props.track === "Noise") {
              filter_n2.set({ frequency: filterFreq, Q: filterRes });
            } else {
              filter_b2.set({ frequency: filterFreq, Q: filterRes });
            }
          } else if (props.mode === 3) {
            if (props.track === "Percussion") {
              filter_s3.set({ frequency: filterFreq, Q: filterRes });
            } else {
              filter_k3.set({ frequency: filterFreq, Q: filterRes });
            }
          } else if (props.mode === 4) {
            filter_4.set({ frequency: filterFreq, Q: filterRes });
          }
        }
      }

      function onPointerLeave() {
        if (drag) drag = false;
      }
      fil_cnv.addEventListener("mousedown", onPointerDown);
      fil_cnv.addEventListener("mousemove", onPointerMove);
      fil_cnv.addEventListener("mouseup", onPointerLeave);
      fil_cnv.addEventListener("mouseleave", onPointerLeave);
      resizeObserver.observe(fil_cnv.parentElement);

      function updateFrame() {
        ctx.save();
        drawFilter(
          ctx,
          fil_cnv,
          radius,
          coord,
          filterFreq,
          filterRes,
          filterType
        );
        ctx.restore();
        requestAnimationFrame(updateFrame);
      }
      updateFrame();
    }
    if (props.mode === 1) filter_1.set({ type: props.settings1.filter });
    if (props.mode === 2 && props.track === "Noise")
      filter_n2.set({ type: props.settings2.nFilter });
    if (props.mode === 2 && props.track === "Bass")
      filter_b2.set({ type: props.settings2.bFilter });
    if (props.mode === 3 && props.track === "Impact")
      filter_k3.set({ type: props.settings3.kFilter });
    if (props.mode === 3 && props.track === "Percussion")
      filter_s3.set({ type: props.settings3.sFilter });
    if (props.mode === 4) filter_4.set({ type: props.settings4.filter });
  }, [
    props.started,
    props.mode,
    props.track,
    props.settings1.filter,
    props.settings2.nFilter,
    props.settings2.bFilter,
    props.settings3.kFilter,
    props.settings3.sFilter,
    props.settings4.filter,
  ]);

  useEffect(() => {
    const env_cnv = document.querySelector("#env_cnv");
    let attack, decay, sustain, release;
    if (props.mode === 1) {
      attack = attack1;
      decay = decay1;
      sustain = sustain1;
      release = release1;
    } else if (props.mode === 2 && props.track === "Bass") {
      attack = attack_b2;
      decay = decay_b2;
      sustain = sustain_b2;
      release = release_b2;
    } else if (props.mode === 3) {
      if (props.track === "Impact") {
        attack = attack_k3;
        decay = decay_k3;
        sustain = sustain_k3;
        release = release_k3;
      }
      if (props.track === "Percussion") {
        attack = attack_s3;
        decay = decay_s3;
        sustain = sustain_s3;
        release = release_s3;
      }
    } else if (props.mode === 4) {
      attack = pad_4.options.envelope.attack;
      decay = pad_4.options.envelope.decay;
      sustain = pad_4.options.envelope.sustain;
      release = pad_4.options.envelope.release;
    }
    if (env_cnv && props.started && attack) {
      const ctx = env_cnv.getContext("2d", {
  willReadFrequently: true
});
      env_cnv.width = env_cnv.parentElement.offsetWidth - 4;
      env_cnv.height = env_cnv.parentElement.offsetHeight - 4;
      let sl = 20 / 100;
      let radius = 5;
      let margin = radius / 2;
      let dragA = false;
      let dragD = false;
      let dragS = false;
      let dragR = false;
      let scalefactor = env_cnv.width / (attack + decay + sustain + release);
      let coord = {
        0: {
          x: margin,
          y: env_cnv.height,
        },
        a: {
          x: attack * scalefactor + margin,
          y: margin,
        },
        d: {
          x: (attack + decay) * scalefactor,
          y: (1 - sl) * env_cnv.height,
        },
        s: {
          x: (attack + decay + sustain) * scalefactor,
          y: (1 - sl) * env_cnv.height,
        },
        r: {
          x: env_cnv.width - margin,
          y: env_cnv.height - margin,
        },
      };
      const resizeObserver = new ResizeObserver((entries) => {
        let scale = (env_cnv.parentElement.offsetWidth - 4) / env_cnv.width;
        coord.a.x = coord.a.x * scale;
        coord.d.x = coord.d.x * scale;
        coord.s.x = coord.s.x * scale;
        coord.r.x = coord.r.x * scale;
        env_cnv.width = env_cnv.parentElement.offsetWidth - 4;
        env_cnv.height = env_cnv.parentElement.offsetHeight - 4;
      });

      function onPointerDown(event) {
        const rect = env_cnv.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;
        if (intersects(coord.a.x, coord.a.y, x, y, radius)) {
          dragA = true;
        }
        if (intersects(coord.d.x, coord.d.y, x, y, radius)) {
          dragD = true;
        }
        if (intersects(coord.s.x, coord.s.y, x, y, radius)) {
          dragS = true;
        }
        if (intersects(coord.r.x, coord.r.y, x, y, radius)) {
          dragR = true;
        }
      }

      function onPointerMove(event) {
        const rect = env_cnv.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;
        if (dragA) {
          coord.a.x = clamp(x, margin, coord.d.x);
          let a = map(coord.a.x, margin, coord.d.x, 0, 2);
          if (props.mode === 1) {
            attack1 = a;
            lead_1.attack = a;
          } else if (props.mode === 2 && props.track === "Bass") {
            attack_b2 = a;
            bass_2.attack = a;
          } else if (props.mode === 3) {
            if (props.track === "Impact") {
              attack_k3 = a;
              kick_3.attack = a;
            }
            if (props.track === "Percussion") {
              attack_s3 = a;
              snare_3.attack = a;
            }
          } else if (props.mode === 4) {
            pad_4.options.envelope.attack = a;
          }
        }
        if (dragD) {
          coord.d.x = clamp(x, coord.a.x, coord.s.x);
          let d = map(coord.d.x, coord.a.x, coord.s.x, 0, 2);
          if (props.mode === 1) {
            decay1 = d;
          } else if (props.mode === 2 && props.track === "Bass") {
            decay_b2 = d;
          } else if (props.mode === 3) {
            if (props.track === "Impact") {
              decay_k3 = d;
            }
            if (props.track === "Percussion") {
              decay_s3 = d;
            }
          } else if (props.mode === 4) {
            pad_4.options.envelope.decay = d;
          }
        }
        if (dragS) {
          coord.s.x = clamp(x, coord.d.x, coord.r.x);
          coord.s.y = clamp(y, margin, env_cnv.height - margin);
          coord.d.y = clamp(y, margin, env_cnv.height - margin);
          let s = map(coord.s.y, margin, env_cnv.height - margin, 1, 0);
          if (props.mode === 1) {
            sustain1 = s;
          } else if (props.mode === 2 && props.track === "Bass") {
            sustain_b2 = s;
          } else if (props.mode === 3) {
            if (props.track === "Impact") {
              sustain_k3 = s;
            }
            if (props.track === "Percussion") {
              sustain_s3 = s;
            }
          } else if (props.mode === 4) {
            pad_4.options.envelope.sustain = s;
          }
        }
        if (dragR) {
          coord.r.x = clamp(x, coord.s.x, env_cnv.width - margin);
          let r = map(coord.r.x, coord.s.x, env_cnv.width - margin, 0, 5);
          if (props.mode === 1) {
            release1 = r;
            lead_1.release = r;
          } else if (props.mode === 2 && props.track === "Bass") {
            release_b2 = r;
            bass_2.release = r;
          } else if (props.mode === 3) {
            if (props.track === "Impact") {
              release_k3 = r;
              kick_3.release = r;
            }
            if (props.track === "Percussion") {
              release_s3 = r;
              snare_3.release = r;
            }
          } else if (props.mode === 4) {
            pad_4.options.envelope.release = r;
          }
        }
      }

      function onPointerLeave() {
        if (dragA) dragA = false;
        if (dragD) dragD = false;
        if (dragS) dragS = false;
        if (dragR) dragR = false;
      }
      env_cnv.addEventListener("mousedown", onPointerDown);
      env_cnv.addEventListener("mousemove", onPointerMove);
      env_cnv.addEventListener("mouseup", onPointerLeave);
      env_cnv.addEventListener("mouseleave", onPointerLeave);
      resizeObserver.observe(env_cnv.parentElement);

      function updateFrame() {
        ctx.save();
        drawEnv(ctx, env_cnv, coord, radius);
        ctx.restore();
        requestAnimationFrame(updateFrame);
      }
      updateFrame();
    }
  }, [props.started, props.mode, props.track]);

  useEffect(() => {
    if (vid) {
      if (props.playing) {
        vid.play();
        noise_2.start();
      } else {
        vid.pause();
        noise_2.stop();
      }
    }
  }, [props.playing]);
  useEffect(() => {
    if (noise_2) noise_2.playbackRate = props.playbackRate / 2;
  }, [props.playbackRate]);
  useEffect(() => {
    if (samples1.length > 0)
      for (let i = 0; i < notes1; i++) samples1[i].key = props.songKey;
    if (samples4.length > 0)
      for (let i = 0; i < notes4; i++) samples4[i].key = props.songKey;
  }, [props.songKey]);
  useEffect(() => {
    if (samples1.length > 0)
      for (let i = 0; i < notes1; i++) samples1[i].oct = props.settings1.oct;
  }, [props.settings1.oct]);
  useEffect(() => {
    if (samples4.length > 0)
      for (let i = 0; i < notes4; i++) samples4[i].oct = props.settings4.oct;
  }, [props.settings4.oct]);
  useEffect(() => {
    if (lead_1) {
      props.settings1.unmute
        ? (lead_1.volume.value = props.settings1.volume)
        : (lead_1.volume.value = -Infinity);
    }
  }, [props.settings1.unmute, props.settings1.volume]);
  useEffect(() => {
    if (bass_2) {
      if (props.settings2.bUnmute) {
        bass_2.volume.value = props.settings2.bVolume;
      } else {
        bass_2.volume.value = -Infinity;
      }
    }
  }, [props.settings2.bUnmute, props.settings2.bVolume]);
  useEffect(() => {
    if (noise_2) {
      if (props.settings2.nUnmute) {
        noise_2.volume.value = props.settings2.nVolume;
      } else {
        noise_2.volume.value = -Infinity;
      }
    }
  }, [props.settings2.nUnmute, props.settings2.nVolume]);
  useEffect(() => {
    if (snare_3) {
      if (props.settings3.sUnmute) {
        snare_3.volume.value = props.settings3.sVolume - 10;
      } else {
        snare_3.volume.value = -Infinity;
      }
    }
  }, [props.settings3.sUnmute, props.settings3.sVolume]);
  useEffect(() => {
    if (kick_3) {
      if (props.settings3.kUnmute) {
        kick_3.volume.value = props.settings3.kVolume;
      } else {
        kick_3.volume.value = -Infinity;
      }
    }
  }, [props.settings3.kUnmute, props.settings3.kVolume]);
  useEffect(() => {
    if (pad_4) {
      props.settings4.unmute
        ? (pad_4.volume.value = props.settings4.volume)
        : (pad_4.volume.value = -Infinity);
    }
  }, [props.settings4.unmute, props.settings4.volume]);
  useEffect(() => {
    if (notes1 !== props.settings1.samples) {
      if (props.settings1.samples < 0) {
        notes1 = 0;
      } else if (props.settings1.samples > 16) {
        notes1 = 16;
      } else {
        notes1 = props.settings1.samples;
      }
    }
  }, [props.settings1.samples]);
  useEffect(() => {
    if (notes3 !== props.settings3.samples) {
      if (props.settings3.samples < 0) {
        notes3 = 0;
      } else if (props.settings3.samples > 16) {
        notes1 = 16;
      } else {
        notes3 = props.settings3.samples;
      }
    }
  }, [props.settings3.samples]);
  useEffect(() => {
    if (notes4 !== props.settings4.samples) {
      if (props.settings4.samples < 0) {
        notes4 = 0;
      } else if (props.settings4.samples > 16) {
        notes4 = 16;
      } else {
        notes4 = props.settings4.samples;
        for (let i = 0; i < props.settings4.samples; i++)
          samples4[i].notes = props.settings4.samples;
      }
    }
  }, [props.settings4.samples]);

  const preload = (p5) => {
    if (!props.metadata) return;
    if (props.metadata.scale === "M") {
      musicalScale = majorScale;
      musicalScale2 = majorScale2;
    } else {
      musicalScale = minorScale;
      musicalScale2 = minorScale2;
    }
    const kickSample = loDrums[props.metadata.kick];
    const snareSample = hiDrums[props.metadata.perc];
    const leadSample = leadSamples[props.metadata.lead];
    const padSample = padSamples[props.metadata.pad];
    const shakerSample = shakerSamples[props.metadata.noise];
    const bassSample = bassSamples[props.metadata.bass];
    lead_1 = new Tone.Sampler({
      urls: {
        C4: leadSample,
      },
    });
    bass_2 = new Tone.Sampler({
      urls: {
        C4: bassSample,
      },
      onload: () => {
        bass_2.chain(filter_b2, toneWaveform_2, toneFFT, Tone.Destination);
      },
    });
    noise_2 = new Tone.Player({
      url: shakerSample,
      loop: true,
    });
    noise_2.chain(
      filter_n2,
      noise_lp2,
      noise_panner2,
      toneWaveform_2,
      toneFFT,
      Tone.Destination
    );
    snare_3 = new Tone.Sampler({
      urls: {
        C4: snareSample,
      },
    });
    kick_3 = new Tone.Sampler({
      urls: {
        C4: kickSample,
      },
    });
    pad_4 = new Tone.PolySynth(padSample.synth, {
      ...padSample.options,
      envelope: {
        attack: attack4,
        decay: decay4,
        sustain: sustain4,
        release: release4,
      },
    });
    thresh1 = p5.createGraphics(ratio.w, ratio.h);
    particles1 = p5.createGraphics(ratio.w, ratio.h);
    thresh2 = p5.createGraphics(ratio.w, ratio.h);
    particles2 = p5.createGraphics(ratio.w, ratio.h);
    thresh3_gl = p5.createGraphics(ratio.w, ratio.h, p5.WEBGL);
    thresh3_0 = p5.createGraphics(ratio.w, ratio.h);
    particles3 = p5.createGraphics(ratio.w, ratio.h);
    thresh4 = p5.createGraphics(ratio.w, ratio.h);
    particles4 = p5.createGraphics(ratio.w, ratio.h);
    shader = p5.loadShader(vert, frag);
  };

  /*
    S E T U P
  */

  const setup = (p5) => {
    if (!props.metadata) return;
    props.viewEffects && props.opts[0] !== null
      ? (thresh3 = thresh3_0)
      : (thresh3 = thresh3_gl);
    let parent = document.querySelector("#rmxr_div");
    cnv = p5.createCanvas(ratio.w, ratio.h).parent(parent);
    cnv.id("rmxr_cnv");
    const resizeObserver = new ResizeObserver((entries) => {
      scale = (parent.offsetWidth - 4) / ratio.w;
      p5.resizeCanvas(parent.offsetWidth - 4, parent.offsetHeight - 4);
      for (let i = 0; i < 16; i++) {
        samples1[i].posX = samples1[i].posX * scale;
        samples1[i].posY = samples1[i].posY * scale;
        samples3[i].posX = samples3[i].posX * scale;
        samples3[i].posY = samples3[i].posY * scale;
        samples4[i].posX = samples4[i].posX * scale;
        samples4[i].posY = samples4[i].posY * scale;
      }
      setRatio({
        w: parent.offsetWidth - 4,
        h: parent.offsetHeight - 4,
      });
    });
    resizeObserver.observe(parent);
    cnv2 = document.body.querySelector("#remixerOutput");
    scale = p5.width / ratio.w;
    vid = p5.createVideo(
      [props.metadata.videoURL],
      function () {
        vid.elt.id = "rmx_vid";
        vid.hide();
      }
    );
    imgFx = p5.createImg(empty, "new", "Anonymous", function () {
      imgFx.elt.id = "imgFx";
      imgFx.hide();
    });
    p5.background(0);
    p5.frameRate(fps);
    p5.colorMode(p5.HSB, 360, 100, 100, 100);
    rectLayer = p5.createGraphics(
      parent.offsetWidth - 4,
      parent.offsetHeight - 4
    );
    if (viewThresh || viewSamples) {
      setup1(p5);
      setup2(p5);
      setup3(p5);
      setup4(p5);
    }
  };

  function setup1(p5) {
    for (let i = 0; i < initNotes; i++) {
      samples1[i] = new Sampler1(
        p5,
        thresh1,
        particles1,
        scale,
        p5.random(ratio.w),
        p5.random(ratio.h),
        props.settings1.oct,
        props.songKey
      );
    }
  }

  function setup2(p5) {
    flow = new FlowCalculator(step);
    uMotionGraph = new Sampler2(p5, 100, -step / 2, +step / 2, particles2);
    vMotionGraph = new Sampler2(p5, 100, -step / 2, +step / 2, particles2);
  }

  function setup3(p5) {
    particles3.colorMode(p5.HSB, 360, 100, 100, 100);
    for (let i = 0; i < initNotes; i++) {
      samples3[i] = new Sampler3(
        p5,
        thresh3,
        particles3,
        scale,
        pitch3,
        interval3,
        p5.random(ratio.w),
        p5.random(ratio.h)
      );
    }
  }

  function setup4(p5) {
    buffer = new jsfeat.matrix_t(ratio.w, ratio.h, jsfeat.U8C1_t);
    for (let i = 0; i < initNotes; i++) {
      samples4[i] = new Sampler4(
        p5,
        thresh4,
        particles4,
        notes4,
        scale,
        interval4,
        i,
        props.settings4.oct,
        props.songKey
      );
    }
  }

  /*
    D R A W
  */

  const draw = (p5) => {
    if (!props.metadata) return;
    mode = props.mode;
    viewSamples = props.viewSamples;
    viewThresh = props.viewThresh;
    scale = p5.width / ratio.w;
    if (props.viewEffects && props.opts[0] !== null) {
      if (!viewThresh) p5.image(imgFx, 0, 0, ratio.w, ratio.h);
      draw1(p5, imgFx);
      draw2(
        p5,
        imgFx,
        cnv2.getContext('2d', { willReadFrequently: true }).getImageData(0, 0, ratio.w, ratio.h).data
      );
      draw3(p5, imgFx);
      draw4(
        p5,
        cnv2.getContext('2d', { willReadFrequently: true }).getImageData(0, 0, ratio.w, ratio.h).data
      );
    } else {
      try {
        if (vid.width !== p5.width) vid.size(p5.width, p5.height);
        vid.loadPixels();
        if (!viewThresh) p5.image(vid, 0, 0, ratio.w, ratio.h);
        draw1(p5, vid);
        draw2(p5, vid, vid.pixels);
        draw3(p5, vid);
        draw4(p5, vid.pixels);
      } catch {
        vid.width = ratio.w;
        vid.height = ratio.h;
      }
    }
    if (viewSamples && !viewThresh) {
      if (mode === 1) {
        p5.image(particles1, 0, 0, ratio.w, ratio.h);
      } else if (mode === 2) {
        p5.image(particles2, 0, 0, ratio.w, ratio.h);
      } else if (mode === 3) {
        p5.image(particles3, 0, 0, ratio.w, ratio.h);
      } else if (mode === 4) {
        p5.image(particles4, 0, 0, ratio.w, ratio.h);
      }
    } else if (viewSamples && viewThresh) {
      if (mode === 1) {
        p5.image(thresh1, 0, 0, ratio.w, ratio.h);
        p5.image(particles1, 0, 0, ratio.w, ratio.h);
      } else if (mode === 2) {
        p5.image(thresh2, 0, 0, ratio.w, ratio.h);
        p5.image(particles2, 0, 0, ratio.w, ratio.h);
      } else if (mode === 3) {
        p5.image(thresh3, 0, 0, ratio.w, ratio.h);
        p5.image(particles3, 0, 0, ratio.w, ratio.h);
      } else if (mode === 4) {
        p5.image(thresh4, 0, 0, ratio.w, ratio.h);
        p5.image(particles4, 0, 0, ratio.w, ratio.h);
      }
    } else if (!viewSamples && viewThresh) {
      if (mode === 1) {
        p5.image(thresh1, 0, 0, ratio.w, ratio.h);
      } else if (mode === 2) {
        p5.image(thresh2, 0, 0, ratio.w, ratio.h);
        p5.image(particles2, 0, 0, ratio.w, ratio.h);
      } else if (mode === 3) {
        p5.image(thresh3, 0, 0, ratio.w, ratio.h);
      } else if (mode === 4) {
        p5.image(thresh4, 0, 0, ratio.w, ratio.h);
      }
    }
  };

  function draw1(p5, rndr) {
    if (particles1) particles1.clear();
    thresh1.image(rndr, 0, 0, ratio.w, ratio.h);
    thresh1.filter(p5.POSTERIZE, slider1);
    slider1 = p5.map(props.settings1.threshold, 0, 100, 3, 16);
    for (let i = 0; i < notes1; i++) {
      samples1[i].color();
      samples1[i].play();
      if (mode === 1) {
        samples1[i].show(p5.mouseX, p5.mouseY);
        samples1[i].anim();
        samples1[i].wave();
      }
    }
  }

  function draw2(p5, rndr, pixels) {
    sum2 = 0;
    thresh2.image(rndr, 0, 0, ratio.w, ratio.h);
    if (particles2 && pixels.length > 0) {
      if (previousPixels)
        flow.calculate(previousPixels, pixels, rndr.width, rndr.height);
      previousPixels = copyImage(pixels, previousPixels);
      if (flow.flow && flow.flow.u && flow.flow.u !== 0 && flow.flow.v !== 0) {
        noise_panner2.pan.value = clamp(flow.flow.u * 2, -1, 1);
        particles2.clear();
        uMotionGraph.addSample(flow.flow.u);
        vMotionGraph.addSample(flow.flow.v);
        particles2.strokeWeight(1);
        slider2 = props.settings2.threshold / 20;
        flow.flow.zones.forEach(function (zone) {
          if (p5.abs(zone.uv) > slider2) {
            if (viewSamples) particles2.stroke(255);
            sum2++;
          } else {
            if (viewThresh) {
              particles2.stroke(
                10 * p5.abs(zone.uv),
                10 * p5.abs(zone.uv),
                10 * p5.abs(zone.uv),
                255 * p5.abs(zone.uv)
              );
            } else {
              particles2.noFill();
              particles2.noStroke();
            }
          }
          particles2.square(
            zone.x * scale - p5.abs(zone.u + zone.v),
            zone.y * scale - p5.abs(zone.u + zone.v),
            5 + p5.abs(zone.u + zone.v)
          );
          flowLength = flow.flow.zones.length;
        });
      }
      particles2.noFill();
      if (props.settings2.viewUV) {
        uMotionGraph.draw(p5.width, p5.height / 2, "u");
        vMotionGraph.draw(ratio.w, ratio.h / 2, "v");
      }
    }
    if (flowLength > 0) {
      if (filterFreq.current > 0) noise_lp2.set({ frequency: filterFreq.current });
      filterFreq.current = p5.map(sum2, 80, flowLength, 200, 22050);
      bassVelocity = clamp(p5.map(sum2, 0, flowLength, 0, 1) * 10, 0, 1);
    }
  }
  function draw3(p5, rndr) {
    notes3 = props.settings3.samples;
    if (particles3) particles3.clear();
    if (props.viewEffects && props.opts[0] !== null) {
      thresh3 = thresh3_0;
      thresh3.image(rndr, 0, 0, ratio.w, ratio.h);
      thresh3.filter(p5.THRESHOLD, slider3);
    } else {
      thresh3 = thresh3_gl;
      thresh3.shader(shader);
      shader.setUniform("tex0", rndr);
      shader.setUniform("thresh", slider3);
      shader.setUniform("invert", false);
      thresh3.rect(0, 0, ratio.w, ratio.h);
    }
    slider3 = p5.map(props.settings3.threshold, 0, 100, 0.0, 1.0);
    for (let i = 0; i < notes3; i++) {
      samples3[i].color();
      if (mode === 3) {
        samples3[i].anim();
        samples3[i].show(p5.mouseX, p5.mouseY);
        samples3[i].wave();
      }
    }
  }

  function draw4(p5, pix) {
    slider4 = p5.map(props.settings4.threshold, 0, 100, 1, 24);
    jsfeat.imgproc.grayscale(pix, ratio.w, ratio.h, buffer);
    jsfeat.imgproc.gaussian_blur(buffer, buffer, slider4, 0);
    jsfeat.imgproc.canny(buffer, buffer, lowThreshold4, highThreshold4);
    result = jsfeatToP5(buffer, result, p5);
    thresh4.image(result, 0, 0, p5.width, p5.height);
    if (particles4) particles4.clear();
    for (let i = 0; i < notes4; i++) {
      if (props.playing && props.settings4.dynamic) samples4[i].move();
      samples4[i].play();
      if (mode === 4) {
        samples4[i].anim();
        samples4[i].show(p5.mouseX, p5.mouseY);
        samples4[i].wave(props.playing && props.settings4.dynamic);
      }
    }
  }

  /*
    M O U S E
  */

  const mousePressed = (p5) => {
    document.body
      .querySelector("#randombtn")
      ?.addEventListener("click", function () {
        if (mode === 1) {
          for (let i = 0; i < notes1; i++)
            samples1[i].randomize(ratio.w, ratio.h);
        }
        if (mode === 3)
          for (let i = 0; i < notes3; i++)
            samples3[i].randomize(ratio.w, ratio.h);

        if (mode === 4)
          for (let i = 0; i < notes4; i++)
            samples4[i].randomize(ratio.w, ratio.h);
      });
    try {
      cnv.mousePressed(() => {
        if (!props.metadata) return;
        if (mode === 1) {
          samplePressed(samples1, p5);
          if (!sample1isPressed) {
            drawRect = true;
            mXin = p5.constrain(p5.mouseX, 0, ratio.w);
            mYin = p5.constrain(p5.mouseY, 0, ratio.h);
          }
        }
        if (mode === 3) {
          samplePressed(samples3, p5);
          if (!sample3isPressed) {
            drawRect = true;
            mXin = p5.constrain(p5.mouseX, 0, ratio.w);
            mYin = p5.constrain(p5.mouseY, 0, ratio.h);
          }
        }
        if (mode === 4) {
          samplePressed(samples4, p5);
          if (!sample4isPressed) {
            drawRect = true;
            mXin = p5.constrain(p5.mouseX, 0, ratio.w);
            mYin = p5.constrain(p5.mouseY, 0, ratio.h);
          }
        }
      });
    } catch {
      return;
    }
  };
  const mouseDragged = (p5) => {
    mXout = p5.constrain(p5.mouseX, 0, ratio.w);
    mYout = p5.constrain(p5.mouseY, 0, ratio.h);
  };
  const mouseReleased = (p5) => {
    mXout = p5.constrain(p5.mouseX, 0, ratio.w);
    mYout = p5.constrain(p5.mouseY, 0, ratio.h);
    if (mode === 3) {
      let dX = p5.abs(mXin - mXout);
      let dY = p5.abs(mYin - mYout);
      let thr = 5;
      if (dX < thr && dY < 5) {
        drawRect = false;
      }
      if (drawRect) {
        for (let i = 0; i < samples3.length; i++) {
          samples3[i].resample(mXin, mYin, mXout, mYout);
        }
        drawRect = false;
      }
      if (rectLayer) rectLayer.clear();
      sampleReleased(samples3, p5);
      sample3isPressed = false;
    }
    if (mode === 1) {
      let dX = p5.abs(mXin - mXout);
      let dY = p5.abs(mYin - mYout);
      let thr = 5;
      if (dX < thr && dY < 5) {
        drawRect = false;
      }
      if (drawRect) {
        for (let i = 0; i < samples1.length; i++) {
          samples1[i].resample(mXin, mYin, mXout, mYout);
        }
        drawRect = false;
      }
      if (rectLayer) rectLayer.clear();
      sampleReleased(samples1, p5);
      sample1isPressed = false;
    }
    if (mode === 4) {
      let dX = p5.abs(mXin - mXout);
      let dY = p5.abs(mYin - mYout);
      let thr = 5;
      if (dX < thr && dY < 5) {
        drawRect = false;
      }
      if (drawRect) {
        for (let i = 0; i < samples4.length; i++) {
          samples4[i].resample(mXin, mYin, mXout, mYout);
        }
        drawRect = false;
      }
      if (rectLayer) rectLayer.clear();
      sampleReleased(samples4, p5);
      sample4isPressed = false;
    }
  };

  function samplePressed(samples, p5) {
    for (let i = 0; i < samples.length; i++) {
      samples[i].pressed(p5.mouseX, p5.mouseY);
      let d1 = p5.dist(p5.mouseX, p5.mouseY, samples[i].posX, samples[i].posY);
      if (d1 < 16) {
        if (mode === 3) sample3isPressed = true;
        if (mode === 1) sample1isPressed = true;
        if (mode === 4) sample4isPressed = true;
      }
    }
  }

  function sampleReleased(samples, p5) {
    for (let i = 0; i < samples.length; i++) {
      samples[i].released();
    }
  }

  /*
    E T C
  */

  function jsfeatToP5(src, dst, p5) {
    if (!dst || dst.width !== src.cols || dst.height !== src.rows) {
      dst = p5.createImage(src.cols, src.rows);
    }
    let n = src.data.length;
    dst.loadPixels();
    let srcData = src.data;
    let dstData = dst.pixels;
    for (let i = 0, j = 0; i < n; i++) {
      let cur = srcData[i];
      dstData[j++] = cur;
      dstData[j++] = cur;
      dstData[j++] = cur;
      dstData[j++] = 255;
    }
    dst.updatePixels();
    return dst;
  }

  function copyImage(src, dst) {
    let n = src.length;
    if (!dst || dst.length !== n) dst = new src.constructor(n);
    while (n--) dst[n] = src[n];
    return dst;
  }

  return (
    <>
      <Sketch
        preload={preload}
        setup={setup}
        draw={draw}
        mousePressed={mousePressed}
        mouseDragged={mouseDragged}
        mouseReleased={mouseReleased}
      />
      <Waveform
        toneFFT={toneFFT}
        toneWaveform={toneWaveform}
        palette={props.opts.palette}
        playing={props.playing}
      />
    </>
  );
};
export default Painter;
