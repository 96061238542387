import React, { useEffect, useRef, useGlobal, useState } from "reactn";
import styled from "styled-components";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { LUTCubeLoader } from "three/examples/jsm/loaders/LUTCubeLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import { LUTPass } from "three/examples/jsm/postprocessing/LUTPass.js";
import { LoadingManager } from "three";
import logo from "assets/cdcollect/logo.obj";
import { cdTxt } from "assets/cdcollect/cdTxt.js";
import diffuse from "assets/cdcollect/diffuse.png";
import specular from "assets/cdcollect/specular.png";
import bump from "assets/cdcollect/roughness.png";
import lut0 from "assets/cdcollect/lutscube/c0.cube";

const GSCDRom = ({ onClose, repair }) => {
  const [started, setStarted] = useState(false)
  const scene = useRef(null)
  const camera = useRef(null)
  const renderer = useRef(null)
  const manager = useRef(null)
  const cd = useRef(null)
  const lutPass = useRef(null)
  const composer = useRef(null)
  const lutMap = useRef(null)
  const controls = useRef(null)
  const [cdMounted] = useGlobal("cdMounted");
  const [cdCorrupted] = useGlobal("cdCorrupted");
  const canvasReference = useRef();
  const speed = useRef(0);

  useEffect(() => {
    const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
    const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
    let animFrame;
    setStarted(true)
    if (started) {
      initScene();
      initLights();
      initCD(cdTxt);
      function animate() {
        animFrame = requestAnimationFrame(animate);
        cd.current.rotation.y += speed.current * 0.01;
        renderer.current.render(scene.current, camera.current);
        controls.current.update();
        if (lutMap.current) lutPass.current.lut = lutMap.current.texture3D;
        composer.current.render();
      }
      animate();
    }
    return () => {
      cancelAnimationFrame(animFrame)
    };
}, [started]);

  useEffect(() => {
    if (cdCorrupted === "broken") loadData();
    async function loadData() {
      let randomLut = Math.round(Math.random() * 50);
      const lut = await import(`assets/cdcollect/lutscube/c${randomLut}.cube`);
      new LUTCubeLoader(manager.current).load(lut.default, (r) => (lutMap.current = r));
      lutPass.current.intensity = 1;
      const glitch = glitchCD(cdTxt);
      if (cd.current) scene.current.remove(cd.current);
      initCD(glitch);
    }
  }, [cdCorrupted, repair]);

  useEffect(() => {
    if (cdCorrupted === "fixed") {
      new LUTCubeLoader(manager.current).load(lut0, (r) => (lutMap.current = r));
      lutPass.current.intensity = 0.2;
      if (cd.current) scene.current.remove(cd.current);
      initCD(cdTxt);
    }
  }, [cdCorrupted]);

  useEffect(() => {
    const canvas = canvasReference.current;
    if (started && canvas && controls.current && cd.current) {
      if (cdMounted === "ghostsoftware") {
        speed.current = 1;
        canvas.style.backgroundImage = `linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      ),
      linear-gradient(
        45deg,
        var(--colorgrad1) 25%,
        transparent 25%,
        transparent 75%,
        var(--colorgrad1) 75%,
        var(--colorgrad1)
      )`;
        canvas.style.backgroundSize = "2px 2px";
        canvas.style.backgroundPosition = "0 0, 1px 1px";
        canvas.style.boxShadow = `inset -1px -1px 0px #ffffff, inset 1px 1px 0px 0px var(--colorgrad6), inset -2px -2px 0px var(--colorgrad4), inset 2px 2px 0px 0px var(--colorgrad8)`;
        controls.current.enabled = true;
      } else {
        speed.current = 0;
        if (cd.current) cd.current.rotation.y = Math.PI;
        canvas.style.backgroundImage = "none";
        canvas.style.boxShadow = "none";
        controls.current.enabled = false;
      }
    }
  }, [cdMounted, started]);

  function glitchCD(data) {
    let glitch = data;

    const replacement = (replaced, n) => {
      let val = null;
      while (val === null || val === replaced) {
        val = Math.round(Math.random() * 10);
      }
      return val;
    };

    const replaced = Math.round(Math.random() * 10);
    let vert0 = glitch.substring(glitch.indexOf(`		Vertices: *4416 {`));
    let vert1 = vert0.slice(vert0.indexOf(":"), vert0.indexOf("}"));
    let vert = vert1.replace(
      new RegExp(replaced, "g"),
      replacement(replaced, 2)
    );
    glitch = glitch.replace(vert1, vert);

    const replaced1 = Math.round(Math.random() * 10);
    let poly0 = glitch.substring(glitch.indexOf(`		PolygonVertexIndex: *5920 {`));
    let poly1 = poly0.slice(poly0.indexOf("9"), poly0.indexOf("}"));
    let poly = poly1.replace(
      new RegExp(replaced1, "g"),
      replacement(replaced1, 2)
    );
    glitch = glitch.replace(poly1, poly);

    const replaced2 = Math.round(Math.random() * 10);

    let uv0 = glitch.substring(glitch.indexOf(`			UVIndex: *5920 {`));
    let uv1 = uv0.slice(uv0.indexOf(":"), uv0.indexOf("}"));
    let uv = uv1.replace(new RegExp(replaced2, "g"), replacement(replaced2, 2));
    glitch = glitch.replace(uv1, uv);

    return glitch;
  }

  function initCD(cdData) {
    let buffer = new TextEncoder().encode(cdData);
    cd.current = new FBXLoader().parse(buffer);
    cd.current.traverse(function (child) {
      if (child.isMesh) {
        child.material = new THREE.MeshPhongMaterial({
          map: new THREE.TextureLoader().load(diffuse),
          specularMap: new THREE.TextureLoader().load(specular),
          color: 13421772,
          emissive: 0,
          specular: 1644825,
          shininess: 100,
          bumpScale: 0.7,
          bumpMap: new THREE.TextureLoader().load(bump),
        });
        child.material.map.wrapS = 1000;
        child.material.map.wrapT = 1000;
        child.material.map.encoding = 3001;
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });

    new OBJLoader(manager.current).load(logo, function (logo) {
      logo.traverse(function (child) {
        if (child.isMesh) {
          child.material = new THREE.MeshPhongMaterial({
            color: 0xffffff,
          });
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
      logo.scale.set(2, 1.5, 1.5);
      logo.position.z = -0.15;
      logo.position.y = 4.5;
      cd.current.add(logo);
    });
    cd.current.position.y = -12;
    cd.current.rotation.y = Math.PI;
    scene.current.add(cd.current);
  }

  function initLights() {
    const dirLight = new THREE.DirectionalLight(
      new THREE.Color(`hsl(${Math.floor(Math.random() * 360)}, 40%, 70%)`),
      1,
      1
    );
    dirLight.position.set(30, -50, 20);
    scene.current.add(dirLight);
    const dirLight2 = new THREE.DirectionalLight(
      new THREE.Color(`hsl(${Math.floor(Math.random() * 360)}, 40%, 70%)`),
      1,
      1
    );
    dirLight2.position.set(20, 20, 50);
    scene.current.add(dirLight2);
    const dirLight3 = new THREE.DirectionalLight(
      new THREE.Color(`hsl(${Math.floor(Math.random() * 360)}, 40%, 70%)`),
      1,
      1
    );
    dirLight3.position.set(70, -20, -80);
    scene.current.add(dirLight3);
    const ambLight = new THREE.AmbientLight(0xffffff, 3);
    ambLight.position.set(-10, -20, -10);
    scene.current.add(ambLight);
    const hemLight = new THREE.HemisphereLight(0x404040, 0xffd9f2, 2);
    hemLight.position.set(-200, 0, 0);
    scene.current.add(hemLight);
  }

  function initScene() {
    manager.current = new LoadingManager();
    manager.current.onStart = function () {
      if (canvasReference.current) canvasReference.current.style.opacity = "0";
    };
    manager.current.onLoad = function () {
      if (canvasReference.current) canvasReference.current.style.opacity = "1";
    };

    scene.current = new THREE.Scene();
    camera.current = new THREE.PerspectiveCamera(45, 1, 1, 1000);
    camera.current.position.set(0,0,10)
    scene.current.add(camera.current);

    renderer.current = new THREE.WebGLRenderer({
      preserveDrawingBuffer: true,
      alpha: true,
      antialias: true,
      premultipliedAlpha: true,
    });
    renderer.current.setSize(800, 800);
    renderer.current.physicallyCorrectLights = true;
    renderer.current.outputColorSpace = THREE.sRGBEncoding;

    const target = new THREE.WebGLRenderTarget({
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat,
      encoding: THREE.sRGBEncoding,
    });
    composer.current = new EffectComposer(renderer.current, target);
    composer.current.setSize(800, 800);
    composer.current.addPass(new RenderPass(scene.current, camera.current));
    composer.current.addPass(new ShaderPass(GammaCorrectionShader));
    lutPass.current = new LUTPass();
    lutPass.current.enabled = true;
    lutPass.current.intensity = 0.2;
    composer.current.addPass(lutPass.current);
    new LUTCubeLoader(manager.current).load(lut0, (r) => (lutMap.current = r));
    controls.current = new OrbitControls(camera.current, renderer.current.domElement);
    controls.current.maxDistance = 80;
    controls.current.minDistance = 35;
    controls.current.enablePan = false;
    canvasReference.current.appendChild(renderer.current.domElement);
  }

  useEffect(() => {
    const cd_table = document.querySelector("#cd_inner.window_content_inner");
    const resizeObserver = new ResizeObserver((entries) => {
      if (camera.current) {
        camera.current.updateProjectionMatrix();
        renderer.current.setSize(entries[0].target.clientHeight-200, entries[0].target.clientHeight-200);
      }
    });
    resizeObserver.observe(cd_table);
  }, []);

  return (
    <Div>
      <div
        className="canvasreference"
        id="cd_canvas"
        ref={canvasReference}
      ></div>
    </Div>
  );
};

const Div = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
width: 100%;
height: 100%;
#cd_canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: 100%  !important;
  opacity: 0;
  transition: box-shadow 0.3s linear, background-image 0.3s linear,
    opacity 0.2s 1s ease-in;
  canvas {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
`;

export default GSCDRom;
