import React, { useState } from "reactn";
import styled from "styled-components";
import StartSubMenu from "./StartSubMenu";
import Sprite from "./Sprite";
import { startMenuIcons } from "apps";
import run from "assets/icons/run.ico";
import logoff from "assets/icons/logoff.ico";
import shut from "assets/icons/shutdown.ico";
import programs from "assets/icons/programs.ico";
import { appSettings } from "apps";

const StartMenu = ({ className, onClick }) => {
  const rect = document.querySelector("#taskbar_start_menu").getBoundingClientRect();
  const menuWidth = rect.width;

  startMenuIcons.forEach((item) => {
    if (item.type === "menu") {
      item.items.forEach((subItem) => {
        subItem.title = appSettings[subItem.text].header.title;
        subItem.icon = appSettings[subItem.text].header.icon;
      });
    } else {
      item.title = appSettings[item.text].header.title;
      item.icon = appSettings[item.text].header.icon;
    }
  });
  const [hovering, setHovering] = useState("");
  function onPointerOver(e) {
    const item = e.target.closest(".menu_item");
    if (!item) {
      setHovering("");
      return;
    }
    setHovering(item.querySelector(".menu_item_text").textContent);
  }
  return (
    <div className={className}>
      <Sprite />
      <div className="divider"></div>
      <section className="menu" onPointerOver={onPointerOver}>
        <Item onClick={onClick} text="MyComputer">
          <div className="menu_item_subtext">Heaven Computer</div>
        </Item>
        <Item onClick={onClick} text="Metamask">
          <div className="menu_item_subtext">Metamask</div>
        </Item>
        <Item text="Programs" icon={programs}>
          {hovering === "Programs" && (
            <StartSubMenu
              style={{ marginLeft: menuWidth - 40 +"px" }}
              data={startMenuIcons}
              onClick={onClick}
            />
          )}
          <div className="menu_arrow"></div>
        </Item>
        <Items
          onClick={onClick}
          items={[
            { text: "Documents" },
            { text: "Settings" },
            { text: "Help" },
            { text: "Run", icon: run },
          ]}
        />
      </section>
      <div className="divider"></div>
      <footer>
        <div className="footer_item" onClick={() => onClick("Log Off")}>
          <img
            draggable={false}
            className="footer_item_img"
            src={logoff}
            alt="favicon"
          />
          <span>Log Off</span>
        </div>
        <div className="footer_item" onClick={() => onClick("Shut Down")}>
          <img
            draggable={false}
            className="footer_item_img"
            src={shut}
            alt="favicon"
          />
          <span>Shut Down...</span>
        </div>
      </footer>
    </div>
  );
};
function Items({ items, ...rest }) {
  return items.map((item, i) => <Item key={i} {...item} {...rest} />);
}
function Item({
  style,
  text,
  icon,
  onHover = () => {},
  onClick = () => {},
  children,
}) {
  function _onClick() {
    onClick(text);
  }
  function onPointerEnter() {
    onHover(text);
  }

  return (
    <div
      className="menu_item"
      style={style}
      onClick={_onClick}
      onPointerEnter={onPointerEnter}
    >
      <img
        draggable={false}
        className="menu_item_img"
        src={icon ? icon : appSettings[text].header.icon}
        alt={text}
      />
      <div className="menu_item_texts">
        <div className="menu_item_text">
          {appSettings[text]
            ? appSettings[text].header.alt
              ? appSettings[text].header.alt
              : appSettings[text].header.title
            : text}
        </div>
        {children}
      </div>
    </div>
  );
}
export default styled(StartMenu)`
  font-size: 11px;
  display: flex;
  width: 200px;
  max-width: calc(100vw/3 + 20px);
  flex: 0 0 200px;
  flex-direction: column;
  align-items: center;
  background: var(--colorgrad3);
  box-shadow: inset -1px -1px 0px var(--colorgrad8),
    inset 1px 1px 0px var(--colorgrad4), inset -2px -2px 0px var(--colorgrad6),
    inset 2px 2px 0px var(--colorgrad0);
  .menu_arrow {
    right: 10px;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid black;
  }
  footer,
  .menu {
    display: flex;
    width: 100%;
    padding: 0 4px;
    flex-direction: column;
    margin: 4px;
    position: relative;
  }
  .divider {
    width: calc(100% - 16px);
    height: 1px;
  }
  .menu_item_img,
  .footer_item_img {
    width: 16px;
    height: 16px;
    margin: 5px;
    align-self: center;
  }
  .footer_item {
    display: flex;
    align-items: center;
    &:hover {
      color: white;
      background-color: #266ebb;
    }
    &:hover:active > * {
      transform: translate(1px, 1px);
    }
  }
  .sub_menu {
    position: absolute;
    left: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }
  .menu_item,
  .footer_item {
    display: flex;
    flex-direction: row;
    height: 28px;
  }
  .menu_item:hover {
    color: white;
    background-color: #266ebb;
  }
  .menu_item:hover .menu_arrow {
    border-left: 4px solid white;
  }
  .menu_item:hover .menu_item_subtext {
    color: white;
  }
  .menu_item_text {
    max-width: calc(100vw/3 - 20px);
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .menu_item_texts {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    height: 100%;
    position: relative;
  }
  .menu_item_subtext {
    color: var(--colorgrad6);
    font-size: 8px;
    margin-bottom: 1px;
  }
`;
