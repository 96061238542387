export const LOAD_IMAGE = "LOAD_IMAGE";
export const LOAD_STATE = "LOAD_STATE";
export const ASYNC_FILTER = "ASYNC_FILTER";
export const FILTER_IMAGE = "FILTER_IMAGE";
export const SELECT_FILTER = "SELECT_FILTER";
export const SET_FILTER_OPTION = "SET_FILTER_OPTION";
export const SET_FILTER_PALETTE_OPTION = "SET_FILTER_PALETTE_OPTION";
export const ADD_PALETTE_COLOR = "ADD_PALETTE_COLOR";
export const SET_GRAYSCALE = "SET_GRAYSCALE";
export const SET_VIEW_EFFECTS = "SET_VIEW_EFFECTS";
export const SET_INPUT_CANVAS = "SET_INPUT_CANVAS";
export const SET_INPUT_PLAYBACK_RATE = "SET_INPUT_PLAYBACK_RATE";
export const RGB_NEAREST = "RGB_NEAREST_FAST";
export const RGB_APPROX = "RGB_APPROX";
export const HSV_NEAREST = "HSV_NEAREST";
export const BOOL = "BOOL";
export const ENUM = "ENUM";
export const BOOL_RANGE = "BOOL_RANGE";
export const RANGE = "RANGE";
export const STRING = "STRING";
export const COLOR = "COLOR";
export const PALETTE = "PALETTE";
export const COLOR_ARRAY = "COLOR_ARRAY";
export const COLOR_DISTANCE_ALGORITHM = {
  type: ENUM,
  options: [
    { name: "RGB", value: RGB_NEAREST },
    { name: "RGB (approx.)", value: RGB_APPROX },
    { name: "HSV", value: HSV_NEAREST },
  ],
  default: RGB_APPROX
};
