import React from 'react';
import styled from 'styled-components';

function HeaderButtons({
  buttons,
  onMaximize,
  onMinimize,
  onClose,
  onHelp,
  maximized,
  resizable,
  className,
  help
}) {
  const buttonElements = {
    minimize: (
      <button
        key="minimize"
        className="header_button header_button--minimize"
        onPointerUp={onMinimize}
      />
    ),
    maximize: (
      <button
        key="maximize"
        className={`header_button header_button--maximize ${resizable ? '' : 'header_button--disable'}`}
        onPointerUp={onMaximize}
      />
    ),
    close: (
      <button
        key="close"
        className="header_button header_button--close"
        onPointerUp={onClose}
      />
    ),
    help: (
      <button
        key="help"
        className="header_button header_button--help"
        onPointerUp={onHelp}
      />
    ),
  };

  return (
    <div className={className}>
      {buttons ? (
        buttons.map(b => buttonElements[b])
      ) : (
        <>
          {buttonElements.help}
          {buttonElements.minimize}
          {buttonElements.maximize}
          {buttonElements.close}
        </>
      )}
    </div>
  );
}
export default styled(HeaderButtons)`
  opacity: ${({ focused }) => (focused ? 1 : 0.6)};
  height: 22px;
  display: flex;
  align-items: center;
  .header_button {
    height: 18px;
    width: 18px;
    outline: none;
    color: var(--colorgrad7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 3px;
    padding: 0;
    border: 0;
    background: var(--colorgrad3);
    box-shadow: inset -1px -1px 0 0 var(--colorgrad7), inset 1px 1px 0 0 var(--colorgrad0), inset -2px -2px 0 0 var(--colorgrad5), inset 2px 2px 0 0 var(--colorgrad2);
  }
  .header_button--help:after {
    content: "?";
    align-self: center;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .header_button:active {
    background-color: var(--colorgrad3);
    background-image: linear-gradient(45deg, var(--colorgrad1) 25%, transparent 25%, transparent 75%, var(--colorgrad1) 75%, var(--colorgrad1)), linear-gradient(45deg, var(--colorgrad1) 25%, transparent 25%, transparent 75%, var(--colorgrad1) 75%, var(--colorgrad1));
    background-size: 2px 2px;
    background-position: 0 0, 1px 1px;
    box-shadow: inset -1px -1px 0 0 var(--colorgrad0), inset 1px 1px 0 0 var(--colorgrad7), inset -2px -2px 0 0 var(--colorgrad2), inset 2px 2px 0 0 var(--colorgrad3);
  }
  .header_button--minimize:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 3px;
    width: 8px;
    height: 2px;
    background: var(--colorgrad7);
  }
  .header_button--maximize:after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid var(--colorgrad7);
    border-top-width: 2px;
  }
  .header_button--close:before, .header_button--close:after {
    transform-origin: center center;
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 12px;
    background: var(--colorgrad7);
  }
  .header_button--close:before {
    transform: rotateZ(-45deg);
  }
  .header_button--close:after {
    transform: rotateZ(45deg);
  }

`;
