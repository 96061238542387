import React, { useEffect } from "react";
import { clamp } from "utils";

const NumberInput = ({
  min,
  max,
  step,
  handleInput,
  id,
  defaultValue,
  unit,
}) => {
  const input = document.querySelector("#" + id);

  const errorAnimation = () => {
    const e = document.querySelector("form.number-input." + id);
    e.classList.add("shake_err");
    setTimeout(() => {
      e.classList.remove("shake_err");
    }, 700);
  };

  const validateInput = (step) => {
    let value = parseInt(input.value);
    let val;
    if (value + step >= min && value + step <= max) {
      val = value + step;
    } else {
      errorAnimation();
      val = clamp(value + step, min, max);
    }
    input.value = val + unit;
    handleInput(val);
  };

  useEffect(() => {
    const onlyNumbers = (e) => {
      if (
        e.key === "Enter" ||
        e.key === "Backspace" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight"
      ) {
        return;
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        validateInput(step);
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        validateInput(-step);
      } else if (!/^\d*$/.test(e.key)) {
        e.preventDefault();
      } else {
        return;
      }
    };
    input?.addEventListener("keydown", onlyNumbers);
    return () => input?.removeEventListener("keydown", onlyNumbers);
    // eslint-disable-next-line
  }, [input]);

  return (
    <form
      autoComplete="off"
      className={"number-input " + id}
      noValidate="novalidate"
      onSubmit={(e) => {
        e.preventDefault();
        validateInput(0);
      }}
    >
      <input
        className="texter"
        id={id}
        defaultValue={unit ? defaultValue + unit : defaultValue}
        type="text"
      />
      <div className="number-btns">
        <div className="pointer" onPointerUp={() => validateInput(step)}>
          <div />
        </div>
        <div className="pointer" onPointerUp={() => validateInput(-step)}>
          <div />
        </div>
      </div>
    </form>
  );
};

export default NumberInput;
