import React, { useRef } from "react";
import { useDropDown } from "hooks";

const DropDownMenu = ({
  handleDropDown,
  style,
  optionList,
  menuName,
  scrollParent,
  disabled,
}) => {
  const dropup = useRef();
  const dropdown = useRef();

  useDropDown(
    dropup.current,
    dropdown.current,
    disabled,
    scrollParent,
    handleDropDown
  );

  return (
    <div className="drop_wrap" style={style}>
      <div
        className={`dropup${disabled ? " disabled" : ""}`}
        ref={(e) => (dropup.current = e)}
      >
        <span>{menuName}</span>
        <div className={`dropup_btn${disabled ? " disabled" : ""}`}>
          <div />
        </div>
      </div>

      <div className="dropdown closed" ref={(e) => (dropdown.current = e)}>
        {optionList.map((text, i) => (
          <div className="dropdown_option" key={i}>
            <span>{text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropDownMenu;
