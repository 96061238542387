import React from "react";
import styled from "styled-components";

const Sky4 = ({ onToggleComponent, id, standAlone }) => {
  return (
    <Div className="skysection4 content animate selectable">
      <div className="poly-columns">
        <div className="poly-column animate">
          <p>
            Imagine celestial events like a total solar eclipse or aurora borealis translated into pixels and splattered across your walls, enveloping your residence in divine artistry. Such are the walls of SkyLuxury. More than just structures, they are canvases for fine art: the exclusive <span className="sl_link pointer" onClick={() => {
              if (standAlone) window.location.href = "https://heaven.computer";
              onToggleComponent("Gradient Graphics", "open", id);
            }}>gradient.graphics</span> collection, so exclusive that it's yet to be released, by the artist <strong>godmin</strong> — an artist who is widely acknowledged as being an artist who is set to become widely acknowledged in the art scene at some point in the future.
          </p>
        </div>
        <div className="poly-column animate">
          <p>
            Embrace the discreet charm of the nouvelle bourgeoisie. Our interiors draw inspiration from Jacques Tati's ingenious Villa Arpel design, infusing avant-garde geometric abstraction into everyday furnishings, including your sofa. All residences come furnished with up to three random 'furnis,' whose function is entirely yours to decide. Ever fancied sitting on a cone? Now you can. Now you own it. Owning a SkyLuxury residence means you're not just keeping up with the trends — <strong>you are setting them.</strong>
          </p>
        </div>
        <div className="poly-center animate">
          <div className="poly-wrap">
            <div className="poly"></div>
            <div className="poly"></div>
            <div className="poly"></div>
            <div className="poly"></div>
            <div className="poly"></div>
            <div className="poly"></div>
            <div className="poly"></div>
            <div className="poly"></div>
          </div>
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  flex-direction: row;
  --rotT: 7s;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
.poly-column {
  max-width: clamp(400px, 50%, 40vh);
  position: relative;
}

.sl_link {
  --shadow: var(--textColor6);
}

.poly-column p {
  padding-bottom: 4rem;
  text-align: justify !important;
  text-align-last: center;
  position: relative;
}

.poly-columns {
  display: flex;
  justify-content: center;
  align-self: center;
  --gap: clamp(1vw, 2em, 1vh);
  gap: var(--gap);
}

.poly-column::before {
  shape-margin: 2px;
  --baseW: calc(15vmin + var(--gap) * 2);
  --finalW: calc(var(--baseW) * 2/3 + var(--gap));
  --angleOpening: 0deg;
  --initAngle: 0deg;
  --finalAngle: 15deg;
  content: "";
  position: relative;
  height: 100%;
  shape-outside: var(--mask);
  width: var(--finalW);
  animation: rotShape calc(var(--rotT)/2) linear infinite;
}

.poly-column:first-of-type {
  transform: translateX(-150vh);
}

.poly-column:nth-of-type(2) {
  transform: translateX(150vh);
}

.poly-column:first-of-type:before {
  float: right;
  --mask: conic-gradient(from calc(45deg - var(--angleOpening)/2) at 0% var(--translatePoly), rgb(0, 0, 0) 0deg calc(90deg + var(--angleOpening)), transparent calc(90deg + var(--angleOpening)));
}

.poly-column:nth-of-type(2):before {
  float: left;
  --mask: conic-gradient(from calc(-135deg - var(--angleOpening)/2) at 100% var(--translatePoly), rgb(0, 0, 0) 0deg calc(90deg + var(--angleOpening)), transparent calc(90deg + var(--angleOpening)));
}

.poly-center {
  top: -50%;
  transform: translateY(var(--translatePoly));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  position: absolute;
  height: 100%;
  width: 100%;
  mix-blend-mode: difference;
}

.poly-wrap {
  pointer-events: none;
  --i: 0;
  --j: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotateY(0deg);
  animation: rotPoly var(--rotT) linear infinite;
}

.poly-wrap:before {
  position: absolute;
  transform: translateY(calc(17.67767vmin + 2em)) rotateX(90deg);
  content: "";
}

.poly {
  opacity: 0.5;
  position: absolute;
  margin: -14.43376vmin;
  padding: 14.43376vmin;
  transform: rotateY(calc(var(--i)*-1turn/4)) rotate(calc((1 - var(--j))*.5turn)) rotatex(35.26439deg) translatez(10.20621vmin);
  background: linear-gradient(to bottom, var(--polyColor0), var(--polyColor1));
  filter: brightness(calc(1 + var(--j)*.1));
  --shape: polygon(50% 0%, 93.30127% 75%, 6.69873% 75%);
  -webkit-clip-path: var(--shape);
  clip-path: var(--shape);
  animation: rotShade calc(var(--rotT)/2) ease-in-out calc(var(--i)*-1/4*5s) infinite alternate;
  animation-play-state: inherit;
}

.poly:nth-child(n + 5) {
  --j: 1;
}

.poly:nth-child(4n + 2) {
  --i: 1;
}

.poly:nth-child(4n + 3) {
  --i: 2;
}

.poly:nth-child(4n + 4) {
  --i: 3;
}`;

export default Sky4;