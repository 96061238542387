// actions
export const FOCUSING = {
  WINDOW: "WINDOW",
  ICON: "ICON",
  DESKTOP: "DESKTOP",
};
export const POWER_STATE = {
  TURN_ON: "TURN_ON",
  TURN_OFF: "TURN_OFF",
  LOG_OFF: "LOG_OFF",
};
export const OPEN_APP = "OPEN_APP";
export const CLOSE_APP = "CLOSE_APP";
export const CLOSE_APPS = "CLOSE_APPS";
export const FOCUS_APP = "FOCUS_APP";
export const MINIMIZE_APP = "MINIMIZE_APP";
export const TOGGLE_MAXIMIZE_APP = "TOGGLE_MAXIMIZE_APP";
export const FOCUS_ICON = "FOCUS_ICON";
export const SELECT_ICONS = "SELECT_ICONS";
export const FOCUS_DESKTOP = "FOCUS_DESKTOP";
export const START_SELECT = "START_SELECT";
export const END_SELECT = "END_SELECT";
export const SHUT_DOWN = "SHUT_DOWN";
export const CANCEL_POWER_OFF = "CANCEL_POWER_OFF";

// reducer
export const Reducer = (state, action = { type: "" }) => {
  switch (action.type) {
    case OPEN_APP:
      const app = state.apps.find(
        (_app) => _app.component === action.payload.component
      );
      if (action.payload.multiInstance || !app) {
        return {
          ...state,
          apps: [
            ...state.apps,
            {
              ...action.payload,
              id: state.nextAppID,
              zIndex: state.nextZIndex,
            },
          ],
          nextAppID: state.nextAppID + 1,
          nextZIndex: state.nextZIndex + 1,
          focusing: FOCUSING.WINDOW,
        };
      }
      const apps = state.apps.map((app) =>
        app.component === action.payload.component
          ? { ...app, zIndex: state.nextZIndex, minimized: false }
          : app
      );
      return {
        ...state,
        apps,
        nextZIndex: state.nextZIndex + 1,
        focusing: FOCUSING.WINDOW,
      };
    case CLOSE_APP:
      if (state.focusing !== FOCUSING.WINDOW) return state;
      return {
        ...state,
        apps: state.apps.filter((app) => app.id !== action.payload),
        focusing:
          state.apps.length > 1
            ? FOCUSING.WINDOW
            : state.icons.find((icon) => icon.focused)
            ? FOCUSING.ICON
            : FOCUSING.DESKTOP,
      };
    case CLOSE_APPS:
      if (state.focusing !== FOCUSING.WINDOW) return state;
      return {
        ...state,
        apps: state.apps.filter((app) => app.header.title !== action.payload),
        focusing:
          state.apps.length > 1
            ? FOCUSING.WINDOW
            : state.icons.find((icon) => icon.focused)
            ? FOCUSING.ICON
            : FOCUSING.DESKTOP,
      };
    case FOCUS_APP: {
      const apps = state.apps.map((app) =>
        app.id === action.payload
          ? { ...app, zIndex: state.nextZIndex, minimized: false }
          : app
      );
      return {
        ...state,
        apps,
        nextZIndex: state.nextZIndex + 1,
        focusing: FOCUSING.WINDOW,
      };
    }
    case MINIMIZE_APP: {
      if (state.focusing !== FOCUSING.WINDOW) return state;
      const apps = state.apps.map((app) =>
        app.id === action.payload ? { ...app, minimized: true } : app
      );
      return {
        ...state,
        apps,
        focusing: FOCUSING.WINDOW,
      };
    }
    case TOGGLE_MAXIMIZE_APP: {
      if (state.focusing !== FOCUSING.WINDOW) return state;
      const apps = state.apps.map((app) =>
        app.id === action.payload ? { ...app, maximized: !app.maximized } : app
      );
      return {
        ...state,
        apps,
        focusing: FOCUSING.WINDOW,
      };
    }
    case FOCUS_ICON: {
      const icons = state.icons.map((icon) => ({
        ...icon,
        focused: icon.id === action.payload,
      }));
      return {
        ...state,
        focusing: FOCUSING.ICON,
        icons,
      };
    }
    case SELECT_ICONS: {
      const icons = state.icons.map((icon) => ({
        ...icon,
        focused: action.payload.includes(icon.id),
      }));
      return {
        ...state,
        icons,
        focusing: FOCUSING.ICON,
      };
    }
    case FOCUS_DESKTOP:
      return {
        ...state,
        focusing: FOCUSING.DESKTOP,
        icons: state.icons.map((icon) => ({
          ...icon,
          focused: false,
        })),
      };
    case START_SELECT:
      return {
        ...state,
        focusing: FOCUSING.DESKTOP,
        icons: state.icons.map((icon) => ({
          ...icon,
          focused: false,
        })),
        selecting: action.payload,
      };
    case END_SELECT:
      return {
        ...state,
        selecting: false,
      };
    case SHUT_DOWN:
      return {
        ...state,
        powerState: action.payload,
      };
    case CANCEL_POWER_OFF:
      return {
        ...state,
        powerState: POWER_STATE.TURN_ON,
      };
    default:
      return state;
  }
};

// mall
export const playerData = {
  height: 16,
  pos_x: 0,
  pos_y: 20,
  pos_z: 200,
  jump_velocity: 40,
  direction: 0,
  collision_dist: 20,
  step_size: 5,
  speed: 10,
  fly_mode: false,
};
