export function fairyDustCursor(canvas) {
  const possibleColors = [];
  for (let i = 0; i < 10; i++) {
    let pastelColor = `hsl(${360 * Math.random()},70%,80%)`;
    while (possibleColors.includes(pastelColor)) {
      pastelColor = `hsl(${360 * Math.random()},70%,80%)`;
    }
    possibleColors.push(pastelColor);
  }

  let width = window.innerWidth;
  let height = window.innerHeight;
  const cursor = { x: width / 2, y: width / 2 };
  const lastPos = { x: width / 2, y: width / 2 };
  let particles = [];
  const canvImages = [];
  const context = canvas.getContext("2d", { willReadFrequently: true });
  const char = "+";

  function init() {
    canvas.style.top = "0px";
    canvas.style.left = "0px";
    canvas.style.zIndex = "10000";
    canvas.style.pointerEvents = "none";
    canvas.style.position = "fixed";
    canvas.width = width;
    canvas.height = height;
    context.font = "12px monospace";
    context.textBaseline = "middle";
    context.textAlign = "center";

    possibleColors.forEach((color) => {
      const measurements = context.measureText(char);
      const bgCanvas = document.createElement("canvas");
      const bgContext = bgCanvas.getContext("2d", { willReadFrequently: true });

      bgCanvas.width = measurements.width;
      bgCanvas.height =
        measurements.actualBoundingBoxAscent +
        measurements.actualBoundingBoxDescent;
      bgContext.fillStyle = color;
      bgContext.textAlign = "center";
      bgContext.font = "10px monospace";
      bgContext.textBaseline = "middle";
      bgContext.fillText(
        char,
        bgCanvas.width / 2,
        measurements.actualBoundingBoxAscent
      );
      canvImages.push(bgCanvas);
    });

    bindEvents();
    loop();
  }

  function bindEvents() {
    document.body.addEventListener("mousemove", onPointerMove);
    document.body.addEventListener("touchmove", onTouchMove);
    document.body.addEventListener("touchstart", onTouchMove);
    window.addEventListener("resize", onWindowResize);
  }

  function onWindowResize() {
    width = window.innerWidth;
    height = window.innerHeight;
    canvas.width = width;
    canvas.height = height;
  }

  function onTouchMove(e) {
    if (e.touches.length > 0) {
      for (let i = 0; i < e.touches.length; i++) {
        addParticle(
          e.touches[i].clientX,
          e.touches[i].clientY,
          canvImages[Math.floor(Math.random() * canvImages.length)]
        );
      }
    }
  }

  function onPointerMove(e) {
    window.requestAnimationFrame(() => {
      cursor.x = e.clientX;
      cursor.y = e.clientY;
      const distBetweenPoints = Math.hypot(
        cursor.x - lastPos.x,
        cursor.y - lastPos.y
      );
      if (distBetweenPoints > 10) {
        addParticle(
          cursor.x,
          cursor.y,
          canvImages[Math.floor(Math.random() * possibleColors.length)]
        );
        lastPos.x = cursor.x;
        lastPos.y = cursor.y;
      }
    });
  }

  function addParticle(x, y, canvasItem) {
    particles.push(new Particle(x, y, canvasItem));
  }

  function updateParticles() {
    context.clearRect(0, 0, width, height);
    particles.forEach((particle) => particle.update(context));
    particles = particles.filter((particle) => particle.lifeSpan > 0);
  }

  function loop() {
    updateParticles();
    requestAnimationFrame(loop);
  }

  function Particle(x, y, canvasItem) {
    this.lifeSpan = Math.floor(Math.random() * 30 + 300);
    this.initialLifeSpan = this.lifeSpan;
    this.velocity = {
      x: (Math.random() < 0.5 ? -1 : 1) * (Math.random() / 3),
      y: Math.random() * 0.7 + 0.6,
    };
    this.position = { x, y };
    this.canv = canvasItem;

    this.update = function (context) {
      this.position.x += this.velocity.x;
      this.position.y += this.velocity.y;
      this.lifeSpan--;

      const scale = Math.max(this.lifeSpan / this.initialLifeSpan, 0);
      context.drawImage(
        this.canv,
        this.position.x - (this.canv.width / 2) * scale,
        this.position.y - this.canv.height / 2,
        this.canv.width * scale,
        this.canv.height * scale
      );
    };
  }

  init();
}
