import React, { useEffect, useState, useContext } from "reactn";
import styled from "styled-components";
import bg from "assets/bg";
import imageToBase64 from "image-to-base64/browser";
import { BackgroundContext } from "../../contexts/BackgroundContext";

const Background = () => {
  // eslint-disable-next-line
  const [bgImage, setBgImage] = useContext(BackgroundContext);
  const [imgBytes, setImgBytes] = useState(null);
  const [uploaded, setUploaded] = useState(null);
  const setUpCanvas = (src) => {
    let img = new Image();
    let canvas = document.getElementById("bgCanvas");
    let ctx = canvas.getContext("2d");
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const dataUrl = canvas.toDataURL();
      const selector = document.querySelector("#bgSelector");
      if (selector) selector.style.backgroundImage = "url(" + dataUrl + ")";
    };
    img.src = src;
    return [img, canvas, ctx];
  }

  useEffect(() => {
    if (bgImage) {
      if (bgImage.startsWith("data:image")) {
        setUpCanvas(bgImage);
        setUploaded(bgImage);
      } else {
        imageToBase64(bgImage)
          .then((response) => {
            setImgBytes("data:image/png;base64," + response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setUpCanvas(bg);
      setUploaded(null);
      setImgBytes(null);
    }
  }, [bgImage]);

  useEffect(() => {
    if (imgBytes) {
      const setup = setUpCanvas(uploaded ? uploaded : bg);
      let img = setup[0];
      let canvas = setup[1];
      let ctx = setup[2];
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        loadLut();
      };
      let imgLut = new Image();
      let canvasLut = document.createElement("Canvas");
      let ctxLut = canvasLut.getContext("2d");
      function loadLut() {
        imgLut.crossOrigin = "Anonymous2";
        imgLut.onload = function () {
          canvasLut.width = imgLut.width;
          canvasLut.height = imgLut.height;
          ctxLut.drawImage(imgLut, 0, 0);
          filterImage(img, imgLut, canvasLut, ctxLut);
        };
        imgLut.src = imgBytes;
      }
      function filterImage(img, filter, canvasFilter, ctxFilter) {
        let lutWidth = canvasLut.width;
        let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let filterData = ctxFilter.getImageData(
          0,
          0,
          canvasFilter.width,
          canvasFilter.height
        );
        for (let i = 0; i < imgData.data.length; i += 4) {
          let r = Math.floor(imgData.data[i] / 4);
          let g = Math.floor(imgData.data[i + 1] / 4);
          let b = Math.floor(imgData.data[i + 2] / 4);
          let lutX = (b % 8) * 64 + r;
          let lutY = Math.floor(b / 8) * 64 + g;
          let lutIndex = (lutY * lutWidth + lutX) * 4;
          imgData.data[i] = filterData.data[lutIndex];
          imgData.data[i + 1] = filterData.data[lutIndex + 1];
          imgData.data[i + 2] = filterData.data[lutIndex + 2];
          imgData.data[i + 3] = 255;
        }
        ctx.putImageData(imgData, 0, 0);
        const dataUrl = canvas.toDataURL();
        const selector = document.querySelector("#bgSelector");
        if (selector) selector.style.backgroundImage = "url(" + dataUrl + ")";
      }
    }
  }, [imgBytes, uploaded]);

  return (
    <Div>
      <canvas id="bgCanvas"></canvas>
    </Div>
  );
};

// adjust on settings

// object-fit: contain;
// object-fit: cover;
// object-fit: fill;
// object-fit: none;
// object-fit: scale-down;


// stretch image
// width: 100%;
// height: 100%;


// position: absolute;
// top: 0;
// bottom: 0;
// right: 0;

const Div = styled.div`
object-fit: cover;
pointer-events: none;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
z-index: -1;
canvas {
  object-fit: cover;
  pointer-events: none;
  align-self: center;
  width: 100%;
  height: 100%;
}
`;

export default Background;
