// import sky from "assets/icons/sky.ico";
const skyIcons = [
  require("assets/icons/sky/sky0.ico"),
  require("assets/icons/sky/sky1.ico"),
  require("assets/icons/sky/sky2.ico"),
  require("assets/icons/sky/sky3.ico"),
  require("assets/icons/sky/sky4.ico"),
  require("assets/icons/sky/sky5.ico"),
  require("assets/icons/sky/sky6.ico"),
  require("assets/icons/sky/sky7.ico"),
  require("assets/icons/sky/sky8.ico"),
  require("assets/icons/sky/sky9.ico"),
  require("assets/icons/sky/sky10.ico"),
  require("assets/icons/sky/sky11.ico"),
  require("assets/icons/sky/sky12.ico"),
  require("assets/icons/sky/sky13.ico"),
  require("assets/icons/sky/sky14.ico"),
  require("assets/icons/sky/sky15.ico"),
  require("assets/icons/sky/sky16.ico"),
  require("assets/icons/sky/sky17.ico"),
  require("assets/icons/sky/sky18.ico"),
  require("assets/icons/sky/sky19.ico"),
  require("assets/icons/sky/sky20.ico"),
  require("assets/icons/sky/sky21.ico"),
  require("assets/icons/sky/sky22.ico"),
  require("assets/icons/sky/sky23.ico"),
  require("assets/icons/sky/sky24.ico"),
  require("assets/icons/sky/sky25.ico"),
  require("assets/icons/sky/sky26.ico"),
  require("assets/icons/sky/sky27.ico"),
  require("assets/icons/sky/sky28.ico"),
  require("assets/icons/sky/sky29.ico"),
  require("assets/icons/sky/sky30.ico"),
  require("assets/icons/sky/sky31.ico"),
  require("assets/icons/sky/sky32.ico"),
  require("assets/icons/sky/sky33.ico"),
  require("assets/icons/sky/sky34.ico"),
  require("assets/icons/sky/sky35.ico"),
  require("assets/icons/sky/sky36.ico"),
  require("assets/icons/sky/sky37.ico"),
  require("assets/icons/sky/sky38.ico"),
  require("assets/icons/sky/sky39.ico"),
  require("assets/icons/sky/sky40.ico"),
  require("assets/icons/sky/sky41.ico"),
  require("assets/icons/sky/sky42.ico"),
  require("assets/icons/sky/sky43.ico"),
  require("assets/icons/sky/sky44.ico"),
  require("assets/icons/sky/sky45.ico"),
  require("assets/icons/sky/sky46.ico"),
  require("assets/icons/sky/sky47.ico"),
  require("assets/icons/sky/sky48.ico"),
  require("assets/icons/sky/sky49.ico"),
  require("assets/icons/sky/sky50.ico"),
  require("assets/icons/sky/sky51.ico"),
  require("assets/icons/sky/sky52.ico"),
  require("assets/icons/sky/sky53.ico"),
  require("assets/icons/sky/sky54.ico"),
  require("assets/icons/sky/sky55.ico"),
  require("assets/icons/sky/sky56.ico"),
  require("assets/icons/sky/sky57.ico"),
  require("assets/icons/sky/sky58.ico"),
  require("assets/icons/sky/sky59.ico"),
  require("assets/icons/sky/sky60.ico"),
];

const sky = skyIcons[Math.floor(Math.random() * skyIcons.length)]

export default sky;

