const View = [
  {
    type: 'item',
    text: 'Artist',
    disable: false,
  },
  {
    type: 'item',
    text: 'Website',
    disable: false,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Back',
    disable: true,
  },
  {
    type: 'item',
    text: 'Forward',
    disable: true,
  },
];
const Collection = [
  {
    type: 'item',
    text: 'Ghost Software',
    disable: false,
  },
  {
    type: 'item',
    text: 'God Observer',
    disable: false,
  },
  {
    type: 'item',
    text: 'Sky Luxury',
    disable: false,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'CD Collection',
    disable: false,
  }
];
const Contact = [
  {
    type: 'item',
    text: 'Contact Me',
    disable: false,
  },
];
const Edit = [
  {
    type: 'item',
    text: 'Select All',
    disable: false,
  },
  {
    type: 'item',
    text: 'Select None',
    disable: true,
  },
  {
    type: 'separator'
  },
  {
    type: 'item',
    text: 'Refresh',
    disable: true,
  },
  {
    type: 'item',
    text: 'Close',
    disable: false,
  },
];

const optionsMenuData = { View, Collection, Contact, Edit };
export default optionsMenuData;
