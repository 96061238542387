import { PALETTE } from "../constants";
import * as palettes from "../palettes";
import { cloneCanvas, fillBufferPixel } from "../utils";


const optionTypes = {
  palette: { type: PALETTE, default: palettes.nearest },
};
const defaults = {  palette: optionTypes.palette.default };

const sepia = (
  input: HTMLCanvasElement,
  options: {
    palette: Palette
  } = defaults
): HTMLCanvasElement => {
  const { palette } = options;
  const output = cloneCanvas(input, false);
  const inputCtx = input.getContext("2d", {
  willReadFrequently: true
});
  const outputCtx = output.getContext("2d", {
  willReadFrequently: true
});

  if (!inputCtx || !outputCtx) {
    return input;
  }

  const buf = inputCtx.getImageData(0, 0, input.width, input.height).data;

  let nPixels = buf.length;
  let i;
  let r;
  let g;
  let b;

  for (i = 0; i < nPixels; i += 4) {
    r = buf[i + 0];
    g = buf[i + 1];
    b = buf[i + 2];

    buf[i + 0] = Math.min(255, r * 0.393 + g * 0.769 + b * 0.189);
    buf[i + 1] = Math.min(255, r * 0.349 + g * 0.686 + b * 0.168);
    buf[i + 2] = Math.min(255, r * 0.272 + g * 0.534 + b * 0.131);
    let col = palette.getColor([buf[i + 0], buf[i + 1], buf[i + 2], 255], palette.options);
    fillBufferPixel(buf, i, col[0], col[1], col[2], col[3]);
  }

  outputCtx.putImageData(new ImageData(buf, output.width, output.height), 0, 0);

  return output;
};

const defaultFunc = {
  name: "Sepia",
  func: sepia,
  options: defaults,
  optionTypes,
  defaults
}

export default defaultFunc;
