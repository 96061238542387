import React, { useState } from "react";
import styled from "styled-components";
import check from "assets/icons/check.png";

export function OptionsMenu({ items, position = {}, onClick, style }) {
  const [option, setOption] = useState("");
  return (
    <Div {...position}>
      <div className="options-menu_menu" style={style}>
        {items.map((item, index) => {
          switch (item.type) {
            case "option":
              return (
                <div
                  key={item.text}
                  className={`options-menu_row${item.disable ? "--disable" : ""}`}
                  onPointerEnter={() => setOption(item.text)}
                  onClick={() => onClick(item)}
                >
                  {item.select && !item.disable && (
                    <div className="options-menu_check">
                      <div />
                    </div>
                  )}
                  <div className="options-menu_text"><span>{item.text}</span></div>
                </div>
              );
            case "item":
              return (
                <div
                  key={item.text}
                  className={`options-menu_row${item.disable ? "--disable" : ""}`}
                  onPointerEnter={() => setOption(item.text)}
                  onClick={() => onClick(item)}
                >
                  {item.check && !item.disable && (
                    <div className="options-menu_check">
                      <img src={check} alt="favicon" />
                    </div>
                  )}
                  <div className="options-menu_text"><span>{item.text}</span></div>
                </div>
              );
            case "toggle":
            case "menu":
              return (
                <div
                  key={item.text}
                  className={item.disable ? "options-menu_row--disable" : (option === item.text ? "options-menu_row--active" : "options-menu_row")}
                  onPointerEnter={() => setOption(item.text)}
                >
                {item.check && !item.disable && (
                  <div className="options-menu_check">
                    <img src={check} alt="favicon" />
                  </div>
                )}
                  <div className="options-menu_text"><span>{item.text}</span></div>
                  <div style={{ position: "relative" }}>
                    {option === item.text && !item.disable && (
                      <OptionsMenu
                        style={option==="Objects" ? {overflowY: "auto", overflowX: "hidden", maxHeight: '200px'} : {}}
                        position={item.position}
                        items={item.items}
                        onClick={onClick}
                      />
                    )}
                  </div>
                  <div className="options-menu_arrow" />
                </div>
              );
            case "separator":
              return <div key={index} className="options-menu_separator" />;
            default:
              return null;
          }
        })}
      </div>
    </Div>
  );
}

const Div = styled.div`
  z-index: 10;
  left: ${({ left }) => (left ? left : "initial")};
  top: ${({ top }) => (top ? top : "initial")};
  right: ${({ right }) => (right ? right : "initial")};
  bottom: ${({ bottom }) => (bottom ? bottom : "initial")};
  position: absolute;
  display: flex;
  font-size: 11px;
  padding: 3px 4px 4px 3px;
  background: var(--colorgrad3);
  box-shadow: inset -1px -1px 0px var(--colorgrad8), inset 1px 1px 0px var(--colorgrad4),
    inset -2px -2px 0px var(--colorgrad6), inset 2px 2px 0px var(--colorgrad0);
  .options-menu_row {
    position: relative;
    display: contents;
    color: var(--black);
    &:hover > * {
      background: #266ebb;
      color: white;
      img {
        filter: invert(1);
      }
      div {
        background-color: #fff;
      }
    }
  }
  .options-menu_row--active {
    position: relative;
    display: contents;
    color: var(--black);
    & > * {
      background: #266ebb;
      color: white;
    }
  }
  .options-menu_check {
    position: relative;
    height: 0;
    top: 0px;
    left: 6px;
    div {
      background-color: var(--black);
      margin-top: 7.5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
  }
  .options-menu_arrow {
    position: relative;
    top: -14px;
    &:before {
      position: absolute;
      right: 7px;
      content: "";
      border-width: 4px;
      border-style: solid;
      border-right: 0;
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }
  .options-menu_row--disable {
    display: contents;
    color: var(--colorgrad5);
    text-shadow: 1px 1px 0 white;
  }
  .options-menu_title {
    height: 100%;
    line-height: 20px;
    color: white;
    background-color: #1660e8;
  }
  .options-menu_menu {
    z-index: 1;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    grid-template-columns: 16px auto auto 15px 0px;
  }
  .options-menu_menu ~ .options-menu_menu {
    border: 1px solid red;
  }
  .options-menu_separator {
    margin: 2px 3px 2px 3px;
    height: 1px;
    border-top: 1px solid var(--colorgrad6);
    border-bottom: 1px solid #ffffff;
  }
  .options-menu_text {
    padding: 1px 18px;
    display: flex;
    max-width: 160px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
export default OptionsMenu;
