const Lightbar = () => {
  return (
    <>
      <div
        className="hues"
        id="hue0"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 100%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue1"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 98%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue2"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 96%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue3"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 94%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue4"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 92%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue5"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 90%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue6"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 88%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue7"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 86%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue8"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 84%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue9"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 82%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue10"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 80%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue11"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 78%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue12"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 76%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue13"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 75%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue14"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 73%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue15"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 71%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue16"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 69%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue17"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 67%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue18"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 65%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue19"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 63%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue20"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 61%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue21"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 59%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue22"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 57%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue23"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 55%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue24"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 53%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue25"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 51%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue26"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 50%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue27"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 48%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue28"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 46%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue29"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 44%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue30"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 42%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue31"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 40%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue32"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 38%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue33"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 36%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue34"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 34%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue35"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 32%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue36"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 30%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue37"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 28%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue38"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 26%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue39"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 25%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue40"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 23%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue41"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 21%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue42"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 19%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue43"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 17%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue44"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 15%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue45"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 13%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue46"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 11%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue47"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 9%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue48"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 7%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue49"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 5%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue50"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 3%)",
        }}
      ></div>
      <div
        className="hues"
        id="hue51"
        style={{
          height: "4px",
          width: "100%",
          display: "block",
          backgroundColor: "hsl(0, 0.0%, 1%)",
        }}
      ></div>
    </>
  );
};

export default Lightbar;
