import React, { useEffect } from "react";
import { fairyDustCursor } from "./fairy.js";

const Cursor = () => {
  useEffect(() => {
    new fairyDustCursor(document.querySelector("#fairy_wrapper"));
    const color = "#ffffff";
    const sparkles = 50;
    let [x, y] = [400, 300];
    let [ox, oy] = [400, 300];
    let [shigh, sleft, sdown] = [600, 0, 0];
    let [tiny, star, starv, starx, stary, tinyx, tinyy, tinyv] = Array(8)
      .fill()
      .map(() => Array(sparkles).fill(0));

    document.onmousemove = mouse;
    window.onresize = setWidth;

    function sparkle() {
      const root = document.querySelector(":root").style;
      const zoom = root.getPropertyValue('--zoom');
      if (x !== ox || y !== oy) {
        [ox, oy] = [x, y];
        activateSparkles();
      }
      updateSparkles(zoom);
      setTimeout(sparkle, 40);
    }

    function activateSparkles() {
      for (let c = 0; c < sparkles; c++) {
        if (!starv[c]) {
          [starx[c], stary[c]] = [x, y];
          updateStarStyle(star[c], x, y, "visible", "rect(0px, 5px, 5px, 0px)");
          starv[c] = 50;
          break;
        }
      }
    }

    function updateSparkles(zoom) {
      for (let c = 0; c < sparkles; c++) {
        if (starv[c]) updateStar(c, zoom);
        if (tinyv[c]) updateTiny(c, zoom);
      }
    }

    function updateStar(i, zoom) {
      if (--starv[i] === 25) star[i].style.clip = "rect(1px, 4px, 4px, 1px)";
      if (starv[i]) {
        stary[i] += 1 + Math.random() * 3;
        if (stary[i] < shigh + sdown) {
          star[i].style.top = stary[i] * (zoom ? 1 / zoom : 1) + "px";
          starx[i] += ((i % 5) - 2) / 5;
          star[i].style.left = starx[i] * (zoom ? 1 / zoom : 1) + "px";
        } else {
          star[i].style.visibility = "hidden";
          starv[i] = 0;
          return;
        }
      } else {
        tinyv[i] = 50;
        tiny[i].style.top = (tinyy[i] = stary[i]) + "px";
        tiny[i].style.left = (tinyx[i] = starx[i]) + "px";
        tiny[i].style.width = "2px";
        tiny[i].style.height = "2px";
        star[i].style.visibility = "hidden";
        tiny[i].style.visibility = "visible";
      }
    }

    function updateTiny(i, zoom) {
      if (--tinyv[i] === 25) {
        tiny[i].style.width = "1px";
        tiny[i].style.height = "1px";
      }
      if (tinyv[i]) {
        tinyy[i] += 1 + Math.random() * 3;
        if (tinyy[i] < shigh + sdown) {
          tiny[i].style.top = tinyy[i] * (zoom ? 1 / zoom : 1) + "px";
          tinyx[i] += ((i % 5) - 2) / 5;
          tiny[i].style.left = tinyx[i] * (zoom ? 1 / zoom : 1) + "px";
        } else {
          tiny[i].style.visibility = "hidden";
          tinyv[i] = 0;
          return;
        }
      } else tiny[i].style.visibility = "hidden";
    }

    function mouse(e) {
      setScroll();
      y = e ? e.pageY : e.y + sdown;
      x = e ? e.pageX : e.x + sleft;
    }

    function setScroll() {
      if (typeof document.body.pageYOffset === "number") {
        sdown = document.body.pageYOffset;
        sleft = document.body.pageXOffset;
      } else if (document.body.scrollTop || document.body.scrollLeft) {
        sdown = document.body.scrollTop;
        sleft = document.body.scrollLeft;
      } else if (
        document.documentElement &&
        (document.documentElement.scrollTop ||
          document.documentElement.scrollLeft)
      ) {
        sleft = document.documentElement.scrollLeft;
        sdown = document.documentElement.scrollTop;
      } else {
        sdown = 0;
        sleft = 0;
      }
    }
    function setWidth() {
      if (typeof document.body.innerWidth === "number") {
        shigh = document.body.innerHeight;
      } else if (
        document.documentElement &&
        document.documentElement.clientWidth
      ) {
        shigh = document.documentElement.clientHeight;
      } else if (document.body.clientWidth) {
        shigh = document.body.clientHeight;
      }
    }

    // Create div function
    function createDiv(height, width) {
      let div = document.createElement("em");
      div.style.pointerEvents = "none";
      div.style.position = "absolute";
      div.style.height = height + "px";
      div.style.width = width + "px";
      div.style.overflow = "hidden";
      div.style.backgroundColor = color;
      return div;
    }

    // Initialization logic
    initSparkles();
    setWidth();
    sparkle();

    function initSparkles() {
      for (let i = 0; i < sparkles; i++) {
        tiny[i] = createDiv(3, 3);
        tiny[i].style.visibility = "hidden";
        document.querySelector("#cursor_wrapper").appendChild(tiny[i]);
        [starv[i], tinyv[i]] = [0, 0];
        star[i] = createStarDiv();
        document.querySelector("#cursor_wrapper").appendChild(star[i]);
      }
    }

    function createStarDiv() {
      let starDiv = createDiv(5, 5);
      starDiv.style.backgroundColor = "transparent";
      starDiv.style.visibility = "hidden";
      let rlef = createDiv(1, 5);
      let rdow = createDiv(5, 1);
      starDiv.appendChild(rlef);
      starDiv.appendChild(rdow);
      [rlef.style.top, rlef.style.left] = ["2px", "0px"];
      [rdow.style.top, rdow.style.left] = ["0px", "2px"];
      return starDiv;
    }

    function updateStarStyle(elem, x, y, visibility, clip) {
      elem.style.left = x + "px";
      elem.style.top = y + "px";
      elem.style.clip = clip;
      elem.style.visibility = visibility;
    }
  }, []);

  return (
    <>
      <div id="cursor_wrapper"></div>
      <canvas id="fairy_wrapper"></canvas>
    </>
  );
};

export default Cursor;
