import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/droppable";
import { recycleBinIcons, appSettings } from "apps";
import recyclebin from "assets/icons/bin1.ico";

const Icons = ({
  icons,
  onPointerDown,
  onDoubleClick,
  displayFocus,
  mouse,
  selecting,
  setSelectedIcons,
  flexFlow,
  overflow,
  height,
  txtColor,
  docs
}) => {
  const [iconsRect, setIconsRect] = useState([]);
  function measure(rect) {
    if (iconsRect.find((r) => r.id === rect.id)) return;
    setIconsRect((iconsRect) => [...iconsRect, rect]);
  }
  useEffect(() => {
    if (!selecting) return;
    const sx = Math.min(selecting.x, mouse.docX);
    const sy = Math.min(selecting.y, mouse.docY);
    const sw = Math.abs(selecting.x - mouse.docX);
    const sh = Math.abs(selecting.y - mouse.docY);
    const selectedIds = iconsRect
      .filter((rect) => {
        const { x, y, w, h } = rect;
        return x - sx < sw && sx - x < w && y - sy < sh && sy - y < h;
      })
      .map((icon) => icon.id);
    setSelectedIcons(selectedIds);
  }, [iconsRect, setSelectedIcons, selecting, mouse.docX, mouse.docY]);

  return (
    <IconsContainer
      style={{ flexFlow: flexFlow + " wrap", overflow: overflow, maxHeight: height, maxWidth: '100%', width: flexFlow === "row" ? "100%" : "auto"}}
    >
      {icons.map((icon, index) => (
        <StyledIcon
          focused={icon.focused}
          key={index}
          iconItem={icon}
          displayFocus={displayFocus}
          onPointerDown={onPointerDown}
          onDoubleClick={onDoubleClick}
          measure={measure}
          flexFlow={flexFlow}
          txtColor={txtColor}
          icons={icons}
          docs={docs}
        />
      ))}
    </IconsContainer>
  );
};

const Icon = ({
  className,
  displayFocus,
  flexFlow,
  focused,
  iconItem,
  icons,
  measure,
  onDoubleClick,
  onDoubleClickIcon,
  onPointerDown,
  txtColor,
  docs
}) => {
  const id = iconItem.id
  const icoSetting = appSettings[iconItem.component];
  const component = icoSetting.component
  const title = icoSetting.header.title
  const icon = docs ? icoSetting.header.docIcon : icoSetting.header.icon;
  const ref = useRef(null);
  const rectRef = useRef(null);
  function _onPointerDown() {
    onPointerDown(id);
  }
  function _onDoubleClick() {
    onDoubleClick(component);
  }
  useEffect(() => {
    rectRef.current = ref.current.parentElement.getBoundingClientRect();
    $(`.all_icons`).draggable({
      revert: "valid",
      scroll: false,
      containment: [
        rectRef.current.x - 15,
        rectRef.current.y - 15,
        rectRef.current.x + rectRef.current.width - 85,
        rectRef.current.y +
          rectRef.current.height -
          (52 + ref.current.children[1].offsetHeight),
      ],
    });
    $(`.desk_icons`).draggable({
      revert: "valid",
      scroll: false,
      containment: [
        -15,
        -15,
        document.body.clientWidth - 85,
        document.body.clientHeight - 120,
      ],
    });
    $(".RecycleBin").droppable({
      drop: (e, ui) => {
        let droppedtitle = ui.draggable.attr("class").split(/\s+/)[0].trim();
        if (droppedtitle === "System32") {
          window.localStorage.setItem("buggedOut", "true");
        } else {
          appSettings.RecycleBin.header.icon = recyclebin;
          recycleBinIcons.push({component: droppedtitle, id: recycleBinIcons.length, focused: false});
          ui.draggable.remove();
        }
      },
    });
  }, [txtColor]);

  useEffect(() => {
    const target = ref.current;
    if (!target) return;
    const { left, top, width, height } = ref.current.getBoundingClientRect();
    const posX = left + window.scrollX;
    const posY = top + window.scrollY;
    measure({ id, x: posX, y: posY, w: width, h: height });
  }, [id, measure]);
  return (
    <>
      <div
        className={`${iconItem.component.replaceAll(" ", "")}${
          txtColor === "black" ? " all_icons" : " desk_icons"
        }${focused ? " focused_icons " : " "}${className}`}
        onPointerDown={_onPointerDown}
        onDoubleClick={_onDoubleClick}
        ref={ref}
      >
        <div className={`${className}_img_container`}>
          <img
            src={
              title !== "Recycle Bin"
                ? icon
                : appSettings.RecycleBin.header.icon
            }
            alt={title}
            className={`${className}_img`}
          />
        </div>
        <div className={`${className}_text_container`}>
          <div className={`${className}_text`}>{title}</div>
        </div>
      </div>
    </>
  );
};

const IconsContainer = styled.div`
  position: absolute;
  display: flex;
  overflow: hidden;
  padding: 5px 0;
  .RecycleBin {
    position: fixed;
    right: 0;
    bottom: 37px;
  }
  .System32.desk_icons {
    position: fixed;
    top: 50%;
    left: 50%;
    align-self: center;
    justify-self: center;
  }
`;

const StyledIcon = styled(Icon)`
  width: 70px;
  height: 80px;
  margin: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &_text_container {
    font-size: 11px;
    color: ${({ txtColor }) => (txtColor ? txtColor : "white")};
    text-shadow: 0 1px 1px
      ${({ txtColor, focused, displayFocus }) =>
        txtColor && !(focused && displayFocus) ? "white" : "black"};
    margin-top: 5px;
    display: flex;
    justify-content: center;
    &:before {
      content: "";
      display: block;
      flex-grow: 1;
    }
    &:after {
      content: "";
      display: block;
      flex-grow: 1;
    }
  }
  &_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${({ focused, displayFocus }) =>
      focused && displayFocus ? "5" : "3"};
    max-height: 60px;
    padding: 0 3px 2px;
    color: ${({ focused, displayFocus, txtColor }) =>
      focused && displayFocus ? "white" : txtColor};
    background-color: ${({ focused, displayFocus }) =>
      focused && displayFocus ? "#0b61ff" : "transparent"};
    border: ${({ focused, displayFocus }) =>
      focused && displayFocus
        ? "1px dotted darkgrey"
        : "1px solid transparent"};
    text-align: center;
    flex-shrink: 1;
  }
  &_img_container {
    width: 32px;
    height: 32px;
    filter: ${({ focused, displayFocus }) =>
      focused && displayFocus ? "drop-shadow(0 0 blue)" : ""};
  }
  &_img {
    width: 32px;
    height: 32px;
    opacity: ${({ focused, displayFocus }) =>
      focused && displayFocus ? 0.5 : 1};
  }
`;

export default Icons;
