const shakers = [
  require("assets/remixer/shaker/0.wav"),
  require("assets/remixer/shaker/1.wav"),
  require("assets/remixer/shaker/2.wav"),
  require("assets/remixer/shaker/3.wav"),
  require("assets/remixer/shaker/4.wav"),
  require("assets/remixer/shaker/5.wav"),
  require("assets/remixer/shaker/6.wav"),
  require("assets/remixer/shaker/7.wav"),
  require("assets/remixer/shaker/8.wav"),
  require("assets/remixer/shaker/9.wav"),
  require("assets/remixer/shaker/10.wav"),
  require("assets/remixer/shaker/11.wav"),
  require("assets/remixer/shaker/12.wav"),
  require("assets/remixer/shaker/13.wav"),
  require("assets/remixer/shaker/14.wav"),
  require("assets/remixer/shaker/15.wav"),
  require("assets/remixer/shaker/16.wav"),
  require("assets/remixer/shaker/17.wav"),
  require("assets/remixer/shaker/18.wav"),
  require("assets/remixer/shaker/19.wav"),
  require("assets/remixer/shaker/20.wav"),
  require("assets/remixer/shaker/21.wav"),
  require("assets/remixer/shaker/22.wav"),
  require("assets/remixer/shaker/23.wav"),
  require("assets/remixer/shaker/24.wav"),
  require("assets/remixer/shaker/25.wav"),
  require("assets/remixer/shaker/26.wav"),
  require("assets/remixer/shaker/27.wav"),
  require("assets/remixer/shaker/28.wav"),
  require("assets/remixer/shaker/29.wav"),
  require("assets/remixer/shaker/30.wav"),
  require("assets/remixer/shaker/31.wav"),
  require("assets/remixer/shaker/32.wav"),
  require("assets/remixer/shaker/33.wav"),
  require("assets/remixer/shaker/34.wav"),
  require("assets/remixer/shaker/35.wav"),
  require("assets/remixer/shaker/36.wav"),
  require("assets/remixer/shaker/37.wav"),
  require("assets/remixer/shaker/38.wav"),
  require("assets/remixer/shaker/39.wav"),
  require("assets/remixer/shaker/40.wav"),
  require("assets/remixer/shaker/41.wav"),
  require("assets/remixer/shaker/42.wav"),
  require("assets/remixer/shaker/43.wav"),
  require("assets/remixer/shaker/44.wav"),
  require("assets/remixer/shaker/45.wav"),
  require("assets/remixer/shaker/46.wav"),
  require("assets/remixer/shaker/47.wav"),
  require("assets/remixer/shaker/48.wav"),
  require("assets/remixer/shaker/49.wav"),
  require("assets/remixer/shaker/50.wav"),
  require("assets/remixer/shaker/51.wav"),
  require("assets/remixer/shaker/52.wav"),
  require("assets/remixer/shaker/53.wav"),
  require("assets/remixer/shaker/54.wav"),
  require("assets/remixer/shaker/55.wav"),
  require("assets/remixer/shaker/56.wav"),
  require("assets/remixer/shaker/57.wav"),
  require("assets/remixer/shaker/58.wav"),
  require("assets/remixer/shaker/59.wav"),
  require("assets/remixer/shaker/60.wav"),
  require("assets/remixer/shaker/61.wav"),
  require("assets/remixer/shaker/62.wav"),
  require("assets/remixer/shaker/63.wav"),
  require("assets/remixer/shaker/64.wav"),
];

export default shakers;
