import { RANGE, PALETTE } from "../constants";
import * as palettes from "../palettes";
import { cloneCanvas, fillBufferPixel } from "../utils";

const optionTypes = {
  hue: { type: RANGE, range: [0, 259], step: 1, default: 150, label: 'hue' },
  saturation: { type: RANGE, range: [-2, 10], step: 0.5, default: 0, label: 'saturation' },
  luminosity: { type: RANGE, range: [-2, 2], step: 0.1, default: 0, label: 'luminosity' },
  palette: { type: PALETTE, default: palettes.nearest }
};

const defaults = {
  hue: optionTypes.hue.default,
  saturation: optionTypes.saturation.default,
  luminosity: optionTypes.luminosity.default,
  palette: optionTypes.palette.default
};

const huesaturation = (
  input: HTMLCanvasElement,
  options: {
    hue: number,
    saturation: number,
    luminosity: number
  } = defaults
): HTMLCanvasElement => {
  const { hue, saturation, luminosity, palette } = options;
  const output = cloneCanvas(input, false);

  const inputCtx = input.getContext("2d", {
  willReadFrequently: true
});
  const outputCtx = output.getContext("2d", {
  willReadFrequently: true
});

  if (!inputCtx || !outputCtx) {
    return input;
  }

  const buf = inputCtx.getImageData(0, 0, input.width, input.height).data;
  let nPixels = buf.length;
  let v = Math.pow(2, luminosity);
  let s = Math.pow(2, saturation);
  let h = Math.abs(hue + 360) % 360;
  let i;

  var vsu = v * s * Math.cos((h * Math.PI) / 180),
    vsw = v * s * Math.sin((h * Math.PI) / 180);
  // (result spot)(source spot)
  var rr = 0.299 * v + 0.701 * vsu + 0.167 * vsw,
    rg = 0.587 * v - 0.587 * vsu + 0.33 * vsw,
    rb = 0.114 * v - 0.114 * vsu - 0.497 * vsw;
  var gr = 0.299 * v - 0.299 * vsu - 0.328 * vsw,
    gg = 0.587 * v + 0.413 * vsu + 0.035 * vsw,
    gb = 0.114 * v - 0.114 * vsu + 0.293 * vsw;
  var br = 0.299 * v - 0.3 * vsu + 1.25 * vsw,
    bg = 0.587 * v - 0.586 * vsu - 1.05 * vsw,
    bb = 0.114 * v + 0.886 * vsu - 0.2 * vsw;

  var r, g, b, a;

  for (i = 0; i < nPixels; i += 4) {
    r = buf[i + 0];
    g = buf[i + 1];
    b = buf[i + 2];
    a = buf[i + 3];

    buf[i + 0] = rr * r + rg * g + rb * b;
    buf[i + 1] = gr * r + gg * g + gb * b;
    buf[i + 2] = br * r + bg * g + bb * b;
    buf[i + 3] = a; // alpha
    const col = palette.getColor([buf[i + 0], buf[i + 1], buf[i + 2], buf[i + 3]], palette.options);
    fillBufferPixel(buf, i, col[0], col[1], col[2], col[3]);
  }
  outputCtx.putImageData(new ImageData(buf, output.width, output.height), 0, 0);

  return output;
};

const defaultFunc = {
  name: "Hue/Saturation",
  func: huesaturation,
  optionTypes,
  options: defaults,
  defaults
};

export default defaultFunc;
